import React from "react";
import { WithStyles } from "@material-ui/core";
import { FixedMobileStepper } from "./MobileStepper";

interface Props extends WithStyles {
  onNext: () => void;
  onPrev: () => void;
}

export const Step2 = (props: Props) => {

  return (
    <>
      <div className={props.classes.stepContainer}>
        <p>Thank-you - Please look out for an email from noreply@schalagolf.com with instructions on how to reset your password - If you have not received an email, please check your SPAM folder</p>
      </div>
      <FixedMobileStepper
        maxSteps={4}
        step={1}
        onNext={props.onNext}
        onPrev={props.onPrev}
      />
    </>
  );
};
