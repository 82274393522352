import { Button, createStyles, IconButton, LinearProgress, Paper, Theme, WithStyles } from "@material-ui/core";
import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { GlobalState } from "../../types/globalState";
import { RouterProps, Views } from "../Dashboard";
import { Game, IndexedGames } from "../../types/game";
import { switchComponent } from "../../actions/componentRouter";
import GameActions from "../../actions/games";
import BorderedBox from "../SharedComponents/BorderedBox";
import { Close } from "@material-ui/icons";
import { ErrorOnlyCallback } from "../../types/actions";
import { User } from "../../types/user";

interface Props extends WithStyles {
  addPlayerToGroup(id: string, groupName: string, playerId: string, callback?: ErrorOnlyCallback): void;
  switchComponent(view: any, props?: any): void;
  user: User;
  routerProps: RouterProps;
  games: IndexedGames;
  loading: boolean;
}
interface State {}

class Dashboard extends Component<Props, State> {
  getGame = () => {
    return this.props.games[this.props.routerProps.gameId];
  };

  _onCreateGroup = () => {
    this.props.switchComponent(Views.GroupCreation, this.props.routerProps);
  };

  _onAddPlayerToGroup = (groupName: string) => {
    console.log(groupName);
    this.props.addPlayerToGroup(
      this.props.routerProps.gameId,
      groupName,
      this.props.user._id,
      err => !err && this.props.switchComponent(Views.GameManager, this.props.routerProps),
    );
  };

  getUniqueGroups = (playerGroups: any, gameGroups?: string[]) => {
    const playerGroupNames = Object.keys(playerGroups);
    return gameGroups
      ? gameGroups.concat(playerGroupNames).filter((value, index, self) => self.indexOf(value) === index)
      : playerGroupNames;
  };

  renderGroupsList(game: Game) {
    const { classes } = this.props;
    const players = Object.values(game.players);
    const grouped = _.groupBy(players, (player: any) => (player.group ? player.group.name : undefined));
    const uniqueGroups = this.getUniqueGroups(grouped, game.groups); //to be compatible with old gmaes without groups

    return uniqueGroups.map((group: any) => {
      if (group == "null" || group == "undefined") return null;
      const players = grouped[group] ? Object.values(grouped[group]) : [];
      return (
        <div className={classes.groupsContainer}>
          <BorderedBox title={`Group: ${group}`} variant={"h6"} padding={"none"} className={classes.flexGrow}>
            <div className={classes.inline}>
              <strong>Players: </strong>
              {players.map((player: any) => {
                return <p style={{ margin: 8 }}>{`${player.name} ${player.lastName || ""}`}</p>;
              })}
            </div>
          </BorderedBox>
          {players.length < 4 ? (
            <IconButton color={"primary"} className={classes.button} onClick={() => this._onAddPlayerToGroup(group)}>
              Join
            </IconButton>
          ) : (
            <IconButton color={"default"} disabled={true} className={classes.button}>
              Full
            </IconButton>
          )}
        </div>
      );
    });
  }

  renderJoinedPlayers(game: Game) {
    const { classes } = this.props;
    const players = Object.values(game.players);
    return (
      <div className={classes.inline} style={{ height: "auto", paddingBottom: "5rem" }}>
        {players.map((player: any) => (
          <p key={player.id} style={{ margin: 8, minWidth: 100 }}>
            {player.name + " " + player.lastName}
          </p>
        ))}
      </div>
    );
  }

  render() {
    const { classes, loading } = this.props;
    const game = this.getGame();
    if (!game) return null;

    return (
      <Paper className={classes.main}>
        <IconButton className={classes.closeButton} onClick={() => this.props.switchComponent(Views.DEFAULT)}>
          <Close />
        </IconButton>
        {loading ? <LinearProgress /> : null}
        <h3 className={classes.centerTextAlign}>Groups & Players</h3>
        <Button variant={"outlined"} color={"primary"} onClick={this._onCreateGroup} className={classes.center}>
          Create your own Group
        </Button>
        {this.renderGroupsList(game)}
        <h3 className={classes.centerTextAlign}>Joined Players</h3>
        {this.renderJoinedPlayers(game)}
      </Paper>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    main: {
      padding: 16,
    },
    center: {
      left: "50%",
      transform: "translateX(-50%)",
    },
    centerTextAlign: {
      textAlign: "center",
    },
    inline: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    button: {
      width: 64,
      height: 64,
      fontSize: 20,
      marginLeft: 16,
      float: "right",
    },
    groupsContainer: {
      display: "flex",
      alignItems: "flex-end",
      height: "auto !important",
    },
    flexGrow: {
      flexGrow: 1,
    },
    closeButton: {
      position: "absolute",
      top: 0,
      right: 8,
    },
  });

const mapStateToProps = (state: GlobalState) => {
  const { user } = state.auth;
  const { joinedGames } = state.games;
  const { loading } = state.general;
  return {
    user,
    loading,
    games: joinedGames,
  };
};

export default connect(mapStateToProps, {
  switchComponent,
  addPlayerToGroup: GameActions.addPlayerToGroup,
})(withStyles(styles)(Dashboard));
