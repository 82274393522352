import socketClient from "socket.io-client";
import Config from "../config";

let socket: SocketIOClient.Socket | null = null;
let gameId: string = "";

export const connect = (id: string) => {
  try {
    gameId = id;
    socket = socketClient(Config.SOCKET_END_POINT, {
      query: "gameId=" + gameId,
    });
    return true;
  } catch {
    return false;
  }
};

export enum EVENTS {
  SCORES_UPDATED = "scoresUpdated",
  GAME_FINISHED = "gameFinished",
  PLAYERS_CHANGED = "playersChanged",
  LEADERBOARD_REVEAL = "leaderboardReveal",
  PRESENTATION_UPDATED = "presentationUpdated",
}
export type EVENTS_Type =
  | EVENTS.SCORES_UPDATED
  | EVENTS.GAME_FINISHED
  | EVENTS.PLAYERS_CHANGED
  | EVENTS.LEADERBOARD_REVEAL
  | EVENTS.PRESENTATION_UPDATED;

export const addEvent = (event: EVENTS_Type, callBack: (data: any) => void) => socket?.on(event + gameId, callBack);

export const disconnect = () => socket?.disconnect();
