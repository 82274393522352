import BaseApi from "./Api";

class LoggerApi  extends BaseApi {

  url = 'logs';
  constructor() {
    super();
    this.setResourceUrl(this.url);
  }

  insertLog(type: string, data: any){
    return this.instance({
      method: "post",
      url: `${this.url}/${type}`,
      data
    });
  }
}

export default new LoggerApi();
