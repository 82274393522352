import { Player } from "types/player";
import { PlayingRulesType } from "types/competition";
import Config from "config";

type ScoreType = "point" | "nett";

class TeamWinners {
  scorers: { [key: string]: number[] };
  playingRules: PlayingRulesType;
  players: Player[];
  scoringFormat: string;

  constructor(players: Player[], scoringFormat: string, playingRules: PlayingRulesType) {
    this.scorers = {};
    this.playingRules = playingRules;
    this.players = players;
    this.scoringFormat = scoringFormat;
  }

  calculate = (hole: number) => {
    const scoreType = this.scoringFormat === Config.SCORING_FORMAT.STABLEFORD ? "point" : "nett";
    const { bestTwo, bestThree, bestFour } = this.playingRules;

    if (bestFour?.includes(hole)) this._sortByScoreAndUpdate(this.players, hole, scoreType, 4);
    else if (bestThree?.includes(hole)) this._sortByScoreAndUpdate(this.players, hole, scoreType, 3);
    else if (bestTwo?.includes(hole)) this._sortByScoreAndUpdate(this.players, hole, scoreType, 2);
    else this._sortByScoreAndUpdate(this.players, hole, scoreType);

    return this.scorers;
  };

  _updateScorers = (playerId: string, holeNumber: number) => {
    const winHoles = this.scorers[playerId] || [];
    if (winHoles.includes(holeNumber)) return;

    winHoles.push(holeNumber);
    this.scorers = { ...this.scorers, [playerId]: winHoles };
  };

  _resolveSameScorers = (p1: Player, p2: Player) => {
    const scorersObj = this.scorers;

    const p1SoFarWins = scorersObj[p1._id]?.length || 0;
    const p2SoFarWins = scorersObj[p2._id]?.length || 0;
    // 1) The least score
    if (p1SoFarWins !== p2SoFarWins) return p1SoFarWins - p2SoFarWins;
    // 2) Player with the lowest handicap index
    if (p1.hci !== p2.hci) return p1.hci - p2.hci;
    // 3) By their names
    return p1.name.localeCompare(p2.name);
  };

  _sortByScoreAndUpdate = (players: Player[], hole: number, scoreType: ScoreType, top: number = 1) => {
    const sorted = players.sort((p1, p2) => {
      const p1Score = p1.points.holes[hole][scoreType];
      const p2Score = p2.points.holes[hole][scoreType];

      return p1Score !== p2Score
        ? scoreType === "point"
          ? p2Score - p1Score
          : p1Score - p2Score
        : this._resolveSameScorers(p1, p2);
    });

    for (let i = 0; i < top; i++) {
      if (sorted[i]) this._updateScorers(sorted[i]._id, hole);
    }
  };
}

export default TeamWinners;
