import { BaseActions } from "./BaseActions";
import potsApi from "../api/pots";
import ErrorHandler from "../api/Errorhandler";
import ReduxTypes from "../consts/ReduxTypes";

export default new (class PaymentActions extends BaseActions {
  constructor() {
    super(potsApi, "POTS");
  }

  getTransactions = (potId: string, callback?: any) => {
    return (dispatch: any) => {
      potsApi
        .getTransactions(potId)
        .then((res: any) => {
          dispatch({ type: ReduxTypes.POTS_GET_TRANSACTIONS, id: potId, payload: res.data });
          if (callback) callback(null, res.data);
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
          if (callback) callback(err, null);
        });
    };
  };

  getWithdrawalLink = (potId: string, callback?: any) => {
    return (dispatch: any) => {
      potsApi
        .getWithdrawalLink(potId)
        .then((res: any) => {
          if (callback) callback(null, res.data);
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
          if (callback) callback(err, null);
        });
    };
  };
})();
