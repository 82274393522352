import React, { Component } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import {
  AppBar,
  Button,
  createStyles,
  IconButton,
  SwipeableDrawer,
  Theme,
  Toolbar,
  Typography,
  WithStyles,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { Menu } from "@material-ui/icons";
import { GlobalState } from "../../types/globalState";
import { logOut } from "../../actions";
import Routes from "../Routes";
import { BackButton } from "./BackButton";
import DrawerContent from "./DrawerContent";
import Sync from "../Sync";
import Loading from "../Loading";
import { User } from "../../types/user";
import { UpgradePlans } from "../UpgradePlan/UpgradePlans";
import { showDialog } from "../SharedComponents/Dialog";
import SwitchVideoHelp from "components/SharedComponents/SwitchVideoHelp";
import VideoPopup from "components/SharedComponents/VideoPopup";
import { HELP_VIDEOS } from "consts";

let toggleDrawer = (open: boolean) => {};
let closeDrawer = () => {};
const drawerWidth = window.innerWidth < 640 ? window.innerWidth : 340;

interface State {
  open: boolean;
}

interface Props extends WithStyles {
  logOut(): void;
  user: User;
}

class Drawer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      open: false,
    };
    this.toggleDrawer = this.toggleDrawer.bind(this);
  }

  componentDidMount() {
    toggleDrawer = this.toggleDrawer;
    closeDrawer = this.closeDrawer;
  }

  closeDrawer = () => {
    this.setState({ open: false });
  };
  toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    this.setState({ open });
  };

  render() {
    const { open } = this.state;
    const { classes, user } = this.props;

    return (
      <>
        <AppBar
          position="fixed"
          className={clsx({
            [classes.appBarShift]: open,
          })}
        >
          <Loading />
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={this.toggleDrawer(true)}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <Menu />
            </IconButton>

            <div className={classes.switchBox}>
              <Typography variant="h6" noWrap className={classes.title}>
                {process.env.REACT_APP_PAGE_TITLE}
              </Typography>

              <VideoPopup styles={{ marginRight: 15 }} videoURL={HELP_VIDEOS.VIDEO_HELP} />
              <SwitchVideoHelp size="small" label="Video Help" />
            </div>
            <BackButton />
          </Toolbar>
        </AppBar>
        <Sync />
        <Routes
          user={user}
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        />
        <SwipeableDrawer
          open={open}
          className={classes.drawer}
          onClose={this.toggleDrawer(false)}
          onOpen={this.toggleDrawer(true)}
        >
          <DrawerContent drawerWidth={drawerWidth} user={user} />
        </SwipeableDrawer>
      </>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    appBar: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      height: "100%",
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    title: {
      flexGrow: 1,
      marginLeft: 16,
    },
    switchBox: {
      flex: 1,
      display: "flex",
      alignItems: "center",

      "& .MuiSwitch-edgeEnd": {
        marginRight: 0,
      },
    },
  });

const mapStateToProps = (state: GlobalState) => {
  const { user } = state.auth;
  return { user };
};
export { toggleDrawer, closeDrawer };
export default connect(mapStateToProps, { logOut })(withStyles(styles)(Drawer));
