import AdvertsApi from "../api/adverts";
import { BaseActions } from "./BaseActions";
import ErrorHandler from "../api/Errorhandler";
import ReduxTypes from "../consts/ReduxTypes";

export default new (class GameActions extends BaseActions {
  constructor() {
    super(AdvertsApi, "ADVERTS");
  }

  uploadImage = (id: string, key: string, field: string, image: any, callback?: any) => {
    return (dispatch: any) => {
      AdvertsApi.uploadImage(id, key, field, image)
        .then((res: any) => {
          if (callback) callback(null, res.data);
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
          if (callback) callback(err, null);
        });
    };
  };

  getByGameId = (gameId: string, callback?: any) => {
    return (dispatch: any) => {
      AdvertsApi.getByGameId(gameId)
        .then((res: any) => {
          dispatch({ type: ReduxTypes.GET_ADVERT_BY_GAME_ID, payload: res.data, id: gameId });
          if (callback) callback(null, res.data);
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
          if (callback) callback(err, null);
        });
    };
  };

  seen = (gameId: string, holeNumber: number) => {
    return (dispatch: any) => {
      dispatch({ type: ReduxTypes.SET_ADVERT_SEEN, id: gameId, holeNumber });
    };
  };
})();
