import ReduxTypes from "../consts/ReduxTypes";
import userApi from "../api/user";
import ErrorHandler from "../api/Errorhandler";
import { BaseActions } from "./BaseActions";
import { logOut } from "./authentication";
import { showSnackbar } from "components/SharedComponents/Notifier";
import history from "customHistory";
import { GuestPlayerInputs } from "types/player";

export default new (class UserActions extends BaseActions {
  constructor() {
    super(userApi, "USERS");
  }

  getClubPlayers = (
    page: number,
    limit: number,
    filter?: any,
    sort?: string,
    dir?: string,
    query?: string,
    callback?: any,
  ) => {
    return (dispatch: any) => {
      userApi
        .getClubPlayers(page, limit, filter, sort, dir, query)
        .then((res: any) => {
          dispatch({
            type: ReduxTypes.FETCH_CLUB_PLAYERS,
            payload: res.data.items,
            count: res.data.count,
          });
          if (callback) callback(null, res.data);
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
          if (callback) callback(err, null);
        });
    };
  };

  updateMe = (userInfo: any, callback?: any) => {
    return (dispatch: any) => {
      userApi
        .updateMe(userInfo)
        .then((res: any) => {
          dispatch({ type: ReduxTypes.UPDATE_USER, payload: res.data });

          // If user changes his email, then log out the user
          if (userInfo.email) {
            showSnackbar("A verification email has been sent to your new email address.", 6000, "success");
            logOut()(dispatch);
            history.push("/");
          }

          if (callback) callback(null, res.data);
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
          if (callback) callback(err, null);
        });
    };
  };

  updateHcp = (id: string, hci: number, callback?: any) => {
    return (dispatch: any) => {
      userApi
        .updateHcp(id, hci)
        .then((res: any) => {
          dispatch({ type: ReduxTypes.UPDATE_USER_HANDICAP, payload: hci });
          if (callback) callback(null, res.data);
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
          if (callback) callback(err, null);
        });
    };
  };

  updateUser = (userId: string, data: any, callback?: any) => {
    console.log("updateUser", data);
    return (dispatch: any) => {
      userApi
        .update(userId, data)
        .then((res: any) => {
          dispatch({ type: ReduxTypes.getUpdateType("USERS"), payload: userId, data: res.data });
          if (data.email) showSnackbar("A verification email has been sent.", 6000, "success");
          if (callback) callback(null, res.data);
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
          if (callback) callback(err, null);
        });
    };
  };
})();

export const updateGuestUser = (userId: string, data: GuestPlayerInputs, gameId: string, cb?: () => void) => {
  return (dispatch: any) => {
    userApi
      .updateGuest(userId, data)
      .then((res: any) => {
        showSnackbar("Data updated successfully.", 3000, "success");
        dispatch({
          type: ReduxTypes.UPDATE_GUEST_PLAYER,
          payload: {
            userId,
            gameId,
            data: res.data.data.user,
          },
        });
        if (cb) cb();
      })
      .catch((err: any) => {
        ErrorHandler(err, dispatch);
        if (cb) cb();
      });
  };
};
