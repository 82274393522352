import React from "react";

export interface PlayerNameProps {
  isGuest?: boolean;
  lighter?: boolean;
  name?: string;
  lastName: string;
  onlyLastName?: boolean;
  hci?: number;
}

const PlayerName: React.FC<PlayerNameProps> = props => {
  const { isGuest, name, lastName, lighter, hci } = props;

  return (
    <>
      <span
        style={{
          ...(isGuest ? { fontStyle: "italic", paddingRight: 7 } : {}),
          ...(lighter ? { fontWeight: "lighter" } : {}),
        }}
      >
        {`${name} ${lastName}`}
      </span>
      {isGuest && <sup style={{ color: "red" }}>U</sup>}
      {hci !== undefined && <span>{` (${hci}) `}</span>}
    </>
  );
};

export default PlayerName;
