import * as React from "react";
import { LinearProgress } from "@material-ui/core";
import { connect } from "react-redux";
import { GlobalState } from "../../types/globalState";
import { onLoadingChangedEvent } from "../../api/httpService";
import { setIsLoading, setIsNotLoading } from "./../../actions/general";

export interface LoadingProps {
  loading: boolean;
  setIsLoading(): void;
  setIsNotLoading(): void;
  color?: "secondary" | "primary";
  style?: any;
}

const Loading: React.SFC<LoadingProps> = props => {
  const handleLoading = (result: Boolean) => {
    result ? props.setIsLoading() : props.setIsNotLoading();
  };

  onLoadingChangedEvent(handleLoading);
  return props.loading ? <LinearProgress style={props.style} color={props.color || "secondary"} /> : null;
};

const mapStateToProps = (state: GlobalState) => {
  const { loading } = state.general;
  return {
    loading,
  };
};

export default connect(mapStateToProps, { setIsLoading, setIsNotLoading })(Loading);
