import React from "react";
import { useSelector } from "react-redux";
import { GlobalState } from "../../types/globalState";

export interface Routes {
  [index: number]: any;
}

interface Props {
  routes: Routes;
}

export const ComponentSelector = (props: Props) => {
  const { routes } = props;
  const routerState = useSelector((state: GlobalState) => (state.router));
  if (typeof routes[routerState.currentView] === "function") {
    return routes[routerState.currentView](routerState.props);
  } else {
    return <h2>Component route is not a function</h2>;
  }
};