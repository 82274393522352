import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  wrapper: {},
  editor: {
    backgroundColor: "#fefefe",
    padding: "1rem",
    border: "1px solid #ccc",
    fontWeight: "normal",

    "& *": {
      fontFamily: "Arial, sans-serif !important",
      fontWeight: "inherit",
    },
  },
  toolbar: {
    border: "1px solid #ccc",

    "& .rdw-option-active": {
      boxShadow: "1.5px 1.5px 0px #ccc inset",
    },
  },
});
