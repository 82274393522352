import { SignupInput, UsernameType, EmailType } from "../types/user";
import validator from "validator";
import BaseApi from "./Api";
import config from "../config";
class AuthApi extends BaseApi {
  url = "auth";

  constructor() {
    super();
    this.setResourceUrl(this.url);
  }

  signIn = (email: EmailType, username: UsernameType, password: string) => {
    let data: any = {};
    if (email) data.email = email;
    else if (username) data.username = username;
    data.password = password;
    data.version = config.VERSION;

    const res = this.instance({
      method: "post",
      url: `${this.url}/signIn`,
      data,
    });
    return res;
  };

  acceptPolicy = (email: EmailType, username: UsernameType, password: string) => {
    return this.instance({
      method: "patch",
      url: `${this.url}/accept-policy`,
      data: {
        email,
        username,
        password,
      },
    });
  };

  confirmEmail = (token: string) => {
    return this.instance({
      method: "patch",
      url: `${this.url}/verify-email/${token}`,
    });
  };

  resendEmailToken = (email: EmailType, username: UsernameType) => {
    return this.instance({
      method: "post",
      url: `${this.url}/resend-token`,
      data: {
        email,
        username,
      },
    });
  };

  signUp = (data: SignupInput) => {
    const res = this.instance({
      method: "post",
      url: `${this.url}/signUp`,
      data,
    });
    return res;
  };

  existance = (email: EmailType, username: UsernameType) => {
    let data: any = {};
    if (email) data.email = email;
    else if (username) data.username = username;

    return this.instance({
      method: "post",
      url: `${this.url}/exist`,
      data,
    });
  };

  getVerificationCode = (emailOrUsername: string) => {
    let data: any = {};
    let email, username;
    if (validator.isEmail(emailOrUsername)) {
      email = emailOrUsername;
    } else {
      username = emailOrUsername;
    }

    if (email) data.email = email;
    else if (username) data.username = username;
    else throw new Error("email or username should be given");
    return this.instance({
      method: "post",
      url: `${this.url}/getVCode`,
      data,
    });
  };

  resetPassword = (vCode: string, emailOrUsername: string, password: string) => {
    let data: any = {
      password,
      vCode,
    };
    if (validator.isEmail(emailOrUsername)) {
      data.email = emailOrUsername;
    } else {
      data.username = emailOrUsername;
    }

    return this.instance({
      method: "post",
      url: `${this.url}/resetPassword`,
      data,
    });
  };
}

export default new AuthApi();
