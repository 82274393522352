import BaseApi from "./Api";
import { LocalRulesData } from "types/course";
class CoursesApi extends BaseApi {
  url = "courses";
  constructor() {
    super();
    this.setResourceUrl(this.url);
  }

  getScoreCards = (courseId: string) => {
    return this.instance({
      method: "get",
      url: `${this.url}/${courseId}/scoreCards`,
    });
  };

  updateLocalRules = (courseId: string, data: LocalRulesData) => {
    const formData = new FormData();
    formData.append("content", data.content);
    if (data.logo) formData.append("logo", data.logo);

    return this.instance({
      method: "patch",
      url: `${this.url}/${courseId}/update-rules`,
      data: formData,
    });
  };
}

export default new CoursesApi();
