import * as React from "react";
import { TableRow, TableCell } from "@material-ui/core";
import { GolfCourse } from "@material-ui/icons";

import { Player } from "types/player";
import PlayerName from "components/SharedComponents/PlayerName";

export interface TournamentLeaderboardRowProps {
  player: any;
  index: number;
  onClick(player: Player): void;
}

const TournamentLeaderboardRow: React.FunctionComponent<TournamentLeaderboardRowProps> = props => {
  return (
    <TableRow key={props.index} onClick={() => props.onClick(props.player)}>
      <TableCell component="th" scope="row">
        {props.player.disqualified ? "" : `${props.index + 1}.`}
      </TableCell>
      <TableCell>
        <GolfCourse color={"primary"} />
      </TableCell>
      <TableCell>
        <PlayerName isGuest={props.player.guest} name={props.player.name} lastName={props.player.lastName} />
      </TableCell>
      <TableCell>{props.player.disqualified ? "DQ" : props.player.points}</TableCell>
    </TableRow>
  );
};

export default TournamentLeaderboardRow;
