import React from "react";
import { Dialog } from "@material-ui/core";
import DialogToolbar from "./DialogToolbar";

let showDialog = (
  content: any,
  maxWidth: maxWithType,
  fullScreen: boolean,
  backDropClickToClose?: boolean,
  toolbar?: boolean,
  title?: string,
  logo?: string,
  onClose?: () => void,
) => {};
let hideDialog = () => {};

type maxWithType = "xs" | "sm" | "md" | "lg" | "xl" | false;

interface State {
  content: any;
  open: boolean;
  title: string;
  logo: string;
  className: string;
  fullScreen: boolean;
  maxWidth: maxWithType;
  backDropClickToClose: boolean;
  toolbar: boolean;
  onClose?: () => void;
}

class _Dialog extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      title: "",
      logo: "",
      content: null,
      maxWidth: "lg",
      open: false,
      className: "",
      fullScreen: false,
      backDropClickToClose: false,
      toolbar: false,
    };
  }

  componentDidMount() {
    showDialog = this.showDialog;
    hideDialog = this.closeDialog;
  }

  showDialog = (
    content: any,
    maxWidth: maxWithType,
    fullScreen: boolean,
    backDropClickToClose?: boolean,
    toolbar?: boolean,
    title?: string,
    logo?: string,
    onClose?: () => void,
  ) => {
    this.setState({
      open: true,
      content,
      fullScreen: fullScreen && window.innerWidth < 501,
      maxWidth,
      backDropClickToClose: backDropClickToClose ? backDropClickToClose : false,
      toolbar: toolbar ? toolbar : false,
      title: title ? title : "",
      logo: logo || "",
      onClose,
    });
  };
  closeDialog = () => {
    if (this.state.onClose) {
      this.state.onClose();
    }
    this.setState({ open: false });
  };

  render() {
    const { className, maxWidth, open, content, backDropClickToClose, toolbar, title, logo } = this.state;
    return (
      <Dialog
        open={open}
        scroll={"paper"}
        className={className}
        disableEnforceFocus
        onBackdropClick={backDropClickToClose ? this.closeDialog : undefined}
        maxWidth={maxWidth}
        fullScreen={this.state.fullScreen}
      >
        {toolbar ? (
          <DialogToolbar logo={logo} border={logo ? false : true} title={title} onClick={this.closeDialog} />
        ) : null}
        {content}
      </Dialog>
    );
  }
}

export { showDialog, hideDialog };
export default _Dialog;
