import React from "react";

import { Competition, IndexedCompetitions } from "types/competition";
import { Picker, Option } from "components/SharedComponents/Picker";

export interface GameRuleSelectorProps {
  competitionId: string;
  competitions: Competition[];
  competitionsObj: IndexedCompetitions;
  onChange: (opt: Option) => void;
}

const GameRuleSelector: React.FC<GameRuleSelectorProps> = ({
  competitions,
  competitionsObj,
  competitionId,
  onChange,
}) => {
  const selectedRule = competitionsObj[competitionId];

  const { basic: basicRules, WHS: whsRules, custom: customRules } = competitions.reduce(
    (acc, el) => {
      return el.type
        ? { ...acc, [el.type]: [...acc[el.type], { id: el._id, title: el.name, active: el.active }] }
        : acc;
    },
    { basic: [], WHS: [], custom: [] },
  );

  return (
    <Picker
      label="Tap to select a rule"
      options={[
        {
          title: "Standard Rules",
          options: [],
          nestedList: [
            {
              title: "Basic",
              data: basicRules,
            },
            {
              title: "WHS",
              data: whsRules,
            },
          ],
        },
        {
          title: "Custom Rules",
          options: customRules,
        },
      ]}
      selectedId={competitionId}
      selectedTitle={selectedRule?.name}
      onChange={onChange}
    />
  );
};

export default GameRuleSelector;
