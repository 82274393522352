import React, { useState } from "react";
import validator from "validator";
import { Button, DialogContent, IconButton, InputAdornment, Chip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { Warning } from "@material-ui/icons";
import { connect } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { TextInput } from "../SharedComponents/TextInput";
import { showPickerDialog } from "../SharedComponents/PickerDialog";
import { hideAuxiliaryDialog } from "../SharedComponents/AuxiliaryDialog";
import GameActions from "../../actions/games";
import { ErrorOnlyCallback } from "../../types/actions";
import Loading from "../Loading";
import { showSnackbar } from "../SharedComponents/Notifier";
import { hideDialog } from "../SharedComponents/Dialog";
import { Club } from "types/club";

interface Props {
  gameId: string;
  gameClub: Club;
  homeClub?: Club;
  sendSubmissionCardNotifierMail(
    gameId: string,
    homeClubMail: string,
    gameClubMail: string,
    extraReceivers: string[],
    callback?: ErrorOnlyCallback,
  ): void;
}

type FormData = {
  gameClubEmail: string;
  homeClubEmail: string;
};

const SubmissionCardConfirmationDialog: React.FC<Props> = props => {
  const { gameClub, homeClub, gameId, sendSubmissionCardNotifierMail } = props;

  const { control, handleSubmit } = useForm<FormData>({
    defaultValues: {
      homeClubEmail: homeClub?.cardEmail || "",
      gameClubEmail: gameClub.cardEmail || "",
    },
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [receivers, setReceivers] = useState<string[]>([]);

  const handleMoreReceivers = () => {
    showPickerDialog(
      "Email address",
      "Text",
      (email: string) => {
        if (!validator.isEmail(email)) {
          showSnackbar("Please enter a valid Email address");
        } else {
          setReceivers([...receivers, email]);
        }
      },
      3,
      "",
      null,
      false,
      false,
    );
  };

  const onSubmit = (data: FormData) => {
    setLoading(true);
    sendSubmissionCardNotifierMail(gameId, data.homeClubEmail, data.gameClubEmail, receivers, err => {
      setLoading(false);
      if (!err) showSnackbar("Notify Mails have been sent successfully", 5000);
      hideAuxiliaryDialog();
      hideDialog();
    });
  };

  return (
    <DialogContent>
      <Loading />
      <h1>Submission Card Confirmation</h1>
      <p>
        By confirming this message, SCHALA will notify this club and your home club that you wish to submit this card
        for handicap purposes.
      </p>
      <p>
        <strong className="bold">
          Please be aware that you may have to comply with the score recording software used at the course you are
          playing, <br /> so make sure you leave enough time to do so before teeing off !
        </strong>
      </p>
      <div className={"flex-row flex-end"}>
        <Warning style={{ marginRight: 8, color: "red", fontSize: 28 }} />
        <strong style={{ color: "red" }}>Important notice, There is no way back from here.</strong>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="gameClubEmail"
          control={control}
          rules={{
            required: true,
            pattern: /\S+@\S+\.\S+/,
          }}
          render={({ field, formState }) => (
            <TextInput
              {...field}
              style={{ margin: "20px 0" }}
              fullWidth
              placeholder={"Please put this Club Email"}
              autoComplete="email"
              error={!!formState.errors?.gameClubEmail}
              helperText={!!formState.errors?.gameClubEmail ? "Please enter valid email address" : ""}
              disableProfanityCheck
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" onClick={handleMoreReceivers}>
                    <IconButton>
                      <AddIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />

        <div style={{ marginBottom: 20 }}>
          {receivers.map(val => {
            return (
              <Chip
                key={val}
                label={val}
                onDelete={() => {
                  setReceivers(receivers.filter(r => r !== val));
                }}
              />
            );
          })}
        </div>

        {homeClub?._id !== gameClub._id && (
          <Controller
            name="homeClubEmail"
            control={control}
            rules={{
              required: true,
              pattern: /\S+@\S+\.\S+/,
            }}
            render={({ field, formState }) => (
              <TextInput
                {...field}
                style={{ marginBottom: 20 }}
                error={!!formState.errors?.gameClubEmail}
                helperText={!!formState.errors?.homeClubEmail ? "Please enter valid email address" : ""}
                fullWidth
                placeholder={"Please put your Home Club Email"}
                autoComplete="email"
                disableProfanityCheck
              />
            )}
          />
        )}

        <div style={{ padding: 8, float: "right" }}>
          <Button type="submit" disabled={loading} style={{ margin: "0 24px" }} color={"primary"} variant="contained">
            {loading ? "Sending ..." : "Send the email"}
          </Button>
          <Button
            type="button"
            disabled={loading}
            color={"secondary"}
            variant="contained"
            onClick={hideAuxiliaryDialog}
          >
            Cancel
          </Button>
        </div>
      </form>
    </DialogContent>
  );
};

export default connect(null, {
  sendSubmissionCardNotifierMail: GameActions.sendSubmitionCardNotifierMail,
})(SubmissionCardConfirmationDialog);
