import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  TableContainer,
  Paper,
} from "@material-ui/core";
import { PersonAdd, Delete, PermIdentity, Home } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { OrganisersType } from "../../types/game";
import { UserBrifObj } from "../../types/user";
import SearchUser from "../Search/SearchUser";
import { SearchItem } from "../Search/SearchInput";

interface Props {
  organisers?: UserBrifObj[];
  insideHandler?: boolean;
  viewOnly?: boolean;
  onAdd?(user: UserBrifObj): void;
  onDelete?(user: UserBrifObj): void;
  onChange?(organisers: UserBrifObj[]): void;
}

export const GameAdditionalOrganisersTable = (props: Props) => {
  const [organisers, setOrganisers] = useState<UserBrifObj[]>(props.organisers || ([] as UserBrifObj[]));

  const addOrganiser = (user: UserBrifObj) => {
    const _organisers = [...organisers];
    if (_organisers.filter(o => o._id === user._id).length > 0) return;
    _organisers.push(user);
    setOrganisers(_organisers);
    props.onChange && props.onChange(_organisers);
  };

  const removeOrganiser = (user: UserBrifObj) => {
    const _organisers = organisers.filter(organiser => organiser._id !== user._id);
    setOrganisers(_organisers);
    props.onChange && props.onChange(_organisers);
  };

  useEffect(() => setOrganisers(props.organisers || ([] as UserBrifObj[])), [props.organisers]);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {organisers.length > 0 && (
              <>
                <TableCell></TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Last Name</TableCell>
                <TableCell>Username</TableCell>
              </>
            )}
            {!props.viewOnly && (
              <TableCell>
                <SearchUser
                  style={{ width: 58 }}
                  dialog
                  autoClearText
                  title=" "
                  icon={<PersonAdd />}
                  selectedUsers={organisers.map(
                    o => ({ value: o._id, title: o.name, subtitle: o.lastName, object: o } as SearchItem),
                  )}
                  onUserSelected={(user: any) => {
                    if (user && props.insideHandler) addOrganiser(user.object);
                    if (user && props.onAdd) props.onAdd(user.object);
                  }}
                  minChar={3}
                />
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {organisers.map(organiser => (
            <TableRow>
              <TableCell>
                {organiser.type === OrganisersType.Owner ? (
                  <Tooltip title="Game Owner">
                    <PermIdentity />
                  </Tooltip>
                ) : (
                  organiser.type === OrganisersType.ClubManager && (
                    <Tooltip title="Club manager">
                      <Home />
                    </Tooltip>
                  )
                )}
              </TableCell>
              <TableCell>{organiser.name}</TableCell>
              <TableCell>{organiser.lastName}</TableCell>
              <TableCell>{organiser.username}</TableCell>
              {!props.viewOnly && (
                <TableCell>
                  {organiser.type !== OrganisersType.Owner && (
                    <Tooltip title={"Remove Organiser"}>
                      <IconButton
                        onClick={() => {
                          console.log(props.insideHandler);
                          if (props.insideHandler) removeOrganiser(organiser);
                          if (props.onDelete) props.onDelete(organiser);
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
