import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import ClubActions from "../../actions/clubs";
import { GlobalState } from "../../types/globalState";
import BorderedBox from "../SharedComponents/BorderedBox";
import { Button, createStyles, Paper, TextField, Theme, Typography, WithStyles } from "@material-ui/core";
import { showSnackbar } from "../SharedComponents/Notifier";
import LinearProgress from "@material-ui/core/LinearProgress";
import { hideDialog } from "../SharedComponents/Dialog";
import validator from "validator";
import Messages from "../../consts/Messages";
import { TextInput } from "../SharedComponents/TextInput";
import { filter } from "../../utils/profanityFilter";
import { Club, ClubInput } from "../../types/club";

interface Props extends WithStyles {
  insertClub(data: ClubInput): void;
  updateClub(id: string, data: Club): void;

  edit?: Club;
  loading: boolean;
}
interface State {
  club: ClubInput;
  err_name: boolean;
  err_address: boolean;
  err_email: boolean;
  err_phone: boolean;
  err_msg_email: string;
  err_msg_phone: string;
}

class ClubSetup extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    let club: ClubInput = {
      name: "",
      address: "",
      phone: "",
      email: "",
      cardEmail: "",
      cardPhone: "",
      registered: false,
    } as Club;
    if (props.edit) {
      const { _id, ...typedFields } = props.edit;
      club = typedFields;
    }
    this.state = {
      club,
      err_name: false,
      err_email: false,
      err_address: false,
      err_phone: false,
      err_msg_email: "",
      err_msg_phone: "",
    };
  }

  formValidation = () => {
    const { name, email, address, cardEmail } = this.state.club;
    const err_name = name.trim().length < 2 || filter.isProfane(name);
    const err_address = filter.isProfane(address);

    const err_email = !validator.isEmail(email) || (cardEmail !== undefined && !validator.isEmail(cardEmail));
    const err_msg_email = err_email ? Messages.error.emailNotValid : "";

    this.setState({
      err_name,
      err_email,
      err_msg_email,
      err_address,
    });

    return !(err_name || err_email || err_address);
  };

  _handleTextFieldChange = (value: string, name: string) => {
    this.setState({
      club: { ...this.state.club, [name]: value },
    });
  };
  _submitForm = () => {
    const { edit } = this.props;
    const club = this.state.club;
    const newClub = {
      //...club,
      name: club.name.trim(),
      address: club.address.trim(),
      phone: club.phone.trim(),
      email: club.email.trim(),
      cardPhone: club.cardPhone?.trim(),
      cardEmail: club.cardEmail?.trim(),
    } as Club;
    if (this.formValidation()) {
      if (edit) {
        this.props.updateClub(edit._id, newClub);
      } else {
        this.props.insertClub(newClub);
      }
    } else {
      showSnackbar("Form is not valid, check items");
    }
  };

  renderActionButton() {
    const { classes, loading } = this.props;
    if (loading)
      return (
        <div className={classes.loadingContainer}>
          <LinearProgress color="primary" variant={"indeterminate"} />
        </div>
      );
    else
      return (
        <div className={classes.actionButtonContainer}>
          <Button
            fullWidth
            className={classes.actionButton}
            variant={"contained"}
            color={"primary"}
            onClick={this._submitForm}
          >
            Save
          </Button>
          <Button
            fullWidth
            className={classes.actionButton}
            variant={"contained"}
            color={"secondary"}
            onClick={hideDialog}
          >
            Cancel
          </Button>
        </div>
      );
  }
  render() {
    const { classes } = this.props;
    const { name, address, phone, email, cardPhone, cardEmail } = this.state.club;
    const { err_name, err_email, err_address, err_phone, err_msg_email, err_msg_phone } = this.state;

    return (
      <Paper className={classes.paper}>
        <Typography variant={"h5"} className={classes.title}>
          Club Setup
        </Typography>
        <TextInput
          label={"Name"}
          margin={"dense"}
          variant={"outlined"}
          value={name}
          error={err_name}
          captalize
          name={"name"}
          onChange={this._handleTextFieldChange}
          fullWidth
        />
        <TextInput
          label={"Address"}
          margin={"dense"}
          variant={"outlined"}
          value={address}
          error={err_address}
          name={"address"}
          onChange={this._handleTextFieldChange}
          fullWidth
        />
        <BorderedBox title={"Club Contact Email"} variant={"h6"}>
          <TextInput
            placeholder={"Contact Email"}
            margin={"dense"}
            variant={"outlined"}
            value={email}
            error={err_email}
            helperText={err_msg_email}
            disableProfanityCheck
            name={"email"}
            onChange={this._handleTextFieldChange}
            fullWidth
          />
        </BorderedBox>
        <BorderedBox title={"Club Contact Phone"} variant={"h6"}>
          <TextInput
            placeholder={"Contact Phone"}
            margin={"dense"}
            variant={"outlined"}
            value={phone}
            error={err_phone}
            helperText={err_msg_phone}
            name={"phone"}
            onChange={this._handleTextFieldChange}
            fullWidth
          />
        </BorderedBox>

        <BorderedBox title={"Card for Handicap Email"} variant={"h6"}>
          <TextInput
            placeholder={"Card for Handicap Email"}
            margin={"dense"}
            variant={"outlined"}
            value={cardEmail}
            error={err_email}
            helperText={err_msg_email}
            disableProfanityCheck
            name={"cardEmail"}
            onChange={this._handleTextFieldChange}
            fullWidth
          />
        </BorderedBox>

        <BorderedBox title={"Card for Handicap Phone"} variant={"h6"}>
          <TextInput
            placeholder={"Card for Handicap Phone"}
            margin={"dense"}
            variant={"outlined"}
            value={cardPhone}
            error={err_phone}
            helperText={err_msg_phone}
            name={"cardPhone"}
            onChange={this._handleTextFieldChange}
            fullWidth
          />
        </BorderedBox>
        {this.renderActionButton()}
      </Paper>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    title: {
      fontWeight: "bold",
    },
    paper: {
      display: "block",
      position: "relative",
      left: "50%",
      transform: "translateX(-50%)",
      width: "auto",
      maxWidth: 500,
      minWith: 360,
      backgroundColor: "rgba(255,255,552,0.85)",
      padding: theme.spacing(2),
    },
    inline: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    actionButtonContainer: {
      display: "flex",
    },
    actionButton: {
      marginTop: theme.spacing(1),
    },
    loadingContainer: {
      width: "100%",
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
  });

const mapStateToProps = (state: GlobalState) => {
  const { loading } = state.general;
  return {
    loading,
  };
};

export default connect(mapStateToProps, {
  insertClub: ClubActions.insert,
  updateClub: ClubActions.update,
})(withStyles(styles)(ClubSetup));
