import React, { useState } from "react";
import { PropTypes, TextField } from "@material-ui/core";
import { CapitalizeWords } from "../../utils/text";
import { filter } from "../../utils/profanityFilter";

interface InputProps {
  id?: string;
  onChange(value: string, name: string, internalError?: boolean): void;
  value?: string | number;
  name?: string;
  error?: boolean;
  helperText?: string;
  label?: string;
  placeholder?: string;
  style?: any;
  fullWidth?: boolean;
  captalize?: boolean;
  disableProfanityCheck?: boolean;
  margin?: PropTypes.Margin;
  variant?: any;
  autoComplete?: string;
  type?: string;
  rows?: number;
  multiline?: boolean;
  className?: string;
  defaultValue?: string;
  InputProps?: any;
  inputProps?: any;
}

export const TextInput = (props: InputProps) => {
  const [internalError, setInternalError] = useState<boolean>(false);
  const [internalHelperText, setInternalHelperText] = useState<string>("");

  const onChange = (e: any) => {
    let value = e.target.value;
    if (props.captalize) value = CapitalizeWords(value);

    let isProfane = false;
    if (!props.disableProfanityCheck) {
      isProfane = filter.isProfane(value);
      setInternalError(isProfane);
      setInternalHelperText((isProfane && "Swear word") || "");
    }
    props.onChange(value, e.target.name, isProfane);
  };

  return (
    <TextField
      id={props.id}
      fullWidth={props.fullWidth}
      label={props.label}
      placeholder={props.placeholder}
      name={props.name}
      style={props.style}
      error={props.error || internalError}
      helperText={props.helperText || internalHelperText}
      onChange={onChange}
      value={props.value}
      margin={props.margin}
      variant={props.variant}
      autoComplete={props.autoComplete}
      type={props.type}
      rows={props.rows}
      multiline={props.multiline}
      className={props.className}
      defaultValue={props.defaultValue}
      InputProps={props.InputProps}
      inputProps={props.inputProps}
    />
  );
};
