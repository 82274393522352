import React from "react";
import { makeStyles, Theme, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { GlobalState } from "../../types/globalState";

interface Props {
  transactionNo: string;
}

const TransactionsList = (props: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography style={{ padding: "16px", textAlign: "center" }}>{props?.transactionNo}</Typography>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    minWidth: 600,
  },
}));

const mapStatetoProps = (state: GlobalState) => {
  const { user } = state.auth;
  return {
    user,
  };
};

export default connect(mapStatetoProps)(TransactionsList);
