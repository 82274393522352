import React, { Component } from "react";
import { connect } from "react-redux";
import CourseActions from "../../actions/courses";
import SearchInput, { SearchItem } from "./SearchInput";
import { Course } from "../../types/course";

interface Props {
  onClubSelected(item: any):void;
  searchCourse(
    callback: any,
    query: string,
    page: number,
    limit: number,
    filter?: any,
    sort?: string,
    dir?: string,
  ): void;
  defaultSelectedItem?: any;
  className?: string;
  clubId?: string;
}
interface State {
  club?: any;
}

class SearchCourse extends Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      club: props.defaultSelectedItem
    };
  }

  _courseSearchHandler = (query: string) => {
    return new Promise((resolve, reject) => {
      this.props.searchCourse(
        (err: any, result: any) => {
          if (!err) {
            resolve(
              result.data.items.map((item: Course) => ({
                value: item._id,
                title: item.name,
                subtitle: `${item.holesNumber} Holes`,
                object: item,
              })),
            );
          } else {
            reject(err);
          }
        },
        query,
        1,
        10,
        this.props.clubId? { club: { id: this.props.clubId } }: null,
        "name",
        "desc",
      );
    });
  };

  _onCourseSelected = (item: SearchItem) => {
    this.props.onClubSelected(item && item.object ? item.object : null);
  };

  render() {
    const {className} = this.props;
    const { club } = this.state;
    const selectedCourse = club
      ? { value: club.id, title: club.name, subtitle: club.address }
      : undefined;

    return (
      <SearchInput
        dialog
        minChar={2}
        className={className}
        label={"Input course name"}
        placeholder={"Click to search courses"}
        defaultSelectedItem={selectedCourse}
        searchHandler={this._courseSearchHandler}
        onItemSelected={this._onCourseSelected}
      />
    );
  }

}

export default connect(null, {
  searchCourse: CourseActions.search,
})(SearchCourse);
