import React from "react";
import { Dialog, Divider, IconButton, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";

let showAuxiliaryDialog = (
  content: any,
  maxWidth: maxWithType,
  fullScreen: boolean,
  backDropClickToClose?: boolean,
  toolbar?: boolean,
  title?: string,
  widescreen?: boolean,
) => {};
let hideAuxiliaryDialog = () => {};

type maxWithType = "xs" | "sm" | "md" | "lg" | "xl" | false;

interface State {
  content: any;
  open: boolean;
  title: string;
  className: string;
  fullScreen: boolean;
  maxWidth: maxWithType;
  backDropClickToClose: boolean;
  toolbar: boolean;
}

class AuxiliaryDialog extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      title: "",
      content: null,
      maxWidth: "lg",
      open: false,
      className: "",
      fullScreen: false,
      backDropClickToClose: false,
      toolbar: false,
    };
  }

  componentDidMount() {
    showAuxiliaryDialog = this.showDialog;
    hideAuxiliaryDialog = this.closeDialog;
  }

  showDialog = (
    content: any,
    maxWidth: maxWithType,
    fullScreen: boolean,
    backDropClickToClose?: boolean,
    toolbar?: boolean,
    title?: string,
    widescreen?: boolean,
  ) => {
    this.setState({
      open: true,
      content,
      fullScreen: (fullScreen && window.innerWidth < 501) || widescreen === true,
      maxWidth,
      backDropClickToClose: backDropClickToClose ? backDropClickToClose : false,
      toolbar: toolbar ? toolbar : false,
      title: title ? title : "",
    });
  };
  closeDialog = () => {
    this.setState({ open: false });
  };

  render() {
    const { className, maxWidth, open, content, backDropClickToClose, toolbar, title } = this.state;
    return (
      <Dialog
        open={open}
        scroll={"paper"}
        className={className}
        disableEnforceFocus
        onBackdropClick={backDropClickToClose ? this.closeDialog : undefined}
        maxWidth={maxWidth}
        fullScreen={this.state.fullScreen}
      >
        {toolbar ? (
          <div style={{ position: "sticky", top: 0, background: "#FFF", zIndex: 10 }}>
            <Typography style={{ float: "left", width: "fit-content", margin: "6px 20px" }} variant="h6" gutterBottom>
              {title}
            </Typography>
            <IconButton style={{ float: "right" }} onClick={this.closeDialog}>
              <Close />
            </IconButton>
            <Divider style={{ width: "100%" }} />
          </div>
        ) : null}
        {content}
      </Dialog>
    );
  }
}

export { showAuxiliaryDialog, hideAuxiliaryDialog };
export default AuxiliaryDialog;
