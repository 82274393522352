export interface Advert {
  _id: string;
  club: any;
  scoreCard: any;
  name: string;
  holesInfo: any;
  state: AdvertStates.DRAFT | AdvertStates.ACTIVE | AdvertStates.INACTIVE;
  game: any;
  info: { [index: string]: { state: AdvertStates.ACTIVE | AdvertStates.INACTIVE; image: string } };
  type: AdvertTypes;
  expiresAt?: Date | null;
  activatesAt?: Date | null;
  description?: string;
  maxViews?: number;
}

export enum AdvertTypes {
  LEADER_BOARD = "leaderboard",
  START_SHEET = "start-sheet",
  SCORE_CARD = "score-card",
}

export enum AdvertStates {
  ACTIVE = "active",
  INACTIVE = "inactive",
  DRAFT = "draft",
}

export type AdvertInput = Omit<Advert, "_id">;

export interface holesInfo {
  holeNumber: number;
  image: string;
  type: "fullScreen" | "dialog";
  active: boolean;
}

export interface IndexedAdverts {
  [index: string]: Advert;
}

export interface AdvertsState {
  adverts: IndexedAdverts;
  gamesAd: IndexedAdverts;
  count: number;
}
