import { getHandicapDisplay } from "./../index";
import { Game } from "../../types/game";
import Moment from "moment";
import { playerCustomHandicap } from "../../types/handicpas";
import { isCombinedGame } from "..";
import { Player } from "types/player";
import Config from "config";

export const downloadLeaderboardCSV = (game: Game, queryType: string) => {
  DownloadFile(`${game.name}-${Moment().format("L")}.csv`, generateLeaderboardCSV(game, queryType));
};

export const getLeaderboardCSVFile = (fileName: string, game: Game, queryType: string) => {
  return new File([generateLeaderboardCSV(game, queryType)], fileName, { type: "text/csv" });
};

export const downloadCustomHandicapsCSV = (game: Game, playersHcp: playerCustomHandicap[]) => {
  // console.log(playersHcp);
  //@ts-ignore
  DownloadFile(`${game.competition.name}-${Moment().format("L")}.csv`, customHandicapsCSVContent(game, playersHcp));
};

export const getCustomHandicapsCSVFile = (fileName: string, game: Game, playersHcp: playerCustomHandicap[]) => {
  return new File([customHandicapsCSVContent(game, playersHcp)], fileName, { type: "text/csv" });
};

const generateLeaderboardCSV = (game: Game, scoringType: string) => {
  let csv: string = "";
  csv += `Report Date,${Moment().format(Config.DATE_DISPLAY_FORMAT)}\n`;
  csv += `Game Name,${game.name}\n`;
  csv += `Game Code,${game.code}\n`;
  csv += `Game Date,${Moment(game.date).format(Config.DATE_DISPLAY_FORMAT)}\n`;

  let startIndex = 0;
  let endingIndex: number = game.course.holesNumber;

  if (scoringType === "front") endingIndex = game.course.holesNumber / 2;
  else if (scoringType === "back") startIndex = game.course.holesNumber / 2;
  // Header
  const header = ["Pos", "Name", "PH", "HI", "CH"];
  for (let i = startIndex; i < endingIndex; i++) {
    header.push(`Hole${i + 1} gross`, `Hole${i + 1} net/pts`);
  }
  csv += `\n${header.join(",")}\n`;

  const isCombined = isCombinedGame(game.competition);
  const players = game.players ? Object.values(game.players).filter(x => x.isTeamCard || !isCombined) : [];
  const scoringFormat = game.competition.scoringFormat === Config.SCORING_FORMAT.STABLEFORD ? "point" : "nett";

  players.forEach((p: Player, i) => {
    const data = [i + 1, `${p.name} ${p.lastName}`, getHandicapDisplay(p.hcp), p.hci || 0, p.courseHandicap || 0];

    if (p.holes) {
      Object.values(p.holes)
        .slice(startIndex, endingIndex)
        .forEach((hole: any) => {
          data.push(hole.gross, p.points.holes[hole.holeNumber]?.[scoringFormat]);
        });

      csv += `${data.join(",")}\n`;
    }
  });

  return csv;
};

export const customHandicapsCSVContent = (game: Game, playersHcp: playerCustomHandicap[]) => {
  let csv: string = "";
  //@ts-ignore
  csv = `Rule Name,${game.competition.name}\n`;
  csv += `Report Date,${Moment().format(Config.DATE_DISPLAY_FORMAT)}\n`;
  csv += `Game Name,${game.name}\n`;
  csv += `Game Code,${game.code}\n`;
  csv += `Game Date,${Moment(game.date).format(Config.DATE_DISPLAY_FORMAT)}\n`;
  csv += `\nFirst Name,Last Name,PH\n`;

  playersHcp
    .sort((a: playerCustomHandicap, b: playerCustomHandicap) =>
      a.lastName?.toLocaleLowerCase() > b.lastName?.toLocaleLowerCase() ? 1 : -1,
    )
    .forEach(
      (player: playerCustomHandicap) =>
        (csv += `${player.name},${player.lastName ? player.lastName : ""},${
          player.hcp ? getHandicapDisplay(player.hcp) : ""
        }\n`),
    );
  return csv;
};

const DownloadFile = (filename: string, text: string) => {
  let element = document.createElement("a");
  element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(text));
  element.setAttribute("download", filename);
  element.style.display = "none";
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};
