import React, { useState } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Dialog, Button, List, ListItem, Typography } from "@material-ui/core";

export type Option = { id: string; title: string; active: boolean };

export type NestedOption = { title: string; data: Option[] };
export interface Options {
  title: string;
  options: Option[];
  nestedList?: NestedOption[];
}

interface PickerProps {
  label: string;
  options: Options[];
  selectedId?: string;
  selectedTitle?: string;
  onChange?(option: Option): void;
}

export const Picker = (props: PickerProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange = (panel: string) => (_: any, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const renderItem = (opt: Option) => (
    <ListItem
      disabled={!opt.active}
      key={opt.id}
      button
      onClick={() => {
        props.onChange && props.onChange(opt);
        setOpen(false);
      }}
      style={(props.selectedId === opt.id && { backgroundColor: "#3f51b5", color: "white" }) || {}}
    >
      {opt.title}
    </ListItem>
  );

  return (
    <>
      <Button fullWidth onClick={() => setOpen(true)}>
        <p style={{ textAlign: "left", width: "100%" }}>{props.selectedTitle || props.label}</p>
      </Button>
      <Dialog open={open} onBackdropClick={() => setOpen(false)}>
        {props.options.map((opt, i) => {
          return (
            <Accordion
              key={opt.title}
              expanded={expanded === `panel${i}`}
              onChange={handleChange(`panel${i}`)}
              style={{ minWidth: "20rem" }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6" color="primary">
                  {opt.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {opt.nestedList &&
                    opt.nestedList.map((opt, i) => (
                      <div key={opt.title}>
                        <Typography style={{ marginLeft: "3px" }} variant="h6">{`${i + 1}.${opt.title}`}</Typography>
                        {opt.data.map(renderItem)}
                      </div>
                    ))}

                  {opt.options.map(renderItem)}
                </List>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Dialog>
    </>
  );
};
