import { useState, useEffect } from "react";

import advertAPI from "api/adverts";
import { Advert, AdvertStates, AdvertTypes } from "types/advert";

type StateType = {
  advert?: Advert;
  isLoading: boolean;
};

export const useGameAdvert = (gameId: string, type: AdvertTypes) => {
  const [state, setState] = useState<StateType>({
    isLoading: true,
  });

  useEffect(() => {
    const getAd = async () => {
      try {
        const { data } = await advertAPI.getGameAdvert(gameId, type);
        const advert = data.data?.advert?.state === AdvertStates.ACTIVE ? data.data.advert : undefined;
        setState({ advert, isLoading: false });
      } catch (error) {
        console.log(error);
        setState({ isLoading: false });
      }
    };

    getAd();
  }, [gameId, type]);

  return state;
};
