import BaseApi from "./Api";

class PotsApi extends BaseApi {
  url = "pots";
  constructor() {
    super();
    this.setResourceUrl(this.url);
  }

  getTransactions(potId: string) {
    return this.instance({
      method: "get",
      url: `${this.url}/${potId}/transactions`,
    });
  }

  getWithdrawalLink(potId: string) {
    return this.instance({
      method: "get",
      url: `${this.url}/${potId}/withdrawal`,
    });
  }
}

export default new PotsApi();
