import BaseApi from "./Api";

class PlayersApi extends BaseApi {
  url = "players";

  constructor() {
    super();
    this.setResourceUrl(this.url);
  }

  searchByUsername = (query: string) => {
    return this.instance({
      method: "get",
      url: `${this.url}/?query=${query}`,
    });
  };

  getNewPlayers = (gameCode?: string) => {
    return this.instance({
      method: "get",
      url: `${this.url}/new${gameCode ? `?code=${gameCode}` : ""}`,
    });
  };
}

export default new PlayersApi();
