import * as React from "react";
import { Switch, Typography, makeStyles, Theme, MenuItem, Select } from "@material-ui/core";

import BorderedBox from "../SharedComponents/BorderedBox";
import { HandicapRules } from "../../types/competition";
import HandicapControl from "./handicapControl";
import { HandicapAdjustmentRule } from "../../types/competition";
import PlacingControl from "../SharedComponents/PlacingControl";
import { HELP_VIDEOS } from "consts";

export interface CustomGameHandicapsProps {
  handicapRules: HandicapRules;
  onHandicapRulesChanged: (value: HandicapRules) => void;
}

const hcpAdjustmentNumbers = Array.from({ length: 21 }, (_, i) => i - 10);
const hcpAdjustmentPercents = Array.from({ length: 21 }, (_, i) => i * 5 - 50);

const CustomGameHandicaps: React.FunctionComponent<CustomGameHandicapsProps> = (props: CustomGameHandicapsProps) => {
  const classes = useStyles();
  const { handicapRules: rules } = props;

  const hcpListItems = rules.usingNumberInFirstTimeHandicapAdjustment ? hcpAdjustmentNumbers : hcpAdjustmentPercents;

  const onSwitchChanged = (field: string, event: React.ChangeEvent<HTMLInputElement>) => {
    props.onHandicapRulesChanged({
      ...props.handicapRules,
      [field]: event.target.checked as boolean,
    } as HandicapRules);
  };

  const onHandicapDuctingChanged = (result: number[]) => {
    props.onHandicapRulesChanged({
      ...props.handicapRules,
      handicapDeducting: result,
    } as HandicapRules);
  };

  const onLosersHandicapDuctingChanged = (result: number[]) => {
    props.onHandicapRulesChanged({
      ...props.handicapRules,
      losersHandicapDeducting: result,
    } as HandicapRules);
  };

  const onHandicapRulesChanged = (result: HandicapAdjustmentRule[]) => {
    props.onHandicapRulesChanged({
      ...props.handicapRules,
      handicapAdjustmentRules: result,
    } as HandicapRules);

    //  const { handicapRules } = this.state.competition;
    //  if (!handicapRules) return;
  };

  const onSelectChanged = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    if (!event.target.name) return;
    props.onHandicapRulesChanged({
      ...props.handicapRules,
      [event.target.name]: event.target.value as string,
    });
  };

  return (
    <BorderedBox title={"Handicap Rules"} variant={"h6"} videoIcon videoURL={HELP_VIDEOS.HANDICAP_RULES}>
      Do you:
      <div className={classes.inline}>
        <Typography variant={"subtitle1"}>Use exact handicaps: </Typography>
        <Switch
          name="exactHandicaps"
          value={rules.exactHandicaps}
          checked={rules.exactHandicaps}
          onChange={e => onSwitchChanged(e.target.name, e)}
        />
      </div>
      <div className={classes.inline}>
        <Typography variant={"subtitle1"} style={{ flex: 1 }}>
          Adjust a first time players official handicap?
        </Typography>
      </div>
      <div className={classes.inline}>
        <div>
          %
          <Switch
            classes={{
              thumb: classes.thumb,
              track: classes.track,
            }}
            name="usingNumberInFirstTimeHandicapAdjustment"
            value={rules.usingNumberInFirstTimeHandicapAdjustment}
            checked={rules.usingNumberInFirstTimeHandicapAdjustment}
            onChange={e => onSwitchChanged(e.target.name, e)}
          />
          Num.
        </div>
        <Select name="firstTimeHandicapAdjustment" value={rules.firstTimeHandicapAdjustment} onChange={onSelectChanged}>
          {hcpListItems.map((item: number, index) => (
            <MenuItem value={item} key={index}>
              {rules.usingNumberInFirstTimeHandicapAdjustment ? item : `${item}%`}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className={classes.inline}>
        <Typography variant={"subtitle1"}>Operate handicap categories?</Typography>
        <Switch
          name="customHandicapCategories"
          value={rules.customHandicapCategories}
          checked={rules.customHandicapCategories}
          onChange={e => onSwitchChanged(e.target.name, e)}
        />
      </div>
      <div className={classes.inline}>
        <Typography variant={"subtitle1"}>Adjust handicap on result position?</Typography>
        <Switch
          name="adjustHandicapOnResult"
          value={rules.adjustHandicapOnResult}
          checked={rules.adjustHandicapOnResult}
          onChange={e => onSwitchChanged(e.target.name, e)}
        />
      </div>
      <div>
        <PlacingControl
          data={rules.handicapDeducting}
          isVisible={rules.adjustHandicapOnResult}
          onChanged={onHandicapDuctingChanged}
          defaultValue={-1}
          type={"winners"}
        />
      </div>
      <div className={classes.inline}></div>
      <div>
        <PlacingControl
          data={rules.losersHandicapDeducting}
          isVisible={rules.adjustHandicapOnResult}
          onChanged={onLosersHandicapDuctingChanged}
          defaultValue={1}
          type={"losers"}
        />
      </div>
      <div className={classes.inline}>
        <Typography variant={"subtitle1"}>
          Can players go above their currently calculated PH when applying custom handicap adjustment?
        </Typography>
        <Switch
          name="aboveHIadjustment"
          value={rules.aboveHIadjustment}
          checked={rules.aboveHIadjustment}
          onChange={e => onSwitchChanged(e.target.name, e)}
        />
      </div>
      <div className={classes.inline}>
        <Typography variant={"subtitle1"}>Adjust handicap on points scored?</Typography>
        <Switch
          name="adjustHandicapOnPoints"
          value={rules.adjustHandicapOnPoints}
          checked={rules.adjustHandicapOnPoints}
          onChange={e => onSwitchChanged(e.target.name, e)}
        />
      </div>
      <div>
        <HandicapControl
          handicapAdjustments={rules.handicapAdjustmentRules}
          isVisible={rules.adjustHandicapOnPoints}
          onChanged={onHandicapRulesChanged}
        />
      </div>
      <div className={classes.inline}>
        <Typography variant={"subtitle1"}>Disregard handicap increase if player records a DNP?</Typography>
        <Switch
          name="disregardDnp"
          value={rules.disregardDnp}
          checked={rules.disregardDnp}
          onChange={e => onSwitchChanged(e.target.name, e)}
        />
      </div>
      <div className={classes.inline}>
        <Typography variant={"subtitle1"}>Do you allow joint/shared prizes: </Typography>
        <Switch
          name="sharedPrize"
          value={rules.sharedPrize}
          checked={rules.sharedPrize}
          onChange={e => onSwitchChanged(e.target.name, e)}
        />
      </div>
    </BorderedBox>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  inline: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  thumb: {
    color: theme.palette.secondary.main,
  },
  track: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

export default CustomGameHandicaps;
