import BaseApi from "./Api";

class TeamsApi extends BaseApi {
  url = "teams";
  constructor() {
    super();
    this.setResourceUrl(this.url);
  }

  insertTeam(name: string, gameId: string, color?: string) {
    return this.instance({
      method: "post",
      url: `${this.url}/`,
      data: { name, gameId, color },
    });
  }

  updateName(id: string, name: string) {
    return this.instance({
      method: "put",
      url: `${this.url}/${id}`,
      data: { name },
    });
  }

  getByGameId(gameId: string) {
    return this.instance({
      method: "get",
      url: `${this.url}/${gameId}`,
    });
  }

  removeGameTeams(id: string, teams: string[]) {
    return this.instance({
      method: "patch",
      url: `${this.url}/remove-game/${id}`,
      data: {
        teams,
      },
    });
  }
}

export default new TeamsApi();
