import React from "react";
import { Tooltip, Button, makeStyles, Theme, IconButton } from "@material-ui/core";
import { Cancel, Visibility } from "@material-ui/icons";
import { connect } from "react-redux";
import Selector from "../SharedComponents/Selector";
import { Game, GState, Leaderboard } from "../../types/game";
import { Rules, User, UserBrifObj } from "../../types/user";
import ShareGameInformationDialog from "./ShareGameInformationDialog";
import GameActions from "../../actions/games";
import { showAlertDialog } from "../SharedComponents/AlertDialog";
import EmailOptionsDialog from "components/SharedComponents/EmailOptions";
import { isCombinedGame } from "./../../utils/index";
import { ErrorOnlyCallback } from "../../types/actions";
import { EmailTypes } from "types/general";
import { useAdMediaQuery } from "hooks/useAdMediaQuery";

interface Props {
  game: Game;
  multipleQueryType: boolean;
  queryType: string;
  user: User;
  widescreen: boolean;
  isGameClosed: boolean;
  onQueryTypeChanged(type: string): void;
  onGameClosed(): void;
  closeGame(id: string, callback?: ErrorOnlyCallback): void;
  onRevealButtonClicked(gameId: string, revealPlaceStatus: number | undefined): void;
}

const teamOptions = [
  { value: "all", label: "All" },
  { value: "front", label: "Front" },
  { value: "back", label: "Back" },
];

const individualOptions = [{ value: "all", label: "All" }];

const isAllCardsFilled = (game: Game) => {
  let isAlCardsField = true;
  const isCombined = isCombinedGame(game.competition);
  const players = Object.values(game.players).filter(x => x.isTeamCard || !isCombined);
  players.forEach(player => {
    if (Object.values(player.holes).length != game.competition.holesNumber) {
      isAlCardsField = false;
      return;
    }
  });
  return isAlCardsField;
};

function LeaderboardActions(props: Props) {
  const { game, queryType, user, isGameClosed, multipleQueryType, widescreen } = props;
  const userId = user._id;
  const rule = user.rule;
  const leaderboardOptions = multipleQueryType ? teamOptions : individualOptions;
  const classes = useStyles();
  const [landscape] = useAdMediaQuery();
  const [openDialog, setOpenDialog] = React.useState(false);

  if (widescreen && landscape) return null;

  const canShowRevealButton = (leaderboard?: Leaderboard) => {
    if (!leaderboard || !leaderboard.presentationMode) return false;
    if (leaderboard.revealIndex === undefined || leaderboard.showInTwoSteps) return true;
    return (
      (leaderboard.revealIndex > 0 && leaderboard.reverseHiddenPlace) ||
      (leaderboard.revealIndex < leaderboard.hideUntil && !leaderboard.reverseHiddenPlace)
    );
  };

  const handleCloseGame = (game: Game) => {
    if (isAllCardsFilled(game))
      showAlertDialog("Close game", `You are about to close '${game.name}' game, are you sure?`, () => {
        props.closeGame(game._id, err => !err && props.onGameClosed());
      });
    else
      showAlertDialog(
        "Close game",
        `Some score cards are incomplete. Complete cards or delete players from game.`,
        null,
        null,
      );
  };

  const renderOrganiserAction = (game: Game) => {
    const isOrganiser = game.organizers.find(o => o._id === userId) !== undefined;
    if (!isOrganiser) return null;

    const { leaderboard } = game;
    const invisible = game.state !== GState.Playing || isGameClosed;

    return (
      <>
        {invisible ? null : (
          <>
            <Tooltip title="Close game" leaveTouchDelay={2000}>
              <Button onClick={() => handleCloseGame(game)}>
                <div className={classes.closeButtonContainer}>
                  <Cancel color="secondary" />
                  <span className={classes.iconLabel}>Close Game</span>
                </div>
              </Button>
            </Tooltip>
            {canShowRevealButton(leaderboard) && (
              <Tooltip title="Reveal Top players" leaveTouchDelay={2000}>
                <IconButton onClick={() => props.onRevealButtonClicked(game._id, game.leaderboard?.revealPlaceStatus)}>
                  <Visibility color="primary" />
                </IconButton>
              </Tooltip>
            )}
          </>
        )}
        <Tooltip title="Send Email" leaveTouchDelay={2000}>
          <IconButton className={classes.eBtn} onClick={setOpenDialog.bind(null, true)}>
            E
          </IconButton>
        </Tooltip>
      </>
    );
  };

  return (
    <div className={classes.container}>
      <Selector
        onChange={(value: string) => props.onQueryTypeChanged(value)}
        size={"small"}
        options={leaderboardOptions}
        value={queryType}
      />
      {game.organizers.filter((o: UserBrifObj) => o._id === userId).length > 0 || rule === Rules.Admin ? (
        <div>
          {renderOrganiserAction(game)}
          <ShareGameInformationDialog game={game} gameQueryType={queryType} />

          <EmailOptionsDialog
            user={user}
            open={openDialog}
            onClose={setOpenDialog.bind(null, false)}
            options={[EmailTypes.FINAL_RESULT, EmailTypes.EMPTY]}
            game={game}
          />
        </div>
      ) : null}
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  closeButtonContainer: {
    display: "inline-flex",
    flexDirection: "column",
    alignItems: "center",
  },
  iconLabel: {
    fontSize: 12,
    fontWeight: "bolder",
  },
  container: {
    backgroundColor: "#eeeeee",
    textAlign: "right",
    padding: "0 8px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  eBtn: {
    border: "1px solid red",
    color: "red",
    width: 38,
    height: 38,
    margin: "0 10px",
    fontSize: "1.3rem",
  },
}));

export default connect(null, { closeGame: GameActions.closeGame })(LeaderboardActions);
