import authApi from "../api/authentication";
import { User, SignupInput, UsernameType, EmailType } from "../types/user";
import { showSnackbar } from "../components/SharedComponents/Notifier";
import ReduxTypes from "../consts/ReduxTypes";
import ErrorHandler from "../api/Errorhandler";
import Config from "../config";
import { unregister } from "../serviceWorker";
import { showAlertDialog } from "components/SharedComponents/AlertDialog";
import PolicyLink from "components/PolicyLink";
// TODO: We need to refactor our design
const _loginSuccess = (dispatch: any, callback?: any) => (res: any) => {
  addTokenToLocalStorage(res.data.access_token);
  loginUserSuccess(dispatch, res.data);
  if (callback && typeof callback === "function") {
    callback();
  }
};

const _loginFailure = (dispatch: any) => (err: any) => {
  if (err.response) {
    const { status, data } = err.response;
    loginUserFailed(status, data);
  } else {
    ErrorHandler(err, dispatch);
  }
};

export const login = (email: EmailType, username: UsernameType, password: string, callback?: any) => {
  return (dispatch: any) => {
    authApi
      .signIn(email, username, password)
      .then((result: any) => {
        // Check Policy is accepted, and user is verified
        if (result.status === 202) {
          // Policy
          if (result.data?.error === "policy")
            return showAlertDialog(
              "Please accept our terms and conditions before login",
              "",
              () => {
                authApi
                  .acceptPolicy(email, username, password)
                  .then(_loginSuccess(dispatch, callback))
                  .catch(_loginFailure(dispatch));
              },
              () => {},
              "accept",
              "cancel",
              PolicyLink,
            );
          // Verification
          if (result.data?.error === "activate")
            return showAlertDialog(
              result.data.message,
              "If you did not receive the email, click 'RESEND' to resend verification email.",
              () => {
                dispatch({ type: ReduxTypes.GLOBAL_SET_IS_LOADING });
                authApi
                  .resendEmailToken(email, username)
                  .then(() => {
                    showSnackbar("Verification email has been sent successfully", 6000, "success");
                  })
                  .catch(() => {
                    showSnackbar("An Error has occurred while sending the email. please try again later", 6000);
                  })
                  .finally(() => {
                    dispatch({ type: ReduxTypes.GLOBAL_SET_IS_NOT_LOADING });
                  });
              },
              () => {},
              "resend",
              "cancel",
            );
        }
        // Else log the user in!
        else _loginSuccess(dispatch, callback)(result);
      })
      .catch(_loginFailure(dispatch));
  };
};

export const signUp = (args: SignupInput, callback?: any) => {
  return (dispatch: any) => {
    dispatch({ type: ReduxTypes.GLOBAL_SET_IS_LOADING });

    authApi
      .signUp(args)
      .then((result: any) => {
        showSnackbar(result.data.message, 10000, "success");
        if (callback) callback(null, result.data);
      })
      .catch((err: any) => {
        if (callback) callback(err, null);
        if (err.response) {
          const { status, data } = err.response;
          loginUserFailed(status, data);
        } else {
          ErrorHandler(err, dispatch);
        }
      })
      .finally(() => {
        dispatch({ type: ReduxTypes.GLOBAL_SET_IS_NOT_LOADING });
      });
  };
};

export const confirmUserEmail = (token: string) => {
  return (dispatch: any) => {
    authApi
      .confirmEmail(token)
      .then((result: any) => {
        const { data } = result;
        showSnackbar("Thank you, Your email address has been confirmed", 5000, "success");
        addTokenToLocalStorage(data?.data.access_token);
        loginUserSuccess(dispatch, data?.data);
      })
      .catch((err: any) => {
        ErrorHandler(err, dispatch);
      });
  };
};

export const logOut = () => {
  removeTokenFromLocalStorage();
  return (dispatch: any) => {
    dispatch({ type: ReduxTypes.PURGE });
  };
};

export const getUser = () => {
  return (dispatch: any, getState: any) => {
    return new Promise((resolve, reject) => {
      const user = getState().auth.user;
      if (!user) {
        reject();
      } else {
        resolve(user);
      }
    });
  };
};
const addTokenToLocalStorage = (token: string) => {
  localStorage.setItem(Config.STORAGE.TOKEN, JSON.stringify(token));
};

const removeTokenFromLocalStorage = () => {
  localStorage.removeItem(Config.STORAGE.TOKEN);
};

const loginUserFailed = (status: number, message: string) => {
  if (status === 401) {
    showSnackbar("Username and password not recognised, Do you need to create a new account?", 6000);
  } else if (status === 426) {
    unregister();
    showSnackbar("A new version of Schala is available, please wait...");
    return;
  } else {
    showSnackbar(message);
  }
};

const loginUserSuccess = (dispatch: any, user: User) => {
  dispatch({ type: ReduxTypes.LOGIN_USER_SUCCESS, payload: user });
};
