import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import { useSelector } from "hooks/useSelector";
import { useActions } from "hooks/useActions";

interface SwitchVideoHelpProps {
  label?: string;
  size?: "small" | "medium";
}

const SwitchVideoHelp: React.FC<SwitchVideoHelpProps> = ({ label, size = "medium" }) => {
  const videoHelp = useSelector(state => state.general.videoHelp);
  const { setVideoHelp } = useActions();

  return (
    <FormControlLabel
      control={<Switch size={size} edge="end" checked={videoHelp} onChange={evt => setVideoHelp(evt.target.checked)} />}
      label={label}
    />
  );
};

export default SwitchVideoHelp;
