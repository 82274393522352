import BaseApi from "./Api";

class AdvertsApi extends BaseApi {
  url = "averts";
  constructor() {
    super();
    this.setResourceUrl(this.url);
  }

  uploadImage(id: string, key: string, field: string, image: any) {
    const data = new FormData();
    data.append("image", image);
    data.append("field", field);

    return this.instance({
      method: "post",
      url: `${this.url}/uploadImage/${id}/${key}`,
      data,
    });
  }

  updateState(id: string, state: string) {
    return this.instance({
      method: "patch",
      url: `${this.url}/${id}`,
      data: { state },
    });
  }

  getByGameId(gameId: string) {
    return this.instance({
      method: "get",
      url: `${this.url}/${gameId}`,
    });
  }

  getGameAdvert(gameId: string, type: string) {
    return this.instance({
      method: "get",
      url: `${this.url}/game/${gameId}?type=${type}`,
    });
  }

  getGameAdvertActivities(gameId: string, type: string) {
    return this.instance({
      method: "get",
      url: `${this.url}/activities/${gameId}?type=${type}`,
    });
  }
}

export default new AdvertsApi();
