import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { GlobalState } from "../../../types/globalState";
import { WithStyles } from "@material-ui/core";
import UserActions from "../../../actions/user";
import ClubActions from "../../../actions/clubs";
import MyTable, { Column, TableAction, TableState } from "../../SharedComponents/MyTable";
import ListStyles from "../../../SharedStyles/ListStyle";
import { IndexedUsers, Rules, User, Users } from "../../../types/user";
import { hideDialog, showDialog } from "../../SharedComponents/Dialog";
import Selector, { Option } from "../../SharedComponents/Selector";
import Forbidden from "../../403";
import SearchClub from "../../Search/SearchClub";
import { Club } from "../../../types/club";
import SearchUser from "../../Search/SearchUser";
import { AxiosError } from "axios";
import { ErrorOnlyCallback } from "../../../types/actions";

interface Props extends WithStyles {
  addOrganiser(userId: string, callback?: ErrorOnlyCallback): void;
  removeOrganiser(userId: string, callback?: ErrorOnlyCallback): void;
  updateUser(userId: string, data: any, callback?: any): void;

  fetchUsersList(page: number, limit: number, filter?: any, sort?: string, dir?: string, query?: string): void;

  user: User;
  users: Users[];
  usersObj: IndexedUsers;
  loading: boolean;
  count: number;
}
interface State {
  [inde: string]: any;
}

class UsersListTable extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      openDialog: false,
    };
    this.props.fetchUsersList(1, 50, null, "name");
  }

  prepareData = (items: any) => {
    return items.map((item: User) => ({
      ...item,
    }));
  };

  _onTableChange = (actions: TableAction, state: TableState) => {
    this.props.fetchUsersList(state.page + 1, state.pageSize, undefined, state.sort.field, state.sort.dir, state.query);
  };
  _onAddItem = () => {
    showDialog(
      <SearchUser className={"search-dialog-fix"} minChar={3} onUserSelected={this._handleAddUser} />,
      "md",
      false,
      true,
    );
  };
  _onDeleteItems = (rowsIds: string[]) => {
    rowsIds.forEach((id: string) => {
      this.props.removeOrganiser(id);
    });
  };
  _handleAddUser = (item: any) => {
    if (item) {
      this.props.addOrganiser(item.id, err => !err && this.props.fetchUsersList(1, 50));
      hideDialog();
    }
  };

  _editRuleHandler = (user: any) => {
    const options: Option[] = [
      { value: Rules.Admin, label: "Admin" },
      { value: Rules.Organiser, label: "Organiser" },
      { value: Rules.ClubManager, label: "Club Manager" },
      { value: Rules.Player, label: "Player" },
    ];
    showDialog(
      <Selector
        className={"medium-margin"}
        variant={"outlined"}
        onChange={(rule: string) => this._onUpdateUser(user, { rule })}
        value={user.rule}
        options={options}
      />,
      "md",
      false,
      true,
    );
  };
  _editClubHandler = (user: any) => {
    showDialog(
      <div style={{ margin: 16, marginBottom: 0 }}>
        <SearchClub
          defaultSelectedItem={user.club}
          onClubSelected={(club: Club) => club && this._onUpdateUser(user, { club })}
        />
      </div>,
      "xl",
      false,
      true,
    );
  };
  _onUpdateUser = (user: any, data: any) => {
    this.props.updateUser(user.id, data);
    hideDialog();
  };

  render() {
    const { classes, users, loading, count, user } = this.props;
    const columns: Column[] = [
      {
        field: "name",
        title: "Name",
      },
      {
        field: "username",
        title: "User Name",
      },
      {
        field: "email",
        title: "Email",
      },
      {
        field: "gender",
        title: "Gender",
      },
    ];

    if (user.rule !== Rules.ClubManager) return <Forbidden />;

    return (
      <div className={classes.paper}>
        <MyTable
          title={"Users"}
          loading={loading}
          count={count}
          data={this.prepareData(users)}
          defaultOrderBy={"name"}
          columns={columns}
          searchEnabled
          selectable
          onTableChange={this._onTableChange}
          onAdd={this._onAddItem}
          onDelete={this._onDeleteItems}
        />
      </div>
    );
  }
}

const styles = ListStyles({});
const mapStateToProps = (state: GlobalState) => {
  const { users, count } = state.users;
  const { loading } = state.general;
  const { user } = state.auth;
  return {
    user,
    usersObj: users,
    users: users ? Object.values(users) : [],
    loading,
    count,
  };
};

export default connect(mapStateToProps, {
  fetchUsersList: UserActions.fetch,
  deleteUser: UserActions.delete,
  updateUser: UserActions.updateUser,
  addOrganiser: ClubActions.addOrganiser,
  removeOrganiser: ClubActions.removeOrganiser,
})(withStyles(styles)(UsersListTable));
