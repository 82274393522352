import ReduxTypes from "consts/ReduxTypes";
import { IBeforeInstallPromptEvent } from "types/general";

// export const startLoading = (key: LoadingsTypes) => {
//   return {
//     type: ReduxTypes.START_LOADING,
//     payload: key,
//   };
// };
// export const stopLoading = (key: LoadingsTypes) => {
//   return {
//     type: ReduxTypes.STOP_LOADING,
//     payload: key,
//   };
// };

export const setIsLoading = () => {
  return { type: ReduxTypes.GLOBAL_SET_IS_LOADING };
};
export const setIsNotLoading = () => {
  return { type: ReduxTypes.GLOBAL_SET_IS_NOT_LOADING };
};

export const setInstallPromptEvt = (installEvt: IBeforeInstallPromptEvent | null) => {
  return { type: ReduxTypes.GLOBAL_SET_INSTALL_PROMPT, payload: installEvt };
};

export const setVideoHelp = (status: boolean) => ({ type: ReduxTypes.GLOBAL_SET_VIDEO_HELP, payload: status });
