import React, { Component, ReactElement } from "react";
import { connect } from "react-redux";
import UserActions from "../../actions/user";
import SearchInput, { SearchItem } from "./SearchInput";

interface Props {
  onUserSelected(item: any): void;
  searchUsers(
    callback: any,
    query: string,
    page: number,
    limit: number,
    filter?: any,
    sort?: string,
    dir?: string,
  ): void;
  selectedUsers?: SearchItem[];
  title?: string;
  icon?: ReactElement;
  className?: string;
  defaultSelectedItem?: any;
  dialog?: boolean;
  minChar?: number;
  autoClearText?: boolean;
  style?: any;
}
interface State {
  user?: any;
}

class SearchUser extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      user: props.defaultSelectedItem,
    };
  }

  _userSearchHandler = (query: string) => {
    return new Promise((resolve, reject) => {
      this.props.searchUsers(
        (err: any, result: any) => {
          if (!err) {
            resolve(
              result.data.map((item: any) => ({
                value: item.id,
                title: `${item.name} ${item.lastName}`,
                subtitle: item.username,
                object: item,
              })),
            );
          } else {
            reject(err);
          }
        },
        query,
        1,
        10,
        undefined,
        "name",
        "desc",
      );
    });
  };

  _onUserSelected = (item: SearchItem) => {
    const user = item ? { id: item.value, name: item.title, object: item.object } : null;
    this.props.onUserSelected(user);
  };

  render() {
    const { user } = this.state;
    const selectedUser = user ? { value: user.id, title: user.name, subtitle: user.address } : undefined;

    return (
      <SearchInput
        dialog={this.props.dialog}
        minChar={this.props.minChar ? this.props.minChar : 2}
        label={"Input username"}
        className={this.props.className}
        placeholder={this.props.title || "Click to search users"}
        defaultSelectedItem={selectedUser}
        searchHandler={this._userSearchHandler}
        onItemSelected={this._onUserSelected}
        autoClearText={this.props.autoClearText}
        icon={this.props.icon}
        style={this.props.style}
        selectedItems={this.props.selectedUsers}
      />
    );
  }
}

export default connect(null, {
  searchUsers: UserActions.search,
})(SearchUser);
