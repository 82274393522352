import React, { ErrorInfo } from "react";
import { Button } from "@material-ui/core";
import LoggerApi from "../api/loggerApi";
interface State {
  error?: Error;
  errorInfo?: ErrorInfo;
}

class ErrorBoundary extends React.Component<{}, State> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error, errorInfo });
    const errorLog = {
      name: error.name,
      message: error.message,
      componentStack: errorInfo.componentStack,
    };
    LoggerApi.insertLog("FatalError", errorLog).then(() => {
      console.log("Log reported successfully");
    });
  }

  reload = () => {
    localStorage.clear();
    window.location.replace("/");
  };

  render() {
    if (this.state.errorInfo) {
      return (
        <div className={"center paper card"}>
          <h2>Something went wrong.</h2>
          <Button variant={"contained"} color={"primary"} onClick={this.reload}>
            Reload Schala
          </Button>
        </div>
      );
    }
    return this.props.children;
  }
}
export default ErrorBoundary;
