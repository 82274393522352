import BaseApi from "./Api";

class CompetitionsApi extends BaseApi {
  url = "competitions";
  constructor() {
    super();
    this.setResourceUrl(this.url);
  }

  delete = (id: string, active: boolean = false) => {
    return this.instance({
      method: "delete",
      url: `${this.resourceUrl}/${id}`,
      data: {
        active,
      },
    });
  };
}

export default new CompetitionsApi();
