import React, { useState } from "react";
import { WithStyles, FormControl } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { FixedMobileStepper } from "./MobileStepper";
import Messages from "../../../consts/Messages";
import { TextInput } from "../../SharedComponents/TextInput";

interface Props extends WithStyles {
  onNext: (password: string) => void;
  onPrev: () => void;
  defaults: string;
}

export const Step3 = (props: Props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState(props.defaults);
  const [passErr, setPassErr] = useState("");
  const [cPassword, setCPassword] = useState(props.defaults);
  const [cPassErr, setCPassErr] = useState("");

  return (
    <>
      <div className={props.classes.stepContainer}>
        <p>Now give me a secure password</p>
        <FormControl margin="none" color="primary" required fullWidth>
          <TextInput
            id="password"
            name="password"
            type={showPassword ? "text" : "password"}
            label={"Password"}
            value={password}
            autoComplete="current-password"
            error={passErr.length > 0}
            helperText={passErr}
            disableProfanityCheck
            onChange={(value: string, name: string) => setPassword(value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} onMouseDown={evt => evt.preventDefault()}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <FormControl margin="none" color="primary" required fullWidth>
          <TextInput
            id="password-repeat"
            name="password-repeat"
            type={showPassword ? "text" : "password"}
            label={"Confirmation password"}
            value={cPassword}
            autoComplete="current-password"
            error={cPassErr.length > 0}
            helperText={cPassErr}
            disableProfanityCheck
            onChange={(value: string, name: string) => setCPassword(value)}
          />
        </FormControl>
      </div>
      <FixedMobileStepper
        maxSteps={4}
        step={2}
        onNext={() => {
          if (password.length < 6) {
            setPassErr(Messages.error.passwordShortLength);
            return;
          } else if (password !== cPassword) {
            setCPassErr("Your password and confirmtion password does not match");
          } else {
            props.onNext(password);
          }
        }}
        onPrev={props.onPrev}
      />
    </>
  );
};
