import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { GlobalState } from "../../types/globalState";
import { Edit } from "@material-ui/icons";
import { Avatar, IconButton, WithStyles } from "@material-ui/core";
import UserActions from "../../actions/user";
import MyTable, { Column, TableAction, TableState } from "../SharedComponents/MyTable";
import ListStyles from "../../SharedStyles/ListStyle";
import { User, Rules, Gender, getRuleName } from "../../types/user";
import UserSetup from "./UserSetup";
import { hideDialog, showDialog } from "../SharedComponents/Dialog";
import Selector, { Option } from "../SharedComponents/Selector";
import Forbidden from "../403";
import SearchClub from "../Search/SearchClub";
import Config from "../../config";
import Profile from "../Profile";
import CustomSwitch from "components/SharedComponents/CustomSwitch";
import { getHandicapDisplay } from "utils";

interface Props extends WithStyles {
  updateUser(userId: string, data: any, callback?: any): void;
  deleteUser(id: string): void;
  fetchUsersList(page: number, limit: number, filter?: any, sort?: string, dir?: string, query?: string): void;

  user: any;
  users: any;
  usersObj: any;
  loading: boolean;
  count: number;
}
interface State {
  [inde: string]: any;
}

class UsersListTable extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      openDialog: false,
    };
    this.props.fetchUsersList(1, 50, null, "createDate", "desc");
  }

  getAvatar = (user: any) => {
    let avatar = require("../../assets/images/icons/question_mark_200px.png");

    if (user.gender === Gender.Men) {
      avatar = require("../../assets/images/icons/avatar_men.png");
    } else if (user.gender === Gender.Women) {
      avatar = require("../../assets/images/icons/avatar_women.png");
    } else if (user.rule === Rules.ClubManager) {
      avatar = require("../../assets/images/icons/house_100px.png");
    }

    return avatar;
  };
  prepareData = (items: any[]) => {
    return items
      .map((item: User) => ({
        ...item,
        hcp: getHandicapDisplay(item.hcp),
        name: item.name ? item.name : "",
        lastname: item.lastName ? item.lastName : "",
        homeClub: item.homeClub && item.homeClub.name ? item.homeClub.name : "",
        surn: item.createDate,
        createDate: { isDatetime: true, timestamp: item.createDate }, // ? Moment(item.createDate).format(Config.DATE_DISPLAY_FORMAT) : "",
      }))
      .sort((a: any, b: any) => b.createDate - a.createDate);
  };

  _onTableChange = (actions: TableAction, state: TableState) => {
    this.props.fetchUsersList(state.page + 1, state.pageSize, undefined, state.sort.field, state.sort.dir, state.query);
  };
  _onEditItem = (rowId: string) => {
    showDialog(<Profile otherUser user={this.props.usersObj[rowId]} />, "xl", true, true);
  };
  _onDeleteItems = (rowsIds: string[]) => {
    rowsIds.forEach((id: string) => {
      this.props.deleteUser(id);
    });
  };
  _onAddItem = () => {
    showDialog(<UserSetup />, false, true);
  };
  _editRuleHandler = (user: any) => {
    const options: Option[] = [
      { value: Rules.Admin, label: "Admin" },
      { value: Rules.Organiser, label: "Organiser" },
      { value: Rules.ClubManager, label: "Club Manager" },
      { value: Rules.Player, label: "Player" },
    ];
    showDialog(
      <Selector
        className={"medium-margin"}
        variant={"outlined"}
        onChange={(rule: string) => this._onUpdateUser(user, { rule })}
        value={user.rule}
        options={options}
      />,
      "md",
      false,
      true,
    );
  };
  _editClubHandler = (user: any) => {
    showDialog(
      <div style={{ margin: 16, marginBottom: 0 }}>
        <SearchClub
          defaultSelectedItem={user.club}
          onClubSelected={club => club && this._onUpdateUser(user, { club })}
        />
      </div>,
      "xl",
      false,
      true,
    );
  };
  _onUpdateUser = (user: any, data: any) => {
    this.props.updateUser(user._id, data);
    hideDialog();
  };
  _onDeleteUsers = (rowsIds: string[]) => {
    rowsIds.forEach((id: string) => {
      this.props.deleteUser(id);
    });
  };

  render() {
    const { classes, users, loading, count, user } = this.props;
    const columns: Column[] = [
      {
        field: "avatar",
        title: "",
        handler: (field, item) => (
          <Avatar
            className={classes.avatar}
            style={{ width: 48, height: 48, backgroundColor: "#FFF" }}
            src={Config.API_URL + field}
          >
            <img src={this.getAvatar(item)} width="100%" alt="avatar" />
          </Avatar>
        ),
      },
      {
        field: "name",
        title: "Name",
      },
      {
        field: "lastname",
        title: "Last Name",
      },
      {
        field: "username",
        title: "User Name",
      },
      {
        field: "email",
        title: "Email",
      },
      {
        field: "gender",
        title: "Gender",
      },
      {
        field: "hcp",
        title: "HI",
      },
      {
        field: "cdh",
        title: "CDH",
      },
      {
        field: "homeClub",
        title: "Home Club",
      },
      {
        field: "createDate",
        title: "Register Date",
      },

      {
        field: "rule",
        title: "Account type",
        handler: (field: any, item?: any) => {
          return (
            <>
              <IconButton onClick={() => this._editRuleHandler(item)}>
                <Edit />
              </IconButton>
              {getRuleName(field)}
            </>
          );
        },
      },
      {
        field: "active",
        title: "Active",
        handler: (field: any, item?: any) => {
          return (
            <CustomSwitch
              checked={field}
              onChange={e => {
                this.props.updateUser(item._id, { active: e.target.checked });
              }}
            />
          );
        },
      },
      {
        field: "surn",
        title: "SURN",
      },
      {
        field: "canAddUser",
        title: "Enable add Player",
        handler: (field: any, item?: any) => {
          return item.rule === Rules.Organiser ? (
            <CustomSwitch
              checked={field}
              onChange={e => {
                this.props.updateUser(item._id, { canAddUser: e.target.checked });
              }}
            />
          ) : null;
        },
      },
      {
        field: "club",
        title: "Club",
        handler: (field: any, item?: any) => {
          if (item && item.rule === Rules.ClubManager) {
            return (
              <>
                <IconButton onClick={() => this._editClubHandler(item)}>
                  <Edit />
                </IconButton>
                {field ? field.name : "Unassigned"}
              </>
            );
          }
          return <div />;
        },
      },
    ];

    if (user.rule !== Rules.Admin) return <Forbidden />;
    return (
      <div className={classes.paper}>
        <MyTable
          title={"Users"}
          loading={loading}
          count={count}
          data={this.prepareData(users)}
          columns={columns}
          selectable
          searchEnabled
          onEdit={this._onEditItem}
          onTableChange={this._onTableChange}
          onDelete={this._onDeleteUsers}
          onAdd={this._onAddItem}
        />
      </div>
    );
  }
}

const styles = ListStyles({});
const mapStateToProps = (state: GlobalState) => {
  const { users, count } = state.users;
  const { loading } = state.general;
  const { user } = state.auth;
  return {
    user,
    usersObj: users,
    users: users ? Object.values(users) : [],
    loading,
    count,
  };
};

export default connect(mapStateToProps, {
  fetchUsersList: UserActions.fetch,
  deleteUser: UserActions.delete,
  updateUser: UserActions.updateUser,
})(withStyles(styles)(UsersListTable));
