import { createStyles, Theme, WithStyles } from "@material-ui/core";
import React, { Component, useEffect } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import HoleScoreForm from "./HoleScoreForm/HoleScoreForm";
import GameEntranceForm from "../Game/GameEntranceForm";
import ScorerGameSetup from "./ScorerGameSetup";
import { ComponentSelector, Routes } from "../SharedComponents/ComponentSelector";
import Footer from "./Footer";
import Profile from "../Profile";
import GameSelector from "./GameSelector";
import GameManager from "./GameManager";
import GroupCreation from "../Groups/GroupCreation";
import GroupSelector from "../Groups/GroupSelector";
import ClubsListView from "../Club/ClubsListView";
import GamesTabs from "./GamesTabs";
import { showDialog } from "../SharedComponents/Dialog";
import Intro from "../Intro";
import { decodeToken } from "../../utils/Encryption";
import config from "../../config";
import moment from "moment";
import { useWindowSize } from "../../hooks/useWindowsSize";
import CombinedScoreForm from "./HoleScoreForm/CombinedScoreForm";

interface Props extends WithStyles {
  match: any;
  history: any;
  location: any;
}
export interface RouterProps {
  gameId: string;
  group?: any;
}
export enum Views {
  DEFAULT,
  GameManager,
  ScorerGameSetup,
  ScorerCardView,
  HoleScorerForm,
  CombinedScoreForm,
  Profile,
  GroupCreation,
  GroupSelector,
  CoursesListView,
  ClubsListView,
}

const Dashboard = (dasboardProps: Props) => {
  const routes: Routes = {
    [Views.HoleScorerForm]: (props: RouterProps) => (
      <GameSelector renderContent={(gameId: string) => <HoleScoreForm gameId={gameId} />} />
    ),
    [Views.CombinedScoreForm]: (props: RouterProps) => (
      <GameSelector renderContent={(gameId: string) => <CombinedScoreForm gameId={gameId} />} />
    ),
    [Views.GroupSelector]: (props: RouterProps) => <GroupSelector routerProps={props} />,
    [Views.GroupCreation]: (props: RouterProps) => <GroupCreation routerProps={props} />,
    [Views.GameManager]: (props: RouterProps) => <GameManager routerProps={props} />,
    [Views.ScorerGameSetup]: (props: RouterProps) => <ScorerGameSetup routerProps={props} />,
    [Views.Profile]: (props: RouterProps) => <Profile />,
    [Views.ClubsListView]: (props: RouterProps) => <ClubsListView />,
    [Views.DEFAULT]: (props: RouterProps) => {
      const { gameCode } = dasboardProps.match.params;
      return (
        <>
          <GameEntranceForm gameCode={gameCode} />
          <GamesTabs />
        </>
      );
    },
  };

  const { classes } = dasboardProps;
  const size = useWindowSize();
  const mainHeight = size.height - (size.height > 430 ? 54 : 44);
  const contentHeight = mainHeight - 58;

  useEffect(() => {
    const meetBefore = localStorage.getItem("meetBefore");
    const payload = decodeToken(localStorage.getItem(config.STORAGE.TOKEN));
    //for disabling back button
    window.history.pushState(null, "", dasboardProps.location.href);
    window.onpopstate = function() {
      window.history.go(1);
    };
    if (!meetBefore && moment(payload.createDate).add(10, "m") > moment()) {
      showDialog(<Intro />, "xl", true, true);
      localStorage.setItem("meetBefore", "yes");
    }
  }, []);

  return (
    <main className={classes.main} style={{ height: mainHeight }}>
      <div className={dasboardProps.classes.contents} style={{ height: contentHeight }}>
        <ComponentSelector routes={routes} />
      </div>
      <Footer />
    </main>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    main: {
      display: "flex",
      flexDirection: "column",
      width: "auto",
      minWidth: 300,
      maxWidth: 640,
      [theme.breakpoints.up(550 + theme.spacing(6))]: {
        minWidth: 550,
        width: "fit-content",
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    contents: {
      overflow: "auto",
      display: "flex",
      flexDirection: "column",
      backgroundColor: "white",

      "& > div:last-child, & > div:last-child > div": {
        height: "100%",
      },
    },
  });

export default withStyles(styles)(Dashboard);
