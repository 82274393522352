import { createStyles, Theme } from "@material-ui/core";

const getStyles = (style?: any) => (theme: Theme) =>
  createStyles({
    ...style,
    paper: {
      paddingTop: 1,
      padding: 0,
    },
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    title: {
      fontWeight: "bold",
    },
    inline: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    smallInput: {
      width: 32,
    },
    actionButton: {
      margin: theme.spacing(1),
    },
    addButton: {
      color: "#56b75c",
    },
  });

export default getStyles;
