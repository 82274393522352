import React, { ComponentType, useState } from "react";

import { PresentationFields } from "types/game";
import { useScroll } from "hooks/useScroll";

export interface WithScrollState {
  fields?: PresentationFields;
  containerEl?: HTMLDivElement;
  tableRowEl?: HTMLTableRowElement;
  setPageRefreshVisibility?: (val: boolean) => void;
}

export function withScroll<T>(Component: ComponentType<T>) {
  return (props: Omit<T, "startScroll">) => {
    const [state, setState] = useState<WithScrollState>({});
    useScroll(state?.fields, state.containerEl, state.tableRowEl, state.setPageRefreshVisibility);
    return <Component {...(props as T)} startScroll={setState} />;
  };
}
