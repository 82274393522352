import { Player } from "types/player"

export const sortPlayerToFirst = (userName: string,b: Player) => {
    if (userName === `${b.name} ${b.lastName}`) {
        return 1;
    }

    return -1;
}

export const sortNumberAscending = (a: number,b: number) => {
    if (a < b) {
        return -1;
    }
    if (a > b) {
        return 1;
    }
    return 0;
}