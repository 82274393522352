import React from "react";
import { TableRow, TableCell, TableHead, makeStyles, Theme, TableBody, Dialog } from "@material-ui/core";
import LeaderboardTeamRow from "./LeaderboardTeamRow";
import { PositionsInfo } from "./IndividualLeaderBoard";
import TeamDetails from "./TeamDetails";
import { TeamWithPoints, ExtendedTeam } from "types/Team";
import { Player } from "types/player";
import { Game } from "types/game";
import { PlayingFormats } from "types/competition";
import ScoreCardCtx from "contexts/scoreCardDialog";
import DialogToolbar from "components/SharedComponents/DialogToolbar";
import MatchPlayTeam from "./Matchplay/Team";

interface Props {
  isStroke: boolean;
  teams: TeamWithPoints[];
  game: Game;
  openScoreCardDialog(player: Player, teammates: Player[]): void;
  calculateTeamPosition(row: any, index: number, lastPlayerPoint: number | string): PositionsInfo;
  trRefs: React.RefObject<HTMLTableRowElement[]>;
  isOrganizer: boolean;
  widescreen: boolean;
}

export const prepareData = (teams: TeamWithPoints[], players: Player[]) => {
  return teams
    .map((team: TeamWithPoints) => {
      return {
        ...team,
        color: team.color || "#000",
        players: players.filter(p => p.team?._id === team._id),
        gross: team.points ? team.points.totalGross : 0,
        nett: team.points ? team.points.totalNett : 0,
        pts: team.points ? team.points.totalPoint : 0,
        thru: Object.keys(team.points).filter(p => isFinite(+p)).length, // Calculate number of holes
        // TODO: Update API
      };
    })
    .filter(tm => tm.players.length > 0);
};

export default function TeamLeaderboard(props: Props) {
  const classes = useStyles();
  const [selectedTeam, setSelectedTeam] = React.useState<ExtendedTeam | null>(null);
  const { isStroke, game, isOrganizer, widescreen } = props;
  const records = prepareData(props.teams, Object.values(game.players));
  let lastPlayerPoint = -1;

  if (game.competition.playingRules.playingFormat === PlayingFormats.Matchplay)
    return (
      <ScoreCardCtx.Provider value={{ openScoreCardDialog: props.openScoreCardDialog }}>
        {/* <MatchPlayTeam teams={records} game={game} trRef={props.trRef} /> */}
      </ScoreCardCtx.Provider>
    );

  return (
    <>
      <TableHead>
        <TableRow>
          <TableCell className={classes.headerCell}>Pos</TableCell>
          <TableCell className={classes.headerCell}></TableCell>
          <TableCell className={classes.headerCell}>Name</TableCell>
          <TableCell className={classes.headerCell}>Gross</TableCell>
          <TableCell className={classes.headerCell}>Nett</TableCell>
          {!isStroke && <TableCell className={classes.headerCell}>Pts</TableCell>}
          <TableCell className={classes.headerCell}>Thru</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {records.map((team, index: number) => {
          const info = props.calculateTeamPosition(team, index, lastPlayerPoint);
          lastPlayerPoint = team.pts;
          return (
            <LeaderboardTeamRow
              key={index}
              team={team}
              info={info}
              isStroke={isStroke}
              payBeforePlay={game.paymentRules?.payBeforePlay}
              openScoreCardDialog={props.openScoreCardDialog}
              openTeamDetailsDialog={setSelectedTeam}
              trRefs={props.trRefs}
              isOrganizer={isOrganizer}
              widescreen={widescreen}
              index={index}
            />
          );
        })}
      </TableBody>
      {selectedTeam && (
        <Dialog open className={classes.dialog} onBackdropClick={setSelectedTeam.bind(null, null)} maxWidth="md">
          <DialogToolbar onClick={setSelectedTeam.bind(null, null)} />
          <TeamDetails team={selectedTeam} game={game} />
        </Dialog>
      )}
    </>
  );
}

export const useStyles = makeStyles((theme: Theme) => ({
  headerCell: {
    fontWeight: "bold",
  },
  dialog: {
    "& .MuiDialog-paper": {
      [theme.breakpoints.down("sm")]: {
        margin: "0 !important",
        maxHeight: "100%",
        height: "100%",
        minWidth: "100%",
      },
    },
  },
}));
