import React, { ReactElement } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

type AlertFunType = (
  title: string,
  message: string | ReactElement,
  onAgree?: any,
  onDisagree?: any,
  btnText?: string,
  declineBtnText?: string,
  Component?: React.FC | null,
) => void;

let showAlertDialog: AlertFunType;
interface State {
  open: boolean;
  title: string;
  message: string | ReactElement;
  onAgree?(): void;
  onDisagree?(): void;
  btnText: string;
  declineBtnText: string;
  Component: React.FC | null;
}

class AlertDialog extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      title: "",
      message: "",
      open: false,
      Component: null,
      btnText: "Yes",
      declineBtnText: "No",
      onDisagree: undefined,
    };
  }
  componentDidMount() {
    showAlertDialog = this.showDialog;
  }

  showDialog: AlertFunType = (
    title,
    message,
    onAgree,
    onDisagree,
    btnText = "Yes",
    declineBtnText = "No",
    Component = null,
  ) => {
    this.setState({
      open: true,
      title,
      message,
      onAgree,
      Component,
      btnText,
      declineBtnText,
      onDisagree: onDisagree ? onDisagree : undefined,
    });
  };

  closeDialog = () => {
    this.setState({ open: false });
  };
  _onDisagree = () => {
    if (this.state.onDisagree) this.state.onDisagree();
    this.closeDialog();
  };
  _onAgree = () => {
    if (this.state.onAgree) this.state.onAgree();
    this.closeDialog();
  };

  render() {
    const { Component } = this.state;

    return (
      <div>
        <Dialog open={this.state.open} aria-labelledby={this.state.title}>
          <DialogTitle>{this.state.title}</DialogTitle>
          <IconButton
            onClick={this.closeDialog}
            style={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <DialogContentText style={{ minWidth: 300 }}>
              {Component ? <Component /> : this.state.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {this.state.onDisagree && (
              <Button onClick={this._onDisagree} style={{ color: "red" }}>
                {this.state.declineBtnText}
              </Button>
            )}
            <Button onClick={this._onAgree} autoFocus style={this.state.onDisagree ? { color: "green" } : {}}>
              {this.state.onDisagree ? this.state.btnText : "OK"}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export { showAlertDialog };
export default AlertDialog;
