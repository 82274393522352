import dataURItoBlob from "./dataURItoBlob";
//@ts-ignore
import * as PDFJS from "pdfjs-dist/es5/build/pdf";
import { PDFDocumentProxy, PDFPageProxy } from "pdfjs-dist";
PDFJS.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.js`;

export const pdfToImage = async (blob: any): Promise<string[] | null> => {
  let output: any[] = [];
  const pdf = await PDFJS.getDocument({ data: blob }).promise;
  if (!(pdf as PDFDocumentProxy)) {
    console.log("PDFJS.getDocument Error", pdf);
    return null;
  }
  for (let i = 0; i < pdf.numPages; i++) {
    const page = await pdf.getPage(i + 1);
    if (!(page as PDFPageProxy)) {
      console.log("pdf.getPage Error", page);
      continue;
    }
    const scale = 1.5;
    const viewport = page.getViewport({ scale });
    const canvas: any = document.createElement("canvas");
    canvas.setAttribute("id", `canvas-${i}`);
    const context = canvas.getContext("2d");
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    await page.render({ canvasContext: context, viewport: viewport }).promise;
    output.push(dataURItoBlob(canvas.toDataURL("image/jpeg")));
  }
  return output;
};
