import { Action } from "../types/actions";
import ReduxTypes, { Keys } from "../consts/ReduxTypes";
import { deleteReducer, fetchReducer, insertReducer, updateReducer } from "./BaseReducerFunctions";
import { AdvertsState } from "../types/advert";

const INITIAL_STATE = {
  adverts: {},
  gamesAd: {},
  count: 0,
};
const KEY: Keys = "ADVERTS";

export default (state: AdvertsState = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case ReduxTypes.getInsertType(KEY):
      return insertReducer(state, action, "adverts");

    case ReduxTypes.getFetchType(KEY):
      return fetchReducer(state, action, "adverts");

    case ReduxTypes.getDeleteType(KEY):
      return deleteReducer(state, action, "adverts");

    case ReduxTypes.getUpdateType(KEY):
      return updateReducer(state, action, "adverts");

    case ReduxTypes.GET_ADVERT_BY_GAME_ID:{
      let gamesAd = { ...state.gamesAd };
      if(!gamesAd[action.id]) gamesAd[action.id] = { ...gamesAd[action.id], ...action.payload };
      return { ...state, gamesAd };
    }

    case ReduxTypes.SET_ADVERT_SEEN: {
      let gamesAd = { ...state.gamesAd };
      gamesAd[action.id].holesInfo[action.holeNumber].seen = true;
      return { ...state, gamesAd };
    }
    case ReduxTypes.PURGE:
      return INITIAL_STATE;

    default:
      return state;
  }
};
