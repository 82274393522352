import React from "react";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

import { HoleSigns } from "types/hole";
import { useStyles } from "./styles/grossPicker";

interface GrossPickerProps {
  onSelect: (gross: HoleSigns | number | null) => void;
  dnfGrid?: boolean;
}

const NUMBERS = [
  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
  [25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36],
];

const GrossPicker: React.FC<GrossPickerProps> = ({ onSelect, dnfGrid }) => {
  const classes = useStyles();
  const [index, setIndex] = React.useState<number>(0);

  return (
    <Grid container className={`${classes.root} ${dnfGrid ? "dnf" : ""}`}>
      <Grid item md={12}>
        {dnfGrid ? (
          <>
            <Typography variant="h6" style={{ marginBottom: 10 }}>
              Select number of shots played before retiring.
            </Typography>
            <Typography>All remaining holes will be marked as DNP. Use PU button for a general Pick Up.</Typography>
          </>
        ) : (
          <IconButton onClick={() => onSelect(null)}>
            <Avatar className={classes.clearButtonAvatar}>CLEAR SCORE</Avatar>
          </IconButton>
        )}
      </Grid>

      {NUMBERS[index].map((gross: number) => (
        <Grid item md={3} key={gross}>
          <IconButton onClick={() => onSelect(gross)}>
            <Avatar className={classes.avatar}>{gross}</Avatar>
          </IconButton>
        </Grid>
      ))}
      <Grid item md={3}>
        <IconButton onClick={() => onSelect(HoleSigns.PU)}>
          <Avatar className={classes.avatar2}>PU</Avatar>
        </IconButton>
      </Grid>
      {!dnfGrid && (
        <>
          <Grid item md={3}>
            <IconButton onClick={() => onSelect(HoleSigns.DNF)}>
              <Avatar className={classes.avatar2}>DNF</Avatar>
            </IconButton>
          </Grid>
          <Grid item md={3}>
            <IconButton onClick={() => onSelect(HoleSigns.DNP)}>
              <Avatar className={classes.avatar2}>DNP</Avatar>
            </IconButton>
          </Grid>
        </>
      )}
      {index < 2 && (
        <Grid item md={3}>
          <IconButton onClick={() => setIndex(prevIndex => prevIndex + 1)}>
            <Avatar className={classes.avatar}>{index === 0 ? "13+" : "25+"}</Avatar>
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};

export default GrossPicker;
