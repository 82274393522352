import { Action } from "../types/actions";
import ReduxTypes from "../consts/ReduxTypes";

const INITIAL_STATE = {
  currentView: 0,
  props: {},
};

export default (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case ReduxTypes.CHANGE_VIEW: {
      if (action.props) {
        return { ...state, currentView: action.view, props: action.props };
      }
      else {
        return { ...state, currentView: action.view };
      }
    }
    case ReduxTypes.SET_VIEW_STATE:
      return { ...state, props: action.props };

    case ReduxTypes.PURGE:
      return INITIAL_STATE;

    default:
      return state;
  }
};
