import BaseApi from "./Api";

class ScoresApi extends BaseApi {
  url = "scores";
  constructor() {
    super();
    this.setResourceUrl(this.url);
  }

  calculateScores(gameId: string | undefined, scorecardId: string, holes: any[], hcp: number | undefined) {
    let grosses = "";
    holes.forEach(hole => {
      grosses += `grosses=${hole.gross}&`;
    });

    return this.instance({
      method: "get",
      url: `${this.url}/?gameId=${gameId}&scorecardId=${scorecardId}&hcp=${hcp}&${grosses}`,
      data: {},
    });
  }
}

export default new ScoresApi();
