import React from "react";
import Link from "@material-ui/core/Link";

interface PolicyLinkProps {
  startText?: string;
}

const PolicyLink: React.FC<PolicyLinkProps> = ({ startText }) => {
  return (
    <>
      {`${startText} to indicate that you have read and agree to the terms of the`}
      <Link href="https://schalagolf.com/Privacy_Notice.html" target="_blank" rel="noopener">
        &nbsp;Schala GDPR Policy
      </Link>
    </>
  );
};

PolicyLink.defaultProps = {
  startText: "Click accept",
};

export default PolicyLink;
