
import httpService from './httpService'

class BaseApi {

  instance: any = httpService.instance;
  resourceUrl: string = '';
  setResourceUrl(subUrl : string)
  {
    this.resourceUrl = subUrl;
  }

  copy = (id: string, data: any) => {
    return this.instance({
      method: "post",
      url: `${this.resourceUrl}/${id}/copy`,
      data,
    });
  };

  insert = (data: any) => {
    return this.instance({
      method: "post",
      url: `${this.resourceUrl}/`,
      data,
    });
  };

  get = (
    page: number,
    limit: number,
    filter?: any,
    sort?: string,
    dir?: string,
    query?: string,
  ) => {
    let url = `/?page=${page}&limit=${limit}`;
    if (sort) {
      if (dir) {
        url += `&sort=${sort}:${dir}`;
      } else {
        url += `&sort=${sort}:asc`;
      }
    }
    if (query) {
      url += `&query=${query}`;
    }
    if (filter) {
      url += `&filter=${JSON.stringify(filter)}`;
    }
    url = this.resourceUrl + url;

   return this.instance({
      method: "get",
      url,
    });
  };

  search = (
    query: string,
    page: number,
    limit: number,
    filter?: any,
    sort?: string,
    dir?: string,
  ) => {
    let url = `/search/?query=${query}&page=${page}&limit=${limit}`;
    if (sort) {
      if (dir) {
        url += `&sort=${sort}:${dir}`;
      } else {
        url += `&sort=${sort}:asc`;
      }
    }
    if (filter) {
      url += `&filter=${JSON.stringify(filter)}`;
    }
    url = this.resourceUrl + url;

    return this.instance({
      method: "get",
      url
    });
  };

  update = (id: string, data: any) => {
    return this.instance({
      method: "put",
      url: `${this.resourceUrl}/${id}`,
      data,
    });
  };

  delete = (id: string) => {
    return this.instance({
      method: "delete",
      url: `${this.resourceUrl}/${id}`,
    });
  };

}

export default BaseApi;
