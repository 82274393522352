import { ScoreCard } from "./scoreCard";
import { Competition } from "./competition";
import { Tournament } from "./tournament";
import { UserBrifObj } from "./user";
import { Player } from "./player";
import { Team } from "./Team";
import { Course } from "./course";

export interface Game {
  _id: string;
  course: Course;
  code: string;
  name: string;
  date: Date;
  startTime: Date;
  endTime: Date;
  tournaments: Tournament[];
  competition: Competition;
  players: { [index: string]: Player };
  scoreCards: ScoreCard[];
  teams: Team[];
  autoProgress: boolean;
  organizers: UserBrifObj[];
  organiser?: UserBrifObj;
  linkedGames: string[];
  state: GameStateTypes;
  groups: string[];
  leaderboard?: Leaderboard;
  startSheet?: PresentationFields;
  maxPlayers: number;
  isPaid: boolean;
  paymentRules: PaymentRules;
  owner: string;
  pot: Pot;
  startingTees: number[];
  gameResultRules?: GameResultRules;
  verifyQs: boolean;
}

export enum SameScoresType {
  WIN = "win",
  LOSS = "loss",
}
export interface GameResultRules {
  toFinish: boolean;
  toResult: boolean;
  toLastHole: boolean;
  extraHoles: number[];
  highlightTarget?: boolean;
  sameScores?: SameScoresType;
}

export enum GState {
  WaitingList = "waitingList",
  Playing = "playing",
  Done = "done",
}

export type GameStateTypes = GState.WaitingList | GState.Playing | GState.Done;

export enum OrganisersType {
  Owner = "game-owner",
  ClubManager = "club-manager",
}

export interface PresentationFields {
  widescreen: boolean;
  scroll: boolean;
  continuous?: boolean;
  rowsPerPage?: number;
  scrollSpeed?: number;
  firstPageHoldTime?: number;
  lastPageHoldTime?: number;
  pagesHoldTime?: number;
}

export interface Leaderboard extends PresentationFields {
  activateStatus: number;
  presentationMode: boolean;
  hideUntil: number;
  revealIndex?: number;
  reverseHiddenPlace: boolean;
  revealPlaceStatus: number;
  showInTwoSteps?: boolean;
  scoresDisplayOrder: boolean;
}

export interface IndexedGames {
  [index: string]: Game;
}

export interface GameState {
  games: IndexedGames;
  count: number;
  joinedGames: IndexedGames;
  visitedLeaderBoard: IndexedGames;
  history: {
    items: IndexedGames;
    count: number;
  };
  organisedByMe: IndexedGames;
  tournaments: Tournament[];
  players: Player[];
  isLoading: boolean;
}

export interface PaymentRules {
  type: PaymentType;
  fee: number;
  payBeforePlay: boolean;
}

export enum PaymentType {
  MULTIPLE_PLAYER = 1,
  TEAM = 2,
}

export enum PaymentRuleFields {
  TYPE = "type",
  FEE = "fee",
  PAY_BEFORE_PLAY = "payBeforePlay",
}

export interface Pot {
  id: string;
  url: string;
}
