import * as React from "react";
import { Button, Dialog, DialogContent, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";

import { useInstallPrompt } from "hooks/useInstallPrompt";

window.addEventListener("appinstalled", e => {
  console.log("app installed");
  localStorage.setItem("installed", "true");
});

export const AddToHomeScreenDialog = () => {
  const prompt = useInstallPrompt();
  const [isVisible, setVisibleState] = React.useState(false);
  const hide = () => setVisibleState(false);

  const install = () => {
    if (prompt) prompt.prompt();
    hide();
  };
  React.useEffect(() => {
    if (prompt) {
      setVisibleState(true);
    }
  }, [prompt]);
  return (
    <Dialog open={isVisible}>
      <DialogContent>
        <strong>Add to Homescreen.</strong>
        <p>Would you like to install Schala in Homescreen.</p>
        <Button color={"primary"} variant={"outlined"} onClick={install} style={{ marginRight: 16 }}>
          Yes install
        </Button>
        <Button color={"secondary"} variant={"outlined"} onClick={hide}>
          No
        </Button>
      </DialogContent>
    </Dialog>
  );
};
export const AddToHomeScreenBanner = () => {
  const prompt = useInstallPrompt();
  const [isVisible, setVisibleState] = React.useState(false);
  const hide = () => setVisibleState(false);

  const install = () => {
    if (prompt) prompt.prompt();
    hide();
  };
  React.useEffect(() => {
    if (prompt) {
      setVisibleState(true);
    }
  }, [prompt]);
  return (
    <div className={"banner"} style={{ display: isVisible ? "flex" : "none" }}>
      <IconButton onClick={hide}>
        <Close style={{ color: "#FFF" }} />
      </IconButton>
      <div style={{ marginRight: 4, marginLeft: 4 }}>
        <strong style={{ color: "#FFF" }}>Schala</strong>
        <p className={"no-margin"} style={{ color: "#FFF" }}>
          Get our free app. it won't take up space on your phone
        </p>
      </div>
      <Button variant={"outlined"} onClick={install}>
        Install
      </Button>
    </div>
  );
};
