import React from "react";
import Moment from "moment";
import _ from "lodash";
import { Button, createStyles, IconButton, Theme, withStyles, WithStyles } from "@material-ui/core";
import { Game, IndexedGames } from "../../../types/game";
import homeIcon from "../../../assets/images/icons/house_100px.png";
import { Gender } from "../../../types/user";
interface Props extends WithStyles {
  gameId: string;
  games: IndexedGames;
}

const StartSheetHeader = (props: Props) => {
  const { classes, gameId, games } = props;
  const game = games[gameId];
  let linkedGames: Game[] = [];
  game.linkedGames.forEach(gameId => linkedGames.push(games[gameId]));
  if (linkedGames.length === 0) linkedGames.push(game);

  return (
    <div className={classes.container}>
      <div className={"row flex-start space-between normal-margin"}>
        <div style={{ flex: 1 }}>
          <p className={classes.title}>{game.course.club.name}</p>
          <p className={classes.title}>{game.course.name}</p>
          {linkedGames.map(game => (
            <div className={"row"}>
              <p className={classes.title}>{game.name}</p>
              <p className={classes.title}>{game.code}</p>
            </div>
          ))}
          <p className={classes.subtitle}>{Moment(game.date).format("LLL")}</p>
        </div>
        <div style={{ flex: 0, height: "100px" }}>
          <img src={homeIcon} />
        </div>
      </div>
      <div className={"col normal-margin"}>
        {Object.values(game.scoreCards).map(scoreCard => (
          <p className={classes.subtitle}>
            <strong>Tee ({scoreCard.gender === Gender.Men ? "M" : "L"})</strong> ({scoreCard.teeColor}), (
            {scoreCard.courseRating}), ({scoreCard.slope})
            {` Yds ${scoreCard.holesParams.reduce(
              (total: number, hole) => total + hole.yards,
              0,
            )} Par ${scoreCard.holesParams.reduce((total: number, hole) => total + hole.par, 0)}`}
          </p>
        ))}
      </div>
    </div>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    container: {},
    title: {
      margin: 4,
      flex: 1,
      fontSize: 16,
      fontWeight: "bold",
    },
    subtitle: {
      margin: 4,
      flex: 1,
      fontSize: 14,
      color: "#5c5c5c",
    },
  });

export default withStyles(styles)(StartSheetHeader);
