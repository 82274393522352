import { Badge, Table, TableCell, TableRow } from "@material-ui/core";
import React from "react";
import { Game } from "../../../types/game";
import { Player, UHole } from "../../../types/player";
import { User } from "../../../types/user";
import { getHandicapDisplay } from "utils";

interface Props {
  game: Game;
  user: User;
}

export const CombinedGamesHolesStarterPlayers = (props: Props) => {
  const me = props.game.players[props.user._id];
  const players = Object.values(props.game.players)
    .filter(player => player.team && me.team && player.team._id === me.team._id)
    .sort((a, b) => (`${a.name} ${a.lastName}` > `${b.name} ${b.lastName}` ? 1 : -1));
  return (
    <Table>
      <TableRow>
        {players.map(
          player =>
            !player.isTeamCard && (
              <TableCell>
                {player.name} {player.lastName} <br /> PH: {getHandicapDisplay(player.hcp)} HI:{" "}
                {Number(player.hci).toFixed(1)}
              </TableCell>
            ),
        )}
      </TableRow>
      <TableRow>
        {players.map(player => (
          <TableCell>
            {player.uholes && (
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {Object.values(player.holes)
                  .filter(holes => holes.gross)
                  .map(uhole => uhole.holeNumber)
                  .join(", ")}
              </div>
            )}
          </TableCell>
        ))}
      </TableRow>
    </Table>
  );
};
