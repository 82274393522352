import React, { useState } from "react";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useStyles } from "./styles";

export interface TextEditorProps {
  onContentChange: (content: string) => void;
  html?: string;
}

const TextEditor: React.FC<TextEditorProps> = ({ html = "", onContentChange }) => {
  const classes = useStyles();
  const [editorState, setEditorState] = useState(() => {
    const contentBlock = htmlToDraft(html);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    return EditorState.createWithContent(contentState);
  });

  const handleEditorChange = (state: EditorState) => {
    setEditorState(state);
    const html = draftToHtml(convertToRaw(state.getCurrentContent()));
    onContentChange(html);
  };

  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={handleEditorChange}
      toolbar={{
        options: ["inline", "blockType", "fontSize", "list", "textAlign", "colorPicker", "link", "history"],
      }}
      placeholder="Start typing..."
      wrapperClassName={classes.wrapper}
      editorClassName={classes.editor}
      toolbarClassName={classes.toolbar}
    />
  );
};

export default TextEditor;
