import { ListItemText } from "@material-ui/core";
import React from "react";
import Moment from "moment";
import { Game } from "../../types/game";
import { ScoreCard } from "../../types/scoreCard";
import { Gender } from "../../types/user";

interface GameDetailsProps {
  game: Game;
  hideTeeInfo?: boolean;
}

export const getTeeDetails = (game: Game): string[] => {
  let output: string[] = [];
  const cards: ScoreCard[] = Object.values(game.scoreCards);
  cards.forEach(card => {
    let parts = [];
    const totalPar = card.holesParams.reduce((total: number, param) => total + param.par || 0, 0);
    parts.push(card.teeColor);
    if (card.courseRating) parts.push(`CR: ${card.courseRating}`);
    parts.push(`SL: ${card.slope}`);
    parts.push(`Par: ${totalPar}`);
    if (card.gender === Gender.Men) {
      output.push(`Men (${parts.join(", ")})`);
    } else {
      output.push(`Ladies (${parts.join(", ")})`);
    }
  });
  return output;
};

export const GameDetails = (props: GameDetailsProps) => {
  const game = props.game;
  const date = Moment(game.date);
  const scoreCard: ScoreCard = Object.values(game.scoreCards)[0];
  return (
    <div>
      <ListItemText className={"fullWidth"} primary={`${game.name}, ${game.code}`} />
      <ListItemText className={"fullWidth"} secondary={`${scoreCard?.course.name}, ${scoreCard?.course.club?.name}`} />
      <ListItemText className={"fullWidth"} secondary={`${date.format("ddd DD/MM/YY hh.mm")}`} />
      <ListItemText
        className={"fullWidth"}
        secondary={`${
          game.competition?.playingRules && game.competition.playingRules.playingAs === "team" ? "Team" : "Individual"
        }, ${scoreCard?.course.holesNumber} Holes${
          game.competition?.fullHandicapAllowance ? `, PH = CH x ${game.competition.fullHandicapAllowance}%` : ""
        }`}
      />
      {!props.hideTeeInfo && (
        <div className={"row wrap"}>
          {getTeeDetails(game).map((detail, i) => (
            <ListItemText key={i} className={"fullWidth no-margin"} secondary={detail} />
          ))}
        </div>
      )}
    </div>
  );
};
