import * as React from "react";
import BorderedBox from "../SharedComponents/BorderedBox";
import { Switch, Typography, makeStyles, Theme, Input, TextField } from "@material-ui/core";
import { ExpireRules } from "../../types/competition";
import { HELP_VIDEOS } from "consts";

export interface HandicapsExpireRulesProps {
  expireRules?: ExpireRules;
  onHandicapExpireRulesChanged: (value: ExpireRules) => void;
  error: boolean;
}

const HandicapsExpireRules: React.FunctionComponent<HandicapsExpireRulesProps> = (props: HandicapsExpireRulesProps) => {
  const classes = useStyles();
  const { expireRules } = props;

  const onTextFieldChanged = (field: string) => (event: any) => {
    props.onHandicapExpireRulesChanged({
      ...props.expireRules,
      [field]: Number.parseInt(event.target.value),
    } as ExpireRules);
  };

  const onSwitchChanged = (field: string, event: React.ChangeEvent<HTMLInputElement>) => {
    props.onHandicapExpireRulesChanged({
      ...props.expireRules,
      [field]: event.target.checked as boolean,
    } as ExpireRules);
  };

  return (
    <BorderedBox
      title={"Handicap Expiry Rules"}
      videoIcon
      videoURL={HELP_VIDEOS.EXPIRY_RULES}
      variant={"h6"}
      error={props.error}
    >
      <div className={classes.inline}>
        <Typography variant={"subtitle1"}>Do you allow Handicaps to expire:</Typography>
        <Switch
          name="isActive"
          value={expireRules?.isActive}
          checked={expireRules?.isActive}
          onChange={e => onSwitchChanged(e.target.name, e)}
        />
      </div>
      {expireRules?.isActive ? (
        <>
          <div>
            {" "}
            <label>A player custom handicap will expire if:</label>
          </div>
          <br />
          <div className={classes.inline}>
            <Typography variant={"subtitle1"}>More than </Typography>
            <Input
              className={classes.smallInputs}
              type={"number"}
              inputProps={{ min: 0 }}
              value={expireRules?.minMonths}
              onChange={onTextFieldChanged("minMonths")}
            />
          </div>
          <Typography variant={"subtitle1"}> months passes since his/her last game played. </Typography>
          <b> OR</b>
          <div className={classes.inline}>
            <Typography variant={"subtitle1"}>Less than </Typography>
            <Input
              type={"number"}
              inputProps={{ min: 0 }}
              className={classes.smallInputs}
              value={expireRules?.minGames}
              onChange={onTextFieldChanged("minGames")}
            />
          </div>
          <div className={classes.block}>
            <Typography variant={"subtitle1"}>games have been played in a</Typography>
            <TextField
              type={"number"}
              style={{ paddingLeft: 15 }}
              inputProps={{ min: 0 }}
              className={classes.smallInputs}
              value={expireRules?.duringMonths}
              onChange={onTextFieldChanged("duringMonths")}
            />
            <Typography variant={"subtitle1"}> month period. </Typography>
          </div>
        </>
      ) : null}
    </BorderedBox>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  inline: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  smallInputs: {
    width: 50,
  },
  block: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "end",
  },
}));

export default HandicapsExpireRules;
