import React, { Component } from "react";
import { Theme, WithStyles, createStyles } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { CircularProgress, Button, Typography, Box } from "@material-ui/core";
import PaymentAction from "../../actions/payments";
import { PaymentSessionRes } from "types/payment";
import { hideAuxiliaryDialog } from "components/SharedComponents/AuxiliaryDialog";
import { hideDialog, showDialog } from "components/SharedComponents/Dialog";
import GameNavigationDialog from "components/Game/GameNavigationDialog";
import GameActions from "../../actions/games";
import { AxiosError } from "axios";
import { Game } from "types/game";

interface Props extends WithStyles {
  payment: PaymentSessionRes;
  verifyPayment(id: string, data: any, callback?: any): void;
  description: string;
  getGameById(
    id: string,
    queryType?: string,
    updateRedux?: boolean,
    callback?: (err: AxiosError | null, data: Game) => void,
  ): void;
}

class PaymentCallback extends Component<Props> {
  state = {
    isVerifying: false,
  };

  handleVerifyPayment = () => {
    this.setState({ isVerifying: true });
    this.props.verifyPayment(
      this.props.payment.id,
      { ...this.props.payment.paymentSession, description: this.props.description },
      (err: any) => {
        // if (!err) {
        // } else {
        // }
        this.setState({ isVerifying: false });
      },
    );
  };

  componentDidMount() {
    this.handleVerifyPayment();
  }

  render() {
    const { classes, payment } = this.props;
    const { isVerifying } = this.state;

    return (
      <Box className={classes.container}>
        <Typography variant="h4" gutterBottom>
          Payment Successful!
        </Typography>
        <Typography variant="h6" gutterBottom>
          Payment Approved, we will send an email after payment is captured.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Please click the button below to proceed.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            hideAuxiliaryDialog();
            hideDialog();
            const gameId = payment.paymentSession?.metadata?.game;
            if (payment && gameId) {
              this.props.getGameById(gameId, undefined, true);
              setTimeout(() => {
                showDialog(<GameNavigationDialog gameId={gameId} />, "xs", true, true, true);
              }, 500);
            }
          }}
          disabled={isVerifying}
          style={{ width: "100%" }}
        >
          {isVerifying ? <CircularProgress size={24} /> : "Proceed to Game"}
        </Button>
      </Box>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      width: 400,
      padding: theme.spacing(4),
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      borderRadius: theme.shape.borderRadius,
      textAlign: "center",
    },
  });

export default connect(null, {
  getGameById: GameActions.getById,
  verifyPayment: PaymentAction.verifyPayment,
})(withStyles(styles)(PaymentCallback));
