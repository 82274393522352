import { Button, createStyles, Paper, Tab, Tabs, Theme, WithStyles } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import SwipeableViews from "react-swipeable-views";
import React, { Component } from "react";
import { connect } from "react-redux";
import { GlobalState } from "../../types/globalState";
import { RouterProps } from "./index";
import * as socket from "../../socket";
import GameActions from "../../actions/games";
import { Game, IndexedGames } from "../../types/game";

interface Props extends WithStyles {
  setUpdatedResult(game: any): void;
  setGameFinished(gameId: string): void;
  renderContent(gameId: string): any;
  routerProps: RouterProps;
  joinedGames: IndexedGames;
}
interface State {
  gameIndex: number;
  offlineMode: boolean;
}

class Dashboard extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      gameIndex: 0,
      offlineMode: false,
    };
  }

  onScoresUpdated = (result: any) => this.props.setUpdatedResult(result);

  onPlayersChanged = (result: any) => this.props.setUpdatedResult(result);

  componentDidMount(): void {
    this.getGames().forEach((game: any) => {
      this.connectToWebsocket(game?._id);
    });
  }

  componentWillUnmount() {
    socket.disconnect();
  }

  reconnect = () => {
    this.getGames().forEach((game: any) => {
      this.connectToWebsocket(game._id);
    });
  };

  connectToWebsocket = (gameId: any) => {
    if (socket.connect(gameId)) {
      socket.addEvent(socket.EVENTS.SCORES_UPDATED, this.onScoresUpdated);
      socket.addEvent(socket.EVENTS.PLAYERS_CHANGED, this.onPlayersChanged);
    } else this.setState({ offlineMode: true });
  };

  _onTabChanged = (event: object, value: any) => {
    this.setState({ gameIndex: value });
  };

  _onChangeSwipableIndex = (gameIndex: number) => {
    this.setState({ gameIndex });
  };

  getGames = () => {
    const { joinedGames } = this.props;
    const joinedGamesValues: any[] = Object.values(joinedGames);
    let { gameId } = this.props.routerProps;
    if (!gameId && joinedGamesValues.length > 0) gameId = joinedGamesValues[0]._id as string;

    let games: Game[] = [];
    if (joinedGames[gameId] && joinedGames[gameId].linkedGames) {
      joinedGames[gameId].linkedGames.forEach((id: string) => {
        games.push(joinedGames[id]);
      });
    }
    if (games.length === 0) games.push(joinedGames[gameId]);
    return games;
  };
  render() {
    const { gameIndex, offlineMode } = this.state;
    let { gameId } = this.props.routerProps;
    const games = this.getGames();
    const game = games.find(x => x?._id === gameId) || games[0];
    const name = games?.length > 1 ? `Linked (${games.map(game => game.name).join(", ")})` : game?.name;
    const gamesKeys = games && games.length > 0 ? games?.map((g: any) => g?._id) : [];
    const gameNum = gamesKeys?.length ?? 0;

    if (gameNum > 0) {
      return (
        <>
          {offlineMode && (
            <Button color="secondary" variant={"contained"} fullWidth onClick={this.reconnect}>
              Reconnect
            </Button>
          )}
          <Paper square>
            <Tabs
              variant={gameNum < 6 ? "fullWidth" : "scrollable"}
              indicatorColor="primary"
              textColor="primary"
              value={gameIndex}
              onChange={this._onTabChanged}
            >
              <Tab key={game?._id} label={name} value={gamesKeys.indexOf(game?._id) as number} />
            </Tabs>
          </Paper>
          <SwipeableViews enableMouseEvents index={gameIndex} onChangeIndex={this._onChangeSwipableIndex}>
            {this.props.renderContent(game?._id)}
          </SwipeableViews>
        </>
      );
    } else {
      return <p>You joined no game</p>;
    }
  }
}

const styles = (theme: Theme) => createStyles({});
const mapStateToProps = (state: GlobalState) => {
  const { joinedGames } = state.games;
  const { props } = state.router;
  return {
    routerProps: props,
    joinedGames,
  };
};

export default connect(mapStateToProps, {
  setUpdatedResult: GameActions.setUpdatedResult,
  setGameFinished: GameActions.setGameFinished,
})(withStyles(styles)(Dashboard));
