import * as React from "react";
import { Grid, Card, CardMedia } from "@material-ui/core";
import { Advert } from "./../../types/advert";
import Config from "../../config";
import { showAdvert } from "../SharedComponents/AdvertDisplayer";

export interface AdvertsThimbnailsProps {
  adverts: Advert;
  gameId : string;
}

const AdvertsThimbnails: React.SFC<AdvertsThimbnailsProps> = props => {
  
  const advertisments = Object.values(props.adverts.holesInfo);
  const handleAdvertClick = (holeNumber: number) => {
    const { gameId } = props;
    showAdvert(gameId,  props.adverts, holeNumber, true, false);
  };

  return (
    <div style={{ width: "90%", margin: "auto", padding: 10, textAlign: "center" }}>
      <h2>Advertisment</h2>
      <Grid container spacing={2}>
        {advertisments.map((ads: any, key: number) => {
          return (
            <Grid item md={4} xs={4} key={key}>
              <Card>
                <CardMedia
                  onClick={() => {
                    handleAdvertClick(ads.holeNumber);
                  }}
                  style={{ cursor: "pointer" }}
                  component="img"
                  alt="Schala Advertisement"
                  height="120"
                  image={Config.API_URL + ads.image}
                />
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default AdvertsThimbnails;
