import React, { ChangeEvent } from "react";
import {
  createStyles,
  Grid,
  IconButton,
  Button,
  Switch,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Theme,
} from "@material-ui/core";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { TightBodyCell, TightHeaderCell } from "../../SharedComponents/CustomTableElements";
import { Game } from "../../../types/game";
import { ScoreCard } from "../../../types/scoreCard";
import { GolfCourse } from "@material-ui/icons";
import { Gross } from "./Gross";
import GameActions from "../../../actions/games";
import { connect } from "react-redux";
import { showDialog } from "../../SharedComponents/Dialog";
import ModernScoreCardView from "../../ScoreCard/ModernScoreCardView";
import { ErrorOnlyCallback } from "../../../types/actions";
import { User } from "../../../types/user";
import { GlobalState } from "../../../types/globalState";
import ScoringTableActionButton from "./ScoringTableActionButton";
import { Player } from "../../../types/player";
import { showAlertDialog } from "../../SharedComponents/AlertDialog";
import { isGameStandard, getHandicapDisplay } from "utils";
import Config from "config";
import PlayerName from "components/SharedComponents/PlayerName";

interface Props extends WithStyles {
  index: Number;
  game: Game;
  player: Player;
  holeIndex: number;
  scoring: boolean;
  scoreCard: ScoreCard;
  user: User;
  combined?: boolean;
  openPickerDialogForMisMatchHole(player: any, holeNumber: number): void;
  handleGrossChange(gameId: string, player: Player, holeNumber: number, gross: any, dnfSelected: boolean): void;
  handleVerifyPlayer(playerId: string): void;
  grossFinalCheck(player: Player): number | void;
  onOpenScorerSetup: () => void;

  canGoNextHole: boolean;
  toggleScoringPlayer(gameId: string, playerId: string, action: "add" | "remove", callback?: ErrorOnlyCallback): void;
}

const ScoringTable = (props: Props) => {
  const { user, player, game, holeIndex, classes, scoreCard, index } = props;
  const holesParams = scoreCard.holesParams;
  const { si, par, number } = holesParams[holeIndex];
  const markAsFilled = player.holes[number] && player.holes[number].gross && !props.canGoNextHole;
  const isPointAvailable = player.points && player.points.holes[number];

  const waitingForMyScore =
    player.uholes && player.uholes[number] && player.uholes[number].grosses
      ? props.scoring && !player.uholes[number].grosses[user._id]
      : false;

  const showHiConfirmDialog = (gameId: string, playerId: string, hi: number) => {
    showAlertDialog(
      "HI Confirmation",
      `Is this player's HI (${hi}) correct?`,
      () => showHiNotice(gameId, playerId),
      () => props.onOpenScorerSetup(),
    );
  };
  const showHiNotice = (gameId: string, playerId: string) => {
    setTimeout(
      () =>
        showAlertDialog(
          "",
          "Incorrect HI can cause disqualification. Should you need to amend the HI, go to Mark Cards.",
          null,
          null,
        ),
      100,
    );
    props.toggleScoringPlayer(gameId, playerId, "add");
  };

  const isStandard = isGameStandard(game.competition.type);

  const toggleScoringPlayer = (event: ChangeEvent<{ checked: boolean }>, game: Game, player: Player) => {
    const { checked } = event.target;
    if (!checked) {
      showAlertDialog(
        "Deactivate scoring",
        <p>
          You are about to deselect scoring for {player.name} {player.lastName}, are you sure?
          <br />
          <br />
          <strong style={{ color: "red" }}>To reactivate scoring, go to Mark Cards</strong>
        </p>,
        () => {
          props.toggleScoringPlayer(game._id, player._id, "remove");
        },
        () => {},
      );
    } else {
      if (isStandard) showHiConfirmDialog(game._id, player._id, player.hci);
      else props.toggleScoringPlayer(game._id, player._id, "add");
    }
  };

  return (
    <Grid container>
      <Grid item md={12} xs={12}>
        <Table
          key={player._id + holeIndex}
          className={[
            classes.table,
            markAsFilled ? (waitingForMyScore ? classes.waitingForScore : classes.filledTable) : {},
          ].join(" ")}
          size="small"
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TightBodyCell colSpan={1} className={classes.firstColumn}>
                <div className={player.isUnverifiable ? classes.unverifiedGolfIcon : classes.normalGolfIcon}>
                  <IconButton
                    color={"inherit"}
                    size={"small"}
                    onClick={() =>
                      showDialog(
                        <ModernScoreCardView
                          gameId={game._id}
                          player={player}
                          onVerifyPlayer={props.handleVerifyPlayer}
                          quickScoring
                        />,
                        "xl",
                        false,
                        true,
                        true,
                        player.isUnverifiable ? "Does Player agree to their score?" : "",
                      )
                    }
                  >
                    <GolfCourse />
                  </IconButton>
                </div>
                <PlayerName isGuest={player.guest} name={player.name} lastName={player.lastName} />
              </TightBodyCell>
              <TightBodyCell colSpan={1} align={"left"}>
                <div className="row wrap">
                  <span style={{ marginRight: 6 }}>
                    PH: {player.hcp ? `${Number(player.hcp).toFixed(1)}/${getHandicapDisplay(player.hcp)}` : "New"}
                  </span>
                  {props.combined || !isStandard ? null : (
                    <span>HI: {player.hci ? Number(player.hci).toFixed(1) : "New"}</span>
                  )}
                </div>
              </TightBodyCell>
              <TightBodyCell align={"left"} className={"large-font"}>
                Par: {par} / SI: {si}
              </TightBodyCell>

              <TightBodyCell>
                {index === 0 && (
                  <Button
                    disableElevation
                    variant="contained"
                    className={classes.btn}
                    disabled={!game.course.localRules}
                    onClick={() =>
                      showDialog(
                        <article
                          className={classes.article}
                          dangerouslySetInnerHTML={{ __html: game.course?.localRules || "" }}
                        />,
                        "md",
                        false,
                        true,
                        true,
                        "",
                        `${game.course?.club.logo ? Config.API_URL + game.course.club.logo : ""}`,
                      )
                    }
                  >
                    Local Rules
                  </Button>
                )}
              </TightBodyCell>
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow>
              <TightHeaderCell className={classes.headCell}>
                {!props.combined && (
                  <>
                    Scoring
                    <Switch checked={props.scoring} onChange={e => toggleScoringPlayer(e, game, player)} />
                  </>
                )}
              </TightHeaderCell>
              <TightHeaderCell className={classes.headCell}>Gross</TightHeaderCell>
              <TightHeaderCell className={classes.headCell}>Nett</TightHeaderCell>
              <TightHeaderCell className={classes.headCell}>Pts</TightHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TightHeaderCell className={classes.headCell}>Hole</TightHeaderCell>
              <TightBodyCell className={"medium-font"}>
                <Gross game={game} player={player} holeNumber={number} />
              </TightBodyCell>
              <TightBodyCell className={"large-font"}>
                {isPointAvailable ? player.points.holes[number].nett : 0}
              </TightBodyCell>
              <TightBodyCell className={"large-font"}>
                {isPointAvailable ? player.points.holes[number].point : 0}
              </TightBodyCell>
            </TableRow>
            <TableRow className={classes.lastRow}>
              <TightHeaderCell className={classes.headCell}>Round</TightHeaderCell>
              <TightBodyCell className={"large-font"}>
                {isPointAvailable ? player.points.holes[number].totalGross : 0}
              </TightBodyCell>
              <TightBodyCell className={"large-font"}>
                {isPointAvailable ? player.points.holes[number].totalNett : 0}
              </TightBodyCell>
              <TightBodyCell className={"large-font"}>
                {isPointAvailable ? player.points.holes[number].totalPoint : 0}
              </TightBodyCell>
            </TableRow>
          </TableBody>
          {(props.scoring || props.combined) && (
            <ScoringTableActionButton
              gameId={game._id}
              player={player}
              combined={props.combined}
              holeNumber={number}
              numberOfHoles={scoreCard.holesParams.length}
              handleGrossChange={props.handleGrossChange}
              grossFinalCheck={props.grossFinalCheck}
              handleVerifyPlayer={props.handleVerifyPlayer}
              openPickerDialogForMisMatchHole={props.openPickerDialogForMisMatchHole}
            />
          )}
        </Table>
      </Grid>
    </Grid>
  );
};
const styles = (theme: Theme) =>
  createStyles({
    table: {
      position: "relative",
    },
    filledTable: {
      backgroundColor: "#FFF59D",
    },
    waitingForScore: {
      backgroundColor: "#F8BBD0",
    },
    headCell: {
      width: 85,
      whiteSpace: "nowrap",
    },
    lastRow: {
      borderColor: "#6794ff",
      borderWidth: 2,
      borderBottom: "solid",
    },
    normalGolfIcon: {
      color: theme.palette.primary.main,
    },
    unverifiedGolfIcon: {
      color: "#ffaf0f",
    },
    firstColumn: {
      whiteSpace: "nowrap",
      minWidth: 100,
    },
    btn: {
      textTransform: "inherit",
      padding: 7,
      marginBottom: 10,
      backgroundColor: "#98fb98",

      "&:hover": {
        backgroundColor: "#20dd20",
      },
    },
    article: {
      fontWeight: "normal",
      padding: "0 1rem",

      "& *": {
        fontFamily: "Arial, sans-serif !important",
        fontWeight: "inherit",
      },

      "& strong, b": {
        fontWeight: "bold",
      },
    },
  });

const mapStateToProps = (state: GlobalState) => {
  const { user } = state.auth;
  return {
    user,
  };
};

export default connect(mapStateToProps, {
  toggleScoringPlayer: GameActions.toggleScoringPlayer,
})(withStyles(styles)(ScoringTable));
