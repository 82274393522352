import * as React from "react";
import {
  makeStyles,
  Theme,
  IconButton,
  Toolbar,
  Tooltip,
  Table,
  TableRow,
  TableHead,
  TableCell,
  Switch,
  Divider,
} from "@material-ui/core";
import { Assignment, NavigateBefore, NavigateNext, ListAlt } from "@material-ui/icons";
import { ScoreCard } from "./../../../types/scoreCard";

export interface ScoringTableToolbarProps {
  holeIndex: number;
  scoreCard: ScoreCard;
  isAutoProgressEnable: boolean;
  isCombined?: boolean;
  onOpenLeaderboard: () => void;
  onPreviousHole: () => void;
  onNextHole: () => void;
  onOpenScorerSetup: () => void;
  onAutoProgressChanged: (event: any) => void;
}

const ScoringTableToolbar: React.FunctionComponent<ScoringTableToolbarProps> = props => {
  const classes = useStyles();
  const { holeIndex, scoreCard } = props;
  const { si, par, yards } = scoreCard.holesParams[holeIndex];
  const isNextDisable = holeIndex === scoreCard.holesParams.length - 1;

  const calculateHoleNumber = () => {
    const { scoreCard, holeIndex } = props;
    if (!scoreCard || !scoreCard.name) return 0;
    const { number } = scoreCard.holesParams[holeIndex];
    const name = scoreCard.name.toLowerCase();
    const { holesNumber } = scoreCard.course;
    if (name.includes("back")) {
      return number + holesNumber;
    } else if (name.includes("middle")) {
      return number + 6;
    } else return number;
  };

  return (
    <>
      <Toolbar style={{ padding: 8, justifyContent: "space-between", position: "relative" }}>
        <div className={classes.inline}>
          <div className={classes.column}>
            <Tooltip title={"Leaderboard"}>
              <IconButton style={{ paddingBottom: "5px" }} onClick={props.onOpenLeaderboard}>
                <Assignment />
              </IconButton>
            </Tooltip>
            <p className={classes.iconText}>Leaderboard</p>
          </div>
        </div>
        <IconButton style={{ paddingRight: 0 }} disabled={holeIndex === 0} onClick={props.onPreviousHole}>
          <NavigateBefore />
        </IconButton>
        <div className={classes.title}>
          <p style={{ margin: 12 }}>Hole</p>
          <strong className={"extra-large-font"}>{calculateHoleNumber()}</strong>
        </div>
        <IconButton style={{ paddingLeft: 0 }} disabled={isNextDisable} onClick={props.onNextHole}>
          <NavigateNext />
        </IconButton>
        <div className={classes.column}>
          {props.isCombined ? null : (
            <>
              <Tooltip title={"Mark Cards"}>
                <IconButton style={{ paddingBottom: "5px" }} onClick={props.onOpenScorerSetup}>
                  <ListAlt />
                </IconButton>
              </Tooltip>
              <p className={classes.iconText}>Mark Cards</p>
            </>
          )}
        </div>
      </Toolbar>
      <Table style={{ backgroundColor: "#8f8b8f" }} size="small">
        <TableHead>
          <TableRow>
            <TableCell style={{ color: "#FFF", width: 100 }}>Yds : {yards}</TableCell>
            <TableCell className={classes.headCell + " large-font"} style={{ color: "#FFF" }}>
              Par : {par}
            </TableCell>
            <TableCell className={classes.headCell + " large-font"} style={{ color: "#FFF" }}>
              SI: {si}
            </TableCell>
            <TableCell align={"right"} className={classes.headCell} style={{ color: "#FFF" }} colSpan={2}>
              <strong style={{ color: "#FFF" }}>Auto progress</strong>
              <Switch checked={props.isAutoProgressEnable} onChange={props.onAutoProgressChanged} color="primary" />
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>
      <Divider />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  headCell: {
    width: 85,
  },
  inline: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    display: "flex",
    justifyContent: "center",
    fontSize: "1rem",
    flexGrow: 0.25,
    margin: 0,
  },
  column: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  iconText: {
    margin: "0",
    fontSize: "smaller",
  },
}));

export default ScoringTableToolbar;
