import React from "react";
import { TableRow, TableCell, TableHead, makeStyles, Theme, TableBody } from "@material-ui/core";
import LeaderboardPlayerRow from "./LeaderboardPlayerRow";
import { PositionsInfo } from "./IndividualLeaderBoard";
import { Player, PayStatus } from "types/player";
import { getPlayersVerificationState } from "utils";

interface GameInfo {
  isStroke: boolean;
  isFinalScore: boolean;
  isStandardGame: boolean;
  isPaidGame: boolean;
  isCombined: boolean;
  shouldPayBeforePlay: boolean;
  exactHandicaps: boolean;
  holesNumber: number;
  verifyQs: boolean;
  widescreen: boolean;
  handicapAdjustment: boolean;
  scoresDisplayOrder: boolean;
}

interface Props {
  gameInfo: GameInfo;
  players: { [index: string]: Player };
  activeUserId: string;
  calculatePlayerPosition(row: any, index: number, lastPlayerPoint: number | string): PositionsInfo;
  openScoreCardDialog(player: any): void;
  trRefs: React.RefObject<HTMLTableRowElement[]>;
  isOrganizer: boolean;
}

const getThru = (player: any): number | undefined => {
  let holes = Object.values(player.holes as any);
  let lastItem = holes[holes.length - 1] as any;
  if (lastItem) return lastItem.holeNumber;

  return undefined;
};

export const prepareData = (
  players: any,
  isCombinedGame: boolean,
  payBeforePlay: boolean,
  scoresDisplayOrder: boolean,
  isStroke: boolean,
) => {
  const playersList = isCombinedGame ? players.filter((x: any) => x.isTeamCard) : players;

  const preparedData = playersList
    .map((p: any) => {
      return {
        ...p,
        startingHole: p.group?.startingHole || 1,
        gross: p.points?.totalGross || 0,
        nett: p.points?.totalNett || 0,
        pts: p.points?.totalPoint || 0,
        thru: getThru(p),
      };
    })
    .filter((p: Player) => (payBeforePlay ? p.payStatus !== PayStatus.NOT_PAID : true));

  if (scoresDisplayOrder && isStroke) {
    return preparedData.sort((a: any, b: any) => b.nett - a.nett);
  }

  return preparedData;
};

export default function PlayersLeaderboard(props: Props) {
  const classes = useStyles();
  const { activeUserId, isOrganizer } = props;
  const {
    isFinalScore,
    isStandardGame,
    isPaidGame,
    isStroke,
    isCombined,
    shouldPayBeforePlay,
    exactHandicaps,
    holesNumber,
    verifyQs,
    widescreen,
    handicapAdjustment,
    scoresDisplayOrder,
  } = props.gameInfo;
  const records = prepareData(
    Object.values(props.players),
    isCombined,
    shouldPayBeforePlay,
    scoresDisplayOrder,
    isStroke,
  );
  const playersVState = verifyQs ? getPlayersVerificationState(records, holesNumber, true) : undefined;
  let lastPlayerPoint = -1;

  return (
    <>
      <TableHead>
        <TableRow>
          <TableCell className={classes.headerCell}>Pos</TableCell>
          <TableCell className={classes.headerCell}></TableCell>
          <TableCell className={classes.headerCell}>Name</TableCell>
          <TableCell className={classes.headerCell}>PH</TableCell>
          {isStandardGame && <TableCell className={classes.headerCell}>HI</TableCell>}
          <TableCell className={classes.headerCell}>Gross</TableCell>
          <TableCell className={classes.headerCell}>Nett</TableCell>
          {!isStroke && <TableCell className={classes.headerCell}>Pts</TableCell>}
          {isFinalScore ? (
            handicapAdjustment ? (
              <TableCell className={classes.headerCell}>New PH</TableCell>
            ) : null
          ) : (
            <TableCell className={classes.headerCell}> Thru</TableCell>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {records.map((data: any, index: number) => {
          const info = props.calculatePlayerPosition(data, index, lastPlayerPoint);
          lastPlayerPoint = data.pts;
          return (
            <LeaderboardPlayerRow
              key={data._id}
              player={data}
              info={info}
              activeUserId={activeUserId}
              isStroke={isStroke}
              isFinalScore={isFinalScore}
              isStandardGame={isStandardGame}
              openScoreCardDialog={props.openScoreCardDialog}
              isPaidGame={isPaidGame}
              shouldPayBeforePlay={shouldPayBeforePlay}
              exactHandicaps={exactHandicaps}
              verified={playersVState ? playersVState[data._id] === true : undefined}
              trRefs={props.trRefs}
              isOrganizer={isOrganizer}
              widescreen={widescreen}
              index={index}
              handicapAdjustment={handicapAdjustment}
            />
          );
        })}
      </TableBody>
    </>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  headerCell: {
    fontWeight: "bold",
  },
}));
