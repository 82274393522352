import axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from "axios";
import failedCallsHandler from "./failedCallsHandler";
import Config from "../config";

const instance = axios.create();
let onLoadingChanged: (status: boolean) => void = () => {};

instance.defaults.baseURL = Config.API_URL;
instance.defaults.timeout = Config.TIME_OUT;

instance.interceptors.request.use((config: AxiosRequestConfig) => {
  onLoadingChanged(true);
  const token = localStorage.getItem(Config.STORAGE.TOKEN);
  if (token) config.headers.access_token = `${JSON.parse(token)}`;
  return config;
});

instance.interceptors.response.use(
  function (response: AxiosResponse) {
    onLoadingChanged(false);
    return response;
  },
  function (error: AxiosError) {
    onLoadingChanged(false);
    failedCallsHandler.add(error);
    return Promise.reject(error);
  },
);

export const onLoadingChangedEvent = (callback: (status: boolean) => void) => {
  onLoadingChanged = callback;
};

export default {
  get: instance.get,
  post: instance.post,
  put: instance.put,
  delete: instance.delete,
  patch: instance.patch,
  instance: instance,
};
