import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 15,
    },
    container: {
      margin: "20px 0",
    },
    table: {
      border: "1px solid rgba(224, 224, 224, 1)",

      "& .MuiTableCell-root": {
        borderRight: "1px solid rgba(224, 224, 224, 1)",
      },

      "& .MuiTableCell-root.MuiTableCell-body": {
        padding: "5px !important",
      },
    },
    dark: {
      color: "#000",
      fontSize: "1.2em",
      fontFamily: "Roboto, Helvetica, Arial, sans-serif !important",
    },
    th: {
      color: "#333d7b",
      textShadow: "0px 0px black",
    },
    red: {
      color: "red",
      textShadow: "0px 0px black",
      fontSize: "1.2em",

      "&.red1": {
        fontSize: "1.1em",
      },
    },
    logo: {
      width: 60,
      height: 60,
      position: "absolute",
      top: 0,
      right: 0,
    },
    cell: {
      width: "2.5rem",
      height: "2.5rem",
      display: "inline-flex",
      borderRadius: "50%",
      alignItems: "center",
      justifyContent: "center",
    },
    empty: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  }),
);
