import React, { Component } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Tooltip,
  IconButton,
  Button,
  withStyles,
  Theme,
  createStyles,
  WithStyles,
  Dialog,
} from "@material-ui/core";
import { connect } from "react-redux";
import { GlobalState } from "./../../types/globalState";
import { Tournament, TournamentGames, ScoreSystem } from "./../../types/tournament";
import moment from "moment";
import LeaderboardRow from "./leaderboardRow";
import Trophy from "../../assets/images/trophy.png";
import { CloudDownload, Close } from "@material-ui/icons";
import { exportResultToPDF, exportResultToImageFile } from "../../utils/GameReportGenerator/pdf";
import { showAuxiliaryDialog } from "../SharedComponents/AuxiliaryDialog";
import { ShareFileButton } from "../../components/SharedComponents/ShareButton";
import TournamentHoleByHoleDialog from "./TournamentHoleByHoleDialog";
import { Player } from "./../../types/player";

export interface Props extends WithStyles {
  tournamentGames: TournamentGames;
  tournament: Tournament;
}

export interface State {
  isDialogOpen: boolean;
  player?: Player;
}

class TournamentLeaderboard extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isDialogOpen: false,
    };
  }

  closeDialog = () => this.setState({ isDialogOpen: false });

  handleRowClick = (player: Player) => {
    this.setState({ player: player, isDialogOpen: true });
  };

  renderModernScoreCardDialog = () => {
    const { player, isDialogOpen } = this.state;
    if (!player) return;

    const playerPoints = this.props.tournamentGames.points.find(p => p._id === player._id)?.points || 0;

    return (
      <Dialog open={isDialogOpen} onBackdropClick={this.closeDialog} maxWidth="lg">
        <div>
          <IconButton style={{ float: "right" }} onClick={this.closeDialog}>
            <Close />
          </IconButton>
        </div>
        <TournamentHoleByHoleDialog player={player} points={playerPoints} tournament={this.props.tournament} />
      </Dialog>
    );
  };

  render() {
    const { tournament, tournamentGames, classes } = this.props;
    const tournamentForExport: any = {
      name: tournament.name,
      players: tournamentGames.points,
      course: { holesNumber: 18 },
    };

    return (
      <div>
        <div style={{ textAlign: "center", marginBottom: 10 }}>
          <Typography variant="h6" gutterBottom>
            {tournament.name}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {moment(tournament.seasonStart).format("ll")}- {moment(tournament.seasonEnd).format("ll")}
          </Typography>
          <Typography variant="body1" gutterBottom>
            LEADERBOARD
          </Typography>
        </div>
        <div className={classes.buttonsBar}>
          {[ScoreSystem.TOTAL_GROSS, ScoreSystem.TOTAL_NETT, ScoreSystem.TOTAL_POINTS].includes(
            tournament.selectedScoreSystem,
          ) ? null : (
            <Tooltip title="Export Result" leaveTouchDelay={2000}>
              <IconButton
                onClick={() => {
                  showAuxiliaryDialog(
                    <div className="flex-column">
                      <ShareFileButton
                        title={`SCHALA - ${tournament.name} Tournament Result`}
                        text={`Here is result of ${tournament.name} tournament which has been played using SCHALA`}
                        url={`https://schala.golf`}
                        getFiles={async () =>
                          await exportResultToImageFile(`TournamentResult`, tournamentForExport, "", true)
                        }
                      />
                      <Button onClick={() => exportResultToPDF(tournamentForExport, "", true)}>Download</Button>
                    </div>,
                    "xl",
                    false,
                    true,
                    true,
                    "Choose action",
                  );
                }}
              >
                <CloudDownload />
              </IconButton>
            </Tooltip>
          )}
        </div>
        <TableContainer
          component={Paper}
          style={{
            backgroundImage: `url(${Trophy})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
          <Table aria-label="simple table" style={{ minWidth: 500 }}>
            <TableHead>
              <TableRow>
                <TableCell>Pos.</TableCell>
                <TableCell></TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Score</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.tournamentGames.points.map((player, index) => {
                return <LeaderboardRow player={player} key={index} index={index} onClick={this.handleRowClick} />;
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {this.renderModernScoreCardDialog()}
      </div>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    buttonsBar: {
      backgroundColor: "#eeeeee",
      textAlign: "right",
      padding: "0 8px",
    },
  });

const mapStateToProps = (state: GlobalState) => {
  const { tournamentGames } = state.tournaments;
  return {
    tournamentGames,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(TournamentLeaderboard));
