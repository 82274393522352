import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Switch from "@material-ui/core/Switch";

const StyledSwitch = withStyles({
  switchBase: {
    color: "#ebebeb",
    "&$checked": {
      color: "#52d869",
    },
    "&$checked + $track": {
      backgroundColor: "#52d869",
    },
  },
  checked: {},
  track: {},
})(Switch);

interface CustomSwitchProps {
  checked: boolean;
  onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
}

const CustomSwitch: React.FC<CustomSwitchProps> = ({ checked, onChange }) => {
  return <StyledSwitch disableRipple checked={checked} onChange={onChange} />;
};

export default CustomSwitch;
