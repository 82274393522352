import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { GlobalState } from "../../types/globalState";
import GamesActions from "../../actions/games";
import { switchComponent } from "../../actions/componentRouter";
import { Assignment } from "@material-ui/icons";
import {
  Button,
  List,
  createStyles,
  LinearProgress,
  TextField,
  Theme,
  Typography,
  WithStyles,
  IconButton,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { showSnackbar } from "../SharedComponents/Notifier";
import { hideDialog, showDialog } from "../SharedComponents/Dialog";
import { Views } from "../Dashboard";
import { Link } from "react-router-dom";
import { Game, IndexedGames } from "../../types/game";
import { User } from "../../types/user";
import { AxiosError } from "axios";
import { showAlertDialog } from "../SharedComponents/AlertDialog";
import EmailDialog, { showEmailDialog } from "../SharedComponents/EmailDialog";
import history from "customHistory";
import GamePayment from "components/Payment/GamePayment";
import VideoPopup from "components/SharedComponents/VideoPopup";
import { HELP_VIDEOS } from "consts";

interface Props extends WithStyles {
  removePlayer(id: string, playerId: string, cb?: () => void): void;
  getJoinedGames(): void;
  joinGame(id: string, callback?: any): void;
  switchComponent(view: any, props?: any): void;
  getGameByCode(code: string, queryType: string, callback?: (error: AxiosError | null, data?: Game) => void): void;
  visitedLeaderBoard: Game[];
  gameCode?: string;
  joinedGames: IndexedGames;
  loading: boolean;
  user: User;
}
interface State {
  gameCode: string;
}

class GameEntranceForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      gameCode: props.gameCode ? props.gameCode : "",
    };
  }

  componentDidMount() {
    if (this.props.gameCode) {
      this._onSubmit();
      const updatedUrl = window.location.protocol + "//" + window.location.host;
      window.history.pushState({ path: updatedUrl }, "", updatedUrl);
    }
  }

  _onGameCodeChange = (event: any) => {
    this.setState({ gameCode: event.target.value.toUpperCase() });
  };
  _gameExist = (code: string) => {
    const { joinedGames } = this.props;
    let result = false;
    Object.values(joinedGames).forEach((game: any) => {
      if (game.code === code) {
        result = true;
      }
    });
    return result;
  };
  _onSubmit = () => {
    const { gameCode } = this.state;
    if (gameCode.length !== 6) return showSnackbar("Please enter the Game Code");
    if (!this._gameExist(gameCode)) {
      this.props.getGameByCode(gameCode, "all", (err, game) => {
        if (err || !game) return showSnackbar("Game Code is not valid.");

        // Pay before play game ?
        if (game.paymentRules?.payBeforePlay) {
          showSnackbar("In order to continue, you must pay the game fee first.");
          showDialog(<GamePayment type="pay" game={game} payBeforeJoin />, "xl", true, true, true, "", "");
        } else {
          this._joinGame(game._id);
        }
      });
    } else {
      showSnackbar("You already joined this game");
    }
  };

  _joinGame = (gameId: string) => {
    this.props.joinGame(gameId, (err: any) => {
      if (!err) {
        showSnackbar("You successfully joined the game.", 7000, "success");
      } else {
        if (err?.response?.data.payload?.isFull) {
          showAlertDialog(
            "Ooops !",
            "The game is full, Do you want to send an Email to the game Organiser?",
            () => this.showEmailNotify(err.response.data.payload.organizers[0]),
            () => {},
          );
        }
      }
    });
  };

  showEmailNotify = (organizer: any) => {
    const { gameCode } = this.state;
    showEmailDialog(`Game Code ${gameCode}`, `${organizer.name} ${organizer.lastName}`, organizer._id);
  };

  _onCreateGame = () => {
    showAlertDialog(
      "Do you want to copy a previous game ?",
      "",
      () => {
        // Yes
        history.push("/games");
      },
      // No
      () => {
        this.props.switchComponent(Views.ClubsListView);
      },
    );
    //
  };
  _showLeaderBoardHistory = () => {
    let { visitedLeaderBoard } = this.props;
    showDialog(
      <>
        <List>
          <ListItem>
            <ListItemText primary={"Leader-boards History"} secondary={"You see the last 10 visited leader-board"} />
          </ListItem>
          {visitedLeaderBoard
            .sort((a: any, b: any) => (a.visitDate > b.visitDate ? 1 : -1))
            .slice(0, 10)
            .map(game => (
              <ListItem
                dense
                key={game._id}
                button
                component={Link}
                to={`/leaderBoard/${game.code}`}
                onClick={hideDialog}
              >
                <ListItemText primary={game.name} secondary={game.code} />
              </ListItem>
            ))}
        </List>
      </>,
      "xl",
      false,
      true,
    );
  };

  render() {
    const { classes, loading, visitedLeaderBoard } = this.props;

    return (
      <>
        {loading ? <LinearProgress /> : null}
        <div className={classes.paper}>
          <Typography variant={"h5"}>Enter Game Code</Typography>
          <p>Find your code on your invite email, the available QR code or ask the organiser upon arrival.</p>
          <form className={classes.form}>
            <div className={classes.inline}>
              <TextField
                label="Game Code"
                value={this.state.gameCode}
                style={{ flex: 1, minWidth: 128 }}
                margin={"normal"}
                variant={"outlined"}
                onChange={this._onGameCodeChange}
              />
              {this.state.gameCode.length === 6 ? (
                <Link className={classes.view} to={`/leaderBoard/${this.state.gameCode}`}>
                  <Button variant="contained" color="secondary" className={classes.submit}>
                    View Leader Board
                  </Button>
                </Link>
              ) : (
                <div className={classes.view}>
                  <Button
                    onClick={() => showSnackbar("Please enter the Game Code")}
                    variant="contained"
                    color="secondary"
                    className={classes.submit}
                  >
                    View Leader Board
                  </Button>
                </div>
              )}
              {visitedLeaderBoard.length > 0 ? (
                <div className={classes.column}>
                  <IconButton onClick={this._showLeaderBoardHistory}>
                    <Assignment />
                  </IconButton>
                  <span>History</span>
                </div>
              ) : null}
            </div>
            <div className={classes.row}>
              <Button
                onClick={this._onSubmit}
                variant="contained"
                color="primary"
                className={`${classes.submit} ${classes.join}`}
              >
                Join the game
              </Button>
            </div>
            <p className={"text-center"}>
              <strong>OR</strong>
            </p>
            <div className={classes.video}>
              <VideoPopup videoURL={HELP_VIDEOS.CREATE_GAME} />

              <Button
                onClick={this._onCreateGame}
                fullWidth
                variant="outlined"
                color="primary"
                className={classes.submit}
              >
                Create a Game
              </Button>
            </div>
          </form>
        </div>
        <EmailDialog />
      </>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(0)}px`,
      backgroundColor: "rgba(255, 255, 255, 0.85)",
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
      padding: theme.spacing(2),
      paddingTop: 0,
    },
    submit: {
      borderRadius: 100,
    },
    inline: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    join: {
      flex: 5,
    },
    view: {
      textDecoration: "none",
      color: "white",
      marginTop: 6,
      marginLeft: 16,
    },
    row: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      marginTop: 16,
    },
    column: {
      display: "flex",
      flexDirection: "column",
    },
    video: {
      position: "relative",

      "& > button:first-child": {
        position: "absolute",
        left: -20,
        top: -35,
      },
    },
  });

const mapStateToProps = (state: GlobalState) => {
  const { loading } = state.general;
  const { joinedGames, visitedLeaderBoard } = state.games;

  return {
    joinedGames,
    visitedLeaderBoard: visitedLeaderBoard ? Object.values(visitedLeaderBoard) : [],
    loading,
    user: state.auth.user,
  };
};
export default connect(mapStateToProps, {
  getGameByCode: GamesActions.getGameByCode,
  joinGame: GamesActions.joinGame,
  removePlayer: GamesActions.removePlayer,
  getJoinedGames: GamesActions.getJoinedGames,
  switchComponent,
})(withStyles(styles)(GameEntranceForm));
