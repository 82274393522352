import ReduxTypes, { Keys } from "../consts/ReduxTypes";
import { hideDialog } from "../components/SharedComponents/Dialog";
import { hideAuxiliaryDialog } from "../components/SharedComponents/AuxiliaryDialog";
import ErrorHandler from "../api/Errorhandler";

export class BaseActions {
  api: any;
  key: Keys;

  constructor(api: any, key: Keys) {
    this.api = api;
    this.key = key;
  }

  insert = (data: any, keepDialog?: boolean, callback?: any) => {
    return (dispatch: any) => {
      this.api
        .insert(data)
        .then((res: any) => {
          dispatch({
            type: ReduxTypes.getInsertType(this.key),
            payload: res.data,
            data,
          });

          if (callback) callback(null, res.data);
          if (!keepDialog) hideDialog();
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
          if (callback) callback(err, null);
        });
    };
  };

  copy = (id: string, data: any, keepDialog?: boolean, callback?: any) => {
    return (dispatch: any) => {
      this.api
        .copy(id, data)
        .then((res: any) => {
          dispatch({
            type: ReduxTypes.getCopyType(this.key),
            payload: res.data,
            data,
          });

          if (callback) callback(null, res.data);
          if (!keepDialog) hideDialog();
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
          if (callback) callback(err, null);
        });
    };
  };

  delete = (id: string) => {
    return (dispatch: any) => {
      this.api
        .delete(id)
        .then((res: any) => {
          dispatch({ type: ReduxTypes.getDeleteType(this.key), payload: id });
        })
        .catch((err: any) => {
          if (err.response.status === 404) {
            dispatch({
              type: ReduxTypes.getDeleteType(this.key),
              payload: id,
            });
          } else {
            ErrorHandler(err, dispatch);
          }
        });
    };
  };

  update = (id: string, data: any, auxiliary?: boolean) => {
    return (dispatch: any) => {
      this.api
        .update(id, data)
        .then((res: any) => {
          dispatch({
            type: ReduxTypes.getUpdateType(this.key),
            payload: id,
            data,
          });

          auxiliary ? hideAuxiliaryDialog() : hideDialog();
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
        });
    };
  };

  updateAdvertState = (id: string, state: string) => {
    return (dispatch: any) => {
      this.api
        .updateState(id, state)
        .then((res: any) => {
          /* dispatch({
            type: ReduxTypes.getUpdateType(this.key),
            payload: id,
            data,
          });*/
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
        });
    };
  };

  fetch = (page: number, limit: number, filter?: any, sort?: string, dir?: string, query?: string, callback?: any) => {
    return (dispatch: any) => {
      this.api
        .get(page, limit, filter, sort, dir, query)
        .then((res: any) => {
          dispatch({
            type: ReduxTypes.getFetchType(this.key),
            payload: res.data.items,
            count: res.data.count,
          });
          if (callback) callback(null, res.data.items);
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
          if (callback) callback(err, null);
        });
    };
  };

  search = (callback: any, query: string, page: number, limit: number, filter?: any, sort?: string, dir?: string) => {
    return (dispatch: any) => {
      this.api
        .search(query, page, limit, filter, sort, dir)
        .then((res: any) => {
          callback(null, res);
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
          callback(err, null);
        });
    };
  };
}
