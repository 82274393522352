import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inline: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-evenly",
    },
    root: {
      maxWidth: 320,
      alignSelf: "center",

      "&.dnf": {
        maxWidth: 370,
        padding: "1rem 2rem",
      },
    },
    avatar: {
      fontSize: 22,
      fontWeight: "bold",
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.dark,
    },
    avatar2: {
      fontSize: 15,
      fontWeight: "bold",
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.grey[900],
    },
    clearButtonAvatar: {
      backgroundColor: "#cc2c1c",
      fontSize: 10,
    },
    clearButton: {
      position: "absolute",
      left: 8,
      top: -6,
    },
  }),
);
