import React, { Component } from "react";
import { connect } from "react-redux";
import ClubActions from "../../actions/clubs";
import SearchInput, { SearchItem } from "./SearchInput";
import { Club } from "../../types/club";

interface Props {
  onClubSelected(item: any): void;
  searchClubs(
    callback: any,
    query: string,
    page: number,
    limit: number,
    filter?: any,
    sort?: string,
    dir?: string,
  ): void;
  placeHolder?: string;
  label?: string;
  className?: string;
  defaultSelectedItem?: any;
  dialog?: boolean;
  style?: any;
}
interface State {
  club?: any;
}

class ScoreCardSetup extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      club: props.defaultSelectedItem,
    };
  }

  _clubSearchHandler = (query: string) => {
    return new Promise((resolve, reject) => {
      this.props.searchClubs(
        (err: any, result: any) => {
          if (!err) {
            resolve(
              result.data.items.map((item: Club) => ({
                value: item._id,
                title: item.name,
              })),
            );
          } else {
            reject(err);
          }
        },
        query,
        1,
        10,
        undefined,
        "name",
        "desc",
      );
    });
  };

  _onClubSelected = (item: SearchItem) => {
    const club = item ? { _id: item.value, name: item.title } : null;
    this.props.onClubSelected(club);
  };

  render() {
    const { className, dialog, placeHolder, label, style } = this.props;
    const { club } = this.state;
    const selectedClub = club ? { value: club._id, title: club.name, subtitle: club.address } : undefined;

    return (
      <div style={style}>
        <SearchInput
          dialog={dialog}
          minChar={2}
          label={label ? label : "Input clubs name"}
          className={className}
          placeholder={placeHolder ? placeHolder : "Click to search clubs"}
          defaultSelectedItem={selectedClub}
          searchHandler={this._clubSearchHandler}
          onItemSelected={this._onClubSelected}
        />
      </div>
    );
  }
}

export default connect(null, {
  searchClubs: ClubActions.search,
})(ScoreCardSetup);
