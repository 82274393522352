import * as React from "react";
import { Grid, Input, Typography, makeStyles, Theme, Button, Select, MenuItem } from "@material-ui/core";
import { Add, Close } from "@material-ui/icons";
import { HandicapAdjustmentRule, AdjustmentType } from "./../../types/competition";

export interface HandicapControlProps {
  handicapAdjustments: HandicapAdjustmentRule[];
  onChanged: (event: any) => void;
  isVisible: boolean;
}

const HandicapControl: React.SFC<HandicapControlProps> = props => {
  const classes = useStyles();
  const { handicapAdjustments, isVisible } = props;

  const handleOnTextChanged = (index: number, field: string) => (event: any) => {
    let adjustments = [...handicapAdjustments];
    adjustments[index] = { ...adjustments[index], [field]: event.target.value as number };
    props.onChanged(adjustments);
  };

  const addNewRule = () => {
    const initData: HandicapAdjustmentRule = { greaterThan: 0, lessThan: 100, value: 0, type: AdjustmentType.OVERALL };
    props.onChanged(handicapAdjustments ? handicapAdjustments.concat(initData) : [initData]);
  };

  const removeRule = (index: number) => {
    handicapAdjustments.splice(index, 1);
    props.onChanged(handicapAdjustments);
  };

  return !isVisible ? null : (
    <div>
      {handicapAdjustments?.map((handicap, index: number) => {
        return (
          <Grid container className={[classes.container, classes.blinkBackground].join(" ")}>
            <Grid md={12} xs={12} item className={classes.closeButton}>
              <Close color="secondary" onClick={() => removeRule(index)} />
            </Grid>
            <Grid item md={10} xs={10}>
              <Typography variant={"subtitle1"}>If player points score is greater than </Typography>
            </Grid>
            <Grid item md={2} xs={2} className={classes.inputContainer}>
              <Input
                type={"number"}
                value={handicap.greaterThan}
                onChange={handleOnTextChanged(index, "greaterThan")}
                className={classes.smallInput}
              />
            </Grid>
            <Grid item md={10} xs={10}>
              <Typography variant={"subtitle1"}>and less than </Typography>
            </Grid>
            <Grid item md={2} xs={2} className={classes.inputContainer}>
              <Input
                type={"number"}
                value={handicap.lessThan}
                onChange={handleOnTextChanged(index, "lessThan")}
                className={classes.smallInput}
              />
            </Grid>
            <Grid item md={2} xs={6}>
              <Typography variant={"subtitle1"}>then </Typography>
            </Grid>
            <Grid item md={10} xs={6} className={classes.inputContainer}>
              <Select id="adjustmentType" value={handicap.type} onChange={handleOnTextChanged(index, "type")}>
                <MenuItem value={AdjustmentType.OVERALL}>Overall</MenuItem>
                <MenuItem value={AdjustmentType.PER_POINT}>Per Point</MenuItem>
              </Select>
            </Grid>
            <Grid item md={9} xs={9}>
              <Typography variant={"subtitle1"}>adjust handicap by</Typography>
            </Grid>
            <Grid item md={1} xs={1}>
              <Typography variant="h5" gutterBottom>
                {!handicap.value ? null : handicap.value < 0 ? "-" : "+"}{" "}
              </Typography>
            </Grid>
            <Grid item md={2} xs={2} className={classes.inputContainer}>
              <Input
                type={"number"}
                inputProps={{ step: 0.1 }}
                value={handicap.value}
                onChange={handleOnTextChanged(index, "value")}
                className={classes.smallInput}
              />
            </Grid>
          </Grid>
        );
      })}
      <div>
        <Button fullWidth style={{ marginTop: 8 }} color={"primary"} variant={"outlined"} onClick={addNewRule}>
          <Add />
        </Button>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    border: "1px dashed #aaaaaa",
    borderRadius: 5,
    padding: 10,
    marginTop: 10,
  },
  smallInput: {
    width: 60,
  },
  inputContainer: {
    textAlign: "right",
  },
  closeButton: {
    textAlign: "right",
  },
  blinkBackground: {
    animation: `$blink 2s infinite`,

    animationIterationCount: 1,
  },
  "@keyframes blink": {
    from: { background: "rgba(250,237,57,0.9)" },
    to: { background: "rgba(250,237,57,0.0)" },
  },
}));

export default HandicapControl;
