import {
  Button,
  createStyles,
  Dialog,
  IconButton,
  LinearProgress,
  Table,
  TableContainer,
  Theme,
  WithStyles,
} from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { GlobalState } from "../../types/globalState";
import GameActions from "../../actions/games";
import HandicapActions from "../../actions/handicaps";
import ModernScoreCardView from "../ScoreCard/ModernScoreCardView";
import { Close } from "@material-ui/icons";
import { Game, Leaderboard } from "../../types/game";
import { showAlertDialog } from "../SharedComponents/AlertDialog";
import ScoreCardView from "../ScoreCard/ScoreCardSubmition";
import { Player } from "../../types/player";
import { showSnackbar } from "../SharedComponents/Notifier";
import Config from "../../config";
import * as socket from "../../socket";
import { User, UserBrifObj } from "../../types/user";
import LeaderboardUnavailable from "./LeaderboardUnavailable";
import { playerCustomHandicap } from "../../types/handicpas";
import _ from "lodash";
import { AxiosError } from "axios";
import { Competition, PlayingAsFormats } from "./../../types/competition";
import { isCombinedGame } from "./../../utils/index";
import LeaderboardToolbar from "./LeaderboardToolbar";
import LeaderboardActions from "./LeaderboardActions";
import TeamLeaderboard from "./TeamLeaderboard";
import PlayersLeaderboard from "./PlayersLeaderboard";
import AdvertSection from "components/Adverts/GameAdvert";
import { ADVERT_VALUES } from "consts";
import { AdvertTypes } from "types/advert";
import { isGameStandard, scrollTo } from "utils";
import { withScroll, WithScrollState } from "utils/withScroll";

interface Props extends WithStyles {
  getGameById(
    id: string,
    queryType?: string,
    updateRedux?: boolean,
    callback?: (err: AxiosError | null, data: Game) => void,
  ): void;
  getHandicapsByRuleId(id: string, callback: (err: AxiosError | null, data: playerCustomHandicap[]) => void): void;
  getGameByCode(code: string, queryType: string, callback?: (error: AxiosError | null, data?: Game) => void): void;
  addGameToVisitedLeaderBoard(game: any): void;
  setUpdatedResult(game: any): void;
  setGameFinished(gameId: string): void;
  revealLeaderboard(gameId: string, callback?: any): void;
  setLeaderboardRevealResult(game: any): void;
  finalCheck?: boolean;
  loading: boolean;
  gameId: string;
  user: User;
  activeUserId?: string;
  startScroll: (data: WithScrollState) => void;
}

export interface PositionsInfo {
  position: number | string;
  showInfo: boolean;
  showInTwoSteps: boolean;
}
interface State {
  openDialog: string;
  player?: any;
  teammates?: Player[];
  game?: any;
  offlineMode: boolean;
  isGameClosed: boolean;
  displayPageRefresh: boolean;
  getGameByIdQueryType: string;
}

let closeDialog = (player?: any) => {};
class individualLeaderBoard extends Component<Props, State> {
  mainRef = React.createRef<HTMLDivElement>();
  trRefs = React.createRef<HTMLTableRowElement[]>();

  constructor(props: Props) {
    super(props);
    // @ts-ignore
    this.trRefs.current = [];
    this.state = {
      game: null,
      openDialog: "",
      isGameClosed: false,
      offlineMode: false,
      displayPageRefresh: false,
      getGameByIdQueryType: "all",
    };
  }

  componentDidMount = () => {
    this.loadData((game: Game) => {
      this.startScrolling(game.leaderboard);
    });
    closeDialog = this.closeScoreCardDialog;
  };

  componentWillUnmount() {
    socket.disconnect();
  }

  handlePageRefreshAdvert = (val: boolean) => {
    this.setState({ displayPageRefresh: val });
  };

  startScrolling = (leaderboard?: Leaderboard) => {
    if (!leaderboard) return;

    this.props.startScroll({
      fields: leaderboard,
      containerEl: this.mainRef.current || undefined,
      tableRowEl: this.trRefs.current?.[0],
      setPageRefreshVisibility: leaderboard.widescreen ? this.handlePageRefreshAdvert : undefined,
    });
  };

  onGameUpdated = (result: any) => {
    this.setState((state: State) => ({ ...state, game: { ...state.game, players: result.players } }));
    this.props.setUpdatedResult(result);
  };

  onLeaderboardRevealed = (result: any) => {
    this.setState(
      (state: State) => ({ ...state, game: { ...state.game, leaderboard: result.leaderboard } }),
      () => {
        const index = result.leaderboard.revealIndex;
        const trEl = this.trRefs.current?.[index - 1];
        const containerEl = this.mainRef.current;
        if (!trEl || !containerEl) return;

        const { height: rowHeight } = trEl.getBoundingClientRect();

        if (index > 3 && !result.leaderboard.showInTwoSteps)
          scrollTo(containerEl, containerEl.scrollTop + rowHeight, 1000, false);
      },
    );
    this.props.setLeaderboardRevealResult(result);
  };

  onPresentationUpdate = ({ leaderboard }: { leaderboard: Leaderboard }) => {
    this.setState((state: State) => ({ ...state, game: { ...state.game, leaderboard } }));
    this.startScrolling(leaderboard);
  };

  connectToWebsocket = (gameId: any) => {
    if (socket.connect(gameId)) {
      socket.addEvent(socket.EVENTS.SCORES_UPDATED, this.onGameUpdated);
      socket.addEvent(socket.EVENTS.PLAYERS_CHANGED, this.onGameUpdated);
      socket.addEvent(socket.EVENTS.LEADERBOARD_REVEAL, this.onLeaderboardRevealed);
      socket.addEvent(socket.EVENTS.PRESENTATION_UPDATED, this.onPresentationUpdate);
    } else this.setState({ offlineMode: true });
  };

  reconnect = () => {
    this.props.gameId && this.connectToWebsocket(this.props.gameId);
  };

  loadData = (cb?: (game: Game) => void) => {
    if (this.props.gameId) {
      this.getGame(this.state.getGameByIdQueryType, cb);
      this.connectToWebsocket(this.props.gameId);
    } else {
      this.getGameByGameCode(cb);
    }
  };

  getGameByGameCode = (cb?: (game: Game) => void) => {
    //@ts-ignore
    const gameCode = this.props.match.params.gameId;
    if (gameCode) {
      this.props.getGameByCode(gameCode, this.state.getGameByIdQueryType, (err, game) => {
        if (err) {
          if (err.response && err.response.status === 404) {
            showSnackbar("Game Code is invalid!");
          }
        } else if (game) {
          this.connectToWebsocket(game._id);
          this.setState({ game });
          if (cb) cb(game);
          this.props.addGameToVisitedLeaderBoard(game);
        }
      });
    }
  };

  getGame = (queryType?: string, cb?: (game: Game) => void) => {
    let { gameId } = this.props;
    if (!gameId) gameId = this.state.game._id;
    this.props.getGameById(gameId, queryType, false, (err, game) => {
      if (!err) {
        this.setState({ game });

        if (cb) cb(game);
      }
    });
  };
  summation(list: any[], key?: string) {
    return list.reduce((total: any, e: any) => {
      if (key) {
        if (typeof e[key] === "number") return total + e[key];
      } else {
        if (typeof e === "number") return total + e;
      }
      return total;
    }, 0);
  }
  getScoreCard(player: any) {
    const { game } = this.state;
    if (!game) return null;

    if (player.teeColor) return game.scoreCards[player.teeColor.scoreCardId];
    return game.scoreCards[0];
  }

  getScoringFormat = () => {
    const { game } = this.state;
    return typeof game?.competition === "object" ? game.competition.scoringFormat.toString() : "undefined";
  };

  isAllCardsFilled = (game: Game) => {
    let isAlCardsField = true;
    const isCombined = isCombinedGame(game.competition);
    const players = Object.values(game.players).filter(x => x.isTeamCard || !isCombined);
    players.forEach(player => {
      if (Object.values(player.holes).length != game.competition.holesNumber) {
        isAlCardsField = false;
        return;
      }
    });
    return isAlCardsField;
  };

  handleGameClosed = () => this.setState({ isGameClosed: true });

  openScoreCardDialog = (player?: Player, teammates?: Player[]) => {
    if (this.state.game.organizers.filter((o: UserBrifObj) => o._id === this.props.user._id).length === 0) {
      this.setState({
        openDialog: "viewCard",
        player: player,
      });
    } else {
      showAlertDialog(
        "Submission",
        "Review Submission Card.",
        () => {
          this.setState({
            openDialog: "submitionCard",
            player: player,
          });
        },
        () => {
          this.setState({
            openDialog: "viewCard",
            player: player,
            teammates,
          });
        },
      );
    }
  };
  closeScoreCardDialog = () => {
    this.loadData();
    this.setState({ openDialog: "" });
  };
  renderPlayerScoreCardViewDialog() {
    if (!this.state.player) return null;
    return (
      <>
        <Dialog open={this.state.openDialog === "submitionCard"} fullScreen onBackdropClick={this.closeScoreCardDialog}>
          <ScoreCardView player={this.state.player} game={this.state.game} closeDialog={this.closeScoreCardDialog} />
        </Dialog>
        <Dialog open={this.state.openDialog === "viewCard"} onBackdropClick={this.closeScoreCardDialog}>
          <div>
            <IconButton style={{ float: "right" }} onClick={this.closeScoreCardDialog}>
              <Close />
            </IconButton>
          </div>
          <ModernScoreCardView player={this.state.player} teammates={this.state.teammates} game={this.state.game} />
        </Dialog>
      </>
    );
  }

  checkPresentationMode = (game: Game, index: number) => {
    const { leaderboard } = game;
    if (leaderboard && leaderboard.presentationMode) {
      if (index >= leaderboard.hideUntil || !leaderboard.hideUntil) return true;
      if (leaderboard.revealIndex !== undefined) {
        if (leaderboard.reverseHiddenPlace) return index >= leaderboard.revealIndex;
        else return index < leaderboard.revealIndex;
      }
      return false;
    }
    return true;
  };

  showInTwoSteps = (game: Game, index: number) => {
    const { leaderboard } = game;
    if (
      leaderboard &&
      leaderboard.presentationMode &&
      leaderboard.revealPlaceStatus === Config.LEADERBOARD_CONFIG.REVEAL_HIDDEN_PLACES.TWO_STEP &&
      leaderboard.showInTwoSteps
    ) {
      if (leaderboard.reverseHiddenPlace) return index === leaderboard.revealIndex;
      else return index + 1 === leaderboard.revealIndex;
    }
    return false;
  };

  isTeamGame = (competition: Competition) =>
    competition.playingRules && competition.playingRules.playingAs === PlayingAsFormats.Team;

  calculatePosition = (player: any, index: number, lastPlayerPoint: number | string): PositionsInfo => {
    const { game } = this.state;
    let position: number | string = index + 1;
    const sharedPrize = game.competition?.handicapRules?.sharedPrize;
    const showInfo = this.checkPresentationMode(game, index);
    const showInTwoSteps = this.showInTwoSteps(game, index);
    if ((sharedPrize && player.pts === lastPlayerPoint) || player.disqualified) position = "";
    return { position, showInfo, showInTwoSteps };
  };

  onRevealButtonClicked = async (gameId: any, revealStatus: any) => {
    this.props.revealLeaderboard(gameId);
  };

  haveAccessToLeaderboard = (game: Game, user: User) => {
    if (game.state === "done") return true;
    if (game.leaderboard && user.rule !== Config.RULES.ADMIN)
      switch (game.leaderboard.activateStatus) {
        case Config.LEADERBOARD_CONFIG.ACTIVATED.EVERY_ONE:
          return true;
        case Config.LEADERBOARD_CONFIG.ACTIVATED.VIEW_ONLY:
          return game.players[user._id || ""] === undefined;
        case Config.LEADERBOARD_CONFIG.ACTIVATED.ORGANIZER_ONLY:
          return user.rule === Config.RULES.ORGANIZER && game.organizers.findIndex(o => o._id === user._id) !== -1;
      }
    return true;
  };

  isStandardGame = () => isGameStandard(this.state.game.competition.type);

  render() {
    const { classes, loading, user } = this.props;
    const { game, offlineMode } = this.state;
    if (!game || !game.competition) return <LinearProgress />;
    if (!game.players) return <p>There was no player on this game!</p>;
    const { competition } = game;
    const isFinalScore = game.state === "done" || this.state.isGameClosed;
    const isStroke = this.getScoringFormat() === Config.SCORING_FORMAT.STROKE;
    const isTeamGame = this.isTeamGame(competition);
    const isCombined = isCombinedGame(competition);
    const isTeamLeaderboard = isTeamGame && !isCombined;
    const widescreen = game.leaderboard?.widescreen;
    const isOrganizer = game.organizers.findIndex((o: UserBrifObj) => o._id === user._id) !== -1;

    if (!this.haveAccessToLeaderboard(game, user)) return <LeaderboardUnavailable />;

    const handleQueryTypeChanged = (type: string) => {
      this.setState({ getGameByIdQueryType: type });
      this.getGame(type);
    };

    return (
      <main className={`${classes.main} ${widescreen ? "wide" : ""}`}>
        {loading && <LinearProgress />}
        {offlineMode && (
          <Button color="secondary" variant={"contained"} fullWidth onClick={this.reconnect}>
            Reconnect
          </Button>
        )}
        <LeaderboardToolbar
          name={game.name}
          code={game.code}
          date={game.date}
          isFinalScore={isFinalScore}
          widescreen={widescreen}
        />

        <LeaderboardActions
          game={game}
          user={user}
          multipleQueryType={isTeamLeaderboard && !isCombined}
          queryType={this.state.getGameByIdQueryType}
          onQueryTypeChanged={handleQueryTypeChanged}
          isGameClosed={this.state.isGameClosed}
          onRevealButtonClicked={this.onRevealButtonClicked}
          onGameClosed={this.handleGameClosed}
          widescreen={widescreen}
        />

        <TableContainer className={`${classes.tableContainer} ${widescreen ? "wide" : ""}`} ref={this.mainRef}>
          <Table id="leaderboard" className={classes.leaderboard} stickyHeader>
            {isTeamLeaderboard ? (
              <TeamLeaderboard
                widescreen={widescreen}
                isOrganizer={isOrganizer}
                trRefs={this.trRefs}
                openScoreCardDialog={this.openScoreCardDialog}
                calculateTeamPosition={this.calculatePosition}
                game={game}
                teams={game.teams}
                isStroke={isStroke}
              />
            ) : (
              <PlayersLeaderboard
                gameInfo={{
                  isStroke,
                  isFinalScore,
                  isPaidGame: game.isPaid,
                  isStandardGame: this.isStandardGame(),
                  isCombined,
                  scoresDisplayOrder: game.leaderboard.scoresDisplayOrder,
                  shouldPayBeforePlay: game.paymentRules?.payBeforePlay,
                  exactHandicaps: competition.handicapRules.exactHandicaps,
                  handicapAdjustment:
                    competition.handicapRules.adjustHandicapOnResult ||
                    competition.handicapRules.adjustHandicapOnPoints,
                  holesNumber: competition.holesNumber,
                  verifyQs: game.verifyQs,
                  widescreen,
                }}
                players={game.players}
                calculatePlayerPosition={this.calculatePosition}
                activeUserId={this.props.activeUserId || user._id}
                openScoreCardDialog={this.openScoreCardDialog}
                isOrganizer={isOrganizer}
                trRefs={this.trRefs}
              />
            )}
          </Table>
        </TableContainer>
        {this.renderPlayerScoreCardViewDialog()}
        {widescreen && (
          <AdvertSection
            clubLogo={game.course.club.logo}
            gameId={game._id}
            type={AdvertTypes.LEADER_BOARD}
            displayPageRefresh={this.state.displayPageRefresh}
          />
        )}
      </main>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    main: {
      position: "relative",
      width: "auto",
      minWidth: 300,
      maxWidth: 640,
      backgroundColor: "#FFF",
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      height: "100%",

      "&.wide": {
        minWidth: "100%",
        maxWidth: "100%",

        [theme.breakpoints.up("lg")]: {
          "& h2,h3,p,strong,td": {
            fontSize: "1.2em",
          },
          "& button.MuiButton-root": {
            fontSize: "1.1em",
          },
          "& th, td.MuiTableCell-body ": {
            fontSize: "1.3rem",
          },
        },
        [theme.breakpoints.up("xl")]: {
          "& h2,h3,p,strong,td": {
            fontSize: "1.4em",
          },
          "& button.MuiButton-root": {
            fontSize: "1.2em",
          },
          "& th, td.MuiTableCell-body ": {
            fontSize: "1.8rem",
          },
        },
      },

      [theme.breakpoints.up(550 + theme.spacing(6))]: {
        minWidth: 520,
        width: "fit-content",
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    headerCell: {
      fontWeight: "bold",
    },
    leaderboard: {
      backgroundColor: "#FFF",
    },
    tableContainer: {
      height: "auto",
      flex: 1,
      paddingBottom: 65,

      "&.wide": {
        [theme.breakpoints.up("md")]: {
          paddingBottom: ADVERT_VALUES.HEIGHT,
          paddingRight: ADVERT_VALUES.SIDEBAR_WIDTH,
        },
      },
    },
  });
const mapStateToProps = (state: GlobalState) => {
  const { loading } = state.general;
  const { user } = state.auth;
  return {
    user,
    loading,
  };
};

const LeaderboardWithScroll = withScroll(individualLeaderBoard);

export { closeDialog };
export default connect(mapStateToProps, {
  getGameById: GameActions.getById,
  getGameByCode: GameActions.getGameByCode,
  revealLeaderboard: GameActions.revealLeaderboard,
  addGameToVisitedLeaderBoard: GameActions.addGameToVisitedLeaderBoard,
  setUpdatedResult: GameActions.setUpdatedResult,
  setLeaderboardRevealResult: GameActions.setLeaderboardRevealResult,
  setGameFinished: GameActions.setGameFinished,
  getHandicapsByRuleId: HandicapActions.getHandicapsByRuleId,
})(withStyles(styles)(LeaderboardWithScroll));
