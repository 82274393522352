import React, { useState, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CameraAlt from "@material-ui/icons/CameraAlt";

import TextEditor from "components/TextEditor";
import { hideDialog } from "components/SharedComponents/Dialog";
import { buildFileSelector } from "utils/fileSelector";
import { ImageFileType } from "types/course";
import { useActions } from "hooks/useActions";
import Config from "config";
import { useStyles } from "./styles";

export interface LocalRulesProps {
  courseId: string;
  logo?: string;
  content?: string;
}

const fileSelector = buildFileSelector(false, "image/*");

const LocalRules: React.FC<LocalRulesProps> = ({ courseId, logo = "", content = "" }) => {
  const classes = useStyles();
  const { updateLocalRules } = useActions();
  const [image, setImage] = useState<ImageFileType | undefined>();
  const [text, setText] = useState<string>(content);

  const handleFileSelector = (evt: any) => {
    const file = evt.target.files[0];
    if (file) {
      file.preview = URL.createObjectURL(file);
      setImage(file);
    }
  };

  const handleAvatarClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault();
    fileSelector.click();
    fileSelector.onchange = handleFileSelector;
  };

  const handleSubmit = () => {
    updateLocalRules(courseId, { content: text, logo: image });
  };

  useEffect(() => {
    return () => {
      if (image) URL.revokeObjectURL(image.preview);
    };
  }, [image]);

  return (
    <div className={classes.root}>
      <div className={classes.avatarContainer}>
        <Avatar
          className={classes.avatar}
          alt="Club Logo"
          src={image ? image.preview : `${logo ? Config.API_URL + logo : ""}`}
        >
          Insert Logo
        </Avatar>
        <IconButton onClick={handleAvatarClick} size="small" className={classes.btn}>
          <CameraAlt className={classes.icon} />
        </IconButton>
      </div>

      <TextEditor html={content} onContentChange={setText} />

      <div className={classes.cta}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Save
        </Button>
        <Button variant="contained" color="secondary" onClick={hideDialog}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default LocalRules;
