import * as React from "react";
import BorderedBox from "../SharedComponents/BorderedBox";
import { Select, MenuItem, Typography, makeStyles, Theme } from "@material-ui/core";
import { HandicapAdjustment } from "../../types/competition";

export interface HandicapAdjustmentsProps {
  handicapAdjustment?: HandicapAdjustment;
  onHandicapAdjustmentSelectChanged: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
  error: boolean;
  isPrivateGame: boolean;
  holesNumber: number;
}

const HandicapAdjustmentSection: React.FunctionComponent<HandicapAdjustmentsProps> = (
  props: HandicapAdjustmentsProps,
) => {
  const classes = useStyles();
  const { handicapAdjustment: rules, isPrivateGame, holesNumber } = props;
  return (
    <BorderedBox title={"Handicap Adjustment"} variant={"h6"} error={props.error}>
      {isPrivateGame && (
        <div className={classes.inline}>
          <Typography variant={"subtitle1"}>Do you want apply WHS multiple gender/tee adjustment?</Typography>
          <Select
            name="adjustMultipleTeeGender"
            value={rules?.adjustMultipleTeeGender === undefined ? undefined : rules?.adjustMultipleTeeGender ? 1 : 0} // undefined isnt equal to No
            onChange={props.onHandicapAdjustmentSelectChanged}
          >
            <MenuItem value={1}>Yes</MenuItem>
            <MenuItem value={0}>No</MenuItem>
          </Select>
        </div>
      )}
      {holesNumber === 9 && (
        <>
          <div className={classes.inline}>
            <Typography variant={"subtitle1"}>Do you want apply WHS HI/2 adjustment?</Typography>
            <Select
              name="adjust9HolesRule"
              value={rules?.adjust9HolesRule ? 1 : 0}
              onChange={props.onHandicapAdjustmentSelectChanged}
            >
              <MenuItem value={1}>Yes</MenuItem>
              <MenuItem value={0}>No</MenuItem>
            </Select>
          </div>

          <div className={classes.inline}>
            <Typography variant={"subtitle1"}>Do you want apply Playing Handicap/2 adjustment ?</Typography>
            <Select
              name="adjust9HolesRulePH"
              value={rules?.adjust9HolesRulePH ? 1 : 0}
              onChange={props.onHandicapAdjustmentSelectChanged}
            >
              <MenuItem value={1}>Yes</MenuItem>
              <MenuItem value={0}>No</MenuItem>
            </Select>
          </div>
        </>
      )}
    </BorderedBox>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  inline: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

export default HandicapAdjustmentSection;
