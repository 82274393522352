import React from "react";
import Cookies from "js-cookie";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { useActions } from "hooks/useActions";
import { showSnackbar } from "components/SharedComponents/Notifier";
import { useStyles } from "./styles";

const COOKIE_NAME = "CookieConsent";

const CookieConsent: React.FC = () => {
  const classes = useStyles();
  const { logOut } = useActions();
  const [visible, setVisible] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (Cookies.get(COOKIE_NAME) === undefined) setVisible(true);
  }, []);

  const handleAcceptClick = () => {
    Cookies.set(COOKIE_NAME, "true", {
      expires: 365,
      sameSite: "lax",
      secure: window.location?.protocol === "https:",
    });
    setVisible(false);
  };

  const handleDeclineClick = () => {
    logOut();
    showSnackbar("In order to continue, cookies must be accepted as they are an integral part of Schala.", 5000);
  };

  return visible ? (
    <div className={classes.root}>
      <IconButton aria-label="close" className={classes.closeBtn} onClick={setVisible.bind(null, false)}>
        <CloseIcon />
      </IconButton>

      <p className={classes.text}>This website uses cookies to enhance the user experience.</p>
      <Button
        className={classes.btn}
        variant="contained"
        color="secondary"
        disableElevation
        onClick={handleDeclineClick}
      >
        Disagree
      </Button>
      <Button className={classes.btn} variant="contained" color="primary" disableElevation onClick={handleAcceptClick}>
        I agree
      </Button>
    </div>
  ) : null;
};

export default CookieConsent;
