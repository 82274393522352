import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { GlobalState } from "../../types/globalState";
import {
  Button,
  createStyles,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Typography,
  WithStyles,
} from "@material-ui/core";
import HoleParamTableRowInput from "./HoleParamTableRowInput";
import { HoleParam } from "../../types/hole";
import ScoreCardActions from "../../actions/scoreCards";
import CourseActions from "../../actions/courses";
import ClubActions from "../../actions/clubs";
import { ScoreCardInput, TeeColors } from "../../types/scoreCard";
import { showSnackbar } from "../SharedComponents/Notifier";
import LinearProgress from "@material-ui/core/LinearProgress";
import { hideDialog } from "../SharedComponents/Dialog";
import SearchInput, { SearchItem } from "../Search/SearchInput";
import { Club } from "../../types/club";
import BorderedBox from "../SharedComponents/BorderedBox";
import { Course } from "../../types/course";
import Selector, { Option } from "../SharedComponents/Selector";
import { TextInput } from "../SharedComponents/TextInput";
import { filter } from "../../utils/profanityFilter";
import { Gender, GenderType, Rules, User } from "types/user";
import { ScoreCardType } from "types/scoreCard";

interface Props extends WithStyles {
  insertNewScoreCard(data: ScoreCardInput): void;

  updateScoreCard(id: string, data: any): void;

  searchClubs(
    callback: any,
    query: string,
    page: number,
    limit: number,
    filter?: any,
    sort?: string,
    dir?: string,
  ): void;

  searchCourse(
    callback: any,
    query: string,
    page: number,
    limit: number,
    filter?: any,
    sort?: string,
    dir?: string,
  ): void;

  edit?: any;
  duplicate?: any;
  loading: boolean;
  user: User;
}
interface State {
  [index: string]: any;
  scoreCard: ScoreCardInput;
  err_club: boolean;
  err_name: boolean;
  err_cr: boolean;
  err_course: boolean;
  err_holesParams: boolean;
}

const cardTypes: Option[] = [
  { label: "Main", value: ScoreCardType.MAIN },
  { label: "Custom", value: ScoreCardType.CUSTOM },
];

///Refactor SearchClub and SearchCourse with /Search/* components
class ScoreCardSetup extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    let scoreCard: ScoreCardInput = {
      holesParams: null,
      name: "",
      course: null,
      //club: null,
      combination: 2,
      gender: Gender.Men,
      teeColor: "white",
      slope: 113,
      courseRating: 0,
      cardType: this._getDefaultCardType(),
    };
    if (props.edit) {
      scoreCard = props.edit;
    } else if (props.duplicate) {
      scoreCard = this._copyFields(props.duplicate);
      scoreCard._id = undefined;
      delete scoreCard._id;
    }
    this.state = {
      scoreCard,
      err_club: false,
      err_course: false,
      err_holesParams: false,
      err_name: false,
      err_cr: false,
      err_slope: false,
    };
  }

  _getDefaultCardType = (type?: ScoreCardType) => {
    return !this._hasFullAccess(this.props.user.rule) ? ScoreCardType.CUSTOM : type || ScoreCardType.MAIN;
  };

  _hasFullAccess = (role: Rules) => {
    const fullAccessRules = [Rules.Admin, Rules.ClubManager];
    return fullAccessRules.includes(role);
  };

  _copyFields = (sc: ScoreCardInput) => {
    return { ...sc, cardType: this._getDefaultCardType(sc.cardType) };
  };

  summation(holeParams: HoleParam[], key: string): number {
    let sum = 0;
    holeParams.forEach((param: HoleParam) => {
      if (param[key]) {
        sum += param[key] as number;
      }
    });
    return sum;
  }

  formValidation = (): boolean => {
    const { name, slope, course, holesParams, courseRating } = this.state.scoreCard;
    const club = course.club || this.state.scoreCard.club;
    let err_holesParams = false;
    const err_name = name.trim().length < 2 || filter.isProfane(name);
    const err_course = !course;
    const err_club = !club;
    const err_cr = !courseRating || courseRating === 0;
    const err_slope = !slope;

    if (holesParams) {
      holesParams.forEach((h: HoleParam) => {
        err_holesParams = err_holesParams || !h.yards || !h.par || !h.si;
      });
    } else {
      err_holesParams = true;
    }

    this.setState({ err_name, err_course, err_club, err_slope, err_cr, err_holesParams });

    return !(err_name || err_club || err_course || err_slope || err_cr || err_holesParams);
  };

  _onHoleParamChange = (holeParam: HoleParam, index: number) => {
    let { holesParams } = this.state.scoreCard;
    if (holesParams) {
      let tmp = [...holesParams];
      tmp[index] = holeParam;
      this.setState((state: State) => ({ scoreCard: { ...state.scoreCard, holesParams: tmp } }));
    }
  };
  _handleTextInputChange = (value: string, name: string) => {
    this.setState({
      scoreCard: { ...this.state.scoreCard, [name]: value },
    });
  };
  _handleTextFieldChange = (field: string) => (event: any) => {
    this.setState({
      scoreCard: { ...this.state.scoreCard, [field]: event.target.value },
    });
  };
  _onTeeColorChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState((state: State) => ({
      scoreCard: {
        ...state.scoreCard,
        teeColor: event.target.value as TeeColors,
      },
    }));
  };
  _onGenderChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const gender = event.target.value as GenderType;
    const teeColor = gender === Gender.Women ? "red" : "white";
    this.setState((state: State) => ({ scoreCard: { ...state.scoreCard, gender, teeColor } }));
  };
  _submitForm = () => {
    const { edit } = this.props;
    const scoreCard = { ...this.state.scoreCard };
    const { _id, club, ...typedFields } = scoreCard;
    const searchedClub = scoreCard.course.club || scoreCard.club;
    const newScoreCard = {
      ...typedFields,
      name: scoreCard.name.trim(),
      course: { ...typedFields.course, club: searchedClub },
    };
    if (this.formValidation()) {
      if (edit) {
        this.props.updateScoreCard(edit._id, newScoreCard);
      } else {
        this.props.insertNewScoreCard(newScoreCard);
      }
    } else {
      showSnackbar("Form is not valid, check items");
    }
  };

  generateHoles = (holesNumber: number) => {
    //Too fucking complicated!
    if (!this.state.scoreCard.holesParams) {
      let holesParams: any = [];
      for (let i = 1; i <= holesNumber; i++) {
        holesParams.push({ number: i, yards: null, par: null, si: null });
      }
      this.setState((state: State) => ({
        scoreCard: {
          ...state.scoreCard,
          holesParams,
        },
      }));
    } else {
      if (holesNumber < this.state.scoreCard.holesParams.length) {
        this.setState((state: State) => ({
          scoreCard: {
            ...state.scoreCard,
            holesParams: state.scoreCard.holesParams ? state.scoreCard.holesParams.splice(0, holesNumber) : null,
          },
        }));
      } else if (holesNumber > this.state.scoreCard.holesParams.length) {
        let holesParams: any = [...this.state.scoreCard.holesParams];
        for (let i = holesParams.length; i <= holesNumber; i++) {
          holesParams.push({ number: i, yards: null, par: null, si: null });
        }
        this.setState((state: State) => ({
          scoreCard: {
            ...state.scoreCard,
            holesParams,
          },
        }));
      }
    }
  };

  _clubSearchHandler = (query: string) => {
    return new Promise((resolve, reject) => {
      this.props.searchClubs(
        (err: any, result: any) => {
          if (!err) {
            resolve(
              result.data.items.map((item: Club) => ({
                value: item._id,
                title: item.name,
              })),
            );
          } else {
            reject(err);
          }
        },
        query,
        1,
        10,
        undefined,
        "name",
        "desc",
      );
    });
  };
  _onClubSelected = (item: SearchItem) => {
    const club: any = item ? { id: item.value, name: item.title } : null;

    if (club) {
      this.setState((state: State) => ({
        err_club: false,
        scoreCard: { ...state.scoreCard, club },
      }));
    } else {
      this.setState((state: State) => ({
        scoreCard: { ...state.scoreCard, club: null, course: null },
      }));
    }
  };
  _courseSearchHandler = (query: string) => {
    const { club } = this.state.scoreCard;
    return new Promise((resolve, reject) => {
      this.props.searchCourse(
        (err: any, result: any) => {
          if (!err) {
            resolve(
              result.data.items.map((item: Course) => ({
                value: item._id,
                title: item.name,
                subtitle: `${item.holesNumber} Holes`,
                object: item,
              })),
            );
          } else {
            reject(err);
          }
        },
        query,
        1,
        10,
        { club: club.id },
        "name",
        "desc",
      );
    });
  };
  _onCourseSelected = (item: SearchItem) => {
    let course = item && item.object ? item.object : null;

    if (course) {
      delete course.club;
      this.setState((state: State) => ({
        scoreCard: {
          ...state.scoreCard,
          course,
        },
      }));
      this.generateHoles(course.holesNumber);
    } else {
      this.setState((state: State) => ({
        scoreCard: {
          ...state.scoreCard,
          course: null,
        },
      }));
    }
  };
  _onChangeCardCombination = (combination: 2 | 3) => {
    this.setState((state: State) => ({ scoreCard: { ...state.scoreCard, combination } }));
    this.generateHoles(this.state.scoreCard.course.holesNumber);
  };

  _handleCardTypeChange = (type: ScoreCardType) => {
    this.setState({
      scoreCard: { ...this.state.scoreCard, cardType: type },
    });
  };

  renderActionButton() {
    const { classes, loading, edit } = this.props;
    if (loading)
      return (
        <div className={classes.loadingContainer}>
          <LinearProgress color="primary" variant={"indeterminate"} />
        </div>
      );
    else
      return (
        <div className={classes.actionButtonContainer}>
          <Button
            fullWidth
            className={this.props.classes.actionButton}
            variant={"contained"}
            color={"primary"}
            onClick={this._submitForm}
          >
            Save
          </Button>
          <Button
            fullWidth
            className={this.props.classes.actionButton}
            variant={"contained"}
            color={"secondary"}
            onClick={hideDialog}
          >
            Cancel
          </Button>
        </div>
      );
  }
  renderCombinationSelector(holesNumber: number) {
    const { combination } = this.state.scoreCard;
    const options: { [index: number]: Option[] } = {
      18: [
        { value: 2, label: "2x9" },
        { value: 3, label: "3x6" },
      ],
    };
    if (options[holesNumber]) {
      return (
        <Selector
          options={options[holesNumber]}
          value={combination}
          variant={"outlined"}
          onChange={this._onChangeCardCombination}
        />
      );
    } else {
      return null;
    }
  }

  renderOptionsTable = () => {
    const { classes } = this.props;
    const { teeColor, gender, slope, courseRating } = this.state.scoreCard;
    const { err_cr, err_slope } = this.state;
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Gender</TableCell>
            <TableCell>Tee</TableCell>
            <TableCell>CR</TableCell>
            <TableCell>Slope</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <FormControl className={classes.inline} margin={"dense"}>
                <Select
                  labelId="gender"
                  id="gender"
                  value={gender}
                  onChange={this._onGenderChange}
                  // input={<BootstrapInput />}
                >
                  <MenuItem value={"men"}>Men</MenuItem>
                  <MenuItem value={"women"}>Ladies</MenuItem>
                </Select>
              </FormControl>
            </TableCell>
            <TableCell>
              <FormControl className={classes.inline} margin={"dense"}>
                <Select labelId="teeColor" id="teeColor" value={teeColor} onChange={this._onTeeColorChange}>
                  <MenuItem value={"white"}>White</MenuItem>
                  <MenuItem value={"yellow"}>Yellow</MenuItem>
                  <MenuItem value={"red"}>Red</MenuItem>
                  <MenuItem value={"blue"}>Blue</MenuItem>
                  <MenuItem value={"green"}>Green</MenuItem>
                  <MenuItem value={"orange"}>Orange</MenuItem>
                  <MenuItem value={"black"}>Black</MenuItem>
                  <MenuItem value={"gold"}>Gold</MenuItem>
                  <MenuItem value={"purple"}>Purple</MenuItem>
                  <MenuItem value={"tiger"}>Tiger</MenuItem>
                </Select>
              </FormControl>
            </TableCell>
            <TableCell>
              <TextField
                type={"number"}
                value={courseRating}
                error={err_cr}
                onChange={this._handleTextFieldChange("courseRating")}
                className={classes.width_48}
              />
            </TableCell>
            <TableCell>
              <TextField
                type={"number"}
                value={slope}
                error={err_slope}
                inputProps={{ min: 55, max: 155 }}
                onChange={this._handleTextFieldChange("slope")}
                className={classes.width_48}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  };

  renderHolesTable() {
    const { course, combination } = this.state.scoreCard;
    if (course) {
      switch (course.holesNumber) {
        case 18: {
          if (combination === 3) {
            return (
              <>
                {this.renderOptionsTable()}
                {this.renderTable_3x6()}
              </>
            );
          } else {
            return (
              <>
                {this.renderOptionsTable()}
                {this.renderTable_2x9()}
              </>
            );
          }
        }
        case 12: {
          return (
            <>
              {this.renderOptionsTable()}
              {this.renderTable_2x6()}
            </>
          );
        }
        case 9: {
          return (
            <>
              {this.renderOptionsTable()}
              {this.renderTable_1x9()}
            </>
          );
        }
        case 6: {
          return (
            <>
              {this.renderOptionsTable()}
              {this.renderTable_1x6()}
            </>
          );
        }
      }
    } else {
      return null;
    }
  }
  renderTable_1x9() {
    const { holesParams } = this.state.scoreCard;
    if (holesParams) {
      return (
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Hole</TableCell>
              <TableCell>Yards</TableCell>
              <TableCell>Par</TableCell>
              <TableCell>Stroke Index</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {holesParams.slice(0, 9).map((holesParam, index) => (
              <HoleParamTableRowInput holeParam={holesParam} index={index} onChange={this._onHoleParamChange} />
            ))}
            <TableRow>
              <TableCell>
                <Typography variant={"body2"}>Total(1-9)</Typography>
              </TableCell>
              <TableCell>{this.summation(holesParams.slice(0, 9), "yards")}</TableCell>
              <TableCell>{this.summation(holesParams.slice(0, 9), "par")}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      );
    } else {
      return null;
    }
  }
  renderTable_2x9() {
    const { holesParams } = this.state.scoreCard;
    if (holesParams) {
      return (
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Hole</TableCell>
              <TableCell>Yards</TableCell>
              <TableCell>Par</TableCell>
              <TableCell>Stroke Index</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {holesParams.slice(0, 9).map((holesParam, index) => (
              <HoleParamTableRowInput holeParam={holesParam} index={index} onChange={this._onHoleParamChange} />
            ))}
            <TableRow>
              <TableCell>
                <Typography variant={"body2"}>Total(1-9)</Typography>
              </TableCell>
              <TableCell>{this.summation(holesParams.slice(0, 9), "yards")}</TableCell>
              <TableCell>{this.summation(holesParams.slice(0, 9), "par")}</TableCell>
              <TableCell></TableCell>
            </TableRow>
            {holesParams.slice(9, 18).map((holesParam, index) => (
              <HoleParamTableRowInput holeParam={holesParam} index={index + 9} onChange={this._onHoleParamChange} />
            ))}
            <TableRow>
              <TableCell>
                <Typography variant={"body2"}>Total(10-18)</Typography>
              </TableCell>
              <TableCell>{this.summation(holesParams.slice(9, 18), "yards")}</TableCell>
              <TableCell>{this.summation(holesParams.slice(9, 18), "par")}</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant={"body2"}>Total(1-18)</Typography>
              </TableCell>
              <TableCell>{this.summation(holesParams, "yards")}</TableCell>
              <TableCell>{this.summation(holesParams, "par")}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      );
    } else return null;
  }
  renderTable_1x6() {
    const { holesParams } = this.state.scoreCard;
    if (holesParams) {
      return (
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Hole</TableCell>
              <TableCell>Yards</TableCell>
              <TableCell>Par</TableCell>
              <TableCell>Stroke Index</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {holesParams.slice(0, 6).map((holesParam, index) => (
              <HoleParamTableRowInput holeParam={holesParam} index={index} onChange={this._onHoleParamChange} />
            ))}
            <TableRow>
              <TableCell>
                <Typography variant={"body2"}>Total(1-6)</Typography>
              </TableCell>
              <TableCell>{this.summation(holesParams.slice(0, 6), "yards")}</TableCell>
              <TableCell>{this.summation(holesParams.slice(0, 6), "par")}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      );
    } else {
      return null;
    }
  }
  renderTable_2x6() {
    const { holesParams } = this.state.scoreCard;
    if (holesParams) {
      return (
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Hole</TableCell>
              <TableCell>Yards</TableCell>
              <TableCell>Par</TableCell>
              <TableCell>Stroke Index</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {holesParams.slice(0, 6).map((holesParam, index) => (
              <HoleParamTableRowInput holeParam={holesParam} index={index} onChange={this._onHoleParamChange} />
            ))}
            <TableRow>
              <TableCell>
                <Typography variant={"body2"}>Total(1-6)</Typography>
              </TableCell>
              <TableCell>{this.summation(holesParams.slice(0, 6), "yards")}</TableCell>
              <TableCell>{this.summation(holesParams.slice(0, 6), "par")}</TableCell>
              <TableCell></TableCell>
            </TableRow>
            {holesParams.slice(6, 12).map((holesParam, index) => (
              <HoleParamTableRowInput holeParam={holesParam} index={index + 6} onChange={this._onHoleParamChange} />
            ))}
            <TableRow>
              <TableCell>
                <Typography variant={"body2"}>Total(6-12)</Typography>
              </TableCell>
              <TableCell>{this.summation(holesParams.slice(6, 12), "yards")}</TableCell>
              <TableCell>{this.summation(holesParams.slice(6, 12), "par")}</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant={"body2"}>Total(1-12)</Typography>
              </TableCell>
              <TableCell>{this.summation(holesParams, "yards")}</TableCell>
              <TableCell>{this.summation(holesParams, "par")}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      );
    } else {
      return null;
    }
  }
  renderTable_3x6() {
    const { holesParams } = this.state.scoreCard;
    if (holesParams) {
      return (
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Hole</TableCell>
              <TableCell>Yards</TableCell>
              <TableCell>Par</TableCell>
              <TableCell>Stroke Index</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {holesParams.slice(0, 6).map((holesParam, index) => {
              return (
                <>
                  <HoleParamTableRowInput holeParam={holesParam} index={index} onChange={this._onHoleParamChange} />
                </>
              );
            })}
            <TableRow>
              <TableCell>
                <Typography variant={"body2"}>Total(1-6)</Typography>
              </TableCell>
              <TableCell>{this.summation(holesParams.slice(0, 6), "yards")}</TableCell>
              <TableCell>{this.summation(holesParams.slice(0, 6), "par")}</TableCell>
              <TableCell></TableCell>
            </TableRow>
            {holesParams.slice(6, 12).map((holesParam, index) => {
              return (
                <>
                  <HoleParamTableRowInput holeParam={holesParam} index={index + 6} onChange={this._onHoleParamChange} />
                </>
              );
            })}
            <TableRow>
              <TableCell>
                <Typography variant={"body2"}>Total(6-12)</Typography>
              </TableCell>
              <TableCell>{this.summation(holesParams.slice(6, 12), "yards")}</TableCell>
              <TableCell>{this.summation(holesParams.slice(6, 12), "par")}</TableCell>
              <TableCell></TableCell>
            </TableRow>
            {holesParams.slice(12, 18).map((holesParam, index) => {
              return (
                <>
                  <HoleParamTableRowInput
                    holeParam={holesParam}
                    index={index + 12}
                    onChange={this._onHoleParamChange}
                  />
                </>
              );
            })}
            <TableRow>
              <TableCell>
                <Typography variant={"body2"}>Total(12-18)</Typography>
              </TableCell>
              <TableCell>{this.summation(holesParams.slice(12, 18), "yards")}</TableCell>
              <TableCell>{this.summation(holesParams.slice(12, 18), "par")}</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant={"body2"}>Total(1-12)</Typography>
              </TableCell>
              <TableCell>{this.summation(holesParams, "yards")}</TableCell>
              <TableCell>{this.summation(holesParams, "par")}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      );
    } else {
      return null;
    }
  }

  render() {
    const { classes, user, edit } = this.props;
    const { name, course, cardType } = this.state.scoreCard;

    if (edit && cardType === ScoreCardType.MAIN && !this._hasFullAccess(user.rule)) {
      hideDialog();
      showSnackbar("You are not allowed to do this action.");
      return null;
    }

    const { club } = course || {};
    const { err_name, err_club, err_course } = this.state;
    const selectedClub = club ? { value: club._id, title: club.name, subtitle: club.address } : undefined;
    const selectedCourse = course
      ? { value: course._id, title: course.name, subtitle: `${course.holesNumber} Holes` }
      : undefined;

    return (
      <Paper className={classes.paper}>
        <Typography variant={"h5"} className={classes.title}>
          Add Score Card
        </Typography>
        {this._hasFullAccess(this.props.user.rule) ? (
          <Selector fullWidth options={cardTypes} value={cardType} onChange={this._handleCardTypeChange} />
        ) : null}
        <BorderedBox title={"Name"} variant={"h6"} padding={"none"}>
          <TextInput
            label={"Score card name"}
            margin={"dense"}
            variant={"outlined"}
            error={err_name}
            fullWidth
            captalize
            name={"name"}
            onChange={this._handleTextInputChange}
            value={name}
          />
        </BorderedBox>
        <BorderedBox title={"Club"} variant={"h6"} padding={"none"} error={err_club}>
          <SearchInput
            dialog
            minChar={2}
            label={"Input clubs name"}
            placeholder={"Click to search clubs"}
            defaultSelectedItem={selectedClub}
            searchHandler={this._clubSearchHandler}
            onItemSelected={this._onClubSelected}
          />
        </BorderedBox>
        <BorderedBox title={"Course"} variant={"h6"} padding={"none"} error={err_course}>
          <div className={classes.inline}>
            <SearchInput
              dialog
              minChar={2}
              className={classes.fullWidth}
              label={"Input course name"}
              placeholder={"Click to search courses"}
              defaultSelectedItem={selectedCourse}
              searchHandler={this._courseSearchHandler}
              onItemSelected={this._onCourseSelected}
            />
            {this.renderCombinationSelector(course ? course.holesNumber : null)}
          </div>
        </BorderedBox>
        {this.renderHolesTable()}
        {this.renderActionButton()}
      </Paper>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    title: {
      fontWeight: "bold",
    },
    paper: {
      display: "block",
      position: "relative",
      left: "50%",
      transform: "translateX(-50%)",
      width: "auto",
      maxWidth: 500,
      backgroundColor: "rgba(255,255,552,1)",
      padding: theme.spacing(2),
    },
    inline: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    width_48: {
      width: 48,
    },
    actionButton: {
      margin: theme.spacing(1),
    },
    fullWidth: {
      width: "100%",
    },
    loadingContainer: {
      width: "100%",
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    actionButtonContainer: {
      display: "flex",
    },
  });
const mapStateToProps = ({ general: { loading }, auth: { user } }: GlobalState) => {
  return {
    loading,
    user,
  };
};

export default connect(mapStateToProps, {
  searchClubs: ClubActions.search,
  searchCourse: CourseActions.search,
  insertNewScoreCard: ScoreCardActions.insert,
  updateScoreCard: ScoreCardActions.update,
})(withStyles(styles)(ScoreCardSetup));
