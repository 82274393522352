import React from "react";
import Config from "../../config";
import Dialog from "@material-ui/core/Dialog";
import { Advert } from "../../types/advert";
import AdvertAction from "../../actions/adverts";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";

let showAdvert = (gameId: string, advert: Advert, holeNumber: number, seeAgain = false, showTimer = true) => {};

interface State {
  gameId: string;
  open: boolean;
  advert?: Advert;
  holeNumber: number;
  closable: boolean;
  timer: number;
  staticTimer: number;
}
interface Props {
  seen(gameId: string, holeNumber: number): void;
}

class AdvertDisplayer extends React.Component<Props, State> {
  interval: any;
  constructor(props: any) {
    super(props);
    this.state = {
      gameId: "",
      open: false,
      holeNumber: 0,
      closable: false,
      timer: 5,
      staticTimer: 10,
    };
  }

  componentDidMount() {
    showAdvert = this.showAdvert;
  }
  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  showAdvert = (gameId: string, advert: Advert, holeNumber: number, seeAgain = false, showTimer = true) => {
    if (!advert || !advert.holesInfo || !advert.holesInfo[holeNumber]) return;
    if (!advert.holesInfo[holeNumber].seen || seeAgain) {
      this.setState({ gameId, advert, holeNumber, open: true });
      if (!showTimer) this.setState({ timer: 0, closable: true });
      else
        this.interval = setInterval(() => {
          this.setState((state: State) => {
            if (this.state.timer <= -this.state.staticTimer) {
              this.closeDialog();
            }
            return {
              timer: state.timer - 1,
              closable: state.timer <= 1,
            };
          });
        }, 1000);
    }
  };

  closeDialog = () => {
    if (this.state.closable) {
      this.props.seen(this.state.gameId, this.state.holeNumber);
      this.setState({ open: false, timer: 5, closable: false });
      if (this.interval) {
        clearInterval(this.interval);
      }
    }
  };
  _onDisagree = () => {
    this.closeDialog();
  };
  _onAgree = () => {
    this.closeDialog();
  };

  render() {
    const { advert, holeNumber, timer, closable } = this.state;
    if (!advert) return null;
    if (!advert!.holesInfo[holeNumber]) return null;
    if (advert!.holesInfo[holeNumber].state !== "active") return null;
    if (!advert!.holesInfo[holeNumber].image || advert!.holesInfo[holeNumber].image === "") return null;
    const dialogType = advert!.holesInfo[holeNumber].type;
    return (
      <div>
        <Dialog
          style={{
            position: "absolute",
            top: dialogType === "bottom" ? "unset" : 0,
            bottom: dialogType === "top" ? "unset" : 0,
          }}
          open={this.state.open}
          fullScreen={dialogType === "fs"}
          onBackdropClick={this.closeDialog}
        >
          <img src={Config.API_URL + advert!.holesInfo[holeNumber].image} className={"fullScreen padding: 8px;"} />
          <Button
            variant={"text"}
            // disabled={!closable}
            style={{
              position: "absolute",
              top: 16,
              right: 16,
              zIndex: 100,
              background: "rgba(255,255,255,0.51)",
              color: "#000",
            }}
            onClick={this.closeDialog}
          >
            {closable ? "X" : `Skip in ${timer} sec`}
          </Button>
        </Dialog>
      </div>
    );
  }
}

export { showAdvert };

export default connect(undefined, {
  seen: AdvertAction.seen,
})(AdvertDisplayer);
