import { Action } from "../types/actions";
import ReduxTypes, { Keys } from "../consts/ReduxTypes";
import { deleteReducer, fetchReducer, insertReducer, updateReducer } from "./BaseReducerFunctions";
import { ClubState } from "../types/club";

const INITIAL_STATE = {
  clubs: {},
  count: 0,
  relatedScorecards: {},
  games: [],
};
const KEY: Keys = "CLUBS";

export default (state: ClubState = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case ReduxTypes.getInsertType(KEY):
      return insertReducer(state, action, "clubs");

    case ReduxTypes.getFetchType(KEY):
      return fetchReducer(state, action, "clubs");

    case ReduxTypes.getDeleteType(KEY):
      return deleteReducer(state, action, "clubs");

    case ReduxTypes.getUpdateType(KEY):
      return updateReducer(state, action, "clubs");

    case ReduxTypes.FETCH_CLUB_GAMES:
      return { ...state, games: action.payload };

    case ReduxTypes.ADD_TO_FAV: {
      let clubs = { ...state.clubs };
      clubs[action.id].favourite = true;
      return { ...state, clubs };
    }
    case ReduxTypes.REMOVE_FROM_FAV: {
      let clubs = { ...state.clubs };
      clubs[action.id].favourite = false;
      return { ...state, clubs };
    }

    case ReduxTypes.PURGE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
