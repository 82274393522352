import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import CompetitionActions from "../../actions/competitions";
import { GlobalState } from "../../types/globalState";
import BorderedBox from "../SharedComponents/BorderedBox";
import {
  Button,
  createStyles,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Switch,
  Theme,
  Typography,
  WithStyles,
} from "@material-ui/core";
import { showSnackbar } from "../SharedComponents/Notifier";
import {
  Competition,
  PlayingRulesType,
  ExpireRules,
  TeamHandicapAllowance,
  PlayingFormats,
  PlayingAsFormats,
  CompetitionInput,
  HandicapRules as HandicapRulesType,
  Eligibility,
  GameRules,
  RuleType,
} from "../../types/competition";
import LinearProgress from "@material-ui/core/LinearProgress";
import { hideDialog } from "../SharedComponents/Dialog";
import Selector, { Option } from "../SharedComponents/Selector";
import Config from "../../config";
import PlayingRules from "./PlayingRules";
import HandicapRules from "./HandicapRules";
import { TextInput } from "../SharedComponents/TextInput";
import { filter } from "../../utils/profanityFilter";
import { HoleNumbers } from "../../types/course";
import { Rules, User, UserBrifObj } from "../../types/user";
import { GameAdditionalOrganisersTable as OrganisersTable } from "components/Game/GameAdditionalOrganiserTable";
import HandicapsExpireRules from "./ExpireRules";
import HandicapAllowance from "./HandicapAllowance";
import HandicapAdjustmentSection from "./HandicapAdjustment";
import HolesNumberPicker from "./HolesNumberPicker";
import CompetitionEligability from "./CompetitionEligability";

interface Props extends WithStyles {
  insertCompetition(data: CompetitionInput): void;
  updateCompetition(id: string, data: CompetitionInput): void;

  loading: boolean;
  user: User;
  edit?: Competition;
  copy?: Competition;
}

interface State {
  competition: CompetitionInput;
  err_name?: boolean;
  err_eligibility?: boolean;
  err_intro?: boolean;
  err_expire?: boolean;
  err_hcpAdjustment: boolean;
  loading?: boolean;
}

class CompetitionSetup extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const playingRules: PlayingRulesType = {
      playingFormat: PlayingFormats.Individual,
      playingAs: PlayingAsFormats.Individual,
      teamPlayers: 2,
      teamsPerTee: 1,
      bestOne: [],
      bestTwo: [],
      bestThree: [],
      bestFour: [],
      isRelatedToCourseHoles: true,
      minimumTeeShots: 0,
      multiRules: false,
    };

    const handicapRules = {
      adjustHandicapOnResult: false,
      adjustHandicapOnPoints: false,
      customHandicapCategories: false,
      aboveHIadjustment: true,
      disregardDnp: false,
      sharedPrize: false,
      exactHandicaps: false,
      usingNumberInFirstTimeHandicapAdjustment: true,
      firstTimeHandicapAdjustment: 0,
      handicapDeducting: [],
      losersHandicapDeducting: [],
      handicapAdjustmentRules: [],
    };
    const teamHandicapAllowance: TeamHandicapAllowance = {
      hcpSortedAllowances: [100, 100, 100, 100],
      combinedTeam: 100,
    };

    let competition: CompetitionInput = {
      type: this._getDefaultRuleType(),
      name: "",
      introduction: "",
      scoringFormat: "stableford",
      individual: 4,
      eligibility: [
        {
          exist: false,
          gender: "men",
          maxHandicap: 54,
          minHandicap: 0,
        },
        {
          exist: false,
          gender: "women",
          maxHandicap: 54,
          minHandicap: 0,
        },
      ],
      playingRules: playingRules,
      handicapRules: handicapRules,
      teamHandicapAllowance: teamHandicapAllowance,
      fullHandicapAllowance: 100,
      guests: false,
      expireRules: { isActive: true, minGames: 0, duringMonths: 0, minMonths: 0 },
      holesNumber: 18,
    };

    if (props.edit) competition = this._copyFields(props.edit, playingRules, handicapRules, teamHandicapAllowance);
    else if (props.copy) competition = this._copyFields(props.copy, playingRules, handicapRules, teamHandicapAllowance);

    this.state = {
      competition,
      loading: false,
      err_intro: false,
      err_name: false,
      err_expire: false,
      err_eligibility: false,
      err_hcpAdjustment: false,
    };
  }

  _copyFields = (cmp: Competition, pr: PlayingRulesType, hr: HandicapRulesType, th: TeamHandicapAllowance) => {
    const { _id, accessor, ...competition } = cmp;
    competition.type = this._getDefaultRuleType(competition.type);

    if (!competition.playingRules) competition.playingRules = pr;
    if (!competition.handicapRules) competition.handicapRules = hr;
    if (!competition.teamHandicapAllowance) competition.teamHandicapAllowance = th;
    return competition;
  };

  _getDefaultRuleType = (type?: GameRules) => {
    return this.props.user.rule !== Rules.Admin ? GameRules.CUSTOM : type || GameRules.BASIC;
  };

  formValidation = () => {
    const { name, introduction, type, expireRules, handicapAdjustment, handicapRules } = this.state.competition;
    const err_name = name.trim().length < 2 || filter.isProfane(name);
    const err_intro = filter.isProfane(introduction);
    const eligibility = this.state.competition.eligibility;
    const err_eligibility = !eligibility[0].exist && !eligibility[1].exist;
    const err_hcpAdjustment =
      type === GameRules.CUSTOM && (!handicapAdjustment || handicapAdjustment.adjustMultipleTeeGender === undefined);
    const err_hcpAbove = type === GameRules.CUSTOM && (!handicapRules || handicapRules.aboveHIadjustment === undefined);

    const err_expire =
      type === GameRules.CUSTOM &&
      expireRules?.isActive &&
      (!expireRules?.minMonths || !expireRules?.minGames || !expireRules?.duringMonths);
    this.setState({ err_name, err_intro, err_eligibility, err_expire, err_hcpAdjustment });

    if (err_hcpAbove) showSnackbar("Please determine if players can go above their PH.");

    return !(err_name || err_intro || err_eligibility || err_expire || err_hcpAdjustment || err_hcpAbove);
  };

  handleTextInputChange = (value: string, name: string) => {
    this.setState({
      competition: { ...this.state.competition, [name]: value },
    });
  };

  handleGuestSwitchChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      competition: {
        ...this.state.competition,
        guests: event.target.checked as boolean,
      },
    });
  };

  handleEligibilityChanged = (eligibility: Eligibility[]) => {
    this.setState({
      competition: { ...this.state.competition, eligibility },
    });
  };

  _handleAccessorChange = (organisers: UserBrifObj[]) => {
    this.setState({
      competition: { ...this.state.competition, accessor: organisers },
    });
  };

  _onScoringFormatChanges = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({
      competition: {
        ...this.state.competition,
        scoringFormat: event.target.value as string,
      },
    });
  };
  _onIndividualChanges = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({
      competition: {
        ...this.state.competition,
        individual: event.target.value as number,
      },
    });
  };

  handleHandicapAllowanceChanged = (value: {
    fullHandicapAllowance: number;
    teamHandicapAllowance: TeamHandicapAllowance;
  }) => {
    this.setState({
      competition: {
        ...this.state.competition,
        fullHandicapAllowance: value.fullHandicapAllowance,
        teamHandicapAllowance: value.teamHandicapAllowance,
      },
    });
  };

  ruleTypes: Option[] = [
    { label: "Standard (basic)", value: GameRules.BASIC },
    { label: "Standard (WHS)", value: GameRules.WHS },
    { label: "Custom", value: GameRules.CUSTOM },
  ];

  _handleRuleTypeChange = (type: RuleType) => {
    this.setState({
      competition: { ...this.state.competition, type },
    });
  };

  onChangeHoleNumbers = (value: string | number) => {
    this.setState({
      competition: {
        ...this.state.competition,
        holesNumber: value as HoleNumbers,
      },
    });
  };

  handleHandicapAdjustmentSelectChanges = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    if (!event.target.name) return;
    this.setState({
      competition: {
        ...this.state.competition,
        handicapAdjustment: {
          ...this.state.competition.handicapAdjustment,
          [event.target.name]: event.target.value === 1,
        },
      },
    });
  };

  handleExpireRulesSwitchChanged = (field: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const expireRules = {
      ...this.state.competition.expireRules,
      [field]: event.target.checked as boolean,
    } as ExpireRules;

    this.setState({
      competition: {
        ...this.state.competition,
        expireRules,
      },
    });
  };

  handleHandicapExpireRulesChanged = (expireRules: ExpireRules) => {
    this.setState({
      competition: {
        ...this.state.competition,
        expireRules,
      },
    });
  };

  handleHandicapRulesChanged = (handicapRules: HandicapRulesType) => {
    this.setState({
      competition: {
        ...this.state.competition,
        handicapRules,
      },
    });
  };

  handlePlayingRulesChanged = (playingRules: PlayingRulesType) => {
    this.setState({
      competition: {
        ...this.state.competition,
        playingRules,
      },
    });
  };

  _validateTeamRules = (cmp: CompetitionInput) => {
    const newCompetition = { ...cmp, name: cmp.name.trim(), introduction: cmp.introduction.trim() };

    if (!cmp.playingRules.multiRules) return newCompetition;

    if (!Array.isArray(cmp.playingRules.minimumTeeShots)) {
      const value = cmp.playingRules.minimumTeeShots || 0;
      newCompetition.playingRules.minimumTeeShots = Array.from({ length: cmp.playingRules.teamPlayers - 1 }).fill(
        value,
      ) as number[];
    }

    if (!Array.isArray(cmp.teamHandicapAllowance.combinedTeam)) {
      const value = cmp.teamHandicapAllowance.combinedTeam || 100;
      newCompetition.teamHandicapAllowance.combinedTeam = Array.from({ length: cmp.playingRules.teamPlayers - 1 }).fill(
        value,
      ) as number[];
    }

    return newCompetition;
  };

  _submitForm = () => {
    const {
      competition: { owner, active, ...cmp },
    } = this.state;
    const { edit } = this.props;
    const newCompetition = this._validateTeamRules(cmp);

    if (this.formValidation()) {
      if (edit) {
        this.props.updateCompetition(edit._id, newCompetition);
      } else {
        this.props.insertCompetition(newCompetition);
      }
    } else {
      showSnackbar("Form is not valid, check items");
    }
  };

  renderActionButton() {
    const { classes, loading } = this.props;
    if (loading)
      return (
        <div className={classes.loadingContainer}>
          <LinearProgress color="primary" variant={"indeterminate"} />
        </div>
      );
    else
      return (
        <div className={classes.actionButtonContainer}>
          <Button
            fullWidth
            className={classes.actionButton}
            variant={"contained"}
            color={"primary"}
            onClick={this._submitForm}
          >
            Save
          </Button>
          <Button
            fullWidth
            className={classes.actionButton}
            variant={"contained"}
            color={"secondary"}
            onClick={hideDialog}
          >
            Cancel
          </Button>
        </div>
      );
  }

  render() {
    const { classes } = this.props;
    const {
      name,
      type,
      introduction,
      eligibility,
      fullHandicapAllowance,
      guests,
      individual,
      scoringFormat,
      handicapRules,
      playingRules,
      holesNumber,
      expireRules,
      teamHandicapAllowance,
      handicapAdjustment,
      accessor,
    } = this.state.competition;

    const { err_name, err_intro, err_eligibility, err_expire, err_hcpAdjustment } = this.state;
    const isTeamGame = playingRules.playingAs === PlayingAsFormats.Team;
    const isCombinedGame = isTeamGame && playingRules.playingFormat === PlayingFormats.Combined;
    const isPrivate = type === GameRules.CUSTOM;

    return (
      <Paper className={classes.paper}>
        <Typography variant={"h5"} className={classes.title}>
          Game Rule Setup
        </Typography>
        <div>
          {this.props.user.rule === Rules.Admin ? (
            <Selector options={this.ruleTypes} value={type} onChange={this._handleRuleTypeChange} />
          ) : null}
          <TextInput
            label={"Name"}
            margin={"dense"}
            variant={"outlined"}
            value={name}
            error={err_name}
            captalize
            name={"name"}
            onChange={this.handleTextInputChange}
            fullWidth
          />
          <TextInput
            label={"Information"}
            margin={"dense"}
            variant={"outlined"}
            value={introduction}
            error={err_intro}
            name={"introduction"}
            onChange={this.handleTextInputChange}
            rows={5}
            multiline
            fullWidth
          />
        </div>
        <BorderedBox title={"Scoring Format"} variant={"h6"}>
          <div className={classes.inline}>
            <FormControl className={classes.inline} margin={"dense"}>
              <Select id="scoring-format" value={scoringFormat} onChange={this._onScoringFormatChanges}>
                <MenuItem value={Config.SCORING_FORMAT.STABLEFORD}>Stableford</MenuItem>
                <MenuItem value={Config.SCORING_FORMAT.STROKE}>Stroke</MenuItem>
              </Select>
            </FormControl>
            <FormControl>
              <Select id="individual" value={individual} onChange={this._onIndividualChanges}>
                <MenuItem value={2}>2 Ball</MenuItem>
                <MenuItem value={3}>3 Ball</MenuItem>
                <MenuItem value={4}>4 Ball</MenuItem>
              </Select>
            </FormControl>
          </div>
        </BorderedBox>
        <BorderedBox title={"Competition Eligability"} variant={"h6"} error={err_eligibility}>
          <CompetitionEligability
            eligibility={eligibility}
            className={classes.smallInput}
            onEligibilityChanged={this.handleEligibilityChanged}
          />
        </BorderedBox>
        <BorderedBox title={"Number of Holes"} variant={"h6"}>
          <HolesNumberPicker
            className={classes.holesNumberContainer}
            onHoleNumberChanged={this.onChangeHoleNumbers}
            holesNumber={holesNumber}
          />
        </BorderedBox>
        <PlayingRules
          playingRules={playingRules}
          holesNumber={holesNumber}
          onPlayingRulesChanged={this.handlePlayingRulesChanged}
        />

        <HandicapRules handicapRules={handicapRules} onHandicapRulesChanged={this.handleHandicapRulesChanged} />
        {isPrivate || holesNumber === 9 ? (
          <>
            <HandicapsExpireRules
              expireRules={expireRules}
              onHandicapExpireRulesChanged={this.handleHandicapExpireRulesChanged}
              error={!!err_expire}
            />
            <HandicapAdjustmentSection
              handicapAdjustment={handicapAdjustment}
              onHandicapAdjustmentSelectChanged={this.handleHandicapAdjustmentSelectChanges}
              isPrivateGame={isPrivate}
              holesNumber={holesNumber}
              error={err_hcpAdjustment}
            />
          </>
        ) : null}

        <HandicapAllowance
          teamHandicapAllowance={teamHandicapAllowance}
          fullHandicapAllowance={fullHandicapAllowance}
          isCombinedGame={isCombinedGame}
          isMultiRules={playingRules.multiRules === true}
          teamPlayers={playingRules.teamPlayers}
          minTeeShots={Array.isArray(playingRules.minimumTeeShots) ? playingRules.minimumTeeShots : []}
          onHandicapAllowanceChanged={this.handleHandicapAllowanceChanged}
        />

        <BorderedBox title={""} variant={"h6"}>
          <div className={classes.inline}>
            <Typography variant={"subtitle1"}>Allow scorers to add guests</Typography>
            <Switch value={guests} checked={guests} onChange={this.handleGuestSwitchChanged} />
          </div>
        </BorderedBox>

        {this.props.user.rule === Rules.Admin && type === GameRules.CUSTOM && (
          <BorderedBox title={"Allocate this rule to other organisers"} variant={"h6"}>
            <OrganisersTable insideHandler={true} organisers={accessor} onChange={this._handleAccessorChange} />
          </BorderedBox>
        )}

        {this.renderActionButton()}
      </Paper>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    title: {
      fontWeight: "bold",
    },
    paper: {
      display: "block",
      position: "relative",
      left: "50%",
      transform: "translateX(-50%)",
      width: "auto",
      maxWidth: 500,
      minWith: 360,
      backgroundColor: "rgba(255,255,552,0.85)",
      padding: theme.spacing(2),
    },
    inline: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    marginRight: {
      marginRight: theme.spacing(1),
    },
    smallInput: {
      width: 48,
    },
    actionButton: {
      marginTop: theme.spacing(1),
    },
    loadingContainer: {
      width: "100%",
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    actionButtonContainer: {
      display: "flex",
    },
    holesNumberContainer: {
      textAlign: "center",
    },
  });

const mapStateToProps = (state: GlobalState) => {
  const { user } = state.auth;
  const { loading } = state.general;
  return {
    user,
    loading,
  };
};

export default connect(mapStateToProps, {
  insertCompetition: CompetitionActions.insert,
  updateCompetition: CompetitionActions.update,
})(withStyles(styles)(CompetitionSetup));
