import React, { Component } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  Theme,
} from "@material-ui/core";
import moment from "moment";
import { connect } from "react-redux";
import { GlobalState } from "../../types/globalState";
import { IndexedPots, Pot, Transaction } from "../../types/pot";
import { Player } from "types/player";

interface Props {
  players: Player[];
}

const PlayersList = (props: Props) => {
  const classes = useStyles();
  const players = props.players;

  return (
    <div className={classes.container}>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Username</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {players.map((player: Player, index: number) => {
              return (
                <TableRow key={index}>
                  <TableCell>{index + 1}.</TableCell>
                  <TableCell component="th" scope="row">
                    {player.name}&nbsp;{player.lastName}
                  </TableCell>
                  <TableCell>{player.email}</TableCell>
                  <TableCell>{player.username}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    minWidth: 600,
  },
}));

const mapStatetoProps = (state: GlobalState) => {
  const { user } = state.auth;
  const { withdraws } = state.withdraws;
  return {
    user,
    withdraws,
  };
};

export default connect(mapStatetoProps)(PlayersList);
