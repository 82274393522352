import ReduxTypes from "../consts/ReduxTypes";
import { Action } from "../types/actions";
import { AuthState, User } from "../types/user";

const INITIAL_STATE = {
  user: {} as User,
};

export default (state: AuthState = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case ReduxTypes.LOGIN_USER_SUCCESS:
      return { ...state, user: action.payload };

    case ReduxTypes.UPDATE_USER:
      return { ...state, user: { ...state.user, ...action.payload } };

      case ReduxTypes.UPDATE_USER_HANDICAP:
        return {...state , user: {...state.user , hcp : action.payload}}

    case ReduxTypes.PURGE:
      return INITIAL_STATE;

    default:
      return state;
  }
};
