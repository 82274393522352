import React from "react";
import { Grid, makeStyles, Theme, Button, IconButton } from "@material-ui/core";
import { Add, Close } from "@material-ui/icons";

export interface PlusProps {
  items: unknown[];
  initialValue: unknown;
  onChange: (items: unknown[]) => void;
  isVisible: boolean;
}

const PlusComponent: React.FC<PlusProps> = props => {
  const classes = useStyles();
  const { items, initialValue, isVisible, children } = props;

  const addNewItem = () => {
    props.onChange(items ? items.concat(initialValue) : [initialValue]);
  };

  const removeItem = (index: number) => {
    items.splice(index, 1);
    props.onChange(items);
  };

  return isVisible ? (
    <div>
      {items.map((item, index: number) => {
        return (
          <Grid container className={[classes.container, classes.blinkBackground].join(" ")}>
            <Grid md={12} xs={12} item className={classes.closeButton}>
              <IconButton onClick={() => removeItem(index)}>
                <Close color="secondary" />
              </IconButton>
            </Grid>
            {typeof children === "function" && children({ item, index })}
          </Grid>
        );
      })}
      <div>
        <Button fullWidth style={{ marginTop: 8 }} color="primary" variant="outlined" onClick={addNewItem}>
          <Add />
        </Button>
      </div>
    </div>
  ) : null;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    border: "1px dashed #aaaaaa",
    borderRadius: 5,
    padding: 10,
    marginTop: 10,
  },
  closeButton: {
    textAlign: "right",
  },
  blinkBackground: {
    animation: `$blink 2s infinite`,

    animationIterationCount: 1,
  },
  "@keyframes blink": {
    from: { background: "rgba(250,237,57,0.9)" },
    to: { background: "rgba(250,237,57,0.0)" },
  },
}));

export default PlusComponent;
