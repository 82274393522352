import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { GlobalState } from "../../types/globalState";
import { IconButton, Switch, WithStyles } from "@material-ui/core";
import ClubsActions from "../../actions/clubs";
import MyTable, { Column, TableAction, TableState } from "../SharedComponents/MyTable";
import { showDialog } from "../SharedComponents/Dialog";
import ClubSetup from "./ClubSetup";
import ListStyles from "../../SharedStyles/ListStyle";
import { Edit } from "@material-ui/icons";
import { Club, IndexedClubs } from "../../types/club";

interface Props extends WithStyles {
  deleteClub(id: string): void;
  updateClub(id: string, data: any): void;
  fetchClubs(page: number, limit: number, filter?: any, sort?: string, dir?: string, query?: string): void;

  clubs: Club[];
  clubsObj: IndexedClubs;
  loading: boolean;
  count: number;
}
interface State {
  [inde: string]: any;
}

class ClubsListTable extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      openDialog: false,
    };
    this.props.fetchClubs(1, 50, null, "name");
  }

  _onTableChange = (actions: TableAction, state: TableState) => {
    this.props.fetchClubs(state.page + 1, state.pageSize, undefined, state.sort.field, state.sort.dir, state.query);
  };
  _onEditItem = (rowId: string) => {
    showDialog(<ClubSetup edit={this.props.clubsObj[rowId]} />, false, true);
  };
  _onDeleteItems = (rowsIds: string[]) => {
    rowsIds.forEach((id: string) => {
      this.props.deleteClub(id);
    });
  };
  _onAddItem = () => {
    showDialog(<ClubSetup />, false, true);
  };

  render() {
    const { classes, clubs, loading, count } = this.props;
    const columns: Column[] = [
      {
        field: "name",
        title: "Name",
      },
      {
        field: "phone",
        title: "Phone",
      },
      {
        field: "email",
        title: "Email",
      },
      {
        field: "address",
        title: "Address",
      },
      {
        field: "registered",
        title: "Schala registration",
        handler: (field: any, item?: any) => {
          return (
            <div>
              {(field && <span style={{ color: "green" }}>Registered</span>) || "unRegistered"}
              <Switch
                defaultChecked={field}
                onChange={e => this.props.updateClub(item._id, { registered: e.target.checked })}
              />
            </div>
          );
        },
      },
    ];

    return (
      <div className={classes.paper}>
        <MyTable
          title={"Clubs"}
          loading={loading}
          count={count}
          data={clubs}
          defaultOrderBy={"name"}
          columns={columns}
          selectable
          searchEnabled
          onTableChange={this._onTableChange}
          onEdit={this._onEditItem}
          onDelete={this._onDeleteItems}
          onAdd={this._onAddItem}
        />
      </div>
    );
  }
}

const styles = ListStyles({});
const mapStateToProps = (state: GlobalState) => {
  const { clubs, count } = state.clubs;
  const { loading } = state.general;
  return {
    clubsObj: clubs,
    clubs: clubs ? Object.values(clubs) : [],
    loading,
    count,
  };
};

export default connect(mapStateToProps, {
  fetchClubs: ClubsActions.fetch,
  deleteClub: ClubsActions.delete,
  updateClub: ClubsActions.update,
})(withStyles(styles)(ClubsListTable));
