import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { GameResultRules, SameScoresType } from "types/game";
import CustomSwitch from "components/SharedComponents/CustomSwitch";
import PlusComponent from "components/SharedComponents/Plus";
import HolesSelector from "components/SharedComponents/HolesSelector";

interface GameResultRulesProps {
  gameResultRules?: GameResultRules;
  holesCount: number;
  onGameResultRulesChanged: (rules: GameResultRules) => void;
}
interface PlusProps {
  item: number[];
  index: number;
}

const GResultRules: React.FC<GameResultRulesProps> = ({ gameResultRules, onGameResultRulesChanged, holesCount }) => {
  const classes = useStyles();
  const extraHoles2d = generateItems(gameResultRules?.extraHoles || []);

  const handleChange = (fieldName: string) => (evt: React.ChangeEvent<HTMLInputElement>) => {
    onGameResultRulesChanged({
      ...gameResultRules,
      [fieldName]: evt.target.checked as boolean,
    } as GameResultRules);
  };

  return (
    <>
      <div className={classes.row}>
        <Typography className={classes.box}>Play to a result either win, loss or half. </Typography>
        <CustomSwitch checked={gameResultRules?.toResult ?? true} onChange={handleChange("toResult")} />
      </div>

      <div className={classes.row}>
        <div className={classes.box}>
          <Typography>Play to a finish so as to get a win or loss result (extra holes possibly required) </Typography>
          <br />
          {gameResultRules?.toFinish && <Typography>Select extra holes in play order</Typography>}

          <PlusComponent
            items={extraHoles2d}
            initialValue={[1]}
            onChange={(extraHoles2d: unknown[]) => {
              if (gameResultRules)
                onGameResultRulesChanged({ ...gameResultRules, extraHoles: (extraHoles2d as number[][]).flat() });
            }}
            isVisible={gameResultRules?.toFinish === true}
          >
            {({ item, index }: PlusProps) => (
              <HolesSelector
                title=""
                holesCount={holesCount}
                selectedHoles={item}
                handleChange={holes => {
                  if (gameResultRules) {
                    extraHoles2d[index] = holes;
                    onGameResultRulesChanged({ ...gameResultRules, extraHoles: extraHoles2d.flat() });
                  }
                }}
              />
            )}
          </PlusComponent>
        </div>
        <CustomSwitch checked={gameResultRules?.toFinish ?? false} onChange={handleChange("toFinish")} />
      </div>

      <div className={classes.row}>
        <div className={classes.box}>
          <Typography>
            Play to last hole to compare score against others (playing against the course)
            <br />
            <br />
            (Game is won or lost against a target score set by other players)
          </Typography>
        </div>
        <CustomSwitch checked={gameResultRules?.toLastHole ?? false} onChange={handleChange("toLastHole")} />
      </div>

      {gameResultRules?.toLastHole && (
        <div className={classes.box}>
          <div className={classes.row}>
            <Typography>Highlight target score on leaderboard</Typography>
            <CustomSwitch
              checked={gameResultRules?.highlightTarget ?? true}
              onChange={handleChange("highlightTarget")}
            />
          </div>

          <div className={classes.row}>
            <Typography>Same scores regarded as</Typography>
            <RadioGroup
              value={gameResultRules.sameScores}
              onChange={evt => {
                onGameResultRulesChanged({
                  ...gameResultRules,
                  sameScores: evt.target.value as SameScoresType,
                });
              }}
            >
              <FormControlLabel value={SameScoresType.WIN} control={<Radio />} label="Win" />
              <FormControlLabel value={SameScoresType.LOSS} control={<Radio />} label="Loss" />
            </RadioGroup>
          </div>
        </div>
      )}
    </>
  );
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      display: "flex",
      marginBottom: 20,

      "& > *:first-child": {
        flex: 1,
      },
    },
    box: {
      display: "flex",
      flexDirection: "column",
      padding: "10px 10px 20px",
      border: "2px solid #000",
    },
  }),
);

function generateItems(items: number[]) {
  const result: number[][] = [];
  let temp: number[] = [];

  items.forEach(item => {
    if (temp.includes(item)) {
      result.push(temp);
      temp = [item];
    } else {
      temp.push(item);
    }
  });

  result.push(temp);

  return result;
}

export default GResultRules;
