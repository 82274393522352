import { Action } from "../types/actions";
import ReduxTypes, { Keys } from "../consts/ReduxTypes";
import { fetchReducer } from "./BaseReducerFunctions";
import { PaymentsState } from "../types/withdraws";

const INITIAL_STATE = {
  payments: {},
  count: 0,
};
const KEY: Keys = "PAYMENTS";

export default (state: PaymentsState = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case ReduxTypes.getFetchType(KEY):
      return fetchReducer(state, action, "payments");
    case ReduxTypes.PURGE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
