import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { showSnackbar } from "./components/SharedComponents/Notifier";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.register({
  onSuccess: () => {
    console.log("service-worker installed successfully");
  },
  onUpdate: () => {
  //  console.log("service-worker updated successfully");
   // showSnackbar("New version of SCHALA is available, reopen SCHALA to get all these cool new features.");
  },
});

// serviceWorker.unregister();
