import React, { Component } from "react";
import { Button } from "@material-ui/core";
import { resend, getSavedCallsCount, onChangeListener } from "../../api/failedCallsHandler";
import { withDetector } from "utils/withDetector";

interface State {
  count: number;
}

interface Props {
  online: boolean;
}

class OfflineRequests extends Component<Props, State> {
  state = {
    count: getSavedCallsCount(),
  };

  componentDidMount(): void {
    onChangeListener((offlineActivities: number) => {
      this.setState({ count: offlineActivities });
    });
  }

  render() {
    if (!this.state.count) return null;

    if (this.props.online) return null;

    return (
      <Button fullWidth color={"secondary"} variant={"contained"}>
        actions failed due to internet connection issues
      </Button>
    );
  }
}

export default withDetector(OfflineRequests);
