import React, { useState } from "react";
import { WithStyles, FormControl, TextField } from "@material-ui/core";
import { FixedMobileStepper } from "./MobileStepper";
import Messages from "../../../consts/Messages";

interface Props extends WithStyles {
  onNext: (vCode: string, password: string) => void;
  onPrev: () => void;
  defaults: string;
}

export const Step3 = (props: Props) => {
  const [vCode, setVCode] = useState("");
  const [password, setPassword] = useState("");
  const [passErr, setPassErr] = useState("");
  const [vCodeErr, setVCodeErr] = useState("");

  return (
    <>
      <div className={props.classes.stepContainer}>
        <p>Please enter the code you received by email and a new password, check the spams if you did'nt get any.</p>
        <FormControl margin="none" color="primary" required fullWidth>
          <TextField
            id="vCode"
            name="vCode"
            label={"Code"}
            value={vCode}
            error={vCodeErr.length > 0}
            helperText={vCodeErr}
            onChange={e => setVCode(e.target.value)}
          />
        </FormControl>
        <FormControl margin="none" color="primary" required fullWidth>
          <TextField
            id="password"
            name="password"
            label={"password"}
            value={password}
            error={passErr.length > 0}
            helperText={passErr}
            autoCapitalize="none"
            onChange={e => setPassword(e.target.value)}
          />
        </FormControl>
      </div>
      <FixedMobileStepper
        maxSteps={4}
        step={2}
        onNext={() => {
          if (vCode.trim().length < 6) {
            setVCodeErr("Validation code length is 6 character");
            return;
          } else {
            setVCodeErr("");
          }
          if (password.trim().length < 6) {
            setPassErr(Messages.error.passwordShortLength);
            return;
          } else {
            setPassErr("");
          }
          props.onNext(vCode, password);
        }}
        onPrev={props.onPrev}
      />
    </>
  );
};
