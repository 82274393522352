import React, { Fragment, useEffect } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { Router } from "react-router-dom";
import Contents from "./components";
import { CssBaseline } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import { persistor, store } from "./store";
import Notifier from "./components/SharedComponents/Notifier";
import AlertDialog from "./components/SharedComponents/AlertDialog";
import Dialog from "./components/SharedComponents/Dialog";
import PickerDialogComponent from "./components/SharedComponents/PickerDialog";
import AdvertDisplayer from "./components/SharedComponents/AdvertDisplayer";
import { AddToHomeScreenBanner } from "./AddToHomeScreen";
import { deepPurple, yellow } from "@material-ui/core/colors";
import "./styles/dialog-responsive.css";
import "./styles/table-responsive.css";
import "./styles/tabs-responsive.css";
import "./styles/expantion-panel-responsive.css";
import "./styles/input-responsive.css";
import "./index.css";
import ErrorBoundary from "./components/ErrorBoundary";
import { updateVersion } from "./Update";
import AuxiliaryDialog from "./components/SharedComponents/AuxiliaryDialog";
import CookieConsent from "components/CookieConsent";
import history from "./customHistory";

export default () => {
  const theme = createMuiTheme({
    palette: {
      primary: deepPurple,
      secondary: yellow,
    },
  });
  const update = () => {
    updateVersion();
  };
  useEffect(() => update(), []);
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router history={history}>
          <Fragment>
            <CssBaseline>
              <ErrorBoundary>
                {/*<ThemeProvider theme={theme}>*/}
                <AddToHomeScreenBanner />
                <Contents />
                <CookieConsent />
                <Notifier />
                <AlertDialog />
                <Dialog />
                <AuxiliaryDialog />
                <AdvertDisplayer />
                <PickerDialogComponent />
              </ErrorBoundary>
            </CssBaseline>
          </Fragment>
        </Router>
      </PersistGate>
    </Provider>
  );
};
