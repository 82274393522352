import * as React from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { GlobalState } from "../../../types/globalState";
import { createStyles, LinearProgress, TableContainer, Theme, WithStyles, Paper, Typography } from "@material-ui/core";
import { Views } from "../index";
import { Game, IndexedGames, GState } from "../../../types/game";
import { User } from "../../../types/user";
import GameActions from "../../../actions/games";
import AdvertActions from "../../../actions/adverts";
import { switchComponent } from "../../../actions/componentRouter";
import { Player } from "./../../../types/player";
import ScoringTableToolbar from "./ScoringTableToolbar";
import { showAdvert } from "../../SharedComponents/AdvertDisplayer";
import { showSnackbar } from "../../SharedComponents/Notifier";
import { ErrorOnlyCallback } from "../../../types/actions";
import { IndexedAdverts } from "../../../types/advert";
import ScoringTable from "./ScoringTable";
import { hideDialog, showDialog } from "../../SharedComponents/Dialog";
import IndividualLeaderBoard from "../../leaderBoard/IndividualLeaderBoard";
import { CombinedGamesHolesStarterPlayers } from "./CombinedGamesHolesStarterPlayers";
import AdvertsThimbnails from "../../Adverts/AdvertsThumbnails";
import Config from "../../../config";
import { showAlertDialog } from "../../SharedComponents/AlertDialog";

export interface CombinedScoreFormProps extends WithStyles {
  getAdvertByGameId(gameId: string, callback?: any): void;
  setGameAutoProgress(gameId: string, autoProgress: boolean): void;
  updateScores(
    gameId: string,
    playerId: string,
    scoreId: string,
    holeNumber: number,
    gross: number | null,
    shots?: number,
    onBehalfPlayer?: boolean,
    offline?: boolean,
    callback?: ErrorOnlyCallback,
  ): void;
  switchComponent(view: any, props?: any): void;
  finishGame(id: string, callback?: ErrorOnlyCallback): void;
  toggleScoringPlayer(gameId: string, playerId: string, action: "add" | "remove", callback?: ErrorOnlyCallback): void;

  gamesAd: IndexedAdverts;
  joinedGames: IndexedGames;
  loading: boolean;
  gameId: string;
  user: User;
}

export interface CombinedScoreFormState {
  holeIndex: number;
  isSubmitted: boolean;
  isVerified: boolean;
}

class CombinedScoreForm extends React.Component<CombinedScoreFormProps, CombinedScoreFormState> {
  constructor(props: CombinedScoreFormProps) {
    super(props);
    this.state = {
      holeIndex: 0,
      isSubmitted: false,
      isVerified: false,
    };
    this.props.getAdvertByGameId(this.props.gameId);
  }

  readonly IS_UNVERIFIABLE = -2;

  componentDidMount() {
    let holeIndex = this.getInitialHoleIndex();
    if (!holeIndex) holeIndex = 0;
    this.setState({ holeIndex });
  }

  getGame = (): Game => {
    return this.props.joinedGames[this.props.gameId];
  };

  showAdvertsThumbnail = () => {
    const { gameId, gamesAd } = this.props;
    const advert = gamesAd[gameId];

    if (!advert || !advert.holesInfo) return;
    showDialog(<AdvertsThimbnails adverts={advert} gameId={gameId} />, "xl", false, true, true);
  };

  getNumberOfHoles = () => {
    const game = this.getGame();
    if (game && game.scoreCards) return game.course.holesNumber;

    return 0;
  };

  getTeammates = (player: Player) => {
    const game = this.getGame();
    return Object.values(game.players).filter(x => x.team?._id === player.team?._id);
  };

  getTeamScoreCard = (player: Player) => {
    const game = this.getGame();
    const cardId = game.players[player._id].teeColor.scoreCardId;
    const getStartingHoleIndex = this.getStartingHoleIndex();
    const teamScoreCard = game.scoreCards.find(sc => sc._id === cardId);
    if (teamScoreCard?.holesParams) {
      teamScoreCard.holesParams = [
        ...teamScoreCard.holesParams.slice(getStartingHoleIndex),
        ...teamScoreCard.holesParams.slice(0, getStartingHoleIndex),
      ];
    }
    return teamScoreCard;
  };

  getInitialHoleIndex = () => {
    const game = this.getGame();
    if (!game) return null;
    const { user } = this.props;
    const me = game.players[user._id];
    if (!me.team) return;
    const teamCard = this.getTeamCard(me.team._id);
    const holesParams: any = this.getTeamScoreCard(me)!.holesParams;
    for (let hi = 0; hi < holesParams.length; hi++) {
      const { number } = holesParams[hi];
      if (!teamCard.holes[number] || !teamCard.holes[number].gross) {
        return hi;
      }
      if (Object.keys(teamCard.holes).length === holesParams.length) {
        return holesParams.length - 1;
      }
    }
    return 0;
  };

  getStartingHoleIndex = (): number => {
    const game = this.getGame();
    const player = game.players[this.props.user._id];
    if (player.group && player.group.startingHole) {
      return player.group.startingHole - 1;
    }
    return 0;
  };

  getTeamCard = (teamId: string) => {
    const game = this.getGame();
    return game.players[teamId];
  };

  canGoNextHole = () => {
    if (this.state.holeIndex < this.getNumberOfHoles() - 1) return true;
    return false;
  };

  _nextHole = () => {
    const { gameId, gamesAd } = this.props;
    const nextHole = this.state.holeIndex + 1;
    if (this.canGoNextHole()) {
      showAdvert(gameId, gamesAd[gameId], nextHole);
      this.setState({ holeIndex: this.state.holeIndex + 1 });
    } else if (!this.getGame()?.autoProgress)
      showSnackbar("Please score all players before proceeding to the next hole");
  };

  handleNextHole = () => {
    const { gameId, gamesAd } = this.props;
    const nextHole = this.state.holeIndex + 1;

    if (this.canGoNextHole()) {
      showAdvert(gameId, gamesAd[gameId], nextHole);
      this.setState({ holeIndex: nextHole });
    } else if (!this.getGame()?.autoProgress)
      showSnackbar("Please score all players before proceeding to the next hole");
  };

  previousHole = () => {
    if (this.state.holeIndex > 0) {
      this.setState(state => ({
        holeIndex: state.holeIndex - 1,
      }));
    } else {
      this.setState({ holeIndex: this.getNumberOfHoles() - 1 });
    }
  };

  handleGrossChange = (gameId: string, player: Player, holeNumber: number, gross: any, dnfSelected: boolean) => {
    this.props.updateScores(
      gameId,
      player._id,
      player.scoreId,
      holeNumber,
      dnfSelected ? "DNF" : gross,
      gross,
      undefined,
      undefined,
      err => {
        if (!err && this.getGame().autoProgress) this.handleNextHole();
      },
    );

    hideDialog();
  };

  handleAutoProgressChange = (event: any) => {
    this.props.setGameAutoProgress(this.getGame()?._id || "", event.target.checked as boolean);
  };

  handleOnVerified = (playerId: string) => {
    this.setState({ isVerified: true });
  };

  grossFinalCheck = (player: any): number | void => {
    if (!player) return;
    const holes = Object.values(player.holes);
    if (holes.length < this.getNumberOfHoles()) return;
    player.isUnverifiable = true;
    if (!this.state.isVerified) return this.IS_UNVERIFIABLE;
    return -1;
  };

  isGameFinished() {
    if (this.getGame()?.state === GState.Done) this.leaveGame();
  }

  onSubmitCard = () => {
    const { gameId } = this.props;
    this.props.finishGame(gameId);
  };

  checkMinimumTeeShots = () => {
    const game = this.getGame();
    const minShots = game.competition.playingRules.minimumTeeShots as number;
    if (!minShots) return true;

    const { user } = this.props;
    const me = game.players[user._id];
    if (!me.team) return false;

    const teammates = this.getTeammates(me);
    const teamCard = this.getTeamCard(me.team._id);
    let isValid = true;
    teammates.forEach(player => {
      const teeShots = Object.values(teamCard.uholes).filter(hole => hole.grosses[player._id]).length;
      if (teeShots < minShots) isValid = false;
    });
    this.setState({ isVerified: false });
    if (!isValid) showAlertDialog("Oops!", `Minimum ${minShots} allocated shots required!`);
    return isValid;
  };

  canSubmitCard(teamCard: Player) {
    const { isSubmitted } = this.state;
    if (this.grossFinalCheck(teamCard) != -1) return false;
    if (localStorage.getItem(Config.STORAGE.FAILED_API_CALLS)) return false;
    if (!this.checkMinimumTeeShots()) return false;
    if (!isSubmitted) {
      this.setState({ isSubmitted: true }, () => {
        this.onSubmitCard();
        this.leaveGame();
      });
    }
  }

  leaveGame = () => {
    this.props.switchComponent(Views.DEFAULT);
    this.showAdvertsThumbnail();
  };

  render() {
    const { classes, loading, user } = this.props;
    const { holeIndex } = this.state;
    if (!this.props.joinedGames[this.props.gameId]) return null;

    const game = this.getGame();
    const me = game.players[user._id];
    if (!me.team)
      return (
        <div className={classes.submitDiv}>
          <Typography variant="body2" gutterBottom>
            You have no team, please join a team.
          </Typography>
        </div>
      );
    const teamCard = this.getTeamCard(me.team._id);
    if (game && holeIndex < this.getNumberOfHoles()) {
      const scoreCard = this.getTeamScoreCard(me);

      if (!scoreCard) {
        showAlertDialog("Oops!", "No score card found!");
        return null;
      }

      const holesParams: any = scoreCard!.holesParams;
      this.canSubmitCard(teamCard);
      this.isGameFinished();
      if (game.autoProgress === undefined) this.props.setGameAutoProgress(game._id, true);

      return (
        <div key={game._id}>
          {loading && <LinearProgress color={"primary"} />}
          <TableContainer component={Paper}>
            <ScoringTableToolbar
              isCombined={true}
              holeIndex={holeIndex}
              scoreCard={scoreCard}
              isAutoProgressEnable={game.autoProgress}
              onOpenLeaderboard={() =>
                showDialog(<IndividualLeaderBoard gameId={this.props.gameId} />, "xl", true, true, true)
              }
              onPreviousHole={this.previousHole}
              onNextHole={this.handleNextHole}
              onAutoProgressChanged={this.handleAutoProgressChange}
              onOpenScorerSetup={() => {
                this.props.switchComponent(Views.ScorerGameSetup, {
                  gameId: game._id,
                  group: game.players[this.props.user._id].group,
                });
              }}
            />
            <CombinedGamesHolesStarterPlayers game={game} user={user} />
            {teamCard && (
              <ScoringTable
                game={game}
                index={0}
                holeIndex={holeIndex}
                player={teamCard}
                scoreCard={scoreCard}
                canGoNextHole={true}
                scoring={false}
                combined
                grossFinalCheck={this.grossFinalCheck}
                handleGrossChange={this.handleGrossChange}
                handleVerifyPlayer={this.handleOnVerified}
                openPickerDialogForMisMatchHole={() => {}}
                onOpenScorerSetup={() => {}}
              />
            )}
          </TableContainer>
        </div>
      );
    } else {
      return null;
    }
  }
}

const styles = (theme: Theme) =>
  createStyles({
    headCell: {
      width: 85,
    },
    title: {
      display: "flex",
      justifyContent: "center",
      fontSize: "1rem",
      flexGrow: 0.25,
      margin: 0,
    },
    inline: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-evenly",
    },
    column: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-evenly",
    },

    submitDiv: {
      backgroundColor: theme.palette.secondary.main,
      padding: 10,
      textAlign: "center",
      color: "#ffffff",
    },
    iconText: {
      margin: "0",
      fontSize: "smaller",
    },
  });
const mapStateToProps = (state: GlobalState) => {
  const { joinedGames } = state.games;
  const { gamesAd } = state.adverts;
  const { user } = state.auth;
  const { loading } = state.general;
  return {
    user: user as User,
    gamesAd,
    loading,
    joinedGames,
  };
};

export default connect(mapStateToProps, {
  finishGame: GameActions.finishGame,
  updateScores: GameActions.updateScores,
  getAdvertByGameId: AdvertActions.getByGameId,
  setGameAutoProgress: GameActions.setGameAutoProgress,
  toggleScoringPlayer: GameActions.toggleScoringPlayer,
  switchComponent,
})(withStyles(styles)(CombinedScoreForm));
