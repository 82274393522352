import React, { useState } from "react";
import { Table, TableBody, TableCell, TableHead, TableRow, withStyles, Button, Dialog } from "@material-ui/core";

import { Player, GuestPlayerInputs } from "types/player";
import { Gender } from "types/user";
import DialogToolbar from "components/SharedComponents/DialogToolbar";
import { hideAuxiliaryDialog } from "components/SharedComponents/AuxiliaryDialog";
import { useActions } from "hooks/useActions";
import GuestPlayerForm from "./GuestPlayerForm";

interface Props {
  players: Player[];
  gameId: string;
}

interface State {
  open: boolean;
  selected?: Player;
}

const GuestPlayersTable: React.FC<Props> = props => {
  const { players, gameId } = props;
  const [state, setState] = useState<State>({ open: false });
  const { updateGuestUser } = useActions();

  const handleClose = () => setState({ open: false });

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell align="left">Name</StyledTableCell>
            <StyledTableCell align="left">Surname</StyledTableCell>
            <StyledTableCell align="center">HI</StyledTableCell>
            <StyledTableCell align="center">PH</StyledTableCell>
            <StyledTableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {players.map((p: Player) => (
            <StyledTableRow key={p._id}>
              <TableCell>{p.name}</TableCell>
              <TableCell>{p.lastName}</TableCell>
              <TableCell>{p.hci || 0}</TableCell>
              <TableCell>{Math.round(p.hcp || 0)}</TableCell>
              <TableCell>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setState({ open: true, selected: p });
                  }}
                >
                  Edit
                </Button>
              </TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      {state.selected && (
        <Dialog open={state.open} onBackdropClick={handleClose}>
          <DialogToolbar title="Edit Guest Player" onClick={handleClose} />
          <GuestPlayerForm
            defaultValues={{
              ...state.selected,
              hcp: state.selected.hci,
              gender: state.selected.gender || Gender.Men,
            }}
            onSubmit={(formValue: GuestPlayerInputs) => {
              if (state.selected?._id)
                updateGuestUser(state.selected._id, formValue, gameId, () => {
                  handleClose();
                  hideAuxiliaryDialog();
                });
            }}
          />
        </Dialog>
      )}
    </>
  );
};

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default GuestPlayersTable;
