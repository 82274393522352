import React from "react";
import { withStyles, Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import { AdvertTypes } from "types/advert";
import { useGameAdvertActivities } from "hooks/useActivities";
import moment from "moment";

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#777",
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

const useStyles = makeStyles({
  table: {
    margin: "40px 0",
  },
});

interface AdvertActivitiesProps {
  gameId: string;
  type: AdvertTypes;
}

const AdvertActivities: React.FC<AdvertActivitiesProps> = ({ gameId, type }) => {
  const classes = useStyles();
  const { activities = [] } = useGameAdvertActivities(gameId, type);

  return (
    <TableContainer className={classes.table} component={Paper}>
      <Typography variant="h5" style={{ padding: 10 }}>
        Activities Logs:
      </Typography>
      <Table>
        <caption style={{ fontSize: "1.5rem" }}>{`Total logs: ${activities.length}`}</caption>
        <TableHead>
          <TableRow>
            <StyledTableCell>Date</StyledTableCell>
            <StyledTableCell>Request IP</StyledTableCell>
            <StyledTableCell>User</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {activities.map(act => (
            <StyledTableRow key={act._id}>
              <StyledTableCell>{moment(act.dateTime).format("ddd DD/MM/YY hh.mm")}</StyledTableCell>
              <StyledTableCell>{act.req.ip}</StyledTableCell>
              <StyledTableCell>{`${act.user.name} ${act.user.lastName} (${act.user.username})`}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AdvertActivities;
