import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Switch,
  Typography,
  Theme,
  Select,
  MenuItem,
  Input,
  WithStyles,
  createStyles,
  InputAdornment,
} from "@material-ui/core";
import { PaymentRules, PaymentRuleFields } from "../../types/game";
import VideoPopup from "components/SharedComponents/VideoPopup";
import { HELP_VIDEOS } from "consts";

export interface Props extends WithStyles {
  isPaid: boolean;
  paymentRules: PaymentRules;
  disabled?: boolean;
  onPaymentRulesChanged: (value: PaymentRules) => void;
  onPaidSwitchChanged: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

class GamePayment extends Component<Props> {
  onPaymentRulesChange = (field: PaymentRuleFields, value: number | boolean) => {
    this.props.onPaymentRulesChanged({
      ...this.props.paymentRules,
      [field]: value,
    });
  };

  render() {
    const { classes, isPaid, paymentRules, disabled } = this.props;

    return (
      <div>
        <div className={classes.inline}>
          <Typography variant={"subtitle1"}>Activate payment process in game</Typography>
          <Switch name="isPaid" value={isPaid} checked={isPaid} onChange={e => this.props.onPaidSwitchChanged(e)} />
        </div>
        {!isPaid ? null : (
          <div>
            <div className={classes.inline}>
              <Typography variant={"subtitle1"}>Enable Pay Before Play</Typography>
              <Switch
                checked={paymentRules.payBeforePlay}
                onChange={evt => this.onPaymentRulesChange(PaymentRuleFields.PAY_BEFORE_PLAY, evt.target.checked)}
              />
            </div>
            <div className={classes.inline}>
              <Typography variant={"subtitle1"} className={classes.disabled}>
                Allow Nett refund on leaving game
              </Typography>
              <Switch disabled />
            </div>
            <div>
              <Typography variant={"h6"}>Paying</Typography>
            </div>
            <div className={classes.inline}>
              <Typography variant={"subtitle1"}>Payment Type: </Typography>
              <Select
                disabled={disabled}
                name="teamPlayers"
                value={paymentRules.type}
                onChange={evt => this.onPaymentRulesChange(PaymentRuleFields.TYPE, evt.target.value as number)}
              >
                <MenuItem value={1}>Multiple Player</MenuItem>
                <MenuItem value={2}>Team</MenuItem>
              </Select>
            </div>
            <div className={classes.inline}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography variant={"subtitle1"}>Entry amount:</Typography>
                <VideoPopup videoURL={HELP_VIDEOS.GAME_SETUP_TRANSACTION} />
              </div>
              <Input
                type={"number"}
                // disabled={disabled}
                value={paymentRules.fee}
                startAdornment={<InputAdornment position="start">&#163;</InputAdornment>}
                onChange={evt => this.onPaymentRulesChange(PaymentRuleFields.FEE, parseInt(evt.target.value))}
                className={classes.smallInput}
              />
            </div>
            <div className={classes.inline}>
              <Typography variant={"subtitle1"}>Nett amount after transaction fee deducted: </Typography>
              <Input
                type={"number"}
                disabled
                startAdornment={<InputAdornment position="start">&#163;</InputAdornment>}
                value={(paymentRules.fee - (paymentRules.fee * (3 / 100) + 0.2)).toFixed(2)}
                className={classes.smallInput}
              />
            </div>
            <div>
              <Typography variant={"h6"}>Other Items</Typography>
            </div>
            <div className={classes.inline}>
              <Typography variant={"subtitle1"} className={classes.disabled}>
                Raffle Tickets
              </Typography>
              <Switch disabled />
            </div>
            <div className={classes.inline}>
              <Typography variant={"subtitle1"} className={classes.disabled}>
                Charity Donation
              </Typography>
              <Switch disabled />
            </div>
            <div className={classes.inline}>
              <Typography variant={"subtitle1"} className={classes.disabled}>
                Allow Auction process
              </Typography>
              <Switch disabled />
            </div>
            <div className={classes.inline}>
              <Typography variant={"subtitle1"} className={classes.disabled}>
                Gift Fund
              </Typography>
              <Switch disabled />
            </div>
          </div>
        )}
      </div>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    inline: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    disabled: {
      color: "#aaaaaa",
    },
    smallInput: {
      width: 80,
    },
  });

export default withStyles(styles)(GamePayment);
