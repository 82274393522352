import * as React from "react";
import { Input, Button, Typography, makeStyles, Theme, Grid, Select, MenuItem, IconButton } from "@material-ui/core";
import { Add, Close } from "@material-ui/icons";

export interface PlacingControlProps {
  onChanged: (result: any) => void;
  data: number[];
  isVisible: boolean;
  defaultValue?: number;
  type: "winners" | "losers";
}

const PlacingControl: React.FunctionComponent<PlacingControlProps> = (props: PlacingControlProps) => {
  const classes = useStyles();
  const { data, isVisible } = props;

  const handlePlacingScoreChange = (field: number) => (event: any) => {
    let placingScores = [...data];
    placingScores[field] = event.target.value as number;
    props.onChanged(placingScores);
  };

  const addNewPlace = () => {
    const def = props.defaultValue || 0;
    props.onChanged(data ? data.concat(def) : [def]);
  };

  const removePlace = (index: number) => {
    data.splice(index, 1);
    props.onChanged(data);
  };

  const getTitle = (index: number) => {
    if (index === 0 && props.type === "losers") return "Last";

    let preTitle: any;
    switch (index) {
      case 0:
        preTitle = "st";
        break;
      case 1:
        preTitle = "nd";
        break;
      case 2:
        preTitle = "rd";
        break;
      default:
        preTitle = "th";
        break;
    }

    return (
      <>
        {index + 1}
        <sup>{preTitle}</sup> {props.type === "losers" ? "to last" : null}
      </>
    );
  };
  return !isVisible ? null : (
    <div className={classes.placing}>
      {data?.map((item: number, index: number) => {
        return (
          <Grid container spacing={0}>
            <Grid item md={2} xs={2}>
              <IconButton onClick={() => removePlace(index)}>
                <Close color="secondary" className={classes.removeBtn} />
              </IconButton>
            </Grid>

            <Grid item md={8} xs={8}>
              <Typography variant={"subtitle1"}>{getTitle(index)}</Typography>
            </Grid>
            <Grid item md={2} xs={2}>
              <Input
                type={"number"}
                value={item}
                inputProps={{ step: 0.1 }}
                onChange={handlePlacingScoreChange(index)}
                className={classes.smallInput}
              />
            </Grid>
          </Grid>
        );
      })}
      <Button fullWidth style={{ marginTop: 8 }} color={"primary"} variant={"outlined"} onClick={addNewPlace}>
        <Add />
      </Button>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: "8 2 8 2",
  },
  smallInput: {
    width: 48,
  },
  removeBtn: {
    cursor: "pointer",
  },
  placing: {
    margin: "10px 0px 10px 0px",
  },
}));

export default PlacingControl;
