import React, { useState } from "react";
import { WithStyles, FormControl, TextField, LinearProgress } from "@material-ui/core";
import validator from "validator";
import { FixedMobileStepper } from "./MobileStepper";
import authApi from "../../../api/authentication";
import Messages from "../../../consts/Messages";
import { showSnackbar } from "../../SharedComponents/Notifier";
import {toggleLoading} from "./index";

interface Props extends WithStyles {
  onNext: (emailOrUsername: string) => void;
  defaults: string;
}

export const Step1 = (props: Props) => {
  const [emailOrUsername, setEmailOrUsername] = useState<string>(props.defaults);
  const [err, setErr] = useState("");

  return (
    <>
      <div className={props.classes.stepContainer}>
        <p>Please enter your Email or SCHALA Username in order to reset your password</p>
        <FormControl margin="none" color="primary" required fullWidth>
          <TextField
            id="input"
            name="input"
            label={"Email/Username"}
            //autoComplete="email"
            value={emailOrUsername}
            error={err.length > 0}
            helperText={err}
            onChange={e => {
              setEmailOrUsername(e.target.value);
            }}
          />
        </FormControl>
      </div>
      <FixedMobileStepper
        maxSteps={4}
        step={0}
        onNext={() => {
          if(emailOrUsername.length < 5)
            return setErr( "Enter a valid email or username");
          else
            setErr("");
          toggleLoading();
          authApi.getVerificationCode(emailOrUsername)
            .then((res: any) => {
                toggleLoading();
                props.onNext(emailOrUsername);
            })
            .catch((err: any) => {
              toggleLoading();
              if(!err || !err.response) showSnackbar("Connection Error.");
              else if (err.response.status === 404) {
                setErr(Messages.error.emailNotExist);
              }
              else {
                showSnackbar(err.response.data.error);
              }
            });
        }}
        onPrev={() => true}
      />
    </>
  );
};
