import clsx from "clsx";
import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Checkbox,
  createStyles,
  IconButton,
  InputAdornment,
  lighten,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Theme,
  Toolbar,
  Tooltip,
  Typography,
  WithStyles,
  InputBase,
} from "@material-ui/core";
import { AddCircle, Close, Delete, Edit, FileCopy, Search } from "@material-ui/icons";
import Moment from "moment";
import Config from "../../config";

interface Props extends WithStyles {
  data: any;
  count: number;
  title: string;
  loading: boolean;
  columns: Column[];

  filter?: any;
  selectable?: boolean;
  searchEnabled?: boolean;
  hideAddButton?: boolean;
  defaultSortDir?: Dir;
  defaultOrderBy?: string;
  disabledRow?: boolean;
  serverSidePagination?: boolean;

  onTableChange?(action: string, data: TableState): void;

  onClickItem?(row: any): void;

  onDelete?(rowIds: string[]): void;

  onDuplicate?(rowId: string): void;

  onEdit?(rowId: string): void;

  onAdd?(): void;
}

interface State {
  [inde: string]: any;

  page: number;
  pageSize: number;
  orderBy?: string;
  dir?: Dir;
  searchText: string;
  selectedRows: any;
  selectedRowsNum: number;
}

export interface Column {
  field: string;
  title: string;

  handler?(field: any, item?: any): JSX.Element | null;
}

export interface TableState {
  sort: {
    field: string;
    dir: Dir;
  };
  page: number;
  query?: string;
  pageSize: number;
  selectedRows: any;
}

export type TableAction =
  | "sort"
  | "offline-search"
  | "search"
  | "changePage"
  | "changePageSize"
  | "selectedRowsChanged"
  | "clearSearch";
export type Dir = "asc" | "desc";

class ScoreCardsList extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      openDialog: false,
      page: 0,
      pageSize: 50,
      selectedRows: [],
      selectedRowsNum: 0,
      searchText: "",
      dir: props.defaultSortDir || "asc",
      orderBy: props.defaultOrderBy,
    };
  }

  _onTableChange = (action: TableAction, data: any) => {
    let sort: any = {};
    if (this.state.orderBy && this.state.dir) {
      sort = {
        field: this.state.orderBy,
        dir: this.state.dir,
      };
    }
    let tableState: TableState = {
      sort,
      page: this.state.page,
      query: this.state.searchText,
      pageSize: this.state.pageSize,
      selectedRows: this.state.selectedRows,
    };
    switch (action) {
      case "sort": {
        const dir = data.dir === "asc" ? "desc" : "asc";
        this.setState({ orderBy: data.orderBy, dir });
        tableState.sort = { field: data.orderBy, dir };
        break;
      }

      case "search": {
        if (data.length < 2) return;
        break;
      }
      case "clearSearch": {
        break;
      }
      case "changePage": {
        tableState.page = data;
        this.setState({ page: data });
        break;
      }
      case "changePageSize": {
        this.setState({ pageSize: data });
        tableState.pageSize = data;
        break;
      }
      case "selectedRowsChanged": {
        const { row, checked } = data;
        let selectedRows = { ...this.state.selectedRows };
        if (checked) {
          selectedRows[row._id] = checked;
        } else {
          delete selectedRows[row._id];
        }
        this.setState({ selectedRows, selectedRowsNum: Object.values(selectedRows).length });
        tableState.selectedRows = selectedRows;
      }
      default:
        break;
    }

    if (this.props.onTableChange) this.props.onTableChange(action, tableState);
  };
  _handleChangePage = (event: unknown, newPage: number) => {
    this._onTableChange("changePage", newPage);
  };
  _handleChangeRowsPerPage = (event: any) => {
    this._onTableChange("changePageSize", event.target.value);
  };
  _onSelectRow = (row: any, checked: boolean) => {
    let selectedRows = { ...this.state.selectedRows };
    if (checked) {
      selectedRows[row._id] = row;
    } else {
      delete selectedRows[row._id];
    }
    this.setState({ selectedRows, selectedRowsNum: Object.values(selectedRows).length });
    // this._onTableChange('selectedRowsChanged', {row, checked});
  };

  _selectAll = (rows: any[]) => {
    let selectedRows: any = {};
    rows.forEach(row => (selectedRows[row._id] = row));
    this.setState({ selectedRows: selectedRows, selectedRowsNum: rows.length });
  };

  _clearSelection = () => {
    this.setState({ selectedRows: {}, selectedRowsNum: 0 });
  };

  _onClickEdit = () => {
    if (this.props.onEdit) {
      const selectedRows: any[] = Object.values(this.state.selectedRows);
      if (selectedRows.length > 0) {
        //@ts-ignore
        this.props.onEdit(selectedRows[0]._id);
      }
    }
  };
  _onClickDuplicate = () => {
    if (this.props.onDuplicate) {
      const selectedRows: any[] = Object.values(this.state.selectedRows);
      if (selectedRows.length > 0) {
        //@ts-ignore
        this.props.onDuplicate(selectedRows[0]._id);
      }
    }
  };

  _onClickDelete = () => {
    if (this.props.onDelete) {
      this.props.onDelete(Object.values(this.state.selectedRows).map((row: any) => row._id));
      this._clearSelection();
    }
  };

  _onClickAdd = () => {
    if (this.props.onAdd) {
      this.props.onAdd();
    }
  };
  handleSearchInputChanged = (event: any) => {
    const value = event.target.value;
    this.setState({ searchText: value }, () => this._onTableChange("search", value));
  };

  _clearSearchText = () => {
    this.setState({ searchText: "" }, () => this._onTableChange("clearSearch", {}));
  };

  descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  search(rows: any, search: string, columns: Column[]) {
    const searchTexts = search.split(" ");
    return rows.filter((item: any) => {
      let exist: boolean[] = [];
      searchTexts.forEach((search, index) => {
        exist[index] = false;
        columns.forEach((column: Column) => {
          try {
            exist[index] =
              exist[index] ||
              item[column.field]
                .toString()
                .toLowerCase()
                .includes(search.toLowerCase());
          } catch (e) {}
        });
      });
      for (let i = 0; i < exist.length; i++) {
        if (!exist[i]) return false;
      }
      return true;
    });
  }

  filter(rows: any, filter: any) {
    if (filter) {
      return rows.filter(function(item: any) {
        let result = true;
        Object.keys(filter).forEach(k1 => {
          if (typeof item[k1] === "object") {
            Object.keys(filter[k1]).forEach(k2 => {
              if (typeof item[k1][k2] === "object") {
                Object.keys(filter[k1][k2]).forEach(k3 => {
                  result = result && item[k1][k2][k3] === filter[k1][k2][k3];
                });
              } else {
                result = result && item[k1][k2] === filter[k1][k2];
              }
            });
          } else {
            result = result && item[k1] === filter[k1];
          }
        });
        return result;
      });
    } else {
      return rows;
    }
  }

  checkIfIsDatetime = (data: any) => {
    return data && data.isDatetime ? Moment(data.timestamp).format(Config.DATE_DISPLAY_FORMAT) : data;
  };

  renderRow = (row: any) => {
    const { columns, selectable, disabledRow } = this.props;
    const { selectedRows } = this.state;
    return (
      <TableRow key={row._id} onClick={() => (this.props.onClickItem ? this.props.onClickItem(row) : null)}>
        {selectable && (
          <TableCell padding="checkbox">
            <Checkbox
              disabled={disabledRow ? !row.active : false}
              onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                this._onSelectRow(row, checked);
              }}
              checked={selectedRows[row._id] !== undefined}
            />
          </TableCell>
        )}
        {columns.map((column: Column, key: number) => {
          const cellData = this.checkIfIsDatetime(row[column.field]);
          return <TableCell key={key}>{column.handler ? column.handler(cellData, row) : cellData}</TableCell>;
        })}
      </TableRow>
    );
  };

  renderHeaderCell = (column: Column) => {
    const { classes } = this.props;
    const { orderBy, dir } = this.state;
    return (
      <TableCell key={column.title} sortDirection={"asc"} className={classes.headerCell}>
        <TableSortLabel
          active={orderBy === column.field}
          direction={orderBy === column.field ? dir : "asc"}
          onClick={() => {
            this._onTableChange("sort", { orderBy: column.field, dir });
          }}
        >
          {column.title}
          {orderBy === column.field && (
            <span className={classes.visuallyHidden}>{dir === "desc" ? "sorted descending" : "sorted ascending"}</span>
          )}
        </TableSortLabel>
      </TableCell>
    );
  };
  renderSelectionToolbar = () => {
    const { classes, onDuplicate, onEdit } = this.props;
    const { selectedRowsNum } = this.state;
    return (
      <>
        <Typography color="inherit" variant="h6">
          {selectedRowsNum} selected
        </Typography>
        <div className={classes.inline}>
          {selectedRowsNum < 2 && (
            <>
              {onDuplicate && (
                <Tooltip title="Duplicate" leaveTouchDelay={2000}>
                  <IconButton aria-label="duplicate" onClick={this._onClickDuplicate}>
                    <FileCopy />
                  </IconButton>
                </Tooltip>
              )}
              {onEdit && (
                <Tooltip title="Edit" leaveTouchDelay={2000}>
                  <IconButton aria-label="edit" onClick={this._onClickEdit}>
                    <Edit />
                  </IconButton>
                </Tooltip>
              )}
            </>
          )}
          <Tooltip title="Delete" leaveTouchDelay={2000}>
            <IconButton aria-label="delete" onClick={this._onClickDelete}>
              <Delete />
            </IconButton>
          </Tooltip>
        </div>
      </>
    );
  };
  renderToolbar = (title: string) => {
    const { classes, searchEnabled, hideAddButton } = this.props;
    const { selectedRowsNum, searchText } = this.state;
    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: selectedRowsNum > 0,
        })}
      >
        {selectedRowsNum > 0 ? (
          this.renderSelectionToolbar()
        ) : (
          <>
            <Typography className={classes.title} variant="h6">
              {title}
            </Typography>
            {searchEnabled && (
              <>
                <InputBase
                  value={searchText}
                  placeholder="Search"
                  className={classes.searchInput}
                  onChange={this.handleSearchInputChanged}
                  startAdornment={
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  }
                  endAdornment={
                    searchText && (
                      <InputAdornment position="end">
                        <IconButton onClick={this._clearSearchText}>
                          <Close />
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                />
              </>
            )}
            {this.props.onAdd && (
              <Tooltip className={clsx({ [classes.invisible]: hideAddButton })} title="Add new" leaveTouchDelay={2000}>
                <IconButton
                  onClick={this._onClickAdd}
                  className={classes.addButton}
                  color="primary"
                  aria-label="Add New"
                >
                  <AddCircle fontSize={"default"} color={"action"} />
                </IconButton>
              </Tooltip>
            )}
          </>
        )}
      </Toolbar>
    );
  };
  renderEmptyTable = (message: string) => {
    const { columns } = this.props;
    return (
      <TableBody>
        <TableRow>
          <TableCell colSpan={columns.length + 1}>{message}</TableCell>
        </TableRow>
      </TableBody>
    );
  };
  getComparator = (a: any, b: any, dir: Dir, orderBy: string) => {
    let elmnt1: any = a[orderBy];
    let elmnt2: any = b[orderBy];
    const numberReg = /^[-+]?[0-9]*\.?[0-9]*$/;
    if (elmnt1?.isDatetime && elmnt2?.isDatetime) {
      elmnt1 = elmnt1.timestamp;
      elmnt2 = elmnt2.timestamp;
    } else if (numberReg.test(elmnt1) && numberReg.test(elmnt2)) {
      elmnt1 = parseFloat(elmnt1);
      elmnt2 = parseFloat(elmnt2);
    } else if (typeof elmnt1 === "string" && typeof elmnt2 === "string") {
      elmnt1 = elmnt1.toLowerCase();
      elmnt2 = elmnt2.toLowerCase();
    }
    if (elmnt1 < elmnt2) return dir === "desc" ? 1 : -1;
    else if (elmnt1 > elmnt2) return dir === "desc" ? -1 : 1;
    else return 0;
  };

  render() {
    const { classes, data, columns, count, title, selectable, loading, filter, serverSidePagination } = this.props;
    const { searchText, page, pageSize, orderBy, dir, selectedRows, selectedRowsNum } = this.state;
    let rows = orderBy && dir ? data.sort((a: any, b: any) => this.getComparator(a, b, dir, orderBy)) : data;
    rows = this.filter(rows, filter);
    rows = this.search(rows, searchText, columns);

    if (!serverSidePagination) {
      rows = rows.slice(page * pageSize, (page + 1) * pageSize);
    } else {
      const totalPageCount = Math.ceil(count / pageSize);
      if (page + 1 === totalPageCount && count % pageSize !== 0) {
        rows = rows.slice(0, count % pageSize);
      } else {
        rows = rows.slice(0, pageSize);
      }
    }
    return (
      <Paper className={classes.fullWidth}>
        <LinearProgress className={clsx({ [classes.invisible]: !loading })} />
        {this.renderToolbar(title)}
        <TableContainer className={classes.container}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {selectable && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={selectedRowsNum > 0 && selectedRowsNum < rows.length}
                      checked={selectedRowsNum === rows.length}
                      onChange={e => {
                        if (e.target.checked) this._selectAll(rows);
                        else this._clearSelection();
                      }}
                    />
                  </TableCell>
                )}
                {columns.map((item: Column) => this.renderHeaderCell(item))}
              </TableRow>
            </TableHead>
            {rows.length > 0 ? (
              <TableBody>{rows.map((row: any) => this.renderRow(row))}</TableBody>
            ) : (
              this.renderEmptyTable("No item found!")
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[50, 200, 500]}
          component="div"
          count={count}
          rowsPerPage={pageSize}
          page={page}
          onChangePage={this._handleChangePage}
          onChangeRowsPerPage={this._handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === "light"
        ? {
            color: theme.palette.grey.A700,
            backgroundColor: lighten(theme.palette.grey.A100, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      fontWeight: "bold",
    },
    headerCell: {
      fontWeight: "bold",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    fullWidth: {
      width: "100%",
    },
    container: {
      height: document.documentElement.clientHeight - (window.innerWidth < 600 ? 170 : 186),
    },
    inline: {
      display: "flex",
    },
    searchInput: {
      width: 256,
    },
    invisible: {
      visibility: "hidden",
    },
  });
export default withStyles(styles)(ScoreCardsList);
