import CoursesApi from "../api/courses";
import { BaseActions } from "./BaseActions";
import ErrorHandler from "../api/Errorhandler";
import ReduxTypes from "../consts/ReduxTypes";
import { LocalRulesData } from "types/course";
import { showSnackbar } from "components/SharedComponents/Notifier";

export default new (class GameActions extends BaseActions {
  constructor() {
    super(CoursesApi, "COURSES");
  }
  getScoreCards = (courseId: string, callback?: any) => {
    return (dispatch: any) => {
      CoursesApi.getScoreCards(courseId)
        .then((res: any) => {
          if (callback) callback(null, res.data);
        })
        .catch((err: any) => {
          console.log(err);
        });
    };
  };
})();

export const updateLocalRules = (id: string, data: LocalRulesData) => {
  return (dispatch: any) => {
    CoursesApi.updateLocalRules(id, data)
      .then((res: any) => {
        showSnackbar("Data updated successfully.", 3000, "success");
        dispatch({
          type: ReduxTypes.UPDATE_LOCAL_RULES,
          payload: {
            id,
            content: data.content,
            logo: res.data?.data?.logo,
          },
        });
      })
      .catch((err: any) => {
        ErrorHandler(err, dispatch);
      });
  };
};
