import { Player } from "types/player";
import { PlayersVerificationType } from "types/general";

export const getPlayersVerificationState = (
  players: Player[],
  holesNumber: number,
  verifyQs: boolean,
): PlayersVerificationType => {
  return players.reduce((acc, p) => ({ ...acc, [p._id]: _isVerified(p, holesNumber, verifyQs) }), {});
};

const _isVerified = (player: Player, holesNumber: number, verifyQs: boolean) => {
  if (!verifyQs) {
    return Object.keys(player.holes).length === holesNumber;
  }

  return _havePlayerAndMarkerScored(player, holesNumber);
};

const _havePlayerAndMarkerScored = (player: Player, totalHoles: number) => {
  const { uholes = {}, holes = {} } = player;
  let pCompletedHoles = 0;
  let mCompletedHoles = 0;

  for (const hole of Object.keys(holes)) {
    const isMismatch = holes[+hole].mismatch;
    if (isMismatch) return "X";
    const grosses = uholes[+hole]?.grosses;
    if (!grosses) continue;
    // Player gross
    const pGross = grosses[player._id];
    if (pGross) ++pCompletedHoles;
    // Marker gross
    const markerId = Object.keys(grosses).find(pId => pId !== player._id);
    if (markerId && grosses[markerId]) ++mCompletedHoles;
  }

  if (pCompletedHoles === totalHoles && pCompletedHoles === mCompletedHoles) return true;

  if (pCompletedHoles === totalHoles) return "P";
  if (mCompletedHoles === totalHoles) return "M";

  return false;
};
