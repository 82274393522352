import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import queryString from "query-string";
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "@material-ui/core/Paper";
import LinearProgress from "@material-ui/core/LinearProgress";
import { GlobalState } from "../../../types/globalState";
import { signUp, confirmUserEmail } from "../../../actions";
import { createStyles, Theme, WithStyles } from "@material-ui/core";
import logo from "../../../assets/images/qr-logo.png";
import { Step1 } from "./Step1";
import { Step2 } from "./Step2";
import { Step3 } from "./Step3";
import { Step4 } from "./Step4";
import { SignupInput } from "../../../types/user";
import { useInstallPrompt } from "hooks/useInstallPrompt";
import { hcpConvertor } from "utils/hcpConvertor";

export interface SignUpPageProps extends WithStyles {
  signUp(args: SignupInput, callBack?: any): void;
  confirmUserEmail(token: string): void;
  loading: boolean;
}

interface SignUpStepState extends SignupInput {
  step: number;
}
interface SignUpStepProps extends WithStyles {
  handleFinalSubmit: (args: SignupInput) => void;
}

const SignUpStepContent: React.FC<SignUpStepProps> = ({ classes, handleFinalSubmit }) => {
  const [state, setState] = useState<SignUpStepState>({
    email: "",
    username: "",
    password: "",
    title: "",
    name: "",
    lastName: "",
    gender: "",
    homeClub: "",
    postalCode: "",
    socialHcp: "",
    hcp: 0,
    cdh: 0,
    policyAccepted: false,
    step: 0,
  });

  switch (state.step) {
    case 0:
      return (
        <Step1
          classes={classes}
          defaults={state.email}
          onNext={email => {
            setState(prevState => ({ ...prevState, step: 1, email }));
          }}
        />
      );
    case 1:
      return (
        <Step3
          defaults={state.password}
          classes={classes}
          onNext={password => {
            setState(prevState => ({ ...prevState, step: 2, password }));
          }}
          onPrev={() => setState(prevState => ({ ...prevState, step: 0 }))}
        />
      );
    case 2:
      return (
        <Step2
          defaults={state.username}
          classes={classes}
          onNext={username => {
            setState(prevState => ({ ...prevState, step: 3, username }));
          }}
          onPrev={() => setState(prevState => ({ ...prevState, step: 1 }))}
        />
      );
    case 3:
      return (
        <Step4
          classes={classes}
          onNext={args => {
            handleFinalSubmit({ ...state, ...args, hcp: parseFloat(hcpConvertor(args.hcp)) });
          }}
          onPrev={() => setState(prevState => ({ ...prevState, step: 2 }))}
        />
      );
    default:
      return null;
  }
};

const SignUpPage: React.FC<SignUpPageProps> = props => {
  const { classes, loading, signUp, confirmUserEmail } = props;
  const prompt = useInstallPrompt();
  const location = useLocation();
  const history = useHistory();
  const { token } = queryString.parse(location.search);

  const handleSubmit = (formValues: SignupInput) => {
    signUp(formValues, () => {
      if (prompt) prompt.prompt();
    });
  };

  useEffect(() => {
    if (token) confirmUserEmail(`${token}`);

    return () => {
      history.replace({ search: "" });
    };
  }, [token, confirmUserEmail, history]);

  return (
    <div>
      {loading ? <LinearProgress color="primary" variant="query" /> : null}
      <main className={classes.main}>
        <Paper className={classes.paper}>
          <img src={logo} alt={"logo"} width={128} />
          <strong>Schala</strong>
          {!token && (
            <div className={classes.stepperContainer}>
              <SignUpStepContent classes={classes} handleFinalSubmit={handleSubmit} />
            </div>
          )}
        </Paper>
      </main>
    </div>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    main: {
      width: "auto",
      display: "block", // Fix IE 11 issue.
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      [theme.breakpoints.up(400 + theme.spacing(6))]: {
        width: 400,
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    root: {
      "& .Mui-error": {
        fontWeight: "bold",
      },
    },
    paper: {
      width: window.innerWidth < 400 ? "100%" : 360,
      height: window.innerWidth < 400 ? "100%" : "auto",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
      backgroundColor: "rgba(255,255,255,0.85)",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%)",
      maxHeight: "100vh",
    },
    avatar: {
      margin: theme.spacing(3),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
      padding: theme.spacing(2),
      paddingTop: 0,
    },
    submit: {
      marginTop: theme.spacing(3),
      backgroundColor: "#4692e7",
      borderRadius: 100,
    },
    stepperContainer: {
      marginBottom: 32,
      overflowY: "auto",
      height: "100%",
    },
    stepContainer: {
      width: 320,
      marginBottom: 16,

      "& .policy-label .MuiTypography-body1": {
        fontSize: 14,
        marginTop: 10,

        "& > a": {
          fontSize: "1.1em",
        },
      },
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      justifyContent: "space-between",
    },
  });

const mapStateToProps = (state: GlobalState) => {
  return { loading: state.general.loading };
};

export default connect(mapStateToProps, { signUp, confirmUserEmail })(withStyles(styles)(SignUpPage));
