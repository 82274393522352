export default {
  error: {
    emailExist: "Email already exists. Use Forgot Password to retrieve your account.",
    emailNotExist: "Email/Username does not exist on schala",
    emailNotValid: "Email is not valid",
    phoneNotValid: "Phone no is not valid",
    usernameExist: "Username already exist!",
    emailUserShortLength: "Username/email is not valid",
    usernameShortLength: "Usernames must be at least 5 characters and not contain blank spaces or swear words",
    passwordShortLength: "Password must be at least 6 character",
    nameShortLength: "Name should be at least 3 character",
  },
  apiError: {
    404: "Item Not found!",
  },
  share: {
    title: "Share this Game invite link with your friends",
    message:
      "Let's play golf with Schala, please tap on the following link to automatically enter the Game Code on your device",
  },
  removingGame: {
    title: "Remove Game",
    message: "You are about to removing this game from your history, Are you sure?",
  },
};
