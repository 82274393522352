import { Button, createStyles, Theme, WithStyles } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import React, { Component } from "react";
import { connect } from "react-redux";
import { GlobalState } from "../../types/globalState";
import HoleScoreForm from "./HoleScoreForm/HoleScoreForm";
import { IndexedGames } from "../../types/game";
import CombinedScoreForm from "./HoleScoreForm/CombinedScoreForm";
import { isCombinedGame } from "./../../utils/index";

interface Props extends WithStyles {
  joinedGames: IndexedGames;
  gameId: string;
}
enum View {
  MyCardView,
  ScorerView,
}
interface State {
  view: View;
}

class GameManager extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      view: View.MyCardView,
    };
  }

  _switchView = () => {
    this.setState(state => {
      const view = state.view === View.MyCardView ? View.ScorerView : View.MyCardView;
      return { view };
    });
  };

  renderScorer() {
    const { gameId } = this.props;
    const { view } = this.state;
    return (
      <>
        <Button color="primary" variant={"contained"} onClick={this._switchView} fullWidth>
          {view === View.MyCardView ? "Click to put others scores" : "Click to see your ScoreCard"}
        </Button>
        <HoleScoreForm gameId={gameId} />
      </>
    );
  }
  render() {
    const { gameId } = this.props;
    const { competition } = this.props?.joinedGames?.[gameId] ?? { competition: undefined };

    if (competition && isCombinedGame(competition)) return <CombinedScoreForm gameId={gameId} />;
    return <HoleScoreForm gameId={gameId} />;
  }
}

const styles = (theme: Theme) => createStyles({});
const mapStateToProps = (state: GlobalState) => {
  const { joinedGames } = state.games;
  return {
    joinedGames,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(GameManager));
