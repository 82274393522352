import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  root: {
    padding: "1rem 2rem 2rem",
  },
  avatar: {
    width: "6rem",
    height: "6rem",
    textAlign: "center",
    backgroundColor: "#aea4a4",
  },
  avatarContainer: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "1rem",
  },
  icon: {
    color: "#fff",
    backgroundColor: "#bbb",
    borderRadius: "50%",
    fontSize: 30,
    padding: 4,
  },
  btn: {
    position: "absolute",
    bottom: 0,
    right: "44%",
  },
  cta: {
    display: "flex",
    marginTop: "1rem",

    "& > button": {
      minWidth: "6rem",

      "&:first-child": {
        marginRight: "1rem",
      },
    },
  },
});
