import { Action } from "../types/actions";
import ReduxTypes, { Keys } from "../consts/ReduxTypes";
import { fetchReducer } from "./BaseReducerFunctions";
import { WithdrawsState } from "../types/withdraws";

const INITIAL_STATE = {
  withdraws: {},
  count: 0,
};
const KEY: Keys = "WITHDRAWS";

export default (state: WithdrawsState = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case ReduxTypes.getFetchType(KEY):
      return fetchReducer(state, action, "withdraws");
    case ReduxTypes.PURGE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
