import { Button, createStyles, Divider, Paper, Theme, WithStyles } from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { logOut } from "../../actions";
import { hideDialog } from "../SharedComponents/Dialog";

interface Props extends WithStyles {
  logOut(): void;
}

class Dashboard extends Component<Props, {}> {
  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.container}>
        <h2>WELCOME TO SCHALA BASIC</h2>
        <p>You have successfully signed up and are now ready to create and join game scoring</p>
        <Divider />
        <p>
          When you are given a valid game code, just sign in again and you will be able to enter it and join the game.
        </p>
        <Divider />
        <p>
          Once you have joined, you will be able to digitally score cards and have them digitally verified by others for
          both competition results and handicap submission purposes.
        </p>
        <Divider />
        <p>
          You are using the basic free version where you can score in ANY game created by others, you can create your own
          4 player individual game and you can view the live leaderboard throughout any game.
        </p>
        <div className={classes.inline}>
          <Button
            color={"secondary"}
            variant={"outlined"}
            onClick={() => {
              this.props.logOut();
              hideDialog();
            }}
          >
            LOG OUT
          </Button>
          <Button onClick={hideDialog} color={"primary"} variant={"outlined"}>
            Go to Game Entry
          </Button>
        </div>
        <p style={{ color: "#cc3f3c" }}>
          Upgrade to the Premium version is not currently available in the BETA version. However, when it does become
          available, you will be able to create multi user games, organise roll ups, swindles, and other competition
          formats with both individual and team selections. You will also be able to produce start sheets if required
          and submit digital score cards for custom games, tournaments and conventional or custom handicap submission
          purposes. For further information on current availability, please contact info@schalagolf.com or visit our
          website www.schalagolf.com
        </p>
        <div className={classes.inline}>
          <Button href={"http://www.schalagolf.com"} target={"_blank"} variant={"contained"} color={"primary"}>
            Upgrade Now
          </Button>
        </div>
      </Paper>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "block",
      width: "auto",
      minWidth: 300,
      maxWidth: 640,
      padding: 12,
      paddingBottom: 16,
    },
    inline: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-around",
    },
  });
export default connect(null, { logOut })(withStyles(styles)(Dashboard));
