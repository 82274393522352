import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { GlobalState } from "../../types/globalState";
import { WithStyles } from "@material-ui/core";
import ScoreCardActions from "../../actions/scoreCards";
import MyTable, { TableAction, TableState } from "../SharedComponents/MyTable";
import { showDialog } from "../SharedComponents/Dialog";
import ScoreCardSetup from "./ScoreCardSetup";
import ListStyles from "../../SharedStyles/ListStyle";
import { IndexedScoreCards, ScoreCard } from "../../types/scoreCard";

interface Props extends WithStyles {
  deleteScoreCard(id: string): void;

  fetchScoreCards(page: number, limit: number, filter?: string, sort?: string, dir?: string, query?: string): void;

  scoreCards: ScoreCard[];
  scoreCardsObj: IndexedScoreCards;
  loading: boolean;
  count: number;
}
interface State {
  [inde: string]: any;
}

class ScoreCardsListTable extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      openDialog: false,
    };
    this.props.fetchScoreCards(1, 50, undefined, "name");
  }

  _onTableChange = (actions: TableAction, state: TableState) => {
    this.props.fetchScoreCards(
      state.page + 1,
      state.pageSize,
      undefined,
      state.sort.field,
      state.sort.dir,
      state.query,
    );
  };
  _onEditItem = (rowId: string) => {
    showDialog(<ScoreCardSetup edit={this.props.scoreCardsObj[rowId]} />, false, true);
  };
  _onDuplicateItem = (rowId: string) => {
    showDialog(<ScoreCardSetup duplicate={this.props.scoreCardsObj[rowId]} />, false, true);
  };
  _onDeleteItems = (rowsIds: string[]) => {
    rowsIds.forEach((id: string) => {
      this.props.deleteScoreCard(id);
    });
  };
  _onAddItem = () => {
    showDialog(<ScoreCardSetup />, false, true);
  };
  prepareData = (items: any) => {
    return items.map((item: ScoreCard) => {
      let holeNum: any = item.course.holesNumber;
      if (item.course.holesNumber === 18) {
        holeNum += item.combination === 3 ? "(3x6)" : "(2x9)";
      }
      if (item) {
        return {
          ...item,
          club: item.course.club?.name,
          course: item.course.name,
          holeNum,
        };
      } else {
        return item;
      }
    });
  };

  render() {
    const { classes, scoreCards, loading, count } = this.props;
    const columns = [
      {
        field: "name",
        title: "Name",
      },
      {
        field: "club",
        title: "Club",
      },
      {
        field: "course",
        title: "Course",
      },
      {
        field: "holeNum",
        title: "Number of holes",
      },
      {
        field: "teeColor",
        title: "Tee",
      },
      {
        field: "gender",
        title: "Gender",
      },
      {
        field: "slope",
        title: "Slope",
      },
      {
        field: "cardType",
        title: "Type",
      },
    ];

    return (
      <div className={classes.paper}>
        <MyTable
          title={"Score Cards"}
          loading={loading}
          count={count}
          data={this.prepareData(scoreCards)}
          columns={columns}
          defaultOrderBy={"name"}
          selectable
          searchEnabled
          onTableChange={this._onTableChange}
          onEdit={this._onEditItem}
          onDelete={this._onDeleteItems}
          onAdd={this._onAddItem}
          onDuplicate={this._onDuplicateItem}
        />
      </div>
    );
  }
}

const styles = ListStyles({});
const mapStateToProps = (state: GlobalState) => {
  const { scoreCards, count } = state.scoreCards;
  const { loading } = state.general;
  return {
    scoreCardsObj: scoreCards,
    scoreCards: scoreCards ? Object.values(scoreCards) : [],
    loading,
    count,
  };
};

export default connect(mapStateToProps, {
  deleteScoreCard: ScoreCardActions.delete,
  fetchScoreCards: ScoreCardActions.fetch,
})(withStyles(styles)(ScoreCardsListTable));
