import React from "react";
import { createStyles, IconButton, Theme, withStyles, WithStyles } from "@material-ui/core";
import { Check, GolfCourse, SportsGolf } from "@material-ui/icons";
import { GState } from "../../../types/game";
import { Player } from "../../../types/player";
import GrossPickerDialog from "../../Game/GrossPickerDialog";
import { showDialog } from "../../SharedComponents/Dialog";
import ModernScoreCardView from "../../ScoreCard/ModernScoreCardView";

const IS_UNVERIFIABLE: number = -2;

interface Props extends WithStyles {
  gameId: string;
  player: Player;
  holeNumber: number;
  numberOfHoles: number;
  combined?: boolean;
  openPickerDialogForMisMatchHole(player: any, holeNumber: number): void;
  handleGrossChange(gameId: string, player: Player, holeNumber: number, gross: any, dnfSelected: boolean): void;
  handleVerifyPlayer(playerId: string): void;
  grossFinalCheck(player: Player): number | void;
}

const ScoringTableActionButton = (props: Props) => {
  const { classes, player, holeNumber, gameId, numberOfHoles } = props;
  const checkResult = props.grossFinalCheck(player);

  const openPickerDialog = (gameId: string, player: Player, holeNumber: number) =>
    showDialog(
      <GrossPickerDialog
        gameId={gameId}
        holeNumber={holeNumber}
        onSelect={(gross: any, dnfSelected: boolean, p?: Player) =>
          props.handleGrossChange(gameId, p || player, holeNumber, gross, dnfSelected)
        }
      />,
      "sm",
      false,
      true,
      true,
      "ENTER PLAYERS GROSS SCORE",
    );

  const handlePickerDialog = (gameId: string, player: Player, holeNumber: number) => {
    if (props.combined) openPickerDialog(gameId, player, holeNumber);
    else openPickerDialog(gameId, player, holeNumber);
  };

  if (player.state === GState.Done) return null;

  if (!checkResult)
    return (
      <IconButton
        className={classes.action}
        style={{ backgroundColor: "#3f51b5" }}
        onClick={() => handlePickerDialog(gameId, player, holeNumber)}
      >
        <SportsGolf style={{ color: "#FFF" }} />
      </IconButton>
    );
  else if (checkResult === -1)
    return (
      <IconButton
        className={classes.action}
        style={{ backgroundColor: "#33a136" }}
        onClick={() => handlePickerDialog(gameId, player, holeNumber)}
      >
        <Check style={{ color: "#FFF" }} />
      </IconButton>
    );
  else if (checkResult == IS_UNVERIFIABLE) {
    return (
      <>
        {player.holes[numberOfHoles] && player.holes[numberOfHoles].gross ? (
          <IconButton
            className={classes.action}
            style={{ backgroundColor: "#ffaf0f" }}
            onClick={() =>
              showDialog(
                <ModernScoreCardView
                  gameId={gameId}
                  player={player}
                  onVerifyPlayer={props.handleVerifyPlayer}
                  quickScoring
                />,
                "xl",
                false,
                true,
                true,
                "Does Player agree to their score?",
              )
            }
          >
            <span className={classes.orangeCircle}>Hand in card</span>
          </IconButton>
        ) : (
          <IconButton
            className={classes.action}
            style={{ backgroundColor: "#ffaf0f" }}
            onClick={() => handlePickerDialog(gameId, player, holeNumber)}
          >
            <GolfCourse style={{ color: "#FFF" }} />
          </IconButton>
        )}
      </>
    );
  } else if (checkResult !== undefined)
    return (
      <IconButton
        className={classes.action}
        style={{ backgroundColor: "#ff2011" }}
        onClick={() => props.openPickerDialogForMisMatchHole(player, checkResult)}
      >
        <span style={{ height: 24, width: 24, color: "#FFF" }}>{checkResult}</span>
      </IconButton>
    );
  return null;
};

const styles = (theme: Theme) =>
  createStyles({
    action: {
      position: "absolute",
      top: 60,
      right: 8,
    },

    orangeCircle: {
      color: "#fff",
      fontSize: "small",
      maxWidth: "35px",
      maxHeight: "38px",
    },
  });

export default withStyles(styles)(ScoringTableActionButton);
