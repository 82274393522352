import React from "react";

export interface LeaderboardUnavailableProps {}

const LeaderboardUnavailable: React.SFC<LeaderboardUnavailableProps> = () => {
  return (
    <div style={{ width: "100%", padding: 10, backgroundColor: "#ffffff", textAlign: "center" }}>
      <h2>Leaderboard turned off by organiser.</h2>
    </div>
  );
};

export default LeaderboardUnavailable;
