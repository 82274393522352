import BaseApi from "./Api";

class ScoreCardsApi extends BaseApi {
  url = 'scoreCards';
  constructor() {
    super();
    this.setResourceUrl(this.url);
  }
}

export default new ScoreCardsApi();
