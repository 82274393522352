import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Checkbox,
  createStyles,
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  Button,
  InputAdornment,
  InputBase,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Toolbar,
  Tooltip,
  Typography,
  WithStyles,
} from "@material-ui/core";
import {
  AddCircle as Add,
  Close,
  HighlightOff,
  PersonAdd,
  RemoveCircle,
  Search,
  PictureAsPdf,
  Edit as EditIcon,
} from "@material-ui/icons";
import { searchPlayers } from "../../actions";
import { TightBodyCell, TightHeaderCell } from "../SharedComponents/CustomTableElements";
import GameActions from "../../actions/games";
import { GlobalState } from "../../types/globalState";
import { showPickerDialog } from "../SharedComponents/PickerDialog";
import TeamActions from "../../actions/teams";
import GroupCreation from "../Groups/GroupCreation";
import { showSnackbar } from "../SharedComponents/Notifier";
import { Game, IndexedGames } from "./../../types/game";
import { ErrorOnlyCallback } from "../../types/actions";
import { User, Rules } from "../../types/user";
import { PlayingAsFormats, PlayingFormats } from "../../types/competition";
import * as socket from "../../socket";
import { showAlertDialog } from "../SharedComponents/AlertDialog";
import { Player } from "../../types/player";
import DialogToolbar from "components/SharedComponents/DialogToolbar";
import PlayerName from "components/SharedComponents/PlayerName";
import RemoveDialogContent from "./RemoveDialogContent";
import GuestPlayerForm from "./GuestPlayerForm";
import { GuestPlayerInputs } from "types/player";
import { Team } from "types/team";
import { getHandicapDisplay, sortByGroupName } from "utils";
import VideoPopup from "components/SharedComponents/VideoPopup";
import { HELP_VIDEOS } from "consts";
interface Props extends WithStyles {
  updatePlayersPH(id: string, playerIds: string[]): void;
  addPlayer(id: string, playerId: string, itsMe: boolean, callback?: ErrorOnlyCallback): void;
  addGuestPlayer(id: string, inputs: GuestPlayerInputs, callback?: ErrorOnlyCallback): void;
  insertTeam(name: string, gameId: string, color?: string): void;
  updateTeam(teamId: string, gameId: string, name: string, color?: string): void;
  searchPlayersByUserName(username: string, callback: any): void;
  removePlayer(id: string, playerId: string): void;
  updateGameGroup(id: string, group: string, oldName: string, playerIds: string[]): void;
  addPlayerToGroup(id: string, group: string, playerId: string): void;
  updateGroupStartingHole(id: string, startingHole: number, playerId: string, teamId?: string): void;
  changePlayerTeam(id: string, playerId: string, teamId: string): void;
  removePlayerFromGroup(id: string, group: string, playerId: string): void;
  setUpdatedResult(game: any): void;
  getGamePlayers(id: string, cb?: VoidFunction): void;
  games: IndexedGames;
  gameId: string;
  minChar: number; //Minimum character to call searchPlayers
  user: User;
  players: Player[];
  isLoading: boolean;
  onSwitchClick: VoidFunction;
}

enum DialogTypes {
  REMOVE_TEAM = "REMOVE_TEAM",
  REMOVE_GROUP = "REMOVE_GROUP",
  ADD_GUEST = "ADD_GUEST",
  ADD_GROUP = "ADD_GROUP",
  SEARCH_PLAYERS = "SEARCH_PLAYERS",
}

const toolbarConfig = {
  [DialogTypes.REMOVE_GROUP]: {
    title: "Remove Groups",
  },
  [DialogTypes.REMOVE_TEAM]: {
    title: "Remove Teams",
  },
  [DialogTypes.SEARCH_PLAYERS]: {
    border: false,
  },
  [DialogTypes.ADD_GROUP]: {},
  [DialogTypes.ADD_GUEST]: {
    title: "Add Unregistered Player",
    secondary: true,
  },
};

interface State {
  [index: string]: any;
  searchRes: any;
  searchTxt: string;
  openDialog: boolean;
  dialogType: DialogTypes | "";
  players: any;
}

class GamePlayersManager extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      searchRes: [],
      searchTxt: "",
      openDialog: false,
      gameId: "",
      dialogType: "",
      players: [],
    };
  }

  componentDidMount() {
    this.props.getGamePlayers(this.props.gameId, () => {
      // Notify the organiser if there are some empty teams in the game
      const game = this.getGame();
      if (
        game?.competition.playingRules.playingAs === PlayingAsFormats.Team &&
        (this.props.user.rule === Rules.Organiser || this.props.user.rule === Rules.Admin)
      ) {
        const unusedTeams = game.teams.filter(tm => !this.props.players.some(p => p.team?._id === tm._id));

        if (unusedTeams.length) {
          showAlertDialog(
            "",
            `There are some teams (${unusedTeams
              .map(t => t.name)
              .join(", ")}) that have not been used. Please remove them if they are not needed.`,
            null,
          );
        }
      }
    });
    //just for combined games in case of receiving new hcps on team changes
    this.connectToWebsocket(this.props.gameId);
  }

  componentWillUnmount() {
    socket.disconnect();
  }

  onPlayersChanged = (result: any) => {
    this.props.setUpdatedResult(result);
  };

  connectToWebsocket = (gameId: any) => {
    if (socket.connect(gameId)) {
      socket.addEvent(socket.EVENTS.PLAYERS_CHANGED, this.onPlayersChanged);
    }
  };

  searchPlayers = (value: string) => {
    this.props.searchPlayersByUserName(value, (result: any, err: any) => {
      this.setState({ searchRes: result.data });
    });
  };
  clearPlayers = () => {
    this.setState({ players: {} });
  };
  getGame = (): Game => {
    return this.props.games[this.props.gameId];
  };

  getGroups = () => {
    const game = this.getGame();
    const groups = new Set(game?.groups || []);

    this.props.players.forEach(p => {
      if (p.group?.name) groups.add(p.group.name);
    });

    return [...groups].sort(sortByGroupName);
  };

  _getTeamNames = () => {
    const game = this.getGame();
    return [...new Set(game.teams.map(t => t.name))];
  };

  _getUniqueTeams = () => {
    const game = this.getGame();
    return [...new Map(game.teams.map(t => [t.name, t])).values()];
  };

  _onTextFieldChanged = (field: string) => (event: any) => {
    const value = event.target.value as string;
    this.setState({ [field]: value });
    if (value.length >= this.props.minChar) {
      this.searchPlayers(value);
    } else {
      this.setState({ searchRes: [] });
    }
  };

  _removePlayer = (player: any) => {
    const { gameId } = this.props;
    this.props.removePlayer(gameId, player._id);
  };

  _addPlayer = (player: any, guestInputs?: GuestPlayerInputs, cb?: () => void) => {
    const { gameId, user } = this.props;
    const errCallback = (error: any) => {
      if (error) {
        if (error?.response?.data.payload?.isFull) {
          this.setState({ isGameFull: true });
          showAlertDialog("Ooops!", "The game is full.", null);
        }
      } else {
        this.props.getGamePlayers(this.props.gameId); // TODO: We need to refactor API design
        if (cb) cb();
      }
    };
    // Guest player
    if (guestInputs) this.props.addGuestPlayer(gameId, guestInputs, errCallback);
    // Normal player
    else this.props.addPlayer(gameId, player._id, player._id === user._id, errCallback);
    //
    const { name, lastName } = player ? player : guestInputs;
    this.setState({ searchTxt: "", searchRes: [] });
    showSnackbar(`${name} ${lastName ? lastName : ""} has been added to the list.`);
  };

  _onTeamEdit = (team: Team) => {
    const game = this.getGame();

    const renderColorPicker =
      game.competition.playingRules.playingAs === PlayingAsFormats.Team &&
      game.competition.playingRules.playingFormat === PlayingFormats.Matchplay;

    showPickerDialog(
      "Team name",
      "Text",
      this._handleEditTeam(team._id),
      3,
      team.name,
      null,
      true,
      false,
      renderColorPicker,
    );
  };

  _handleEditTeam = (teamId: string) => (teamName: string, color: string) => {
    console.log(teamId, teamName, color);
    const { gameId } = this.props;
    this.props.updateTeam(teamId, gameId, teamName, color);
  };

  _onGroupEdit = (player: Player) => {
    showPickerDialog(
      "Group name",
      "Text",
      this._handleEditGroup(player.group.name),
      3,
      player.group.name,
      null,
      true,
      false,
    );
  };

  _handleEditGroup = (oldName: string) => (groupName: string) => {
    const { gameId } = this.props;
    const playerIds = this.props.players.filter(p => p.group?.name === oldName).map(p => p._id);
    this.props.updateGameGroup(gameId, groupName, oldName, playerIds);
  };

  _clear = () => {
    this.setState({ searchTxt: "", searchRes: [] });
  };

  _handleAddNewTeam = (teamName: string, color?: string) => {
    this.props.insertTeam(teamName, this.getGame()._id, color);
    this._toggleNewTeamDialog();
  };

  _toggleNewTeamDialog = () => {
    const game = this.getGame();

    const renderColorPicker =
      game.competition.playingRules.playingAs === PlayingAsFormats.Team &&
      game.competition.playingRules.playingFormat === PlayingFormats.Matchplay;

    showPickerDialog("Team name", "Text", this._handleAddNewTeam, 3, null, null, true, false, renderColorPicker);
  };

  _toggleDialog = (type?: DialogTypes) => () => {
    this.setState({ openDialog: type ? true : !this.state.openDialog, dialogType: type || "" });
  };

  _onChangeTeam = (player: any, teamId: string) => {
    const game = this.getGame();
    if (game) {
      this.props.changePlayerTeam(game._id, player._id, teamId);
    }
  };

  _onChangeGroup = (player: any, group: string) => {
    const game = this.getGame();
    if (game) {
      this.props.addPlayerToGroup(this.props.gameId, group, player._id);
    }
  };

  _checkPayBeforeAdd = (confirmCallBack: VoidFunction) => {
    const game = this.getGame();

    if (game?.isPaid && game.paymentRules?.payBeforePlay) {
      showAlertDialog(
        "You are adding a player to the pay before play game. You confirm that payment has been made by other means.",
        "",
        confirmCallBack,
        // No
        () => {},
      );
    } else {
      confirmCallBack();
    }
  };

  _renderDialogContent = () => {
    switch (this.state.dialogType) {
      case DialogTypes.ADD_GUEST:
        return (
          <GuestPlayerForm
            displayText
            onSubmit={(formValue: GuestPlayerInputs, cb: () => void) => {
              this._checkPayBeforeAdd(() => {
                this._addPlayer(null, formValue, cb);
              });
            }}
          />
        );
      case DialogTypes.REMOVE_GROUP:
        return (
          <RemoveDialogContent
            items={this.getGroups()}
            gameId={this.props.gameId}
            onClose={this._toggleDialog()}
            type="Group"
          />
        );
      case DialogTypes.REMOVE_TEAM:
        return (
          <RemoveDialogContent
            items={this._getTeamNames()}
            gameId={this.props.gameId}
            onClose={this._toggleDialog()}
            type="Team"
          />
        );
      case DialogTypes.ADD_GROUP:
        return (
          <GroupCreation organiser={true} routerProps={{ gameId: this.props.gameId }} onSubmit={this._toggleDialog()} />
        );
      case DialogTypes.SEARCH_PLAYERS:
        return this.renderSearchContent();
      default:
        return null;
    }
  };

  renderDialogs() {
    const { classes } = this.props;
    const toolbarProps = this.state.dialogType ? toolbarConfig[this.state.dialogType] : {};
    return (
      <Dialog
        open={this.state.openDialog}
        onBackdropClick={this._toggleDialog()}
        className={this.state.dialogType === "SEARCH_PLAYERS" ? classes.searchDialog : ""}
      >
        <DialogToolbar {...toolbarProps} onClick={this._toggleDialog()} />
        {this._renderDialogContent()}
      </Dialog>
    );
  }

  renderSearchContent = () => {
    const { searchRes, searchTxt } = this.state;
    const { classes, players } = this.props;

    return (
      <DialogContent>
        <InputBase
          value={searchTxt}
          placeholder={"Input username or email"}
          className={classes.searchInput}
          inputProps={{ autoFocus: true }}
          onChange={this._onTextFieldChanged("searchTxt")}
          startAdornment={
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          }
          endAdornment={
            searchTxt && (
              <InputAdornment position="end">
                <IconButton onClick={this._clear}>
                  <Close fontSize="small" />
                </IconButton>
              </InputAdornment>
            )
          }
        />

        {searchRes?.length ? (
          <List>
            {searchRes.map((item: any) => {
              const alreadyAdded = !!players.find((p: Player) => p._id === item._id);
              return (
                <ListItem
                  button
                  disabled={alreadyAdded}
                  onClick={() => {
                    this._checkPayBeforeAdd(() => {
                      this._addPlayer(item);
                    });
                  }}
                >
                  <ListItemIcon>
                    <Checkbox edge="start" checked={alreadyAdded} disableRipple />
                  </ListItemIcon>
                  <ListItemText primary={`${item.name} ${item.lastName || ""}`} secondary={item.username} />
                </ListItem>
              );
            })}
          </List>
        ) : (
          searchTxt.length >= this.props.minChar && (
            <div className={classes.guestContainer}>
              <Typography color="secondary">
                Player not registered ?
                <br />
                Add as a Guest
              </Typography>

              <Tooltip title="add guest player" leaveTouchDelay={2000}>
                <IconButton onClick={this._toggleDialog(DialogTypes.ADD_GUEST)}>
                  <PersonAdd color="secondary" />
                </IconButton>
              </Tooltip>
            </div>
          )
        )}
      </DialogContent>
    );
  };

  customizedSortHandler = (players: any) => {
    let PlayersWithGroup = [];
    let PlayersWithoutGroup = [];
    for (let i = 0; i < players.length; i++) {
      if (!players[i].group || !players[i].group.name) {
        PlayersWithoutGroup.push(players[i]);
      }
      if (players[i].group && players[i].group.name) {
        PlayersWithGroup.push(players[i]);
      }
    }
    PlayersWithGroup.sort((a: any, b: any) => {
      const { startingHole: sth1 = 1, name: nm1 } = a.group;
      const { startingHole: sth2 = 1, name: nm2 } = b.group;
      // First sort them by Start Hole
      if (sth1 !== sth2) {
        return sth1 - sth2;
      }

      return sortByGroupName(nm1, nm2);
    });
    PlayersWithoutGroup.sort((a: any, b: any) =>
      a["lastName"] < b["lastName"] ? -1 : a["lastName"] > b["lastName"] ? 1 : 0,
    );
    return [...PlayersWithGroup, ...PlayersWithoutGroup];
  };

  _updatePH = () => {
    const game = this.getGame();
    if (game) {
      const playerIds = this.props.players.map(p => p._id);
      this.props.updatePlayersPH(game._id, playerIds);
    }
  };

  _updateStartHole = (p: Player, value: number) => {
    if (this.props.gameId) this.props.updateGroupStartingHole(this.props.gameId, value, p._id, p.team?._id);
  };

  renderTable() {
    const { classes, players: ps, isLoading } = this.props;

    const game = this.getGame();
    if (!game) return <LinearProgress />;

    const players = ps
      .filter(x => !x.isTeamCard)
      .sort((a: any, b: any) => (a.group && b.group && a.group.name > b.group.name ? 1 : -1));

    return (
      <>
        <Toolbar>
          <Button
            disableElevation
            variant="contained"
            className={classes.linkBtn}
            startIcon={<PictureAsPdf color="primary" />}
            onClick={this.props.onSwitchClick}
          >
            Start Sheet
          </Button>

          {players.length > 0 && (
            <Button className={classes.rBtn} variant="outlined" color="secondary" onClick={this._updatePH}>
              Update Players PH
            </Button>
          )}
          <Tooltip title="add player" leaveTouchDelay={2000}>
            <IconButton aria-label="add player" onClick={this._toggleDialog(DialogTypes.SEARCH_PLAYERS)}>
              <PersonAdd className="green" />
            </IconButton>
          </Tooltip>
        </Toolbar>
        <TableContainer className={classes.paper} style={{ opacity: isLoading ? "0.3" : "1" }}>
          {players.length > 0 && (
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TightHeaderCell>
                    <span>Name</span>
                    <VideoPopup styles={{ marginLeft: 25 }} videoURL={HELP_VIDEOS.PLAYERS_GROUPS} />
                  </TightHeaderCell>
                  <TightHeaderCell>
                    <IconButton
                      disabled={!game.groups.length}
                      color="secondary"
                      onClick={this._toggleDialog(DialogTypes.REMOVE_GROUP)}
                    >
                      <RemoveCircle />
                    </IconButton>
                    Group
                    <IconButton color={"primary"} onClick={this._toggleDialog(DialogTypes.ADD_GROUP)}>
                      <Add />
                    </IconButton>
                  </TightHeaderCell>
                  <TightHeaderCell>
                    <IconButton
                      disabled={!game.teams.length}
                      color="secondary"
                      onClick={this._toggleDialog(DialogTypes.REMOVE_TEAM)}
                    >
                      <RemoveCircle />
                    </IconButton>
                    Team
                    <IconButton color={"primary"} onClick={this._toggleNewTeamDialog}>
                      <Add />
                    </IconButton>
                  </TightHeaderCell>
                  <TightHeaderCell>Start Hole</TightHeaderCell>
                  <TightHeaderCell>CH</TightHeaderCell>
                  <TightHeaderCell>PH</TightHeaderCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.customizedSortHandler(players).map((player: any) => (
                  <TableRow key={player._id}>
                    <TightBodyCell component="th" scope="row">
                      <PlayerName
                        isGuest={player.guest}
                        name={player.name}
                        lastName={player.lastName}
                        hci={player.hci}
                      />
                      <label className={classes.usernameLabel}>{player.username}</label>
                    </TightBodyCell>
                    <TightBodyCell>
                      <IconButton
                        disabled={!player.group?.name}
                        color={"primary"}
                        onClick={() => this._onGroupEdit(player)}
                      >
                        <EditIcon />
                      </IconButton>
                      <FormControl className={classes.margin}>
                        <Select
                          labelId="group"
                          id="group"
                          value={player.group?.name || ""}
                          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                            this._onChangeGroup(player, event.target.value as string);
                          }}
                        >
                          {this.getGroups().map((item: any) => (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TightBodyCell>
                    <TightBodyCell>
                      <IconButton
                        disabled={!player.team?._id}
                        color={"primary"}
                        onClick={() => this._onTeamEdit(player.team)}
                      >
                        <EditIcon />
                      </IconButton>
                      <FormControl className={classes.margin} style={{ width: "100%" }}>
                        <Select
                          labelId="team"
                          id="team"
                          value={player.team ? player.team._id : ""}
                          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                            this._onChangeTeam(player, event.target.value as string);
                          }}
                        >
                          {game.teams
                            ? this._getUniqueTeams().map((item: any) => (
                                <MenuItem key={item._id} value={item._id}>
                                  {item.name}
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                      </FormControl>
                    </TightBodyCell>
                    <TightBodyCell>
                      <IconButton
                        disabled={!player.group?.startingHole}
                        color="secondary"
                        onClick={() => {
                          this._updateStartHole(player, 0);
                        }}
                      >
                        <RemoveCircle />
                      </IconButton>
                      <FormControl className={classes.margin} style={{ width: "100%" }}>
                        <Select
                          style={{ textAlign: "center" }}
                          labelId="startingHole"
                          id="startingHole"
                          value={player.group?.startingHole || ""}
                          onChange={evt => {
                            this._updateStartHole(player, evt.target.value as number);
                          }}
                        >
                          {game.startingTees?.map((i: number) => (
                            <MenuItem key={i} value={i}>
                              {i}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TightBodyCell>
                    {/* Course Handicap */}
                    <TightBodyCell>{getHandicapDisplay(player.courseHandicap, false)}</TightBodyCell>
                    <TightBodyCell>{getHandicapDisplay(player.hcp)}</TightBodyCell>
                    <TightBodyCell align="right">
                      <IconButton
                        onClick={() =>
                          showAlertDialog(
                            "Warning",
                            <span>
                              You are about to remove
                              <strong style={{ color: "black" }}>
                                {" " + player.name} {player.lastName + " "}
                              </strong>
                              from <strong style={{ color: "black" }}>{game.name}</strong> are you sure?
                            </span>,
                            () => this._removePlayer(player),
                            () => {},
                          )
                        }
                      >
                        <HighlightOff color={"error"} />
                      </IconButton>
                    </TightBodyCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </>
    );
  }
  render() {
    return (
      <>
        {this.renderTable()}
        {this.renderDialogs()}
      </>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      padding: 8,
      minWidth: 300,
      backgroundColor: "rgba(170, 170, 170, 0)",
    },
    linkBtn: {
      marginRight: "auto",
      backgroundColor: "#eee",
      textTransform: "inherit",
    },
    usernameLabel: {
      color: "#aaaaaa",
      display: "flex",
    },
    searchInput: {
      width: 256,
    },
    rBtn: {
      textTransform: "inherit",
      margin: "0 1rem",
      minWidth: "10rem",
      padding: 4,
    },
    searchDialog: {
      [theme.breakpoints.down("sm")]: {
        "& .MuiDialog-scrollPaper": {
          alignItems: "flex-start",
          marginTop: "20px",
        },
      },
    },
    guestContainer: {
      display: "flex",
      justifyContent: "space-between",
      margin: "15px 0",
      animation: "$fadeIn 1000ms",
    },
    "@keyframes fadeIn": {
      "0%": { opacity: 0 },
      "50%": { opacity: 0 },
      "100%": { opacity: 1 },
    },
  });

function mapStateToProps(state: GlobalState) {
  const { joinedGames, organisedByMe, games, players, isLoading } = state.games;
  const { user } = state.auth;
  return {
    user,
    games: { ...games, ...organisedByMe, ...joinedGames },
    players,
    isLoading,
  };
}

export default connect(mapStateToProps, {
  searchPlayersByUserName: searchPlayers,
  addPlayer: GameActions.addPlayer,
  updatePlayersPH: GameActions.updatePlayersPH,
  addGuestPlayer: GameActions.addGuestPlayer,
  insertTeam: TeamActions.insertTeam,
  updateTeam: TeamActions.update,
  removePlayer: GameActions.removePlayer,
  changePlayerTeam: GameActions.changePlayerTeam,
  removePlayerFromGroup: GameActions.removePlayerFromGroup,
  addPlayerToGroup: GameActions.addPlayerToGroup,
  updateGameGroup: GameActions.updateGameGroup,
  updateGroupStartingHole: GameActions.updateGroupStartingHole,
  setUpdatedResult: GameActions.setUpdatedResult,
  getGamePlayers: GameActions.getGamePlayers,
})(withStyles(styles)(GamePlayersManager));
