import { BaseActions } from "./BaseActions";
import paymentApi from "../api/payment";
import ErrorHandler from "../api/Errorhandler";
import ReduxTypes from "../consts/ReduxTypes";
import { PayStatus } from "../types/player";

export default new (class PaymentActions extends BaseActions {
  constructor() {
    super(paymentApi, "PAYMENTS");
  }

  onBoardingAccount = (redirectUrl: string, callback?: any) => {
    return (dispatch: any) => {
      paymentApi
        .onboarding(redirectUrl)
        .then((res: any) => {
          if (callback) callback(null, res.data);
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
          if (callback) callback(err, null);
        });
    };
  };

  paymentSession = (data: any, callback?: any) => {
    return (dispatch: any) => {
      paymentApi
        .paymentSession(data)
        .then((res: any) => {
          if (callback) callback(null, res.data);
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
          if (callback) callback(err, null);
        });
    };
  };

  getWithdraws = (
    page: number,
    limit: number,
    filter?: any,
    sort?: string,
    dir?: string,
    query?: string,
    callback?: any,
  ) => {
    return (dispatch: any) => {
      paymentApi
        .getWithdraws(page, limit, filter, sort, dir, query)
        .then((res: any) => {
          dispatch({
            type: ReduxTypes.getFetchType("WITHDRAWS"),
            payload: res.data.items,
            count: res.data.count,
          });
          if (callback) callback(null, res.data);
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
          if (callback) callback(err, null);
        });
    };
  };
  verifyPayment = (id: string, data: any, callback?: any) => {
    return (dispatch: any) => {
      paymentApi
        .verifyPayment(id, data)
        .then((res: any) => {
          if (callback) callback(null, res.data);
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
          if (callback) callback(err, null);
        });
    };
  };
  addWithoutPayment = (gameId: string, playersId: string[], payStatus: PayStatus, callback?: any) => {
    return (dispatch: any) => {
      paymentApi
        .addWithoutPay(gameId, playersId, payStatus)
        .then((res: any) => {
          dispatch({ type: ReduxTypes.SET_AS_PAID, id: gameId, playersId: playersId, payStatus });
          if (callback) callback(null, res.data);
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
          if (callback) callback(err, null);
        });
    };
  };
})();
