import React, { ReactElement } from "react";
import { Game } from "../../../types/game";
import { Player } from "../../../types/player";

interface Props {
  game: Game;
  holeNumber: number;
  player: Player;
}

export const Gross = ({ game, holeNumber, player }: Props) => {
  if (player.holes && player.holes[holeNumber]) {
    const hole = player.holes[holeNumber];
    if (hole.mismatch) {
      const uhole = player.uholes && player.uholes[holeNumber];
      return (
        <>
          {Object.keys(uhole.grosses).map(playerId => {
            const shortUsername = game.players[playerId].username.substring(0, 10);
            return (
              <p
                style={{ color: "#ff7a61", fontWeight: "bold", margin: 0 }}
              >{`${shortUsername}: ${uhole.grosses[playerId]}`}</p>
            );
          })}
        </>
      );
    }
    return <p style={{ color: "#4aff4d", fontWeight: "bold", margin: 0 }}> {hole.gross} </p>;
  }
  return <>0</>;
};
