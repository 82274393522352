import React, { Component } from "react";
import { HexColorPicker } from "react-colorful";
import { Button, Dialog, DialogContent } from "@material-ui/core";

import { TextInput } from "../SharedComponents/TextInput";
import { filter } from "../../utils/profanityFilter";

interface Props {
  [index: number]: any;
}

interface State {
  [index: string]: any;

  open: boolean;
  value: string;
  label: string;
  onSubmit: any;
  inputType: string;
  color?: string;
  onCancel?: any;
  minLength: number;
  loading?: boolean;
  defaultValue?: any;
  error: boolean;
  error_msg: string;
  captalize?: boolean;
  disableProfanityCheck?: boolean;
  displayColorPicker?: boolean;
}

let showPickerDialog = (
  label: string,
  inputType: string,
  onSubmit: any,
  minLength?: number,
  defaultValue?: any,
  onCancel?: any,
  captalize?: boolean,
  disableProfanityCheck?: boolean,
  displayColorPicker?: boolean,
) => {};
let hidePickerDialog = () => {};

export default class PickerDialog extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      open: false,
      value: "",
      label: "",
      minLength: 0,
      inputType: "text",
      color: "#000",
      displayColorPicker: false,
      error: false,
      error_msg: "",
      onSubmit: () => {},
    };
  }

  _handleTextFieldChange = (value: string, name: string) => {
    this.setState({ [name]: value });
  };

  _showDialog = (
    label: string,
    inputType: string,
    onSubmit: any,
    minLength?: number,
    defaultValue?: any,
    onCancel?: any,
    captalize?: boolean,
    disableProfanityCheck?: boolean,
    displayColorPicker?: boolean,
  ) => {
    this.setState({
      open: true,
      label,
      value: defaultValue,
      minLength: minLength ? minLength : 0,
      inputType,
      onSubmit,
      onCancel,
      captalize,
      disableProfanityCheck,
      displayColorPicker,
    });
  };
  _hideDialog = () => {
    this.setState({ open: false });
  };
  _onSubmit = () => {
    const { value, inputType, minLength, color, label } = this.state;
    let error = null;
    if (inputType === "number") {
      const hcpRegex = RegExp(/^\+?\d{1,2}(?:\.\d)?$/gm);
      error = !hcpRegex.test(value);
      const error_msg = error ? `invalid ${label}` : "";
      this.setState({ error, error_msg });
    }
    if (inputType !== "number") {
      error = value.length < minLength || filter.isProfane(value);
      const error_msg = error ? `Minimum required length is ${minLength}` : "";
      this.setState({ error, error_msg });
    }
    if (!error) {
      this.state.onSubmit(value.trim(), color);
      this._hideDialog();
    }
  };
  _onCancel = () => {
    const { onCancel } = this.state;
    this._hideDialog();
    if (typeof onCancel === "function") onCancel();
  };

  componentDidMount(): void {
    showPickerDialog = this._showDialog;
    hidePickerDialog = this._hideDialog;
  }

  render() {
    return (
      <Dialog open={this.state.open}>
        <DialogContent
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
            marginBottom: 10,
          }}
        >
          <TextInput
            type={this.state.inputType}
            value={this.state.value}
            label={this.state.label}
            error={this.state.error}
            helperText={this.state.error_msg}
            variant={"outlined"}
            captalize={this.state.captalize}
            disableProfanityCheck={this.state.disableProfanityCheck}
            name={"value"}
            onChange={this._handleTextFieldChange}
          />
          <Button onClick={this._onSubmit} color={"primary"}>
            Submit
          </Button>
          <Button onClick={this._onCancel} color={"secondary"}>
            Cancel
          </Button>

          {this.state.displayColorPicker && (
            <div
              style={{
                flexBasis: "100%",
                margin: "15px 0",
                display: "flex",
                alignItems: "center",
              }}
            >
              <HexColorPicker
                color={this.state.color}
                onChange={(color: string) => {
                  this.setState({ color });
                }}
              />

              <div className="value" style={{ borderBottom: `30px solid ${this.state.color}`, margin: 10 }}>
                Current team color
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
    );
  }
}

export { showPickerDialog, hidePickerDialog };
