import React, { useState } from "react";
import { WithStyles, FormControl } from "@material-ui/core";
import { FixedMobileStepper } from "./MobileStepper";
import authApi from "../../../api/authentication";
import Messages from "../../../consts/Messages";
import { showSnackbar } from "../../SharedComponents/Notifier";
import { TextInput } from "../../SharedComponents/TextInput";
import { filter } from "../../../utils/profanityFilter";

interface Props extends WithStyles {
  onNext: (username: string) => void;
  onPrev: () => void;
  defaults: string;
}

export const Step2 = (props: Props) => {
  const [username, setUsername] = useState(props.defaults);
  const [err, setErr] = useState("");

  return (
    <>
      <div className={props.classes.stepContainer}>
        <p>Lets choose a proper username.</p>
        <FormControl margin="none" color="primary" required fullWidth>
          <TextInput
            id="username"
            name="username"
            label={"Username"}
            value={username}
            error={err.length > 0}
            helperText={err}
            onChange={(value: string, name: string) => setUsername(value)}
          />
        </FormControl>
      </div>
      <FixedMobileStepper
        maxSteps={4}
        step={1}
        onNext={() => {
          if (username.trim().length < 5 || username.trim().includes(" ") || filter.isProfane(username)) {
            setErr(Messages.error.usernameShortLength);
            return;
          }
          authApi
            .existance(null, username.trim())
            .then((res: any) => {
              setErr(Messages.error.usernameExist);
            })
            .catch((err: any) => {
              if (err.response.status === 404) {
                props.onNext(username.trim());
              } else {
                showSnackbar(err.response.data.error);
              }
            });
        }}
        onPrev={props.onPrev}
      />
    </>
  );
};
