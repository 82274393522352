import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Progress from "@material-ui/core/LinearProgress";

import { IndexedGames, Game } from "types/game";
import { Player } from "types/player";
import { User } from "types/user";
import { EmailTypes } from "types/general";
import { mailToString } from "utils/share";
import { exportLeaderboardToBase64 } from "utils/GameReportGenerator/pdf";
import { downloadStartSheetPDF } from "components/Game/GameStartSheet";
import DialogToolbar from "components/SharedComponents/DialogToolbar";
import { useActions } from "hooks/useActions";
import { useSelector } from "hooks/useSelector";

const LABELS = {
  [EmailTypes.START_SHEET]: "Current Start Sheet",
  [EmailTypes.FINAL_RESULT]: "Final Result",
  [EmailTypes.EMPTY]: "General blank email",
};

interface EmailOptionsProps {
  game: Game;
  options: EmailTypes[];
  open: boolean;
  onClose: () => void;
  user: User;
  gamesObj?: IndexedGames;
}
const ALL_VALUE = "All";

const EmailOptions: React.FC<EmailOptionsProps> = props => {
  const { onClose, open, options, game, gamesObj, user } = props;
  const players = Object.values(game.players).map((p: Player) => ({
    email: p.email,
    name: p.name,
    lastName: p.lastName,
  }));
  const [value, setValue] = useState<EmailTypes>(options[0]);
  const [state, setState] = useState<string[]>([ALL_VALUE]);
  const classes = useStyles();
  const { sendEmailToPlayers } = useActions();
  const isLoading = useSelector(state => state.general.loading);
  const userState = useSelector(state => state.auth.user);
  const isOrganiser = game.organizers.find(o => o._id === userState._id) !== undefined;

  const handleSubmit = () => {
    if (!state.length) return;
    const orgEmail = game.organizers.find(org => org._id === user._id)?.email;
    if (!orgEmail) return;
    const selectedPlayers = state.includes(ALL_VALUE) ? players : players.filter(p => state.includes(p.email));

    // 1) Start Sheet
    if (value === EmailTypes.START_SHEET && gamesObj) {
      downloadStartSheetPDF(game._id, gamesObj, isOrganiser, userState, true).then((file: string) => {
        sendEmailToPlayers(file, selectedPlayers, game.code, game.name, value, orgEmail, onClose);
      });
      return;
    }
    // 2) Final Result (currently it works only when leaderboard table is in the DOM!)
    // As we don't need this feature at the moment
    // TODO: Implement export-leaderboard-to-pdf without depending on the DOM!
    if (value === EmailTypes.FINAL_RESULT) {
      const table = document.querySelector("#leaderboard");
      if (!table) return;
      const file = exportLeaderboardToBase64("leaderboard", game);
      sendEmailToPlayers(file, selectedPlayers, game.code, game.name, value, orgEmail, onClose);
      return;
    }
    // 3) Final Result
    if (value === EmailTypes.EMPTY) {
      mailToString(
        [orgEmail],
        game.name,
        "",
        [],
        selectedPlayers.map(p => p.email),
      );
    }
  };

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (!evt.target.name) return;

    if (evt.target.checked) setState([...state, evt.target.name]);
    else setState(state.filter(g => g !== evt.target.name));
  };

  return (
    <Dialog onClose={onClose} open={open} maxWidth="md">
      <DialogToolbar title="Select Email Type & Players" onClick={onClose} />
      <Progress style={{ opacity: isLoading ? 1 : 0 }} />

      <div className={classes.container}>
        <RadioGroup
          value={value}
          onChange={evt => {
            setValue(evt.target.value as EmailTypes);
          }}
          className={classes.group1}
        >
          {options.map(opt => (
            <FormControlLabel key={opt} value={opt} control={<Radio color="primary" />} label={LABELS[opt]} />
          ))}
        </RadioGroup>

        <DialogTitle>Players: </DialogTitle>

        <FormGroup className={classes.group}>
          <FormControlLabel
            className={classes.checkbox}
            control={<Checkbox checked={state.includes(ALL_VALUE)} onChange={handleChange} name={ALL_VALUE} />}
            label={ALL_VALUE}
          />
          {players.map(p => (
            <FormControlLabel
              key={p.email}
              disabled={state.includes(ALL_VALUE)}
              className={classes.checkbox}
              control={<Checkbox checked={state.includes(p.email)} onChange={handleChange} name={p.email} />}
              label={`${p.name} ${p.lastName}`}
            />
          ))}
        </FormGroup>

        <Button color="primary" variant="contained" style={{ float: "right" }} onClick={handleSubmit}>
          Submit
        </Button>
      </div>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: "0 2rem 1rem",

      [theme.breakpoints.down("xs")]: {
        padding: "0 1rem 1rem",
      },
    },
    formControl: {
      margin: theme.spacing(3),
    },
    group1: {
      flexDirection: "row",
      justifyContent: "center",
      marginTop: 15,

      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        justifyContent: "flex-start",
      },
    },
    group: {
      flexDirection: "row",
      marginBottom: "30px",
    },
    checkbox: {
      flexBasis: "30%",

      [theme.breakpoints.down("xs")]: {
        flexBasis: "48%",
        marginRight: 5,
        marginBottom: 10,
      },
    },
  }),
);

export default EmailOptions;
