import {
  Avatar,
  Button,
  createStyles,
  Divider,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  TextField,
  Theme,
  WithStyles,
} from "@material-ui/core";
import { Check } from "@material-ui/icons";
import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { GlobalState } from "../../types/globalState";
import GameActions from "../../actions/games";
import { RouterProps, Views } from "../Dashboard";
import { showSnackbar } from "../SharedComponents/Notifier";
import BorderedBox from "../SharedComponents/BorderedBox";
import { switchComponent } from "../../actions/componentRouter";
import { TextInput } from "../SharedComponents/TextInput";
import { filter } from "../../utils/profanityFilter";
import { AxiosError } from "axios";
import { UserBrifObj } from "../../types/user";
import { ErrorOnlyCallback } from "../../types/actions";

interface Props extends WithStyles {
  onSubmit?(): void;
  insertGroup(id: string, name: string, playersId: string[], callback?: ErrorOnlyCallback): void;
  getFreePlayers(id: string, callback?: (err: AxiosError | null, players: UserBrifObj[]) => void): void;
  switchComponent(view: any, props?: any): void;

  organiser?: boolean;
  loading: boolean;
  routerProps: RouterProps;
}
interface State {
  [index: string]: any;
  gameId?: string;
  name: string;
  players: any;
  searchText: string;
  err_name: boolean;
  err_msg_name: string;
}

class Dashboard extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      name: "",
      players: [],
      searchText: "",
      err_players: false,
      err_name: false,
      err_msg_name: "",
    };
    this.props.getFreePlayers(props.routerProps.gameId, (err, players) => {
      if (!err) {
        this.setState({ players });
      } else {
        showSnackbar(err.toString());
      }
    });
  }
  _onTextFieldChanged = (field: string) => (event: any) => {
    const value = event.target.value as string;
    this.setState({ [field]: value });
  };
  _handleTextFieldChange = (value: string, name: string) => {
    this.setState({ [name]: value });
  };
  _onSelectDeselectPlayer = (player: any) => {
    let players = [...this.state.players];
    for (let i = 0; i < players.length; i++) {
      if (players[i]._id === player._id) {
        players[i].selected = players[i].selected ? !players[i].selected : true;
      }
    }
    this.setState({ players });
  };
  _formValidation = () => {
    const { name } = this.state;
    const err_name = name.length < 3 || filter.isProfane(name);
    let err_msg_name = "";
    if (err_name) {
      err_msg_name = "Minimum length for name is 3";
    }

    this.setState({ err_name, err_msg_name });
    return !err_name;
  };
  _submitForm = () => {
    const { gameId } = this.props.routerProps;
    const { onSubmit } = this.props;
    const { name, players } = this.state;
    const selectedPlayers = players.filter((p: any) => p.selected);
    if (this._formValidation()) {
      this.props.insertGroup(
        gameId,
        name.trim(),
        selectedPlayers.map((p: any) => p._id),
        err => {
          if (typeof onSubmit === "function") {
            onSubmit();
          } else if (selectedPlayers.length > 0) {
            this.props.switchComponent(Views.GameManager, { gameId, group: name });
          } else this.props.switchComponent(Views.GroupSelector, { gameId });
        },
      );
    } else {
      showSnackbar("Form validation error, please check items");
    }
  };

  sortHandler = (name: string) => (a: any, b: any) => {
    var result = a[name] < b[name] ? -1 : a[name] > b[name] ? 1 : 0;
    return result;
  };

  renderPlayersList = (players: any, searchText: string) => {
    if (players.length === 0) {
      return <strong>There is no free player</strong>;
    }
    return (
      <List>
        {players
          .filter((p: any) =>
            `${p.name.trim()} ${p.lastName.trim() || ""}`.toLowerCase().includes(searchText.toLowerCase()),
          )
          .sort(this.sortHandler("name"))
          .map((p: any) => (
            <ListItem button onClick={() => this._onSelectDeselectPlayer(p)}>
              <ListItemAvatar>
                {p.selected ? (
                  <Avatar style={{ backgroundColor: "#3ccc3f" }}>
                    <Check />
                  </Avatar>
                ) : (
                  <Avatar>
                    <p />
                  </Avatar>
                )}
              </ListItemAvatar>
              <ListItemText primary={`${p.name} ${p.lastName || ""}`} secondary={p.username} />
            </ListItem>
          ))}
      </List>
    );
  };
  render() {
    const { classes, loading } = this.props;
    const { name, players, searchText, err_players, err_name, err_msg_name } = this.state;
    return (
      <Paper className={classes.container}>
        {loading && <LinearProgress />}
        <h2 style={{ margin: 8 }}>Create your group</h2>
        <TextInput
          value={name}
          fullWidth
          style={{ padding: 16 }}
          variant={"outlined"}
          placeholder={"Group name"}
          error={err_name}
          helperText={err_msg_name}
          captalize
          name={"name"}
          onChange={this._handleTextFieldChange}
        />
        <div className={classes.firstActionButton}>
          <Button onClick={this._submitForm} color={"primary"} variant={"contained"}>
            Submit
          </Button>
        </div>
        <BorderedBox title={"Players"} variant={"h6"} error={err_players}>
          <TextField
            value={searchText}
            fullWidth={true}
            style={{ marginBottom: 24 }}
            variant={"outlined"}
            placeholder={"Search players by name"}
            onChange={this._onTextFieldChanged("searchText")}
          />
          <Divider />
          {this.renderPlayersList(players, searchText)}
        </BorderedBox>
        <Button className={classes.actionButton} onClick={this._submitForm} color={"primary"} variant={"contained"}>
          Submit
        </Button>
      </Paper>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      padding: 12,
    },
    actionButton: {
      marginTop: 16,
    },
    firstActionButton: {
      textAlign: "right",
      marginRight: "15px",
    },
  });
const mapStateToProps = (state: GlobalState) => {
  const { loading } = state.general;
  return {
    loading,
  };
};

export default connect(mapStateToProps, {
  getFreePlayers: GameActions.getFreePlayers,
  insertGroup: GameActions.insertGroup,
  switchComponent,
})(withStyles(styles)(Dashboard));
