import React from "react";
import { TableRow, TableCell, Button, IconButton } from "@material-ui/core";
import { GolfCourse } from "@material-ui/icons";
import EmptyPositionRow from "./EmptyPositionRow";
import { PositionsInfo } from "./IndividualLeaderBoard";
import { Player, PayStatus } from "types/player";
import { ExtendedTeam } from "types/Team";
import PlayerName from "components/SharedComponents/PlayerName";
interface Props {
  team: ExtendedTeam;
  info: PositionsInfo;
  isStroke: boolean;
  payBeforePlay: boolean;
  openScoreCardDialog(player: Player, teammates: Player[]): void;
  openTeamDetailsDialog: (team: ExtendedTeam) => void;
  trRefs: React.RefObject<HTMLTableRowElement[]>;
  isOrganizer: boolean;
  widescreen: boolean;
  index: number;
}

export default function LeaderboardTeamRow(props: Props) {
  const {
    team,
    info,
    isStroke,
    openScoreCardDialog,
    openTeamDetailsDialog,
    payBeforePlay,
    isOrganizer,
    widescreen,
  } = props;

  const { position, showInTwoSteps, showInfo } = info;
  if ((!showInfo && !isOrganizer) || (!showInfo && widescreen))
    return <EmptyPositionRow trRefs={props.trRefs} index={props.index} cells={isStroke ? 5 : 6} position={position} />;
  // console.log(team);

  return (
    <TableRow
      key={team._id}
      style={{
        ...(!showInfo && isOrganizer && !widescreen ? { opacity: 0.5 } : {}),
      }}
      ref={el => props.trRefs.current && el && (props.trRefs.current[props.index] = el)}
    >
      <TableCell>{position}</TableCell>
      {showInTwoSteps ? (
        <>
          <TableCell />
          <TableCell />
        </>
      ) : (
        <>
          <TableCell>
            <IconButton onClick={openTeamDetailsDialog.bind(null, team)}>
              <GolfCourse color={"primary"} />
            </IconButton>
          </TableCell>
          <TableCell>
            {team.players.map((p: Player) => (
              <Button
                key={p._id}
                style={{ textTransform: "inherit" }}
                className="disabled-red"
                variant="text"
                color="primary"
                onClick={() => {
                  const teammates = team.players.filter((p2: Player) => p2._id !== p._id);
                  openScoreCardDialog(p, teammates);
                }}
                disabled={payBeforePlay && p.payStatus === PayStatus.NOT_PAID}
              >
                <PlayerName isGuest={p.guest} name={p.name} lastName={p.lastName} onlyLastName />
              </Button>
            ))}
          </TableCell>
        </>
      )}
      <TableCell>{team.points.totalGross}</TableCell>
      <TableCell>{team.points.totalNett}</TableCell>
      {isStroke ? null : <TableCell>{team.points.totalPoint}</TableCell>}
      <TableCell>
        <>
          {team.thru}
          <sup>{team.players[0]?.group?.startingHole || 1}</sup>
        </>
      </TableCell>
    </TableRow>
  );
}
