import React, { Component } from "react";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import UserActions from "../../../actions/user";
import { GlobalState } from "../../../types/globalState";
import MyTable, { Column, TableAction, TableState } from "../../SharedComponents/MyTable";
import ClubActions from "../../../actions/clubs";
import { AxiosError } from "axios";
import { ErrorOnlyCallback } from "../../../types/actions";
import { User, UserBrifObj, Users } from "../../../types/user";

interface Props {
  getClubPlayers(
    page: number,
    limit: number,
    filter?: any,
    sort?: string,
    dir?: string,
    query?: string,
    callback?: any,
  ): void;
  addOrganiser(userId: string, callback?: ErrorOnlyCallback): void;
  removeOrganiser(userId: string, callback?: ErrorOnlyCallback): void;
  user: User;
  users: UserBrifObj[];
  count: number;
  loading: boolean;
}

interface State {
  searchText: string;
}

class ClubPlayersList extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    props.getClubPlayers(1, 50, null, "name");
  }

  _onTableChange = (actions: TableAction, state: TableState) => {
    this.props.getClubPlayers(state.page + 1, state.pageSize, undefined, state.sort.field, state.sort.dir, state.query);
  };

  render() {
    const { loading, users, count } = this.props;
    const columns: Column[] = [
      {
        field: "name",
        title: "Name",
      },
      {
        field: "lastName",
        title: "Last Name",
      },
      {
        field: "username",
        title: "User Name",
      },
      {
        field: "id",
        title: "Action",
        handler: (id: any, item?: any) => {
          if (item.organisingClub && item.organisingClub.includes(this.props.user.club.id)) {
            return (
              <Button
                color={"secondary"}
                variant={"contained"}
                onClick={() =>
                  this.props.removeOrganiser(id, err => !err && this.props.getClubPlayers(1, 50, null, "name"))
                }
              >
                Remove organiser
              </Button>
            );
          }

          return (
            <Button
              color={"primary"}
              variant={"contained"}
              onClick={() => this.props.addOrganiser(id, err => !err && this.props.getClubPlayers(1, 50, null, "name"))}
            >
              Turn to club organiser
            </Button>
          );
        },
      },
    ];
    return (
      <>
        <MyTable
          data={users}
          count={count}
          loading={loading}
          title={"Club's Players"}
          defaultOrderBy={"name"}
          searchEnabled
          onTableChange={this._onTableChange}
          columns={columns}
        />
      </>
    );
  }
}

const mapStateToProps = (state: GlobalState) => {
  const { clubPlayers } = state.users;
  const { loading } = state.general;
  const { user } = state.auth;
  return {
    user,
    users: clubPlayers.items ? Object.values(clubPlayers.items) : [],
    loading,
    count: clubPlayers.count,
  };
};
export default connect(mapStateToProps, {
  getClubPlayers: UserActions.getClubPlayers,
  addOrganiser: ClubActions.addOrganiser,
  removeOrganiser: ClubActions.removeOrganiser,
})(ClubPlayersList);
