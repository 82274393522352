import ReduxTypes from "../consts/ReduxTypes";
import teamsApi from "../api/teams";
import ErrorHandler from "../api/Errorhandler";

export default new (class GameActions {
  getByGameId = (gameId: string, callback: any) => {
    return (dispatch: any) => {
      teamsApi
        .getByGameId(gameId)
        .then((res: any) => {
          dispatch({ type: ReduxTypes.GET_TEAMS, payload: res.data, id: gameId });
          if (callback) callback(null, res.data);
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
          if (callback) callback(err, null);
        });
    };
  };

  insertTeam = (name: string, gameId: string, color?: string, callback?: any) => {
    return (dispatch: any) => {
      teamsApi
        .insertTeam(name, gameId, color)
        .then((res: any) => {
          dispatch({ type: ReduxTypes.ADD_NEW_TEAM, payload: { _id: res.data._id, name }, id: gameId });
          if (callback) callback(null, res.data);
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
          if (callback) callback(err, null);
        });
    };
  };

  update = (id: string, gameId: string, name: string, color: string) => {
    return (dispatch: any) => {
      teamsApi
        .update(id, { color, name })
        .then((res: any) => {
          dispatch({
            type: ReduxTypes.UPDATE_GAME_TEAM,
            payload: { teamId: id, name, color, gameId },
          });
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
        });
    };
  };
})();

export const removeGameTeams = (id: string, teams: string[], cb?: () => void) => {
  return (dispatch: any) => {
    teamsApi
      .removeGameTeams(id, teams)
      .then(() => {
        dispatch({ type: ReduxTypes.REMOVE_GAME_TEAMS, id, teams });
        if (cb) cb();
      })
      .catch((err: any) => {
        ErrorHandler(err, dispatch);
      });
  };
};
