import React from "react";
import { ScoreCard, TeeColors } from "../../types/scoreCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { CheckCircle } from "@material-ui/icons";
import { Card, CardContent, CardActions, Typography, makeStyles, Theme } from "@material-ui/core";
import ButtonBase from "@material-ui/core/ButtonBase";

interface Props {
  cards: ScoreCard[];
  gameCards: ScoreCard[];
  onScoreCardSelected: (item: ScoreCard) => void;
}

const getTextColor = (color: TeeColors) => {
  switch (color) {
    case "red":
    case "black":
    case "purple":
    case "blue":
    case "green":
      return "white";
    default:
      return "black";
  }
};

const getParsTotal = (scoreCard: ScoreCard) => {
  return scoreCard.holesParams.reduce((total, param) => total + param.par, 0);
};

export default function ScoreCardsList(props: Props) {
  const classes = useStyles();
  const { cards } = props;
  const width = window.innerWidth < 500 ? window.innerWidth - 60 : 439;

  if (cards.length === 0) return <p>No score cards available</p>;

  return (
    <div style={{ width }}>
      <Swiper virtual spaceBetween={0} slidesPerView={width < 400 ? 2 : 2.5} pagination navigation>
        {cards
          .sort((a: ScoreCard, b: ScoreCard) => {
            if (a.gender === b.gender) {
              return getParsTotal(a) > getParsTotal(b) ? -1 : getParsTotal(a) < getParsTotal(b) ? 1 : 0;
            } else {
              return a.gender < b.gender ? -1 : 1;
            }
          })
          .map((item: ScoreCard) => (
            <SwiperSlide key={item._id}>
              <Card
                className={classes.scoreCardItem}
                style={{ background: item.teeColor, color: getTextColor(item.teeColor) }}
              >
                <CardContent>
                  <Typography variant="subtitle2">{item.name}</Typography>
                  <Typography variant="caption">
                    {item.gender === "men" ? "Men" : "Ladies"}/{item.teeColor}/{getParsTotal(item)}
                  </Typography>
                </CardContent>

                <CardActions className={classes.cardActions}>
                  {props.gameCards.some((x: any) => x._id === item._id) ? (
                    <>
                      <span style={{ marginRight: 3 }}>Deselect</span>
                      <CheckCircle style={{ color: getTextColor(item.teeColor) }} />
                    </>
                  ) : (
                    <span>Pick Me</span>
                  )}
                </CardActions>
                <ButtonBase
                  className={classes.cardBtn}
                  onClick={() => {
                    props.onScoreCardSelected(item);
                  }}
                  disabled={
                    !!props.gameCards.find(
                      card => card.gender === item.gender && card.teeColor === item.teeColor && card._id !== item._id,
                    )
                  }
                />
              </Card>
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  scoreCardItem: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    width: 150,
    height: 185,
    margin: 4,
    padding: 4,

    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  cardBtn: {
    width: "100%",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  cardActions: {
    margin: "auto 0 10%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
