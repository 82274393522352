import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { GlobalState } from "../../types/globalState";
import { createStyles, Input, TableCell, TableRow, TextField, Theme, WithStyles } from "@material-ui/core";
import { HoleParam } from "../../types/hole";

interface Props extends WithStyles {
  onChange(holeParam: HoleParam, index: number): void;

  holeParam: HoleParam;
  index: number;
}

interface State extends HoleParam {}

class HoleParamTableRowInput extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      number: props.holeParam.number,
      yards: props.holeParam.yards,
      par: props.holeParam.par,
      si: props.holeParam.si,
    };
  }

  _onYardsChange = (event: any) => {
    const value = parseInt(event.target.value);
    this.setState({ yards: value });
    this.props.onChange({ ...this.state, yards: value }, this.props.index);
  };
  _onParChange = (event: any) => {
    const value = parseInt(event.target.value);
    this.setState({ par: value });
    this.props.onChange({ ...this.state, par: value }, this.props.index);
  };
  _onStrokeIndexChange = (event: any) => {
    const value = parseInt(event.target.value);
    this.setState({ si: value });
    this.props.onChange({ ...this.state, si: value }, this.props.index);
  };

  render() {
    const { classes } = this.props;
    const { number, yards, par, si } = this.state;
    return (
      <TableRow key={number}>
        <TableCell>{number}</TableCell>
        <TableCell>
          <TextField
            inputProps={{ min: 1 }}
            value={yards}
            onChange={this._onYardsChange}
            type={"number"}
            className={classes.mediumInput}
          />
        </TableCell>
        <TableCell>
          <Input
            inputProps={{ min: 1 }}
            value={par}
            onChange={this._onParChange}
            type={"number"}
            className={classes.smallInput}
          />
        </TableCell>
        <TableCell>
          <Input
            inputProps={{ min: 1 }}
            value={si}
            onChange={this._onStrokeIndexChange}
            type={"number"}
            className={classes.smallInput}
          />
        </TableCell>
      </TableRow>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    smallInput: {
      width: 32,
    },
    mediumInput: {
      width: 64,
    },
  });

const mapStateToProps = (state: GlobalState) => {
  return {};
};

export default connect(mapStateToProps, {})(withStyles(styles)(HoleParamTableRowInput));
