import React, { useState } from "react";
import { WithStyles, FormControl, Button } from "@material-ui/core";
import validator from "validator";
import { FixedMobileStepper } from "./MobileStepper";
import authApi from "../../../api/authentication";
import Messages from "../../../consts/Messages";
import { showSnackbar } from "../../SharedComponents/Notifier";
import { TextInput } from "../../SharedComponents/TextInput";
import { Link } from "react-router-dom";

interface Props extends WithStyles {
  onNext: (email: string) => void;
  defaults: string;
}

export const Step1 = (props: Props) => {
  const [email, setEmail] = useState(props.defaults);
  const [err, setErr] = useState("");
  const [emailExists, setEmailExist] = useState(false);

  return (
    <>
      <div className={props.classes.stepContainer}>
        <p>Lets start with your email address</p>
        <FormControl margin="none" color="primary" required fullWidth>
          <TextInput
            id="email"
            name="email"
            label={"Email"}
            autoComplete="email"
            className={props.classes.root}
            value={email}
            error={err.length > 0}
            helperText={err}
            disableProfanityCheck
            onChange={(value: string, name: string) => setEmail(value)}
          />
        </FormControl>
        {emailExists && <Link to={`/forgotPassword/${email}`}>
          <span  >
            Forgot password?
                </span>
        </Link>}
      </div>
      <FixedMobileStepper
        maxSteps={4}
        step={0}
        onNext={() => {
          setEmailExist(false);
          if (!validator.isEmail(email)) {
            setErr("Email is not valid");
            return;
          }
          authApi
            .existance(email.trim(), null)
            .then((res: any) => {
              setErr(Messages.error.emailExist);
              setEmailExist(true);
            })
            .catch((err: any) => {
              if (!err) showSnackbar("Connection Error.");
              if (err.response.status === 404) {
                props.onNext(email.trim());
              } else {
                showSnackbar(err.response.data.error);
              }
            });
        }}
        onPrev={() => true}
      />
    </>
  );
};
