import React, { useState } from "react";
import { connect } from "react-redux";
import Moment from "moment";
import {
  createStyles,
  Divider,
  TextField,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Theme,
  WithStyles,
  withStyles,
  Switch,
  Button,
} from "@material-ui/core";
import { GlobalState } from "../../types/globalState";
import { Game, GState } from "../../types/game";
import gameActions from "../../actions/games";
import Loading from "../Loading";
import ChainIcon from "./ChainIcon";
import { AxiosError } from "axios";
import { hideAuxiliaryDialog } from "../SharedComponents/AuxiliaryDialog";

interface Props extends WithStyles {
  linkGames(gameIds: string[], callback?: (err: AxiosError | null) => void): void;
  getJoinedGames(callback?: (err: AxiosError | null, data: Game[]) => void): void;
  getCurrentOrganisedGames(callback?: (err: AxiosError | null, data: Game[]) => void): void;
  game: Game;
  games: Game[];
}

const LinkedGamesList = (props: Props) => {
  const [searchText, setSearchText] = useState<string>("");
  const [selectedGames, setSelectedGames] = useState<string[]>(props.game.linkedGames || []);
  const games = props.games.filter(g => g.name.includes(searchText) || g.code.includes(searchText));

  const selectGame = (checked: boolean, game: Game) => {
    if (checked) {
      let _selectedGames = [...selectedGames];
      _selectedGames.push(game._id);
      setSelectedGames(_selectedGames);
    } else setSelectedGames(selectedGames.filter(s => s !== game._id));
  };

  let editable = true;
  Object.values(props.game.players).forEach(player => {
    if ((player.uholes && player.uholes[1]) || (player.holes && player.holes[1])) editable = false;
  });

  return (
    <div style={{ minWidth: 400 }}>
      <Loading style={{ position: "sticky", top: 50 }} />
      <div className={props.classes.header}>
        <TextField
          fullWidth
          variant={"outlined"}
          label={"Filter by Name or Game Code"}
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
        />
      </div>
      <List>
        {games.map((game, index) => (
          <div style={{ width: "100%", position: "relative" }}>
            <ListItem>
              <ListItemText primary={game.name} secondary={game.code} />
              <ListItemText secondary={`${Moment(game.date).format("ddd DD/MM/YY hh.mm")}`} />
              <ListItemSecondaryAction>
                <Switch
                  checked={!!selectedGames.find(gameId => gameId === game._id)}
                  onChange={e => selectGame(e.target.checked, game)}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
            <ChainIcon currentGame={game} nextGame={games[index + 1]} />
          </div>
        ))}
        {games.length === 0 && (
          <ListItem>
            <ListItemText secondary="There is no linkable game" />
          </ListItem>
        )}
      </List>
      <div className={props.classes.footer}>
        <Button color="secondary" variant="contained" className={props.classes.button} onClick={hideAuxiliaryDialog}>
          Cancel
        </Button>
        {editable && (
          <Button
            color="primary"
            variant="contained"
            className={props.classes.button}
            onClick={() => {
              props.linkGames(selectedGames, err => !err && props.getCurrentOrganisedGames());
              hideAuxiliaryDialog();
            }}
          >
            Save
          </Button>
        )}
      </div>
    </div>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    footer: {
      position: "sticky",
      bottom: 0,
      borderRadius: 0,
      display: "flex",
    },
    button: {
      flex: 1,
      borderRadius: 0,
    },
    header: {
      padding: "0 8px",
      margin: 4,
      zIndex: 100,
    },
  });

const sortGame = (games: Game[], game: Game) => {
  return games.sort((a, b) => (a.linkedGames.find(id => id === game._id) ? -1 : 1));
};

const filterGames = (games: Game[], game: Game) => {
  const gameDate = Moment(game.date);
  return games.filter(g => {
    const gDate = Moment(g.date);
    if (g.linkedGames.length > 0 && !g.linkedGames.find(id => id === game._id)) return false;
    return g.course._id == game.course._id && gameDate.isSame(gDate, "date") && g.state !== GState.Done;
  });
};

const mapStateToProps = (state: GlobalState, props: any) => {
  const { organisedByMe, joinedGames } = state.games;
  return {
    games: sortGame(filterGames(Object.values({ ...organisedByMe, ...joinedGames }), props.game), props.game),
  };
};

export default connect(mapStateToProps, {
  linkGames: gameActions.linkGames,
  getJoinedGames: gameActions.getJoinedGames,
  getCurrentOrganisedGames: gameActions.getCurrentOrganisedGames,
})(withStyles(styles)(LinkedGamesList));
