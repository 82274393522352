export enum ADVERT_VALUES {
  HEIGHT = 120,
  SIDEBAR_WIDTH = "33vw",
}

const YOUTUBE_BASE_URL = "https://www.youtube.com/embed/";

export const HELP_VIDEOS = {
  MY_GAME_RULES: `${YOUTUBE_BASE_URL}18VLbVv50QY`,
  EXPIRY_RULES: `${YOUTUBE_BASE_URL}QO7MXrp8LSg`,
  HANDICAP_RULES: `${YOUTUBE_BASE_URL}Yak-VuciO3g`,
  PLAYING_RULES: `${YOUTUBE_BASE_URL}6cjNO3s1UKg`,
  GAME_RULES_1: `${YOUTUBE_BASE_URL}ka8Qh5vJO2c`,
  CREATE_GAME: `${YOUTUBE_BASE_URL}i_JXH_vaRTQ`,
  GAME_MENU_QUICK: `${YOUTUBE_BASE_URL}2MWKQ496LFo`,
  GAME_MENU_PAYMENT: `${YOUTUBE_BASE_URL}slNQjsKCvcc`,
  GAME_MENU_PAYMENT_2: `${YOUTUBE_BASE_URL}dWfOZ4-CuNU`,
  GAME_MENU_PRESENTATION: `${YOUTUBE_BASE_URL}LVcvIfFSXj8`,
  GAME_MENU_PLAYERS: `${YOUTUBE_BASE_URL}M4Ns5uuzZiI`,
  GAME_MENU_ORGANISERS: `${YOUTUBE_BASE_URL}EMUWNQZNVaQ`,
  GAME_MENU_SHARE: `${YOUTUBE_BASE_URL}WIRNN-d1xGE`,
  GAME_MENU_LEADERBOARD: `${YOUTUBE_BASE_URL}fmJpH3h1nzM`,
  GAME_MENU_CARD: `${YOUTUBE_BASE_URL}DllZs5g0o8c`,
  GAME_MENU_EMAIL: `${YOUTUBE_BASE_URL}8s1RF7yAd1Y`,
  GAME_MENU_QR: `${YOUTUBE_BASE_URL}hL2-4iYWhF4`,
  PLAYERS_GROUPS: `${YOUTUBE_BASE_URL}pRhHJ3Jit4A`,
  GAME_SETUP_SCORE_CARD: `${YOUTUBE_BASE_URL}AP663gy9b4I`,
  GAME_SETUP_QS: `${YOUTUBE_BASE_URL}mP4kRcFjjf4`,
  GAME_SETUP_LD: `${YOUTUBE_BASE_URL}seImz4B6XGs`,
  GAME_SETUP_TM: `${YOUTUBE_BASE_URL}DlOAoS5X_FU`,
  LEADERBOARD_PRESENTATION: `${YOUTUBE_BASE_URL}YMNYsx3zdpA`,
  LEADERBOARD_SCROLL: `${YOUTUBE_BASE_URL}wnIfRZXsrEg`,
  VIDEO_HELP: `${YOUTUBE_BASE_URL}36zln4sggpE`,
  WELCOME_ORGANISER: `${YOUTUBE_BASE_URL}-6ojN0gn4bI`,
  ORGANISER_MENU: `${YOUTUBE_BASE_URL}3PlFBT7s_Do`,
  MY_TOURNAMENTS: `${YOUTUBE_BASE_URL}vCAHtViLNFs`,
  MY_GAMES: `${YOUTUBE_BASE_URL}FU9K57vIteo`,
  MY_POTS: `${YOUTUBE_BASE_URL}iXH0IcWlEFc`,
  MY_SCORE_CARDS: `${YOUTUBE_BASE_URL}1TgOXUmi65k`,
  PROFILE: `${YOUTUBE_BASE_URL}KukIQZhH_3Y`,
  ADD_NEW_PLAYER: `${YOUTUBE_BASE_URL}QNyQZIskSYI`,
  GAME_SETUP_PAYMENT: `${YOUTUBE_BASE_URL}pjM60Jzf6gI`,
  GAME_SETUP_TRANSACTION: `${YOUTUBE_BASE_URL}ZWGnUAvQ3AY`,
};
