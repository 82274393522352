import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  root: {
    minWidth: "35vw",
    padding: "2rem 2rem 5rem 2rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",

    "& > *:nth-child(2)": {
      marginTop: "2rem",
    },

    "& > button": {
      position: "absolute",
      bottom: 10,
    },
  },
});
