import React from "react";
import { WithStyles } from "@material-ui/core";
import { FixedMobileStepper } from "./MobileStepper";

interface Props extends WithStyles {
  onNext: () => void;
  onPrev: () => void;
  defaults: string;
}

export const Step4 = (props: Props) => {

  return (
    <>
      <div className={props.classes.stepContainer}>
        <p>Your password has been changed successfully</p>
      </div>
      <FixedMobileStepper
        maxSteps={4}
        step={3}
        onNext={()=>{}}
        onPrev={props.onPrev}
      />
    </>
  );
};
