import React from "react";
import { TableRow, TableCell } from "@material-ui/core";
import _ from "lodash";

interface Props {
  cells: number;
  position: string | number;
  trRefs: React.RefObject<HTMLTableRowElement[]>;
  index: number;
}

const renderEmptyCells = (cellsCount: number) => {
  return _.times(cellsCount, i => {
    return <TableCell />;
  });
};

export default function EmptyPositionRow(props: Props) {
  const { cells, position } = props;
  return (
    <TableRow ref={el => props.trRefs.current && el && (props.trRefs.current[props.index] = el)}>
      <TableCell>{position}</TableCell>
      {renderEmptyCells(cells)}
    </TableRow>
  );
}
