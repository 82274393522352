import { Box, Button, CircularProgress, TextField, Typography } from "@material-ui/core";
import * as React from "react";
import { PaymentSessionRes } from "types/payment";
import { showAuxiliaryDialog } from "components/SharedComponents/AuxiliaryDialog";
import PaymentCallback from "./PaymentCallback";

interface PaymentModalProps extends PaymentSessionRes {}

const PaymentModal = (props: PaymentModalProps) => {
  const [errors, setErrors] = React.useState<string[]>([]);
  const [description, setDescription] = React.useState<string>("");
  const [isDisabled, setIsDisabled] = React.useState<boolean>(false);

  const handlePaymentResultRef = React.useRef<(paymentSession: any) => void>();

  handlePaymentResultRef.current = (paymentSession: any) => {
    if (paymentSession.status === "Approved" || paymentSession.status === "Captured") {
      showAuxiliaryDialog(
        <PaymentCallback
          payment={{
            id: props.id,
            paymentSession,
          }}
          description={description}
        />,
        "xl",
        true,
        false,
        true,
      );
    }
    if (paymentSession.lastError) {
      const userFacingError = Ryft.getUserFacingErrorMessage(paymentSession.lastError);
      setErrors(userFacingError as any);
    }
  };

  React.useEffect(() => {
    if (props.paymentSession.clientSecret === undefined && Ryft === undefined) return;

    const initRyft = async () => {
      try {
        if (props.paymentSession.clientSecret === undefined) return;

        if (typeof Ryft === "undefined") {
          console.error("Ryft is not loaded");
          return;
        }
        Ryft.init({
          publicKey: process.env.REACT_APP_RYFT_API_KEY || "your-public-key-here",
          clientSecret: props.paymentSession.clientSecret,
          accountId: props.paymentSession.metadata.accountId,
          paymentType: props.paymentSession.paymentType,
          customerPaymentMethods: {
            allowStorage: true,
          },
          localisation: {
            cardNumberPlaceholder: "Card No.",
            expiryMonthPlaceholder: "mm",
            expiryYearPlaceholder: "yy",
            cvvPlaceholder: "CVV",
          },
        });

        const form = document.getElementById("ryft-pay-form") as HTMLFormElement;
        form.addEventListener("submit", async (e: Event) => {
          e.preventDefault();
          const payButton = document.getElementById("ryft-pay-btn") as HTMLButtonElement;
          if (payButton) {
            setIsDisabled(true);
            payButton.disabled = true;
          }
          try {
            await Ryft.attemptPayment().then((paymentSession: any) => {
              if (handlePaymentResultRef.current) {
                handlePaymentResultRef.current(paymentSession);
              }
              // window.location.href = "/";
            });
          } catch (error) {
            console.error("Payment error:", error);
            setErrors(error as any);
          } finally {
            setIsDisabled(false);
            payButton.disabled = false;
          }
        });
        Ryft.addEventHandler("cardValidationChanged", (e: any) => {
          const payButton = document.getElementById("ryft-pay-btn") as HTMLButtonElement;
          if (payButton) {
            setIsDisabled(!e.isValid);
            payButton.disabled = !e.isValid;
          }
        });
        Ryft.addEventHandler("walletPaymentSessionResult", (e: any) => {
          if (handlePaymentResultRef.current) {
            handlePaymentResultRef.current(e.paymentSession);
          }
        });
      } catch (error) {
        console.error(error);
      }
    };

    initRyft();
  }, [props.paymentSession.clientSecret, props.paymentSession.metadata.accountId, props.paymentSession.paymentType]);

  return (
    <Box style={{ maxWidth: 500, width: "100%", minHeight: 400, padding: "16px" }}>
      <div className="Ryft--paysection">
        <form id="ryft-pay-form" className="Ryft--payform">
          <TextField
            label={"Comment"}
            name="payment-comment"
            margin={"normal"}
            value={description}
            fullWidth
            multiline
            variant="outlined"
            helperText="Add a comment for this payment"
            onChange={e => setDescription(e.target.value)}
          />
          <Button
            disabled={isDisabled}
            style={{ width: "100%" }}
            color="primary"
            variant="contained"
            type="submit"
            id="ryft-pay-btn"
          >
            PAY NOW £{props.paymentSession.amount / 100}
            {isDisabled && <CircularProgress size={24} style={{ marginLeft: 8 }} />}
          </Button>
          <Typography variant="body1" color="error" style={{ padding: "8px" }} id="ryft-pay-error">
            {errors.toString()}
          </Typography>
        </form>
      </div>
    </Box>
  );
};

export default PaymentModal;
