import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import "./DropZone.css";
import { FilePond as FilePondComponent, registerPlugin } from "react-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";

import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginImagePreview);
registerPlugin(FilePondPluginFileValidateSize);

class DropZone extends Component {

  constructor(props) {
    super(props);
    this.state = {
      files: [],
    };
  }

  _onAddFile = () => {
    const fileItems = this.pond.getFiles();

    let files = [];
    fileItems.forEach(item => {
      if (item.status !== 8)
        files.push(item.file);
    });
    this.setState({
      files,
    });

    this.props.onUpdateFiles(files[0]);
  };
  _onRemoveFile = () => {
    this.props.onUpdateFiles(null);
  };

  renderError() {
    const { classes } = this.props;
    if (this.props.error && this.state.files.length === 0) {
      return <p className={classes.error}>*Image is required</p>;
    }
    return <div/>;
  }

  render() {

    return (
      <div>
        {this.renderError()}
        <FilePondComponent
          className={'error'}
          allowMultiple={false}
          maxFileSize={"5MB"}
          acceptedFileTypes={["image/jpeg", "image/jpg", "image/png"]}
          ref={ref => (this.pond = ref)}
          files={this.state.files}
          //className={classes.error}
          maxFiles={1}
          // onupdatefiles={this._onUpdateFiles} //calls twice
          onaddfile={this._onAddFile}
          onremovefile={this._onRemoveFile}
          // name={'file'}
          // allowMultiple={false}
        />
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    margin: theme.spacing(5),
  },
  error: {
    color: "#f44336",
  },
});
export default withStyles(styles)(DropZone);
