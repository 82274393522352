import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { GlobalState } from "../../../types/globalState";
import { createStyles, LinearProgress, TableContainer, Theme, WithStyles } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { hideDialog, showDialog } from "../../SharedComponents/Dialog";
import { switchComponent } from "../../../actions/componentRouter";
import GameActions from "../../../actions/games";
import AdvertActions from "../../../actions/adverts";
import { Game, GState, IndexedGames } from "../../../types/game";
import { User } from "../../../types/user";
import { Views } from "../index";
import ModernScoreCardView from "../../ScoreCard/ModernScoreCardView";
import IndividualLeaderBoard from "../../leaderBoard/IndividualLeaderBoard";
import { showSnackbar } from "../../SharedComponents/Notifier";
import { IndexedAdverts } from "../../../types/advert";
import { showAdvert } from "../../SharedComponents/AdvertDisplayer";
import GrossPickerDialog from "../../Game/GrossPickerDialog";
import { Player } from "../../../types/player";
import Config from "../../../config";
import AdvertsThimbnails from "../../Adverts/AdvertsThumbnails";
import { ErrorOnlyCallback } from "../../../types/actions";
import { ItemSelector } from "../../SharedComponents/ItemSelector";
import ScoringTable from "./ScoringTable";
import ScoringTableToolbar from "./ScoringTableToolbar";
import { withDetector } from "utils/withDetector";

interface Props extends WithStyles {
  getAdvertByGameId(gameId: string, callback?: any): void;
  setGameAutoProgress(gameId: string, autoProgress: boolean): void;
  updateScores(
    gameId: string,
    playerId: string,
    scoreId: string,
    holeNumber: number,
    gross: number | null,
    shots?: number,
    onBehalfPlayer?: boolean,
    offline?: boolean,
    callback?: ErrorOnlyCallback,
  ): void;
  switchComponent(view: any, props?: any): void;
  finishGame(id: string, callback?: ErrorOnlyCallback): void;
  toggleScoringPlayer(gameId: string, playerId: string, action: "add" | "remove", callback?: ErrorOnlyCallback): void;

  gamesAd: IndexedAdverts;
  joinedGames: IndexedGames;
  loading: boolean;
  gameId: string;
  user: User;
  online: boolean;
}
interface State {
  holeIndex: number;
  isSubmitted: boolean;
  verifiedPlayers: String[];
  disagreePlayers: string[];
}

class HoleScoreForm extends Component<Props, State> {
  private holeDivRef: any;
  constructor(props: Props) {
    super(props);
    this.holeDivRef = null; //React.createRef();
    this.state = {
      holeIndex: 0,
      isSubmitted: false,
      verifiedPlayers: [],
      disagreePlayers: [],
    };
    this.props.getAdvertByGameId(this.props.gameId);
  }
  componentDidMount() {
    let holeIndex = this.getInitialHoleIndex();
    if (!holeIndex) holeIndex = 0;
    this.setState({ holeIndex });
  }
  getScoringPlayers = (): string[] => {
    const game = this.getGame();
    return game.players[this.props.user._id].scoringPlayers || [];
  };

  getInitialHoleIndex = () => {
    const game = this.getGame();
    if (!game) return null;
    const {} = this.props;
    const playersId = this.getScorablePlayers().map((p: any) => p._id);
    if (playersId.length === 0) return null;
    const currentPlayerId = this.props.user._id;
    const holesParams: any = this.getScoreCard()!.holesParams;

    for (let hi = 0; hi < holesParams.length; hi++) {
      const { number } = holesParams[hi];
      for (let pi = 0; pi < playersId.length; pi++) {
        const playerId = playersId[pi];
        const player = game!.players[playerId];

        if (
          !player.holes[number] ||
          !player.holes[number].gross ||
          (player.uholes && !player.uholes[number]?.grosses?.[currentPlayerId])
        ) {
          return hi;
        }
      }
    }
    return holesParams.length ? holesParams.length - 1 : 0;
  };
  getStartingHoleIndex = (): number => {
    const players: any = this.getScorablePlayers();
    for (let i = 0; i < players.length; i++) {
      if (players[i].group && players[i].group.startingHole) {
        return players[i].group.startingHole - 1;
      }
    }
    return 0;
  };
  getGame = (): Game => {
    return this.props.joinedGames[this.props.gameId];
  };
  getLinkedGames = (): Game[] => {
    const game = this.getGame();
    return game.linkedGames.map(id => this.props.joinedGames[id]);
  };
  getScoreCard = (playerId?: string) => {
    const game = this.getGame();
    const { user } = this.props;
    if (!playerId) playerId = user._id;
    if (game && game.scoreCards) {
      const getStartingHoleIndex = this.getStartingHoleIndex();
      const cardId = game.players[playerId].teeColor ? game.players[playerId].teeColor.scoreCardId : null;
      let sc: any = { ...Object.values(game.scoreCards)[0] };
      if (cardId) sc = { ...game.scoreCards.find(x => x._id === cardId) };
      if (sc.holesParams) {
        sc.holesParams = [
          ...sc.holesParams.slice(getStartingHoleIndex),
          ...sc.holesParams.slice(0, getStartingHoleIndex),
        ];
      }
      return sc;
    }
    return null;
  };

  getMyGroupPlayers = () => {
    const { user } = this.props;
    const game = this.getGame();
    return Object.values(game.players).filter(
      (p: any) => p.group && p.group.name === game.players[user._id].group.name,
    );
  };

  getScorablePlayers = () => {
    const scoringPlayers = this.getScoringPlayers();
    return this.getMyGroupPlayers().filter((p: any) => _.isEmpty(scoringPlayers) || scoringPlayers.includes(p._id));
  };

  getScorableCards = () => {
    if (this.state.holeIndex === 0) return this.getMyGroupPlayers();
    return this.getScorablePlayers();
  };

  isUnverifiable = (player: Player) => {
    const { verifiedPlayers } = this.state;
    if (verifiedPlayers.includes(player._id)) return false;
    const uholes = Object.values(player.uholes || {});
    for (let i = 0; i < uholes.length; i++) {
      if (Object.values(uholes[i].grosses).length === 1) return true;
    }
    return false;
  };
  getNumberOfHoles = () => {
    const game = this.getGame();
    if (game && game.scoreCards) return game.course.holesNumber;

    return 0;
  };

  getVerifiedHolesCount = (player: Player) => {
    const uholes = Object.values(player.uholes || {});
    let count = 0;
    for (let i = 0; i < uholes.length; i++) {
      if (Object.values(uholes[i].grosses).length > 1) count++;
    }
    return count;
  };

  readonly IS_UNVERIFIABLE = -2;

  grossFinalCheck = (player: any): number | void => {
    const holes = Object.values(player.holes);
    if (holes.length < this.getNumberOfHoles()) return;
    if (this.state.holeIndex < holes.length - 1) return;
    for (let i = 0; i < holes.length; i++) {
      const hole: any = holes[i];
      if (hole.mismatch) return hole.holeNumber;
    }
    if (this.getVerifiedHolesCount(player) === holes.length - 1) return;

    player.isUnverifiable = this.isUnverifiable(player);
    if (player.isUnverifiable) return this.IS_UNVERIFIABLE;
    return -1;
  };
  grossFinalCheckAllPlayers = () => {
    const scoringPlayers = this.getScoringPlayers();
    if (scoringPlayers.length === 0) return false;
    const players = this.getScorablePlayers();

    if (players.length === 0) return false;
    for (let i = 0; i < players.length; i++) {
      if (this.grossFinalCheck(players[i]) !== -1) {
        return false;
      }
    }
    return true;
  };

  pickerDialog = (player: Player, holeNumber: number) => {
    const { gameId } = this.props;
    return (
      <GrossPickerDialog
        gameId={gameId}
        holeNumber={holeNumber}
        onSelect={(gross: any, dnfSelected: boolean, p?: Player) =>
          this._handleGrossChange(gameId, p || player, holeNumber, gross, dnfSelected)
        }
      />
    );
  };
  openPickerDialog = (player: any, holeNumber: number) => {
    showDialog(this.pickerDialog(player, holeNumber), "sm", false, true, true);
  };

  openPickerDialogForMisMatchHole = (player: any, holeNumber: number) => {
    this.handleChangeHoleNumber(holeNumber);
    this.openPickerDialog(player, holeNumber);
  };

  handleChangeHoleNumber = (holeNumber: number) => {
    const holeIndex = holeNumber - 1;
    this.setState({ holeIndex: holeIndex });
  };

  _handleAutoProgressChange = (event: any) => {
    this.props.setGameAutoProgress(this.getGame()?._id || "", event.target.checked as boolean);
  };

  scrollToHoleDivHandler = () => {
    if (this.holeDivRef) this.holeDivRef.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  _handleGrossChange = (gameId: string, player: Player, holeNumber: number, gross: any, dnfSelected: boolean) => {
    const holes = Object.values(player.holes);
    this.props.updateScores(
      gameId,
      player._id,
      player.scoreId,
      holeNumber,
      dnfSelected ? "DNF" : gross,
      gross,
      undefined,
      !this.props.online,
      err => {
        if (!err && this.getGame()?.autoProgress) {
          this._nextHole();
        }
      },
    );

    if (!this.props.online) {
      this._nextHole();
    }

    hideDialog();
    if (holes.length === this.getNumberOfHoles() && this.getInitialHoleIndex() === holes.length)
      this._onShowScoreCard(player);
  };

  _onOpenScorerSetup = () => {
    const items = this.getLinkedGames().map(game => ({
      title: game.name,
      onClick: () => {
        this.openScorerSetup(game);
        hideDialog();
      },
    }));
    if (items.length > 0) showDialog(<ItemSelector items={items} />, "xl", true, true, true, "Select a game");
    else this.openScorerSetup(this.getGame());
  };

  openScorerSetup = (game: Game) => {
    const userId = this.props.user._id;
    this.props.switchComponent(Views.ScorerGameSetup, {
      gameId: game._id,
      group: game.players[userId].group,
    });
  };
  _onOpenLarboard = () => {
    const items = this.getLinkedGames().map(game => ({
      title: game.name,
      onClick: () => {
        showDialog(<IndividualLeaderBoard gameId={game._id} />, "xl", true, true, true);
      },
    }));
    if (items.length > 0) showDialog(<ItemSelector items={items} />, "xl", true, true, true, "Select a game");
    else showDialog(<IndividualLeaderBoard gameId={this.props.gameId} />, "xl", true, true, true);
  };
  _onShowScoreCard = (player: any) => {
    showDialog(
      <ModernScoreCardView
        gameId={this.props.gameId}
        player={player}
        onVerifyPlayer={this.handleVerifyPlayer}
        quickScoring
      />,
      "xl",
      false,
      true,
      true,
      "Does Player agree to their score?",
    );
  };

  showAdvertsThumbnail = () => {
    const { gameId, gamesAd } = this.props;
    const advert = gamesAd[gameId];

    if (!advert || !advert.holesInfo) return;
    showDialog(<AdvertsThimbnails adverts={advert} gameId={gameId} />, "xl", false, true, true);
  };

  _nextHole = () => {
    const { gameId, gamesAd } = this.props;
    const nextHole = this.state.holeIndex + 1;
    if (this.canGoNextHole()) {
      showAdvert(gameId, gamesAd[gameId], nextHole);
      this.setState({ holeIndex: this.state.holeIndex + 1 });
      this.scrollToHoleDivHandler();
    } else if (!this.getGame()?.autoProgress)
      showSnackbar("Please score all players before proceeding to the next hole");
  };

  canGoNextHole = () => {
    const { holeIndex } = this.state;
    if (holeIndex < this.getNumberOfHoles() - 1) {
      const initialHoleIndex = this.getInitialHoleIndex();
      const nextHole = holeIndex + 1;
      if (!this.scoredIAllMyCards()) return false;
      if (initialHoleIndex === null || nextHole <= initialHoleIndex) return true;
      return false;
    }
    return false;
  };

  _previousHole = () => {
    if (this.state.holeIndex > 0) {
      this.setState(state => ({
        holeIndex: state.holeIndex - 1,
      }));
    } else {
      this.setState({ holeIndex: this.getNumberOfHoles() - 1 });
    }
  };

  _onSubmitAllCards = () => {
    const { gameId } = this.props;
    this.props.finishGame(gameId);
  };

  handleDisagreePlayer = (player: Player) => {
    const disagreeUsers = [...this.state.disagreePlayers];
    this.setState({ disagreePlayers: disagreeUsers.concat(player._id) }, () => this._onShowScoreCard(player));
  };

  canSubmitAllCards() {
    const { isSubmitted } = this.state;
    if (!this.grossFinalCheckAllPlayers()) return null;
    if (localStorage.getItem(Config.STORAGE.FAILED_API_CALLS)) return null;
    if (!isSubmitted) {
      this.setState({ isSubmitted: true }, () => {
        this._onSubmitAllCards();
        this.leaveGame();
      });
    }
  }

  leaveGame = () => {
    this.props.switchComponent(Views.DEFAULT);
    this.showAdvertsThumbnail();
  };

  isGameFinished() {
    if (this.getGame()?.state === GState.Done) this.leaveGame();
  }

  handleVerifyPlayer = (playerId: string) => {
    const verifiedUsers = [...this.state.verifiedPlayers];
    this.setState({ verifiedPlayers: verifiedUsers.concat(playerId) });
  };
  scoredIAllMyCards = () => {
    const game = this.getGame();
    if (!game) return false;
    let result = true;
    const { holeIndex } = this.state;
    const scoringPlayers = this.getScoringPlayers();
    scoringPlayers.forEach((playerId: any) => {
      if (this.isThisCardWaitingForMe(game.players[playerId], holeIndex + 1)) result = false;
    });
    return result;
  };

  isThisCardWaitingForMe = (player: Player, number: any) => {
    const scoringPlayers = this.getScoringPlayers();
    const game = this.getGame();
    if (!game) return false;
    const { user } = this.props;
    return player.uholes && player.uholes[number] && player.uholes[number].grosses
      ? scoringPlayers.includes(player._id) && !player.uholes[number].grosses[user._id || ""]
      : false;
  };

  render() {
    const { classes, loading, user } = this.props;
    const { holeIndex, isSubmitted } = this.state;
    if (!this.props.joinedGames[this.props.gameId]) return null;

    const game = this.getGame();

    const mySelf = game.players[user._id];
    if (game && holeIndex < this.getNumberOfHoles()) {
      if (game.autoProgress) this._nextHole();
      const scoreCard = this.getScoreCard(mySelf._id);
      this.canSubmitAllCards();
      this.isGameFinished();
      if (game.autoProgress === undefined) this.props.setGameAutoProgress(game._id, true);

      return (
        <div key={game._id} style={{ height: "100%" }}>
          {loading && <LinearProgress color={"primary"} />}
          <TableContainer component={Paper} style={{ height: "100%" }}>
            {isSubmitted && <div className={classes.submitDiv}>All Cards Submitted!</div>}
            <div ref={ref => (this.holeDivRef = ref)}></div>
            <ScoringTableToolbar
              holeIndex={holeIndex}
              scoreCard={scoreCard}
              isAutoProgressEnable={game.autoProgress}
              onOpenLeaderboard={this._onOpenLarboard}
              onPreviousHole={this._previousHole}
              onNextHole={this._nextHole}
              onAutoProgressChanged={this._handleAutoProgressChange}
              onOpenScorerSetup={this._onOpenScorerSetup}
            />

            {this.getScorableCards().map((player: any, index: Number) => {
              const scoreCard = this.getScoreCard(player._id);
              return (
                <ScoringTable
                  key={index.toString()}
                  index={index}
                  game={game}
                  holeIndex={holeIndex}
                  player={player}
                  scoreCard={scoreCard}
                  canGoNextHole={this.canGoNextHole()}
                  scoring={this.getScoringPlayers().includes(player._id)}
                  grossFinalCheck={this.grossFinalCheck}
                  handleGrossChange={this._handleGrossChange}
                  handleVerifyPlayer={this.handleVerifyPlayer}
                  openPickerDialogForMisMatchHole={this.openPickerDialogForMisMatchHole}
                  onOpenScorerSetup={this._onOpenScorerSetup}
                />
              );
            })}
          </TableContainer>
        </div>
      );
    } else {
      return null;
    }
  }
}

const styles = (theme: Theme) =>
  createStyles({
    headCell: {
      width: 85,
    },
    title: {
      display: "flex",
      justifyContent: "center",
      fontSize: "1rem",
      flexGrow: 0.25,
      margin: 0,
    },
    inline: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-evenly",
    },
    column: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-evenly",
    },

    submitDiv: {
      backgroundColor: theme.palette.secondary.main,
      padding: 10,
      textAlign: "center",
      color: "#ffffff",
    },
    iconText: {
      margin: "0",
      fontSize: "smaller",
    },
  });
const mapStateToProps = (state: GlobalState) => {
  const { joinedGames } = state.games;
  const { gamesAd } = state.adverts;
  const { user } = state.auth;
  const { loading } = state.general;
  return {
    user: user as User,
    gamesAd,
    loading,
    joinedGames,
  };
};

export default connect(mapStateToProps, {
  finishGame: GameActions.finishGame,
  updateScores: GameActions.updateScores,
  getAdvertByGameId: AdvertActions.getByGameId,
  setGameAutoProgress: GameActions.setGameAutoProgress,
  toggleScoringPlayer: GameActions.toggleScoringPlayer,
  switchComponent,
})(withStyles(styles)(withDetector(HoleScoreForm)));
