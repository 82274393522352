import { persistReducer,persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { applyMiddleware,createStore } from "redux";
import ReduxThunk from "redux-thunk";
import reducers from "./reducers";
import { composeWithDevTools } from '@redux-devtools/extension';

const persistConfig = { key: "schala-v26",storage };
const persistedReducer = persistReducer(persistConfig,reducers);
export const store: any = createStore(persistedReducer,composeWithDevTools(
    applyMiddleware(ReduxThunk)
    // other store enhancers if any
));
export const persistor: any = persistStore(store);
