export interface HoleParam {
  [index: string]: number | undefined;
  number: number;
  yards: number;
  par: number;
  si: number;
  score?: number;
}

export enum HoleSigns {
  DNP = "DNP",
  DNF = "DNF",
  PU = "PU",
}
