import * as React from "react";
import moment from "moment";
import { Paper, Typography, makeStyles, Theme, IconButton } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Dialog from "@material-ui/core/Dialog";
import GolfCourseIcon from "@material-ui/icons/GolfCourse";
import FiberIcon from "@material-ui/icons/FiberManualRecord";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";

import { Player } from "types/player";
import { Game } from "types/game";
import { Tournament } from "types/tournament";
import { usePlayerTMGames } from "hooks/usePlayerTMGames";
import { useSelector } from "hooks/useSelector";
import { showAlertDialog } from "../SharedComponents/AlertDialog";
import IndividualLeaderBoard from "components/leaderBoard/IndividualLeaderBoard";
import { showAuxiliaryDialog } from "components/SharedComponents/AuxiliaryDialog";
import ScoreCardView from "components/ScoreCard/ScoreCardSubmition";
import ModernScoreCardView from "components/ScoreCard/ModernScoreCardView";
import PlayerName from "components/SharedComponents/PlayerName";
import { getGrossStyle, getHandicapDisplay } from "utils";
export interface TournamentHoleByHoleDialogProps {
  player: Player;
  points: number;
  tournament: Tournament;
}

interface StateType {
  dialog?: string;
  player?: Player;
  game?: Game;
}

const TournamentHoleByHoleDialog: React.FunctionComponent<TournamentHoleByHoleDialogProps> = props => {
  const classes = useStyles();
  const user = useSelector(state => state.auth.user);
  const { player, tournament, points } = props;
  const { games, isEclectic, holes, isLoading } = usePlayerTMGames(player._id, tournament._id);
  const [state, setState] = React.useState<StateType>({});

  const showScoreCardDialog = (gameId: string) => {
    // Get the game
    const selectedGame = games.find(g => g._id === gameId);
    if (!selectedGame) return;
    // And the player
    const selectedPlayer = selectedGame.players[player._id];
    // Is organizer ?
    const isOrganizer = selectedGame.organizers.find(o => o._id === user._id) !== undefined;

    if (isOrganizer) {
      showAlertDialog(
        "Submission",
        "Review Submission Card.",
        () => {
          setState({
            dialog: "submission",
            player: selectedPlayer,
            game: selectedGame,
          });
        },
        () => {
          setState({
            dialog: "viewCard",
            player: selectedPlayer,
            game: selectedGame,
          });
        },
      );
    } else {
      setState({
        dialog: "viewCard",
        player: selectedPlayer,
        game: selectedGame,
      });
    }
  };

  return (
    <>
      <Paper className={classes.paper}>
        <Typography variant={"h5"} className={classes.header}>
          <PlayerName isGuest={player.guest} name={player.name} lastName={player.lastName} />
          <small>{` - PH ${getHandicapDisplay(player.hcp)}`}</small>
        </Typography>

        <div style={{ textAlign: "center", marginBottom: 25 }}>
          <Typography variant="h5" gutterBottom>
            {tournament.name}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {moment(tournament.seasonStart).format("ll")}- {moment(tournament.seasonEnd).format("ll")}
          </Typography>
        </div>

        {isLoading && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
          </div>
        )}

        {isEclectic && (
          <div className={classes.flex}>
            {holes.map(hole => (
              <div key={hole.holeNumber} style={{ display: "flex", flexDirection: "column", width: "fit-content" }}>
                <div className={[classes.tile, classes.square].join(" ")}>{hole.holeNumber}</div>
                <div className={classes.tile} style={{ paddingTop: 8 }}>
                  {hole.par}
                </div>
                <div
                  className={[classes.tile, classes.circle].join(" ")}
                  style={getGrossStyle(hole.par, hole.gross)}
                  onClick={showScoreCardDialog.bind(null, hole.gameId)}
                >
                  {hole.gross === "mismatch" ? <CloseIcon style={{ margin: -6 }} /> : hole.gross}
                </div>
                <div className={[classes.tile, classes.points].join(" ")}>
                  {!hole.gross || hole.gross === "mismatch" ? null : hole.score}
                </div>
              </div>
            ))}
          </div>
        )}

        <List dense>
          {games.map(gm => (
            <ListItem
              key={gm._id}
              className={classes.item}
              onClick={isEclectic ? showScoreCardDialog.bind(null, gm._id) : undefined}
            >
              <ListItemIcon>
                <FiberIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText style={{ flex: "inherit" }} primary={moment(gm.date).format("ll")} />
              <ListItemText style={{ textAlign: "center" }} primary={gm.name} secondary={gm.code} />
              {isEclectic ? (
                <div style={{ flex: 1, padding: 5, border: "1px solid #444" }}>
                  {gm.holes.length ? `Holes: ${gm.holes.join(", ")}` : `NONE`}
                </div>
              ) : (
                <>
                  <div className={classes.point}>{points}</div>
                  <IconButton
                    style={{ marginLeft: 15 }}
                    onClick={() => {
                      showAuxiliaryDialog(
                        <IndividualLeaderBoard gameId={gm._id} activeUserId={player._id} />,
                        "xl",
                        true,
                        true,
                        true,
                      );
                    }}
                  >
                    <GolfCourseIcon style={{ color: "#29b529" }} />
                  </IconButton>
                </>
              )}
            </ListItem>
          ))}
        </List>
      </Paper>

      {isEclectic && state.dialog && state.player && state.game && (
        <>
          <Dialog open={state.dialog === "submission"} fullScreen onBackdropClick={setState.bind(null, {})}>
            <ScoreCardView player={state.player} game={state.game} closeDialog={setState.bind(null, {})} />
          </Dialog>
          <Dialog open={state.dialog === "viewCard"} onBackdropClick={setState.bind(null, {})}>
            <div>
              <IconButton style={{ float: "right" }} onClick={setState.bind(null, {})}>
                <CloseIcon />
              </IconButton>
            </div>
            <ModernScoreCardView player={state.player} game={state.game} />
          </Dialog>
        </>
      )}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    display: "block",
    position: "relative",
    left: "50%",
    transform: "translateX(-50%)",
    minWidth: 700,
    padding: theme.spacing(2),

    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
    },
  },
  item: {
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },
  point: {
    width: 40,
    height: 30,
    padding: 5,
    textAlign: "center",
    backgroundColor: "orange",
    color: "white",
  },
  header: {
    paddingBottom: 20,
  },
  title: {
    maxWidth: "75%",
    margin: "1rem auto 3rem",
    padding: 5,
    border: "2.5px solid #000",
    textAlign: "center",
  },
  flex: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    padding: "0 50px",

    [theme.breakpoints.down("sm")]: {
      padding: "0 20px",
    },

    "& > div": {
      minWidth: "11%",
      marginBottom: 10,

      [theme.breakpoints.down("sm")]: {
        minWidth: "15%",
      },
    },
  },
  tile: {
    width: 42,
    height: 42,
    paddingTop: 8,
    margin: 2,
    fontSize: 16,
    textAlign: "center",
  },
  points: {
    color: "#f50057",
  },
  square: {
    border: "solid",
    borderColor: "#33a136",
    borderWidth: "2px",
    padding: 3,
    display: "flex",
    flexDirection: "column",

    "& > span": {
      lineHeight: "1",

      "&:last-child": {
        color: "#33a136",
      },
    },
  },
  circle: {
    border: "solid",
    borderColor: "#000",
    borderWidth: "2px",
    borderRadius: 32,
    cursor: "pointer",
  },
}));

export default TournamentHoleByHoleDialog;
