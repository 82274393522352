import { PayStatus } from "../types/player";
import BaseApi from "./Api";

class PaymentsApi extends BaseApi {
  url = "payments";
  constructor() {
    super();
    this.setResourceUrl(this.url);
  }
  verifyPayment(id: string, data: any) {
    return this.instance({
      method: "put",
      url: `${this.url}/${id}`,
      data,
    });
  }
  getWithdraws = (page: number, limit: number, filter?: any, sort?: string, dir?: string, query?: string) => {
    let url = `/withdraws?page=${page}&limit=${limit}`;
    if (sort) {
      if (dir) {
        url += `&sort=${sort}:${dir}`;
      } else {
        url += `&sort=${sort}:asc`;
      }
    }
    if (query) {
      url += `&query=${query}`;
    }
    if (filter) {
      url += `&filter=${JSON.stringify(filter)}`;
    }
    url = this.url + url;
    return this.instance({
      method: "get",
      url,
    });
  };
  onboarding(redirectUrl: string): any {
    return this.instance({
      method: "post",
      url: `${this.url}/onboarding`,
      data: { redirectUrl },
    });
  }
  paymentSession(data: any): any {
    return this.instance({
      method: "post",
      url: `${this.url}/paymentSession`,
      data,
    });
  }
  addWithoutPay(gameId: string, playersId: string[], type: PayStatus) {
    return this.instance({
      method: "put",
      url: `${this.url}/${gameId}/paid`,
      data: { playersId, type },
    });
  }
}

export default new PaymentsApi();
