import React, { useReducer } from "react";
import {
  WithStyles,
  FormControl,
  TextField,
  Select,
  MenuItem,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Button,
  Box,
  Typography,
} from "@material-ui/core";
import { FixedMobileStepper } from "./MobileStepper";
import SearchClub from "../../Search/SearchClub";
import { showAlertDialog } from "../../SharedComponents/AlertDialog";
import { showSnackbar } from "../../SharedComponents/Notifier";
import { TextInput } from "../../SharedComponents/TextInput";
import { filter } from "../../../utils/profanityFilter";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateUtils from "@date-io/date-fns";
import { calculateAge } from "../../../utils/date";
import PolicyLink from "components/PolicyLink";

interface Step4Inputs {
  title: string;
  name: string;
  lastName: string;
  cdh: number;
  hcp: string;
  dob: Date;
  homeClub: string;
  postalCode: string;
  socialHcp: string;
  gender: string;
  policyAccepted: boolean;
}

interface Props extends WithStyles {
  onNext: (inputs: Step4Inputs) => void;
  onPrev: () => void;
}

const initialState = {
  title: "",
  titleErr: "",
  name: "",
  nameErr: "",
  lastName: "",
  lastNameErr: "",
  cdh: "",
  cdhErr: "",
  dob: null,
  hcp: "",
  ageErr: "",
  dobErr: "",
  hcpErr: "",
  homeClub: "",
  homeClubErr: "",
  postalCodeErr: "",
  socialHcpErr: "",
  postalCode: "",
  socialHcp: "",
  gender: "men",
  genderErr: "men",
  policyAccepted: false,
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "title":
    case "name":
    case "lastName":
    case "cdh":
    case "hcp":
    case "dob":
    case "homeClub":
    case "gender":
    case "titleErr":
    case "nameErr":
    case "postalCodeErr":
    case "socialHcpErr":
    case "postalCode":
    case "socialHcp":
    case "lastNameErr":
    case "cdhErr":
    case "dobErr":
    case "hcpErr":
    case "homeClubErr":
    case "genderErr":
    case "policyAccepted":
      return {
        ...state,
        [action.type]: action.payload,
      };

    default:
      throw new Error();
  }
};

export const Step4 = (props: Props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleChange = (value: string, name: string) => {
    dispatch({ type: name, payload: value });
  };
  function validatePostalCode(postalCode: string) {
    const separatedPC = postalCode.split(" ");
    const firstPartRegex = /^[A-Z][A-HJ-Y]?[0-9][A-Z0-9]?$/;
    const lastPartRegex = /^(0[Aa]{2}|[0-9][A-Za-z]{2})$/i;
    if (separatedPC[0]?.match(firstPartRegex) && (!separatedPC[1] || separatedPC[1]?.length === 0)) {
      dispatch({ type: "postalCodeErr", payload: "" });
      return true;
    }
    if (separatedPC[1]?.match(lastPartRegex)) {
      dispatch({ type: "postalCodeErr", payload: "" });
      return true;
    }

    return false;
  }

  function validateWHSBoxes(cdhNumber: string, handicapIndex: string) {
    let errorMessages = [];
    const hcpRegex = RegExp(/^\+?\d{1,2}(?:\.\d)?$/gm);
    if (cdhNumber.length > 0 || handicapIndex.length > 0) {
      if (cdhNumber.length === 0) {
        errorMessages.push({ message: "CDH is required", err: "cdhErr" });
      }
      if (handicapIndex.length === 0) {
        errorMessages.push({ message: "Handicap Index is required", err: "hcpErr" });
      }
      if (!/^\d{1,10}$/.test(cdhNumber)) {
        errorMessages.push({ message: "Invalid CDH number format", err: "cdhErr" });
      }
      if (
        handicapIndex.length > 0 &&
        (parseInt(handicapIndex) < 0 || parseInt(handicapIndex) > 60 || !hcpRegex.test(handicapIndex))
      ) {
        errorMessages.push({ message: "Invalid Handicap Index format", err: "hcpErr" });
      }
    } else {
      errorMessages.push({ message: "CDH or Handicap Index is required", err: "cdhErr" });
    }
    if (errorMessages.length === 0) {
      dispatch({ type: "cdhErr", payload: "" });
      dispatch({ type: "hcpErr", payload: "" });
      return true;
    } else {
      errorMessages.forEach(errorMessage => {
        showSnackbar(errorMessage.message);
        dispatch({
          type: errorMessage.err,
          payload: errorMessage.message,
        });
      });
      return false;
    }
  }
  function validateSocialHcp(socialHcp: string) {
    if (!Boolean(socialHcp)) {
      return false;
    }
    if (/^[0-9A-Za-z]{5}$/.test(socialHcp)) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <>
      <div className={props.classes.stepContainer} style={{ padding: "0 16px 16px 16px" }}>
        <p>Some additional information.</p>
        <div className={props.classes.formContainer}>
          <SearchClub
            style={{ marginTop: 8 }}
            dialog
            placeHolder="Tap To Search Home Club"
            onClubSelected={(item: any) => dispatch({ type: "homeClub", payload: (item && item._id) || "" })}
          />
          <FormControl color="primary" size={"medium"} fullWidth>
            <Select
              label="Title"
              placeholder="Title"
              value={state.title}
              onChange={event => dispatch({ type: "title", payload: event.target.value })}
            >
              <MenuItem value={"Mr"}>Mr</MenuItem>
              <MenuItem value={"Mrs"}>Mrs</MenuItem>
              <MenuItem value={"Miss"}>Miss</MenuItem>
            </Select>
          </FormControl>
          <FormControl color="primary" size={"medium"} fullWidth>
            <TextInput
              type="text"
              label={"First Name"}
              style={{ margin: "3px 0" }}
              value={state.name}
              error={state.nameErr.length > 0}
              helperText={state.nameErr}
              captalize
              name={"name"}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl color="primary" size={"medium"} fullWidth>
            <TextInput
              type="text"
              label={"Last Name"}
              style={{ margin: "3px 0" }}
              value={state.lastName}
              error={state.lastNameErr.length > 0}
              helperText={state.lastNameErr}
              captalize
              name={"lastName"}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl color="primary" size={"medium"} fullWidth>
            <TextField
              type="text"
              label={"Postcode"}
              style={{ margin: "3px 0" }}
              value={state.postalCode}
              error={state.postalCodeErr.length > 0}
              helperText={state.postalCodeErr}
              onChange={event => dispatch({ type: "postalCode", payload: event.target.value })}
            />
          </FormControl>
          <FormControl
            color="primary"
            size={"medium"}
            style={{ display: "flex", flexDirection: "row", alignItems: "end", gap: "8px" }}
          >
            <MuiPickersUtilsProvider utils={DateUtils}>
              <KeyboardDatePicker
                id="date"
                variant="dialog"
                format="dd/MM/yyyy"
                margin="normal"
                views={["year", "month", "date"]}
                openTo="year"
                placeholder="Date of birth"
                value={state.dob}
                error={state.dobErr.length > 0}
                helperText={state.dobErr}
                disableFuture
                onChange={(date: Date | null) => {
                  if (date) dispatch({ type: "dob", payload: date });
                }}
              />
            </MuiPickersUtilsProvider>
            <TextField
              disabled
              type="number"
              label={"Age"}
              style={{ margin: "8px 0", width: "20%" }}
              value={calculateAge(state.dob)}
            />
          </FormControl>

          <Box display="flex" alignItems="end" style={{ gap: "8px" }}>
            <FormControl color="primary" size={"medium"} fullWidth style={{ flex: "1" }}>
              <TextField
                type="text"
                label={"WHS Handicap Index"}
                style={{ margin: "3px 0" }}
                value={state.hcp}
                error={state.hcpErr.length > 0}
                helperText={state.hcpErr}
                inputProps={{ min: 0, max: 54 }}
                onChange={event => dispatch({ type: "hcp", payload: event.target.value })}
              />
            </FormControl>
            <FormControl color="primary" size={"medium"} fullWidth style={{ width: "40%" }}>
              <TextField
                type="number"
                label={"WHS CDH No."}
                style={{ margin: "3px 0" }}
                value={state.cdh}
                error={state.cdhErr.length > 0}
                helperText={state.cdhErr}
                onChange={event => dispatch({ type: "cdh", payload: event.target.value })}
              />
            </FormControl>
          </Box>
          <Box display="flex" alignItems="end" style={{ gap: "8px" }}>
            <Typography variant="caption" style={{ width: "60%" }}>
              If you have no WHS then enter your Social H/cap
            </Typography>
            <FormControl color="primary" fullWidth size={"medium"} style={{ flex: "1" }}>
              <TextField
                disabled={Boolean(state.hcp)}
                type="text"
                label={"Social H/Cap"}
                style={{ margin: "3px 0" }}
                value={state.socialHcp}
                error={state.socialHcpErr.length > 0}
                helperText={state.socialHcpErr}
                name={"socialHcp"}
                onChange={event => dispatch({ type: "socialHcp", payload: event.target.value })}
              />
            </FormControl>
          </Box>
          <FormControl color="primary" size={"medium"} fullWidth style={{ marginTop: 16 }}>
            <FormLabel component="legend">Gender</FormLabel>
            <RadioGroup
              defaultValue="men"
              aria-label="gender"
              onChange={event => dispatch({ type: "gender", payload: event.target.value })}
              row
            >
              <FormControlLabel value="men" control={<Radio />} label="Male" />
              <FormControlLabel value="women" control={<Radio />} label="Female" />
            </RadioGroup>
          </FormControl>

          <FormControlLabel
            className="policy-label"
            control={
              <Checkbox
                checked={state.policyAccepted}
                onChange={evt => dispatch({ type: "policyAccepted", payload: evt.target.checked })}
                name="policy"
                color="secondary"
              />
            }
            label={<PolicyLink startText="Check here" />}
          />

          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: 15 }}
            onClick={() => {
              const data = {
                title: state.title,
                name: state.name.trim(),
                lastName: state.lastName.trim(),
                homeClub: state.homeClub.trim(),
                dob: state.dob,
                hcp: state.hcp.trim(),
                cdh: state.cdh,
                postalCode: state.postalCode.trim(),
                socialHcp: Boolean(state.hcp) ? "" : state.socialHcp,
                gender: state.gender,
                policyAccepted: state.policyAccepted,
              };

              if (!data.policyAccepted) {
                return showSnackbar("You must accept our policy to register");
              }

              dispatch({ type: "nameErr", payload: "" });
              dispatch({ type: "lastNameErr", payload: "" });
              if (data.name.length === 0 || filter.isProfane(data.name))
                dispatch({ type: "nameErr", payload: "First Name cannot be empty or contain swear words" });
              else if (data.lastName.length === 0 || filter.isProfane(data.lastName))
                dispatch({ type: "lastNameErr", payload: "Last Name cannot be empty or contain swear words" });
              else if (data.postalCode.length === 0 || !validatePostalCode(data.postalCode)) {
                dispatch({
                  type: "postalCodeErr",
                  payload: "Please submit the first part of your postcode. The second part is optional",
                });
              } else if ((data.hcp.length > 0 || data.cdh.length > 0) && !validateWHSBoxes(state.cdh, state.hcp)) {
                dispatch({
                  type: "hcpErr",
                  payload: "",
                });
              } else if (!Boolean(state.hcp) && data.socialHcp.length > 0 && !validateSocialHcp(data.socialHcp)) {
                return;
              } else if (data.homeClub.length < 3) {
                showAlertDialog(
                  "No Home Club selected",
                  "You have selected no home club, are you a club member?",
                  () => {},
                  () => props.onNext(data),
                );
              } else {
                props.onNext(data);
              }
            }}
          >
            Register
          </Button>
        </div>
      </div>
      <FixedMobileStepper maxSteps={4} step={3} onPrev={props.onPrev} onNext={() => {}} />
    </>
  );
};
