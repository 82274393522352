import React, { Component } from "react";
import { IconButton, createStyles, Paper, TextField, Theme, Typography, WithStyles } from "@material-ui/core";
import MyTable, { Column, TableAction, TableState } from "../SharedComponents/MyTable";
import withStyles from "@material-ui/core/styles/withStyles";
import PotsActions from "../../actions/pots";
import PaymentActions from "../../actions/payments";
import { connect } from "react-redux";
import { GlobalState } from "../../types/globalState";
import { Pot } from "../../types/pot";
import TransactionList from "./TransactionsList";
import { showDialog } from "../SharedComponents/Dialog";
import { List, Payment } from "@material-ui/icons";
import { Withdraw } from "../../types/withdraws";
import PlayersList from "./PlayersList";
import { Player } from "types/player";
import { InfoRounded } from "@material-ui/icons";
import { showAuxiliaryDialog } from "components/SharedComponents/AuxiliaryDialog";

interface State {
  [inde: string]: any;
}
interface Props extends WithStyles {
  fetchPots(page: number, limit: number): void;
  getTransactions(potId: string, callback?: any): void;
  getWithdrawalLink(potId: string, callback?: any): void;
  getWithdraws(page: number, limit: number, filter?: any, sort?: string, dir?: string, query?: string): void;

  withdraws: Withdraw[];
  count: number;
  loading: boolean;
}

class WithdrawsListView extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    // this.props.fetchPots(1, 50);
    this.props.getWithdraws(1, 50);
  }

  onPlayersClick = (players: Player[]) => {
    showDialog(<PlayersList players={players} />, "xl", false, true, true, `Players`);
  };

  onTransactionClick = (transactionNo: string) => {
    showDialog(<TransactionList transactionNo={transactionNo} />, "xl", false, true, true, `Transaction Number`);
  };

  onWithdrawalClick = (pot: Pot) => {
    this.props.getWithdrawalLink(pot._id, (err: any, data: any) => {
      if (!err) window.location = data;
    });
  };

  prepareData = (items: any) => {
    return items.map((item: Withdraw, index: number) => {
      return {
        ...item,
        index: index + 1,
        gameCode: item.game.code,
        gross: (item?.payment?.amount / 100).toFixed(2),
        customer: `${item?.owner?.name ?? ""} ${item?.owner?.lastName ?? ""}, \n ${item?.owner?.email ?? ""}, \n ${item
          ?.owner?.username ?? ""}`,
        net: ((item?.payment?.amount - item.payment.platformFee) / 100).toFixed(2),
        createDate: { isDatetime: true, timestamp: item.payment.createdTimestamp * 1000 },
        status: item.payment.status,
        players: item.players,
      };
    });
  };

  _onTableChange = (actions: TableAction, state: TableState) => {
    const sortField = new Map<string, string>([
      ["gameCode", "game.code"],
      ["gross", "payment.amount"],
      ["customer", "owner.username"],
      ["createDate", "payment.createdTimestamp"],
      ["status", "payment.status"],
      ["players", "players"],
    ]);
    let sort: string | undefined = "";
    if (actions === "sort" && state.sort.field) {
      sort = sortField.get(state.sort.field);
    }
    this.props.getWithdraws(state.page + 1, state.pageSize, undefined, sort, state.sort.dir, state.query);
  };

  render() {
    const { classes, withdraws, count, loading } = this.props;
    const onPlayersClick = this.onPlayersClick;
    const onTransactionClick = this.onTransactionClick;
    const columns: Column[] = [
      // {
      //   field: "index",
      //   title: "#",
      // },
      {
        field: "gameCode",
        title: "Game Code",
      },

      {
        field: "customer",
        title: "Customer",
      },
      {
        field: "gross",
        title: "Gross",
      },
      {
        field: "net",
        title: "Net",
      },
      {
        field: "createDate",
        title: "Date",
      },
      {
        field: "status",
        title: "Status",
      },
      {
        field: "transactionNo",
        title: "Transaction Number",
        handler(item: any, field: any): JSX.Element {
          return (
            <IconButton onClick={() => onTransactionClick(field?.transactionNo)}>
              <InfoRounded />
            </IconButton>
          );
        },
      },
      {
        field: "players",
        title: "Players",
        handler(item: any, field: any): JSX.Element {
          return (
            <IconButton onClick={() => onPlayersClick(field.players ?? [])}>
              <List />
            </IconButton>
          );
        },
      },
    ];
    return (
      <div className={classes.paper}>
        <MyTable
          title={"Withdraws"}
          loading={loading}
          count={count}
          data={this.prepareData(withdraws)}
          // defaultOrderBy={"payment.createdTimestamp"}
          // searchEnabled
          columns={columns}
          serverSidePagination
          onTableChange={this._onTableChange}
        />
      </div>
    );
  }
}

const styles = (theme: Theme) => createStyles({});

const mapStateToProps = (state: GlobalState) => {
  const { withdraws, count } = state?.withdraws;
  const { loading } = state.general;
  return {
    withdraws: withdraws ? Object.values(withdraws) : [],
    loading,
    count,
  };
};

export default connect(mapStateToProps, {
  fetchPots: PotsActions.fetch,
  getWithdraws: PaymentActions.getWithdraws,
  fetchPayments: PaymentActions.fetch,
  getTransactions: PotsActions.getTransactions,
  getWithdrawalLink: PotsActions.getWithdrawalLink,
})(withStyles(styles)(WithdrawsListView));
