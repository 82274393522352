import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import Paper from "@material-ui/core/Paper";
import LinearProgress from "@material-ui/core/LinearProgress";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { createStyles, Divider, TextField, Theme, WithStyles } from "@material-ui/core";

import { login } from "../../actions";
import { GlobalState } from "../../types/globalState";
import validator from "validator";
import Messages from "../../consts/Messages";
import logo from "../../assets/images/qr-logo.png";
import { Link } from "react-router-dom";
import config from "../../config";

interface Props extends WithStyles {
  login(email: string | null, username: string | null, password: string, callback?: any): void;
  loading: boolean;
  match: any;
}

interface State {
  email: string;
  password: string;
  err_msg_email: string;
  err_msg_password: string;
  showPassword: boolean;
}

class LoginPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      err_msg_password: "",
      err_msg_email: "",
      showPassword: false,
    };
  }

  formValidation = () => {
    const { email, password } = this.state;

    const err_email = email.length < 5;
    const err_password = password.length < 6;

    const err_msg_email = err_email ? Messages.error.emailUserShortLength : "";
    const err_msg_password = err_password ? Messages.error.passwordShortLength : "";

    this.setState({ err_msg_email, err_msg_password });

    return !(err_email || err_password);
  };

  _onClick = (event: any) => {
    event.preventDefault();
    const { email, password } = this.state;
    if (this.formValidation()) {
      if (validator.isEmail(email.trim())) {
        this.props.login(email, null, password);
      } else {
        this.props.login(null, email, password);
      }
    }
  };

  _onEmailChanged = (event: any) => {
    this.setState({ email: event.target.value.toLowerCase() });
  };

  _onPasswordChanged = (event: any) => {
    this.setState({ password: event.target.value });
  };

  render() {
    const { classes, loading } = this.props;
    const { err_msg_password, err_msg_email } = this.state;
    const { gameCode } = this.props.match.params;
    return (
      <div>
        {loading ? <LinearProgress color="primary" variant="query" /> : null}
        <main className={classes.main}>
          <CssBaseline />
          <Paper className={classes.paper}>
            <img alt="logo" src={logo} width="85" />
            <p style={{ marginBottom: 16 }}>No SCHALA account yet ?</p>
            <Link to={gameCode ? `/signup/${gameCode}` : `/signup`} style={{ textDecoration: "none" }}>
              <Button variant={"outlined"} color={"primary"}>
                Register
              </Button>
            </Link>
            <Divider />
            <form className={classes.form}>
              <FormControl margin="normal" color="primary" required fullWidth>
                <TextField
                  label={"Username/Email"}
                  name="email"
                  margin={"normal"}
                  error={err_msg_email.length > 0}
                  helperText={err_msg_email}
                  onChange={this._onEmailChanged}
                />
                <TextField
                  label={"Password"}
                  name="password"
                  type={this.state.showPassword ? "text" : "password"}
                  margin={"normal"}
                  autoComplete="current-password"
                  error={err_msg_password.length > 0}
                  helperText={err_msg_password}
                  onChange={this._onPasswordChanged}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => this.setState({ showPassword: !this.state.showPassword })}
                          onMouseDown={evt => evt.preventDefault()}
                        >
                          {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
              <Link to="/forgotPassword">
                <Button variant={"text"} className={classes.rightTextButton}>
                  Forgot password?
                </Button>
              </Link>
              <Button onClick={this._onClick} fullWidth variant="contained" color="primary" className={classes.submit}>
                Sign in
              </Button>
              <label className={classes.version}>{config.VERSION}</label>
            </form>
          </Paper>
        </main>
      </div>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    main: {
      width: "auto",
      display: "block", // Fix IE 11 issue.
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      [theme.breakpoints.up(400 + theme.spacing(6))]: {
        width: 400,
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    paper: {
      width: window.innerWidth < 400 ? "100%" : 375,
      height: window.innerWidth < 400 ? "100%" : "auto",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
      backgroundColor: "rgba(255, 255, 255, 0.85)",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%)",
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
      padding: theme.spacing(2),
      paddingTop: 0,
    },
    submit: {
      marginTop: theme.spacing(3),
      backgroundColor: "#4692e7",
      borderRadius: 100,
    },
    inline: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-even",
    },
    rightTextButton: {
      fontSize: 10,
      float: "right",
    },
    version: {
      fontSize: 11,
      textAlign: "center",
      display: "block",
      paddingTop: 5,
    },
  });

const mapStateToProps = (state: GlobalState) => {
  return { loading: state.general.loading };
};

export default connect(mapStateToProps, { login })(withStyles(styles)(LoginPage));
