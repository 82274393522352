import React from "react";
import { Theme, createStyles, withStyles, MobileStepper, Button } from "@material-ui/core";
import { KeyboardArrowRight, KeyboardArrowLeft } from "@material-ui/icons";
import { Link } from "react-router-dom";

const Stepper = withStyles((theme: Theme) =>
  createStyles({
    dots: {
      position: "absolute",
      left: "50%",
      transform: "translateX(-50%)",
    },
  }),
)(MobileStepper);

interface Props {
  maxSteps: number;
  step: number;
  onNext: any;
  onPrev: any;
}

export const FixedMobileStepper = (props: Props) => {
  return (
    <Stepper
      steps={props.maxSteps}
      activeStep={props.step}
      position={"bottom"}
      variant="dots"
      nextButton={
        props.step === props.maxSteps - 1 ? (
          <Button
            size="small"
            color={"secondary"}
            style={{ minWidth: "5.5rem" }}
            variant={"outlined"}
            component={Link}
            to="/"
          >
            Cancel
          </Button>
        ) : (
          <Button size="small" onClick={props.onNext}>
            Next
            {<KeyboardArrowRight />}
          </Button>
        )
      }
      backButton={
        props.step === 0 ? (
          <Link to="/" style={{ textDecoration: "none" }}>
            <Button size="small">
              {<KeyboardArrowLeft />}
              Back to login
            </Button>
          </Link>
        ) : (
          <Button size="small" onClick={props.onPrev}>
            {<KeyboardArrowLeft />}
            Back
          </Button>
        )
      }
    />
  );
};
