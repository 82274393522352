import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import advertActions from "../../actions/adverts";
import { Close, GolfCourse, Photo, Assignment } from "@material-ui/icons";
import {
  Avatar,
  createStyles,
  Divider,
  IconButton,
  List,
  Theme,
  WithStyles,
  ListItem,
  ListItemAvatar,
  Typography,
} from "@material-ui/core";
import { Game, GState } from "../../types/game";
import GameActions from "../../actions/games";
import { ScoreCard } from "../../types/scoreCard";
import "../../styles/material-list-responsive.css";
import { showDialog } from "../SharedComponents/Dialog";
import { showAlertDialog } from "../SharedComponents/AlertDialog";
import Messages from "../../consts/Messages";
import IndividualLeaderBoard from "../leaderBoard/IndividualLeaderBoard";
import Badge from "@material-ui/core/Badge";
import { User } from "../../types/user";
import { Advert, IndexedAdverts } from "../../types/advert";
import AdvertsThimbnails from "../Adverts/AdvertsThumbnails";
import { GameDetails } from "./GameDetails";
import { GlobalState } from "../../types/globalState";

interface Props extends WithStyles {
  getAdverts(page: number, limit: number): void;
  getGamesHistory(page: number, limit: number, filter?: any, sort?: string, dir?: string, query?: string): void;
  removeFromHistory(gameId: string): void;
  adverts: IndexedAdverts;
  personalGames: Game[];
  user: User;
}
interface State {}

class GamesHistory extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
    this.props.getGamesHistory(1, 25, null, "date", "desc");
  }

  handleShowAdsClick = (advert: Advert, gameId?: string) => {
    if (!gameId) return;
    showDialog(<AdvertsThimbnails adverts={advert} gameId={gameId} />, "xl", false, true, true);
  };

  onRemoveGame = (gameId: string) => {
    showAlertDialog(Messages.removingGame.title, Messages.removingGame.message, () => {
      this.props.removeFromHistory(gameId);
    });
  };

  renderActions(game: Game) {
    const { adverts } = this.props;
    const scoreCard: ScoreCard = Object.values(game.scoreCards)[0];
    const advert = Object.values(adverts).find((x: Advert) => x.scoreCard && x.scoreCard.id === scoreCard._id);

    return (
      <div className={"col"}>
        {this.amIorganizer(game) ? null : (
          <IconButton style={{ padding: 7 }} onClick={() => this.onRemoveGame(game._id || "")}>
            <Close />
          </IconButton>
        )}
        {advert && (
          <IconButton size="medium" onClick={() => this.handleShowAdsClick(advert, game._id)}>
            <Photo />
          </IconButton>
        )}
        <IconButton
          onClick={() => showDialog(<IndividualLeaderBoard finalCheck gameId={game._id} />, "xl", false, false, true)}
        >
          <Assignment />
        </IconButton>
      </div>
    );
  }

  amIorganizer = (game: Game) => {
    return game.organizers.filter(o => o._id === this.props.user._id).length > 0;
  };

  renderAvatar(game: Game) {
    if (this.amIorganizer(game))
      return (
        <Badge badgeContent={"Org"} color="primary" anchorOrigin={{ vertical: "top", horizontal: "left" }}>
          <Avatar style={{ backgroundColor: "#8f8b8f" }}>
            <GolfCourse />
          </Avatar>
        </Badge>
      );
    else
      return (
        <Avatar style={{ backgroundColor: "#8f8b8f" }}>
          <GolfCourse />
        </Avatar>
      );
  }

  renderItem(game: Game) {
    if (typeof game !== "object" || !game) return null;

    return (
      <React.Fragment key={game._id}>
        <ListItem style={{ alignItems: "flex-start" }}>
          <ListItemAvatar style={{ margin: "16px 0 0 4px" }}>{this.renderAvatar(game)}</ListItemAvatar>
          <div className={this.props.classes.cardDescContainer}>
            <GameDetails game={game} hideTeeInfo />
            {this.renderActions(game)}
          </div>
        </ListItem>
        <Divider component="li" />
      </React.Fragment>
    );
  }

  render() {
    const { classes, personalGames } = this.props;
    if (personalGames.length === 0)
      return (
        <div className={classes.emptyHistory}>
          <Typography variant="body2" gutterBottom>
            You have no game history yet.
          </Typography>
        </div>
      );

    return (
      <List className={classes.paper}>
        {personalGames.sort((a: any, b: any) => b.date - a.date).map((game: Game) => this.renderItem(game))}
      </List>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      backgroundColor: "rgba(255, 255, 255, 1)",
    },
    cardDescContainer: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    emptyHistory: {
      textAlign: "center",
      padding: 20,
      color: "#888888",
    },
  });

const mapStateToProps = (state: GlobalState) => {
  const { user } = state.auth;
  const { history } = state.games;
  const { adverts } = state.adverts;

  return {
    adverts,
    user,
    personalGames: history ? Object.values(history.items) : [],
  };
};

export default connect(mapStateToProps, {
  getGamesHistory: GameActions.getGamesHistory,
  removeFromHistory: GameActions.removeFromHistory,
  getAdverts: advertActions.fetch,
})(withStyles(styles)(GamesHistory));
