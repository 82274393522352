import { List, ListItem, ListItemText } from "@material-ui/core";
import React from "react";

export interface Item {
  title: string;
  onClick(): void;
}

interface Props {
  items: Item[];
}

export const ItemSelector = (props: Props) => {
  return (
    <div style={{ minWidth: 400 }}>
      <List>
        {props.items.map(item => (
          <ListItem button onClick={item.onClick}>
            <ListItemText primary={item.title} />
          </ListItem>
        ))}
      </List>
    </div>
  );
};
