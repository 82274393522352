import { createStyles, Paper, Tab, Tabs, Theme, WithStyles } from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { GlobalState } from "../../types/globalState";
import JoinedGamesList from "../Game/JoinedGamesList";
import GamesHistory from "../Game/GamesHistory";
import { User } from "./../../types/user";
import TournamentHistory from "../Tournament/TournamentHistory";

interface Props extends WithStyles {
  user: User;
}
interface State {
  gameId?: string;
  tabIndex: number;
}

class GamesTabs extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      tabIndex: 0,
    };
  }

  _onTabChanged = (event: object, value: any) => {
    this.setState({ tabIndex: value });
  };

  renderTabsContent() {
    switch (this.state.tabIndex) {
      case 0:
        return <JoinedGamesList />;
      case 1:
        return <GamesHistory />;
      case 2:
        return <TournamentHistory />;
    }
  }

  render() {
    const { tabIndex } = this.state;
    return (
      <div>
        <Paper square>
          <Tabs
            variant={"fullWidth"}
            indicatorColor="primary"
            textColor="primary"
            value={tabIndex}
            onChange={this._onTabChanged}
          >
            <Tab label={"Current Games"} value={0} />
            <Tab label={"Game History"} value={1} />
            <Tab label={"Tournaments"} value={2} />
          </Tabs>
          {this.renderTabsContent()}
        </Paper>
      </div>
    );
  }
}

const styles = (theme: Theme) => createStyles({});

const mapStateToProps = (state: GlobalState) => {
  const { user } = state.auth;
  return {
    user,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(GamesTabs));
