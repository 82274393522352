import React, { Component } from "react";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core";

class UnderConstruction extends Component<WithStyles, {}> {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <img className={classes.image} src={require("../assets/images/under-construction.jpg")} />
      </div>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
    },
    image: {
      width: "60%",
    },
  });

export default withStyles(styles)(UnderConstruction);
