import React from "react";
import Snackbar from "@material-ui/core/Snackbar";

let openSnackbarFn = (msg: string, duration: number, type?: string) => {};

interface State {
  open: boolean;
  message: string;
  duration: number;
  type: string;
}

class Notifier extends React.Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      open: false,
      message: "",
      duration: 6000,
      type: "",
    };
  }

  componentDidMount() {
    openSnackbarFn = this.openSnackbar;
  }
  openSnackbar = (message: string, duration: number, type: string = "") => {
    this.setState({
      open: true,
      message,
      duration,
      type,
    });
  };
  handleSnackbarClose = () => {
    this.setState({
      open: false,
      message: "",
    });
  };

  render() {
    let { message, duration, open, type } = this.state;

    return (
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        message={<span id="snackbar-message-id" dangerouslySetInnerHTML={{ __html: message }} />}
        autoHideDuration={duration}
        onClose={this.handleSnackbarClose}
        open={open}
        className={`custom-snackbar ${type === "success" ? "green" : ""}`}
      />
    );
  }
}
export function showSnackbar(message: string, duration: number = 3000, type: string = "") {
  openSnackbarFn(message, duration, type);
}

export default Notifier;
