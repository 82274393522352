import React from "react";
import { Toolbar, makeStyles, Theme, Typography } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import moment from "moment";
import { ADVERT_VALUES } from "consts";

interface Props {
  name: string;
  date: number;
  code: string;
  isFinalScore: boolean;
  widescreen: boolean;
}
export default function LeaderboardToolbar(props: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const landscape = useMediaQuery(theme.breakpoints.up("md"));

  const { name, code, date, isFinalScore, widescreen } = props;

  return (
    <Toolbar
      style={
        landscape
          ? {
              flexWrap: "wrap",
              marginTop: widescreen ? ADVERT_VALUES.HEIGHT : 0,
              marginRight: widescreen ? ADVERT_VALUES.SIDEBAR_WIDTH : 0,
              padding: "1rem",
              marginBottom: "1rem",
            }
          : {}
      }
    >
      {landscape ? (
        <>
          <Typography variant="h1" className={classes.title}>
            {name}
          </Typography>
          <Typography style={{ display: "flex", justifyContent: "space-between", flexBasis: "100%" }}>
            <strong style={{ flex: 1 }}>{code}</strong>
            <strong>{moment(date).format("ll")}</strong>
            <strong className={classes.status}>{isFinalScore ? "Final Result" : "Live Score"}</strong>
          </Typography>
        </>
      ) : (
        <>
          <strong className={classes.title}>{name}</strong>
          <h3 className={classes.header}>Leaderboard</h3>
          <strong className={classes.status}>{isFinalScore ? "Final Result" : "Live Score"}</strong>
        </>
      )}
    </Toolbar>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    flex: 1,
    textAlign: "left",

    [theme.breakpoints.up("md")]: {
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "1.4rem",
      marginBottom: 15,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.35em",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "2em",
    },
  },
  header: {
    flex: 1,
    textAlign: "center",
  },
  status: {
    color: "#33a136",
    flex: 1,
    textAlign: "right",
  },
}));
