import React from "react";
import { Dialog, IconButton, Typography } from "@material-ui/core";
import DuoIcon from "@material-ui/icons/Duo";

import { useSelector } from "hooks/useSelector";
import DialogToolbar from "./DialogToolbar";

interface VideoPopupProps {
  videoURL?: string;
  styles?: Record<string, unknown>;
}

const VideoPopup: React.FC<VideoPopupProps> = ({ videoURL, styles = {} }) => {
  const [open, setOpen] = React.useState(false);
  const videoHelp = useSelector(state => state.general.videoHelp);

  return (
    <>
      <IconButton
        style={{ color: "rgb(255 0 255)", ...(videoHelp ? {} : { opacity: 0 }), ...styles }}
        edge="end"
        onClick={setOpen.bind(null, true)}
        disabled={!videoHelp}
      >
        <DuoIcon />
      </IconButton>

      <Dialog open={open} disableEnforceFocus onBackdropClick={setOpen.bind(null, false)} maxWidth={false}>
        <DialogToolbar border onClick={setOpen.bind(null, false)} />

        <div className="video-container">
          {videoURL ? (
            <iframe
              src={`${videoURL}?autoplay=1`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          ) : (
            <Typography style={{ textAlign: "center" }} variant="h4">
              Video coming soon
            </Typography>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default VideoPopup;
