import { Action } from "../types/actions";
import ReduxTypes from "../consts/ReduxTypes";
import { CompetitionState } from "../types/competition";
import { deleteReducer, fetchReducer, insertReducer, updateReducer } from "./BaseReducerFunctions";

const INITIAL_STATE = {
  competitions: {},
  count: 0,
};
const KEY = "COMPETITION";
export default (state: CompetitionState = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case ReduxTypes.getInsertType(KEY):
      return insertReducer(state, action, "competitions");

    case ReduxTypes.getFetchType(KEY):
      return fetchReducer(state, action, "competitions");

    case ReduxTypes.getDeleteType(KEY):
      const { id, active } = action.payload;
      const cmp = state.competitions[id];
      return {
        ...state,
        competitions: { ...state.competitions, [id]: { ...cmp, active } },
      };

    case ReduxTypes.getUpdateType(KEY):
      return updateReducer(state, action, "competitions");

    case ReduxTypes.PURGE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
