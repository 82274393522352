import React, { Component } from "react";
import { Button, Dialog, DialogContent, LinearProgress } from "@material-ui/core";
import { TextInput } from "../SharedComponents/TextInput";
import mailerApi from "../../api/mailer";
import { connect } from "react-redux";
import { GlobalState } from "../../types/globalState";

interface Props {
  loading: boolean;
}

interface State {
  [index: string]: any;
  open: boolean;
  subject: string;
  text: string;
  to: string;
  userId: string;
}

let showEmailDialog = (subject: string, to: string, userId: string) => {};
let hideEmailDialog = () => {};

class EmailDialog extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      open: false,
      subject: "",
      text: "",
      userId: "",
      to: "",
    };
  }

  handleTextFieldChange = (value: string, name: string) => {
    this.setState({ [name]: value });
  };

  showEmailDialog = (subject: string, to: string, userId: string) => {
    this.setState({
      open: true,
      subject,
      to,
      userId,
    });
  };
  hideEmailDialog = () => {
    this.setState({ open: false });
  };
  sendEmail = async () => {
    const { userId, subject, text } = this.state;
    const res = await mailerApi.send(userId, subject, text);
    if (res) {
      this.hideEmailDialog();
    }
  };

  componentDidMount(): void {
    showEmailDialog = this.showEmailDialog;
    hideEmailDialog = this.hideEmailDialog;
  }

  render() {
    return (
      <Dialog open={this.state.open}>
        <DialogContent
          style={{
            marginBottom: 10,
            minWidth: 400,
          }}
        >
          <div>
            To:&nbsp;
            <label>{this.state.to}</label>{" "}
          </div>
          <br />
          <div>
            <TextInput
              fullWidth
              value={this.state.subject}
              label="Subject to read"
              variant={"outlined"}
              name={"subject"}
              onChange={this.handleTextFieldChange}
            />
          </div>
          <br />
          <div>
            <TextInput
              fullWidth
              value={this.state.text}
              label="Text"
              variant={"outlined"}
              name={"text"}
              onChange={this.handleTextFieldChange}
              multiline
              rows={3}
            />
          </div>
          <br />
          <div style={{ textAlign: "right", width: "100%" }}>
            {!this.props.loading ? (
              <>
                <Button variant="contained" onClick={this.hideEmailDialog} color={"primary"}>
                  Cancel
                </Button>
                &nbsp;
                <Button variant="contained" onClick={this.sendEmail} color={"secondary"}>
                  Send
                </Button>
              </>
            ) : (
              <div>
                <LinearProgress color="primary" variant={"indeterminate"} />
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state: GlobalState) => {
  const { loading } = state.general;
  return {
    loading,
  };
};

export default connect(mapStateToProps)(EmailDialog);

export { showEmailDialog };
