import { Action } from "../types/actions";
import ReduxTypes from "../consts/ReduxTypes";
import { ScoreCardState } from "../types/scoreCard";
import { deleteReducer, fetchReducer, insertReducer, updateReducer } from "./BaseReducerFunctions";

const INITIAL_STATE = {
  scoreCards: {},
  clubScoreCards: [],
  count: 0,
};
const KEY = "SCORECARD";

export default (state: ScoreCardState = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case ReduxTypes.getInsertType(KEY):
      return insertReducer(state, action, "scoreCards");
    case ReduxTypes.getFetchType(KEY):
      return fetchReducer(state, action, "scoreCards");
    case ReduxTypes.getDeleteType(KEY):
      return deleteReducer(state, action, "scoreCards");
    case ReduxTypes.getUpdateType(KEY):
      return updateReducer(state, action, "scoreCards");

    case ReduxTypes.PURGE:
      return INITIAL_STATE;

    case ReduxTypes.FETCH_CLUB_SCORECARDS: {
      return { ...state, clubScoreCards: [...action.payload] };
    }
    default:
      return state;
  }
};
