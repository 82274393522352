import React, { Component, ReactElement } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Checkbox,
  createStyles,
  Dialog,
  DialogContent,
  IconButton,
  InputAdornment,
  InputBase,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  WithStyles,
} from "@material-ui/core";
import { Close, Search as SearchIcon } from "@material-ui/icons";
import DialogToolbar from "components/SharedComponents/DialogToolbar";

interface Props extends WithStyles {
  // onSearchTextChanged?(value: string): void;
  searchHandler?(query: string): any;
  onItemSelected?(item?: SearchItem): void;
  selectedItems?: SearchItem[];
  defaultSelectedItem?: SearchItem;
  label: string;
  dialog?: boolean;
  error?: boolean;
  className?: string;
  placeholder: string;
  minChar: number; //Minimum character to call searchPlayers
  autoClearText?: boolean;
  icon?: ReactElement;
  style?: any;
}
interface State {
  searchItems?: SearchItem[];
  selectedItem?: SearchItem;
  searchText: string;
  label: string;
  open: boolean;
}
export interface SearchItem {
  value: string | number;
  title: string;
  subtitle?: string;
  object?: any;
}

class SearchInput extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedItem: props.defaultSelectedItem,
      searchText: "",
      label: "",
      open: false,
    };
  }

  _onSearchInputChanged = (event: any) => {
    const { searchHandler, minChar } = this.props;
    const searchText = event.target.value as string;
    this.setState({ searchText });
    if (searchText.length >= minChar) {
      if (searchHandler) {
        searchHandler(searchText)
          .then((searchItems: SearchItem[]) => {
            this.setState({ searchItems });
          })
          .catch((err: any) => {
            console.log(err);
          });
      }
    } else {
      this.setState({ searchItems: undefined });
    }
  };
  _clearSearchText = () => {
    const { onItemSelected } = this.props;
    this.setState({ searchText: "", searchItems: undefined, selectedItem: undefined });
    if (onItemSelected) {
      onItemSelected(undefined);
    }
  };
  _onClickItem = (item: SearchItem) => {
    const { onItemSelected, autoClearText } = this.props;
    this.setState({
      searchText: item.title,
      selectedItem: item,
      searchItems: undefined,
      open: false,
    });
    if (onItemSelected) {
      onItemSelected(item);
      if (autoClearText) this._clearSearchText();
    }
  };

  _openDialog = () => {
    this.setState({ open: true });
  };
  _closeDialog = () => {
    this.setState({ open: false });
  };

  renderSearchItems() {
    return (
      <List>
        {this.state.searchItems &&
          this.state.searchItems.map((item: SearchItem) => {
            const alreadyAdded =
              this.props.selectedItems && !!this.props.selectedItems.find(s => s.value === item.value);
            return (
              <ListItem button disabled={alreadyAdded} onClick={() => this._onClickItem(item)}>
                <ListItemIcon>
                  <Checkbox edge="start" checked={alreadyAdded} disableRipple />
                </ListItemIcon>
                <ListItemText primary={item.title} secondary={item.subtitle} />
              </ListItem>
            );
          })}
      </List>
    );
  }
  renderSearchInput() {
    const { classes, label } = this.props;
    const { searchText } = this.state;
    return (
      <>
        <InputBase
          value={searchText}
          placeholder={label}
          className={classes.searchInput}
          onChange={this._onSearchInputChanged}
          autoFocus
          inputProps={{ autofocus: true }}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          endAdornment={
            searchText !== "" && (
              <InputAdornment position="end">
                <IconButton onClick={this._clearSearchText}>
                  <Close />
                </IconButton>
              </InputAdornment>
            )
          }
        />
        {this.renderSearchItems()}
      </>
    );
  }

  render() {
    const { classes, className, dialog, placeholder, icon, style } = this.props;
    const { open, selectedItem } = this.state;

    return (
      <div className={[className, classes.container].join(" ")} style={style}>
        {dialog ? (
          <>
            <div className={classes.inline}>
              {selectedItem && selectedItem.title ? (
                <ListItem
                  button
                  onClick={() => {
                    this._clearSearchText();
                    this._openDialog();
                  }}
                >
                  <ListItemIcon>
                    <Close />
                  </ListItemIcon>
                  <ListItemText primary={selectedItem.title} secondary={selectedItem?.subtitle} />
                </ListItem>
              ) : (
                <ListItem button onClick={this._openDialog}>
                  <ListItemIcon>{icon || <SearchIcon />}</ListItemIcon>
                  <ListItemText primary={placeholder} />
                </ListItem>
              )}
            </div>
            <Dialog open={open} onBackdropClick={this._closeDialog}>
              <DialogToolbar onClick={this._closeDialog} border={false} />
              <DialogContent>{this.renderSearchInput()}</DialogContent>
            </Dialog>
          </>
        ) : (
          this.renderSearchInput()
        )}
      </div>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      marginTop: 0,
    },
    inline: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    searchInput: {
      width: 256,
    },
  });

export default withStyles(styles)(SearchInput);
