import BaseApi from "./Api";

class ClubsApi extends BaseApi {
  url = "clubs";
  constructor() {
    super();
    this.setResourceUrl(this.url);
  }

  addToFav = (id: string) => {
    return this.instance({
      method: "put",
      url: `${this.url}/favourite/${id}`,
    });
  };
  removeFromFav = (id: string) => {
    return this.instance({
      method: "delete",
      url: `${this.url}/favourite/${id}`,
    });
  };

  addOrganiser = (userId: string) => {
    return this.instance({
      method: "post",
      url: `${this.url}/addOrganiser/${userId}`,
    });
  };
  removeOrganiser = (userId: string) => {
    return this.instance({
      method: "delete",
      url: `${this.url}/removeOrganiser/${userId}`,
    });
  };

  getEmail = (userId: string) => {
    return this.instance({
      method: "get",
      url: `${this.url}/${userId}/email`,
    });
  };

  getScorecards = (clubId: string) => {
    return this.instance({
      method: "get",
      url: `${this.url}/${clubId}/scorecards`,
    });
  };
  getGames = (clubId: string) => {
    return this.instance({
      method: "get",
      url: `${this.url}/${clubId}/games`,
    });
  };
}

export default new ClubsApi();
