import React, { Component } from "react";
import BorderedBox from "../SharedComponents/BorderedBox";
import {
  IconButton,
  Switch,
  Typography,
  Theme,
  Select,
  MenuItem,
  Chip,
  Dialog,
  WithStyles,
  createStyles,
  Grid,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { GolfCourse } from "@material-ui/icons";
import { PlayingRulesType } from "../../types/competition";
import Config from "../../config";
import BestHoles from "./BestHoles";
import { BestHolesFielld } from "types/competition";
import { HELP_VIDEOS } from "consts";
export interface Props extends WithStyles {
  playingRules: PlayingRulesType;
  holesNumber: number;
  onPlayingRulesChanged: (value: PlayingRulesType) => void;
}

type RuleFields = BestHolesFielld;
export interface State {
  isDialogOpen: boolean;
  selectedField: RuleFields;
}

class CustomGamePlayingFormats extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isDialogOpen: false,
      selectedField: "bestOne",
    };
  }

  onSelectChanged = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    if (!event.target.name) return;
    this.props.onPlayingRulesChanged({
      ...this.props.playingRules,
      [event.target.name]:
        event.target.name === "multiRules" ? event.target.value === 1 : (event.target.value as string),
    });
  };

  onSwitchChanged = (field: string, event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onPlayingRulesChanged({ ...this.props.playingRules, [field]: event.target.checked as boolean });
  };

  onTopScoresTextChanged = (field: string, value: number[]) => {
    this.props.onPlayingRulesChanged({ ...this.props.playingRules, [field]: value });
  };

  openDialog = (selectedItem: RuleFields) => this.setState({ isDialogOpen: true, selectedField: selectedItem });

  closeDialog = () => this.setState({ isDialogOpen: false });

  handleHoleClicked = (hole: number) => {
    const { playingRules: rules } = this.props;
    const { selectedField } = this.state;
    // All button
    if (hole === -1) {
      const holes = Array.from({ length: this.props.holesNumber }, (_, i) => i + 1);
      return this.onTopScoresTextChanged(selectedField, holes);
    }

    const values = rules[selectedField] || [];
    this.onTopScoresTextChanged(
      selectedField,
      values.includes(hole) ? values.filter(x => x != hole) : values.concat(hole),
    );
  };

  onTeamRulesChange = (index: number) => (evt: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const minimumTeeShots = Array.isArray(this.props.playingRules.minimumTeeShots)
      ? this.props.playingRules.minimumTeeShots
      : [];

    minimumTeeShots[index] = +(evt.target.value as number);

    this.props.onPlayingRulesChanged({ ...this.props.playingRules, minimumTeeShots });
  };

  render() {
    const { classes } = this.props;
    const { playingRules: rules } = this.props;

    const bestScoreQuestions = [
      { title: "Best 1 scores on holes :", field: "bestOne" },
      { title: "Best 2 scores on holes :", field: "bestTwo" },
      { title: "Best 3 scores on holes :", field: "bestThree" },
      { title: "All 4 scores on holes :", field: "bestFour" },
    ];

    return (
      <BorderedBox title={"Playing Rules"} variant={"h6"} videoIcon videoURL={HELP_VIDEOS.PLAYING_RULES}>
        <div className={classes.inline}>
          <Typography variant={"subtitle1"}>What playing format is it: </Typography>
          <Select name="playingFormat" value={rules.playingFormat} onChange={this.onSelectChanged}>
            <MenuItem value="individual">Individual</MenuItem>
            <MenuItem value="matchplay">Matchplay</MenuItem>
            <MenuItem value="combined">Combined</MenuItem>
          </Select>
        </div>
        <div className={classes.inline}>
          <Typography variant={"subtitle1"}>Players play as: </Typography>
          <Select name="playingAs" value={rules.playingAs} onChange={this.onSelectChanged}>
            <MenuItem value="individual">Individual</MenuItem>
            <MenuItem value="team">Team</MenuItem>
          </Select>
        </div>
        <div className={classes.inline}>
          <Typography variant={"subtitle1"}>If a team game, how many players per team: </Typography>
          <Select name="teamPlayers" value={rules.teamPlayers} onChange={this.onSelectChanged}>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={6}>6</MenuItem>
          </Select>
        </div>
        <div className={classes.inline}>
          <Typography variant={"subtitle1"}>How many teams allowed per tee: </Typography>
          <Select name="teamsPerTee" value={rules.teamsPerTee} onChange={this.onSelectChanged}>
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            {rules.teamPlayers === 2 && <MenuItem value={3}>3</MenuItem>}
          </Select>
        </div>

        {rules.playingAs === Config.PLAYING_FORMATS.TEAM && (
          <div className={classes.inline}>
            <Typography variant={"subtitle1"}>Are there different rules for numbers of players in a team? </Typography>
            <Select name="multiRules" onChange={this.onSelectChanged} value={rules.multiRules ? 1 : 0}>
              <MenuItem value={1}>Yes</MenuItem>
              <MenuItem value={0}>No</MenuItem>
            </Select>
          </div>
        )}

        {rules.multiRules ? (
          Array.from(Array(rules.teamPlayers - 1)).map((_, i) => (
            <div key={i} className={`${classes.borderBox} ${classes.inline}`}>
              <Typography variant={"subtitle1"}>
                <span>Minimum number of tee shots that a player has to use in a</span>
                <span style={{ color: "rgb(15 200 30" }}>{` ${i + 2} player `}</span>
                <span>team</span>
              </Typography>
              <Select
                value={Array.isArray(rules.minimumTeeShots) ? rules.minimumTeeShots[i] : ""}
                onChange={this.onTeamRulesChange(i)}
              >
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={7}>7</MenuItem>
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={9}>9</MenuItem>
              </Select>
            </div>
          ))
        ) : (
          <div className={classes.inline}>
            <Typography variant={"subtitle1"}>Minimum number of tee shots that a player has to use: </Typography>
            <Select name="minimumTeeShots" value={rules.minimumTeeShots} onChange={this.onSelectChanged}>
              <MenuItem value={0}>0</MenuItem>
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={6}>6</MenuItem>
              <MenuItem value={7}>7</MenuItem>
              <MenuItem value={8}>8</MenuItem>
              <MenuItem value={9}>9</MenuItem>
            </Select>
          </div>
        )}

        {rules.playingAs === Config.PLAYING_FORMATS.TEAM ? (
          <>
            {bestScoreQuestions.map((option, index) => {
              const fieldName = option.field as BestHolesFielld;
              return (
                <Grid container key={index}>
                  <Grid item md={6} className={classes.bestHoleText}>
                    <Typography variant={"subtitle1"}>{option.title} </Typography>
                  </Grid>
                  <Grid item md={6}>
                    <IconButton
                      color="primary"
                      aria-label="Select Holes"
                      component="span"
                      onClick={() => this.openDialog(fieldName)}
                    >
                      <GolfCourse />
                    </IconButton>
                  </Grid>
                  <Grid item md={12} className={classes.holesList}>
                    {rules[fieldName]?.map((x, index) => (
                      <>
                        <Chip
                          key={index}
                          className={classes.hole}
                          style={{ background: "#009e0f", color: "white" }}
                          label={x}
                        />
                        {index === 8 ? <br /> : null}
                      </>
                    ))}
                  </Grid>
                </Grid>
              );
            })}
            <div className={classes.inline}>
              <Typography variant={"subtitle1"}>Holes related to: </Typography>
              Holes played
              <Switch
                classes={{
                  thumb: classes.thumb,
                  track: classes.track,
                }}
                name="isRelatedToCourseHoles"
                value={rules.isRelatedToCourseHoles}
                checked={rules.isRelatedToCourseHoles}
                onChange={e => this.onSwitchChanged(e.target.name, e)}
              />
              Course holes
            </div>
          </>
        ) : null}

        <Dialog open={this.state.isDialogOpen} onBackdropClick={this.closeDialog}>
          <BestHoles
            holesCount={this.props.holesNumber}
            selectedHoles={rules[this.state.selectedField]}
            onHoleClicked={this.handleHoleClicked}
            onSubmit={this.closeDialog}
          />
        </Dialog>
      </BorderedBox>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    inline: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    borderBox: {
      borderBottom: "1px dashed #aaa",
      margin: "1rem 0",
      padding: "0.5rem 0",
    },
    thumb: {
      color: theme.palette.secondary.main,
    },
    holesList: {
      textAlign: "left",
      padding: 5,
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
    },
    hole: {
      marginRight: 2,
      marginTop: 2,
      width: 37,
      height: 37,
      borderRadius: "50%",

      [theme.breakpoints.down("xs")]: {
        width: 50,
        height: 50,
      },
    },
    track: {
      backgroundColor: theme.palette.secondary.main,
    },
    bestHoleText: {
      marginRight: "auto",
    },
    input: {
      "& > input": {
        textAlign: "center",
      },
    },
  });

export default withStyles(styles)(CustomGamePlayingFormats);
