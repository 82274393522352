import React, { Component } from "react";
import { connect } from "react-redux";
import { Game, GState, IndexedGames } from "../../types/game";
import { Views } from "../Dashboard";
import { GlobalState } from "../../types/globalState";
import { User, UserBrifObj } from "../../types/user";
import { hideAuxiliaryDialog, showAuxiliaryDialog } from "../SharedComponents/AuxiliaryDialog";
import { showAlertDialog } from "../SharedComponents/AlertDialog";
import { switchComponent } from "../../actions/componentRouter";
import GameActions from "../../actions/games";
import GamePlayersManager from "./GamePlayersManager";
import GameAdditionalOrganisersManager from "./GameAdditionalOrganisersManager";
import IndividualLeaderBoard from "../leaderBoard/IndividualLeaderBoard";
import QuickScorePlayersList from "../QuickScore/QuickScorePlayersList";
import Moment from "moment";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import withStyles from "@material-ui/core/styles/withStyles";
import { Grid, WithStyles, createStyles, Theme, IconButton, Button, ListItemSecondaryAction } from "@material-ui/core";
import {
  Assignment,
  ExitToApp,
  PersonAdd,
  PlayCircleFilled,
  SupervisorAccount,
  Share,
  Link,
  PictureAsPdf,
  Email,
  Edit,
} from "@material-ui/icons";
import CropFreeIcon from "@material-ui/icons/CropFree";
import PenIcon from "@material-ui/icons/BorderColor";
import { hideDialog } from "../SharedComponents/Dialog";
import { AxiosError } from "axios";
import { ErrorOnlyCallback } from "../../types/actions";
import Loading from "../Loading";
import { share } from "../../utils/share";
import Messages from "../../consts/Messages";
import SubmissionCardConfirmtionDialog from "./SubmissionCardConfirmtionDialog";
import EmailDialog, { showEmailDialog } from "../SharedComponents/EmailDialog";
import LinkedGamesList from "./LinkedGamesList";
import { isCombinedGame } from "./../../utils/index";
import { StartSheet } from "./GameStartSheet";
import { Player, PayStatus } from "../../types/player";
import { Rules } from "types/user";
import _ from "lodash";
import GamePayment from "../Payment/GamePayment";
import PaymentMethods from "../Payment/PaymentMethods";
import GuestPlayersTable from "./GuestPlayers";
import GameSetup from "./GameSetup";
import config from "config";
import { EmailTypes } from "types/general";
import QRCode from "components/QRCode";
import EmailOptionsDialog from "components/SharedComponents/EmailOptions";
import VideoPopup from "components/SharedComponents/VideoPopup";
import { HELP_VIDEOS } from "consts";

interface Props extends WithStyles {
  getGameById(
    id: string,
    queryType?: string,
    updateRedux?: boolean,
    callback?: (err: AxiosError | null, data: Game) => void,
  ): void;
  switchComponent(view: any, props?: any): void;
  activateGame(id: string): void;
  joinGame(id: string, callback?: (error: AxiosError | null, data: Game[]) => void): void;
  leaveGame(id: string, callback?: ErrorOnlyCallback): void;
  games: IndexedGames;
  gameId: string;
  user: User;
}

interface State {
  openQuickscoriePlayersListDialog: boolean;
  isGameFull: boolean;
  emailOpen: boolean;
}

class GameNavigationDialog extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { openQuickscoriePlayersListDialog: false, isGameFull: false, emailOpen: false };
    this.props.getGameById(props.gameId, undefined, true);
  }

  onLeaveGame(gameId: string) {
    showAlertDialog(
      "Leave game",
      "You are about to leave the game, your current scores will be deleted, Are you sure?",
      () => {
        this.props.leaveGame(gameId);
        hideDialog();
      },
      () => hideDialog(),
    );
  }

  onOpenLarboard = (gameId: string, widescreen?: boolean) => {
    showAuxiliaryDialog(<IndividualLeaderBoard gameId={gameId} />, "xl", true, true, true, "", widescreen);
  };

  onEnterGame(gameId: string) {
    const userId = this.props.user!._id;
    const game = this.getGame();
    if (game.players[userId]) {
      const group = game.players[userId].group;
      if (group) {
        this.props.switchComponent(Views.GameManager, { gameId, group });
        hideDialog();
      } else {
        this.props.switchComponent(Views.GroupSelector, { gameId });
        hideDialog();
      }
    }
  }

  imPlaying(game: Game) {
    return !!game.players[this.props.user._id];
  }

  myPlayerObject(game: Game) {
    return game.players[this.props.user._id];
  }

  getGame = () => {
    return this.props.games[this.props.gameId];
  };

  sendNotifierMail = () => {
    const game = this.getGame();

    showAuxiliaryDialog(
      <SubmissionCardConfirmtionDialog
        gameId={game._id}
        homeClub={this.props.user.homeClub}
        gameClub={game.course.club}
      />,
      "xl",
      true,
    );
  };

  handlePGComponentSwitch = () => {
    const game = this.getGame();
    if (!game) return;
    hideAuxiliaryDialog();
    const imOrganiser = game.organizers.find(o => o._id === this.props.user._id) !== undefined;

    showAuxiliaryDialog(
      <StartSheet
        gameId={game._id}
        games={this.props.games}
        downloadable={imOrganiser}
        onSwitchClick={this.handleSSComponentSwitch}
      />,
      "xl",
      true,
      true,
      true,
      `${game.name} Start Sheet`,
    );
  };

  handleSSComponentSwitch = () => {
    const game = this.getGame();
    if (!game) return;
    hideAuxiliaryDialog();

    showAuxiliaryDialog(
      <GamePlayersManager onSwitchClick={this.handlePGComponentSwitch} gameId={game._id} minChar={3} />,
      "lg",
      true,
      true,
      true,
    );
  };

  onOpenScorerSetup = () => {
    const userId = this.props.user._id;
    const game = this.getGame();
    this.props.switchComponent(Views.ScorerGameSetup, {
      gameId: game._id,
      group: game?.players[userId].group,
    });
    hideDialog();
  };

  showEmailNotify = () => {
    const game = this.getGame();
    const organizer = game.organizers[0];
    showEmailDialog(`Game Code ${game.code}`, `${organizer.name} ${organizer.lastName}`, organizer._id);
  };

  onJoinGame = (gameId: string) => {
    const { isGameFull } = this.state;
    if (isGameFull) return;
    this.props.joinGame(gameId, error => {
      if (error) {
        if (error?.response?.data.payload?.isFull) {
          this.setState({ isGameFull: true });
          showAlertDialog(
            "Ooops !",
            "The game is full, Do you want to send an Email to the game Organiser?",
            () => this.showEmailNotify(),
            () => {},
          );
        }
      } else hideDialog();
    });
  };

  isGameFinished = () => {
    const game = this.getGame();
    const { user } = this.props;
    if (!this.imPlaying(game)) return false;
    const me = game.players[user._id];
    if (me.group) return this.areAllCardsSubmited(this.getTeammates(me.group.name));
  };

  getTeammates = (teamName: String) => {
    const game = this.getGame();
    return Object.values(game.players).filter(player => player.group?.name === teamName);
  };

  onGuestClick = (game: Game) => {
    showAuxiliaryDialog(
      <PaymentMethods
        onClick={(type: "guest" | "paid") => {
          hideAuxiliaryDialog();
          showAuxiliaryDialog(<GamePayment type={type} game={game} />, "xl", true, true, true);
        }}
      />,
      "xl",
      true,
      true,
      true,
    );
  };

  areAllCardsSubmited = (players: Player[]) => players.every(player => player.state === "done");

  filterGuestPlayers = (currentUser: User, guests: Player[], organizers: UserBrifObj[], player: Player) => {
    // Organiser has access to all guests
    if (currentUser.rule === Rules.Organiser) {
      const org = organizers.find(o => o._id === currentUser._id);
      if (org) return guests;
    }

    if (!player?.group?.name) return [];

    const userGroup = player.group.name;
    return guests.filter(p => p.group?.name === userGroup);
  };

  render() {
    const { user, classes } = this.props;
    const game = this.getGame();
    if (!game) return null;
    const imPlaying = this.imPlaying(game);
    const player = this.myPlayerObject(game);
    const guestPlayers = this.filterGuestPlayers(
      user,
      Object.values(game.players).filter(p => p.guest),
      game.organizers,
      player,
    );
    const date = Moment(game.date);
    const { isGameFull } = this.state;
    const isCombined = isCombinedGame(game.competition);
    const imOrganiser = game.organizers!.filter((o: UserBrifObj) => o._id === user._id).length > 0;
    const imOwner = game.owner === user._id;
    const shouldPayBeforePlay = game.paymentRules?.payBeforePlay && player?.payStatus === PayStatus.NOT_PAID;

    return (
      <div>
        <Loading />
        <List>
          <ListItem>
            <Grid container direction={"row"}>
              <Grid item className={classes.centeredText} xs={4}>
                <ListItemText primary={game.name} />
              </Grid>
              <Grid item className={classes.centeredItem} xs={4}>
                {imPlaying && game.state === GState.Playing && (
                  <Button
                    disabled={this.isGameFinished() || shouldPayBeforePlay}
                    color="primary"
                    variant="contained"
                    className={classes.playButton}
                    onClick={() => this.onEnterGame(game._id)}
                  >
                    <div className="col flex-center">
                      <PlayCircleFilled color={"inherit"} />
                      <span>Play</span>
                    </div>
                  </Button>
                )}
              </Grid>
              <Grid item className={classes.centeredText} xs={4}>
                <ListItemText primary={game.code} secondary={date.format("ll")} />
              </Grid>
            </Grid>
          </ListItem>
          {!isCombined && game.state === GState.Playing && !shouldPayBeforePlay && (
            <ListItem
              button
              onClick={() =>
                game._id && showAuxiliaryDialog(<QuickScorePlayersList gameId={game._id} />, "xl", true, true, true)
              }
            >
              <IconButton className={classes.qsLabel}>QS</IconButton>
              <ListItemText className={"fullWidth"}>Quick Score - Quickly enter scores</ListItemText>
              <ListItemSecondaryAction>
                <VideoPopup videoURL={HELP_VIDEOS.GAME_MENU_QUICK} />
              </ListItemSecondaryAction>
            </ListItem>
          )}
          {game.state !== GState.WaitingList && game.isPaid && (
            <>
              <ListItem
                button
                onClick={() => showAuxiliaryDialog(<GamePayment type="pay" game={game} />, "xl", true, true, true)}
              >
                <IconButton className={classes.qsLabel}>&#163;</IconButton>
                <ListItemText className={"fullWidth"}>Payment</ListItemText>
                <ListItemSecondaryAction>
                  <VideoPopup videoURL={HELP_VIDEOS.GAME_MENU_PAYMENT} />
                </ListItemSecondaryAction>
              </ListItem>
              {(imOwner || imOrganiser) && (
                <ListItem button onClick={() => this.onGuestClick(game)}>
                  <IconButton className={classes.qsLabel}>G</IconButton>
                  <ListItemText className={"fullWidth"}>Guests &#38; other paid methods</ListItemText>
                  <ListItemSecondaryAction>
                    <VideoPopup videoURL={HELP_VIDEOS.GAME_MENU_PAYMENT_2} />
                  </ListItemSecondaryAction>
                </ListItem>
              )}
            </>
          )}
          {game.state !== GState.Done && !shouldPayBeforePlay && (
            <ListItem
              button
              onClick={() =>
                game._id &&
                showAuxiliaryDialog(
                  <StartSheet
                    gameId={game._id}
                    games={this.props.games}
                    downloadable={imOrganiser}
                    onSwitchClick={this.handleSSComponentSwitch}
                  />,
                  "xl",
                  true,
                  true,
                  true,
                  `${game.name} Start Sheet`,
                  game.startSheet?.widescreen,
                )
              }
            >
              <IconButton className={classes.qsLabel}>
                <PictureAsPdf />
              </IconButton>
              <ListItemText className={"fullWidth"}>Start Sheet</ListItemText>
            </ListItem>
          )}
          {game.state !== GState.Done && guestPlayers.length > 0 && !shouldPayBeforePlay && (
            <ListItem
              button
              onClick={() =>
                game._id &&
                showAuxiliaryDialog(
                  <GuestPlayersTable players={guestPlayers} gameId={game._id} />,
                  "xl",
                  true,
                  true,
                  true,
                  "Unregistered Players",
                )
              }
            >
              <IconButton className={classes.qsLabel}>
                <PenIcon />
              </IconButton>
              <ListItemText className={"fullWidth"}>Edit unregistered players</ListItemText>
            </ListItem>
          )}
          {imOrganiser && (
            <ListItem
              button
              onClick={() =>
                game._id &&
                showAuxiliaryDialog(<GameSetup edit={game} onlyPresentationConfig auxiliary />, "sm", true, true, true)
              }
            >
              <IconButton className={classes.qsLabel}>
                <Edit />
              </IconButton>
              <ListItemText className={"fullWidth"}>Edit presentation</ListItemText>
              <ListItemSecondaryAction>
                <VideoPopup videoURL={HELP_VIDEOS.GAME_MENU_PRESENTATION} />
              </ListItemSecondaryAction>
            </ListItem>
          )}
          {game.state !== GState.Done && imOrganiser && (
            <>
              <ListItem
                button
                onClick={() =>
                  showAuxiliaryDialog(
                    <GamePlayersManager onSwitchClick={this.handlePGComponentSwitch} gameId={game._id} minChar={3} />,
                    "lg",
                    true,
                    true,
                    true,
                  )
                }
              >
                <IconButton>
                  <PersonAdd color="primary" />
                </IconButton>
                <ListItemText className={"fullWidth"}>Players & Groups</ListItemText>
                <ListItemSecondaryAction>
                  <VideoPopup videoURL={HELP_VIDEOS.GAME_MENU_PLAYERS} />
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem
                button
                onClick={() =>
                  showAuxiliaryDialog(<GameAdditionalOrganisersManager game={game} />, "lg", true, true, true)
                }
              >
                <IconButton>
                  <SupervisorAccount color="primary" />
                </IconButton>
                <ListItemText className={"fullWidth"}>Nominate additonal Organisers</ListItemText>
                <ListItemSecondaryAction>
                  <VideoPopup videoURL={HELP_VIDEOS.GAME_MENU_ORGANISERS} />
                </ListItemSecondaryAction>
              </ListItem>
              {!shouldPayBeforePlay && (
                <>
                  <ListItem
                    button
                    onClick={() =>
                      showAuxiliaryDialog(
                        <LinkedGamesList game={game} />,
                        "lg",
                        true,
                        true,
                        true,
                        `Linked Games with ${game.name}`,
                      )
                    }
                  >
                    <IconButton>
                      <Link color="primary" />
                    </IconButton>
                    <ListItemText className={"fullWidth"}>Linked Games</ListItemText>
                    <ListItemSecondaryAction>
                      <VideoPopup />
                    </ListItemSecondaryAction>
                  </ListItem>

                  {imOrganiser && (
                    <ListItem
                      button
                      onClick={() => {
                        this.setState({ emailOpen: true });
                      }}
                    >
                      <IconButton className={classes.qsLabel}>
                        <Email color={"primary"} />
                      </IconButton>
                      <ListItemText>Email Players</ListItemText>
                      <ListItemSecondaryAction>
                        <VideoPopup videoURL={HELP_VIDEOS.GAME_MENU_EMAIL} />
                      </ListItemSecondaryAction>
                    </ListItem>
                  )}
                </>
              )}
            </>
          )}
          {imOrganiser && game.state === GState.WaitingList && (
            <ListItem button onClick={() => this.props.activateGame(game._id)}>
              <IconButton>
                <PlayCircleFilled color={"secondary"} />
              </IconButton>
              <ListItemText className={"fullWidth"}>Activate the Game</ListItemText>
            </ListItem>
          )}
          {imPlaying && game.state !== GState.Done && !shouldPayBeforePlay && (
            <ListItem button onClick={() => this.onLeaveGame(game._id)}>
              <IconButton>
                <ExitToApp color={"primary"} />
              </IconButton>
              <ListItemText className={"fullWidth"}>Leave the Game</ListItemText>
            </ListItem>
          )}
          {!imPlaying && game.state !== GState.Done && (
            <ListItem button onClick={() => this.onJoinGame(game._id)}>
              <IconButton className={classes.qsLabel}>Join</IconButton>
              {!isGameFull ? (
                <ListItemText className={"fullWidth"}>Join the Game</ListItemText>
              ) : (
                <label className={classes.errorLabel}>GAME FULL</label>
              )}
            </ListItem>
          )}
          {!shouldPayBeforePlay && (
            <>
              {game.leaderboard?.activateStatus === config.LEADERBOARD_CONFIG.ACTIVATED.ORGANIZER_ONLY &&
              !imOrganiser ? null : (
                <ListItem button onClick={() => this.onOpenLarboard(game._id, game.leaderboard?.widescreen)}>
                  <IconButton className={classes.qsLabel}>
                    <Assignment color={"primary"} />
                  </IconButton>
                  <ListItemText>Leaderboard</ListItemText>
                  <ListItemSecondaryAction>
                    <VideoPopup videoURL={HELP_VIDEOS.GAME_MENU_LEADERBOARD} />
                  </ListItemSecondaryAction>
                </ListItem>
              )}

              {game.state !== GState.Done && (
                <>
                  <ListItem
                    button
                    onClick={() => {
                      showAuxiliaryDialog(
                        <QRCode url={`http://schala.golf/join/${game.code}`} game={game} />,
                        "lg",
                        true,
                        true,
                        true,
                      );
                    }}
                  >
                    <IconButton>
                      <CropFreeIcon color="primary" />
                    </IconButton>
                    <ListItemText>Generate QR code</ListItemText>
                    <ListItemSecondaryAction>
                      <VideoPopup videoURL={HELP_VIDEOS.GAME_MENU_QR} />
                    </ListItemSecondaryAction>
                  </ListItem>

                  <ListItem
                    button
                    onClick={() =>
                      share({
                        title: Messages.share.title,
                        text: Messages.share.message,
                        url: `http://schala.golf/join/${game.code}`,
                      })
                    }
                  >
                    <IconButton>
                      <Share color={"primary"} />
                    </IconButton>
                    <ListItemText>Share this game</ListItemText>
                    <ListItemSecondaryAction>
                      <VideoPopup videoURL={HELP_VIDEOS.GAME_MENU_SHARE} />
                    </ListItemSecondaryAction>
                  </ListItem>
                </>
              )}
            </>
          )}
          {imPlaying && (
            <ListItem style={{ backgroundColor: "orange", padding: "0 8px" }}>
              <Button
                disabled={
                  shouldPayBeforePlay ||
                  !_.isEmpty(player.submitionEmail) ||
                  game.state === GState.Done ||
                  (player.holes[1] && !!player.holes[1].gross)
                }
                color={"primary"}
                variant={"contained"}
                style={{ width: 170 }}
                onClick={this.sendNotifierMail}
              >
                {_.isEmpty(player.submitionEmail) ? "CARD FOR HANDICAP" : "Sent"}
              </Button>
              <ListItemText className={"fullWidth"} style={{ padding: 8 }}>
                {shouldPayBeforePlay ? (
                  "Please pay your fee before playing in this game !"
                ) : (
                  <small>Notify this club and home club that you wish to submit this card for handicap purposes.</small>
                )}
              </ListItemText>
              <ListItemSecondaryAction style={{ top: "70%" }}>
                <VideoPopup videoURL={HELP_VIDEOS.GAME_MENU_CARD} />
              </ListItemSecondaryAction>
            </ListItem>
          )}
        </List>
        <EmailDialog />

        <EmailOptionsDialog
          options={[EmailTypes.START_SHEET, EmailTypes.EMPTY]}
          game={game}
          user={this.props.user}
          gamesObj={this.props.games}
          open={this.state.emailOpen}
          onClose={() => {
            this.setState({ emailOpen: false });
          }}
        />
      </div>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    centeredText: {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      textAlign: "center",
    },
    centeredItem: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    qsLabel: {
      color: theme.palette.primary.main,
      fontSize: 16,
    },
    errorLabel: {
      color: "red",
    },
    playButton: {
      width: 78,
      height: 78,
      borderRadius: 78,
      fontSize: 18,
    },
  });

const mapStateToProps = (state: GlobalState) => {
  const { joinedGames, organisedByMe } = state.games;
  const { loading } = state.general;
  const { user } = state.auth;
  return {
    user,
    loading,
    games: { ...organisedByMe, ...joinedGames },
  };
};

export default connect(mapStateToProps, {
  getGameById: GameActions.getById,
  leaveGame: GameActions.leaveGame,
  joinGame: GameActions.joinGame,
  activateGame: GameActions.activateGame,
  switchComponent,
})(withStyles(styles)(GameNavigationDialog));
