import { Action } from "../types/actions";
import ReduxTypes from "../consts/ReduxTypes";
import { deleteReducer, fetchReducer, insertReducer, updateReducer } from "./BaseReducerFunctions";
import { UsersState } from "../types/user";
import { act } from "react-dom/test-utils";

const INITIAL_STATE = {
  users: {},
  clubPlayers:{
    items:{},
    count:0
  },
  count: 0,
};
const KEY = "USERS";

export default (state: UsersState = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case ReduxTypes.FETCH_CLUB_PLAYERS:
      let clubPlayers = {...state.clubPlayers};
      clubPlayers.count = action.count;
      if(clubPlayers.items!) clubPlayers.items = {};
      action.payload.forEach((player:any)=>{
        clubPlayers.items[player.id] = player;
      });

      return { ...state, clubPlayers };

    case ReduxTypes.getInsertType(KEY):
      return insertReducer(state, action, "users");

    case ReduxTypes.getFetchType(KEY):
      return fetchReducer(state, action, "users");

    case ReduxTypes.getDeleteType(KEY):
      return deleteReducer(state, action, "users");

    case ReduxTypes.getUpdateType(KEY):
      return updateReducer(state, action, "users");

    case ReduxTypes.PURGE:
      return INITIAL_STATE;

    default:
      return state;
  }
};
