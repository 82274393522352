import { Team } from "./Team";
import { GenderType } from "./user";

export interface GuestPlayerInputs {
  name: string;
  lastName: string;
  hcp: number;
  gender: GenderType;
}
export interface Player {
  [index: string]: any;
  _id: string;
  name: string;
  lastName: string;
  username: string;
  email: string;
  hcp: number;
  hci: number;
  cdh: number;
  group: { name: string; startingHole?: number };
  games: string[];
  submitionEmail?: string[];
  scoreId: string;
  holes: Hole;
  uholes: UHole;
  scoringPlayers: string[];
  state: "registered" | "done";
  points: Points;
  team?: Team;
  isTeamCard?: boolean;
  guest?: boolean;
  gender?: GenderType;
  payStatus: PayStatus;
  isFirstTimeCHP?: boolean;
}

export interface Hole {
  [index: number]: { gross: number; holeNumber: number; mismatch?: boolean };
}

export interface UHole {
  [index: number]: {
    holeNumber: number;
    grosses: {
      [index: string]: number;
    };
  };
}

export interface Points {
  totalPoint: number;
  totalNett: number;
  totalGross: number;
  holes: HoleScoreInfo[];
  summary: PointsSummaryInfo[];
}

export interface HoleScoreInfo {
  point: number;
  nett: number;
  totalPoint: number;
  totalNett: number;
  totalGross: number;
}

export interface PointsSummaryInfo {
  title: string;
  totalPoint: number;
  totalNett: number;
  totalGross: number;
}

export enum PayStatus {
  NOT_PAID = 0,
  PAID = 1,
  GUEST = 2,
}
