import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import UserActions from "../../actions/user";
import { GlobalState } from "../../types/globalState";
import {
  Button,
  createStyles,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Theme,
  Typography,
  WithStyles,
} from "@material-ui/core";
import { showSnackbar } from "../SharedComponents/Notifier";
import LinearProgress from "@material-ui/core/LinearProgress";
import { hideDialog } from "../SharedComponents/Dialog";
import Selector, { Option } from "../SharedComponents/Selector";
import { validateEmail } from "../../utils/validator";
import Messages from "../../consts/Messages";
import { TextInput } from "../SharedComponents/TextInput";
import { filter } from "../../utils/profanityFilter";
import { Gender, Rules } from "../../types/user";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateUtils from "@date-io/date-fns";
import SearchClub from "../Search/SearchClub";

interface Props extends WithStyles {
  insertNewUser(data: any): void;
  updateUser(id: string, data: any): void;
  edit?: any;
  loading: boolean;
  isPlayer?: boolean;
}
interface State {
  user: any;
  err: any;
  err_msg: any;
}

class UserSetup extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    let user = {
      rule: Rules.Player,
      name: "",
      lastName: "",
      email: "",
      username: "",
      password: "",
      gender: Gender.Men,
    };
    this.state = {
      user,
      err: {},
      err_msg: {},
    };
  }

  clearForm = () => {};
  _validateWHSBoxes = (cdhNumber: string, handicapIndex: string) => {
    const hcpRegex = RegExp(/^\+?\d{1,2}(?:\.\d)?$/gm);
    const cdhRegex = /^\d{1,10}$/;
    if (cdhNumber.length > 0 || handicapIndex.length > 0) {
      if (cdhNumber.length === 0) {
        return { msg: "CDH is required", cdh: true };
      }
      if (handicapIndex.length === 0) {
        return { msg: "Handicap Index is required", hcp: true };
      }
      if (!cdhRegex.test(cdhNumber)) {
        return { msg: "Invalid CDH number format", cdh: true };
      }
      if (
        handicapIndex.length > 0 &&
        (parseInt(handicapIndex) < 0 || parseInt(handicapIndex) > 60 || !hcpRegex.test(handicapIndex))
      ) {
        return { msg: "Invalid Handicap Index format", hcp: true };
      }
    } else {
      return { msg: "CDH or Handicap Index is required", cdh: true };
    }
    return {};
  };

  formValidation = () => {
    const { name, lastName, email, username, password, hcp, cdh } = this.state.user;
    const whsErr = this._validateWHSBoxes(cdh, hcp) ?? {};
    let err: any = {};
    let err_msg: any = {};

    err.name = !name || filter.isProfane(name);
    err.hcp = whsErr.hcp;
    err.cdh = whsErr.cdh;
    err_msg.hcp = whsErr.hcp ? whsErr.msg : "";
    err_msg.cdh = whsErr.cdh ? whsErr.msg : "";
    err.lastName = !lastName || filter.isProfane(lastName);
    err.email = !validateEmail(email);
    err.username = username.length < 5 || filter.isProfane(username);
    err.password = password.length < 6;
    err_msg.email = err.email ? Messages.error.emailNotValid : "";
    err_msg.username = err.username ? Messages.error.usernameShortLength : "";
    err_msg.password = err.password ? Messages.error.passwordShortLength : "";

    this.setState({ err, err_msg });

    return !(err.name || err.lastName || err.email || err.username || err.password || err.hcp || err.cdh);
  };

  _handleTextInputChange = (value: string, name: string) => {
    this.setState({
      user: { ...this.state.user, [name]: value },
    });
  };
  _handleTextFieldChange = (e: React.ChangeEvent<{ name: string; value: any }>) => {
    this.setState({
      user: { ...this.state.user, [e.target.name]: e.target.value },
    });
  };
  _handleRuleChange = (rule: string) => {
    this.setState({
      user: {
        ...this.state.user,
        rule,
      },
    });
  };
  _submitForm = () => {
    const user = this.state.user;
    const newUser = {
      ...user,
      name: user.name.trim(),
      lastName: user.lastName.trim(),
      email: user.email.trim(),
      username: user.username.trim(),
      dob: Date.parse(user.dob?.toString()),
    };
    if (this.formValidation()) {
      this.props.insertNewUser(newUser);
    } else {
      showSnackbar("Form is not valid, check items");
    }
  };

  renderActionButton() {
    const { classes, loading } = this.props;
    if (loading)
      return (
        <div className={classes.loadingContainer}>
          <LinearProgress color="primary" variant={"indeterminate"} />
        </div>
      );
    else
      return (
        <div className={classes.actionButtonContainer}>
          <Button
            fullWidth
            className={classes.actionButton}
            variant={"contained"}
            color={"primary"}
            onClick={this._submitForm}
          >
            Save
          </Button>
          <Button
            fullWidth
            className={classes.actionButton}
            variant={"contained"}
            color={"secondary"}
            onClick={hideDialog}
          >
            Cancel
          </Button>
        </div>
      );
  }

  render() {
    const { classes } = this.props;
    const { name, lastName, username, email, password, rule, dob, gender, cdh, homeClub, hcp } = this.state.user;
    const { err, err_msg } = this.state;
    const options: Option[] = [
      { value: Rules.Admin, label: "Admin" },
      { value: Rules.Organiser, label: "Organiser" },
      { value: Rules.ClubManager, label: "Club Manager" },
      { value: Rules.Player, label: "Player" },
    ];
    return (
      <Paper className={classes.paper}>
        <Typography variant={"h5"} className={classes.title}>
          User Setup
        </Typography>
        <FormControl margin="none" color="primary" required fullWidth>
          <TextInput
            id="name"
            name="name"
            label={"Name"}
            autoComplete="name"
            value={name}
            error={err.name}
            helperText={err_msg.name}
            captalize
            onChange={this._handleTextInputChange}
          />
        </FormControl>
        <FormControl margin="none" color="primary" required fullWidth>
          <TextInput
            id="lastName"
            name="lastName"
            label={"Last Name"}
            autoComplete="lastName"
            value={lastName}
            error={err.lastName}
            helperText={err_msg.lastName}
            captalize
            onChange={this._handleTextInputChange}
          />
        </FormControl>
        <FormControl margin="none" color="primary" required fullWidth>
          <TextInput
            id="email"
            name="email"
            label={"Email"}
            autoComplete="disabled"
            value={email}
            error={err.email}
            helperText={err_msg.email}
            disableProfanityCheck
            onChange={this._handleTextInputChange}
          />
        </FormControl>
        <FormControl margin="none" color="primary" required fullWidth>
          <TextInput
            id="username"
            name="username"
            autoComplete="disabled"
            label={"Username"}
            value={username}
            error={err.username}
            helperText={err_msg.username}
            onChange={this._handleTextInputChange}
          />
        </FormControl>
        <FormControl margin="none" color="primary" required fullWidth>
          <TextInput
            id="password"
            name="password"
            type="password"
            label={"Password"}
            value={password}
            error={err.password}
            helperText={err_msg.password}
            autoComplete="new-password"
            disableProfanityCheck
            onChange={this._handleTextInputChange}
          />
        </FormControl>
        <FormControl color="primary" size={"medium"} fullWidth style={{ marginTop: 16 }}>
          <FormLabel component="legend">Gender</FormLabel>
          <RadioGroup defaultValue="men" aria-label="gender" name="gender" onChange={this._handleTextFieldChange} row>
            <FormControlLabel value="men" control={<Radio />} label="Male" />
            <FormControlLabel value="women" control={<Radio />} label="Female" />
          </RadioGroup>
        </FormControl>
        <FormControl color="primary" size={"medium"} fullWidth>
          <TextField
            type="text"
            name="hcp"
            label={"Handicap Index"}
            style={{ margin: "3px 0" }}
            value={hcp}
            error={err.hcp}
            helperText={err_msg.hcp}
            inputProps={{ min: 0, max: 54 }}
            onChange={this._handleTextFieldChange}
          />
        </FormControl>
        <FormControl color="primary" size={"medium"} fullWidth>
          <TextField
            type="number"
            label={"CDH ID No."}
            style={{ margin: "3px 0" }}
            value={cdh}
            error={err.cdh}
            helperText={err_msg.cdh}
            name="cdh"
            onChange={this._handleTextFieldChange}
          />
        </FormControl>
        <MuiPickersUtilsProvider utils={DateUtils}>
          <KeyboardDatePicker
            id="date"
            variant="dialog"
            format="dd/MM/yyyy"
            margin="normal"
            fullWidth
            placeholder="Date of birth"
            value={dob || null}
            onChange={(date: Date | null) => this.setState(state => ({ user: { ...state.user, dob: date } }))}
          />
        </MuiPickersUtilsProvider>
        <SearchClub
          style={{ marginTop: 8 }}
          dialog
          placeHolder="Tap To Search Home Club"
          onClubSelected={(homeClub: any) => this.setState(state => ({ user: { ...state.user, homeClub } }))}
        />
        {!this.props.isPlayer && (
          <Selector variant={"outlined"} onChange={this._handleRuleChange} value={rule} options={options} />
        )}
        {this.renderActionButton()}
      </Paper>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    title: {
      fontWeight: "bold",
    },
    paper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      position: "relative",
      left: "50%",
      transform: "translateX(-50%)",
      width: "auto",
      backgroundColor: "rgba(255,255,552,0.85)",
      padding: theme.spacing(2),
    },
    inline: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    actionButtonContainer: {
      display: "flex",
      width: "100%",
    },
    actionButton: {
      marginTop: theme.spacing(1),
    },
    loadingContainer: {
      width: "100%",
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
  });
const mapStateToProps = (state: GlobalState) => {
  const { loading } = state.general;
  return {
    loading,
  };
};

export default connect(mapStateToProps, {
  insertNewUser: UserActions.insert,
  updateUser: UserActions.updateMe,
})(withStyles(styles)(UserSetup));
