import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import Input from "@material-ui/core/Input";

import { PresentationFields } from "types/game";

export const DEFAULT_PRESENTATION_VALUES = {
  scroll: false,
  continuous: false,
  rowsPerPage: 5,
  scrollSpeed: 3,
  firstPageHoldTime: 7,
  lastPageHoldTime: 5,
  pagesHoldTime: 7,
  widescreen: false,
};

interface PresentationInputsProps {
  fields: PresentationFields;
  onSwitchChange: (field: string, value: boolean) => void;
  handleInputChange: (field: string, value: number) => void;
}

const PresentationInputs: React.FC<PresentationInputsProps> = props => {
  const {
    onSwitchChange,
    handleInputChange,
    fields: {
      widescreen = DEFAULT_PRESENTATION_VALUES.widescreen,
      scroll = DEFAULT_PRESENTATION_VALUES.scroll,
      continuous = DEFAULT_PRESENTATION_VALUES.continuous,
      rowsPerPage = DEFAULT_PRESENTATION_VALUES.rowsPerPage,
      scrollSpeed = DEFAULT_PRESENTATION_VALUES.scrollSpeed,
      firstPageHoldTime = DEFAULT_PRESENTATION_VALUES.firstPageHoldTime,
      lastPageHoldTime = DEFAULT_PRESENTATION_VALUES.lastPageHoldTime,
      pagesHoldTime = DEFAULT_PRESENTATION_VALUES.pagesHoldTime,
    },
  } = props;

  const onInputChange = (field: string) => (evt: React.ChangeEvent<HTMLInputElement>) =>
    handleInputChange(field, +evt.target.value);

  return (
    <Grid container style={{ marginTop: 20 }} className="input-center">
      {/*  */}
      <Grid item md={10} xs={10}>
        <Typography variant="body1" gutterBottom>
          Widescreen format for TV/Projector
        </Typography>
      </Grid>
      <Grid item md={2} xs={2}>
        <Switch
          name="widescreen"
          value={widescreen}
          checked={widescreen}
          onChange={e => onSwitchChange(e.target.name, e.target.checked)}
        />
      </Grid>
      {/*  */}
      <Grid item xs={10}>
        <Typography variant="body1" gutterBottom>
          Activate scrolling screen
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Switch
          name="scroll"
          value={scroll}
          checked={scroll}
          onChange={e => onSwitchChange(e.target.name, e.target.checked)}
        />
      </Grid>

      {scroll && (
        <>
          <Grid item xs={10}>
            <Typography variant="body1" gutterBottom>
              Continuous scroll
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Switch
              name="continuous"
              value={continuous}
              checked={continuous}
              onChange={e => onSwitchChange(e.target.name, e.target.checked)}
            />
          </Grid>

          <Grid item xs={10}>
            <Typography variant="body1" gutterBottom>
              Items to scroll per page
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Input
              value={rowsPerPage}
              margin="dense"
              disabled={continuous}
              onChange={onInputChange("rowsPerPage")}
              inputProps={{
                step: 1,
                min: 1,
                max: 50,
                type: "number",
              }}
            />
          </Grid>

          <Grid item xs={10}>
            <Typography variant="body1" gutterBottom>
              Page scroll/refresh speed (seconds)
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Input
              value={scrollSpeed}
              margin="dense"
              onChange={onInputChange("scrollSpeed")}
              inputProps={{
                step: 1,
                min: 3,
                max: 100,
                type: "number",
              }}
            />
          </Grid>

          <Grid item xs={10}>
            <Typography variant="body1" gutterBottom>
              First Page hold time (seconds)
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Input
              value={firstPageHoldTime}
              margin="dense"
              disabled={continuous}
              onChange={onInputChange("firstPageHoldTime")}
              inputProps={{
                step: 1,
                min: 3,
                max: 100,
                type: "number",
              }}
            />
          </Grid>

          {widescreen && (
            <>
              <Grid item xs={10}>
                <Typography variant="body1" gutterBottom>
                  Last Page hold time (seconds)
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Input
                  value={lastPageHoldTime}
                  margin="dense"
                  onChange={onInputChange("lastPageHoldTime")}
                  inputProps={{
                    step: 1,
                    min: 0,
                    max: 100,
                    type: "number",
                  }}
                />
              </Grid>
            </>
          )}

          <Grid item xs={10}>
            <Typography variant="body1" gutterBottom>
              Other Page hold time (seconds)
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Input
              value={pagesHoldTime}
              margin="dense"
              disabled={continuous}
              onChange={onInputChange("pagesHoldTime")}
              inputProps={{
                step: 1,
                min: 3,
                max: 100,
                type: "number",
              }}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default PresentationInputs;
