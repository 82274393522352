import React from "react";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core";
import { Link } from "@material-ui/icons";
import { Game } from "../../types/game";

interface Props extends WithStyles {
  currentGame: Game;
  nextGame?: Game;
}
const ChainIcon = ({ currentGame, nextGame, classes }: Props) => {
  if (nextGame && currentGame.linkedGames.find(id => id === nextGame._id)) {
    return (
      <>
        <Link className={classes.rightLink} />
        <Link className={classes.leftLink} />
      </>
    );
  }
  return null;
};

const styles = (theme: Theme) =>
  createStyles({
    rightLink: {
      transform: "rotate(90deg)",
      position: "absolute",
      color: "rgb(51, 161, 54)",
      bottom: -16,
      right: 20,
      fontSize: 32,
    },
    leftLink: {
      transform: "rotate(90deg)",
      position: "absolute",
      color: "rgb(51, 161, 54)",
      bottom: -16,
      left: 20,
      fontSize: 32,
    },
  });

export default withStyles(styles)(ChainIcon);
