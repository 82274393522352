import BaseApi from "./Api";

class HandicapsApi extends BaseApi {
  url = "handicaps";
  constructor() {
    super();
    this.setResourceUrl(this.url);
  }

  getHandicapInfo = (id: string) => {
    return this.instance({
      method: "get",
      url: `${this.url}/${id}/handicaps`,
    });
  };

  getHandicapsByRuleId = (id: string) => {
    return this.instance({
      method: "get",
      url: `${this.url}/gameRule/${id}`,
    });
  };

  updateHandicap = (id: string, competitionId: string, hcp: number, playerId: string) => {
    return this.instance({
      method: "put",
      url: `${this.url}/${id}`,
      data: { hcp, competitionId, playerId },
    });
  };
}

export default new HandicapsApi();
