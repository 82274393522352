import React, { Component } from "react";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core";

class Forbidden extends Component<WithStyles, {}> {
  render() {
    const { classes } = this.props;
    return (
      <main>
        <div className={classes.main}>
          <h1 className={"text-center"}>You are not allowed here</h1>
        </div>
      </main>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    main: {
      width: "100%",
      display: "block",
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
      //width: "fit-content",
      backgroundColor: "#FFF",
      padding: theme.spacing(2),
    },
    image: {
      width: "inherit",
    },
  });

export default withStyles(styles)(Forbidden);
