import BaseApi from "./Api";
import Config from "../config";
import { GuestPlayerInputs } from "types/player";
import { EmailTypes } from "types/general";

class GameApi extends BaseApi {
  url = "games";
  saveIfFailed = Config.SAVE_IF_FAILED;

  constructor() {
    super();
    this.setResourceUrl(this.url);
  }

  geById(id: string, queryType?: string) {
    return this.instance({
      method: "get",
      url: `${this.url}/${id}${(queryType && `?queryType=${queryType}`) || ""}`,
    });
  }

  activate(id: string) {
    return this.instance({
      method: "put",
      url: `${this.url}/${id}/activate`,
    });
  }

  join(code: string) {
    return this.instance({
      method: "post",
      url: `${this.url}/join/${code}`,
    });
  }

  leave(id: string) {
    return this.instance({
      method: "post",
      url: `${this.url}/leaveGame/${id}`,
    });
  }

  finish(id: string) {
    return this.instance({
      method: "put",
      url: `${this.url}/finish/${id}`,
    });
  }

  closeGame(id: string) {
    return this.instance({
      method: "put",
      url: `${this.url}/closeGame/${id}`,
      data: {},
    });
  }

  addPlayer(id: string, playerId: string) {
    return this.instance({
      method: "post",
      url: `${this.url}/addPlayer/${id}`,
      data: { playerId },
    });
  }

  addGuestPlayer(id: string, inputs: GuestPlayerInputs) {
    return this.instance({
      method: "post",
      url: `${this.url}/add-guest/${id}`,
      data: inputs,
    });
  }

  removePlayer(id: string, playerId: string) {
    return this.instance({
      method: "delete",
      url: `${this.url}/removePlayer/${id}`,
      data: { playerId },
    });
  }

  changePlayerTeeColor(id: string, playerId: string, scoreCardId: string) {
    return this.instance({
      method: "post",
      url: `${this.url}/changePlayerTeeColor/${id}`,
      data: { playerId, scoreCardId },
    });
  }

  changePlayerTeam(id: string, playerId: string, teamId: string) {
    return this.instance({
      method: "post",
      url: `${this.url}/changePlayerTeam/${id}`,
      data: { playerId, teamId },
    });
  }

  changePlayerHcp(id: string, playerId: string, hcp: number) {
    return this.instance({
      method: "put",
      url: `${this.url}/changePlayerHcp/${id}`,
      data: { playerId, hcp },
    });
  }

  recalculatePlayingHcp(id: string, playerId: string, hci: number) {
    return this.instance({
      method: "patch",
      url: `${this.url}/${id}/playingHcp/${playerId}`,
      data: { hci },
    });
  }

  getJoinedGames() {
    return this.instance({
      method: "get",
      url: `${this.url}/joinedGames`,
    });
  }

  getCurrentOrganisingGames() {
    return this.instance({
      method: "get",
      url: `${this.url}/currentOrganising`,
    });
  }

  dangerouslyUpdateScore(id: string, scoreId: string, data: any) {
    return this.instance({
      method: "put",
      url: `${this.url}/updateScore/${id}/score/${scoreId}`,
      data,
    });
  }

  getGamesHistory(page: number, limit: number, filter?: any, sort?: string, dir?: string, query?: string) {
    let url = `${this.url}/gamesHistory/?page=${page}&limit=${limit}`;
    if (sort) {
      if (dir) {
        url += `&sort=${sort}:${dir}`;
      } else {
        url += `&sort=${sort}:asc`;
      }
    }
    if (query) {
      url += `&query=${query}`;
    }
    if (filter) {
      url += `&filter=${JSON.stringify(filter)}`;
    }
    return this.instance({
      method: "get",
      url,
    });
  }

  updateScores(
    gameId: string,
    scoreId: string,
    holeNumber: number,
    gross: number | null,
    shots?: number,
    onBehalfPlayer?: boolean,
  ) {
    let data: any = { holeNumber, gross, shots, onBehalfPlayer };
    return this.instance({
      method: "put",
      url: `${this.url}/${gameId}/updateScore/${scoreId}`,
      headers: { saveIfFailed: true },
      data,
    });
  }

  linkGames(gameIds?: string[]) {
    return this.instance({
      method: "post",
      url: `${this.url}/linkGames`,
      data: { gameIds },
    });
  }

  getGameByCode(code: string, query: string) {
    return this.instance({
      method: "get",
      url: `${this.url}/code/${code}${(query && `?queryType=${query}`) || ""}`,
    });
  }

  //GROUPS
  getFreePlayers(id: string) {
    return this.instance({
      method: "get",
      url: `${this.url}/${id}/group/freePlayers`,
    });
  }

  insertGroup(id: string, name: string, playersId: string[]) {
    return this.instance({
      method: "post",
      url: `${this.url}/${id}/group`,
      data: {
        name,
        playersId,
      },
    });
  }

  updateGroup(id: string, name: string, data: any) {
    return this.instance({
      method: "put",
      url: `${this.url}/${id}/group/${name}`,
      data,
    });
  }

  updateGroupStartingHole(id: string, startingHole: number, playerId: string, teamId?: string) {
    return this.instance({
      method: "put",
      url: `${this.url}/${id}/group-starting-hole`,
      data: {
        startingHole,
        playerId,
        teamId,
      },
    });
  }

  addPlayerToGroup(id: string, name: string, playerId: string) {
    return this.instance({
      method: "put",
      url: `${this.url}/${id}/group/addPlayer`,
      data: {
        name,
        playerId,
      },
    });
  }

  updateGameGroup(id: string, name: string, oldName: string, playersId: string[]) {
    return this.instance({
      method: "patch",
      url: `${this.url}/${id}/group`,
      data: {
        name,
        oldName,
        playersId,
      },
    });
  }

  removePlayerFromGroup(id: string, name: string, playerId: string) {
    return this.instance({
      method: "delete",
      url: `${this.url}/${id}/group/removePlayer`,
      data: {
        name,
        playerId,
      },
    });
  }

  removeGameGroups(id: string, groups: string[]) {
    return this.instance({
      method: "patch",
      url: `${this.url}/${id}/remove-groups`,
      data: {
        groups,
      },
    });
  }

  getTournaments() {
    return this.instance({
      method: "get",
      url: `${this.url}/tournaments`,
    });
  }

  removeFromHistory(id: string) {
    return this.instance({
      method: "put",
      url: `${this.url}/${id}/remove`,
    });
  }

  revealLeaderboard(id: string) {
    return this.instance({
      method: "put",
      url: `${this.url}/${id}/revealLeaderboard`,
    });
  }

  addOrganiser(gameId: string, playerId: string) {
    return this.instance({
      method: "put",
      url: `${this.url}/${gameId}/addOrganiser/${playerId}`,
    });
  }

  removeOrganiser(gameId: string, playerId: string) {
    return this.instance({
      method: "put",
      url: `${this.url}/${gameId}/removeOrganiser/${playerId}`,
    });
  }

  sendSubmitionCardNotifierMail(gameId: string, homeClubMail: string, gameClubMail: string, extraReceivers: string[]) {
    return this.instance({
      method: "post",
      url: `${this.url}/${gameId}/sendSubmitionCardNotifierMail/`,
      data: {
        homeClubMail,
        gameClubMail,
        extraReceivers,
      },
    });
  }

  toggleScoringPlayer(gameId: string, playerId: string, action: "add" | "remove") {
    return this.instance({
      method: "put",
      url: `${this.url}/${gameId}/toggleScoringPlayer/${playerId}/${action}`,
    });
  }

  getGamePlayers(gameId: string) {
    return this.instance({
      method: "get",
      url: `${this.url}/${gameId}/players`,
    });
  }

  updateGameRule(gameId: string, ruleId: string) {
    return this.instance({
      method: "patch",
      url: `${this.url}/${gameId}/update-rule`,
      data: {
        competitionId: ruleId,
      },
    });
  }

  resetGamePresentation(gameId: string) {
    return this.instance({
      method: "patch",
      url: `${this.url}/${gameId}/reset-presentation`,
    });
  }

  updatePlayersPH(gameId: string, playerIds: string[]) {
    return this.instance({
      method: "patch",
      url: `${this.url}/${gameId}/update-players-ph`,
      data: {
        playerIds,
      },
    });
  }

  sendEmailToPlayers(
    file: string,
    players: { name: string; lastName: string; email: string }[],
    gameCode: string,
    gameName: string,
    type: EmailTypes,
    orgEmail?: string,
  ) {
    return this.instance({
      method: "post",
      url: `${this.url}/send-email`,
      data: {
        file,
        players,
        gameCode,
        gameName,
        orgEmail,
        type,
      },
    });
  }
}

export default new GameApi();
