import { BaseActions } from "./BaseActions";
import handicapsApi from "../api/handicaps";
import ErrorHandler from "../api/Errorhandler";
import ReduxTypes from "../consts/ReduxTypes";
import { AxiosError } from "axios";
import { playerCustomHandicap } from "../types/handicpas";

export default new (class HandicapActins extends BaseActions {
  constructor() {
    super(handicapsApi, "HANDICAPS");
  }

  getHandicapInfo = (id: string, callback?: any) => {
    return (dispatch: any) => {
      handicapsApi
        .getHandicapInfo(id)
        .then((res: any) => {
          dispatch({ type: ReduxTypes.HANDICAPS_SET_HANDICAPS_DETAILS, id: id, payload: res.data });
          if (callback) callback(null, res.data);
          return res.data;
        })
        .catch((err: any) => {
          ErrorHandler(err, null);
          if (callback) callback(err, null);
        });
    };
  };

  getHandicapsByRuleId = (id: string, callback: (err: AxiosError | null, data: playerCustomHandicap[]) => void) => {
    return (dispatch: any) => {
      handicapsApi
        .getHandicapsByRuleId(id)
        .then((res: any) => {
          const result = res.data.map((el: any) => ({
            ...el.player,
            ...el.handicaps[id],
          }));
          callback(null, result as playerCustomHandicap[]);
          return result;
        })
        .catch((err: any) => {
          ErrorHandler(err, null);
          callback(err, {} as playerCustomHandicap[]);
        });
    };
  };

  updateHandicap = (id: string, competitionId: string, hcp: number, playerId: string, callback?: any) => {
    return (dispatch: any) => {
      handicapsApi
        .updateHandicap(id, competitionId, hcp, playerId)
        .then((res: any) => {
          dispatch({ type: ReduxTypes.HANDICAPS_UPDATE_HCP, id: id, payload: hcp, competitionId: competitionId });
          if (callback) callback(null, res.data);
          return res.data;
        })
        .catch((err: any) => {
          ErrorHandler(err, null);
          if (callback) callback(err, null);
        });
    };
  };
})();
