import Messages from "../consts/Messages";
import { showSnackbar } from "../components/SharedComponents/Notifier";
import ReduxTypes from "../consts/ReduxTypes";
import { logOut } from "../actions";

export default function(error: any, dispatch: any, duration: number = 3000) {
  console.log(error);
  if (error && error.response) {
    const { data, status } = error.response;

    switch (status) {
      case 403:
        if (data.error.includes("suspended")) dispatch(logOut());
        showSnackbar((data?.error as string) || "You are not allowed to do this action.");
        break;
      case 401:
        showSnackbar("Token has been expired");
        dispatch(logOut());
        break;
      case 304:
        console.log("304 error, skipping");
        return;
      default:
        const { error, message } = data;
        if (error || message) {
          if (error?.includes("access_token")) dispatch({ type: ReduxTypes.PURGE });
          showSnackbar(data.error || data.message, duration);
        } else {
          //@ts-ignore
          showSnackbar(Messages.apiError[status!] as string);
        }
    }
  }
}
