import React from "react";
import { useLocation } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";

import { useGameAdvert } from "hooks/useGameAdvert";
import { Advert, AdvertStates, AdvertTypes } from "types/advert";
import Config from "config";
import { useStyles } from "./styles/advert";

interface AdvertSectionProps {
  gameId: string;
  clubLogo?: string;
  type: AdvertTypes;
  displayPageRefresh?: boolean;
}

const extractData = (advert?: Advert) => {
  if (!advert) return {};

  const { logo, head, base, refresh, sidebar } = advert.info;

  return {
    logoSrc: logo?.state === AdvertStates.ACTIVE ? logo.image : "",
    headSrc: head?.state === AdvertStates.ACTIVE ? head.image : "",
    baseSrc: base?.state === AdvertStates.ACTIVE ? base.image : "",
    sidebarSrc: sidebar?.state === AdvertStates.ACTIVE ? sidebar.image : "",
    refreshSrc: refresh?.state === AdvertStates.ACTIVE ? refresh.image : "",
  };
};

const AdvertSection: React.FC<AdvertSectionProps> = ({ gameId, clubLogo, type, displayPageRefresh = false }) => {
  const classes = useStyles();
  const loc = useLocation();
  const { advert, isLoading } = useGameAdvert(gameId, type);
  const { logoSrc, headSrc, baseSrc, refreshSrc, sidebarSrc } = extractData(advert);

  return (
    <>
      {/* Top */}
      <div className={`${classes.ad} top`} style={{ top: 0, ...(isLoading ? { opacity: 0 } : {}) }}>
        <Avatar
          src={headSrc ? Config.API_URL + headSrc : Config.PUBLIC_URL + "images/default-top-advert.png"}
          variant="square"
          className={classes.avatar}
        />
      </div>

      {/* Sidebar */}
      <div className={classes.sidebar}>
        <div className={classes.logoContainer}>
          <Avatar
            variant="square"
            src={logoSrc ? Config.API_URL + logoSrc : Config.PUBLIC_URL + "images/default-advert-logo.jpg"}
          />

          <Avatar
            variant="square"
            alt="Club logo"
            src={clubLogo ? `${Config.API_URL}${clubLogo}` : Config.PUBLIC_URL + "images/default-club-logo.jpg"}
          />
        </div>
        <div className={classes.ad}>
          <Avatar
            src={sidebarSrc ? Config.API_URL + sidebarSrc : Config.PUBLIC_URL + "images/default-sidebar-advert.jpg"}
            variant="square"
            className={classes.avatar}
          />
        </div>
      </div>

      {/* Bottom */}
      <div
        className={`${classes.ad} bottom`}
        style={{ bottom: loc.pathname.startsWith("/leader") ? 64 : 0, ...(isLoading ? { opacity: 0 } : {}) }}
      >
        <Avatar
          src={baseSrc ? Config.API_URL + baseSrc : Config.PUBLIC_URL + "images/default-base-advert.jpg"}
          variant="square"
          className={classes.avatar}
        />
      </div>

      {refreshSrc && displayPageRefresh && (
        <div style={{ position: "absolute", top: "0", left: "0", bottom: "0", right: "0", zIndex: 99999 }}>
          <Avatar src={Config.API_URL + refreshSrc} variant="square" className={`${classes.avatar} contain`} />
        </div>
      )}
    </>
  );
};

export default AdvertSection;
