import { useEffect, useState } from "react";

interface Size {
  width: number;
  height: number;
}

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<Size>({
    width: window.innerWidth,
    height: document.documentElement.clientHeight,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: document.documentElement.clientWidth, //window.innerWidth,
        height: document.documentElement.clientHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};
