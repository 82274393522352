import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import DateUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import TournamentActions from "../../actions/tournaments";
import { GlobalState } from "../../types/globalState";
import BorderedBox from "../SharedComponents/BorderedBox";
import {
  Button,
  createStyles,
  Paper,
  TextField,
  Theme,
  Typography,
  WithStyles,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { showSnackbar } from "../SharedComponents/Notifier";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Tournament, ScoreSystem, TournamentInput } from "../../types/tournament";
import { hideDialog } from "../SharedComponents/Dialog";
import { TextInput } from "../SharedComponents/TextInput";
import { filter } from "../../utils/profanityFilter";
import { Rules, User, UserBrifObj } from "types/user";
import PlacingControl from "../SharedComponents/PlacingControl";
import { GameAdditionalOrganisersTable as OrganisersTable } from "components/Game/GameAdditionalOrganiserTable";

interface Props extends WithStyles {
  insertNewTournament(data: TournamentInput): void;

  updateTournament(id: string, data: TournamentInput): void;

  edit?: Tournament;
  copy?: Tournament;
  loading: boolean;
  user: User;
}

interface State {
  tournament: TournamentInput;
  err_name: boolean;
  err_seasonStart: boolean;
  err_seasonEnd: boolean;
  organisers: UserBrifObj[];
}

class TournamentSetup extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    let tournament: TournamentInput = {
      name: "",
      seasonStart: new Date(),
      seasonEnd: new Date(),
      selectedScoreSystem: ScoreSystem.BEST_GROSS,
      placingScores: [1, 1],
    };
    if (props.edit) {
      tournament = props.edit;
    } else if (props.copy) {
      tournament = props.copy;
    }
    this.state = {
      tournament,
      err_seasonStart: false,
      err_seasonEnd: false,
      err_name: false,
      organisers: [],
    };
  }

  clearForm = () => {};
  formValidation = () => {
    const { name } = this.state.tournament;
    const err_name = name.trim().length < 2 || filter.isProfane(name);

    this.setState({ err_name });

    return !err_name;
  };

  handleRadioButtonsChanged = (event: any) => {
    this.setState({
      tournament: {
        ...this.state.tournament,
        selectedScoreSystem: Number(event.target.value),
      },
    });
  };

  handlePlacingScoreChanged = (result: number[]) => {
    this.setState({
      tournament: { ...this.state.tournament, placingScores: result },
    });
  };

  _handleTextFieldChange = (value: string, name: string) => {
    this.setState({
      tournament: { ...this.state.tournament, [name]: value },
    });
  };
  _handleStartDateChange = (date: Date | null) => {
    if (date !== null) {
      this.setState({
        tournament: { ...this.state.tournament, seasonStart: date },
      });
    }
  };
  _handleEndDateChange = (date: Date | null) => {
    if (date !== null) {
      this.setState({
        tournament: { ...this.state.tournament, seasonEnd: date },
      });
    }
  };
  _submitForm = () => {
    const { edit } = this.props;
    const { tournament, organisers } = this.state;
    const newTournament: any = {
      ...tournament,
      name: tournament.name.trim(),
      ...(organisers.length ? { owners: organisers.map(o => o._id) } : {}),
    };
    delete newTournament._id;
    if (this.formValidation()) {
      if (edit) return this.props.updateTournament(edit._id, newTournament);

      this.props.insertNewTournament(newTournament);
    } else {
      showSnackbar("Form is not valid, check items");
    }
  };

  renderActionButton() {
    const { classes, loading } = this.props;
    if (loading)
      return (
        <div className={classes.loadingContainer}>
          <LinearProgress color="primary" variant={"indeterminate"} />
        </div>
      );
    else
      return (
        <div className={classes.actionButtonContainer}>
          <Button
            fullWidth
            className={classes.actionButton}
            variant={"contained"}
            color={"primary"}
            onClick={this._submitForm}
          >
            Save
          </Button>
          <Button
            fullWidth
            className={classes.actionButton}
            variant={"contained"}
            color={"secondary"}
            onClick={hideDialog}
          >
            Cancel
          </Button>
        </div>
      );
  }

  render() {
    const { classes, copy, edit } = this.props;
    const { tournament } = this.state;
    const { err_name } = this.state;

    return (
      <Paper className={classes.paper}>
        <Typography variant={"h5"} className={classes.title}>
          {copy ? "Copy Tournament" : " Tournament Setup"}
        </Typography>
        <TextInput
          label={"Name"}
          margin={"dense"}
          variant={"outlined"}
          value={tournament.name}
          error={err_name}
          captalize
          name={"name"}
          onChange={this._handleTextFieldChange}
          fullWidth
        />
        <BorderedBox title={"Season Date"} variant={"h6"}>
          <div className={classes.inline}>
            <MuiPickersUtilsProvider utils={DateUtils}>
              <KeyboardDatePicker
                id="season-start"
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                label="Season start"
                value={tournament.seasonStart}
                onChange={this._handleStartDateChange}
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={DateUtils}>
              <KeyboardDatePicker
                id="season-end"
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                label="Season end"
                value={tournament.seasonEnd}
                onChange={this._handleEndDateChange}
              />
            </MuiPickersUtilsProvider>
          </div>
        </BorderedBox>

        {!copy && (
          <>
            <BorderedBox title={"Season Scores"} variant={"h6"}>
              <div className={classes.inline}>
                <RadioGroup
                  name="scoringSystems"
                  value={tournament.selectedScoreSystem | ScoreSystem.BEST_GROSS}
                  onChange={this.handleRadioButtonsChanged}
                >
                  <FormControlLabel
                    value={ScoreSystem.BEST_GROSS}
                    control={<Radio />}
                    label="Count each players best gross hole scores towards season Gross eclectic:"
                  />
                  <FormControlLabel
                    value={ScoreSystem.BEST_NETT}
                    control={<Radio />}
                    label="Count each players best nett hole scores towards season Nett eclectic:"
                  />
                  <FormControlLabel
                    value={ScoreSystem.WORST_GROSS}
                    control={<Radio />}
                    label="Count each players worst gross hole scores towards season Gross eclectic:"
                  />
                  <FormControlLabel
                    value={ScoreSystem.WORST_NETT}
                    control={<Radio />}
                    label="Count each players worst nett hole scores towards season Nett eclectic:"
                  />
                  <FormControlLabel
                    value={ScoreSystem.TOTAL_BLOBS}
                    control={<Radio />}
                    label="Count each players PU + Nett par+2 scores towards season ‘Total Blobs’:"
                  />
                  <FormControlLabel
                    value={ScoreSystem.TOTAL_BIRDIES}
                    control={<Radio />}
                    label="Count each players gross par-1 hole scores towards season ‘Birdies’:"
                  />
                  <FormControlLabel
                    value={ScoreSystem.TOTAL_EAGLES}
                    control={<Radio />}
                    label="Count each players gross par-2 hole scores towards season ‘Eagles’:"
                  />
                  <FormControlLabel
                    value={ScoreSystem.TOTAL_ALBATROSS}
                    control={<Radio />}
                    label="Count each players gross par-3 hole scores towards season ‘Albatross’s’:"
                  />
                  <FormControlLabel
                    value={ScoreSystem.TOTAL_POINTS}
                    control={<Radio />}
                    label="Collect total number of stableford points from each game:"
                  />
                  <FormControlLabel
                    value={ScoreSystem.TOTAL_GROSS}
                    control={<Radio />}
                    label="Collect Gross stroke play scores from each game:"
                  />
                  <FormControlLabel
                    value={ScoreSystem.TOTAL_NETT}
                    control={<Radio />}
                    label="Collect Nett stroke play scores from each game:"
                  />
                  <FormControlLabel
                    value={ScoreSystem.OOM_GROSS}
                    control={<Radio />}
                    label="Use Placing scores from each gross score game result to create Gross OoM result:"
                  />
                  <FormControlLabel
                    value={ScoreSystem.OOM_NETT}
                    control={<Radio />}
                    label="Use Placing scores from players nett score game result to create Nett OoM result:"
                  />
                </RadioGroup>
              </div>
            </BorderedBox>
            <BorderedBox title={"Placing Scores"} variant={"h6"}>
              <PlacingControl
                data={tournament.placingScores}
                isVisible={true}
                onChanged={this.handlePlacingScoreChanged}
                type={"winners"}
              />
            </BorderedBox>

            {this.props.user.rule === Rules.Admin && !edit && (
              <BorderedBox title={"Allocate this tournament to other organisers"} variant={"h6"}>
                <OrganisersTable
                  insideHandler={true}
                  organisers={this.state.organisers}
                  onChange={organisers => this.setState({ organisers })}
                />
              </BorderedBox>
            )}
          </>
        )}

        {this.renderActionButton()}
      </Paper>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    title: {
      fontWeight: "bold",
      marginBottom: "1rem",
    },
    paper: {
      display: "block",
      position: "relative",
      left: "50%",
      transform: "translateX(-50%)",
      width: "auto",
      maxWidth: 500,
      minWith: 360,
      backgroundColor: "rgba(255,255,552,0.85)",
      padding: theme.spacing(2),
    },
    inline: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    actionButtonContainer: {
      display: "flex",
    },
    actionButton: {
      marginTop: theme.spacing(1),
    },
    loadingContainer: {
      width: "100%",
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
  });

const mapStateToProps = (state: GlobalState) => {
  const { loading } = state.general;
  const { user } = state.auth;

  return {
    loading,
    user,
  };
};

export default connect(mapStateToProps, {
  insertNewTournament: TournamentActions.insert,
  updateTournament: TournamentActions.update,
})(withStyles(styles)(TournamentSetup));
