import { ReactElement } from "react";
import { renderToString } from "react-dom/server";
//@ts-ignore
import html2pdf from "html2pdf.js";

const pdfOptions = {
  margin: 0,
  enableLinks: true,
  image: {
    type: "jpeg",
    quality: 0.98,
  },
  html2canvas: {
    dpi: 196,
    letterRendering: true,
  },
  jsPDF: {
    unit: "cm",
    format: "a4",
    orientation: "portrait",
    putOnlyUsedFonts: true,
  },
};

export const exportReactElementToPDF = (filename: string, pages: ReactElement[], returnPdf?: boolean) => {
  // html2pdf()
  //   .from(renderToString(page))
  //   .set({ ...pdfOptions, filename })
  //   .save();
  var worker = html2pdf()
    .from(renderToString(pages[0]))
    .toPdf();

  pages.slice(1).forEach(page => {
    worker = worker
      .get("pdf")
      .then((pdf: any) => pdf.addPage())
      .from(renderToString(page))
      .toContainer()
      .toCanvas()
      .toPdf();
  });

  if (returnPdf) {
    return worker
      .set(pdfOptions)
      .output("datauristring")
      .then((base64: string) => base64);
  } else worker.set({ ...pdfOptions, filename }).save();
};
