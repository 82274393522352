import * as React from "react";
import BorderedBox from "../SharedComponents/BorderedBox";
import { Typography, makeStyles, Theme } from "@material-ui/core";
import { TeamHandicapAllowance } from "../../types/competition";
import SliderInput from "./SliderInput";

export interface HandicapAllowanceProps {
  teamHandicapAllowance: TeamHandicapAllowance;
  fullHandicapAllowance: number;
  isCombinedGame: boolean;
  isMultiRules: boolean;
  teamPlayers: number;
  minTeeShots: number[];
  onHandicapAllowanceChanged: (changes: StateChanges) => void;
}

const RANKS: { [index: number]: string } = {
  0: "highest",
  1: "2nd",
  2: "3rd",
  3: "4th",
};

interface StateChanges {
  fullHandicapAllowance: number;
  teamHandicapAllowance: TeamHandicapAllowance;
}

const HandicapAllowance: React.FunctionComponent<HandicapAllowanceProps> = props => {
  const classes = useStyles();
  const { teamHandicapAllowance: teamAllowance, fullHandicapAllowance, isMultiRules, teamPlayers, minTeeShots } = props;

  const onFullHandicapAllowanceChanged = (value: number) => {
    props.onHandicapAllowanceChanged({
      teamHandicapAllowance: teamAllowance,
      fullHandicapAllowance: value,
    });
  };

  const onTeamHandicapAllowanceChanged = (index: number, value: number) => {
    const teamHandicapAllowance = { ...teamAllowance };

    teamHandicapAllowance.hcpSortedAllowances[index] = value;
    props.onHandicapAllowanceChanged({ teamHandicapAllowance, fullHandicapAllowance });
  };

  const onCombinedTeamHandicapAllowanceChanged = (index?: number) => (value: number) => {
    let combinedTeam = teamAllowance.combinedTeam;
    if (isMultiRules && !Array.isArray(combinedTeam)) combinedTeam = [];

    if (index !== undefined && Array.isArray(combinedTeam)) combinedTeam[index] = value;
    else combinedTeam = value;

    const teamHandicapAllowance = { ...teamAllowance, combinedTeam };
    props.onHandicapAllowanceChanged({ teamHandicapAllowance, fullHandicapAllowance });
  };

  return (
    <BorderedBox title={"Handicap Allowance"} variant={"h6"}>
      {!props.isCombinedGame ? (
        <>
          <Typography variant={"subtitle1"} className={classes.title}>
            Individual player % allowance on Playing Handicap
          </Typography>
          <SliderInput onChange={onFullHandicapAllowanceChanged} initialValue={fullHandicapAllowance} />
        </>
      ) : (
        <>
          {Array.from(Array(4)).map((_, i: number) => (
            <React.Fragment key={i}>
              <Typography variant={"subtitle1"}>{`Adjust ${RANKS[i]} team player handicap by`}</Typography>
              <SliderInput
                onChange={value => onTeamHandicapAllowanceChanged(i, value)}
                initialValue={teamAllowance.hcpSortedAllowances[i]}
              />
            </React.Fragment>
          ))}

          <div>
            {isMultiRules ? (
              Array.from(Array(teamPlayers - 1)).map((_, i) =>
                minTeeShots[i] >= 0 ? (
                  <React.Fragment key={i}>
                    <Typography variant={"subtitle1"} style={{ marginBottom: 10 }}>
                      <span>Adjust</span>
                      <span style={{ color: "rgb(15 200 30" }}>{` ${i + 2} player `}</span>
                      <span>team combined playing handicap by</span>
                    </Typography>
                    <SliderInput
                      onChange={onCombinedTeamHandicapAllowanceChanged(i)}
                      initialValue={Array.isArray(teamAllowance.combinedTeam) ? teamAllowance.combinedTeam[i] : 100}
                    />
                  </React.Fragment>
                ) : null,
              )
            ) : (
              <>
                <Typography variant={"subtitle1"}>Adjust team combined playing handicap by</Typography>
                <SliderInput
                  onChange={onCombinedTeamHandicapAllowanceChanged()}
                  initialValue={!Array.isArray(teamAllowance.combinedTeam) ? teamAllowance.combinedTeam : 100}
                />
              </>
            )}
          </div>
        </>
      )}
    </BorderedBox>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    marginBottom: "1rem",
  },
  smallInputs: {
    width: 50,
  },
  block: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "end",
  },
}));

export default HandicapAllowance;
