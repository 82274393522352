import _,{ uniq } from "lodash";
import { Action } from "../types/actions";
import ReduxTypes from "../consts/ReduxTypes";
import { Game,GameState,GState,IndexedGames } from "../types/game";
import { deleteReducer,fetchReducer,insertReducer,updateReducer } from "./BaseReducerFunctions";
import { PayStatus } from "../types/player";

const INITIAL_STATE = {
  games: {},
  joinedGames: {},
  history: {
    items: {},
    count: 0,
  },
  organisedByMe: {},
  visitedLeaderBoard: {},
  count: 0,
  tournaments: [],
  players: [],
  isLoading: false,
};
const KEY = "GAME";

export default (state: GameState = INITIAL_STATE,action: Action) => {
  switch (action.type) {
    case ReduxTypes.getFetchType(KEY):
      return fetchReducer(state,action,"games");

    case ReduxTypes.getDeleteType(KEY):
      return deleteReducer(state,action,"games");

    case ReduxTypes.getUpdateType(KEY): {
      let tempState = { ...state };

      if (action.payload in state.organisedByMe) {
        tempState = updateReducer(state,action,"organisedByMe");
      }
      if (action.payload in state.joinedGames) {
        tempState = updateReducer(tempState,action,"joinedGames");
      }

      return updateReducer(tempState,action,"games");
    }

    case ReduxTypes.UPDATE_GAME_RULE: {
      const game1 = state.games[action.payload.id];
      return {
        ...state,
        games: { ...state.games,[action.payload.id]: { ...game1,competition: action.payload.competition } },
      };
    }
    case ReduxTypes.UPDATE_GAME_GROUP: {
      // TODO: Need to refactor our state management
      const joinedGames = { ...state.joinedGames };
      const organisedByMe = { ...state.organisedByMe };
      const games = { ...state.games };
      const { gameId,groupName,oldName,playerIds } = action.payload;

      if (joinedGames[gameId] && joinedGames[gameId].groups) {
        joinedGames[gameId].groups = joinedGames[gameId].groups.filter(g => g !== oldName).concat(groupName);
      }
      if (organisedByMe[gameId] && organisedByMe[gameId].groups) {
        organisedByMe[gameId].groups = organisedByMe[gameId].groups.filter(g => g !== oldName).concat(groupName);
      }
      if (games[gameId] && games[gameId].groups) {
        games[gameId].groups = games[gameId].groups.filter(g => g !== oldName).concat(groupName);
      }
      return {
        ...state,
        joinedGames,
        games,
        organisedByMe,
        players: state.players.map(p => {
          if (playerIds.includes(p._id)) {
            return { ...p,group: { ...p.group,name: groupName } };
          }
          return p;
        }),
      };
    }

    case ReduxTypes.ADD_TO_VISITED_LEADER_BOARD: {
      let visitedLeaderBoard = { ...state.visitedLeaderBoard };
      visitedLeaderBoard[action.payload._id] = { ...action.payload,visitDate: new Date() };
      return { ...state,visitedLeaderBoard };
    }

    case ReduxTypes.UPDATE_GUEST_PLAYER: {
      const {
        gameId,
        userId,
        data: { hcp,...rest },
      } = action.payload;

      let joinedGames = { ...state.joinedGames };
      let games = { ...state.games };
      let organisedByMe = { ...state.organisedByMe };

      if (games[gameId]?.players[userId]) {
        const player = games[gameId].players[userId];
        games[gameId].players[userId] = { ...player,...rest,hci: hcp };
      }
      if (joinedGames[gameId]?.players[userId]) {
        const player = joinedGames[gameId].players[userId];
        joinedGames[gameId].players[userId] = { ...player,...rest,hci: hcp };
      }
      if (organisedByMe[gameId]?.players[userId]) {
        const player = organisedByMe[gameId].players[userId];
        organisedByMe[gameId].players[userId] = { ...player,...rest,hci: hcp };
      }

      return {
        ...state,
        joinedGames,
        games,
        organisedByMe,
        players: state.players.map(p => {
          if (p._id === userId) {
            return { ...p,...rest,hci: hcp };
          }
          return p;
        }),
      };
    }

    case ReduxTypes.JOIN_GAME: {
      let joinedGames = { ...state.joinedGames };
      let organisedByMe = { ...state.organisedByMe };
      action.payload.map((game: Game) => {
        joinedGames[game._id] = game;
        if (organisedByMe[game._id]) organisedByMe[game._id] = game;
      });
      return { ...state,joinedGames,organisedByMe };
    }

    case ReduxTypes.LEAVE_GAME: {
      let joinedGames = { ...state.joinedGames };
      delete joinedGames[action.id];
      return { ...state,joinedGames };
    }

    case ReduxTypes.CLEAR_JOINED_GAMES: {
      return { ...state,joinedGames: {} };
    }

    case ReduxTypes.GET_JOINED_GAMES: {
      const joinedGames: any = {};
      action.payload.forEach((game: any) => {
        joinedGames[game._id] = game;
      });
      return { ...state,joinedGames };
    }

    case ReduxTypes.GET_GAMES_HISTORY: {
      let history: any = { items: {},count: action.payload.count };
      action.payload.items.forEach((game: any) => {
        history.items[game._id] = game;
      });
      return { ...state,history };
    }

    case ReduxTypes.GET_GAMES_ORGANISED_BY_ME: {
      let organisedByMe: any = {};
      action.payload.forEach((game: any) => {
        organisedByMe[game._id] = game;
      });
      return { ...state,organisedByMe };
    }

    case ReduxTypes.CHANGE_TEE_COLOR: {
      let joinedGames = { ...state.joinedGames };
      Object.values(action.payload).forEach(
        (tee: any) => (joinedGames[tee.gameId].players[action.playerId].teeColors = tee),
      );

      return { ...state,joinedGames };
    }

    case ReduxTypes.GET_TEAMS: {
      let joinedGames = { ...state.joinedGames };
      joinedGames[action.id].teams = action.payload;
      return { ...state,joinedGames };
    }

    case ReduxTypes.ADD_NEW_TEAM: {
      let joinedGames = { ...state.joinedGames };
      let organisedByMe = { ...state.organisedByMe };
      let games = { ...state.games };

      if (joinedGames[action.id]) {
        forEachLinkedGame(joinedGames,action.id,gameId => {
          joinedGames[gameId].teams.push(action.payload);
        });
      }
      if (organisedByMe[action.id]) {
        forEachLinkedGame(organisedByMe,action.id,gameId => {
          organisedByMe[gameId].teams.push(action.payload);
        });
      }
      if (games[action.id]) games[action.id].teams.push(action.payload);
      return { ...state,joinedGames,games,organisedByMe };
    }

    case ReduxTypes.CHANGE_TEAM: {
      let joinedGames = { ...state.joinedGames };
      let organisedByMe = { ...state.organisedByMe };
      let games = { ...state.games };

      if (joinedGames[action.id]) {
        forEachLinkedGame(joinedGames,action.id,gameId => {
          joinedGames[gameId].players[action.playerId].team = { ...action.payload };
        });
      }
      if (organisedByMe[action.id]) {
        forEachLinkedGame(organisedByMe,action.id,gameId => {
          organisedByMe[gameId].players[action.playerId].team = { ...action.payload };
        });
      }
      if (games[action.id] && games[action.id].players[action.playerId])
        games[action.id].players[action.playerId].team = { ...action.payload };

      return {
        ...state,
        joinedGames,
        games,
        organisedByMe,
        players: state.players.map(p => {
          if (p._id === action.playerId) {
            return { ...p,team: action.payload };
          }
          return p;
        }),
      };
    }

    case ReduxTypes.UPDATE_GAME_TEAM: {
      const joinedGames = { ...state.joinedGames };
      const organisedByMe = { ...state.organisedByMe };
      const games = { ...state.games };
      const { gameId,teamId,...data } = action.payload;

      const teams = joinedGames[gameId]?.teams || organisedByMe[gameId]?.teams || games[gameId]?.teams || [];
      const newTeams = teams.map(t => (t._id === teamId ? { ...t,...data } : t));

      if (joinedGames[gameId]?.teams) {
        joinedGames[gameId].teams = newTeams;
      }
      if (organisedByMe[gameId]?.teams) {
        organisedByMe[gameId].teams = newTeams;
      }
      if (games[gameId]?.teams) {
        games[gameId].teams = newTeams;
      }

      return {
        ...state,
        joinedGames,
        games,
        organisedByMe,
        players: state.players.map(p => {
          if (p.team?._id === teamId) {
            return { ...p,team: { ...p.team,...data } };
          }
          return p;
        }),
      };
    }

    case ReduxTypes.REMOVE_GAME_TEAMS: {
      const joinedGames = { ...state.joinedGames };
      const organisedByMe = { ...state.organisedByMe };
      const games = { ...state.games };

      const teams = joinedGames[action.id]?.teams || organisedByMe[action.id]?.teams || games[action.id]?.teams || [];
      const newTeams = teams.filter(t => !action.teams.includes(t.name));

      if (joinedGames[action.id]?.teams) joinedGames[action.id].teams = newTeams;
      if (organisedByMe[action.id]?.teams) organisedByMe[action.id].teams = newTeams;
      if (games[action.id]?.teams) games[action.id].teams = newTeams;

      return {
        ...state,
        joinedGames,
        games,
        organisedByMe,
        players: state.players.map(p => {
          if (action.teams.includes(p.team?.name)) return { ...p,team: undefined };
          return p;
        }),
      };
    }

    case ReduxTypes.UPDATE_LINKED_GAMES: {
      let joinedGames = { ...state.joinedGames };
      let organisedByMe = { ...state.organisedByMe };
      let games = { ...state.games };

      action.payload.forEach((id: string) => {
        if (joinedGames[id]) joinedGames[id].linkedGames = [...action.payload];
        if (organisedByMe[id]) organisedByMe[id].linkedGames = [...action.payload];
        if (games[id]) games[id].linkedGames = [...action.payload];
      });

      return { ...state,joinedGames,games,organisedByMe };
    }

    case ReduxTypes.CHANGE_HCP: {
      let joinedGames = { ...state.joinedGames };
      joinedGames[action.id].players[action.playerId].hcp = action.payload;
      return { ...state,joinedGames };
    }

    case ReduxTypes.CHANGE_HCI: {
      let joinedGames = { ...state.joinedGames };
      joinedGames[action.id].players[action.playerId].hci = action.payload.hci;
      joinedGames[action.id].players[action.playerId].hcp = action.payload.hcp;
      return { ...state,joinedGames };
    }

    case ReduxTypes.UPDATE_GAME_PLAYER: {
      //im scorer of given player
      let joinedGames = { ...state.joinedGames };
      let organisedByMe = { ...state.organisedByMe };
      if (joinedGames[action.id]) {
        joinedGames[action.id].players = {
          ...action.payload,
        };
      }
      if (organisedByMe[action.id]) {
        organisedByMe[action.id].players = {
          ...action.payload,
        };
      }

      return { ...state,organisedByMe,joinedGames,players: Object.values(action.payload) };
    }

    case ReduxTypes.FINISH_CURRENT_GAME: {
      let joinedGames = { ...state.joinedGames };
      if (joinedGames[action.id]) {
        joinedGames[action.id].state = action.payload;
        return { ...state,joinedGames };
      } else {
        return state;
      }
    }

    case ReduxTypes.UPDATE_GAME_SCORES: {
      let joinedGames = { ...state.joinedGames };
      const { playerId,holeNumber,gross } = action.payload;

      if (joinedGames[action.id]) {
        let player = joinedGames[action.id].players[playerId];
        if (player) {
          if (!player.holes) player.holes = {};
          console.log("reducer",holeNumber,gross)
          player.holes[holeNumber] = { gross,holeNumber };
        }
      }

      return { ...state,joinedGames };
    }

    case ReduxTypes.UPDATE_GAME_BY_ID: {
      let joinedGames = { ...state.joinedGames };
      let games = { ...state.games };
      let organisedByMe = { ...state.organisedByMe };

      if (organisedByMe[action.id]) organisedByMe[action.id] = JSON.parse(JSON.stringify(action.payload)); //deep clone, because of inside arrays
      if (joinedGames[action.id]) joinedGames[action.id] = JSON.parse(JSON.stringify(action.payload));
      if (games[action.id]) games[action.id] = JSON.parse(JSON.stringify(action.payload));

      return { ...state,joinedGames,organisedByMe,games };
    }

    case ReduxTypes.UPDATE_GAMES_LIST: {
      const { old_val,new_val } = action.payload;
      let tmp = { ...state.games };
      if (!old_val) {
        //Add new item
        tmp[new_val._id] = new_val;
      } else {
        //Update an existing item
        tmp[old_val._id] = new_val;
      }
      return { ...state,games: tmp };
    }

    case ReduxTypes.INSERT_GROUP: {
      const joinedGames = { ...state.joinedGames };
      const organisedByMe = { ...state.organisedByMe };
      const games = { ...state.games };

      if (joinedGames[action.id]) {
        forEachLinkedGame(joinedGames,action.id,gameId => {
          if (joinedGames[gameId].groups) joinedGames[gameId].groups.push(action.payload.name);
          else joinedGames[gameId].groups = [action.payload.name];
          action.playersId!.forEach(p => {
            if (joinedGames[gameId].players[p]) {
              joinedGames[gameId].players[p].group = action.payload;
            }
          });
        });
      }

      if (organisedByMe[action.id]) {
        forEachLinkedGame(organisedByMe,action.id,gameId => {
          if (organisedByMe[gameId].groups) organisedByMe[gameId].groups.push(action.payload.name);
          else organisedByMe[gameId].groups = [action.payload.name];
          action.playersId!.forEach(p => {
            if (organisedByMe[gameId].players[p]) {
              organisedByMe[gameId].players[p].group = action.payload;
            }
          });
        });
      }

      if (games[action.id]) {
        if (games[action.id].groups) games[action.id].groups.push(action.payload.name);
        else games[action.id].groups = [action.payload.name];
        action.playersId!.forEach(p => {
          if (games[action.id].players[p]) {
            games[action.id].players[p].group = action.payload;
          }
        });
      }

      return {
        ...state,
        joinedGames,
        games,
        organisedByMe,
        players: state.players.map(p => {
          if (action.playersId.includes(p._id)) {
            return { ...p,group: { ...p.group,name: action.payload.name } };
          }
          return p;
        }),
      };
    }

    case ReduxTypes.UPDATE_GROUP: {
      const joinedGames = { ...state.joinedGames };
      if (joinedGames[action.id]) {
        Object.values(joinedGames[action.id].players)
          .map((player: any) => {
            if (player.group.name === action.name) {
              return { ...player,group: { ...player.group,...action.payload } };
            }
            return player;
          })
          .forEach((p: any) => {
            joinedGames[action.id].players[p._id] = p;
          });
      }

      const games = { ...state.games };

      return { ...state,joinedGames,games };
    }

    case ReduxTypes.ADD_PLAYER_TO_GORUP: {
      const joinedGames = { ...state.joinedGames };
      const organisedByMe = { ...state.organisedByMe };
      const games = { ...state.games };

      const group =
        joinedGames[action.id]?.players[action.playerId]?.group ||
        organisedByMe[action.id]?.players[action.playerId]?.group ||
        games[action.id]?.players[action.playerId]?.group ||
        {};

      if (joinedGames[action.id] && joinedGames[action.id].players[action.playerId]) {
        forEachLinkedGame(
          joinedGames,
          action.id,
          gameId => (joinedGames[gameId].players[action.playerId].group = { ...group,name: action.payload }),
        );
      }
      if (organisedByMe[action.id] && organisedByMe[action.id].players[action.playerId]) {
        forEachLinkedGame(
          organisedByMe,
          action.id,
          gameId => (organisedByMe[gameId].players[action.playerId].group = { ...group,name: action.payload }),
        );
      }
      if (games[action.id] && games[action.id].players[action.playerId]) {
        games[action.id].players[action.playerId].group = { ...group,name: action.payload };
      }
      return {
        ...state,
        joinedGames,
        games,
        organisedByMe,
        players: state.players.map(p => {
          if (p._id === action.playerId) {
            return { ...p,group: { ...p.group,name: action.payload } };
          }
          return p;
        }),
      };
    }

    case ReduxTypes.REMOVE_PLAYER_FROM_GORUP: {
      const joinedGames = { ...state.joinedGames };
      const organisedByMe = { ...state.organisedByMe };
      const games = { ...state.games };
      if (joinedGames[action.id] && joinedGames[action.id].players[action.playerId!]) {
        forEachLinkedGame(
          joinedGames,
          action.id,
          //@ts-ignore
          gameId => delete joinedGames[gameId].players[action.playerId].group,
        );
      }
      if (organisedByMe[action.id] && organisedByMe[action.id].players[action.playerId!]) {
        forEachLinkedGame(
          organisedByMe,
          action.id,
          //@ts-ignore
          gameId => delete organisedByMe[gameId].players[action.playerId].group,
        );
      }
      if (games[action.id] && games[action.id].players[action.playerId!]) {
        //@ts-ignore
        delete games[action.id].players[action.playerId!].group;
      }
      return {
        ...state,
        joinedGames,
        games,
        players: state.players.map(p => {
          if (p._id === action.playerId) {
            return { ...p,group: { ...p.group,name: undefined } };
          }
          return p;
        }),
      };
    }

    case ReduxTypes.REMOVE_GAME_GROUPS: {
      // TODO: Refactor store state!
      const joinedGames = { ...state.joinedGames };
      const organisedByMe = { ...state.organisedByMe };
      const games = { ...state.games };

      const groups =
        joinedGames[action.id]?.groups || organisedByMe[action.id]?.groups || games[action.id]?.groups || [];
      const newGroups = groups.filter(g => !action.groups.includes(g));

      if (joinedGames[action.id]?.groups) joinedGames[action.id].groups = newGroups;
      if (organisedByMe[action.id]?.groups) organisedByMe[action.id].groups = newGroups;
      if (games[action.id]?.groups) games[action.id].groups = newGroups;

      return {
        ...state,
        joinedGames,
        games,
        organisedByMe,
        players: state.players.map(p => {
          if (action.groups.includes(p.group?.name)) return { ...p,group: { ...p.group,name: undefined } };
          return p;
        }),
      };
    }

    case ReduxTypes.PLAYER_ADDED: {
      const joinedGames = { ...state.joinedGames };
      const organisedByMe = { ...state.organisedByMe };
      const games = { ...state.games };

      action.payload.map((game: Game) => {
        if (joinedGames[game._id] || action.itsMe) joinedGames[game._id] = game;
        if (organisedByMe[game._id]) organisedByMe[game._id] = game;
        if (games[game._id]) games[game._id] = game;
      });

      return { ...state,joinedGames,games,organisedByMe };
    }

    case ReduxTypes.PLAYER_REMOVED: {
      const joinedGames = { ...state.joinedGames };
      const organisedByMe = { ...state.organisedByMe };
      const games = { ...state.games };
      if (joinedGames[action.id] && joinedGames[action.id].players) {
        forEachLinkedGame(joinedGames,action.id,gameId => {
          delete joinedGames[gameId].players[action.payload];
        });
      }
      if (organisedByMe[action.id] && organisedByMe[action.id].players) {
        forEachLinkedGame(organisedByMe,action.id,gameId => {
          delete organisedByMe[gameId].players[action.payload];
        });
      }

      if (games[action.id] && games[action.id].players) {
        delete games[action.id].players[action.payload];
      }
      return {
        ...state,
        joinedGames,
        games,
        organisedByMe,
        players: state.players.filter(p => p._id !== action.payload),
      };
    }

    case ReduxTypes.ACTIVATE_GAME: {
      const joinedGames = { ...state.joinedGames };
      const organisedByMe = { ...state.organisedByMe };
      const games = { ...state.games };
      action.ids.forEach((id: string) => {
        if (joinedGames[id]) joinedGames[id].state = GState.Playing;
        if (organisedByMe[id]) organisedByMe[id].state = GState.Playing;
        if (games[id]) games[id].state = GState.Playing;
      });

      return { ...state,joinedGames,games,organisedByMe };
    }

    case ReduxTypes.CLOSE_GAME: {
      const joinedGames = { ...state.joinedGames };
      const games = { ...state.games };
      const organisedByMe = { ...state.organisedByMe };

      if (joinedGames[action.id]) joinedGames[action.id].state = GState.Done;
      if (games[action.id]) games[action.id].state = GState.Done;
      if (organisedByMe[action.id]) organisedByMe[action.id].state = GState.Done;

      return { ...state,joinedGames,games,organisedByMe };
    }

    case ReduxTypes.DANGEROUSLY_UPDATE_SCORES: {
      const joinedGames = { ...state.joinedGames };
      const games = { ...state.games };
      const organisedByMe = { ...state.organisedByMe };

      if (joinedGames[action.id] && joinedGames[action.id].players[action.playerId]) {
        joinedGames[action.id].players[action.playerId].holes = action.payload;
      }
      if (games[action.id] && games[action.id].players[action.playerId]) {
        games[action.id].players[action.playerId].holes = action.payload;
      }
      if (organisedByMe[action.id] && organisedByMe[action.id].players[action.playerId]) {
        organisedByMe[action.id].players[action.playerId].holes = action.payload;
      }

      return { ...state,joinedGames,games,organisedByMe };
    }

    case ReduxTypes.GET_TOURNAMENTS: {
      return { ...state,tournaments: [...action.payload] };
    }

    case ReduxTypes.SET_GAME_AUTOPROGRESS: {
      let joinedGames = { ...state.joinedGames };
      if (joinedGames[action.id]) {
        joinedGames[action.id].autoProgress = action.payload;
        return { ...state,joinedGames };
      } else {
        return state;
      }
    }

    case ReduxTypes.REMOVE_GAME_FROM_HISTORY: {
      let history = { ...state.history };
      delete history.items[action.id];
      return { ...state,history: history };
    }

    case ReduxTypes.SET_AS_PAID: {
      const joinedGames = { ...state.joinedGames };
      const games = { ...state.games };
      const organisedByMe = { ...state.organisedByMe };
      const gameId = action.id;
      const { payStatus } = action;

      if (joinedGames[action.id]) {
        action.playersId!.forEach(p => {
          if (joinedGames[gameId].players[p]) {
            joinedGames[gameId].players[p].payStatus = payStatus;
          }
        });
      }

      if (games[action.id]) {
        action.playersId!.forEach(p => {
          if (games[gameId].players[p]) {
            games[gameId].players[p].payStatus = payStatus;
          }
        });
      }
      if (organisedByMe[action.id]) {
        action.playersId!.forEach(p => {
          if (organisedByMe[gameId].players[p]) {
            organisedByMe[gameId].players[p].payStatus = payStatus;
          }
        });
      }

      return { ...state,joinedGames,games,organisedByMe };
    }

    case ReduxTypes.GET_GAME_PLAYERS: {
      const joinedGames = { ...state.joinedGames };
      const games = { ...state.games };
      const organisedByMe = { ...state.organisedByMe };

      if (joinedGames[action.id]) {
        joinedGames[action.id].players = action.payload;
      }
      if (games[action.id]) {
        games[action.id].players = action.payload;
      }
      if (organisedByMe[action.id]) {
        organisedByMe[action.id].players = action.payload;
      }

      return { ...state,joinedGames,games,organisedByMe,players: action.payload,isLoading: false };
    }
    case ReduxTypes.UPDATE_GROUP_STARTING_HOLE:
      return {
        ...state,
        players: state.players.map(p => {
          if (action.payload.playerId === p._id) {
            return { ...p,group: { ...p.group,startingHole: action.payload.startingHole } };
          }
          return p;
        }),
      };
    case ReduxTypes.GET_GAME_PLAYERS_REQ:
      return { ...state,isLoading: true };

    case ReduxTypes.PURGE:
      return INITIAL_STATE;

    default:
      return state;
  }
};

const forEachLinkedGame = (games: IndexedGames,gameId: string,handler: (gameId: string) => void) => {
  let linkedGames = [gameId];
  linkedGames.push(...games[gameId].linkedGames);
  uniq(linkedGames).forEach(gameId => {
    handler(gameId);
  });
};
