import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import LinearProgress from "@material-ui/core/LinearProgress";

import { useActions } from "hooks/useActions";
import { useSelector } from "hooks/useSelector";

export type RemoveDialogType = "Group" | "Team";

export interface RemoveDialogProps {
  items: string[];
  gameId: string;
  type: RemoveDialogType;
  onClose: () => void;
}

const ALL_VALUE = "All";

const RemoveDialogContent: React.FC<RemoveDialogProps> = props => {
  const classes = useStyles();
  const [state, setState] = React.useState<string[]>([]);
  const { items, gameId, onClose, type } = props;
  const { removeGameGroups, removeGameTeams } = useActions();
  const { loading } = useSelector(state => state.general);

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (evt.target.checked) setState([...state, evt.target.name]);
    else setState(state.filter(g => g !== evt.target.name));
  };

  return (
    <FormControl component="fieldset" className={classes.formControl}>
      {loading && <LinearProgress />}
      <FormGroup className={classes.group}>
        {items.length > 1 && (
          <FormControlLabel
            className={classes.checkbox}
            control={<Checkbox checked={state.includes(ALL_VALUE)} onChange={handleChange} name={ALL_VALUE} />}
            label={ALL_VALUE}
          />
        )}
        {items.map(item => (
          <FormControlLabel
            key={item}
            disabled={state.includes(ALL_VALUE)}
            className={classes.checkbox}
            control={<Checkbox checked={state.includes(item)} onChange={handleChange} name={item} />}
            label={item}
          />
        ))}
      </FormGroup>

      <Button
        color="primary"
        variant="contained"
        onClick={() => {
          if (!state.length) return;

          const selected = state.includes(ALL_VALUE) ? items : state;

          if (type === "Group") removeGameGroups(gameId, selected, onClose);
          else removeGameTeams(gameId, selected, onClose);
        }}
      >
        Submit
      </Button>
    </FormControl>
  );
};

export default RemoveDialogContent;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(3),
    },
    group: {
      flexDirection: "row",
      marginBottom: "30px",
    },
    checkbox: {
      flexBasis: "30%",
    },
  }),
);
