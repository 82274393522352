import { getHandicapDisplay } from "./../index";
import { Game, GState } from "../../types/game";
import Moment from "moment";
import autoTable from "jspdf-autotable";
import jsPDF from "jspdf";
import { pdfToImage } from "../pdfUtils";
import { isCombinedGame } from "..";
import { Gender } from "../../types/user";

let doc: jsPDF | null = null;
const leftPading = 5;
const rightPading = 30;
let lineIndex = 0;
const nextLine = 5;

const writeKeyValueText = (key: string, value: string) => {
  doc?.text(key, leftPading, lineIndex);
  doc?.text(value, rightPading, lineIndex);
  lineIndex += nextLine;
};

const getData = (game: Game, scoringType: string) => {
  const result: any = [];
  const isCombined = isCombinedGame(game.competition);
  const players = game.players ? Object.values(game.players).filter(x => x.isTeamCard || !isCombined) : [];

  players.forEach((player: any) => {
    let data: any = [
      player.name,
      player.lastName ? player.lastName : "",
      getHandicapDisplay(player.hcp),
      player.hci ? Number(player.hci).toFixed(1) : 0,
      player.cdh ? player.cdh : "",
    ];
    let startIndex = 0;
    let endingIndex: number = game.course.holesNumber;

    if (scoringType === "front") endingIndex = game.course.holesNumber / 2;
    else if (scoringType === "back") startIndex = game.course.holesNumber / 2;

    if (player.holes) {
      Object.values(player.holes)
        .slice(startIndex, endingIndex)
        .forEach((hole: any) => {
          data = [...data, hole?.gross || hole];
        });
      result.push(data);
    }
  });
  return result;
};

const createHeader = (game: Game, scoringType: string) => {
  const header = ["First Name", "Last Name", "PH", "HI", "CDH No."];
  let startIndex = 0;
  let endingIndex: number = game.course.holesNumber;

  if (scoringType === "front") endingIndex = game.course.holesNumber / 2;
  else if (scoringType === "back") startIndex = game.course.holesNumber / 2;

  for (let i = startIndex; i < endingIndex; i++) {
    header.push(`Hole\n${i + 1}`);
  }
  return header;
};

const writeGameDetails = (game: Game) => {
  writeKeyValueText("Golf Club:", game.course.club.name);
  writeKeyValueText(`Golf Course:`, game.course.name);
  writeKeyValueText(`Date:`, Moment(game.date).format("DD/MM/YYYY"));
  writeKeyValueText(`Golf Name:`, game.name);
  writeKeyValueText(`Golf Code:`, game.code);

  Object.values(game.scoreCards).forEach(scoreCard => {
    writeKeyValueText(
      `Tee (${scoreCard.gender === Gender.Men ? "Men" : "Ladies"}):`,
      `${scoreCard.teeColor}, CR:${scoreCard.courseRating}, Slope: ${
        scoreCard.slope
      }, Par: ${scoreCard.holesParams.reduce((total: number, hole) => total + hole.par, 0)}`,
    );
  });
};

const writeLeaderboardDetails = (game: Game) => {
  writeKeyValueText("Tournament:", game.name);
  writeKeyValueText(`Date:`, Moment(game.date).format("DD/MM/YYYY"));
};

const setPageStyle = () => {
  lineIndex = 10;
  doc?.setFontSize(10);
};

const getResultJSPDFObject = (game: Game, scoringType: string, isTournament: boolean) => {
  doc = new jsPDF("l", "mm", "a4");
  setPageStyle();
  isTournament ? writeLeaderboardDetails(game) : writeGameDetails(game);
  autoTable(doc, {
    head: [createHeader(game, scoringType)],
    body: getData(game, scoringType),
    startY: lineIndex,
    margin: { horizontal: 0, vertical: 0 },
    bodyStyles: {
      cellWidth: "auto",
      fontSize: 9,
    },
    styles: {
      halign: "center",
      valign: "middle",
    },
  });
  return doc;
};

const getLeaderboardJSPDFObject = (tableTitle: string, game: Game) => {
  doc = new jsPDF("p", "mm", "a4");
  setPageStyle();
  writeGameDetails(game);
  doc?.text(game.state === GState.Done ? "FINAL RESULT" : "", 100, 40);
  autoTable(doc, { html: `#${tableTitle}`, startY: 50 });
  return doc;
};

export const exportResultToPDF = (game: Game, scoringType: string, isTournament = false) => {
  getResultJSPDFObject(game, scoringType, isTournament).save(`${game.name}-${game.code}.pdf`);
};

export const exportLeaderboardToPDF = (tableTitle: string, game: Game) => {
  getLeaderboardJSPDFObject(tableTitle, game).save(`leaderboard-${game.name}-${game.code}.pdf`);
};

export const exportLeaderboardToBase64 = (tableTitle: string, game: Game) => {
  return getLeaderboardJSPDFObject(tableTitle, game).output("datauristring");
};

export const exportLeaderboardToImageFile = async (
  filename: string,
  tableTitle: string,
  game: Game,
): Promise<File[] | null> => {
  const blob = getLeaderboardJSPDFObject(tableTitle, game).output("arraybuffer", filename);
  const images = await pdfToImage(blob);
  if (!images) return null;
  return images.map(image => new File([image], `${filename}.jpg`, { type: "image/jpeg" }));
};

export const exportResultToImageFile = async (
  filename: string,
  game: Game,
  scoringType: string,
  isTournament = false,
): Promise<File[] | null> => {
  const blob = getResultJSPDFObject(game, scoringType, isTournament).output("arraybuffer", filename);
  const images = await pdfToImage(blob);
  if (!images) return null;
  return images.map(image => new File([image], `${filename}.jpg`, { type: "image/jpeg" }));
};
