import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, TableCell, TableRow, Theme } from "@material-ui/core";

export const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.grey.A100,
      },
    },
  }),
)(TableRow);

export const TightHeaderCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: `8px ${window.innerWidth < 400 ? "0" : "12px"} 8px 8px`,
      fontWeight: "bold",
    },
  }),
)(TableCell);

export const TightBodyCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: `8px ${window.innerWidth < 400 ? "0" : "12px"} 8px 8px`,
    },
  }),
)(TableCell);
