import playersApi from "../api/players";

export const searchPlayers = (text: string, callback: any) => {
  return (dispatch: any) => {
    playersApi
      .searchByUsername(text)
      .then((res: any) => {
        callback(res, null);
      })
      .catch((err: any) => {
        if (err.response.status === 404) {
          callback([], null);
        } else {
          callback([], err);
        }
      });
  };
};
