import { Club } from "./club";
import { Course } from "./course";
import { HoleParam } from "./hole";
import { GenderType } from "./user";

export enum ScoreCardType {
  MAIN = "main",
  CUSTOM = "custom",
}
interface _ScoreCard {
  name: string;
  holesParams: HoleParam[] | null;
  courseRating: number;
  combination: 2 | 3;
  gender: GenderType;
  teeColor: TeeColors;
  slope: number;
  cardType: ScoreCardType.CUSTOM | ScoreCardType.MAIN;
  sss?: number;
}

export interface ScoreCard extends _ScoreCard {
  _id: string;
  club: Club;
  holesParams: HoleParam[];
  courseRating: number;
  bogeyRating?: number;
  course: Course;
  ownerId: string;
  type?: "front9" | "back9";
}

export interface ScoreCardInput extends _ScoreCard {
  _id?: string;
  club?: any;
  course: any;
}

export type TeeColors =
  | "white"
  | "yellow"
  | "red"
  | "blue"
  | "green"
  | "orange"
  | "black"
  | "gold"
  | "purple"
  | "tiger";

export type IndexedScoreCards = { [index: string]: ScoreCard };

export interface ScoreCardState {
  scoreCards: IndexedScoreCards;
  clubScoreCards: ScoreCard[];
  count: number;
}
