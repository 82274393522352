import { useState, useEffect } from "react";

import advertAPI from "api/adverts";
import { AdvertTypes } from "types/advert";
import { UserBrifObj } from "types/user";

export interface Activity {
  _id: string;
  user: UserBrifObj;
  dateTime: number;
  req: {
    ip: string;
  };
}

type StateType = {
  activities?: Activity[];
  isLoading: boolean;
};

export const useGameAdvertActivities = (gameId: string, type: AdvertTypes) => {
  const [state, setState] = useState<StateType>({
    isLoading: true,
  });

  useEffect(() => {
    const getAdActivities = async () => {
      try {
        const { data } = await advertAPI.getGameAdvertActivities(gameId, type);
        setState({ activities: data.data?.activities, isLoading: false });
      } catch (error) {
        console.log(error);
        setState({ isLoading: false });
      }
    };

    getAdActivities();
  }, [gameId, type]);

  return state;
};
