import { Action } from "../types/actions";
import ReduxTypes from "../consts/ReduxTypes";
import { deleteReducer, fetchReducer, insertReducer, updateReducer } from "./BaseReducerFunctions";
import { TournamentState, TournamentGames } from "../types/tournament";

const INITIAL_STATE = {
  tournaments: {},
  tournamentGames: {} as TournamentGames,
  count: 0,
};
const KEY = "TOURNAMENT";

export default (state: TournamentState = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case ReduxTypes.getCopyType(KEY):
    case ReduxTypes.getInsertType(KEY):
      return insertReducer(state, action, "tournaments");

    case ReduxTypes.getFetchType(KEY):
      return fetchReducer(state, action, "tournaments");

    case ReduxTypes.getDeleteType(KEY):
      return deleteReducer(state, action, "tournaments");

    case ReduxTypes.getUpdateType(KEY):
      return updateReducer(state, action, "tournaments");

    case ReduxTypes.GET_TOURNAMENT_GAMES:
      return { ...state, tournamentGames: { ...action.payload } };

    case ReduxTypes.PURGE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
