import CompetitionApi from "../api/competitions";
import { BaseActions } from "./BaseActions";
import ReduxTypes from "../consts/ReduxTypes";
import ErrorHandler from "../api/Errorhandler";

export default new (class CompetitionActions extends BaseActions {
  constructor() {
    super(CompetitionApi, "COMPETITION");
  }

  delete = (id: string, active: boolean = false) => {
    return (dispatch: any) => {
      CompetitionApi.delete(id, active)
        .then((res: any) => {
          dispatch({ type: ReduxTypes.getDeleteType(this.key), payload: { id, active } });
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
        });
    };
  };
})();
