import React from "react";
import Selector, { Option } from "../SharedComponents/Selector";

interface Props {
  holesNumber: number;
  onHoleNumberChanged: (value: string | number) => void;
  className : string;
}

export default function HolesNumberPicker(props: Props) {
  const { holesNumber,className } = props;
  const options: Option[] = [
    {
      label: 18,
      value: 18,
    },
    {
      label: 12,
      value: 12,
    },
    {
      label: 9,
      value: 9,
    },
    {
      label: 6,
      value: 6,
    },
  ];

  return <Selector  className={className} value={holesNumber} options={options} variant={"outlined"} onChange={props.onHoleNumberChanged} />;
}
