// @ts-ignore
import React, { Component } from "react";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateUtils from "@date-io/date-fns";
import Config from "../../config";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import AdvertsActions from "../../actions/adverts";
import ClubsActions from "../../actions/clubs";
import { GlobalState } from "../../types/globalState";
import BorderedBox from "../SharedComponents/BorderedBox";
import {
  Button,
  Card,
  CardContent,
  createStyles,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
  WithStyles,
  Grid,
} from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Close, Search } from "@material-ui/icons";
import { showSnackbar } from "../SharedComponents/Notifier";
import LinearProgress from "@material-ui/core/LinearProgress";
import { hideDialog } from "../SharedComponents/Dialog";
import { Advert, AdvertInput, AdvertTypes } from "../../types/advert";
import SearchClub from "../Search/SearchClub";
import { ScoreCard } from "../../types/scoreCard";
import { Club } from "../../types/club";
import { Game } from "../../types/game";
import { HoleParam } from "../../types/hole";
import Selector, { Option } from "../SharedComponents/Selector";
import DropZone from "../SharedComponents/DropZone/DropZone";
import Messages from "../../consts/Messages";
import { TextInput } from "../SharedComponents/TextInput";
import { filter } from "../../utils/profanityFilter";
import { Rules } from "../../types/user";
import AdvertActivities from "./Activities";

const isGame = (item: Game | ScoreCard): item is Game => (item as Game).code !== undefined;

interface Props extends WithStyles {
  updateAdverts(id: string, data: AdvertInput): void;

  deleteAdverts(id: string): void;

  insertAdverts(data: AdvertInput, keepDialog?: boolean, callback?: any): void;

  uploadImage(id: string, key: string, field: string, image: any, callback?: any): void;

  //fetchScoreCards(page: number, limit: number, filter?: any, sort?: string, dir?: string, query?: string): void;

  fetchClubScorecards(clubId: string): void;
  fetchClubGames(clubId: string): void;

  scoreCards: ScoreCard[];
  games: Game[];
  user: any;
  edit?: Advert;
  copy?: Advert;
  loading: boolean;
}
interface State {
  advert: AdvertInput;
  uploadState: any;
  advertId?: string;
  error: any;
  error_msg: any;
  search: string;
}

const ADVERTS: Option[] = [
  { value: AdvertTypes.SCORE_CARD, label: "Score Card" },
  { value: AdvertTypes.LEADER_BOARD, label: "Leaderboard" },
  { value: AdvertTypes.START_SHEET, label: "Start Sheet" },
];

class AdvertSetup extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    let advert: any = {
      club: props.user.rule === Rules.ClubManager ? props.user.club : {},
      holesInfo: {},
      state: "draft",
      name: "",
      type: AdvertTypes.SCORE_CARD,
      info: {},
    };
    let advertId;
    if (props.edit) {
      advert = props.edit;
      advertId = props.edit._id;
    }
    if (props.copy) {
      const { _id, ...data } = props.copy;
      advert = { ...data, state: "draft", name: data.name + " Copy" };
    }
    this.state = {
      advert,
      advertId,
      uploadState: {},
      error: {},
      error_msg: {},
      search: "",
    };
  }

  componentDidMount() {
    const { club, type } = this.state.advert;
    if (!club?._id || !type) return;

    if (type === AdvertTypes.SCORE_CARD) this.props.fetchClubScorecards(club._id);
    else this.props.fetchClubGames(club._id);
  }

  formValidation = () => {
    const { name } = this.state.advert;
    let error: any = {};
    let error_msg: any = {};

    error.name = name.trim().length < 2 || filter.isProfane(name);
    error_msg.name = error.name ? Messages.error.nameShortLength : "";

    this.setState({ error, error_msg });
    return !error.name;
  };
  getUsableScoreCard = () => {
    const { scoreCards } = this.props;
    const { advert } = this.state;

    return scoreCards.filter((s: any) => s.course.club._id === advert.club._id);
  };

  _handleTextFieldChange = (value: string | number, name: string) => {
    this.setState({
      advert: { ...this.state.advert, [name]: value },
    });
  };

  _handleSearch = (value: string) => {
    this.setState({
      search: value.toLowerCase(),
    });
  };

  _handleTypeChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      advert: { ...this.state.advert, type: evt.target.value as AdvertTypes },
    });
    const clubId = this.state.advert.club?._id;
    if (!clubId) return;
    if (evt.target.value === AdvertTypes.SCORE_CARD) this.props.fetchClubScorecards(clubId);
    else this.props.fetchClubGames(clubId);
  };

  _handleClubSelection = (item: Club) => {
    if (item) {
      this.setState({
        advert: {
          ...this.state.advert,
          club: {
            _id: item._id,
            name: item.name,
          },
        },
      });
      if (this.state.advert.type === AdvertTypes.SCORE_CARD) this.props.fetchClubScorecards(item._id);
      else this.props.fetchClubGames(item._id);
    }
    //
    else {
      this.setState({
        advert: { ...this.state.advert, club: {} },
      });
    }
  };
  _handleItemSelection = (item: ScoreCard | Game) => {
    this.setState(
      {
        advert: {
          ...this.state.advert,
          ...(isGame(item) ? { game: item } : { scoreCard: item }),
        },
      },
      () => this._saveDraft(),
    );
  };
  _fileUpdateHandler = (key: string, file: any) => {
    const {
      advertId,
      advert: { type },
    } = this.state;
    if (!advertId || !file) return;

    const field = type === AdvertTypes.SCORE_CARD ? "holesInfo" : "info";

    this.changeUploadState(key, true, false, false);
    this.props.uploadImage(advertId, key, field, file, (err: any, res: any) => {
      if (err) this.changeUploadState(key, false, true, false);
      else {
        this.changeUploadState(key, false, false, true);
        if (type === AdvertTypes.SCORE_CARD) this.updateHolesInfo(+key, "image", res.image);
        else this.updateAdInfo(key, "image", res.image);
      }
    });
  };

  changeUploadState = (key: string, loading: boolean, err: boolean, succeeded: boolean) => {
    this.setState((state: State) => {
      let uploadState: any = { ...state.uploadState };
      uploadState[key] = { loading, err, succeeded };
      return { uploadState };
    });
  };

  updateHolesInfo = (holeNumber: number, key: string, value: any) => {
    this.setState((state: State) => {
      let holesInfo = state.advert.holesInfo;
      holesInfo[holeNumber] = {
        ...holesInfo[holeNumber],
        holeNumber,
        [key]: value,
      };
      return {
        advert: {
          ...state.advert,
          holesInfo,
        },
      };
    });
  };

  updateAdInfo = (type: string, key: string, value: any) => {
    this.setState((state: State) => {
      const { info } = state.advert;
      const values = info[type] || {};

      return {
        advert: {
          ...state.advert,
          info: { ...info, [type]: { ...values, [key]: value } },
        },
      };
    });
  };

  _saveDraft = () => {
    const { advert, advertId } = this.state;
    if (advertId) return;

    this.props.insertAdverts(advert, true, (err: any, res: any) => {
      if (!err) {
        this.setState({ advertId: res._id });
      }
    });
  };
  _submitForm = () => {
    const { advertId } = this.state;
    const { advert } = this.state;
    const newAdvert: any = {
      ...advert,
      name: advert.name.trim(),
    };
    delete newAdvert._id;
    if (this.formValidation()) {
      this.props.updateAdverts(advertId!, { ...newAdvert, state: "inactive" });
    } else {
      showSnackbar("Form is not valid, check items");
    }
  };
  _cancelForm = () => {
    const { advertId, advert } = this.state;
    if (advertId && advert.state === "draft") {
      this.props.deleteAdverts(advertId);
    }
    hideDialog();
  };

  renderDocsList(cards: Game[] | ScoreCard[]) {
    const { classes } = this.props;
    const { scoreCard, game } = this.state.advert;
    // @ts-ignore
    const items = cards.filter((el: ScoreCard | Game) => {
      if (!isGame(el)) return true;
      return el.name.toLowerCase().includes(this.state.search) || el.code.toLowerCase().includes(this.state.search);
    });

    return items.map((item: ScoreCard | Game) => (
      <Card
        key={item._id}
        className={classes.scoreCardItem}
        style={scoreCard?._id === item._id || game?._id === item._id ? { backgroundColor: "rgba(51,161,54,0.79)" } : {}}
      >
        <CardContent
          className={classes.cardContent}
          onClick={() => {
            this._handleItemSelection(item);
          }}
        >
          <Typography variant="subtitle2" style={{ marginBottom: 10 }}>
            <strong>{item.name}</strong>
          </Typography>

          {isGame(item) ? (
            <Typography variant="body2" component="p">
              {item.code}
            </Typography>
          ) : (
            <>
              <Typography className={classes.pos} color="textSecondary">
                {item.gender === "men" ? "Men" : "Ladies"}/{item.teeColor}
              </Typography>
              <Typography variant="body2" component="p">
                {item.course.name}
              </Typography>
            </>
          )}
        </CardContent>
      </Card>
    ));
  }
  renderDocs() {
    const { type } = this.state.advert;
    const sortedGames = this.props.games?.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
    const docs = type === AdvertTypes.SCORE_CARD ? this.props.scoreCards : sortedGames;

    if (!docs) return <p>No documents found!</p>;

    return (
      <div style={{ display: "flex", height: 210, maxWidth: "100%", overflowX: "auto", margin: "20px 0" }}>
        {this.renderDocsList(docs)}
      </div>
    );
  }
  renderActionButton() {
    const { classes, loading } = this.props;
    if (loading)
      return (
        <div className={classes.loadingContainer}>
          <LinearProgress color="primary" variant={"indeterminate"} />
        </div>
      );
    else
      return (
        <div className={classes.actionButtonContainer}>
          <Button
            fullWidth
            className={classes.actionButton}
            variant={"contained"}
            color={"primary"}
            onClick={this._submitForm}
          >
            Save
          </Button>
          <Button
            fullWidth
            className={classes.actionButton}
            variant={"contained"}
            color={"secondary"}
            onClick={this._cancelForm}
          >
            Cancel
          </Button>
        </div>
      );
  }
  renderImagePreview(image: string, deleteHandler: VoidFunction) {
    const imageName = image.split("_")[1]?.split(".")[0];

    return (
      <div style={{ position: "relative" }}>
        <img
          src={Config.API_URL + image}
          style={{ width: "100%", height: "auto", borderRadius: 8 }}
          alt="Advertisement"
        />
        <span style={{ position: "absolute", bottom: 5, left: 0, background: "rgb(255 255 255 / 80%)" }}>
          {imageName}
        </span>
        <IconButton
          size={"small"}
          style={{ top: 8, left: 8, position: "absolute", backgroundColor: "rgba(55,55,55,0.78)" }}
          onClick={deleteHandler}
        >
          <Close style={{ color: "rgb(255,255,255)" }} />
        </IconButton>
      </div>
    );
  }
  renderScoreCardForm() {
    const { scoreCard, holesInfo } = this.state.advert;
    const holeNumbers = scoreCard.holesParams!.map((param: HoleParam) => param.number);
    const { uploadState } = this.state;
    const advertTypes: Option[] = [
      { value: "fs", label: "Full Screen" },
      { value: "top", label: "Top Dialog" },
      { value: "dialog", label: "Middle Dialog" },
      { value: "bottom", label: "Bottom Dialog" },
    ];
    const advertStates: Option[] = [
      { value: "active", label: "Active" },
      { value: "inactive", label: "inActive" },
    ];
    return (
      <Table style={{ width: 940 }}>
        <TableHead>
          <TableRow>
            <TableCell>Hole</TableCell>
            <TableCell>Image</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>State</TableCell>
            {/*<TableCell>Statistics</TableCell>*/}
          </TableRow>
        </TableHead>
        <TableBody>
          {holeNumbers.map((holeNumber: number) => (
            <TableRow key={holeNumber}>
              <TableCell style={{ width: 64 }} align={"center"}>
                {holeNumber}
              </TableCell>
              <TableCell style={{ width: 340 }}>
                {holesInfo?.[holeNumber] && holesInfo[holeNumber].image ? (
                  this.renderImagePreview(holesInfo[holeNumber].image, () => {
                    this.updateHolesInfo(holeNumber, "image", undefined);
                  })
                ) : (
                  <>
                    {uploadState[holeNumber] && uploadState[holeNumber].loading ? <LinearProgress /> : null}
                    {uploadState[holeNumber] && uploadState[holeNumber].err ? "Upload failed" : ""}
                    <DropZone onUpdateFiles={(files: any) => this._fileUpdateHandler(`${holeNumber}`, files)} />
                  </>
                )}
              </TableCell>
              <TableCell>
                <Selector
                  value={holesInfo?.[holeNumber] && holesInfo[holeNumber].type ? holesInfo[holeNumber].type : ""}
                  options={advertTypes}
                  variant={"outlined"}
                  size="small"
                  onChange={value => this.updateHolesInfo(holeNumber, "type", value)}
                />
              </TableCell>
              <TableCell>
                <Selector
                  value={holesInfo?.[holeNumber] && holesInfo[holeNumber].state ? holesInfo[holeNumber].state : ""}
                  options={advertStates}
                  variant={"outlined"}
                  onChange={value => this.updateHolesInfo(holeNumber, "state", value)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  renderGameAdForm() {
    const { info = {} } = this.state.advert;
    const { uploadState } = this.state;
    const advertTypes: Option[] = [
      { value: "logo", label: "Logo" },
      { value: "head", label: "Page Head" },
      { value: "base", label: "Page Base" },
      { value: "sidebar", label: "Page Sidebar" },
      { value: "refresh", label: "Page Refresh" },
    ];
    const advertStates: Option[] = [
      { value: "active", label: "Active" },
      { value: "inactive", label: "inActive" },
    ];

    return (
      <Table style={{ width: 940 }}>
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell>Image</TableCell>
            <TableCell>State</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {advertTypes.map(adv => (
            <TableRow key={adv.value}>
              <TableCell>{adv.label}</TableCell>
              <TableCell style={{ width: 340 }}>
                {info[adv.value]?.image ? (
                  this.renderImagePreview(info[adv.value].image, () => {
                    this.updateAdInfo(adv.value, "image", undefined);
                  })
                ) : (
                  <>
                    {uploadState[adv.value]?.loading ? <LinearProgress /> : null}
                    {uploadState[adv.value]?.err ? "Upload failed" : ""}
                    <DropZone onUpdateFiles={(files: any) => this._fileUpdateHandler(adv.value, files)} />
                  </>
                )}
              </TableCell>

              <TableCell>
                <Selector
                  value={info[adv.value]?.state || ""}
                  options={advertStates}
                  variant={"outlined"}
                  onChange={value => this.updateAdInfo(adv.value, "state", value)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  render() {
    const { classes, user, edit, copy } = this.props;
    const { error, error_msg, search } = this.state;
    const { club, scoreCard, name, type, game, expiresAt, activatesAt, maxViews, description } = this.state.advert;

    return (
      <Paper className={classes.paper}>
        <Typography variant={"h5"} className={classes.title}>
          {copy ? "Duplicate Advertisement" : "Advertisement Setup"}
        </Typography>

        {/* Advert type */}
        <RadioGroup name="type" value={type} onChange={this._handleTypeChange}>
          {ADVERTS.map(adv => (
            <FormControlLabel
              style={edit || copy ? { display: type !== adv.value ? "none" : "block" } : {}}
              value={adv.value}
              control={<Radio />}
              label={adv.label}
            />
          ))}
        </RadioGroup>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <MuiPickersUtilsProvider utils={DateUtils}>
              <KeyboardDatePicker
                variant="dialog"
                format="dd/MM/yyyy"
                margin="normal"
                inputVariant="outlined"
                label="Activation Date"
                value={activatesAt || null}
                fullWidth
                onChange={(date: Date | null) => {
                  this.setState({ advert: { ...this.state.advert, activatesAt: date } });
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item xs={12} sm={6}>
            <MuiPickersUtilsProvider utils={DateUtils}>
              <KeyboardDatePicker
                variant="dialog"
                format="dd/MM/yyyy"
                margin="normal"
                inputVariant="outlined"
                label="Expiration Date"
                value={expiresAt || null}
                disablePast
                fullWidth
                onChange={(date: Date | null) => {
                  this.setState({ advert: { ...this.state.advert, expiresAt: date } });
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          {type !== AdvertTypes.SCORE_CARD && (
            <Grid item xs={12} sm={6}>
              <TextInput
                value={maxViews}
                onChange={this._handleTextFieldChange}
                name="maxViews"
                type="number"
                label="Maximum Views"
                variant="outlined"
                fullWidth
                inputProps={{ min: 0, step: 1 }}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextInput
              value={description}
              onChange={this._handleTextFieldChange}
              name="description"
              multiline
              rows={4}
              label="Description"
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>

        {user.rule === Rules.Admin ? (
          <BorderedBox title={"Club"} variant={"h6"} padding={"none"}>
            <SearchClub dialog onClubSelected={this._handleClubSelection} defaultSelectedItem={club} />
          </BorderedBox>
        ) : null}
        {club && club._id ? (
          <>
            <div className={classes.inputBox}>
              <TextInput
                label={"Name"}
                margin={"dense"}
                variant={"outlined"}
                value={name}
                error={error.name}
                helperText={error_msg.name}
                captalize
                name={"name"}
                onChange={this._handleTextFieldChange}
                fullWidth
              />
              {type !== AdvertTypes.SCORE_CARD && (
                <TextInput
                  placeholder="Game Code or Game Name"
                  margin={"dense"}
                  variant={"outlined"}
                  value={search}
                  onChange={this._handleSearch}
                  fullWidth
                  style={{ marginLeft: 15 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </div>
            {this.renderDocs()}
          </>
        ) : null}
        {scoreCard?._id && type === AdvertTypes.SCORE_CARD ? this.renderScoreCardForm() : null}
        {game?._id && (type === AdvertTypes.LEADER_BOARD || type === AdvertTypes.START_SHEET)
          ? this.renderGameAdForm()
          : null}
        {this.renderActionButton()}

        {edit && game && <AdvertActivities gameId={game._id} type={type} />}
      </Paper>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    title: {
      fontWeight: "bold",
    },
    paper: {
      display: "block",
      position: "relative",
      left: "50%",
      transform: "translateX(-50%)",
      width: "auto",
      minWith: 360,
      backgroundColor: "rgba(255,255,552,0.85)",
      padding: theme.spacing(2),
    },
    inline: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    cardContent: {
      flexGrow: 1,
      maxHeight: 131,
      textAlign: "center",
      cursor: "pointer",
    },
    actionButtonContainer: {
      display: "flex",
    },
    actionButton: {
      marginTop: theme.spacing(1),
    },
    loadingContainer: {
      width: "100%",
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    scoreCardItem: {
      display: "flex",
      flexDirection: "column",
      width: 150,
      minWidth: 150,
      height: 185,
      margin: 4,
      padding: 4,
      boxShadow: "rgb(50 50 93 / 25%) 0px 2px 5px -1px, rgb(0 0 0 / 30%) 0px 1px 3px -1px",

      "&:hover": {
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
      },
    },
    inputBox: {
      display: "flex",
      margin: "15px 0",
    },
  });
const mapStateToProps = (state: GlobalState) => {
  const { user } = state.auth;
  const { loading } = state.general;
  const { clubScoreCards } = state.scoreCards;
  return {
    user,
    loading,
    scoreCards: clubScoreCards || [],
    games: state.clubs.games || [],
  };
};

export default connect(mapStateToProps, {
  insertAdverts: AdvertsActions.insert,
  updateAdverts: AdvertsActions.update,
  deleteAdverts: AdvertsActions.delete,
  uploadImage: AdvertsActions.uploadImage,
  //fetchScoreCards: ScoreCardsActions.fetch,
  fetchClubScorecards: ClubsActions.getScorecards,
  fetchClubGames: ClubsActions.getGames,
})(withStyles(styles)(AdvertSetup));
