// @ts-nocheck
export interface ShareInput {
  title: string;
  text: string;
  files?: any[];
  url?: string;
}
export const share = (input: ShareInput) => {
  console.log(input.files || "no file");
  if (window.navigator && window.navigator.share) {
    window.navigator
      .share({ text: input.text, title: input.title, files: input.files, url: input.url })
      .catch((err: Error) => {
        console.log(`share error ${err.name} ${err.message}`);
      });
  } else alert(`Your system doesn't support share.`);
};

const _formatList = (list: string[]) => list.join(",").replace(/\s/g, "");

export const mailToString = (emails: string[], subject: string, body: string, cc: string[], bcc: string[]) => {
  let mail = document.createElement("a");
  mail.href = `mailto:${_formatList(emails)}${`?subject=${subject}`}&${`body=${body}`}&${`cc=${_formatList(
    cc,
  )}`}&${`bcc=${_formatList(bcc)}`}`;
  mail.click();
};
