export const addDays = (date: Date, days: number) => {
  date.setDate(date.getDate() + days);
  return date;
};

export const calculateAge = (dob: Date | null) => {
  if (!dob) return "";
  var age_dt = new Date(Date.now() - dob.getTime());
  var year = age_dt.getUTCFullYear();
  return Math.abs(year - 1970);
};
