import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { GlobalState } from "../../types/globalState";
import { IconButton, WithStyles } from "@material-ui/core";
import { List } from "@material-ui/icons";
import HandicapActions from "../../actions/handicaps";
import MyTable, { Column, TableAction, TableState } from "../SharedComponents/MyTable";
import { showDialog } from "../SharedComponents/Dialog";
import { Handicap, Handicaps } from "../../types/handicap";
import ListStyles from "../../SharedStyles/ListStyle";
import HandicapsList from "./HandicapsList";
import AddHandicapForm from "./AddHandicapForm";
import { Competition } from "types/competition";
import CompetitionActions from "actions/competitions";

interface Props extends WithStyles {
  fetchHnadicapLists(page: number, limit: number, filter?: any, sort?: string, dir?: string, query?: string): void;
  getHandicapInfo(id: string, callback?: any): void;
  fetchCompetitionsList(
    page: number,
    limit: number,
    filter?: string,
    sort?: string,
    dir?: string,
    query?: string,
  ): void;
  insertHandicap(date: any, keepDialog?: boolean, callback?: any): void;
  competitions: Competition[];
  handicaps: Handicaps[];
  loading: boolean;
  count: number;
}
interface State {
  [inde: string]: any;
}

class HandicapssListTable extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      openDialog: false,
    };
    this.props.fetchHnadicapLists(1, 50, null, "player.lastName");
    this.props.fetchCompetitionsList(1, 100);
  }

  prepareData = (items: any) => {
    return items.map((item: Handicaps) => ({
      ...item,
      customHandicaps: item.handicaps.length,
      name: item.player.name,
      lastName: item.player.lastName,
      username: item.player.username || "---",
      playerId: item.player._id,
    }));
  };

  _onTableChange = (actions: TableAction, state: TableState) => {
    this.props.fetchHnadicapLists(
      state.page + 1,
      state.pageSize,
      undefined,
      state.sort.field,
      state.sort.dir,
      state.query,
    );
  };

  _onAddItem = () => {
    showDialog(
      <AddHandicapForm
        competitions={this.props.competitions}
        onSubmit={data => {
          this.props.insertHandicap(data, false);
        }}
      />,
      "xl",
      false,
      true,
      true,
      "Add new custom handicap",
    );
  };

  onRowClick = (item: any) => {
    this.props.getHandicapInfo(item._id, (error: any, data: Handicap[]) => {
      showDialog(
        <HandicapsList player={item.player} id={item._id} />,
        "xl",
        false,
        true,
        true,
        `${item.name} ${item.lastName}`,
      );
    });
  };

  render() {
    const { classes, handicaps, loading, count } = this.props;
    const onRowClick = this.onRowClick;
    const columns: Column[] = [
      {
        field: "name",
        title: "Name",
      },
      {
        field: "lastName",
        title: "Last Name",
      },
      {
        field: "username",
        title: "Username",
      },
      {
        field: "customHandicaps",
        title: "Custom Handicaps Count",
      },
      {
        field: "playerId",
        title: "Handicaps",
        handler(item: any, field: any): JSX.Element {
          return (
            <IconButton onClick={() => onRowClick(field)}>
              <List />
            </IconButton>
          );
        },
      },
    ];
    return (
      <div className={classes.paper}>
        <MyTable
          title={"Custom Handicaps"}
          loading={loading}
          count={count}
          data={this.prepareData(handicaps)}
          defaultOrderBy={"lastName"}
          columns={columns}
          searchEnabled
          onTableChange={this._onTableChange}
          onAdd={this._onAddItem}
        />
      </div>
    );
  }
}

const styles = ListStyles({});
const mapStateToProps = (state: GlobalState) => {
  const { handicaps, count } = state.handicaps;
  const { loading } = state.general;
  const { competitions } = state.competitions;
  const { user } = state.auth;

  return {
    handicaps: handicaps ? Object.values(handicaps) : [],
    competitions: competitions ? Object.values(competitions).filter(cmp => cmp.owner?._id === user._id) : [],
    loading,
    count,
  };
};

export default connect(mapStateToProps, {
  fetchHnadicapLists: HandicapActions.fetch,
  getHandicapInfo: HandicapActions.getHandicapInfo,
  insertHandicap: HandicapActions.insert,
  fetchCompetitionsList: CompetitionActions.fetch,
})(withStyles(styles)(HandicapssListTable));
