import React, { Component } from "react";
import { connect } from "react-redux";
import Drawer from "./Drawer";
import { User } from "../types/user";
import { GlobalState } from "../types/globalState";
import Authentication from "./Authentication";
import { decodeToken } from "../utils/Encryption";
import config from "../config";

interface Props {
  user: User;
}

class Contents extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  isLoggedIn = () => !!decodeToken(localStorage.getItem(config.STORAGE.TOKEN)) && !!this.props.user.access_token;

  render() {
    return this.isLoggedIn() ? <Drawer /> : <Authentication />;
  }
}

const mapStateToProps = (state: GlobalState) => {
  const { user } = state.auth;
  return { user };
};

export default connect(mapStateToProps, {})(Contents);
