import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Slider from "@material-ui/core/Slider";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";

const useStyles = makeStyles({
  input: {
    width: 85,

    "& > input": {
      textAlign: "center",
    },
  },
});

export interface SliderInputProps {
  onChange: (value: number) => void;
  initialValue: number;
}

const SliderInput: React.FC<SliderInputProps> = props => {
  const classes = useStyles();
  const ref = React.useRef(true);
  const { initialValue, onChange } = props;
  const [value, setValue] = React.useState<number | string>(initialValue);

  const handleSliderChange = (evt: any, newValue: number | number[]) => {
    if (Array.isArray(newValue)) setValue(newValue[0]);
    else setValue(newValue);
  };

  const handleInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setValue(evt.target.value === "" ? "" : Number(evt.target.value));
  };

  const handleBlur = () => {
    if (value < 10) {
      setValue(10);
    } else if (value > 100) {
      setValue(100);
    }
  };

  React.useEffect(() => {
    // In order to not call onChange for the first render
    if (ref.current) {
      ref.current = false;
      return;
    }
    if (value) onChange(+value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs>
        <Slider
          defaultValue={100}
          step={0.01}
          max={100}
          min={10}
          value={typeof value === "number" ? value : 0}
          onChange={handleSliderChange}
        />
      </Grid>
      <Grid item style={{ paddingTop: 0 }}>
        <Input
          className={classes.input}
          value={value}
          margin="dense"
          onChange={handleInputChange}
          onBlur={handleBlur}
          inputProps={{
            step: 0.01,
            min: 10,
            max: 100,
            type: "number",
          }}
          startAdornment={<InputAdornment position="start">%</InputAdornment>}
        />
      </Grid>
    </Grid>
  );
};

export default SliderInput;
