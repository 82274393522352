export interface IBeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: "accepted" | "dismissed";
    platform: string;
  }>;

  prompt(): Promise<void>;
}
export interface GeneralState {
  loading: boolean;
  installPromptEvt: IBeforeInstallPromptEvent | null;
  videoHelp: boolean;
}

export enum EmailTypes {
  FINAL_RESULT = "leaderboard",
  START_SHEET = "start-sheet",
  EMPTY = "",
}

export type PlayersVerificationType = {
  [playerId: string]: "M" | "P" | "X" | boolean;
};

export interface Mismatch {
  hole: number;
  player: { score: number; name: string };
  marker: { score: number; name: string };
}
