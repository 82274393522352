import { Game } from "./game";

export interface Tournament {
  _id: string;
  name: string;
  seasonStart: Date;
  seasonEnd: Date;
  selectedScoreSystem: ScoreSystem;
  placingScores: number[];
  owner: string;
}
export type TournamentInput = Omit<Tournament, "_id" | "owner">;
export enum ScoreSystem {
  BEST_GROSS = 0,
  BEST_NETT = 1,
  WORST_GROSS = 2,
  WORST_NETT = 3,
  TOTAL_BLOBS = 4,
  TOTAL_BIRDIES = 5,
  TOTAL_EAGLES = 6,
  TOTAL_ALBATROSS = 7,
  TOTAL_POINTS = 8,
  TOTAL_GROSS = 9,
  TOTAL_NETT = 10,
  OOM_GROSS = 11,
  OOM_NETT = 12,
}

export interface TournamentGames {
  id: string;
  points: TournamentPointInfo[];
  games: Game[];
}
export interface TournamentPlayer {
  id: string;
  name: string;
  lastName: string;
  scoresInfo: any[];
  calculatedPoints?: number[];
}

export interface TournamentPointInfo {
  _id: string;
  name: string;
  lastName: string;
  points: number;
}

export type IndexedTournaments = { [index: string]: Tournament };

export interface TournamentState {
  tournaments: IndexedTournaments;
  tournamentGames: TournamentGames;
  count: number;
}
