import React, { Component } from "react";
import { createStyles, Theme, WithStyles } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { Route } from "react-router";
import Dashboard from "./Dashboard";
import Intro from "./Intro";
import UnderConstruction from "./UnderConstruction";
import ScoreCardsList from "./ScoreCard/ScoreCardsListTable";
import GameListTable from "./Game/GameListTable";
import TournamentsList from "./Tournament/TournamentsListTable";
import CompetitionsList from "./Competition/CompetitionsList";
import ClubsList from "./Club/ClubsListTable";
import CoursesList from "./Courses/CoursesListTable";
import AdvertsList from "./Adverts/AdvertsList";
import UsersListTable from "./Users/UsersListTable";
import ClubsListView from "./Club/ClubsListView";
import IndividualLeaderBoard from "./leaderBoard/IndividualLeaderBoard";
import OrganisersListTable from "./Users/club/OrganisersListTable";
import ClubPlayersList from "./Users/club/ClubPlayersList";
import HandicapsListTable from "./Handicaps/HandicapsListTable";
import { Rules, User } from "../types/user";
import PaymentCallback from "./Payment/PaymentCallback";
import WithdrawsListView from "./Withdraws/WithdrawsListView";
interface Props extends WithStyles {
  className: string;
  user: User;
}

class Routes extends Component<Props, any> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  publicRoutes() {
    return (
      <>
        <Route path="/" exact component={Dashboard} />
        <Route path="/intro" exact component={Intro} />
        <Route path="/join/:gameCode" exact component={Dashboard} />
        <Route path="/signup/" component={Dashboard} />
        <Route path="/signup/:gameCode" component={Dashboard} />
        <Route path="/scoreboard" exact component={UnderConstruction} />
        <Route path="/handicaps" exact component={HandicapsListTable} />
        <Route path="/statistics" exact component={UnderConstruction} />
        <Route path="/leaderBoard/:gameId" exact component={IndividualLeaderBoard} />
        <Route path="/paymentCallback" exact component={PaymentCallback} />
      </>
    );
  }

  adminRoutes(user: any) {
    if (user.rule !== Rules.Admin) return null;

    return (
      <>
        <Route path="/users" exact component={UsersListTable} />
        <Route path="/clubs" exact component={ClubsList} />
      </>
    );
  }

  clubRoutes(user: any) {
    if (user.rule !== Rules.Admin && user.rule !== Rules.ClubManager) return null;
    return (
      <>
        <Route path="/courses" exact component={CoursesList} />
        <Route path="/adverts" exact component={AdvertsList} />
        <Route path="/organisers" exact component={OrganisersListTable} />
        <Route path="/players" exact component={ClubPlayersList} />
      </>
    );
  }

  organiserRoutes(user: any) {
    if (user.rule === Rules.Player) return null;
    return (
      <>
        <Route path="/games" exact component={GameListTable} />
        <Route path="/clubListView" exact component={ClubsListView} />
        <Route path="/scoreCards" exact component={ScoreCardsList} />
        <Route path="/tournaments" exact component={TournamentsList} />
        <Route path="/gameRules" exact component={CompetitionsList} />
        <Route path="/withdraws" exact component={WithdrawsListView} />
      </>
    );
  }

  render() {
    const { user } = this.props;
    return (
      <div className={[this.props.className].join(" ")}>
        {this.publicRoutes()}
        {this.adminRoutes(user)}
        {this.clubRoutes(user)}
        {this.organiserRoutes(user)}
      </div>
    );
  }
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(Routes);
