export interface User {
  _id: string;
  rule: RulesType;
  email: string;
  username: string;
  title: string;
  name: string;
  lastName: string;
  avatar: string;
  gender: GenderType;
  access_token: string;
  dob: number;
  licenseType?: LicenseType;
  organisingClub?: string[];
  club?: any;
  homeClub?: any;
  cdh?: number;
  hcp?: number;
  postalCode: string;
  socialHcp: string;
  createDate: number;
  active: boolean;
  canAddUser: boolean;
}

export interface Users {
  createDate: number;
  lastActivity: number;
  id: string;
  rule: RulesType;
  email: string;
  username: string;
  name: string;
  gender: GenderType;
  title?: string;
  lastName?: string;
  avatar?: string;
  club?: {
    id: string;
    name: string;
  };
  homeClub: {
    id: string;
    name: string;
  };
  favClubs?: string[];
  licenseType?: LicenseType;
  organisingClub?: string[];
  cdh?: string;
  hcp?: number;
  policyAccepted: boolean;
  isVerified: boolean;
  active: boolean;
}

export enum License {
  Free = "free",
  SelfLicensed = "self-licensed",
  ClubLicensed = "club-licensed",
}

export const getLicenseName = (license: LicenseType) => {
  switch (license) {
    case License.Free:
      return "Free License";
    case License.SelfLicensed:
      return "Self Licensed";
    case License.ClubLicensed:
      return "Club Licensed";
  }
};

export type LicenseType = License.Free | License.SelfLicensed | License.ClubLicensed;

export interface UserBrifObj {
  _id: string;
  name: string;
  lastName: string;
  username: string;
  email?: string;
  title?: string;
  type?: any;
}

export interface SignupInput {
  email: string;
  username: string;
  password: string;
  title: string;
  name: string;
  lastName: string;
  gender: string;
  homeClub: string;
  postalCode: string;
  socialHcp: string;
  hcp: number;
  cdh: number;
  policyAccepted: boolean;
}

export interface AuthState {
  user: User;
}

export enum Gender {
  Men = "men",
  Women = "women",
}

export type GenderType = Gender.Men | Gender.Women;

export enum Rules {
  Admin = "admin",
  Organiser = "organiser",
  ClubManager = "club",
  Player = "player",
}

export type RulesType = Rules.Admin | Rules.Organiser | Rules.ClubManager | Rules.Player;

export const getRuleName = (rule: RulesType) => {
  switch (rule) {
    case Rules.Admin:
      return "Admin";
    case Rules.ClubManager:
      return "Club Manager";
    case Rules.Organiser:
      return "Organiser";
    case Rules.Player:
      return "Player";
  }
};

export type IndexedUsers = { [index: string]: Users };
export type IndexedUserBrifObj = { [index: string]: UserBrifObj };

export interface UsersState {
  users: IndexedUsers;
  clubPlayers: { items: IndexedUserBrifObj; count: number };
  count: number;
}

export type UsernameType = string | null;
export type EmailType = string | null;
