import { combineReducers } from "redux";
import authReducer from "./authReducer";
import clubsReducer from "./clubsReducer";
import gamesReducer from "./gamesReducer";
import coursesReducer from "./coursesReducer";
import generalReducer from "./generalReducer";
import advertsReducer from "./advertsReducer";
import scoreCardReducer from "./scoreCardReducer";
import tournamentsReducer from "./tournamentsReducer";
import competitionsReducer from "./competitionsReducer";
import componentRouterReducer from "./componentRouterReducer";
import usersReducer from "./usersReducer";
import handicapReducer from "./handicapReducer";
import potsReducer from "./potsReducer";
import withdrawsReducer from "./withdrawsReducer";
import paymentsReducer from "./paymentsReducer";

export default combineReducers({
  router: componentRouterReducer,
  competitions: competitionsReducer,
  tournaments: tournamentsReducer,
  scoreCards: scoreCardReducer,
  general: generalReducer,
  adverts: advertsReducer,
  courses: coursesReducer,
  clubs: clubsReducer,
  games: gamesReducer,
  auth: authReducer,
  users: usersReducer,
  handicaps: handicapReducer,
  pots: potsReducer,
  payments: paymentsReducer,
  withdraws: withdrawsReducer,
});
