import React, { Component } from "react";
import { connect } from "react-redux";
import Moment from "moment";
import Config from "../../config";
import withStyles from "@material-ui/core/styles/withStyles";
import { GlobalState } from "../../types/globalState";
import { Hole, Player } from "../../types/player";
import { Check, Edit } from "@material-ui/icons";
import {
  Button,
  IconButton,
  createStyles,
  FormControl,
  FormControlLabel,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Typography,
  WithStyles,
  Dialog,
} from "@material-ui/core";
import GamesActions from "../../actions/games";
import { HoleParam } from "../../types/hole";
import { StyledTableRow, TightBodyCell, TightHeaderCell } from "../SharedComponents/CustomTableElements";
import { Game, GState } from "../../types/game";
import BorderedBox from "../SharedComponents/BorderedBox";
import GrossPickerDialog from "../Game/GrossPickerDialog";
import ScoreAPi from "../../api/scores";
import { TextInput } from "../SharedComponents/TextInput";
import { filter } from "../../utils/profanityFilter";
import { showSnackbar } from "../SharedComponents/Notifier";
import _ from "lodash";
import { ErrorOnlyCallback } from "../../types/actions";
import { User } from "../../types/user";
import { HoleSigns } from "../../types/hole";
import { getHandicapDisplay, isCombinedGame } from "utils";
import PlayerName from "components/SharedComponents/PlayerName";
import { hcpConvertor } from "utils/hcpConvertor";

interface Props extends WithStyles {
  dangerouslyUpdateScore(
    gameId: string,
    playerId: string,
    scoreId: string,
    data: any,
    holes: any,
    callback?: ErrorOnlyCallback,
  ): void;
  player: any;
  loading: any;
  game: any;
  user: User;
  closeDialog: VoidFunction;
}
interface State {
  [inde: string]: any;
  game: Game;
  reason: string;
  comments: string;
  nofinish: boolean;
  disqualified: boolean;
  openPickerDialog: boolean;
  pickerHoleParams: HoleParam;
  hcp: string;
  hci: string;
  err_comments: boolean;
  err_hcp: boolean;
  selectedScorerId: string;
}
enum VerifyStatus {
  VERIFIED,
  MANUALY_VERIFIED,
  UNVERIFIED,
}

class ScoreCardSubmition extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const player = props.game!.players[props.player._id];
    const selectedScorerId: any = _.isEmpty(player.uholes)
      ? Object.keys(props.game.players)[0]
      : this.getFirstholeScorer(player.uholes, props.game!.players);
    this.state = {
      game: { ...props.game },
      reason: player.reason ? player.reason : "",
      comments: player.comments ? player.comments : "",
      nofinish: player.nofinish ? player.nofinish : false,
      disqualified: player.disqualified ? player.disqualified : false,
      openPickerDialog: false,
      pickerHoleParams: {} as HoleParam,
      hcp: getHandicapDisplay(player.hcp),
      hci: getHandicapDisplay(player.hci),
      err_comments: false,
      err_hcp: false,
      selectedScorerId: selectedScorerId,
    };
  }

  getFirstholeScorer = (uholes: any, players: { [index: string]: Player }) => {
    const firstHole = Object.keys(uholes)[0];
    const firstScorerPlayer = Object.keys(uholes[firstHole].grosses)[0];
    //If organizer who hasn't joined the game, used QS
    return players[firstScorerPlayer] ? firstScorerPlayer : Object.keys(players)[0];
  };

  summation(holeParams: HoleParam[], key: string): number {
    let sum = 0;
    Object.values(holeParams).forEach((param: HoleParam) => {
      if (param[key] && typeof param[key] === "number") {
        sum += param[key] as number;
      }
    });
    return sum;
  }

  getStartingHoleIndex = (): number => {
    const player = this.getPlayer();
    if (player && player.group && player.group.startingHole) {
      return player.group.startingHole - 1;
    }
    return 0;
  };
  getGame = (): Game => {
    return this.state.game;
  };
  getPlayer = (): Player | null => {
    const game = this.getGame();
    const { player } = this.props;
    if (!game) return null;
    return game!.players[player._id];
  };
  getScoreCard = () => {
    const { user, player } = this.props;
    const game = this.getGame();
    const startingHoleIndex = this.getStartingHoleIndex();
    if (user && game && game.scoreCards) {
      const cardId = game.players[player._id].teeColor ? game.players[player._id].teeColor.scoreCardId : null;
      let sc: any = { ...Object.values(game.scoreCards)[0] };
      if (cardId) sc = { ...game.scoreCards.find(x => x._id === cardId) };
      if (sc.holesParams) {
        sc.holesParams = [...sc.holesParams.slice(startingHoleIndex), ...sc.holesParams.slice(0, startingHoleIndex)];
      }
      return sc;
    }
    return null;
  };
  getScoringFormat = () => {
    const game = this.getGame();
    return typeof game?.competition === "object" ? game.competition.scoringFormat.toString() : "undefined";
  };

  _handleChangeReason = (event: any) => {
    this.setState({ reason: event.target.value });
  };
  handleChangeScorer = (event: any) => {
    this.setState({ selectedScorerId: event.target.value });
  };
  _handleCommentsChange = (value: string, name: string) => {
    this.setState({ [name]: value });
  };

  _handleHandicapIndexChange = (evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const hci = evt?.target.value;
    this.setState({ hci });
  };

  _handleHandicapChange = async (event: any) => {
    const hcp = event.target.value;
    const player = this.getPlayer();
    if (!player) return;

    const points = await this.recalculatePoints(player?.holes, hcp);
    this.setState((state: State) => {
      return {
        ...state,
        hcp: hcp,
        game: {
          ...state.game,
          players: {
            ...state.game.players,
            [player._id]: {
              ...state.game.players[player._id],
              points: points,
            },
          },
        },
      };
    });
  };

  _handleSwitchChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ [field]: event.target.checked as boolean });
  };

  recalculatePoints = async (holes: any, hcp: string) => {
    const game = this.getGame();
    if (!game) return;

    const scoreCard = this.getScoreCard();
    return (
      await ScoreAPi.calculateScores(game._id, scoreCard._id, Object.values(holes), parseFloat(hcpConvertor(hcp)))
    ).data;
  };

  _updateHoles = (
    gross: any,
    holeNumber: number,
    holesCount: number,
    dnfSelected: boolean,
    holesObj: Hole,
    playersId?: string[],
  ) => {
    const holes: { [index: number]: any } = {};
    const allHoles = Array.from({ length: holesCount }, (_, i) => i + 1);

    const fillScores = (holeNumbers: number[], g: any) => {
      holeNumbers.forEach(i => {
        holes[i] = {
          gross: g,
          holeNumber: i,
          ...(playersId
            ? {
                grosses: {
                  ...playersId.reduce(
                    (acc, id) => ({
                      ...acc,
                      [id]: g,
                    }),
                    {},
                  ),
                },
              }
            : {}),
        };
      });
    };

    console.log("UPDATE HOLES");

    // DNP
    if (gross === HoleSigns.DNP) {
      const remainingHoles = allHoles.filter(h => holesObj[h]?.gross === undefined);
      fillScores(remainingHoles, gross);
    }
    // DNF
    else if (dnfSelected) {
      fillScores([holeNumber], HoleSigns.DNF);
      const remainingHoles = allHoles.filter(h => holesObj[h]?.gross === undefined && h !== holeNumber);

      fillScores(remainingHoles, HoleSigns.DNP);
    } else {
      fillScores([holeNumber], gross);
    }

    return holes;
  };

  _handleGrossChange = async (teePlayer: Player | null, holeNumber: number, gross: any, dnfSelected: boolean) => {
    const game = this.getGame();
    const player = this.getPlayer();
    if (!player) return;
    if (gross !== "PU" && gross !== "DNF" && gross !== "DNP") gross = parseInt(gross);

    const { hcp } = this.state;

    const holes = {
      ...player.holes,
      ...this._updateHoles(gross, holeNumber, game.competition.holesNumber, dnfSelected, player.holes),
    };

    let uholes = { ...(player.uholes || {}) };

    if (isCombinedGame(game.competition)) {
      if (!teePlayer) return;

      uholes = {
        ...uholes,
        ...this._updateHoles(gross, holeNumber, game.competition.holesNumber, dnfSelected, player.holes, [
          teePlayer._id,
        ]),
      };
    } else {
      const scorers = uholes[1] && uholes[1].grosses ? Object.keys(uholes[1].grosses) : [this.state.selectedScorerId];
      // const grosses = uholes[1] && uholes[1].grosses ? uholes[1].grosses : {};
      uholes = {
        ...uholes,
        ...this._updateHoles(gross, holeNumber, game.competition.holesNumber, dnfSelected, player.holes, scorers),
      };
    }

    const points = await this.recalculatePoints(holes, hcp);
    this.setState((state: State) => {
      return {
        ...state,
        game: {
          ...state.game,
          players: {
            ...state.game.players,
            [player._id]: {
              ...state.game.players[player._id],
              points,
              holes,
              uholes,
            },
          },
        },
      };
    });
  };

  _submitForm = () => {
    const hcpRegex = RegExp(/^\+?\d{1,2}(?:\.\d)?$/gm);
    const { disqualified, reason, comments, nofinish, hcp, hci } = this.state;
    const err_comments = filter.isProfane(comments);
    if (parseFloat(hci) < 0 || parseFloat(hci) > 60 || !hcpRegex.test(`${hci}`)) {
      this.setState({ err_hcp: true });
      showSnackbar("Handicap index is invalid");
      return;
    }
    this.setState({ err_hcp: false });
    this.setState({ err_comments });
    const player = this.getPlayer();
    const game = this.getGame();
    if (!err_comments) {
      this.props.dangerouslyUpdateScore(
        game._id,
        player!._id,
        player!.scoreId,
        {
          uholes: this.setUholesFromChangedGrosses(game.players[player!._id]),
          reason,
          comments: comments.trim(),
          nofinish,
          disqualified,
          hcp: hcpConvertor(hcp),
          hci: hcpConvertor(hci),
          playerId: player?._id,
        },
        game.players[player!._id].holes,
        err => {
          if (!err) this.props.closeDialog();
        },
      );
    } else {
      showSnackbar("Form is not valid, check items");
    }
  };

  setUholesFromChangedGrosses = (player: any) => {
    const uholes = { ...player.uholes };
    Object.keys(player.holes).forEach(key => {
      if (player.holes[key].gross) {
        const grosses = Object.keys(player.uholes[key].grosses);
        if (grosses.length === 1) {
          uholes[key].grosses[grosses[0]] = player.holes[key].gross;
        }
      }
    });

    return uholes;
  };

  _showGrossPicker = (param: HoleParam) => {
    this.setState({
      openPickerDialog: true,
      pickerHoleParams: param,
    });
  };

  calculateHoleNumber(number: number) {
    const scoreCard = this.getScoreCard();
    if (!scoreCard || !scoreCard.name) return number;
    const name = scoreCard.name.toLowerCase();
    const { holesNumber } = scoreCard.course;
    if (name.includes("back")) {
      return number + holesNumber;
    } else if (name.includes("middle")) {
      return number + 6;
    } else return number;
  }

  renderRow = (param: HoleParam, holes: any, uholes: any) => {
    const { classes } = this.props;
    const { selectedScorerId } = this.state;
    const player = this.getPlayer();
    const game = this.getGame();
    const isCombined = isCombinedGame(game.competition);
    const scorerPlayer = game.players[selectedScorerId];
    const isScorerPointAvailable = isCombined ? false : scorerPlayer.holes[param.number];

    if (!player) return;
    const verifyStatus = this.checkIsVerified(player, param);
    const holesObj = _.isEmpty(uholes) || holes;

    return (
      <StyledTableRow>
        <TightBodyCell className={classes.tightCell}>{this.calculateHoleNumber(param.number)}</TightBodyCell>
        <TightBodyCell>{param.yards}</TightBodyCell>
        <TightBodyCell>{param.si}</TightBodyCell>
        <TightBodyCell>{param.par}</TightBodyCell>
        <TightBodyCell>
          {holesObj && holesObj[param.number]
            ? holesObj[param.number].gross ||
              (holesObj[param.number].grosses && holesObj[param.number].grosses[selectedScorerId]) ||
              ""
            : ""}
          <IconButton onClick={() => this._showGrossPicker(param)}>
            <Edit />
          </IconButton>
        </TightBodyCell>
        <TightBodyCell>{holes && holes[param.number] ? player.points.holes[param.number].nett : 0}</TightBodyCell>
        <TightBodyCell>{player.points.holes[param.number].point}</TightBodyCell>
        <TightBodyCell>{this.renderAutoVerified(verifyStatus)}</TightBodyCell>
        <TightBodyCell>{this.renderManualyVerified(verifyStatus)}</TightBodyCell>
        <TightBodyCell>{isScorerPointAvailable ? scorerPlayer.holes[param.number].gross : ""}</TightBodyCell>
        <TightBodyCell>{isScorerPointAvailable ? scorerPlayer.points.holes[param.number].nett : 0}</TightBodyCell>
        <TightBodyCell>{isScorerPointAvailable ? scorerPlayer.points.holes[param.number].point : 0}</TightBodyCell>
      </StyledTableRow>
    );
  };

  canOrganizerEditGross = (verifyStatus: VerifyStatus, holes: any, uholes: any, holeNumber: number) => {
    const { selectedScorerId } = this.state;
    if (verifyStatus === VerifyStatus.VERIFIED) return false;
    if (verifyStatus === VerifyStatus.UNVERIFIED) return false;
    if (_.isEmpty(uholes) || uholes[holeNumber].grosses[selectedScorerId]) return true;
    return false;
  };
  checkIsVerified = (player: Player, holesParams: HoleParam) => {
    if (!player.holes) return VerifyStatus.UNVERIFIED;
    if (!player.holes[holesParams.number]) return VerifyStatus.UNVERIFIED;
    if (!player.holes[holesParams.number].gross) return VerifyStatus.UNVERIFIED;
    if (!_.isEmpty(player.uholes) && Object.values(player.uholes[holesParams.number].grosses).length > 1)
      return VerifyStatus.VERIFIED;
    return VerifyStatus.MANUALY_VERIFIED;
  };

  renderAutoVerified = (verifyStatus: VerifyStatus) =>
    verifyStatus === VerifyStatus.VERIFIED ? <Check style={{ color: "#33a136" }} /> : null;

  renderManualyVerified = (verifyStatus: VerifyStatus) =>
    verifyStatus === VerifyStatus.MANUALY_VERIFIED ? <Check style={{ color: "#ffaf0f" }} /> : null;

  renderSummeryRow = (title: string, holesParams: any, point: any, markerPoint: any) => {
    const player = this.getPlayer();
    if (!player) return null;
    return (
      <StyledTableRow>
        <TightHeaderCell>{title}</TightHeaderCell>
        <TightBodyCell>{this.summation(holesParams, "yards")}</TightBodyCell>
        <TightHeaderCell>{title}</TightHeaderCell>
        <TightBodyCell>{this.summation(holesParams, "par")}</TightBodyCell>
        <TightBodyCell>{point.totalGross}</TightBodyCell>
        <TightBodyCell>{point.totalNett}</TightBodyCell>
        <TightBodyCell>{point.totalPoint}</TightBodyCell>
        <TightBodyCell />
        <TightBodyCell />
        <TightBodyCell>{markerPoint?.totalGross}</TightBodyCell>
        <TightBodyCell>{markerPoint?.totalNett}</TightBodyCell>
        <TightBodyCell>{markerPoint?.totalPoint}</TightBodyCell>
      </StyledTableRow>
    );
  };

  render_Table(
    combination: number,
    holesParams: HoleParam[],
    holes: any,
    player: Player,
    scorerPlayer: Player,
    uholes: any,
  ) {
    if (!holes) holes = {};

    switch (holesParams.length) {
      case 12:
        return this.render_Table_2x6(holesParams, holes, player, scorerPlayer, uholes);
      case 18:
        if (combination === 2) {
          return this.render_Table_2x9(holesParams, holes, player, scorerPlayer, uholes);
        } else {
          return this.render_Table_3x6(holesParams, holes, player, scorerPlayer, uholes);
        }
      case 9:
      case 6:
        return this.render_Table_6_or_9(holesParams, holes, player, scorerPlayer, uholes);
      default:
        return null;
    }
  }
  render_Table_6_or_9 = (holesParams: HoleParam[], holes: any, player: Player, scorerPlayer: Player, uholes: any) => {
    return (
      <TableBody>
        {holesParams.map(param => this.renderRow(param, holes, uholes))}
        {this.renderSummeryRow("Total", holesParams, player.points, scorerPlayer.points)}
      </TableBody>
    );
  };
  render_Table_2x9 = (holesParams: HoleParam[], holes: any, player: Player, scorerPlayer: Player, uholes: any) => {
    const OUT = this.renderSummeryRow(
      "OUT",
      holesParams.slice(0, 9),
      player.points.summary[1],
      scorerPlayer.points.summary[1],
    );
    return (
      <TableBody>
        {holesParams.slice(0, 9).map(param => this.renderRow(param, holes, uholes))}
        {OUT}
        {holesParams.slice(9).map(param => this.renderRow(param, holes, uholes))}
        {this.renderSummeryRow("IN", holesParams.slice(9), player.points.summary[3], scorerPlayer.points.summary[3])}
        {OUT}
        {this.renderSummeryRow("Total", holesParams, player.points, scorerPlayer.points)}
      </TableBody>
    );
  };
  render_Table_3x6 = (holesParams: HoleParam[], holes: any, player: Player, scorerPlayer: Player, uholes: any) => {
    const OUT = this.renderSummeryRow(
      "OUT",
      holesParams.slice(0, 6),
      player.points.summary[0],
      scorerPlayer.points.summary[0],
    );
    return (
      <TableBody>
        {holesParams.slice(0, 6).map(param => this.renderRow(param, holes, uholes))}
        {OUT}
        {holesParams.slice(6, 12).map(param => this.renderRow(param, holes, uholes))}
        {this.renderSummeryRow(
          "MIDDLE",
          holesParams.slice(6, 12),
          player.points.summary[2],
          scorerPlayer.points.summary[2],
        )}
        {holesParams.slice(12).map(param => this.renderRow(param, holes, uholes))}
        {this.renderSummeryRow("IN", holesParams.slice(12), player.points.summary[4], scorerPlayer.points.summary[4])}
        {OUT}
        {this.renderSummeryRow("Total", holesParams, player.points, scorerPlayer.points)}
      </TableBody>
    );
  };
  render_Table_2x6 = (holesParams: HoleParam[], holes: any, player: Player, scorerPlayer: Player, uholes: any) => {
    const OUT = this.renderSummeryRow(
      "OUT",
      holesParams.slice(0, 6),
      player.points.summary[0],
      scorerPlayer.points.summary[0],
    );
    return (
      <TableBody>
        {holesParams.slice(0, 6).map(param => this.renderRow(param, holes, uholes))}
        {OUT}
        {holesParams.slice(6).map(param => this.renderRow(param, holes, uholes))}
        {this.renderSummeryRow("IN", holesParams.slice(6), player.points.summary[1], scorerPlayer.points.summary[1])}
        {OUT}
        {this.renderSummeryRow("Total", holesParams, player.points, scorerPlayer.points)}
      </TableBody>
    );
  };

  renderTopSection(game: Game) {
    const { classes } = this.props;
    const player = this.getPlayer();
    if (!player) return;
    const scoreCard = this.getScoreCard();
    const { hcp, selectedScorerId } = this.state;
    const isCombined = isCombinedGame(game.competition);
    return (
      <div className={classes.inline}>
        <BorderedBox className={"fit-content"} title={"Club Name"} variant={"body2"} padding={"none"}>
          {game.course.club.name}
        </BorderedBox>
        <BorderedBox className={"fit-content"} title={"Course"} variant={"body2"} padding={"none"}>
          {game.course.name}
        </BorderedBox>
        <BorderedBox className={"fit-content"} title={"Date"} variant={"body2"} padding={"none"}>
          {Moment(game.date).format(Config.DATE_DISPLAY_FORMAT)}
        </BorderedBox>
        <BorderedBox className={"fit-content"} title={"Holes"} variant={"body2"} padding={"none"}>
          {game.course.holesNumber}
        </BorderedBox>
        <BorderedBox className={"fit-content"} title={"Start Time"} variant={"body2"} padding={"none"}>
          {Moment(game.date).format(Config.TIME_DISPLAY_FORMAT)}
        </BorderedBox>
        <BorderedBox className={"fit-content"} title={"Finish Time"} variant={"body2"} padding={"none"}>
          {player.updateDate ? Moment(player.updateDate).format(Config.TIME_DISPLAY_FORMAT) : "..."}
        </BorderedBox>
        <BorderedBox className={"fit-content"} title={"Format"} variant={"body2"} padding={"none"}>
          {typeof game.competition === "object" ? game.competition.scoringFormat : "undefined"}
        </BorderedBox>
        <BorderedBox className={"fit-content"} title={"Tees Used"} variant={"body2"} padding={"none"}>
          {scoreCard ? scoreCard.teeColor : "undefined"}
        </BorderedBox>
        <BorderedBox className={"fit-content"} title={"Game Name"} variant={"body2"} padding={"none"}>
          {game.name}
        </BorderedBox>
        <BorderedBox className={"fit-content"} title={"Game Code"} variant={"body2"} padding={"none"}>
          {game.code}
        </BorderedBox>
        <BorderedBox className={"fit-content"} title={"Tournament Name"} variant={"body2"} padding={"none"}>
          {game.tournaments && game.tournaments.length > 0
            ? game.tournaments.map((t: any) => t.name + ", ")
            : "Standalone"}
        </BorderedBox>
        <BorderedBox className={"fit-content"} title={"Player"} variant={"body2"} padding={"none"}>
          <PlayerName isGuest={player.guest} name={player.name} lastName={player.lastName} />
        </BorderedBox>
        <BorderedBox className={"fit-content"} title={"PH"} variant={"body2"} padding={"none"}>
          <TextField
            type="number"
            value={hcp}
            disabled
            style={{ height: 23, width: 100 }}
            onChange={this._handleHandicapChange}
          />
        </BorderedBox>

        <BorderedBox className={"fit-content"} title={"HI"} variant={"body2"} padding={"none"}>
          <TextField
            type="text"
            value={this.state.hci}
            disabled={game.state === GState.Done}
            inputProps={{ min: -10, max: 60 }}
            style={{ height: 23, width: 100 }}
            onChange={this._handleHandicapIndexChange}
          />
        </BorderedBox>

        {isCombined ? null : (
          <BorderedBox className={"fit-content"} title={"Scorer"} variant={"body2"} padding={"none"}>
            <Select value={selectedScorerId} onChange={this.handleChangeScorer}>
              {Object.values(game.players).map((player: any) => {
                return (
                  <MenuItem value={player._id}>
                    <PlayerName isGuest={player.guest} name={player.name} lastName={player.lastName} />
                  </MenuItem>
                );
              })}
            </Select>
          </BorderedBox>
        )}
      </div>
    );
  }
  renderActions() {
    const { comments, reason, nofinish, disqualified } = this.state;
    return (
      <div className={"flex-column "}>
        <TextInput
          className={"normal-margin"}
          label="Comments"
          multiline
          fullWidth
          rows={4}
          value={comments}
          defaultValue=""
          name={"comments"}
          onChange={this._handleCommentsChange}
          variant="outlined"
        />
        <div>
          <FormControlLabel
            control={
              <Switch
                checked={nofinish}
                onChange={this._handleSwitchChange("nofinish")}
                name="checkedB"
                color="primary"
              />
            }
            label="DNF, DNP, WD"
          />
          <FormControlLabel
            control={
              <Switch
                checked={disqualified}
                onChange={this._handleSwitchChange("disqualified")}
                name="checkedB"
                color="primary"
              />
            }
            label="Disqualified"
          />
          {disqualified ? (
            <FormControl fullWidth className={"normal-margin"}>
              <InputLabel id="reason">Reason</InputLabel>
              <Select id="reason" value={reason} onChange={this._handleChangeReason}>
                <MenuItem value={"Failure to attend"}>Failure to attend</MenuItem>
                <MenuItem value={"Wrong score submissio"}>Wrong score submissio</MenuItem>
                <MenuItem value={"Wrong handicap"}>Wrong handicap</MenuItem>
                <MenuItem value={"Breach of rules"}>Breach of rules</MenuItem>
                <MenuItem value={"Leaving game"}>Leaving game</MenuItem>
                <MenuItem value={"Gross misconduct"}>Gross misconduct</MenuItem>
              </Select>
            </FormControl>
          ) : null}
        </div>
        <div className={"normal-margin flex-row space-around"}>
          <Button color={"primary"} variant={"contained"} onClick={this._submitForm}>
            Submit Changes
          </Button>
          <Button color={"primary"} variant={"outlined"} onClick={this.props.closeDialog}>
            Cancel
          </Button>
        </div>
      </div>
    );
  }
  renderGrossPickerDialog() {
    const { openPickerDialog, pickerHoleParams } = this.state;
    const player = this.getPlayer();
    if (!player) return;
    return (
      <Dialog open={openPickerDialog} onBackdropClick={() => this.setState({ openPickerDialog: false })}>
        <GrossPickerDialog
          gameId={this.props.game._id}
          holeNumber={pickerHoleParams.number}
          teamId={player.team?._id}
          onSelect={(gross: any, dnfSelected: boolean, p?: Player) => {
            this._handleGrossChange(p || null, pickerHoleParams.number, gross, dnfSelected);
            this.setState({ openPickerDialog: false });
          }}
        />
      </Dialog>
    );
  }
  render() {
    const { classes, loading } = this.props;
    const { selectedScorerId } = this.state;
    const game = this.getGame();
    if (!game) return null;
    const { holesParams, combination } = this.getScoreCard()!;
    if (!holesParams) return null;
    const player = this.getPlayer();
    if (!player) return <p>Player Not found</p>;
    const scorerPlayer = game.players[selectedScorerId];
    const holes = player.holes;
    const uholes = player.uholes;
    return (
      <Paper className={classes.paper}>
        <div className={classes.inline} style={{ marginBottom: 16 }}>
          <Typography variant={"h5"} className={classes.title}>
            Submission card
          </Typography>
        </div>
        {this.renderTopSection(game)}
        <Table stickyHeader size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell colSpan={4} />
              <TableCell style={{ background: "#ggg" }} colSpan={3}>
                Player
              </TableCell>
              <TableCell colSpan={1} />
              <TableCell colSpan={3}>Marker</TableCell>
            </TableRow>
          </TableHead>
          <TableHead style={{ backgroundColor: "transparent" }}>
            <TableRow>
              <TightHeaderCell align={"left"}> Hole </TightHeaderCell>
              <TightHeaderCell align={"left"}> Yards </TightHeaderCell>
              <TightHeaderCell align={"left"}> SI </TightHeaderCell>
              <TightHeaderCell align={"left"}> Par </TightHeaderCell>
              <TightHeaderCell align={"left"}> Gross </TightHeaderCell>
              <TightHeaderCell align={"left"}> Nett </TightHeaderCell>
              <TightHeaderCell align={"left"} padding={"none"}>
                Pts
              </TightHeaderCell>
              <TightHeaderCell align={"left"}> Auto Verified </TightHeaderCell>
              <TightHeaderCell align={"left"}> Manually Verified </TightHeaderCell>
              <TightHeaderCell align={"left"}> Gross </TightHeaderCell>
              <TightHeaderCell align={"left"}> Nett </TightHeaderCell>
              <TightHeaderCell align={"left"} padding={"none"}>
                Pts
              </TightHeaderCell>
            </TableRow>
          </TableHead>
          {this.render_Table(combination, holesParams, holes, player, scorerPlayer, uholes)}
        </Table>
        {loading ? <LinearProgress /> : null}
        {this.renderActions()}
        {this.renderGrossPickerDialog()}
      </Paper>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    title: {
      fontWeight: "bold",
    },
    paper: {
      display: "block",
      position: "relative",
      left: "50%",
      transform: "translateX(-50%)",
      width: "auto",
      backgroundColor: "rgba(255,255,552,0.85)",
      padding: theme.spacing(2),
    },
    inline: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      flexWrap: "wrap",
      justifyContent: "space-between",
    },
    smallInput: {
      width: 32,
    },
    actionButton: {
      margin: theme.spacing(1),
    },
  });
const mapStateToProps = (state: GlobalState) => {
  const { user } = state.auth;
  const { loading } = state.general;
  return {
    user,
    loading,
  };
};

export default connect(mapStateToProps, {
  dangerouslyUpdateScore: GamesActions.dangerouslyUpdateScore,
})(withStyles(styles)(ScoreCardSubmition));
