import React from "react";
import {
  Theme,
  Checkbox,
  WithStyles,
  Typography,
  Button,
  createStyles,
  Dialog,
  DialogContent,
  IconButton,
  InputAdornment,
  InputBase,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";

interface Props {
  onClick(type: "paid" | "guest"): void;
}

export default function PaymentMethods(props: Props) {
  return (
    <div>
      <List>
        <ListItem button onClick={() => props.onClick("paid")}>
          <ListItemIcon>&#163;</ListItemIcon>
          <ListItemText primary="Mark as paid (by other method)" />
        </ListItem>
        <ListItem button onClick={() => props.onClick("guest")}>
          <ListItemIcon>G</ListItemIcon>
          <ListItemText primary="Mark as Guest" />
        </ListItem>
      </List>
    </div>
  );
}
