import React, { Component } from "react";
import {
  Avatar,
  Collapse,
  Container,
  createStyles,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Theme,
  Typography,
  WithStyles,
} from "@material-ui/core";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import withStyles from "@material-ui/core/styles/withStyles";
import { Link } from "react-router-dom";
import { closeDrawer } from "./index";
import { ExpandLess, ExpandMore, Duo as DuoIcon } from "@material-ui/icons";
import { Gender, getRuleName, Rules, User } from "../../types/user";
import { switchComponent } from "../../actions/componentRouter";
import { logOut } from "../../actions";
import { connect } from "react-redux";
import { Views } from "../Dashboard";
import config from "../../config";
import { showDialog } from "components/SharedComponents/Dialog";
import UserSetup from "components/Users/UserSetup";
import VideoPopup from "components/SharedComponents/VideoPopup";
import SwitchVideoHelp from "components/SharedComponents/SwitchVideoHelp";
import { HELP_VIDEOS } from "consts";
import { GlobalState } from "types/globalState";

interface Props extends WithStyles {
  logOut(): void;
  switchComponent(view: any, props?: any): void;
  drawerWidth: number;
  user: User;
  showVideoHelps: boolean;
}
interface State {
  openOrgMenu: boolean;
  openAdminMenu: boolean;
  openClubMenu: boolean;
}

class DrawerContent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      openOrgMenu: false,
      openAdminMenu: false,
      openClubMenu: false,
    };
  }

  _openOrganiserMenu = () => {
    this.setState({ openOrgMenu: !this.state.openOrgMenu });
  };
  _openAdminMenu = () => {
    this.setState({ openAdminMenu: !this.state.openAdminMenu });
  };
  _openClubMenu = () => {
    this.setState({ openClubMenu: !this.state.openClubMenu });
  };

  getAvatar = (user: User) => {
    let avatar = require("../../assets/images/icons/question_mark_200px.png");

    if (user.avatar) {
      avatar = config.API_URL + user.avatar;
    } else if (user.gender === Gender.Men) {
      avatar = require("../../assets/images/icons/avatar_men.png");
    } else if (user.gender === Gender.Women) {
      avatar = require("../../assets/images/icons/avatar_women.png");
    } else if (user.rule === Rules.ClubManager) {
      avatar = require("../../assets/images/icons/house_100px.png");
    }
    return avatar;
  };

  renderAdminMenu() {
    const { classes } = this.props;
    const { openAdminMenu } = this.state;
    return (
      <div>
        <ListItem button onClick={this._openAdminMenu}>
          <ListItemIcon>
            <img src={require("../../assets/images/icons/admin.png")} width="48" height="48" />
          </ListItemIcon>
          <ListItemText primary="Admin Menu" />
          {openAdminMenu ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openAdminMenu} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested} component={Link} to="/clubs" onClick={closeDrawer}>
              <ListItemText primary="Clubs" />
            </ListItem>
            <ListItem button className={classes.nested} component={Link} to="/courses" onClick={closeDrawer}>
              <ListItemText primary="Course" />
            </ListItem>
            <ListItem button className={classes.nested} component={Link} to="/scoreCards" onClick={closeDrawer}>
              <ListItemText primary="Score Cards" />
            </ListItem>
            <ListItem button className={classes.nested} component={Link} to="/adverts" onClick={closeDrawer}>
              <ListItemText primary="Advertisements" />
            </ListItem>
            <ListItem button className={classes.nested} component={Link} to="/users" onClick={closeDrawer}>
              <ListItemText primary="Users" />
            </ListItem>
            <ListItem button className={classes.nested} component={Link} to="/gameRules" onClick={closeDrawer}>
              <ListItemText primary="Game Rules" />
            </ListItem>
            <ListItem button className={classes.nested} component={Link} to="/games" onClick={closeDrawer}>
              <ListItemText primary="Games" />
            </ListItem>
            <ListItem button className={classes.nested} component={Link} to="/tournaments" onClick={closeDrawer}>
              <ListItemText primary="Tournaments" />
            </ListItem>
            <ListItem button className={classes.nested} component={Link} to="/withdraws" onClick={closeDrawer}>
              <ListItemText primary="My Withdraw" />
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/"
              onClick={() => {
                this.props.switchComponent(Views.ClubsListView);
                closeDrawer();
              }}
            >
              <ListItemText primary="Create a Game" />
            </ListItem>
          </List>
        </Collapse>
      </div>
    );
  }
  renderClubMenu() {
    const { classes } = this.props;
    const { openClubMenu } = this.state;
    return (
      <div>
        <ListItem button onClick={this._openClubMenu}>
          <ListItemIcon>
            <img src={require("../../assets/images/icons/company_100px.png")} width="48" height="48" />
          </ListItemIcon>
          <ListItemText primary="Club Menu" />
          {openClubMenu ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openClubMenu} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested} component={Link} to="/courses" onClick={closeDrawer}>
              <ListItemText primary="Course" />
            </ListItem>
            <ListItem button className={classes.nested} component={Link} to="/scoreCards" onClick={closeDrawer}>
              <ListItemText primary="Score Cards" />
            </ListItem>
            {/* <ListItem button className={classes.nested} component={Link} to="/adverts" onClick={closeDrawer}>
              <ListItemText primary="Advertisments" />
            </ListItem> */}
            <ListItem button className={classes.nested} component={Link} to="/organisers" onClick={closeDrawer}>
              <ListItemText primary="Organisers" />
            </ListItem>
            <ListItem button className={classes.nested} component={Link} to="/players" onClick={closeDrawer}>
              <ListItemText primary="Players" />
            </ListItem>
            <ListItem button className={classes.nested} component={Link} to="/gameRules" onClick={closeDrawer}>
              <ListItemText primary="Game Rules" />
            </ListItem>
            <ListItem button className={classes.nested} component={Link} to="/games" onClick={closeDrawer}>
              <ListItemText primary="My Games" />
            </ListItem>
            <ListItem button className={classes.nested} component={Link} to="/tournaments" onClick={closeDrawer}>
              <ListItemText primary="My Tournaments" />
            </ListItem>
            <ListItem button className={classes.nested} component={Link} to="/withdraws" onClick={closeDrawer}>
              <ListItemText primary="My Withdraw" />
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/"
              onClick={() => {
                this.props.switchComponent(Views.ClubsListView);
                closeDrawer();
              }}
            >
              <ListItemText primary="Create a Game" />
            </ListItem>
          </List>
        </Collapse>
      </div>
    );
  }
  renderOrganiserMenu() {
    const { classes, user } = this.props;
    const { openOrgMenu } = this.state;
    return (
      <div>
        <ListItem button onClick={this._openOrganiserMenu}>
          <ListItemIcon>
            <img src={require("../../assets/images/icons/organiser.png")} width="48" height="48" />
          </ListItemIcon>
          <ListItemText primary="Organiser Menu" />
          <ListItemSecondaryAction style={{ display: "flex", alignItems: "center" }}>
            <VideoPopup styles={{ marginRight: 0 }} videoURL={HELP_VIDEOS.ORGANISER_MENU} />
            {openOrgMenu ? <ExpandLess /> : <ExpandMore />}
          </ListItemSecondaryAction>
        </ListItem>
        <Collapse in={openOrgMenu} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested} component={Link} to="/scoreCards" onClick={closeDrawer}>
              <ListItemText primary="My Score Cards" />
              <ListItemSecondaryAction>
                <VideoPopup videoURL={HELP_VIDEOS.MY_SCORE_CARDS} />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem button className={classes.nested} component={Link} to="/gameRules" onClick={closeDrawer}>
              <ListItemText primary="My Game Rules" />
              <ListItemSecondaryAction>
                <VideoPopup videoURL={HELP_VIDEOS.MY_GAME_RULES} />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem button className={classes.nested} component={Link} to="/games" onClick={closeDrawer}>
              <ListItemText primary="My Games" />
              <ListItemSecondaryAction>
                <VideoPopup videoURL={HELP_VIDEOS.MY_GAMES} />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem button className={classes.nested} component={Link} to="/tournaments" onClick={closeDrawer}>
              <ListItemText primary="My Tournaments" />
              <ListItemSecondaryAction>
                <VideoPopup videoURL={HELP_VIDEOS.MY_TOURNAMENTS} />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem button className={classes.nested} component={Link} to="/withdraws" onClick={closeDrawer}>
              <ListItemText primary="My Withdraw" />
              <ListItemSecondaryAction>
                <VideoPopup videoURL={HELP_VIDEOS.MY_POTS} />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/"
              onClick={() => {
                this.props.switchComponent(Views.ClubsListView);
                closeDrawer();
              }}
            >
              <ListItemText primary="Create a Game" />
              <ListItemSecondaryAction>
                <VideoPopup videoURL={HELP_VIDEOS.CREATE_GAME} />
              </ListItemSecondaryAction>
            </ListItem>
            {user.canAddUser && (
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  showDialog(<UserSetup isPlayer />, false, true);
                }}
              >
                <ListItemText primary="Add New Player" />
                <ListItemSecondaryAction>
                  <VideoPopup videoURL={HELP_VIDEOS.ADD_NEW_PLAYER} />
                </ListItemSecondaryAction>
              </ListItem>
            )}
          </List>
        </Collapse>
      </div>
    );
  }

  renderHeader() {
    const { classes, user } = this.props;

    return (
      <ListItem className={classes.header}>
        <ListItemAvatar>
          <Avatar
            className={classes.avatar}
            style={{ width: 50, height: 50, backgroundColor: "#FFF" }}
            src={this.getAvatar(user)}
          />
        </ListItemAvatar>
        <div className={classes.column}>
          <Typography variant="body1">Welcome {user.name}</Typography>
          <Typography variant="subtitle2">
            {getRuleName(user.rule)} {user.rule === Rules.ClubManager && <span>of {user.club.name}</span>}
          </Typography>
        </div>
      </ListItem>
    );
  }
  renderMenu() {
    const { user } = this.props;
    switch (user.rule) {
      case Rules.Admin:
        return this.renderAdminMenu();
      case Rules.Organiser:
        return this.renderOrganiserMenu();
      case Rules.ClubManager:
        return this.renderClubMenu();
    }
  }
  render() {
    const { drawerWidth, showVideoHelps, classes } = this.props;
    return (
      <Container style={{ minWidth: drawerWidth }}>
        <List>
          {this.renderHeader()}

          {showVideoHelps && (
            <>
              <ListItem button>
                <ListItemIcon style={{ justifyContent: "center" }}>
                  <strong className={classes.new}>New</strong>
                </ListItemIcon>
                <ListItemText primary="Getting Started" />
                <ListItemSecondaryAction>
                  <VideoPopup videoURL={HELP_VIDEOS.GAME_RULES_1} />
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem button>
                <ListItemIcon style={{ justifyContent: "center" }}>
                  <strong className={classes.new}>New</strong>
                </ListItemIcon>
                <ListItemSecondaryAction>
                  <VideoPopup videoURL={HELP_VIDEOS.WELCOME_ORGANISER} />
                </ListItemSecondaryAction>
                <ListItemText primary="Moving to Schala" />
              </ListItem>
            </>
          )}

          <ListItem
            button
            component={Link}
            to="/"
            onClick={() => {
              this.props.switchComponent(Views.DEFAULT);
              closeDrawer();
            }}
          >
            <ListItemIcon>
              <img src={require("../../assets/images/icons/golf_bag.png")} width="48" height="48" />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/"
            onClick={() => {
              this.props.switchComponent(Views.Profile);
              closeDrawer();
            }}
          >
            <ListItemIcon>
              <img src={require("../../assets/images/icons/user.png")} width="48" height="48" />
            </ListItemIcon>
            <ListItemText primary="Profile" />
            <ListItemSecondaryAction>
              <VideoPopup videoURL={HELP_VIDEOS.PROFILE} />
            </ListItemSecondaryAction>
          </ListItem>
          {this.renderMenu()}
          <ListItem button component={Link} to="/scoreboard" onClick={closeDrawer}>
            <ListItemIcon>
              <img src={require("../../assets/images/icons/scoreboard.png")} width="48" height="48" />
            </ListItemIcon>
            <ListItemText primary="Scoreboards" />
          </ListItem>
          <ListItem button component={Link} to="/handicaps" onClick={closeDrawer}>
            <ListItemIcon>
              <img src={require("../../assets/images/icons/golf_ball.png")} width="48" height="48" />
            </ListItemIcon>
            <ListItemText primary="Handicaps" />
            <ListItemSecondaryAction>
              <VideoPopup />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem button component={Link} to="/statistics" onClick={closeDrawer}>
            <ListItemIcon>
              <img src={require("../../assets/images/icons/statistics.png")} width="48" height="48" />
            </ListItemIcon>
            <ListItemText primary="Statistics" />
          </ListItem>

          <ListItem button component={Link} to="/" onClick={this.props.logOut}>
            <ListItemIcon>
              <img src={require("../../assets/images/icons/sign_out_200px.png")} width="48" height="48" />
            </ListItemIcon>
            <ListItemText primary="Sign Out" />
          </ListItem>
          {/* Hints */}
          <ListItem>
            <ListItemIcon>
              <DuoIcon style={{ margin: "0 auto", color: "rgb(255 0 255)" }} />
            </ListItemIcon>
            <ListItemText primary="Video Help Files" />
            <ListItemSecondaryAction>
              <SwitchVideoHelp />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <span>
                  Contact us @{" "}
                  <a style={{ color: "inherit" }} href="mailto:support@schalagolf.com">
                    support@schalagolf.com
                  </a>
                </span>
              }
            />
          </ListItem>
        </List>
        <p className={"text-right"}>Version {process.env.REACT_APP_VERSION}</p>
      </Container>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    avatar: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    header: {
      width: "100%",
      justifyContent: "center",
    },
    nested: {
      marginLeft: theme.spacing(7),
    },
    imageListItem: {
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
    },
    column: {
      flexDirection: "column",
      marginLeft: 8,
    },
    new: {
      color: "red",
      display: "inline-block",
      transform: "rotate(-45deg)",
      fontFamily: "sans-serif !important",
    },
  });

const mapStateToProps = (state: GlobalState) => {
  return { showVideoHelps: state.general.videoHelp };
};

export default connect(mapStateToProps, {
  switchComponent: switchComponent,
  logOut,
})(withStyles(styles)(DrawerContent));
