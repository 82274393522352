import { createStyles, Theme, WithStyles } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import React, { Component } from "react";
import { connect } from "react-redux";
import { GlobalState } from "../../types/globalState";
import GameSelector from "./GameSelector";
import { RouterProps } from "./index";
import GameView from "./GameView";

interface Props extends WithStyles {
  routerProps: RouterProps
}
interface State {
}

class GameManager extends Component<Props, State> {
  render = () => <GameSelector renderContent={(gameId) => <GameView gameId={gameId}/>}/>;
}

const styles = (theme: Theme) => createStyles({
});
const mapStateToProps = (state: GlobalState) => {
  return {};
};

export default connect(mapStateToProps, {})
(withStyles(styles)(GameManager));
