import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Typography, IconButton, Dialog, Chip } from "@material-ui/core";
import { SportsGolf } from "@material-ui/icons";

import BestHoles from "components/Competition/BestHoles";

interface HolesSelectorProps {
  holesCount: number;
  selectedHoles: number[];
  title: string;
  handleChange: (holes: number[]) => void;
}

const HolesSelector: React.FC<HolesSelectorProps> = ({ holesCount, selectedHoles = [], handleChange, title }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState<boolean>(false);

  const handleHoleClick = (hole: number) => {
    // All button
    if (hole === -1) {
      const holes = Array.from({ length: holesCount }, (_, i) => i + 1);
      return handleChange(holes);
    }

    handleChange(selectedHoles.includes(hole) ? selectedHoles.filter(h => h !== hole) : selectedHoles.concat(hole));
  };

  return (
    <>
      <div className={classes.header}>
        <Typography>{title}</Typography>

        <IconButton className={classes.teesBtn} onClick={setOpen.bind(null, true)}>
          <SportsGolf />
        </IconButton>
      </div>

      <div className={classes.holesList}>
        {selectedHoles.map((h, index) => (
          <React.Fragment key={h}>
            <Chip className={classes.hole} style={{ background: "#009e0f", color: "white" }} label={h} />
            {index === 8 ? <br /> : null}
          </React.Fragment>
        ))}
      </div>

      <Dialog open={open} onBackdropClick={setOpen.bind(null, false)}>
        <BestHoles
          holesCount={holesCount}
          selectedHoles={selectedHoles}
          onHoleClicked={handleHoleClick}
          onSubmit={setOpen.bind(null, false)}
        />
      </Dialog>
    </>
  );
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: " flex",
      justifyContent: "space-between",
      marginTop: 20,
    },
    teesBtn: {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
      alignSelf: "flex-start",

      "&:hover": {
        backgroundColor: theme.palette.primary.light,
      },
    },
    holesList: {
      textAlign: "left",
      padding: 5,
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
    },
    hole: {
      marginRight: 2,
      marginTop: 2,
      width: 37,
      height: 37,
      borderRadius: "50%",

      [theme.breakpoints.down("xs")]: {
        width: 50,
        height: 50,
      },
    },
  }),
);

export default HolesSelector;
