import React, { ChangeEvent, Component } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { GlobalState } from "../../types/globalState";
import {
  Button,
  createStyles,
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  LinearProgress,
  MenuItem,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Toolbar,
  Tooltip,
  Typography,
  WithStyles,
} from "@material-ui/core";
import { AddCircle as Add, Close, Edit, Group, PlayCircleFilledWhite } from "@material-ui/icons";
import GameActions from "../../actions/games";
import UserActions from "../../actions/user";
import TeamActions from "../../actions/teams";
import { switchComponent } from "../../actions/componentRouter";
import Paper from "@material-ui/core/Paper";
import { showSnackbar } from "../SharedComponents/Notifier";
import { User } from "../../types/user";
import { Game, IndexedGames } from "../../types/game";
import { ScoreCard } from "../../types/scoreCard";
import { RouterProps, Views } from "./index";
import { showPickerDialog } from "../SharedComponents/PickerDialog";
import { HoleParam } from "../../types/hole";
import { TextInput } from "../SharedComponents/TextInput";
import { filter } from "../../utils/profanityFilter";
import { Player } from "./../../types/player";
import { ErrorOnlyCallback } from "../../types/actions";
import { isGameStandard, getHandicapDisplay } from "utils";
import PlayerName from "components/SharedComponents/PlayerName";
import { hcpConvertor } from "utils/hcpConvertor";

interface Props extends WithStyles {
  UpdateGame(id: string, data: any): void;
  addPlayer(id: string, playerId: string, itsMe: boolean): void;
  removePlayer(id: string, playerId: string): void;
  switchComponent(view: any, props?: any): void;
  changePlayerTeeColor(id: string, playerId: string, scoreCardId: string): void;
  changePlayerTeam(id: string, playerId: string, teamId: string): void;
  getTeams(gameId: string, callback?: any): void;
  insertTeam(name: string, gameId: string, callback?: any): void;
  addPlayerToGroup(id: string, group: string, playerId: string): void;
  removePlayerFromGroup(id: string, group: string, playerId: string): void;
  changePlayerHcp(id: string, playerId: string, hcp: number): void;
  recalculatePlayingHcp(id: string, playerId: string, hci: number): void;
  updateGroup(id: string, name: string, data: any): void;
  toggleScoringPlayer(gameId: string, playerId: string, action: "add" | "remove", callback?: ErrorOnlyCallback): void;
  updateUserHcp(userId: string, hcp: number, callback?: any): void;

  routerProps: RouterProps;
  joinedGames: IndexedGames;
  loading: boolean;
  user: User;
  defaultMode?: "view" | "edit";
  showModeToggle?: boolean;
  closeThisModal?: () => void;
  groupMembers?: Player[];
}
interface State {
  [index: string]: any;

  openSearchDialog: boolean;
  teamName: string;
  err_teamName?: boolean;
  err_msg_teamName: string;
  open: boolean;
  searchText: string;
  mode: "edit" | "view";
}

class ScorerGameSetup extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      openSearchDialog: false,
      teamName: "",
      err_msg_teamName: "",
      open: false,
      mode: props.defaultMode ? props.defaultMode : "view",
      searchText: "",
    };
  }

  getGame = (): Game | null => {
    const { gameId } = this.props.routerProps;
    if (!gameId) return null;
    return this.props.joinedGames[gameId];
  };

  getScoringPlayers = (): string[] => {
    const game = this.getGame();
    return (game && game.players[this.props.user._id].scoringPlayers) || [];
  };

  getStartingHole = (): number => {
    const { group } = this.props.routerProps;
    const game = this.getGame();
    if (!game) return 1;
    const players: any = Object.values(game.players);
    for (let i = 0; i < players.length; i++) {
      if (players[i].group && players[i].group.name === group.name && players[i].group.startingHole) {
        return players[i].group.startingHole;
      }
    }
    return 1;
  };
  getScoreCardsTeeColor = (): any => {
    return Object.values(this.getGame()!.scoreCards).map((item: ScoreCard) => {
      return {
        id: item._id,
        color: item.teeColor,
        name: item.name,
      };
    });
  };

  _onSubmit = () => {
    this.props.switchComponent(Views.GameManager);
  };
  _onAddPlayer = (item: any) => {
    const game = this.getGame();
    if (game) {
      const { players } = game;
      if (players[item._id]) {
        showSnackbar("Player already exist");
      } else {
        this.props.addPlayer(game._id, item._id, item._id === this.props.user._id);
      }
    }
  };
  _onRemovePlayer = (item: any) => {
    const game = this.getGame();
    if (game) {
      let players: any = { ...game.players };
      players[item._id] = null;
      this.props.removePlayer(game._id, item._id);
    }
  };
  _onHandleTeeColorChanges = (item: any, scoreCardId: string) => {
    const game = this.getGame();
    if (game) {
      this.props.changePlayerTeeColor(game._id, item._id, scoreCardId);
    }
  };
  _onHandleTeamChanges = (player: any, teamId: string) => {
    const game = this.getGame();
    if (game) {
      this.props.changePlayerTeam(game._id, player._id, teamId);
    }
  };
  _onHandleChangeStartingHole = (event: React.ChangeEvent<{ value: unknown }>) => {
    const game = this.getGame();
    const { group } = this.props.routerProps;
    if (game) {
      this.props.updateGroup(game._id, group.name, {
        name: group.name,
        startingHole: parseInt(event.target.value as string),
      });
    }
  };
  _handleTextFieldChange = (value: string, name: string) => {
    this.setState({ [name]: value });
  };
  _toggleNewTeamDialog = () => {
    console.log("close modal");
    this.setState((state: State) => ({ open: !state.open }));
  };
  _addNewTeam = () => {
    const { teamName } = this.state;
    const game = this.getGame();
    const err_teamName = teamName.length < 3 || filter.isProfane(teamName);
    const err_msg_teamName = err_teamName ? "Minimum length is 3 character" : "";
    this.setState({ err_teamName, err_msg_teamName });

    if (!err_teamName) {
      this.props.insertTeam(teamName.trim(), game!._id);
      this._toggleNewTeamDialog();
    }
  };
  _onShowHcpEditorDialog = (player: any) => {
    showPickerDialog(
      "Handicap",
      "number",
      (hcp: string) => {
        this.props.changePlayerHcp(this.props.routerProps.gameId!, player._id, parseFloat(hcpConvertor(hcp)));
      },
      player.hcp,
      null,
      null,
      false,
      true,
    );
  };

  _onShowHciEditorDialog = (player: any) => {
    showPickerDialog(
      "Handicap Index",
      "number",
      (hcp: string) => {
        this.props.recalculatePlayingHcp(this.props.routerProps.gameId, player._id, parseFloat(hcpConvertor(hcp)));
      },
      undefined,
      null,
      null,
      false,
      true,
    );
  };

  _onTextFieldChanged = (field: string) => (event: any) => {
    const value = event.target.value as string;
    this.setState({ [field]: value });
  };
  _handleAddPlayer = (player: any) => {
    const { gameId, group } = this.props.routerProps;
    this.props.addPlayerToGroup(gameId, group.name, player._id);
  };
  _handleRemovePlayer = (player: any) => {
    const { gameId, group } = this.props.routerProps;
    this.props.removePlayerFromGroup(gameId, group.name, player._id);
  };
  _toggleMode = () => {
    this.setState((state: State) => {
      return { mode: state.mode === "edit" ? "view" : "edit", searchText: "" };
    });
  };

  renderNewTeamDialog() {
    return (
      <Dialog open={this.state.open} onBackdropClick={this._toggleNewTeamDialog}>
        <DialogContent
          style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: 10 }}
        >
          <TextInput
            label={"Team name"}
            variant={"outlined"}
            value={this.state.teamName}
            error={this.state.err_teamName}
            helperText={this.state.err_msg_teamName}
            captalize
            name={"teamName"}
            onChange={this._handleTextFieldChange}
          />
          <Button onClick={this._addNewTeam} color={"primary"}>
            Submit
          </Button>
          <Button onClick={this._toggleNewTeamDialog} color={"secondary"}>
            Cancel
          </Button>
        </DialogContent>
      </Dialog>
    );
  }
  renderPlayerRow_View(player: Player) {
    const { classes, user } = this.props;
    const game = this.getGame();
    const scoringPlayers = this.getScoringPlayers();
    if (game) {
      if (player) {
        return (
          <TableRow key={player._id}>
            <TableCell component="th" scope="row">
              <PlayerName isGuest={player.guest} name={player.name} lastName={player.lastName} />
            </TableCell>
            <TableCell>
              <FormControl className={classes.margin}>
                <Select
                  labelId="tee-color"
                  id="tee-color"
                  value={player.teeColor ? player.teeColor.scoreCardId : this.getScoreCardsTeeColor()[0].id}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    this._onHandleTeeColorChanges(player, event.target.value as string);
                  }}
                >
                  {this.getScoreCardsTeeColor().map((item: any) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.color}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </TableCell>
            <TableCell>
              <FormControl className={classes.margin}>
                <Select
                  labelId="team"
                  id="team"
                  value={player.team ? player.team._id : ""}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    this._onHandleTeamChanges(player, event.target.value as string);
                  }}
                >
                  {game.teams &&
                    game.teams.map((item: any) => (
                      <MenuItem key={item._id} value={item._id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </TableCell>
            <TableCell align="left">
              {player.hcp ? getHandicapDisplay(player.hcp) : "New"}
              <IconButton
                disabled={player._id === user._id || this.isStandardGame()}
                size={"small"}
                onClick={() => this._onShowHcpEditorDialog(player)}
              >
                <Edit />
              </IconButton>
            </TableCell>
            {this.isStandardGame() && (
              <TableCell align="left">
                {player.hci ? Number(player.hci).toFixed(1) : "New"}
                <IconButton size={"small"} onClick={() => this._onShowHciEditorDialog(player)}>
                  <Edit />
                </IconButton>
              </TableCell>
            )}
            <TableCell align="left">
              <Switch
                checked={scoringPlayers.includes(player._id)}
                onChange={event =>
                  this.props.toggleScoringPlayer(game?._id, player._id, event.target.checked ? "add" : "remove")
                }
              />
            </TableCell>
          </TableRow>
        );
      }
    }
    return null;
  }

  renderPlayerRow_Edit(row: any) {
    const { routerProps } = this.props;
    const game = this.getGame();
    if (game) {
      if (row) {
        return (
          <TableRow key={row._id}>
            <TableCell component="th" scope="row">
              {`${row.name} ${row.lastName || ""}`}
            </TableCell>
            <TableCell>
              {row.group && row.group.name === routerProps.group.name && routerProps.group.name !== undefined ? (
                <Button
                  style={{ backgroundColor: "#cc3f3c", color: "#FFF" }}
                  onClick={() => this._handleRemovePlayer(row)}
                >
                  Remove from this group
                </Button>
              ) : !row.group.name ? (
                <Button
                  style={{ backgroundColor: "#3ccc3f", color: "#FFF" }}
                  onClick={() => this._handleAddPlayer(row)}
                >
                  Add to this group
                </Button>
              ) : (
                <Button style={{ backgroundColor: "#3761dd", color: "#FFF" }} disabled={true}>
                  Already in {row.group.name}
                </Button>
              )}
            </TableCell>
          </TableRow>
        );
      }
    }
    return null;
  }

  isStandardGame = () => isGameStandard(this.getGame()?.competition.type);

  renderList(game: any, group: any, mode: "edit" | "view") {
    const { searchText } = this.state;
    if (game.players) {
      let players: any = [];
      if (mode === "view") {
        players = Object.values(game.players)
          .filter((p: any) => p.group && p.group.name === group.name)
          .sort((a: any, b: any) => (a.name > b.name ? 1 : -1));
      } else {
        players = Object.values(game.players)
          .filter((p: any) => p.name.toLowerCase().includes(searchText))
          .sort((a: any, b: any) => (a.name < b.name ? 1 : -1))
          .sort((a: any, b: any) => (!a.group ? 1 : a.group > b.group ? -1 : 1));
      }
      if (players.length > 0) {
        if (this.props.groupMembers) {
          return this.props.groupMembers.map(member => this.renderPlayerRow_Edit(member));
        } else {
          return players.map((row: any) =>
            mode === "edit" ? this.renderPlayerRow_Edit(row) : this.renderPlayerRow_View(row),
          );
        }
      } else {
        return (
          <TableRow>
            <TableCell colSpan={2}>No player found</TableCell>
          </TableRow>
        );
      }
    }
    return null;
  }
  render() {
    const { classes, loading, routerProps, showModeToggle = true } = this.props;
    const { mode, searchText } = this.state;

    const game = this.getGame();
    if (!game) return null;

    return (
      <div>
        {loading && <LinearProgress />}
        <TableContainer component={Paper}>
          <Toolbar>
            <div className={classes.titleWrapper}>
              <Typography variant="h6" id="tableTitle">
                {mode === "view" ? "Scorer setup" : "Group Members"}
              </Typography>
              <Typography variant="subtitle2" id="tableTitle">
                {routerProps.group && routerProps.group.name} Group
              </Typography>
            </div>
            {mode === "edit" && (
              <TextField
                value={searchText}
                fullWidth
                variant={"outlined"}
                placeholder={"Search by name"}
                onChange={this._onTextFieldChanged("searchText")}
              />
            )}
            {showModeToggle ? (
              <Tooltip title="Edit players list" leaveTouchDelay={2000}>
                <IconButton color={"primary"} aria-label="add player" onClick={this._toggleMode}>
                  <Group />
                </IconButton>
              </Tooltip>
            ) : (
              <IconButton onClick={this.props.closeThisModal}>
                <Close />
              </IconButton>
            )}
          </Toolbar>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                {mode === "view" ? (
                  <>
                    <TableCell align={"left"}>Tee</TableCell>
                    <TableCell align={"left"}>
                      Team
                      <IconButton color={"primary"} onClick={this._toggleNewTeamDialog}>
                        <Add />
                      </IconButton>
                    </TableCell>
                    <TableCell align="left">PH</TableCell>
                    {this.isStandardGame() && <TableCell align="left">HI</TableCell>}
                  </>
                ) : (
                  <TableCell align={"left"}>Action</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>{game && this.renderList(game, routerProps.group!, mode)}</TableBody>
          </Table>
          {mode === "view" && (
            <TableFooter className={classes.footer}>
              <Typography className={classes.marginRight} variant="body1" id="tableTitle">
                Starting hole
              </Typography>
              <FormControl className={`${classes.startingHole}`}>
                <Select
                  labelId="starting-hole"
                  id="starting-hole"
                  value={this.getStartingHole()}
                  onChange={this._onHandleChangeStartingHole}
                >
                  {//prettier-ignore
                  //@ts-ignore
                  Object.values(game.scoreCards)[0].holesParams.map((param: HoleParam) => (
                      <MenuItem key={param.number} value={param.number}>
                        {param.number}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <div className={classes.spacer} />

              <Tooltip title="start round" leaveTouchDelay={2000}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <IconButton onClick={this._onSubmit} disabled={loading}>
                    <PlayCircleFilledWhite color={"primary"} fontSize={"large"} />
                  </IconButton>
                  <small>BACK</small>
                </div>
              </Tooltip>
            </TableFooter>
          )}
        </TableContainer>
        {this.renderNewTeamDialog()}
      </div>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(170, 170, 170, 0)",
      alignSelf: "center",
      margin: 8,
    },
    footer: {
      padding: theme.spacing(1),
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    titleWrapper: {
      flex: "1 1 100%",
      display: "flex",
      flexDirection: "column",
      marginTop: 8,
    },
    spacer: {
      flexGrow: 1,
    },
    marginRight: {
      marginRight: theme.spacing(2),
    },
    startingHole: {
      "&.outlined": {
        outline: "3px solid orange",
        padding: "0 15px",
      },
    },
  });
const mapStateToProps = (state: GlobalState) => {
  const { joinedGames } = state.games;
  const { user } = state.auth;
  const { loading } = state.general;
  return {
    loading,
    joinedGames,
    user,
  };
};

export default connect(mapStateToProps, {
  UpdateGame: GameActions.update,
  updateGroup: GameActions.updateGroup,
  addPlayer: GameActions.addPlayer,
  removePlayer: GameActions.removePlayer,
  changePlayerTeeColor: GameActions.changePlayerTeeColor,
  changePlayerTeam: GameActions.changePlayerTeam,
  addPlayerToGroup: GameActions.addPlayerToGroup,
  removePlayerFromGroup: GameActions.removePlayerFromGroup,
  getTeams: TeamActions.getByGameId,
  insertTeam: TeamActions.insertTeam,
  changePlayerHcp: GameActions.changePlayerHcp,
  recalculatePlayingHcp: GameActions.recalculatePlayingHcp,
  toggleScoringPlayer: GameActions.toggleScoringPlayer,
  updateUserHcp: UserActions.updateHcp,
  switchComponent,
})(withStyles(styles)(ScorerGameSetup));
