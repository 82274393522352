import React from "react";
import LoginPage from "./LoginPage";
import SignUpPage from "./Signup/SignUpPage";
import ForgotPassword from "./ForgotPassword";
import { Route } from "react-router";

export default () => (
  <>
    <Route path="/join/:gameCode" exact component={LoginPage} />
    <Route path="/forgotPassword/:email?"  component={ForgotPassword} />
    <Route path="/signup/:gameCode" exact component={SignUpPage} />
    <Route path="/signup/" exact component={SignUpPage} />
    <Route path="/" exact component={LoginPage} />
  </>
);
