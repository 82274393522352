import { UserBrifObj } from "./user";

export interface Competition {
  _id: string;
  name: string;
  introduction: string;
  scoringFormat: string;
  individual: number;
  eligibility: Eligibility[];
  playingRules: PlayingRulesType;
  handicapRules: HandicapRules;
  handicapAdjustment?: HandicapAdjustment;
  fullHandicapAllowance: number;
  guests: boolean;
  holesNumber: number;
  type: RuleType;
  expireRules?: ExpireRules;
  teamHandicapAllowance: TeamHandicapAllowance;
  active?: boolean;
  owner?: OwnerType;
  accessor?: UserBrifObj[];
}

type OwnerType = { name: string; lastName: string; _id: string };

export type CompetitionInput = Omit<Competition, "_id">;

export interface Eligibility {
  [index: string]: any;
  exist: boolean;
  gender: string;
  minHandicap: number;
  maxHandicap: number;
}

export interface PlayingRulesType {
  playingFormat: PlayingFormatType;
  playingAs: PlayingAsType;
  teamPlayers: number;
  teamsPerTee: number;
  bestOne: number[];
  bestTwo: number[];
  bestThree: number[];
  bestFour: number[];
  isRelatedToCourseHoles: boolean;
  minimumTeeShots: number | number[]; // Index stands for team players number
  multiRules?: boolean;
}

export interface TeamHandicapAllowance {
  hcpSortedAllowances: number[];
  combinedTeam: number | number[];
}

export interface HandicapRules {
  adjustHandicapOnResult: boolean;
  adjustHandicapOnPoints: boolean;
  customHandicapCategories: boolean;
  handicapDeducting: number[];
  losersHandicapDeducting: number[];
  handicapAdjustmentRules: HandicapAdjustmentRule[];
  aboveHIadjustment: boolean;
  disregardDnp: boolean;
  sharedPrize: boolean;
  exactHandicaps: boolean;
  usingNumberInFirstTimeHandicapAdjustment: boolean;
  firstTimeHandicapAdjustment: number;
}

export interface HandicapAdjustment {
  adjustMultipleTeeGender?: boolean;
  adjust9HolesRule?: boolean;
  adjust9HolesRulePH?: boolean;
}

export interface ExpireRules {
  isActive: boolean;
  minGames: number;
  duringMonths: number;
  minMonths: number;
}

export interface HandicapAdjustmentRule {
  greaterThan: number;
  lessThan: number;
  type: AdjustmentType;
  value: number;
}

export enum AdjustmentType {
  PER_POINT = 0,
  OVERALL = 1,
}

export type IndexedCompetitions = { [index: string]: Competition };

export interface CompetitionState {
  competitions: IndexedCompetitions;
  count: number;
}

export enum PlayingFormats {
  Individual = "individual",
  Matchplay = "matchplay",
  Combined = "combined",
}
export type PlayingFormatType = PlayingFormats.Individual | PlayingFormats.Matchplay | PlayingFormats.Combined;

export enum PlayingAsFormats {
  Individual = "individual",
  Team = "team",
}
export type PlayingAsType = PlayingAsFormats.Individual | PlayingAsFormats.Team;

export type BestHolesFielld = "bestOne" | "bestTwo" | "bestThree" | "bestFour";

export enum GameRules {
  WHS = "WHS",
  BASIC = "basic",
  CUSTOM = "custom",
}

export type StandardRuleType = GameRules.WHS | GameRules.BASIC;

export type RuleType = GameRules.WHS | GameRules.BASIC | GameRules.CUSTOM;
