import { GameRules, RuleType, Competition, PlayingAsFormats, PlayingFormats } from "types/competition";
import { Player, HoleScoreInfo } from "types/player";
import Config from "config";

export * from "./scoreVerification";

export type GrossType = number | "" | "mismatch";

export const isCombinedGame = (competition: Competition) =>
  competition?.playingRules &&
  competition?.playingRules?.playingAs === PlayingAsFormats.Team &&
  competition?.playingRules?.playingFormat === PlayingFormats.Combined;

export const isGameStandard = (type: RuleType | undefined) =>
  type ? [GameRules.BASIC, GameRules.WHS].includes(type) : false;

export const getGross = (hole: number, player: Player) => {
  const holes = player.holes;
  if (!holes || !holes[hole]) return "";
  if (holes[hole].mismatch) return "mismatch";
  return holes[hole].gross;
};

export const getGrossStyle = (par: number, gross: GrossType) => {
  const bColors = ["#ff98ca", "#ffff01", "#fe0000", "#FFF", "#00b054", "#ffc000", "#056ec1", "#7030a0", "#000"];
  const colors = ["#FFFFFF", "#000000", "#FFFFFF", "#000", "#FFFFFF", "#000000", "#FFFFFF", "#FFFFFF", "#FFF"];
  if (gross === "" || gross === null) return {};
  else if (gross === "mismatch") return { backgroundColor: "#cc3f3c", color: "#FFF" };

  const index = gross - par + 3;
  if (colors[index]) {
    const bg = bColors[index];
    return { backgroundColor: bg, color: colors[index], border: bg === "#FFF" ? "1px solid #000" : `1px solid ${bg}` };
  } else {
    return { backgroundColor: "#000", color: "#FFF" };
  }
};

export const getPlayerPoint = (holeScores: HoleScoreInfo, gross: GrossType, scoringFormat: string) => {
  return !gross || gross === "mismatch"
    ? null
    : scoringFormat === Config.SCORING_FORMAT.STABLEFORD
    ? holeScores.point
    : holeScores.nett;
};

export const sortByGroupName = (group1: string = "", group2: string = "") => {
  let g1 = group1.replace(":", ".");
  let g2 = group2.replace(":", ".");

  if (isFinite(+g1) && isFinite(+g2)) return parseFloat(g1) - parseFloat(g2);

  g1 = g1.replace(/\d+/g, (d: string) => (d.length === 1 ? "0" + d : d));
  g2 = g2.replace(/\d+/g, (d: string) => (d.length === 1 ? "0" + d : d));

  return g1.localeCompare(g2);
};

// Sleep function
export const sleep = (sec: number) =>
  new Promise(resolve => {
    setTimeout(resolve, sec * 1000);
  });

export const hasReachedToTheBottom = (el: HTMLDivElement) =>
  Math.floor(el.scrollHeight - Math.abs(el.scrollTop)) <= el.clientHeight;
// Custom scroll function
// duration in milliseconds
export const scrollTo = (el: HTMLDivElement, pos: number, duration: number, continuous: boolean) =>
  new Promise(resolve => {
    // console.log("1.pos", pos, tId);
    // Base condition
    if (duration <= 0) return;
    // Distance between the element's top to its topmost visible content & position to go to
    const distance = pos - el.scrollTop;
    // Number of pixels to scroll (each 10 milliseconds)
    const perTick = (distance / duration) * 10;

    setTimeout(async function() {
      el.scrollTop = el.scrollTop + perTick;
      // If we reach to the position then return
      if (continuous ? hasReachedToTheBottom(el) : Math.floor(el.scrollTop) === pos) {
        resolve(true);
        return;
      }
      // Recursive call
      const finished = await scrollTo(el, pos, duration - 10, continuous);
      if (finished) resolve(true);
    }, 10);
  });

export const getHandicapDisplay = (hcp?: number, round: boolean = true) => {
  return hcp ? (hcp < 0 ? `+${Math.abs(round ? Math.round(hcp) : hcp)}` : `${round ? Math.round(hcp) : hcp}`) : "";
};
