import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  root: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
    backgroundColor: "#353535eb",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    padding: "0.5rem",

    "& > *": {
      color: "inherit",
    },
  },
  btn: {
    whiteSpace: "nowrap",
    marginLeft: "1rem",
  },
  closeBtn: {
    position: "absolute",
    top: "0",
    right: "0",
  },
  text: {
    "@media (max-width:768px)": {
      paddingRight: "10%",
    },
  },
});
