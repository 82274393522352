export default {
  PUBLIC_URL: process.env.REACT_APP_PUBLIC_URL || "",
  API_URL: process.env.REACT_APP_API_URL || "",
  SOCKET_END_POINT: process.env.REACT_APP_WEBSOCKET_URL || "",
  VERSION: process.env.REACT_APP_VERSION || "",
  TIME_OUT: parseInt(process.env.REACT_APP_TIME_OUT || "5000"),

  DATE_DISPLAY_FORMAT: "Do MMMM YYYY",
  DATE_TIME_DISPLAY_FORMAT: "Do MMMM YYYY, h:mm a",
  TIME_DISPLAY_FORMAT: "h:mm a",
  SAVE_IF_FAILED: "saveIfFailed",
  STORAGE: {
    TOKEN: "token",
    FAILED_API_CALLS: "offlineCalls",
  },
  SCORING_FORMAT: {
    STABLEFORD: "stableford",
    STROKE: "stroke",
  },
  LEADERBOARD_CONFIG: {
    ACTIVATED: {
      EVERY_ONE: 1,
      VIEW_ONLY: 2,
      ORGANIZER_ONLY: 3,
    },
    REVEAL_HIDDEN_PLACES: {
      ONE_LINE: 1,
      TWO_STEP: 2,
    },
  },
  RULES: {
    ADMIN: "admin",
    ORGANIZER: "organiser",
    PLAYER: "player",
  },
  PLAYING_FORMATS: {
    INDIVIDUAL: "individual",
    TEAM: "team",
  },
};
