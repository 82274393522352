import BaseApi from "./Api";

class TournamentsApi extends BaseApi {
  url = "tournaments";
  constructor() {
    super();
    this.setResourceUrl(this.url);
  }

  getTournamentGames(tournamentId: string) {
    return this.instance({
      method: "get",
      url: `${this.url}/${tournamentId}/games`,
    });
  }

  getPlayerTournamentGames(playerId: string, tournamentId: string) {
    return this.instance({
      method: "get",
      url: `${this.url}/${tournamentId}/player/${playerId}/games`,
    });
  }

  addGame(tournamentId: string, gameId: string) {
    return this.instance({
      method: "put",
      url: `${this.url}/${tournamentId}/addGame/${gameId}`,
    });
  }
  removeGame(tournamentId: string, gameId: string) {
    return this.instance({
      method: "delete",
      url: `${this.url}/${tournamentId}/removeGame/${gameId}`,
    });
  }
}

export default new TournamentsApi();
