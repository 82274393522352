import clsx from "clsx";
import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme, Typography, TypographyVariant, WithStyles } from "@material-ui/core";

import VideoPopup from "components/SharedComponents/VideoPopup";

interface Props extends WithStyles {
  title: string;
  variant: TypographyVariant;
  style?: any;
  className?: string;
  padding?: "none" | "normal";
  error?: boolean;
  fullWidth?: boolean;
  videoIcon?: boolean;
  videoURL?: string;
}

class BorderedBox extends Component<Props, {}> {
  public static defaultProps: Partial<Props> = {
    padding: "normal",
  };

  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      fullWidth,
      classes,
      children,
      title,
      variant,
      className,
      error,
      padding,
      style,
      videoIcon,
      videoURL,
    } = this.props;
    let _style = { ...style };
    if (fullWidth) _style.width = "100%";

    return (
      <div style={_style} className={[className, classes.container].join(" ")}>
        <fieldset className={classes.bordered} style={{ borderColor: error ? "rgb(255,69,72)" : "rgba(0,0,0,0.3)" }}>
          {title !== "" ? (
            <legend className={classes.title}>
              <Typography
                variant={variant}
                className={classes.text}
                style={{ color: error ? "rgb(255,69,72)" : "#000" }}
              >
                {title}
              </Typography>
              {videoIcon ? <VideoPopup videoURL={videoURL} /> : null}
            </legend>
          ) : null}
          <div
            className={clsx({
              [classes.noPadding]: padding === "none",
              [classes.normalPadding]: padding === "normal",
            })}
          >
            {children}
          </div>
        </fieldset>
      </div>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(2),
    },
    bordered: {
      borderColor: "rgba(0,0,0,0.3)",
      borderRadius: theme.spacing(0.5),
      borderWidth: 1,
      border: "solid",
    },
    title: {
      width: "fit-content",
      padding: "0 4px",
      position: "relative",

      "& > button": {
        position: "absolute",
        right: -36,
        top: -8,
        backgroundColor: "#fff",
      },
    },
    text: {
      //fontWeight:'bold',
    },
    normalPadding: {
      padding: window.innerWidth < 400 ? 2 : theme.spacing(2),
    },
    noPadding: {
      padding: 1,
    },
  });

export default withStyles(styles)(BorderedBox);
