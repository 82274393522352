import ClubsApi from "../api/clubs";
import { BaseActions } from "./BaseActions";
import { sessionExpired } from "../api/helper";
import ReduxTypes from "../consts/ReduxTypes";
import { ErrorOnlyCallback } from "../types/actions";
import { AxiosError } from "axios";

export default new (class GameActions extends BaseActions {
  constructor() {
    super(ClubsApi, "CLUBS");
  }

  addToFav = (id: string, callback?: any) => {
    return (dispatch: any) => {
      ClubsApi.addToFav(id)
        .then((res: any) => {
          dispatch({ type: ReduxTypes.ADD_TO_FAV, id });
          if (callback) callback(null, res.data);
        })
        .catch((err: any) => {
          console.log(err);
        });
    };
  };

  removeFromFav = (id: string, callback?: any) => {
    return (dispatch: any) => {
      ClubsApi.removeFromFav(id)
        .then((res: any) => {
          dispatch({ type: ReduxTypes.REMOVE_FROM_FAV, id });
          if (callback) callback(null, res.data);
        })
        .catch((err: any) => {
          sessionExpired();
        });
    };
  };

  addOrganiser = (userId: string, callback?: ErrorOnlyCallback) => {
    return (dispatch: any) => {
      ClubsApi.addOrganiser(userId)
        .then((res: any) => {
          if (callback) callback(null);
        })
        .catch((err: any) => {
          console.log(err);
        });
    };
  };

  removeOrganiser = (userId: string, callback?: ErrorOnlyCallback) => {
    return (dispatch: any) => {
      ClubsApi.removeOrganiser(userId)
        .then((res: any) => {
          if (callback) callback(null);
          dispatch({ type: ReduxTypes.getDeleteType("USERS"), payload: userId });
        })
        .catch((err: any) => {
          console.log(err);
        });
    };
  };
  getEmail = (userId: string, callback?: (error: AxiosError | null, data: string) => void) => {
    return (dispatch: any) => {
      ClubsApi.getEmail(userId)
        .then((res: any) => {
          if (callback) callback(null, res.data);
        })
        .catch((err: any) => {
          console.log(err);
        });
    };
  };

  getScorecards = (clubId: string, callback?: ErrorOnlyCallback) => {
    return (dispatch: any) => {
      ClubsApi.getScorecards(clubId)
        .then((res: any) => {
          if (callback) callback(null);
          dispatch({ type: ReduxTypes.FETCH_CLUB_SCORECARDS, payload: res.data });
        })
        .catch((err: any) => {
          if (callback) callback(err);
        });
    };
  };
  getGames = (clubId: string, callback?: ErrorOnlyCallback) => {
    return (dispatch: any) => {
      ClubsApi.getGames(clubId)
        .then((res: any) => {
          if (callback) callback(null);
          dispatch({ type: ReduxTypes.FETCH_CLUB_GAMES, payload: res.data });
        })
        .catch((err: any) => {
          if (callback) callback(err);
        });
    };
  };
})();
