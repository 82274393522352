import { useState, useEffect } from "react";

import tournamentAPI from "api/tournaments";
import { Game } from "types/game";
import { GrossType } from "utils";

export interface PlayerTMGameType extends Game {
  holes: number[];
}

type StateType = {
  games: PlayerTMGameType[];
  isLoading: boolean;
  holes: { nett: number; gross: GrossType; holeNumber: number; par: number; score: number; gameId: string }[];
  isEclectic: boolean;
};

export const usePlayerTMGames = (playerId: string, tournamentId: string) => {
  const [state, setState] = useState<StateType>({
    games: [],
    holes: [],
    isEclectic: false,
    isLoading: false,
  });

  useEffect(() => {
    const getTMGames = async () => {
      setState(prevState => ({ ...prevState, isLoading: true }));
      const { data } = await tournamentAPI.getPlayerTournamentGames(playerId, tournamentId);
      setState({ games: data.data.games, holes: data.data.holes, isEclectic: data.data.isEclectic, isLoading: false });
    };

    getTMGames();
  }, [playerId, tournamentId]);

  return state;
};
