import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { GlobalState } from "../../types/globalState";
import { ChevronRight, GolfCourse } from "@material-ui/icons";
import {
  Avatar,
  Badge,
  createStyles,
  Divider,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  Theme,
  WithStyles,
} from "@material-ui/core";
import { Game, GState } from "../../types/game";
import GameActions from "../../actions/games";
import "../../styles/material-list-responsive.css";
import GameNavigationDialog from "./GameNavigationDialog";
import { showDialog } from "../SharedComponents/Dialog";
import { User } from "../../types/user";
import { GameDetails } from "./GameDetails";
import ChainIcon from "./ChainIcon";

interface Props extends WithStyles {
  getJoinedGames(): void;
  getCurrentOrganisedGames(): void;
  clearJoinedGames(): void;
  games: Game[];
  loading: boolean;
  user: User;
}

interface State {
  openNavigationMenu: boolean;
}

class JoinedGamesList extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      openNavigationMenu: false,
    };
    this.props.clearJoinedGames();
    this.props.getJoinedGames();
    this.props.getCurrentOrganisedGames();
  }

  renderAvatar(game: Game) {
    if (game.organizers.filter(o => o._id === this.props.user._id).length > 0)
      return (
        <Badge badgeContent={"Org"} color="primary" anchorOrigin={{ vertical: "top", horizontal: "left" }}>
          <Avatar style={{ backgroundColor: game.state && game.state === GState.Playing ? "#33a136" : "#8f8b8f" }}>
            <GolfCourse />
          </Avatar>
        </Badge>
      );
    else
      return (
        <Avatar style={{ backgroundColor: game.state && game.state === GState.Playing ? "#33a136" : "#8f8b8f" }}>
          <GolfCourse />
        </Avatar>
      );
  }

  renderItem(game: Game, index: number, games: Game[]) {
    if (typeof game !== "object" || !game) return null;

    return (
      <div key={game._id} style={{ width: "100%", position: "relative" }}>
        <ListItem
          dense={false}
          button
          style={{ alignItems: "flex-start" }}
          onClick={() => game._id && showDialog(<GameNavigationDialog gameId={game._id} />, "xs", true, true, true)}
        >
          <ListItemAvatar style={{ margin: "16px 0 0 4px" }}>{this.renderAvatar(game)}</ListItemAvatar>
          <div className={this.props.classes.cardDescContainer}>
            <GameDetails key={game._id} game={game} />
            <ChevronRight color={"primary"} />
          </div>
        </ListItem>
        <Divider component="li" />
        <ChainIcon currentGame={game} nextGame={games[index + 1]} />
      </div>
    );
  }

  render() {
    const { classes, games, loading } = this.props;
    if (!loading && games.length === 0)
      return (
        <h4 className={"text-center medium-padding"}>You have no Current Games. Enter a game code to join a game.</h4>
      );
    return (
      <List className={classes.paper}>
        {loading ? <LinearProgress /> : null}
        {games
          .sort((a: any, b: any) => (b.date > a.date ? 1 : -1))
          .map((game: Game, index: number, games: Game[]) => this.renderItem(game, index, games))}
      </List>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      backgroundColor: "rgba(255, 255, 255, 1)",
    },
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
    },
    cardDescContainer: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  });

const mapStateToProps = (state: GlobalState) => {
  const { joinedGames, organisedByMe } = state.games;
  const { loading } = state.general;
  const { user } = state.auth;
  const games = { ...organisedByMe, ...joinedGames };

  return {
    user,
    loading,
    games: Object.values(games),
  };
};

export default connect(mapStateToProps, {
  getJoinedGames: GameActions.getJoinedGames,
  getCurrentOrganisedGames: GameActions.getCurrentOrganisedGames,
  clearJoinedGames: GameActions.clearJoinedGames,
})(withStyles(styles)(JoinedGamesList));
