import { Action } from "../types/actions";
import ReduxTypes, { Keys } from "../consts/ReduxTypes";
import { deleteReducer, fetchReducer, insertReducer, updateReducer } from "./BaseReducerFunctions";
import { CourseState } from "../types/course";

const INITIAL_STATE = {
  courses: {},
  count: 0,
};
const KEY: Keys = "COURSES";

export default (state: CourseState = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case ReduxTypes.getInsertType(KEY):
      return insertReducer(state, action, "courses");

    case ReduxTypes.getFetchType(KEY):
      return fetchReducer(state, action, "courses");

    case ReduxTypes.getDeleteType(KEY):
      return deleteReducer(state, action, "courses");

    case ReduxTypes.getUpdateType(KEY):
      return updateReducer(state, action, "courses");

    case ReduxTypes.UPDATE_LOCAL_RULES:
      const { id, content, logo } = action.payload;
      const course = state.courses[id];

      return {
        ...state,
        courses: {
          ...state.courses,
          [id]: {
            ...course,
            ...(logo ? { club: { ...course.club, logo } } : {}),
            localRules: content,
          },
        },
      };

    case ReduxTypes.PURGE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
