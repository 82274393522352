import React from "react";
import { useForm, Controller, useWatch } from "react-hook-form";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Autocomplete, { AutocompleteRenderInputParams } from "@material-ui/lab/Autocomplete";

import { HandicapInput } from "types/handicap";
import { Competition } from "types/competition";
import { useNewPlayers, BriefPlayer } from "hooks/useNewPlayers";

export interface AddHandicapFormProps {
  competitions: Competition[];
  onSubmit(data: HandicapInput): void;
}

const AddHandicapForm: React.FC<AddHandicapFormProps> = ({ competitions = [], onSubmit }) => {
  const classes = useStyles();
  const { control, handleSubmit } = useForm<HandicapInput>({});
  const gameCode = useWatch({
    control,
    name: "code",
  });

  const { players } = useNewPlayers(gameCode);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root} autoComplete="off">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Controller
            name="code"
            control={control}
            render={({ field }) => {
              return (
                <TextField
                  value={field.value}
                  onChange={field.onChange}
                  label="Game Code * (Only if for an existing Schala player in first joined game)"
                  variant="outlined"
                  fullWidth
                  inputProps={{ maxLength: 6 }}
                />
              );
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="playerId"
            rules={{ required: true }}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(_, item) => {
                  onChange(item?._id);
                }}
                value={value as any}
                options={players}
                getOptionLabel={(item: BriefPlayer) =>
                  item.name ? `${item.name} ${item.lastName} (${item.username})` : ""
                }
                getOptionSelected={(opt, val) => val && val._id === opt._id}
                renderInput={(params: AutocompleteRenderInputParams) => (
                  <TextField {...params} label="Player" variant="outlined" required />
                )}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="competitionId"
            rules={{ required: true }}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(_, item) => {
                  onChange(item?._id);
                }}
                value={value as any}
                options={competitions}
                getOptionLabel={(item: Competition) => item.name || ""}
                getOptionSelected={(opt, val) => val && val._id === opt._id}
                renderInput={(params: AutocompleteRenderInputParams) => (
                  <TextField {...params} label="Custom Game Rule" variant="outlined" required />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="hcp"
            control={control}
            render={({ field }) => {
              return (
                <TextField
                  value={field.value}
                  onChange={field.onChange}
                  required
                  type="number"
                  label="Custom Playing Handicap"
                  variant="outlined"
                  fullWidth
                  inputProps={{ min: -10, max: 54, inputMode: "decimal", step: "any" }}
                />
              );
            }}
          />
        </Grid>

        <Grid item xs={12} className={classes.btnContainer}>
          <Button variant="contained" color="primary" type="submit">
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddHandicapForm;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "1rem 2rem",

      "& p": {
        textAlign: "justify",
      },
    },
    group: {
      flexDirection: "row",
    },
    btnContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
  }),
);
