import ReduxTypes from "../consts/ReduxTypes";
import { showSnackbar } from "../components/SharedComponents/Notifier";
import gamesApi from "../api/games";
import { BaseActions } from "./BaseActions";
import { UserBrifObj } from "../types/user";
import ErrorHandler from "../api/Errorhandler";
import { Game, GState } from "../types/game";
import { AxiosError } from "axios";
import { ErrorOnlyCallback } from "../types/actions";
import { hideDialog } from "../components/SharedComponents/Dialog";
import { GuestPlayerInputs, Player } from "types/player";
import { EmailTypes } from "types/general";
import { GlobalState } from "types/globalState";
import { HoleSigns } from "types/hole";
import _ from "lodash";
import { hideAuxiliaryDialog } from "components/SharedComponents/AuxiliaryDialog";

export default new (class GameActions extends BaseActions {
  constructor() {
    super(gamesApi, "GAME");
  }

  addGameToVisitedLeaderBoard = (game: any) => {
    return (dispatch: any) => {
      dispatch({ type: ReduxTypes.ADD_TO_VISITED_LEADER_BOARD, payload: game });
    };
  };

  activateGame = (id: string, callback?: (error: AxiosError | null, data: Game) => void) => {
    return (dispatch: any) => {
      gamesApi
        .activate(id)
        .then((res: any) => {
          dispatch({ type: ReduxTypes.ACTIVATE_GAME, ids: res.data });
          if (callback) callback(null, res.data as Game);
        })
        .catch((err: any) => {
          if (err && err.response.status === 404) {
            showSnackbar("Game not found, Please recheck game code or contact game organiser.");
          } else {
            ErrorHandler(err, dispatch);
            if (callback) callback(err, {} as Game);
          }
        });
    };
  };

  getGameByCode = (code: string, queryType: string, callback?: (error: AxiosError | null, data?: Game) => void) => {
    return (dispatch: any) => {
      gamesApi
        .getGameByCode(code, queryType)
        .then((res: any) => {
          if (callback) callback(null, res.data);
        })
        .catch((err: AxiosError) => {
          ErrorHandler(err, dispatch);
          if (callback) callback(err);
        });
    };
  };

  joinGame = (id: string, callback?: (error: AxiosError | null, data: Game[]) => void) => {
    return (dispatch: any) => {
      gamesApi
        .join(id)
        .then((res: any) => {
          dispatch({ type: ReduxTypes.JOIN_GAME, payload: res.data });
          if (callback) callback(null, res.data as Game[]);
        })
        .catch((err: any) => {
          console.log(err);
          ErrorHandler(err, dispatch);
          if (callback) callback(err, [] as Game[]);
        });
    };
  };

  leaveGame = (id: string, callback?: ErrorOnlyCallback) => {
    return (dispatch: any) => {
      gamesApi
        .leave(id)
        .then((res: any) => {
          dispatch({ type: ReduxTypes.LEAVE_GAME, id });
          if (callback) callback(null);
        })
        .catch((err: any) => {
          console.log(err);
          ErrorHandler(err, dispatch);
          if (callback) callback(err);
        });
    };
  };

  finishGame = (id: string, callback?: ErrorOnlyCallback) => {
    return (dispatch: any) => {
      gamesApi
        .finish(id)
        .then((res: any) => {
          if (callback) callback(null);
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
          if (callback) callback(err);
        });
    };
  };

  closeGame = (id: string, callback?: ErrorOnlyCallback) => {
    return (dispatch: any) => {
      gamesApi
        .closeGame(id)
        .then((res: any) => {
          dispatch({ type: ReduxTypes.CLOSE_GAME, id });
          if (callback) callback(null);
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
          if (callback) callback(err);
        });
    };
  };

  getById = (
    id: string,
    queryType?: string,
    updateRedux?: boolean,
    callback?: (err: AxiosError | null, data: Game) => void,
  ) => {
    return (dispatch: any) => {
      gamesApi
        .geById(id, queryType)
        .then((res: any) => {
          if (updateRedux) dispatch({ type: ReduxTypes.UPDATE_GAME_BY_ID, payload: res.data, id });
          if (callback) callback(null, res.data as Game);
        })
        .catch((err: AxiosError) => {
          ErrorHandler(err, dispatch);
          if (callback) callback(err, {} as Game);
        });
    };
  };

  getJoinedGames = (callback?: (err: AxiosError | null, data: Game[]) => void) => {
    return (dispatch: any) => {
      gamesApi
        .getJoinedGames()
        .then((res: any) => {
          dispatch({ type: ReduxTypes.GET_JOINED_GAMES, payload: res.data });
          if (callback) callback(null, res.data as Game[]);
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
          if (callback) callback(err, [] as Game[]);
        });
    };
  };

  getCurrentOrganisedGames = (callback?: (err: AxiosError | null, data: Game[]) => void) => {
    return (dispatch: any) => {
      gamesApi
        .getCurrentOrganisingGames()
        .then((res: any) => {
          dispatch({ type: ReduxTypes.GET_GAMES_ORGANISED_BY_ME, payload: res.data });
          if (callback) callback(null, res.data as Game[]);
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
          if (callback) callback(err, [] as Game[]);
        });
    };
  };

  getGamesHistory = (page: number, limit: number, filter?: any, sort?: string, dir?: string, query?: string) => {
    return (dispatch: any) => {
      gamesApi
        .getGamesHistory(page, limit, filter, sort, dir, query)
        .then((res: any) => {
          dispatch({ type: ReduxTypes.GET_GAMES_HISTORY, payload: res.data });
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
        });
    };
  };

  private insertScore = (
    player: Player,
    scorerId: string,
    holeNumber: number,
    gross: number | string,
    holesCount: number,
  ) => {
    console.log("INSERT SCORE");
    const updatedPlayer = { ...player };
    if (!updatedPlayer.uholes) updatedPlayer.uholes = {};

    if (gross === HoleSigns.DNP || gross === HoleSigns.DNF) {
      const holes = Array.from({ length: holesCount }, (_, i) => i + 1);
      const remainingHoles = holes.filter(
        hole => player.uholes?.[hole]?.grosses === undefined || Object.keys(player.uholes[hole].grosses).length === 0,
      );

      if (gross === HoleSigns.DNP) {
        remainingHoles.forEach(hole => {
          updatedPlayer.uholes[hole] = {
            holeNumber: hole,
            // @ts-ignore
            grosses: { ...updatedPlayer.uholes[hole]?.grosses, [scorerId]: gross },
          };
          updatedPlayer.holes[hole] = {
            holeNumber: hole,
            // @ts-ignore
            gross,
          };
        });
      }
      // DNF
      else {
        updatedPlayer.uholes[holeNumber] = {
          holeNumber: holeNumber,
          // @ts-ignore
          grosses: { ...updatedPlayer.uholes[holeNumber]?.grosses, [scorerId]: gross },
        };
        updatedPlayer.holes[holeNumber] = {
          holeNumber,
          // @ts-ignore
          gross,
        };

        remainingHoles
          .filter(h => h !== holeNumber)
          .forEach(hole => {
            updatedPlayer.uholes[hole] = {
              holeNumber: hole,
              // @ts-ignore
              grosses: { ...updatedPlayer.uholes[hole]?.grosses, [scorerId]: HoleSigns.DNP },
            };
            updatedPlayer.holes[hole] = {
              holeNumber: hole,
              // @ts-ignore
              gross: HoleSigns.DNP,
            };
          });
      }
    }
    // Others ...
    else {
      updatedPlayer.uholes[holeNumber] = {
        holeNumber,
        grosses: { ...updatedPlayer.uholes[holeNumber]?.grosses, [scorerId]: gross as number },
      };
      updatedPlayer.holes[holeNumber] = {
        holeNumber,
        gross: gross as number,
      };
    }

    return updatedPlayer;
  };

  private clearScore = (player: Player, scorerId: string, holeNumber: number) => {
    const updatedPlayer = _.omit(player, [`uholes.${holeNumber}.grosses.${scorerId}`, `holes.${holeNumber}`]);
    return updatedPlayer;
  };

  private offlineUpdatePlayerScores = (
    players: { [index: string]: Player },
    playerId: string,
    scorerId: string,
    holeNumber: number,
    gross: number | null,
  ) => {
    const player = players[playerId];
    if (!player) return players;

    const updatedPlayer =
      gross === null
        ? this.clearScore(player, scorerId, holeNumber)
        : this.insertScore(player, scorerId, holeNumber, gross, 18);

    return { ...players, [playerId]: updatedPlayer };
  };

  updateScores = (
    gameId: string,
    playerId: string,
    scoreId: string,
    holeNumber: number,
    gross: number,
    shots?: number,
    onBehalfPlayer?: boolean,
    offline?: boolean,
    callback?: ErrorOnlyCallback,
  ) => {
    return (dispatch: any, getState: () => GlobalState) => {
      dispatch({
        type: ReduxTypes.UPDATE_GAME_SCORES,
        payload: { playerId, holeNumber, gross },
        id: gameId,
        playerId,
      });

      if (offline) {
        const players = getState().games.joinedGames[gameId]?.players;
        const curUserId = getState().auth.user._id;
        if (!players) return;
        const updatedPlayers = this.offlineUpdatePlayerScores(players, playerId, curUserId, holeNumber, gross);
        dispatch({ type: ReduxTypes.UPDATE_GAME_PLAYER, payload: updatedPlayers, id: gameId });
      }

      ApiCall();
      function ApiCall() {
        return new Promise((resolve, reject) => {
          gamesApi
            .updateScores(gameId, scoreId, holeNumber, gross, shots, onBehalfPlayer)
            .then((res: any) => {
              if (callback) callback(null);
              resolve(true);
            })
            .catch((err: any) => {
              ErrorHandler(err, dispatch);
              if (callback) callback(err);
              reject(false);
            });
        });
      }
    };
  };

  dangerouslyUpdateScore = (
    gameId: string,
    playerId: string,
    scoreId: string,
    data: any,
    holes: any,
    callback?: ErrorOnlyCallback,
  ) => {
    return (dispatch: any) => {
      gamesApi
        .dangerouslyUpdateScore(gameId, scoreId, data)
        .then((res: any) => {
          dispatch({ type: ReduxTypes.DANGEROUSLY_UPDATE_SCORES, payload: holes, id: gameId, playerId });
          if (callback) callback(null);
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
          if (callback) callback(err);
        });
    };
  };

  addPlayer = (id: string, playerId: string, itsMe: boolean, callback?: ErrorOnlyCallback) => {
    return (dispatch: any) => {
      gamesApi
        .addPlayer(id, playerId)
        .then((res: any) => {
          dispatch({ type: ReduxTypes.PLAYER_ADDED, payload: res.data, id, itsMe });
          if (callback) callback(null);
        })
        .catch((err: any) => {
          console.log(err);
          ErrorHandler(err, dispatch);
          if (callback) callback(err);
        });
    };
  };

  addGuestPlayer = (id: string, inputs: GuestPlayerInputs, callback?: ErrorOnlyCallback) => {
    return (dispatch: any) => {
      gamesApi
        .addGuestPlayer(id, inputs)
        .then((res: any) => {
          dispatch({ type: ReduxTypes.PLAYER_ADDED, payload: res.data, id, itsMe: false });
          if (callback) callback(null);
        })
        .catch((err: any) => {
          console.log(err);
          ErrorHandler(err, dispatch);
          if (callback) callback(err);
        });
    };
  };

  removePlayer = (id: string, playerId: string, cb?: () => void) => {
    return (dispatch: any) => {
      gamesApi
        .removePlayer(id, playerId)
        .then((res: any) => {
          dispatch({ type: ReduxTypes.PLAYER_REMOVED, payload: playerId, id });
          if (cb) cb();
        })
        .catch((err: any) => {
          console.log(err);
          ErrorHandler(err, dispatch);
        });
    };
  };

  changePlayerTeeColor = (id: string, playerId: string, scoreCardId: string) => {
    return (dispatch: any) => {
      gamesApi
        .changePlayerTeeColor(id, playerId, scoreCardId)
        .then((res: any) => {
          dispatch({ type: ReduxTypes.CHANGE_TEE_COLOR, payload: res.data, playerId });
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
        });
    };
  };

  changePlayerTeam = (id: string, playerId: string, teamId: string) => {
    return (dispatch: any) => {
      gamesApi
        .changePlayerTeam(id, playerId, teamId)
        .then((res: any) => {
          dispatch({ type: ReduxTypes.CHANGE_TEAM, payload: res.data, id, playerId });
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
        });
    };
  };

  changePlayerHcp = (id: string, playerId: string, hcp: number) => {
    return (dispatch: any) => {
      gamesApi
        .changePlayerHcp(id, playerId, hcp)
        .then((res: any) => {
          dispatch({ type: ReduxTypes.CHANGE_HCP, payload: hcp, id, playerId });
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
        });
    };
  };
  linkGames = (gameIds: string[], callback?: (err: AxiosError | null) => void) => {
    return (dispatch: any) => {
      gamesApi
        .linkGames(gameIds)
        .then((res: any) => {
          // dispatch({ type: ReduxTypes.UPDATE_LINKED_GAMES, payload: gameIds });
          if (callback) callback(null);
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
          if (callback) callback(err);
        });
    };
  };

  recalculatePlayingHcp = (id: string, playerId: string, hci: number) => {
    return (dispatch: any) => {
      gamesApi
        .recalculatePlayingHcp(id, playerId, hci)
        .then((res: any) => {
          dispatch({ type: ReduxTypes.CHANGE_HCI, payload: { hci: hci, hcp: res.data.hcp }, id, playerId });
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
        });
    };
  };

  //GROUPS
  insertGroup = (id: string, groupName: string, playersId: string[], callback?: ErrorOnlyCallback) => {
    return (dispatch: any) => {
      gamesApi
        .insertGroup(id, groupName, playersId)
        .then((res: any) => {
          dispatch({ type: ReduxTypes.INSERT_GROUP, payload: { name: groupName }, id, playersId });
          if (callback) callback(null);
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
          if (callback) callback(err);
        });
    };
  };

  updateGroup = (id: string, name: string, data: any) => {
    return (dispatch: any) => {
      gamesApi
        .updateGroup(id, name, data)
        .then((res: any) => {
          dispatch({ type: ReduxTypes.UPDATE_GROUP, payload: data, id, name });
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
        });
    };
  };

  getFreePlayers = (id: string, callback?: (err: AxiosError | null, players: UserBrifObj[]) => void) => {
    return (dispatch: any) => {
      gamesApi
        .getFreePlayers(id)
        .then((res: any) => {
          if (callback) callback(null, res.data as UserBrifObj[]);
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
          if (callback) callback(err, [] as UserBrifObj[]);
        });
    };
  };

  addPlayerToGroup = (id: string, groupName: string, playerId: string, callback?: ErrorOnlyCallback) => {
    return (dispatch: any) => {
      gamesApi
        .addPlayerToGroup(id, groupName, playerId)
        .then(() => {
          dispatch({ type: ReduxTypes.ADD_PLAYER_TO_GORUP, payload: groupName, id, playerId });
          if (callback) callback(null);
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
          if (callback) callback(err);
        });
    };
  };

  updateGameGroup = (
    id: string,
    groupName: string,
    oldName: string,
    playerIds: string[],
    callback?: ErrorOnlyCallback,
  ) => {
    return (dispatch: any) => {
      gamesApi
        .updateGameGroup(id, groupName, oldName, playerIds)
        .then((res: any) => {
          dispatch({ type: ReduxTypes.UPDATE_GAME_GROUP, payload: { gameId: id, groupName, playerIds, oldName } });
          if (callback) callback(null);
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
          if (callback) callback(err);
        });
    };
  };

  updateGroupStartingHole = (
    id: string,
    startingHole: number,
    playerId: string,
    teamId?: string,
    callback?: ErrorOnlyCallback,
  ) => {
    return (dispatch: any) => {
      gamesApi
        .updateGroupStartingHole(id, startingHole, playerId, teamId)
        .then(() => {
          dispatch({ type: ReduxTypes.UPDATE_GROUP_STARTING_HOLE, payload: { startingHole, playerId } });
          if (callback) callback(null);
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
          if (callback) callback(err);
        });
    };
  };

  removePlayerFromGroup = (id: string, groupName: string, playerId: string, callback?: ErrorOnlyCallback) => {
    return (dispatch: any) => {
      gamesApi
        .removePlayerFromGroup(id, groupName, playerId)
        .then((res: any) => {
          dispatch({ type: ReduxTypes.REMOVE_PLAYER_FROM_GORUP, id, playerId });
          if (callback) callback(null);
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
          if (callback) callback(err);
        });
    };
  };

  getTournaments = () => {
    return (dispatch: any) => {
      gamesApi
        .getTournaments()
        .then((res: any) => {
          dispatch({ type: ReduxTypes.GET_TOURNAMENTS, payload: res.data });
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
        });
    };
  };

  setUpdatedResult = (game: any) => {
    return (dispatch: any) => {
      dispatch({ type: ReduxTypes.UPDATE_GAME_PLAYER, payload: game.players, id: game._id });
    };
  };

  setLeaderboardRevealResult = (game: any) => {
    return (dispatch: any) => {
      dispatch({ type: ReduxTypes.UPDATE_GAME_LEADERBOARD, payload: game.leaderboard, id: game._id });
    };
  };

  setGameFinished = (gameId: string) => {
    return (dispatch: any) => {
      dispatch({ type: ReduxTypes.FINISH_CURRENT_GAME, payload: GState.Done, id: gameId });
    };
  };

  setGameAutoProgress = (gameId: string, status: boolean) => {
    return (dispatch: any) => {
      dispatch({ type: ReduxTypes.SET_GAME_AUTOPROGRESS, payload: status, id: gameId });
    };
  };

  removeFromHistory = (gameId: string) => {
    return (dispatch: any) => {
      gamesApi
        .removeFromHistory(gameId)
        .then((res: any) => {
          dispatch({ type: ReduxTypes.REMOVE_GAME_FROM_HISTORY, id: gameId });
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
        });
    };
  };

  addOrganiser = (gameId: string, playerId: string, callback?: ErrorOnlyCallback) => {
    return (dispatch: any) => {
      gamesApi
        .addOrganiser(gameId, playerId)
        .then((res: any) => {
          if (callback) callback(null);
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
          if (callback) callback(err);
        });
    };
  };

  removeOrganiser = (gameId: string, playerId: string, callback?: ErrorOnlyCallback) => {
    return (dispatch: any) => {
      gamesApi
        .removeOrganiser(gameId, playerId)
        .then((res: any) => {
          if (callback) callback(null);
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
          if (callback) callback(err);
        });
    };
  };

  revealLeaderboard = (gameId: string, callback?: ErrorOnlyCallback) => {
    return (dispatch: any) => {
      gamesApi
        .revealLeaderboard(gameId)
        .then((res: any) => {
          if (callback) callback(null);
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
          if (callback) callback(err);
        });
    };
  };

  sendSubmitionCardNotifierMail = (
    gameId: string,
    homeClubMail: string,
    gameClubMail: string,
    extraReceivers: string[],
    callback?: ErrorOnlyCallback,
  ) => {
    return (dispatch: any) => {
      gamesApi
        .sendSubmitionCardNotifierMail(gameId, homeClubMail, gameClubMail, extraReceivers)
        .then((res: any) => {
          if (callback) callback(null);
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch, 7000);
          if (callback) callback(err);
        });
    };
  };

  clearJoinedGames = () => {
    return (dispatch: any) => {
      dispatch({ type: ReduxTypes.CLEAR_JOINED_GAMES });
    };
  };

  toggleScoringPlayer = (gameId: string, playerId: string, action: "add" | "remove", callback?: ErrorOnlyCallback) => {
    return (dispatch: any) => {
      gamesApi
        .toggleScoringPlayer(gameId, playerId, action)
        .then((res: any) => {
          dispatch({ type: ReduxTypes.UPDATE_GAME_BY_ID, payload: res.data, id: gameId });
          if (callback) callback(null);
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
          if (callback) callback(err);
        });
    };
  };

  getGamePlayers = (gameId: string, cb?: VoidFunction) => {
    return (dispatch: any) => {
      dispatch({ type: ReduxTypes.GET_GAME_PLAYERS_REQ });
      gamesApi
        .getGamePlayers(gameId)
        .then((res: any) => {
          dispatch({ type: ReduxTypes.GET_GAME_PLAYERS, payload: res.data.players });
          if (cb) cb();
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
        });
    };
  };

  updateGameRule = (id: string, ruleId: string) => {
    return (dispatch: any) => {
      gamesApi
        .updateGameRule(id, ruleId)
        .then((res: any) => {
          dispatch({ type: ReduxTypes.UPDATE_GAME_RULE, payload: { competition: res.data.data.competition, id } });
          hideDialog();
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
        });
    };
  };

  updatePlayersPH = (id: string, playerIds: string[]) => {
    return (dispatch: any) => {
      gamesApi
        .updatePlayersPH(id, playerIds)
        .then((res: any) => {
          dispatch({ type: ReduxTypes.GET_GAME_PLAYERS, payload: res.data.players, id });
          showSnackbar("Data updated successfully.", 3000, "success");
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
        });
    };
  };
})();

export const removeGameGroups = (id: string, groups: string[], cb?: () => void) => {
  return (dispatch: any) => {
    gamesApi
      .removeGameGroups(id, groups)
      .then(() => {
        dispatch({ type: ReduxTypes.REMOVE_GAME_GROUPS, id, groups });
        if (cb) cb();
      })
      .catch((err: any) => {
        ErrorHandler(err, dispatch);
      });
  };
};

export const resetGamePresentation = (id: string) => {
  return (dispatch: any) => {
    gamesApi
      .resetGamePresentation(id)
      .then((res: any) => {
        dispatch({
          type: ReduxTypes.getUpdateType("GAME"),
          payload: id,
          data: { leaderboard: res.data.data.leaderboard },
        });
        hideAuxiliaryDialog();
      })
      .catch((err: any) => {
        ErrorHandler(err, dispatch);
      });
  };
};

export const sendEmailToPlayers = (
  file: string,
  players: { name: string; lastName: string; email: string }[],
  code: string,
  gameName: string,
  type: EmailTypes,
  orgEmail?: string,
  cb?: VoidFunction,
) => {
  return (dispatch: any) => {
    gamesApi
      .sendEmailToPlayers(file, players, code, gameName, type, orgEmail)
      .then(() => {
        showSnackbar("The email has been sent successfully.", 3000, "success");
        if (cb) cb();
      })
      .catch((err: any) => {
        ErrorHandler(err, dispatch);
      });
  };
};
