import React, { ReactElement, useState, useEffect } from "react";
import _ from "lodash";
import { Button } from "@material-ui/core";

import * as socket from "socket";
import { IndexedGames, PresentationFields } from "types/game";
import { AdvertTypes } from "types/advert";
import { exportReactElementToPDF } from "utils/GameReportGenerator/html2pdf";
import AdvertSection from "components/Adverts/GameAdvert";
import { useAdMediaQuery } from "hooks/useAdMediaQuery";
import StartSheetHeader from "./StartSheetHeader";
import StartSheetTable from "./StartSheetTable";
import { useSelector } from "hooks/useSelector";
import { User } from "types/user";

const maxRow = 16;

interface StartSheetProps {
  gameId: string;
  games: IndexedGames;
  downloadable?: boolean;
  onSwitchClick?: VoidFunction;
}

export const StartSheet: React.FC<StartSheetProps> = ({ gameId, games, downloadable, onSwitchClick }) => {
  const [landscape] = useAdMediaQuery();
  const [game, setGame] = useState(games[gameId]);
  const widescreen = game.startSheet?.widescreen && landscape;

  const user = useSelector(state => state.auth.user);

  const isOrganiser = game.organizers.find(o => o._id === user._id) !== undefined;

  useEffect(() => {
    if (socket.connect(gameId)) {
      socket.addEvent(socket.EVENTS.PRESENTATION_UPDATED, ({ startSheet }: { startSheet: PresentationFields }) =>
        setGame(g => ({ ...g, startSheet })),
      );
    }

    return () => {
      socket.disconnect();
    };
  }, [gameId]);

  return (
    <div
      style={{
        minWidth: window.innerWidth < 540 ? window.innerWidth : 540,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        position: "relative",
      }}
    >
      {!widescreen && <StartSheetHeader gameId={gameId} games={games} />}
      <StartSheetTable
        isOrganiser={isOrganiser}
        game={game}
        from={0}
        userState={user}
        onSwitchClick={onSwitchClick}
        widescreen={widescreen}
      />
      {downloadable && !widescreen && (
        <Button
          fullWidth
          variant={"contained"}
          style={{ position: "sticky", bottom: 0 }}
          color={"primary"}
          onClick={() => downloadStartSheetPDF(gameId, games, isOrganiser, user)}
        >
          Download PDF
        </Button>
      )}
      {widescreen && <AdvertSection type={AdvertTypes.START_SHEET} gameId={gameId} clubLogo={game.course.club.logo} />}
    </div>
  );
};

export const downloadStartSheetPDF = (
  gameId: string,
  games: IndexedGames,
  isOragniser: boolean,
  user: User,
  returnPdf?: boolean,
) => {
  const game = games[gameId];
  const players = Object.values(game.players).filter(p => !p.isTeamCard);
  const numberOfGroups = Object.keys(_.groupBy(players, player => (player.group ? player.group.name : ""))).length;

  let pages: ReactElement[] = [
    <>
      <StartSheetHeader gameId={gameId} games={games} />
      <StartSheetTable
        isOrganiser={isOragniser}
        userState={user}
        game={game}
        from={0}
        to={numberOfGroups > maxRow ? maxRow : undefined}
      />
    </>,
  ];

  const pageNumbers = Math.round(numberOfGroups / maxRow + 1);
  for (let i = 1; i < pageNumbers; i++)
    pages.push(
      <StartSheetTable
        isOrganiser={isOragniser}
        game={games[gameId]}
        from={maxRow * i}
        to={maxRow * (i + 1)}
        userState={user}
      />,
    );

  return exportReactElementToPDF(`${game.name}-startSheet`, pages, returnPdf);
};
