import BaseApi from "./Api";

class MailerApi extends BaseApi {
  url = "mailer";
  constructor() {
    super();
    this.setResourceUrl(this.url);
  }

  send = (userId: string, subject: string, text: string) => {
    return this.instance({
      method: "post",
      url: `${this.url}/${userId}`,
      data: { subject, text },
    });
  };
}

export default new MailerApi();
