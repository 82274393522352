import React from "react";
import { useForm, Controller } from "react-hook-form";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Typography from "@material-ui/core/Typography";

import { GuestPlayerInputs } from "types/player";
import { Gender } from "types/user";
import { CapitalizeWords } from "utils/text";

export interface AddGuestPlayerProps {
  onSubmit: (data: GuestPlayerInputs, cb: () => void) => void;
  defaultValues?: GuestPlayerInputs;
  displayText?: boolean;
}

const AddGuestPlayer: React.FC<AddGuestPlayerProps> = props => {
  const classes = useStyles();
  const { defaultValues, displayText } = props;
  const { control, handleSubmit, reset } = useForm<GuestPlayerInputs>({ defaultValues });

  const onSubmit = (formValue: GuestPlayerInputs) => {
    props.onSubmit(
      { ...formValue, name: CapitalizeWords(formValue.name), lastName: CapitalizeWords(formValue.lastName) },
      reset.bind(null, defaultValues),
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root} autoComplete="off">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                inputProps={{ style: { textTransform: "capitalize" } }}
                required
                {...field}
                fullWidth
                label="First name"
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="lastName"
            control={control}
            render={({ field }) => (
              <TextField
                inputProps={{ style: { textTransform: "capitalize" } }}
                required
                {...field}
                fullWidth
                label="Surname"
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="hcp"
            control={control}
            render={({ field }) => {
              return (
                <TextField
                  value={field.value}
                  onChange={field.onChange}
                  type="number"
                  label="Handicap Index"
                  variant="outlined"
                  fullWidth
                  inputProps={{ min: -10, max: 54, inputMode: "decimal", step: "any" }}
                />
              );
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name="gender"
            control={control}
            render={({ field }) => (
              <FormControl component="fieldset">
                <FormLabel>Gender</FormLabel>
                <RadioGroup {...field} className={classes.group}>
                  <FormControlLabel value="men" control={<Radio />} label="Male" />
                  <FormControlLabel value="women" control={<Radio />} label="Female" />
                </RadioGroup>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {displayText && (
            <Typography color="secondary">
              Unregistered players details are not retained and they will have no features available to them.
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6} className={classes.btnContainer}>
          <Button variant="contained" color="primary" type="submit">
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddGuestPlayer;

AddGuestPlayer.defaultProps = {
  defaultValues: { name: "", lastName: "", hcp: 0, gender: Gender.Men },
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "1rem 2rem",

      "& p": {
        textAlign: "justify",
      },
    },
    group: {
      flexDirection: "row",
    },
    btnContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
  }),
);
