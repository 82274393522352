import { Button, CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { share } from "../../utils/share";
interface ShareFileButtonProps {
  getFiles(): Promise<File[] | null>;
  title: string;
  text: string;
  url?: string;
}

export const ShareFileButton = (props: ShareFileButtonProps) => {
  const [files, setFiles] = useState<File[]>();
  const [error, setError] = useState<boolean>(false);

  const getFiles = async () => {
    const files = await props.getFiles();
    if (files) setFiles(files);
    else setError(true);
  };

  useEffect(() => {
    getFiles();
  }, []);

  if (error)
    return (
      <Button disabled style={{ color: "red" }}>
        Share File Error
      </Button>
    );

  if (!files)
    return (
      <Button disabled>
        Share File
        <CircularProgress size={24} style={{ marginLeft: 8 }} />
      </Button>
    );

  return (
    <Button
      onClick={() =>
        share({
          title: props.title,
          text: props.text,
          url: props.url,
          files,
        })
      }
    >
      Share File
    </Button>
  );
};
