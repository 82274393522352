import React, { Component } from "react";
import { connect } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { IconButton, Dialog, DialogContent, LinearProgress } from "@material-ui/core";
import { GolfCourse, Close } from "@material-ui/icons";
import GameActions from "../../actions/games";
import UserActions from "../../actions/user";
import { User } from "../../types/user";
import { GlobalState } from "../../types/globalState";
import ModernScoreCardView from "../ScoreCard/ModernScoreCardView";
import * as socket from "../../socket";
import { ErrorOnlyCallback } from "../../types/actions";
import { IndexedGames, Game } from "../../types/game";
import { Player } from "types/player";
import { PlayersVerificationType } from "types/general";
import { showAlertDialog } from "../SharedComponents/AlertDialog";
import { showPickerDialog } from "../SharedComponents/PickerDialog";
import { isGameStandard, getPlayersVerificationState } from "utils";
import PlayerName from "components/SharedComponents/PlayerName";
import config from "config";
import { sortPlayerToFirst } from "utils/sortPlayerToFirst";
import { hcpConvertor } from "utils/hcpConvertor";

interface Props {
  setUpdatedResult(game: any): void;
  finishGame(id: string, callback?: ErrorOnlyCallback): void;
  updateUserHcp(userId: string, hcp: number, callback?: any): void;
  recalculatePlayingHcp(id: string, playerId: string, hci: number): void;

  games: IndexedGames;
  gameId: string;
  user: User;
}

interface State {
  openModernScoreCardDialog: boolean;
  openPickerDialog: boolean;
  currentPlayerId?: string;
  onBehalfPlayer?: boolean;
  QSplayers: PlayersVerificationType;
}

type TPlayers = {
  [index: string]: Player;
};

class QuickScorePlayersList extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const players = this.getPlayers();
    const { competition, verifyQs } = this.getGame();

    this.state = {
      openModernScoreCardDialog: false,
      openPickerDialog: false,
      QSplayers: getPlayersVerificationState(Object.values(players), competition.holesNumber, verifyQs),
    };
  }

  componentDidMount() {
    this.connectToWebsocket(this.props.gameId);
  }

  componentWillUnmount() {
    socket.disconnect();
  }

  isMismatch = (pId: string) => {
    return this.state.QSplayers[pId] === "X" ? <span style={{ color: "#ea3d51" }}>X</span> : false;
  };

  getScorablePlayers = () => {
    const players = this.getPlayers();
    return Object.values(players).filter((p: Player) => this.state.QSplayers[p._id] !== true);
  };

  connectToWebsocket = (gameId: any) => {
    if (socket.connect(gameId)) {
      socket.addEvent(socket.EVENTS.SCORES_UPDATED, this.onScoresUpdated);
    }
  };

  onScoresUpdated = (result: any) => this.props.setUpdatedResult(result);

  prepareData(players: any) {
    return players.map((player: any) => {
      return {
        ...player,
        gross: player.points ? player.points.totalGross : 0,
        nett: player.points ? player.points.totalNett : 0,
        pts: player.points ? player.points.totalPoint : 0,
      };
    });
  }

  playerAndTheirGroup(players: TPlayers): Player[] {
    const userPlayer = Object.values(players).find(prev => prev._id === this.props.user._id);

    if (userPlayer) {
      if (userPlayer.group) {
        return Object.values(players)
          .filter(prev => prev._id === userPlayer._id || prev.group?.name === userPlayer.group?.name)
          .sort((_, b) => sortPlayerToFirst(`${userPlayer.name ?? ""} ${userPlayer.lastName}`, b));
      } else {
        return Object.values(players).sort((_, b) =>
          sortPlayerToFirst(`${userPlayer.name ?? ""} ${userPlayer.lastName}`, b),
        );
      }
    }

    return [];
  }

  showHiConfirmDialog = (playerId: string, hi: number) => {
    showAlertDialog(
      "HI Confirmation",
      `Is this player's HI (${hi}) correct?`,
      () => this.showHiNotice(playerId),
      () => this.showHiEditDialog(playerId),
    );
  };

  showHiNotice = (playerId: string) => {
    setTimeout(
      () =>
        showAlertDialog(
          "",
          "Incorrect HI can cause disqualification. Should you need to amend the HI, go to Mark Cards.",
          () => this.openModernScoreCardHandler(true, playerId),
          null,
        ),
      100,
    );
  };

  showHiEditDialog = (playerId: string) => {
    showPickerDialog(
      "Handicap Index",
      "number",
      (hi: string) => {
        this.props.recalculatePlayingHcp(this.props.gameId, playerId, parseFloat(hcpConvertor(hi)));
      },
      undefined,
      null,
      null,
      false,
      true,
    );
  };

  openModernScoreCardHandler = (status: boolean, playerId?: string) => {
    const { verifyQs, organizers } = this.getGame();
    const { user } = this.props;

    if (status && verifyQs && playerId !== user._id && organizers.findIndex(o => o._id === user._id) !== -1) {
      setTimeout(
        () =>
          showAlertDialog(
            "",
            `Are you scoring on behalf of the player ?`,
            () => this.setState({ openModernScoreCardDialog: status, currentPlayerId: playerId, onBehalfPlayer: true }),
            () =>
              this.setState({ openModernScoreCardDialog: status, currentPlayerId: playerId, onBehalfPlayer: false }),
          ),
        100,
      );
    } else {
      this.setState({ openModernScoreCardDialog: status, currentPlayerId: playerId });
    }
  };

  closeModernScoreCardHandler = () => this.openModernScoreCardHandler(false);

  handleVerifyPlayer = () => {
    const { players, competition, verifyQs } = this.getGame();
    this.setState(
      { QSplayers: getPlayersVerificationState(Object.values(players), competition.holesNumber, verifyQs) },
      () => {
        if (Object.values(this.state.QSplayers).every(val => val === true)) {
          this.onSubmitAllCards();
        }
      },
    );
  };

  onSubmitAllCards = () => {
    const { gameId } = this.props;
    this.props.finishGame(gameId);
  };

  getGame = () => {
    const { gameId } = this.props;
    return this.props.games[gameId] as Game;
  };

  getPlayers = () => {
    const { gameId } = this.props;
    return this.props.games[gameId].players;
  };

  findMarkerName = (markerId?: string) => {
    if (!markerId) return "Marker";
    const p = this.getPlayers()[markerId];

    if (p) return `${p.name ?? ""} ${p.lastName}`;
    else {
      const org = this.getGame().organizers.find(org => org._id === markerId);
      if (org) return `${org.name} ${org.lastName}`;
      else return "Marker";
    }
  };

  findMismatches = (player: Player) => {
    const { uholes, holes = {} } = player;

    return Object.keys(holes)
      .filter(h => holes[+h].mismatch)
      .map(hole => {
        const grosses = uholes[+hole].grosses;
        const markerId = Object.keys(grosses).find(pId => pId !== player._id);

        return {
          hole: +hole,
          player: {
            score: grosses[player._id],
            name: `${player.name ?? ""} ${player.lastName}`,
          },
          marker: {
            score: markerId ? grosses[markerId] : 0,
            name: this.findMarkerName(markerId),
          },
        };
      });
  };

  renderModernScoreCardView(hideScores: boolean) {
    const { currentPlayerId } = this.state;
    if (!currentPlayerId) return;

    const players = this.getPlayers();
    const { verifyQs } = this.getGame();

    const mismatches = this.findMismatches(players[currentPlayerId]);

    return (
      <Dialog
        scroll={"paper"}
        open={this.state.openModernScoreCardDialog}
        onBackdropClick={this.closeModernScoreCardHandler}
      >
        <div>
          <IconButton style={{ float: "right" }} onClick={e => this.openModernScoreCardHandler(false, currentPlayerId)}>
            <Close />
          </IconButton>
        </div>
        <ModernScoreCardView
          player={players[currentPlayerId]} // sending one specific player chose by index
          gameId={this.props.gameId}
          quickScoring={true}
          onVerifyPlayer={this.handleVerifyPlayer}
          onCloseModernScoreCardDialog={this.closeModernScoreCardHandler}
          mismatches={mismatches}
          useCurUserScores={verifyQs && !this.state.onBehalfPlayer}
          onBehalfPlayer={this.state.onBehalfPlayer}
        />
      </Dialog>
    );
  }

  render() {
    const { user } = this.props;
    const { leaderboard, organizers, competition } = this.getGame();
    const isStandardGame = isGameStandard(competition.type);
    const players = this.prepareData(this.getScorablePlayers());
    const userAndTheirGroup = this.playerAndTheirGroup(players);
    const hideScores =
      leaderboard?.activateStatus === config.LEADERBOARD_CONFIG.ACTIVATED.ORGANIZER_ONLY &&
      organizers.findIndex(o => o._id === user._id) === -1;

    if (players.length === 0)
      return (
        <DialogContent>
          <h1>No Players for QuickScore</h1>
          <p>All players Score Cards has score</p>
        </DialogContent>
      );

    if (!players[0].points) return <LinearProgress />;

    return (
      <>
        <TableContainer component={Paper} style={{ minWidth: 400 }}>
          {userAndTheirGroup.length > 0 && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Your Group</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userAndTheirGroup.map(player => (
                  <TableRow
                    key={player._id}
                    onClick={e =>
                      isStandardGame || player.isFirstTimeCHP
                        ? this.showHiConfirmDialog(player._id, player.hci)
                        : this.openModernScoreCardHandler(true, player._id)
                    }
                  >
                    <TableCell component="th" scope="row">
                      <IconButton
                        style={{ color: this.isMismatch(player._id) ? "#ea3d51" : "#ffaf0f", marginRight: 5 }}
                        color={"inherit"}
                        size={"small"}
                      >
                        {typeof this.state.QSplayers[player._id] === "string" ? (
                          this.state.QSplayers[player._id]
                        ) : (
                          <GolfCourse />
                        )}
                      </IconButton>
                      <span>
                        <PlayerName isGuest={player.guest} name={player.name ?? ""} lastName={player.lastName} />
                      </span>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{userAndTheirGroup.length > 0 ? "Other Players" : "Name"}</TableCell>
                {/* <TableCell align="right">PH</TableCell>
                <TableCell align="right">Gross</TableCell>
                <TableCell align="right">Net</TableCell>
                <TableCell align="right">Pts</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {players
                .filter((prev: any) => userAndTheirGroup.some(p => p._id === prev._id) === false)
                .map((player: any) => (
                  <TableRow
                    key={player._id}
                    onClick={e =>
                      isStandardGame || player.isFirstTimeCHP
                        ? this.showHiConfirmDialog(player._id, player.hci)
                        : this.openModernScoreCardHandler(true, player._id)
                    }
                  >
                    <TableCell component="th" scope="row">
                      <IconButton
                        style={{ color: this.isMismatch(player._id) ? "#ea3d51" : "#ffaf0f", marginRight: 5 }}
                        color={"inherit"}
                        size={"small"}
                      >
                        {typeof this.state.QSplayers[player._id] === "string" ? (
                          this.state.QSplayers[player._id]
                        ) : (
                          <GolfCourse />
                        )}
                      </IconButton>
                      <span>
                        <PlayerName isGuest={player.guest} name={player.name ?? ""} lastName={player.lastName} />
                      </span>
                    </TableCell>
                    {/* <TableCell align="right">{getHandicapDisplay(player.hcp)}</TableCell>
                  <TableCell align="right">{hideScores ? "--" : this.isMismatch(player._id) || player.gross}</TableCell>
                  <TableCell align="right">{hideScores ? "--" : this.isMismatch(player._id) || player.nett}</TableCell>
                  <TableCell align="right">{hideScores ? "--" : this.isMismatch(player._id) || player.pts}</TableCell> */}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {this.renderModernScoreCardView(hideScores)}
      </>
    );
  }
}

const mapStateToProps = (state: GlobalState) => {
  const { joinedGames, organisedByMe } = state.games;
  const { user } = state.auth;
  return {
    user: user as User,
    games: { ...organisedByMe, ...joinedGames },
  };
};

export default connect(mapStateToProps, {
  setUpdatedResult: GameActions.setUpdatedResult,
  finishGame: GameActions.finishGame,
  updateUserHcp: UserActions.updateHcp,
  recalculatePlayingHcp: GameActions.recalculatePlayingHcp,
})(QuickScorePlayersList);
