export const insertReducer = (state: any, action: any, key: string) => {
  if (!action.payload?._id) return state;
  let items = { ...state[key] };
  items[action.payload._id] = { ...action.data, ...action.payload };
  return { ...state, [key]: items };
};

export const deleteReducer = (state: any, action: any, key: string) => {
  let count = state.count;
  let items = { ...state[key] };
  delete items[action.payload];
  return { ...state, [key]: items, count: count - 1 };
};

export const fetchReducer = (state: any, action: any, key: string) => {
  let newItems: any = {};
  action.payload.forEach((item: any) => {
    newItems[item._id] = item;
  });
  Object.values(state[key]).forEach((item: any) => {
    if (!newItems[item._id]) newItems[item._id] = item;
  });
  // if (action.count) return { ...state, [key]: newItems, count: action.count };
  return { ...state, [key]: newItems, count: action.count };
};

export const updateReducer = (state: any, action: any, key: string) => {
  let items = { ...state[key] };
  items[action.payload] = { ...items[action.payload], ...action.data };
  return { ...state, [key]: items };
};
