import React, { Component } from "react";
import { LinearProgress } from "@material-ui/core";
import { Game } from "../../types/game";
import GameActions from "../../actions/games";
import { GameAdditionalOrganisersTable } from "./GameAdditionalOrganiserTable";
import { connect } from "react-redux";
import { UserBrifObj } from "../../types/user";
import Loading from "../Loading";
import { AxiosError } from "axios";
import { ErrorOnlyCallback } from "../../types/actions";

interface Props {
  getGameById(
    id: string,
    queryType?: string,
    updateRedux?: boolean,
    callback?: (err: AxiosError | null, data: Game) => void,
  ): void;
  addOrganiser(gameId: string, playerId: string, callback?: ErrorOnlyCallback): void;
  removeOrganiser(gameId: string, playerId: string, callback?: ErrorOnlyCallback): void;
  game: Game;
  viewOnly?: boolean;
}

interface State {
  game: Game;
}

class GameAdditionalOrganisersManager extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      game: props.game,
    };
  }

  reload() {
    this.props.getGameById(this.props.game._id, undefined, undefined, (err, game) => {
      if (!err) this.setState({ game });
    });
  }

  render() {
    const { game } = this.state;
    if (!game) return <LinearProgress />;

    return (
      <>
        <Loading color="primary" />
        <GameAdditionalOrganisersTable
          organisers={game.organizers}
          viewOnly={this.props.viewOnly}
          onAdd={(user: UserBrifObj) => this.props.addOrganiser(game._id, user._id, err => !err && this.reload())}
          onDelete={(user: UserBrifObj) =>
            this.props.removeOrganiser(game._id, user._id, (err: any) => !err && this.reload())
          }
        />
      </>
    );
  }
}

export default connect(null, {
  getGameById: GameActions.getById,
  addOrganiser: GameActions.addOrganiser,
  removeOrganiser: GameActions.removeOrganiser,
})(GameAdditionalOrganisersManager);
