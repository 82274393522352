import React, { Component } from "react";
import { Game, PaymentType } from "../../types/game";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Theme,
  Checkbox,
  WithStyles,
  Typography,
  Button,
  createStyles,
  Dialog,
  DialogContent,
  IconButton,
  InputAdornment,
  InputBase,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Switch,
  CircularProgress,
  Box,
} from "@material-ui/core";
import { Close, Search, PersonAdd } from "@material-ui/icons";
import { connect } from "react-redux";

import GameActions from "actions/games";
import PaymentAction from "../../actions/payments";
import { PayStatus, Player } from "../../types/player";
import { GlobalState } from "../../types/globalState";
import { User } from "../../types/user";
import { PaymentRuleFields } from "types/game";
import { showAuxiliaryDialog } from "../SharedComponents/AuxiliaryDialog";
import PaymentModal from "./PaymentModal";
import { PaymentSessionRes } from "types/payment";

interface Props extends WithStyles {
  updateGame(id: string, data: any): void;
  insertPayment(data: any, keepDialogOpen: boolean, callback: any): void;
  paymentSession(data: any, callback: any): void;
  addWithoutPayment(gameId: string, playersId: string[], payStatus: PayStatus, callback?: any): void;
  game: Game;
  user: User;
  type: "guest" | "pay" | "paid";
  payBeforeJoin?: boolean;
}

interface State {
  [index: string]: any;
  selectedPlayers: Map<string, boolean>;
  openDialog: boolean;
  searchRes: any;
  searchTxt: string;
  checked: boolean;
  loading: boolean;
}

class GamePayment extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { user, payBeforeJoin } = this.props;
    const selectedPlayers = new Map();
    if (payBeforeJoin) {
      selectedPlayers.set(user._id, true);
    }

    this.state = {
      selectedPlayers,
      openDialog: false,
      loading: false,
      searchTxt: "",
      searchRes: [],
      checked: props.game.paymentRules.payBeforePlay,
    };
  }

  getGame = () => this.props.game;

  isPlayerSelected = (playerId: string) => this.state.selectedPlayers?.has(playerId);

  onPlayerCheckboxChanged = (playerId: string) => {
    const { selectedPlayers } = this.state;

    if (this.isPlayerSelected(playerId)) selectedPlayers.delete(playerId);
    else selectedPlayers.set(playerId, true);
    this.setState({ selectedPlayers });
  };

  calculateTotal = () => {
    const game = this.getGame();
    const playersCount = this.state.selectedPlayers.size;
    if (game.paymentRules.type === PaymentType.MULTIPLE_PLAYER) return game.paymentRules.fee * playersCount;
    else return game.paymentRules.fee * Math.ceil(playersCount / 4);
  };

  onPayment = () => {
    const game = this.getGame();
    if (this.props.type !== "pay") this.addWithoutPayment(game._id);
    else this.pay(game);
  };

  isGuestMode = () => this.props.type === "guest";

  pay = (game: Game) => {
    this.setState({ loading: true });
    const payment = {
      game,
      players: [...this.state.selectedPlayers.keys()],
      payBeforeJoin: this.props.payBeforeJoin,
    };
    this.props.paymentSession(payment, (err: any, data: PaymentSessionRes) => {
      if (!err) {
        showAuxiliaryDialog(<PaymentModal {...data} />, "xl", true, true, true);
      }
      this.setState({ loading: false });
    });
  };

  addWithoutPayment = (gameId: string) => {
    const payStatus = this.props.type === "guest" ? PayStatus.GUEST : PayStatus.PAID;
    this.props.addWithoutPayment(gameId, [...this.state.selectedPlayers.keys()], payStatus, (err: any) => {
      if (!err) this.setState({ selectedPlayers: new Map() });
    });
  };

  togleDialog = () =>
    this.setState(preState => {
      return { ...preState, openDialog: !preState.openDialog };
    });

  onTextFieldchan = (field: string) => (event: any) => {
    const value = event.target.value as string;
    this.setState({ [field]: value });
    this.searchPlayers(value.toLowerCase());
  };

  searchPlayers = (value: string) => {
    const game = this.getGame();
    this.setState({
      searchRes: Object.values(game.players)
        .filter(player => !player.payStatus)
        .filter(
          player =>
            player.username?.toLowerCase().includes(value) ||
            player.name.toLowerCase().includes(value) ||
            player.lastName.toLowerCase().includes(value) ||
            player.email?.toLowerCase().includes(value),
        ),
    });
  };

  clearSearch = () => {
    this.setState({ searchTxt: "", searchRes: [] });
  };

  renderSearchDialog() {
    const { searchRes, searchTxt } = this.state;
    const { classes } = this.props;
    return (
      <Dialog onBackdropClick={this.togleDialog} open={this.state.openDialog}>
        <DialogContent>
          <InputBase
            value={searchTxt}
            placeholder={"Input username, name or email"}
            className={classes.searchInput}
            inputProps={{ autoFocus: true }}
            onChange={this.onTextFieldchan("searchTxt")}
            startAdornment={
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            }
            endAdornment={
              searchTxt && (
                <InputAdornment position="end">
                  <IconButton onClick={this.clearSearch}>
                    <Close />
                  </IconButton>
                </InputAdornment>
              )
            }
          />
          {typeof searchRes === "object" && this.renderNameList(searchRes)}
        </DialogContent>
      </Dialog>
    );
  }

  getRenderList = (player: Player) => {
    const game = this.getGame();
    const { selectedPlayers } = this.state;
    return Object.values(game.players).filter(x => x.group?.name === player?.group?.name || selectedPlayers.has(x._id));
  };

  renderNameList = (players: Player[]) => {
    const { user, classes } = this.props;
    return (
      <List>
        {players.map(player => {
          const alreadyAdded = this.state.selectedPlayers.has(player._id);
          return (
            <ListItem
              className={user._id === player._id ? classes.currentUserRow : ""}
              button
              key={player._id}
              disabled={Boolean(player.payStatus)}
              onClick={() => this.onPlayerCheckboxChanged(player._id)}
            >
              <ListItemIcon>
                <Checkbox disabled={Boolean(player.payStatus)} edge="start" checked={alreadyAdded} disableRipple />
              </ListItemIcon>
              <ListItemText primary={`${player.name} ${player.lastName || ""}`} secondary={player.username} />
            </ListItem>
          );
        })}
      </List>
    );
  };

  render() {
    const { classes, user } = this.props;
    const game = this.getGame();
    const me = game.players[user._id];
    const players = this.getRenderList(me);
    const isOrganiser = game.organizers.find(org => org._id === user._id) !== undefined;

    const notAllowedToPayOthers = !isOrganiser && game.paymentRules?.payBeforePlay && !me;

    const playersList = notAllowedToPayOthers ? [{ ...user, payStatus: PayStatus.NOT_PAID }] : players;

    return (
      <div className={classes.container}>
        <Typography variant="h5" className={classes.header}>
          {game.name}
        </Typography>

        {isOrganiser && (
          <div className={classes.inline}>
            <Typography variant={"subtitle1"}>Pay Before Play</Typography>
            <Switch
              checked={this.state.checked}
              onChange={evt => {
                this.props.updateGame(game._id, {
                  pot: game.pot,
                  isPaid: game.isPaid,
                  date: game.date,
                  onlyPayment: true,
                  paymentRules: { ...game.paymentRules, [PaymentRuleFields.PAY_BEFORE_PLAY]: evt.target.checked },
                });
                this.setState({ checked: evt.target.checked });
              }}
            />
          </div>
        )}

        {this.renderSearchDialog()}
        <div className={classes.addUsers}>
          <IconButton aria-label="add player" onClick={this.togleDialog} disabled={notAllowedToPayOthers}>
            <PersonAdd />
          </IconButton>
        </div>

        <Typography variant="h6">Selected players:</Typography>
        {this.renderNameList(playersList as Player[])}
        <hr className={classes.hr} />
        <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "12px" }}>
          {!this.isGuestMode() && (
            <Typography variant="h6" style={{ minWidth: "fit-content" }}>
              Total : &#163;{this.calculateTotal()}
            </Typography>
          )}
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={this.onPayment}
            disabled={this.state.selectedPlayers.size === 0 || this.state.loading}
          >
            {this.state.loading ? (
              <CircularProgress size={18} style={{ marginRight: 8 }} />
            ) : this.isGuestMode() ? (
              "Set as guest"
            ) : (
              "Pay"
            )}
          </Button>
        </Box>
      </div>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      padding: "20px 30px",
      minWidth: 350,

      [theme.breakpoints.down("xs")]: {
        minWidth: "auto",
      },
    },
    inline: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    header: {
      textAlign: "center",
      width: "100%",
      paddingBottom: 20,
    },
    button: {
      textAlign: "center",
      width: "fit-content",
      fontWeight: "bold",
      fontSize: "1.25rem",
      lineHeight: 1.6,
      letterSpacing: " 0.0075em",
    },
    playersList: {
      paddingLeft: 15,
      paddingBottom: 20,
      minHeight: 100,
    },
    hr: {
      borderTop: "1px dashed #999999",
    },
    currentUserRow: {
      backgroundColor: "#cce8cf",
    },
    addUsers: {
      textAlign: "right",
      width: "100%",
    },
  });

const mapStateToProps = (state: GlobalState) => {
  const { user } = state.auth;
  return {
    user,
  };
};

export default connect(mapStateToProps, {
  insertPayment: PaymentAction.insert,
  paymentSession: PaymentAction.paymentSession,
  addWithoutPayment: PaymentAction.addWithoutPayment,
  updateGame: GameActions.update,
})(withStyles(styles)(GamePayment));
