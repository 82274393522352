import React from "react";
import Moment from "moment";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Avatar from "@material-ui/core/Avatar";

import Config from "config";
import { ExtendedTeam } from "types/Team";
import { Game } from "types/game";
import { getGross, getGrossStyle, getPlayerPoint } from "utils";
import { useStyles } from "./styles/teamDetails";

export interface TeamDetailsProps {
  team: ExtendedTeam;
  game: Game;
}

const TeamDetails: React.FC<TeamDetailsProps> = ({ team, game }) => {
  const classes = useStyles();
  const { code, name, competition, date, scoreCards } = game;

  const startingHoleIndex = (team.players[0]?.group?.startingHole || 1) - 1;

  const scoreCard = scoreCards.find(sc => sc._id === team.players[0]?.teeColor?.scoreCardId);

  if (!scoreCard) return <h1>No score cards found!</h1>;

  scoreCard.holesParams = [
    ...scoreCard.holesParams.slice(startingHoleIndex),
    ...scoreCard.holesParams.slice(0, startingHoleIndex),
  ];

  return (
    <div className={classes.root}>
      <Grid container spacing={1} style={{ position: "relative" }}>
        <Grid item xs={6} sm={3}>
          <Typography>{code}</Typography>
        </Grid>
        <Grid item xs={12} sm={9}>
          <Typography className={classes.dark}>{name}</Typography>
        </Grid>
        <Grid item xs={3} className={classes.empty}></Grid>
        <Grid item xs={12} sm={9}>
          <Typography>{competition.name}</Typography>
        </Grid>

        {game.course.club.logo && (
          <Avatar
            className={classes.logo}
            alt={game.course.club.name}
            src={`${Config.API_URL}${game.course.club.logo}`}
          />
        )}
      </Grid>

      <TableContainer className={classes.container}>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={3}>
                <span className={classes.th}>Date :&nbsp;</span>
                <span>{Moment(date).format(Config.DATE_DISPLAY_FORMAT)}</span>
              </TableCell>
              {team.players.map(p => (
                <TableCell colSpan={2} key={p._id} align="center" className={classes.th}>
                  {`${p.name} ${p.lastName}`}
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell colSpan={3}>
                <span className={classes.th}>Hcp Allwce :&nbsp;</span>
                <span>{`${competition.fullHandicapAllowance}%`}</span>
              </TableCell>
              {team.players.map(p => (
                <React.Fragment key={p._id}>
                  <TableCell align="center">{`HI: ${p.hci}`}</TableCell>
                  <TableCell align="center">{`PH: ${
                    competition.handicapRules.exactHandicaps ? `${p.hcp}(${Math.round(p.hcp)})` : Math.round(p.hcp)
                  }`}</TableCell>
                </React.Fragment>
              ))}
              <TableCell rowSpan={2} colSpan={2} className={classes.dark}>
                Team Total
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell className={classes.dark}>Hole</TableCell>
              <TableCell className={classes.dark}>Par</TableCell>
              <TableCell className={classes.dark}>SI</TableCell>
              {team.players.map(p => (
                <React.Fragment key={p._id}>
                  <TableCell align="center">Gross</TableCell>
                  <TableCell align="center">Pts/Nett</TableCell>
                </React.Fragment>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(scoreCard.holesParams).map(param => (
              <TableRow key={param.number}>
                <TableCell align="center" className={classes.dark}>
                  {param.number}
                </TableCell>
                <TableCell align="center">{param.par}</TableCell>
                <TableCell align="center" className={classes.red}>
                  {param.si}
                </TableCell>
                {team.players.map(p => {
                  const gross = getGross(param.number, p);
                  const point = getPlayerPoint(p.points.holes[param.number], gross, competition.scoringFormat);

                  return (
                    <React.Fragment key={p._id}>
                      <TableCell align="center">
                        <span style={getGrossStyle(param.par, gross)} className={classes.cell}>
                          {!gross || gross === "mismatch" ? "--" : gross}
                        </span>
                      </TableCell>
                      <TableCell className={`${classes.red} red1`} align="center">
                        {point && team.points[param.number]?.topPlayers.includes(p._id) ? point : "--"}
                      </TableCell>
                    </React.Fragment>
                  );
                })}
                <TableCell align="center" className={classes.red}>
                  {competition.scoringFormat === Config.SCORING_FORMAT.STABLEFORD
                    ? team.points[param.number]?.totals.point || 0
                    : team.points[param.number]?.totals.nett || 0}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={team.players.length * 2 + 3} className={classes.dark}>
                Total
              </TableCell>
              <TableCell align="center" className={classes.red}>
                {competition.scoringFormat === Config.SCORING_FORMAT.STABLEFORD
                  ? team.points.totalPoint
                  : team.points.totalNett}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TeamDetails;
