import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "@material-ui/core/Paper";
import LinearProgress from "@material-ui/core/LinearProgress";
import { signUp } from "../../../actions";
import { createStyles, Theme, WithStyles } from "@material-ui/core";
import logo from "../../../assets/images/logo.png";
import { Step1 } from "./Step1";
import { Step2 } from "./Step2";
import { Step3 } from "./Step3";
import { Step4 } from "./Step4";

import authApi from "../../../api/authentication";
import { showSnackbar } from "../../SharedComponents/Notifier";

interface Props extends WithStyles {
  loading: boolean;
  match: any;
}
interface State {
  activeStep: number;
  loading: boolean;
}
let toggleLoading = () => {};

class SignUpPage extends Component<Props, State> {
  input: any = {
    emailOrUsername: "",
    password: "",
    vCode: "",
  };
  state = {
    activeStep: 0,
    loading: false,
  };

  componentDidMount(): void {
    toggleLoading = this.toggleLoading;
  }

  setActiveStep = (step: number) => {
    this.setState({ activeStep: step });
  };
  toggleLoading = () => {
    this.setState((state: State) => ({ loading: !state.loading }));
  };

  restPassword = () => {
    const { password, vCode, emailOrUsername } = this.input;
    this.toggleLoading();
    authApi
      .resetPassword(vCode, emailOrUsername, password)
      .then((res: any) => {
        this.toggleLoading();
        this.setActiveStep(3);
      })
      .catch((err: any) => {
        this.toggleLoading();
        showSnackbar(err.response.data.error);
      });
  };

  getStepContent = (step: number) => {
    switch (step) {
      case 0:
        const { email } = this.props.match.params;

        return (
          <Step1
            classes={this.props.classes}
            defaults={this.input.emailOrUsername || email}
            onNext={emailOrUsername => {
              this.input.emailOrUsername = emailOrUsername;
              this.setActiveStep(1);
            }}
          />
        );
      case 1:
        return <Step2 classes={this.props.classes} onNext={() => this.setActiveStep(2)} onPrev={() => true} />;
      case 2:
        return (
          <Step3
            defaults={this.input.username}
            classes={this.props.classes}
            onNext={(vCode, password) => {
              this.input.vCode = vCode;
              this.input.password = password;
              this.restPassword();
            }}
            onPrev={() => this.setActiveStep(0)}
          />
        );
      case 3:
        return (
          <Step4
            defaults={this.input.password}
            classes={this.props.classes}
            onNext={() => {}}
            onPrev={() => this.setActiveStep(1)}
          />
        );
      default:
        return null;
    }
  };

  render() {
    const { classes } = this.props;
    const { activeStep } = this.state;


    return (
      <div>
        <main className={classes.main}>
          <Paper className={classes.paper}>
            {this.state.loading ? <LinearProgress color="primary" variant="query" className={classes.loading} /> : null}
            <img src={logo} alt={"logo"} width={128} />
            <strong>Schala</strong>
            <div className={classes.stepperContainer}>{this.getStepContent(activeStep)}</div>
          </Paper>
        </main>
      </div>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    main: {
      width: "auto",
      display: "block", // Fix IE 11 issue.
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      [theme.breakpoints.up(400 + theme.spacing(6))]: {
        width: 400,
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    loading: {
      position: "absolute",
      top: 0,
      width: "100%",
    },
    paper: {
      width: window.innerWidth < 400 ? "100%" : 360,
      height: window.innerWidth < 400 ? "100%" : "auto",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
      backgroundColor: "rgba(255,255,255,0.85)",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%)",
    },
    avatar: {
      margin: theme.spacing(3),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
      padding: theme.spacing(2),
      paddingTop: 0,
    },
    submit: {
      marginTop: theme.spacing(3),
      backgroundColor: "#4692e7",
      borderRadius: 100,
    },
    stepperContainer: {
      marginBottom: 32,
      overflowY: "auto",
    },
    stepContainer: {
      width: 320,
      marginBottom: 16,
    },
    formContainer: {
      display: "flex",
      height: 256,
      width: "100%",
      justifyContent: "space-between",
    },
  });

export default connect(null, { signUp })(withStyles(styles)(SignUpPage));
export { toggleLoading };
