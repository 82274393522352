import ReduxTypes from "../consts/ReduxTypes";

export const switchComponent = (view: any, props?: any) => {
  return {
    type: ReduxTypes.CHANGE_VIEW,
    view,
    props,
  };
};

export const setComponentRouterProps = (props: any) => {
  return {
    type: ReduxTypes.SET_VIEW_STATE,
    props,
  };
};

