import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import {
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
  Switch,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";
import { Leaderboard } from "../../types/game";
import config from "../../config";
import PresentationInputs from "components/SharedComponents/PesentationInputs";
import VideoPopup from "components/SharedComponents/VideoPopup";
import { HELP_VIDEOS } from "consts";
import { useActions } from "hooks/useActions";

const useStyles = makeStyles(() =>
  createStyles({
    video: {
      position: "relative",

      "& > button": {
        position: "absolute",
        top: -15,
        left: -35,
      },
    },
  }),
);
export interface LeaderboardConfigProps {
  leaderboardConfig: Leaderboard;
  gameId: string;
  onLeaderboardConfigChanged: (value: Leaderboard) => void;
}

const LeaderboardConfig: React.FunctionComponent<LeaderboardConfigProps> = props => {
  const classes = useStyles();
  const { resetGamePresentation } = useActions();
  const { ACTIVATED, REVEAL_HIDDEN_PLACES } = config.LEADERBOARD_CONFIG;
  const { leaderboardConfig } = props;

  const onRadioButtonsChanged = (event: any) => {
    props.onLeaderboardConfigChanged({
      ...props.leaderboardConfig,
      [event.target.name]: Number.parseInt(event.target.value),
    });
  };

  const onSwitchChanged = (field: string, value: boolean) => {
    props.onLeaderboardConfigChanged({
      ...props.leaderboardConfig,
      [field]: value,
    });
  };

  const onSelectChanged = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    if (!event.target.name) return;
    props.onLeaderboardConfigChanged({
      ...props.leaderboardConfig,
      [event.target.name]: event.target.value as string,
    });
  };

  const handleInputChange = (field: string, value: number) => {
    props.onLeaderboardConfigChanged({
      ...props.leaderboardConfig,
      [field]: value,
    });
  };

  return (
    <>
      <Grid container>
        <Grid item md={12}>
          <Typography variant="body1" gutterBottom>
            Activate Leaderboard for:
          </Typography>
        </Grid>
        <Grid item md={12}>
          <RadioGroup
            name="activateStatus"
            value={leaderboardConfig?.activateStatus || ACTIVATED.EVERY_ONE}
            onChange={onRadioButtonsChanged}
          >
            <FormControlLabel control={<Radio />} value={ACTIVATED.EVERY_ONE} label="Everyone" />
            <FormControlLabel control={<Radio />} value={ACTIVATED.VIEW_ONLY} label="View Only Screens" />
            <FormControlLabel control={<Radio />} value={ACTIVATED.ORGANIZER_ONLY} label="Organiser Only" />
          </RadioGroup>
        </Grid>
        <Grid item md={10} xs={10}>
          <div className={classes.video}>
            <VideoPopup videoURL={HELP_VIDEOS.LEADERBOARD_PRESENTATION} />
            <Typography variant="body1" gutterBottom>
              Activate Presentation Mode For Organiser
            </Typography>
          </div>
        </Grid>
        <Grid item md={2} xs={2}>
          <Switch
            name="presentationMode"
            value={leaderboardConfig?.presentationMode}
            checked={leaderboardConfig?.presentationMode}
            onChange={e => onSwitchChanged(e.target.name, e.target.checked)}
          />
        </Grid>

        {leaderboardConfig?.revealIndex !== undefined ? (
          <Grid item xs={6}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                resetGamePresentation(props.gameId);
              }}
            >
              Reset presentation options
            </Button>
          </Grid>
        ) : null}
      </Grid>

      {!leaderboardConfig?.presentationMode ? null : (
        <Grid container style={{ paddingTop: 10 }}>
          <Typography variant="h6" gutterBottom>
            Presentation Mode Options
          </Typography>
          <Grid item md={10} xs={10}>
            <Typography variant="body1" gutterBottom>
              Hide places from 1 To
            </Typography>
          </Grid>
          <Grid item md={2} xs={2}>
            <Select name="hideUntil" value={leaderboardConfig?.hideUntil} onChange={onSelectChanged}>
              <MenuItem value={0}>None</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={1000}>All</MenuItem>
            </Select>
          </Grid>
          <Grid item md={10} xs={10}>
            <Typography variant="body1" gutterBottom>
              Reveal hidden places in reverse order
            </Typography>
          </Grid>
          <Grid item md={2} xs={2}>
            <Switch
              name="reverseHiddenPlace"
              value={leaderboardConfig?.reverseHiddenPlace}
              checked={leaderboardConfig?.reverseHiddenPlace}
              onChange={e => onSwitchChanged(e.target.name, e.target.checked)}
            />
          </Grid>
          <Grid item md={10} xs={10}>
            <Typography variant="body1" gutterBottom>
              Display stroke scores in high to low order
            </Typography>
          </Grid>
          <Grid item md={2} xs={2}>
            <Switch
              name="scoresDisplayOrder"
              value={leaderboardConfig?.scoresDisplayOrder}
              checked={leaderboardConfig?.scoresDisplayOrder}
              onChange={e => onSwitchChanged(e.target.name, e.target.checked)}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <RadioGroup
              name="revealPlaceStatus"
              value={leaderboardConfig?.revealPlaceStatus || REVEAL_HIDDEN_PLACES.ONE_LINE}
              onChange={onRadioButtonsChanged}
            >
              <FormControlLabel
                value={REVEAL_HIDDEN_PLACES.ONE_LINE}
                control={<Radio />}
                label="Reveal places in one line"
              />
              <FormControlLabel
                value={REVEAL_HIDDEN_PLACES.TWO_STEP}
                control={<Radio />}
                label="Allow each reveal separately in two steps"
              />
            </RadioGroup>
          </Grid>
        </Grid>
      )}

      <div className={classes.video}>
        <VideoPopup videoURL={HELP_VIDEOS.LEADERBOARD_SCROLL} />
        <PresentationInputs
          fields={leaderboardConfig}
          handleInputChange={handleInputChange}
          onSwitchChange={onSwitchChanged}
        />
      </div>
    </>
  );
};

export default LeaderboardConfig;
