import config from "./config";
import * as serviceWorker from "./serviceWorker";
import { showSnackbar } from "./components/SharedComponents/Notifier";

export const updateVersion = async () => {
  try {
    const response = await fetch(`${config.API_URL}configs/pwaVersion`);
    const { version: latestVersion, publishedVersion } = await response.json();
    let currentVersion = localStorage.getItem("version") || 1;

    localStorage.setItem("publishedVersion", publishedVersion);
    if (!currentVersion) {
      currentVersion = latestVersion;
      localStorage.setItem("version", latestVersion);
    }
    if (currentVersion < latestVersion || config.VERSION != publishedVersion) {
      showSnackbar("A new version of Schala is available, please wait...", 12000);
      forceSWupdate();
      localStorage.setItem("version", latestVersion);
    }
  } catch (error) {
    console.log({ error });
  }
};

const forceSWupdate = () => {
  serviceWorker.unregister();
};
