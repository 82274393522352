import React from "react";

import { IBeforeInstallPromptEvent } from "types/general";
import { useActions } from "hooks/useActions";
import { useSelector } from "hooks/useSelector";

export const useInstallPrompt = (): IBeforeInstallPromptEvent | null => {
  const { setInstallPromptEvt } = useActions();
  const installPrompt = useSelector(state => state.general.installPromptEvt);

  React.useEffect(() => {
    const ready = (evt: IBeforeInstallPromptEvent) => {
      evt.preventDefault();
      setInstallPromptEvt(evt);
    };

    window.addEventListener("beforeinstallprompt", ready as any);

    return () => {
      window.removeEventListener("beforeinstallprompt", ready as any);
    };
  }, []);

  return installPrompt;
};
