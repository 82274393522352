import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import { ADVERT_VALUES } from "consts";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ad: {
      display: "none",

      "&.top": {
        [theme.breakpoints.up("md")]: {
          right: ADVERT_VALUES.SIDEBAR_WIDTH,
        },
      },

      "&.bottom": {
        [theme.breakpoints.up("md")]: {
          right: 0,
        },
      },

      "&.top, &.bottom": {
        [theme.breakpoints.up("md")]: {
          position: "absolute",
          left: 0,
        },
      },

      [theme.breakpoints.up("md")]: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#6edb71",
        color: "#fff",
        height: ADVERT_VALUES.HEIGHT,
        textAlign: "center",
        flex: 1,
        border: "1px solid #333",
      },

      [theme.breakpoints.up("xl")]: {
        "& p.MuiTypography-root": {
          fontSize: "1.6rem",
        },
      },
    },
    sidebar: {
      display: "none",

      [theme.breakpoints.up("md")]: {
        position: "absolute",
        right: 0,
        top: 0,
        bottom: ADVERT_VALUES.HEIGHT,
        zIndex: 100,
        width: ADVERT_VALUES.SIDEBAR_WIDTH,
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "column",
      },
    },
    logoContainer: {
      display: "flex",
      height: ADVERT_VALUES.HEIGHT + 50,

      "& > div": {
        flex: 1,
        height: "100%",
        border: "1px solid #333",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },

      "& img": {
        objectFit: "contain",
      },
    },
    avatar: {
      width: "100%",
      height: "100%",
      backgroundColor: "#fff",

      "& > img": {
        objectFit: "contain",
      },
    },
  }),
);
