import { Action } from "../types/actions";
import ReduxTypes from "../consts/ReduxTypes";

const INITIAL_STATE = {
  loading: false,
  installPromptEvt: null,
  videoHelp: true,
};

export default (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case ReduxTypes.GLOBAL_SET_IS_LOADING:
      return { ...state, loading: true };

    case ReduxTypes.GLOBAL_SET_IS_NOT_LOADING:
      return { ...state, loading: false };

    case ReduxTypes.GLOBAL_SET_INSTALL_PROMPT:
      return { ...state, installPromptEvt: action.payload };

    case ReduxTypes.GLOBAL_SET_VIDEO_HELP:
      return { ...state, videoHelp: action.payload };

    case ReduxTypes.PURGE:
      return INITIAL_STATE;

    default:
      return state;
  }
};
