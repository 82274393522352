import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { GlobalState } from "../../types/globalState";
import { Dialog, WithStyles } from "@material-ui/core";
import AdvertActions from "../../actions/adverts";
import MyTable, { Column, TableAction, TableState } from "../SharedComponents/MyTable";
import { showDialog } from "../SharedComponents/Dialog";
import AdvertSetup from "./AdvertSetup";
import ListStyles from "../../SharedStyles/ListStyle";
import { Advert, IndexedAdverts } from "../../types/advert";
import Selector, { Option } from "../SharedComponents/Selector";

interface Props extends WithStyles {
  deleteAdvert(id: string): void;
  updateAdverts(id: string, data: any): void;
  fetchAdverts(page: number, limit: number, filter?: any, sort?: string, dir?: string, query?: string): void;
  updateAdvertState(id: string, state: string): void;

  adverts: Advert[];
  advertsObj: IndexedAdverts;
  loading: boolean;
  count: number;
}

interface State {
  [index: string]: any;
  openAddDialog?: boolean;
}

class AdvertsList extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      openDialog: false,
    };
    this.props.fetchAdverts(1, 50, null, "name");
  }

  prepareData = (items: any) => {
    return items.map((item: Advert) => {
      return {
        ...item,
        course: item.scoreCard?.course.name || item.game?.course.name,
        scoreCard: item.scoreCard?.name || item.game?.scoreCards[0]?.name,
        teeColor: item.scoreCard?.teeColor || item.game?.scoreCards[0]?.teeColor,
        game: item.game?.name || "---",
        code: item.game?.code || "---",
      };
    });
  };

  _onTableChange = (actions: TableAction, state: TableState) => {
    this.props.fetchAdverts(state.page + 1, state.pageSize, undefined, state.sort.field, state.sort.dir, state.query);
  };
  _onEditItem = (rowId: string) => {
    showDialog(<AdvertSetup edit={this.props.advertsObj[rowId]} />, false, true);
  };
  _onDuplicateItem = (rowId: string) => {
    showDialog(<AdvertSetup copy={this.props.advertsObj[rowId]} />, false, true);
  };
  _onDeleteItems = (rowsIds: string[]) => {
    rowsIds.forEach((id: string) => {
      this.props.deleteAdvert(id);
    });
  };
  _onToggleAddItem = () => {
    showDialog(<AdvertSetup />, "xl", true, false);
  };

  renderAddItemDialog() {
    return (
      <Dialog open={this.state.openAddDialog!} onBackdropClick={this._onToggleAddItem} fullScreen={false}>
        <AdvertSetup />
      </Dialog>
    );
  }

  render() {
    const { classes, adverts, loading, count } = this.props;
    const columns: Column[] = [
      {
        field: "name",
        title: "Name",
      },
      {
        field: "game",
        title: "Game",
      },
      {
        field: "code",
        title: "Game Code",
      },
      {
        field: "type",
        title: "Type",
      },
      {
        field: "course",
        title: "Course",
      },
      {
        field: "scoreCard",
        title: "Score Card",
      },
      {
        field: "teeColor",
        title: "Tee Color",
      },
      {
        field: "id",
        title: "State",
        handler: (field: any, item?: any) => {
          const advertStates: Option[] = [
            { value: "active", label: "Published" },
            { value: "inactive", label: "inActive" },
          ];
          return (
            <Selector
              value={item.state}
              options={advertStates}
              variant={"outlined"}
              onChange={state => this.props.updateAdvertState(item._id, state.toString())}
            />
          );
        },
      },
    ];
    return (
      <div className={classes.paper}>
        <MyTable
          title={"Advertising"}
          loading={loading}
          count={count}
          data={this.prepareData(adverts)}
          columns={columns}
          defaultOrderBy={"name"}
          selectable
          searchEnabled
          onTableChange={this._onTableChange}
          onEdit={this._onEditItem}
          onDuplicate={this._onDuplicateItem}
          onDelete={this._onDeleteItems}
          onAdd={this._onToggleAddItem}
        />
        {this.renderAddItemDialog()}
      </div>
    );
  }
}

const styles = ListStyles({});
const mapStateToProps = (state: GlobalState) => {
  const { adverts, count } = state.adverts;
  const { loading } = state.general;
  return {
    advertsObj: adverts,
    adverts: adverts ? Object.values(adverts) : [],
    loading,
    count,
  };
};

export default connect(mapStateToProps, {
  fetchAdverts: AdvertActions.fetch,
  updateAdverts: AdvertActions.update,
  deleteAdvert: AdvertActions.delete,
  updateAdvertState: AdvertActions.updateAdvertState,
})(withStyles(styles)(AdvertsList));
