import BaseApi from "./Api";
import { User } from "../types/user";
import { GuestPlayerInputs } from "types/player";

class UserApi extends BaseApi {
  url = "users";
  constructor() {
    super();
    this.setResourceUrl(this.url);
  }

  updateMe = (userInfo: any) => {
    let data = new FormData();
    if (userInfo.title) data.append("title", userInfo.title);
    if (userInfo.name) data.append("name", userInfo.name);
    if (userInfo.lastName) data.append("lastName", userInfo.lastName);
    if (userInfo.dob) data.append("dob", userInfo.dob.toString());
    if (userInfo.gender) data.append("gender", userInfo.gender);
    if (userInfo.cdh) data.append("cdh", userInfo.cdh ?? "");
    if (userInfo.postalCode) data.append("postalCode", userInfo.postalCode);
    if (userInfo.socialHcp) data.append("socialHcp", userInfo.socialHcp);
    if (userInfo.image) data.append("image", userInfo.image);
    if (userInfo.hcp) data.append("hcp", userInfo.hcp?.toString() ?? "");
    if (userInfo.email) data.append("email", userInfo.email.toString());
    if (userInfo.username) data.append("username", userInfo.username.toString());
    if (userInfo.homeClub) data.append("homeClub", JSON.stringify(userInfo.homeClub));

    return this.instance({
      method: "put",
      url: `${this.url}/`,
      data,
    });
  };

  update = (userId: string, userInfo: User) => {
    let data = new FormData();
    if (userInfo.title) data.append("title", userInfo.title);
    if (userInfo.name) data.append("name", userInfo.name);
    if (userInfo.lastName) data.append("lastName", userInfo.lastName);
    if (userInfo.dob) data.append("dob", userInfo.dob.toString());
    if (userInfo.gender) data.append("gender", userInfo.gender);
    data.append("cdh", userInfo.cdh?.toString() ?? "");
    if (userInfo.avatar) data.append("avatar", userInfo.avatar);
    if (userInfo.postalCode) data.append("postalCode", userInfo.postalCode);
    data.append("socialHcp", userInfo.socialHcp ?? "");
    data.append("hcp", userInfo.hcp?.toString() ?? "");
    if (userInfo.email) data.append("email", userInfo.email.toString());
    if (userInfo.username) data.append("username", userInfo.username.toString());
    if (userInfo.homeClub) data.append("homeClub", JSON.stringify(userInfo.homeClub));
    if (userInfo.club) data.append("club", JSON.stringify(userInfo.club));
    if (userInfo.rule) data.append("rule", userInfo.rule);
    if ("active" in userInfo) data.append("active", `${userInfo.active}`);
    if ("canAddUser" in userInfo) data.append("canAddUser", `${userInfo.canAddUser}`);

    return this.instance({
      method: "put",
      url: `${this.url}/${userId}`,
      data,
    });
  };

  getClubPlayers = (page: number, limit: number, filter?: any, sort?: string, dir?: string, query?: string) => {
    let url = `${this.url}/club/players?page=${page}&limit=${limit}`;
    if (sort) {
      if (dir) {
        url += `&sort=${sort}:${dir}`;
      } else {
        url += `&sort=${sort}:asc`;
      }
    }
    if (query) {
      url += `&query=${query}`;
    }
    if (filter) {
      url += `&filter=${JSON.stringify(filter)}`;
    }
    return this.instance({
      method: "get",
      url,
    });
  };

  updateHcp = (id: string, hcp: number) => {
    return this.instance({
      method: "patch",
      url: `${this.url}/${id}`,
      data: { hcp },
    });
  };

  updateGuest = (id: string, data: GuestPlayerInputs) => {
    return this.instance({
      method: "patch",
      url: `${this.url}/${id}/update-guest`,
      data,
    });
  };
}

export default new UserApi();
