import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { GlobalState } from "../../types/globalState";
import { WithStyles } from "@material-ui/core";
import CompetitionActions from "../../actions/competitions";
import MyTable, { Column, TableAction, TableState } from "../SharedComponents/MyTable";
import { showDialog } from "../SharedComponents/Dialog";
import CompetitionSetup from "./CompetitionSetup";
import { Competition, IndexedCompetitions } from "../../types/competition";
import ListStyles from "../../SharedStyles/ListStyle";
import { GameRules } from "types/competition";
import { showSnackbar } from "../SharedComponents/Notifier";
import { Rules, User } from "types/user";
import { isGameStandard } from "utils";
import CustomSwitch from "components/SharedComponents/CustomSwitch";

interface Props extends WithStyles {
  deleteCompetition(id: string, active?: boolean): void;
  fetchCompetitionsList(
    page: number,
    limit: number,
    filter?: string,
    sort?: string,
    dir?: string,
    query?: string,
  ): void;

  competitions: Competition[];
  competitionsObj: IndexedCompetitions;
  loading: boolean;
  count: number;
  user: User;
}
interface State {
  [inde: string]: any;
}

class CompetitionsList extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      openDialog: false,
    };
    this.props.fetchCompetitionsList(1, 50, undefined, "name");
  }

  prepareData = (items: any) => {
    return items.map((item: Competition) => {
      let gender = "Undefined";
      let { eligibility } = item;
      if (eligibility[0].exist && eligibility[1].exist) {
        gender = "Mixed";
      } else if (eligibility[0].exist) {
        gender = "Men";
      } else if (eligibility[1].exist) {
        gender = "Ladies";
      }

      const { name, lastName } = item?.owner || {};

      return {
        ...item,
        type: item.type === GameRules.CUSTOM ? "Custom" : `Standard (${item.type})`,
        owner: name && lastName ? `${name} ${lastName}` : `${this.props.user.name} ${this.props.user.lastName}`,
        gender,
        active: item.active === undefined ? true : item.active,
      };
    });
  };

  _onTableChange = (actions: TableAction, state: TableState) => {
    this.props.fetchCompetitionsList(
      state.page + 1,
      state.pageSize,
      undefined,
      state.sort.field,
      state.sort.dir,
      state.query,
    );
  };

  _checkPermission = (cmp: Competition) => {
    if (isGameStandard(cmp.type) && this.props.user.rule !== Rules.Admin) {
      showSnackbar("You are not allowed to do this action.");
      return false;
    }
    return true;
  };

  _onEditItem = (rowId: string) => {
    const cmp = this.props.competitionsObj[rowId];
    if (this._checkPermission(cmp)) showDialog(<CompetitionSetup edit={cmp} />, false, true);
  };

  _onDuplicateItem = (rowId: string) => {
    showDialog(<CompetitionSetup copy={this.props.competitionsObj[rowId]} />, false, true);
  };

  _onDeleteItems = (rowsIds: string[]) => {
    rowsIds.forEach((id: string) => {
      if (this._checkPermission(this.props.competitionsObj[id])) this.props.deleteCompetition(id);
    });
  };

  _onAddItem = () => {
    showDialog(<CompetitionSetup />, false, true);
  };

  render() {
    const { classes, competitions, loading, count, user } = this.props;

    const columns: Column[] = [
      {
        field: "name",
        title: "Name",
      },
      {
        field: "type",
        title: "Rule Type",
      },
      {
        field: "scoringFormat",
        title: "Scoring Format",
      },

      {
        field: "gender",
        title: "Gender",
      },
      ...(user.rule === Rules.Admin
        ? [
            {
              field: "owner",
              title: "Owner",
            },
            {
              field: "active",
              title: "Active",
              handler: (field: any, item?: any) => {
                return (
                  <CustomSwitch
                    checked={field}
                    onChange={e => {
                      this.props.deleteCompetition(item._id, e.target.checked);
                    }}
                  />
                );
              },
            },
          ]
        : []),
    ];

    return (
      <div className={classes.paper}>
        <MyTable
          title={"Game Rules"}
          loading={loading}
          count={count}
          data={this.prepareData(competitions)}
          defaultOrderBy={"name"}
          columns={columns}
          selectable
          disabledRow
          searchEnabled
          onTableChange={this._onTableChange}
          onEdit={this._onEditItem}
          onDelete={this._onDeleteItems}
          onAdd={this._onAddItem}
          onDuplicate={this._onDuplicateItem}
        />
      </div>
    );
  }
}

const styles = ListStyles({});
const mapStateToProps = (state: GlobalState) => {
  const { competitions, count } = state.competitions;
  const { user } = state.auth;
  const { loading } = state.general;
  return {
    competitionsObj: competitions,
    competitions: competitions ? Object.values(competitions) : [],
    count,
    user,
  };
};

export default connect(mapStateToProps, {
  deleteCompetition: CompetitionActions.delete,
  fetchCompetitionsList: CompetitionActions.fetch,
})(withStyles(styles)(CompetitionsList));
