export type Keys =
  | "SCORECARD"
  | "COMPETITION"
  | "TOURNAMENT"
  | "GAME"
  | "CLUBS"
  | "COURSES"
  | "TEAMS"
  | "ADVERTS"
  | "USERS"
  | "HANDICAPS"
  | "PAYMENTS"
  | "WITHDRAWS"
  | "POTS";

export default new (class ReduxTypes {
  //Global
  public GLOBAL_SET_VIDEO_HELP = "GLOBAL_SET_VIDEO_HELP";
  public GLOBAL_SET_INSTALL_PROMPT = "GLOBAL_SET_INSTALL_PROMPT";
  public GLOBAL_SET_IS_LOADING = "GLOBAL_SET_IS_LOADING";
  public GLOBAL_SET_IS_NOT_LOADING = "GLOBAL_SET_IS_NOT_LOADING";

  public PURGE = "PURGE";
  //Authentication
  public LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
  public LOGOUT_USER = "LOGOUT_USER";
  //Component Router
  public CHANGE_VIEW = "CHANGE_VIEW";
  public SET_VIEW_STATE = "SET_VIEW_STATE";

  //ScoreCards
  public INSERT_SCORECARD_SUCCEED = "INSERT_SCORECARD_SUCCEED";
  public FETCH_SCORECARDS_SUCCEED = "FETCH_SCORECARD_SUCCEED";
  public DELETE_SCORECARDS_SUCCEED = "DELETE_SCORECARD_SUCCEED";
  public UPDATE_SCORECARDS_SUCCEED = "UPDATE_SCORECARD_SUCCEED";
  //Competitions
  public INSERT_COMPETITION_SUCCEED = "INSERT_COMPETITION_SUCCEED";
  public FETCH_COMPETITION_SUCCEED = "FETCH_COMPETITION_SUCCEED";
  public DELETE_COMPETITION_SUCCEED = "DELETE_COMPETITION_SUCCEED";
  public UPDATE_COMPETITION_SUCCEED = "UPDATE_COMPETITION_SUCCEED";
  //Tournaments
  public INSERT_TOURNAMENT_SUCCEED = "INSERT_TOURNAMENT_SUCCEED";
  public FETCH_TOURNAMENT_SUCCEED = "FETCH_TOURNAMENT_SUCCEED";
  public DELETE_TOURNAMENT_SUCCEED = "DELETE_TOURNAMENT_SUCCEED";
  public UPDATE_TOURNAMENT_SUCCEED = "UPDATE_TOURNAMENT_SUCCEED";
  public GET_TOURNAMENT_GAMES = "GET_TOURNAMENT_GAMES";
  public ADD_GAME_TO_TOURNAMENTS = "ADD_GAME_TO_TOURNAMENTS";
  public REMOVE_GAME_FROM_TOURNAMENTS = "REMOVE_GAME_FROM_TOURNAMENTS";
  //Games
  public UPDATE_GAME_RULE = "UPDATE_GAME_RULE";
  public UPDATE_GAME_TEAM = "UPDATE_GAME_TEAM";
  public UPDATE_GAME_GROUP = "UPDATE_GAME_GROUP";
  public REMOVE_GAME_GROUPS = "REMOVE_GAME_GROUPS";
  public GET_GAME_PLAYERS = "GET_GAME_PLAYERS";
  public GET_GAME_PLAYERS_REQ = "GET_GAME_PLAYERS_REQ";
  public ADD_TO_VISITED_LEADER_BOARD = "ADD_TO_VISITED_LEADER_BOARD";
  public INSERT_GAME_SUCCEED = "INSERT_GAME_SUCCEED";
  public UPDATE_GAMES_LIST = "UPDATE_GAMES_SUCCEED_WEBSOCKET";
  public UPDATE_GAME_BY_ID = "UPDATE_GAME_BY_ID";
  public JOIN_GAME = "JOIN_GAME";
  public LEAVE_GAME = "LEAVE_GAME";
  public FINISH_GAME = "FINISH_GAME";
  public CLOSE_GAME = "CLOSE_GAME";
  public FETCH_GAMES_SUCCEED = "FETCH_GAME_SUCCEED";
  public DELETE_GAMES_SUCCEED = "DELETE_GAME_SUCCEED";
  public UPDATE_GAMES_SUCCEED = "UPDATE_GAME_SUCCEED";
  public PLAYER_REMOVED = "PLAYER_REMOVED";
  public PLAYER_ADDED = "PLAYER_ADDED";
  public UPDATE_GAME_PLAYER = "UPDATE_GAME_PLAYER";
  public FINISH_CURRENT_GAME = "FINISH_CURRENT_GAME";
  public CHANGE_TEE_COLOR = "CHANGE_TEE_COLOR";
  public GET_JOINED_GAMES = "GET_JOINED_GAMES";
  public ACTIVATE_GAME = "ACTIVATE_GAME";
  public UPDATE_GAME_SCORES = "UPDATE_GAME_SCORES";
  public UPDATE_MARKER = "UPDATE_MARKER";
  public CHANGE_HCP = "CHANGE_HCP";
  public CHANGE_HCI = "CHANGE_HCI";
  public GET_GAMES_HISTORY = "GET_GAMES_HISTORY";
  public GET_GAMES_ORGANISED_BY_ME = "GET_GAMES_ORGANISED_BY_ME";
  public DANGEROUSLY_UPDATE_SCORES = "DANGEROUSLY_UPDATE_SCORES";
  public GET_TOURNAMENTS = "GET_TOURNAMENTS";
  public SET_GAME_AUTOPROGRESS = "SET_GAME_AUTOPROGRESS";
  public REMOVE_GAME_FROM_HISTORY = "REMOVE_GAME_FROM_HISTORY";
  public UPDATE_GAME_LEADERBOARD = "UPDATE_GAME_LEADERBOARD";
  public CLEAR_JOINED_GAMES = "CLEAR_JOINED_GAMES";
  public UPDATE_LINKED_GAMES = "UPDATE_LINKED_GAMES";
  public SET_AS_PAID = "SET_PLAYERS_AS_GUESTS";

  //Teams
  public REMOVE_GAME_TEAMS = "REMOVE_GAME_TEAMS";
  public GET_TEAMS = "GET_TEAMS";
  public CHANGE_TEAM = "CHANGE_TEAM";
  public ADD_NEW_TEAM = "ADD_NEW_TEAM";
  //GROUP
  public INSERT_GROUP = "INSERT_GROUP";
  public UPDATE_GROUP = "UPDATE_GROUP";
  public UPDATE_GROUP_STARTING_HOLE = "UPDATE_GROUP_STARTING_HOLE";
  public ADD_PLAYER_TO_GORUP = "ADD_PLAYER_TO_GORUP";
  public REMOVE_PLAYER_FROM_GORUP = "REMOVE_PLAYER_FROM_GORUP";
  ///USER
  public UPDATE_GUEST_PLAYER = "UPDATE_GUEST_PLAYER";
  public UPDATE_USER = "UPDATE_USER";
  public UPDATE_USER_ADMIN = "UPDATE_USER_ADMIN";
  public UPDATE_USER_HANDICAP = "UPDATE_USER_HANDICAP_INDEX";

  public UPDATE_LOCAL_RULES = "UPDATE_LOCAL_RULES";
  //CLUB
  public ADD_TO_FAV = "ADD_TO_FAV";
  public REMOVE_FROM_FAV = "REMOVE_FROM_FAV";
  public FETCH_CLUB_PLAYERS = "FETCH_CLUB_PLAYERS";
  public FETCH_CLUB_GAMES = "FETCH_CLUB_GAMES";
  public FETCH_CLUB_SCORECARDS = "FETCH_RELATED_SCORECARDS";
  //ADVERT
  public GET_ADVERT_BY_GAME_ID = "GET_ADVERT_BY_GAME_ID";
  public SET_ADVERT_SEEN = "SET_ADVERT_SEEN";
  //HANDICAPS
  public HANDICAPS_SET_HANDICAPS_DETAILS = "HANDICAPS_SET_HANDICAPS_DETAILS";
  public HANDICAPS_UPDATE_HCP = "HANDICAPS_UPDATE_HCP";

  //POTS
  public POTS_GET_TRANSACTIONS = "POTS_GET_TRANSACTIONS";
  // public GET_WITHDRAWS = "GET_WITHDRAWS";

  getCopyType(Key: Keys) {
    return `COPY_${Key}_SUCCEED`;
  }

  getInsertType(Key: Keys) {
    return `INSERT_${Key}_SUCCEED`;
  }

  getUpdateType(Key: Keys) {
    return `UPDATE_${Key}_SUCCEED`;
  }

  getFetchType(Key: Keys) {
    return `FETCH_${Key}_SUCCEED`;
  }

  getDeleteType(Key: Keys) {
    return `DELETE_${Key}_SUCCEED`;
  }
})();
