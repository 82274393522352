import React, { Component } from "react";
import GameActions from "../../actions/games";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
  Typography,
  DialogActions,
  Button,
  Dialog,
} from "@material-ui/core";
import { connect } from "react-redux";
import { GlobalState } from "../../types/globalState";
import { Game, IndexedGames } from "../../types/game";
import { User } from "../../types/user";
import Selector from "../SharedComponents/Selector";
import { showSnackbar } from "../SharedComponents/Notifier";
import { Player } from "../../types/player";
import { HoleSigns } from "../../types/hole";
import { isCombinedGame } from "./../../utils/index";
import GrossPicker from "./GrossPicker";

interface Props extends WithStyles {
  gameId: string;
  onSelect(gross: any, dnfSelected: boolean, player?: Player): void;
  games: IndexedGames;
  user: User;
  holeNumber: number;
  //for submision card page
  teamId?: string;
}
interface State {
  selectedPlayer?: Player;
  openPickerDialog: boolean;
  gross?: HoleSigns;
}
class CombinedGrossPickerDialog extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedPlayer: this.getScoredPlayer(this.getGame(props), this.props.holeNumber),
      openPickerDialog: false,
    };
  }

  getGame = (props?: Props) => {
    if (props) return props.games[props.gameId];
    else return this.props.games[this.props.gameId];
  };

  getTeamId = () => {
    const players = this.getGame().players;
    let teamId = this.props.teamId;
    if (!teamId) {
      const me = players[this.props.user._id];
      teamId = me.team?._id || "";
    }
    return teamId;
  };

  getTeamPlayers = (): Player[] => {
    if (!this.isCombined()) return [];
    const players = this.getGame().players;
    let teamId = this.props.teamId;
    if (!teamId) {
      const me = players[this.props.user._id];
      teamId = me.team?._id;
    }
    return Object.values(players).filter(p => !p.isTeamCard && p.team && p.team._id === this.getTeamId());
  };

  getScoredPlayer = (game: Game, holeNumber: number) => {
    if (!this.isCombined()) return undefined;
    const teamId = this.getTeamId();
    const teePlayer = Object.keys(
      (game.players[teamId].uholes[holeNumber] && game.players[teamId].uholes[holeNumber].grosses) || {},
    );
    if (teePlayer.length) return game.players[teePlayer[0]];
    return undefined;
  };

  isCombined = () => {
    const game = this.getGame();
    return isCombinedGame(game.competition);
  };

  renderDNPContent = (cb: (grs: HoleSigns) => void) => {
    return (
      <div style={{ padding: "1rem 2rem" }}>
        <Typography variant="h6" style={{ marginBottom: 10 }}>
          Player has not played any shots on this hole.
        </Typography>
        <Typography>
          All remaining holes will be marked as DNP. Confirm or use PU button for a general Pick Up.
        </Typography>

        <DialogActions>
          <Button onClick={cb.bind(null, HoleSigns.PU)} color="primary" variant="contained">
            PU
          </Button>
          <Button onClick={cb.bind(null, HoleSigns.DNP)} color="secondary" variant="contained">
            Confirm
          </Button>
        </DialogActions>
      </div>
    );
  };

  onSelect = (gross: any) => {
    const selectedPlayer = this.isCombined() ? this.state.selectedPlayer : undefined;

    if (this.isCombined() && !selectedPlayer) return showSnackbar("Please select a player");

    if (gross === HoleSigns.DNP || gross === HoleSigns.DNF) {
      return this.setState({ openPickerDialog: true, gross });
    }

    this.props.onSelect(gross, false, selectedPlayer);
  };

  renderDNPGrossPicker() {
    const { openPickerDialog, selectedPlayer, gross } = this.state;
    if (!openPickerDialog || !gross) return null;

    const getGross = (grs: HoleSigns | null | number) => {
      this.props.onSelect(
        grs,
        gross === HoleSigns.DNF && grs !== HoleSigns.PU,
        this.isCombined() ? selectedPlayer : undefined,
      );
      this.setState({ openPickerDialog: false });
    };

    return (
      <Dialog open={openPickerDialog} onBackdropClick={() => this.setState({ openPickerDialog: false })}>
        {gross === HoleSigns.DNP ? this.renderDNPContent(getGross) : <GrossPicker dnfGrid onSelect={getGross} />}
      </Dialog>
    );
  }

  render() {
    if (!this.getGame()) return <p>Only players are allowed to score</p>;

    const { classes } = this.props;
    const players = this.getTeamPlayers();

    return (
      <div className={classes.container}>
        {this.isCombined() && (
          <Selector
            options={players.map(p => ({ label: `${p.name} ${p.lastName}`, value: p }))}
            onChange={(selectedPlayer: any) => this.setState({ selectedPlayer })}
            value={this.state.selectedPlayer}
            orientation={"vertical"}
            fullWidth={window.innerWidth < 460}
          />
        )}

        <GrossPicker onSelect={this.onSelect} />
        {this.renderDNPGrossPicker()}
      </div>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "row-reverse",
      alignItems: "center",
      justifyContent: "center",
      flexWrap: "wrap",
      marginRight: 16,
    },
  });

const mapStateToProps = (state: GlobalState) => {
  const { user } = state.auth;
  const { organisedByMe, joinedGames } = state.games;
  return {
    user,
    games: { ...organisedByMe, ...joinedGames },
  };
};

export default connect(mapStateToProps, {
  toggleScoringPlayer: GameActions.toggleScoringPlayer,
})(withStyles(styles)(CombinedGrossPickerDialog));
