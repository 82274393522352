import React, { Component } from "react";
import { connect } from "react-redux";
import Moment from "moment";
import withStyles from "@material-ui/core/styles/withStyles";
import { GlobalState } from "../../types/globalState";
import { IconButton, WithStyles } from "@material-ui/core";
import { List } from "@material-ui/icons";
import TournamentActions from "../../actions/tournaments";
import MyTable, { Column, TableAction, TableState } from "../SharedComponents/MyTable";
import { showDialog } from "../SharedComponents/Dialog";
import TournamentSetup from "./TournamentSetup";
import { IndexedTournaments, Tournament } from "../../types/tournament";
import GameList from "../Game/GameListTable";
import config from "../../config";
import ListStyles from "../../SharedStyles/ListStyle";

interface Props extends WithStyles {
  deleteTournaments(id: string): void;

  fetchTournamentsList(page: number, limit: number, filter?: any, sort?: string, dir?: string, query?: string): void;

  tournaments: Tournament[];
  tournamentsObj: IndexedTournaments;
  loading: boolean;
  count: number;
}
interface State {
  [inde: string]: any;
}

class TournamentsListTable extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      openDialog: false,
    };
    this.props.fetchTournamentsList(1, 50, null, "seasonStart");
  }

  prepareData = (items: any) => {
    return items.map((item: Tournament) => ({
      ...item,
      seasonStart: { isDatetime: true, timestamp: item.seasonStart },
      seasonEnd: { isDatetime: true, timestamp: item.seasonEnd },
    }));
  };

  _onTableChange = (actions: TableAction, state: TableState) => {
    this.props.fetchTournamentsList(
      state.page + 1,
      state.pageSize,
      undefined,
      state.sort.field,
      state.sort.dir,
      state.query,
    );
  };
  _onEditItem = (rowId: string) => {
    showDialog(<TournamentSetup edit={this.props.tournamentsObj[rowId]} />, false, true);
  };
  _onDuplicateItem = (rowId: string) => {
    showDialog(<TournamentSetup copy={this.props.tournamentsObj[rowId]} />, false, true);
  };
  _onDeleteItems = (rowsIds: string[]) => {
    rowsIds.forEach((id: string) => {
      this.props.deleteTournaments(id);
    });
  };
  _onAddItem = () => {
    showDialog(<TournamentSetup />, false, true);
  };

  render() {
    const { classes, tournaments, loading, count } = this.props;
    const columns: Column[] = [
      {
        field: "name",
        title: "Name",
      },
      {
        field: "seasonStart",
        title: "Season start",
      },
      {
        field: "seasonEnd",
        title: "Season end",
      },
      {
        field: "id",
        title: "Games",
        handler(item: any): JSX.Element {
          return (
            <IconButton
              onClick={() => {
                showDialog(<GameList filter={{ tournament: { id: item } }} hideAddButton />, "xl", false, true, true);
              }}
            >
              <List />
            </IconButton>
          );
        },
      },
    ];

    return (
      <div className={classes.paper}>
        <MyTable
          title={"Tournaments"}
          loading={loading}
          count={count}
          data={this.prepareData(tournaments)}
          columns={columns}
          defaultOrderBy={"seasonStart"}
          defaultSortDir={"desc"}
          selectable
          searchEnabled
          onTableChange={this._onTableChange}
          onEdit={this._onEditItem}
          onDelete={this._onDeleteItems}
          onAdd={this._onAddItem}
          onDuplicate={this._onDuplicateItem}
        />
      </div>
    );
  }
}

const styles = ListStyles({});
const mapStateToProps = (state: GlobalState) => {
  const { tournaments, count } = state.tournaments;
  const { loading } = state.general;
  return {
    tournamentsObj: tournaments,
    tournaments: tournaments ? Object.values(tournaments) : [],
    loading,
    count,
  };
};

export default connect(mapStateToProps, {
  fetchTournamentsList: TournamentActions.fetch,
  deleteTournaments: TournamentActions.delete,
})(withStyles(styles)(TournamentsListTable));
