import React, { Component } from "react";
import { connect } from "react-redux";
import Moment from "moment";
import ListStyles from "../../SharedStyles/ListStyle";
import { Button, IconButton, withStyles, WithStyles } from "@material-ui/core";
import { People, Share, Assignment, SupervisorAccount } from "@material-ui/icons";
import PenIcon from "@material-ui/icons/BorderColor";
import GameActions from "../../actions/games";
import MyTable, { Column, TableAction, TableState } from "../SharedComponents/MyTable";
import { showDialog, hideDialog } from "../SharedComponents/Dialog";
import GameSetup from "./GameSetup";
import { GlobalState } from "../../types/globalState";
import GamePlayersManager from "./GamePlayersManager";
import { Game, GState, IndexedGames, OrganisersType } from "../../types/game";
import Messages from "../../consts/Messages";
import IndividualLeaderBoard from "../leaderBoard/IndividualLeaderBoard";
import { share } from "../../utils/share";
import GameAdditionalOrganisersManager from "./GameAdditionalOrganisersManager";
import { UserBrifObj, Rules } from "../../types/user";
import { showAlertDialog } from "../SharedComponents/AlertDialog";
import { StartSheet } from "./GameStartSheet";

interface Props extends WithStyles {
  activateGame(id: string): void;
  deleteGame(id: string): void;
  fetchGames(page: number, limit: number, filter?: any, sort?: string, dir?: string, query?: string): void;
  user: any;
  gamesObj: IndexedGames;
  games: Game[];
  loading: boolean;
  count: number;
  filter?: any;
  hideAddButton?: boolean;
}
interface State {
  actionMenuAnchors: any;
}
class GameListTable extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      actionMenuAnchors: {},
    };
    this.props.fetchGames(1, 50, props.filter, "date");
  }

  getGameOwner = (game: Game): UserBrifObj => {
    return game?.organizers?.filter(o => o?.type === OrganisersType.Owner)[0];
  };

  prepareData = (items: any) => {
    return items.map((item: any) => {
      if (item) {
        const date = Moment(item.date);
        return {
          ...item,
          name: item.name,
          code: item.code,
          course: item?.course?.name,
          club: item.course?.club?.name,
          date: { isDatetime: true, timestamp: date },
          tournamentName: typeof item.tournament === "object" ? item.tournament.map((t: any) => t.name) : "",
          competitionName: item.competition ? item.competition.name : "",
          organiser: this.getGameOwner(item)?.username,
        };
      } else {
        return item;
      }
    });
  };

  _onTableChange = (actions: TableAction, state: TableState) => {
    this.props.fetchGames(state.page + 1, state.pageSize, undefined, state.sort.field, state.sort.dir, state.query);
  };
  _onDeleteItems = (rowsIds: string[]) => {
    showAlertDialog(
      "Delete games",
      "If you delete this game(s), all scorecards will be removed and no handicap adjustment will be made. are you sure?",
      () =>
        rowsIds.forEach((id: string) => {
          this.props.deleteGame(id);
        }),
      () => {},
    );
  };
  _onEditItem = (rowId: string) => {
    showDialog(<GameSetup edit={this.props.gamesObj[rowId]} />, false, true);
  };
  _onDuplicateItem = (rowId: string) => {
    showDialog(<GameSetup copy={this.props.gamesObj[rowId]} />, false, true);
  };
  _onAddItem = () => {
    showDialog(<GameSetup />, false, true);
  };
  _activateGame = (id: any) => {
    this.props.activateGame(id);
  };

  _handleComponentSwitch = (goTo: string, gameId: string) => () => {
    const game = this.props.gamesObj[gameId];
    if (!game) return;
    // Go to Start Sheet
    if (goTo === "Start") {
      hideDialog();
      const imOrganiser = game.organizers.find(o => o._id === this.props.user._id) !== undefined;

      showDialog(
        <StartSheet
          gameId={game._id}
          games={this.props.gamesObj}
          downloadable={imOrganiser}
          onSwitchClick={this._handleComponentSwitch("PG", gameId)}
        />,
        "xl",
        true,
        true,
        true,
        `${game.name} Start Sheet`,
      );
    }
    // Go to Players & Groups
    else if (goTo === "PG") {
      hideDialog();

      showDialog(
        <GamePlayersManager onSwitchClick={this._handleComponentSwitch("Start", gameId)} gameId={gameId} minChar={3} />,
        "lg",
        true,
        true,
        true,
      );
    }
  };

  render() {
    const { classes, games, loading, count, filter, hideAddButton } = this.props;
    const columns: Column[] = [
      {
        field: "name",
        title: "Name",
      },
      ...(this.props.user.rule === Rules.Admin || this.props.user.rule === Rules.ClubManager
        ? [
            {
              field: "organiser",
              title: "Organiser",
            },
          ]
        : []),
      {
        field: "code",
        title: "Game Code",
        handler: (field: any, item?: any) => (
          <>
            {field}
            <IconButton
              color={"primary"}
              onClick={() => {
                share({
                  title: Messages.share.title,
                  text: Messages.share.message,
                  url: `http://schala.golf/join/${field.code}`,
                });
              }}
            >
              <Share />
            </IconButton>
          </>
        ),
      },
      {
        field: "club",
        title: "Club",
      },
      {
        field: "course",
        title: "Course",
      },
      {
        field: "date",
        title: "Date",
      },
      {
        field: "tournamentName",
        title: "Tournament",
      },
      {
        field: "competitionName",
        title: "Game Setup",
      },
      ...(this.props.user.rule === Rules.Organiser
        ? [
            {
              field: "_id",
              title: "Game Rule",
              handler: (id: string, game: Game) => (
                <IconButton
                  style={{ color: "#2ebc2e" }}
                  onClick={() =>
                    showDialog(<GameSetup edit={this.props.gamesObj[id]} onlyGameRule />, "md", false, true)
                  }
                >
                  <PenIcon />
                </IconButton>
              ),
            },
          ]
        : []),
      {
        field: "_id",
        title: "Players",
        handler: (id: string) => {
          return (
            <IconButton
              onClick={() => {
                showDialog(
                  <GamePlayersManager
                    onSwitchClick={this._handleComponentSwitch("Start", id)}
                    gameId={id}
                    minChar={3}
                  />,
                  "lg",
                  true,
                  true,
                  true,
                );
              }}
            >
              <People />
            </IconButton>
          );
        },
      },
      {
        field: "_id",
        title: "Organisers",
        handler: (id: string, game: Game) => {
          return (
            <IconButton
              onClick={() => showDialog(<GameAdditionalOrganisersManager game={game} />, "lg", true, true, true)}
            >
              <SupervisorAccount />
            </IconButton>
          );
        },
      },
      {
        field: "state",
        title: "State",
        handler: (state: any, game: Game) => {
          if (state === GState.Done) {
            return <strong style={{ color: "#6a666a" }}>Done</strong>;
          } else if (state === GState.Playing) {
            return <strong style={{ color: "#33a136" }}>Playing</strong>;
          } else {
            return (
              <Button color={"primary"} variant={"outlined"} onClick={() => this._activateGame(game._id)}>
                Activate
              </Button>
            );
          }
        },
      },
      {
        field: "_id",
        title: "Leader Board",
        handler: (id: string, game: Game) => (
          <IconButton
            onClick={() => showDialog(<IndividualLeaderBoard finalCheck gameId={id} />, "xl", false, false, true)}
          >
            <Assignment />
          </IconButton>
        ),
      },
    ];

    return (
      <div className={classes.paper}>
        <MyTable
          title={"Games"}
          loading={loading}
          count={count}
          filter={filter}
          data={this.prepareData(games)}
          hideAddButton={hideAddButton}
          columns={columns}
          defaultOrderBy={"date"}
          defaultSortDir={"desc"}
          selectable
          searchEnabled
          onTableChange={this._onTableChange}
          onEdit={this._onEditItem}
          onDelete={this._onDeleteItems}
          onDuplicate={this._onDuplicateItem}
        />
      </div>
    );
  }
}

const styles = ListStyles({});
const mapStateToProps = (state: GlobalState) => {
  const { games, count, joinedGames, organisedByMe } = state.games;
  const { loading } = state.general;
  const { user } = state.auth;
  return {
    gamesObj: { ...games, ...joinedGames, ...organisedByMe },
    games: games ? Object.values(games) : [],
    loading,
    count,
    user,
  };
};

export default connect(mapStateToProps, {
  deleteGame: GameActions.delete,
  fetchGames: GameActions.fetch,
  activateGame: GameActions.activateGame,
})(withStyles(styles)(GameListTable));
