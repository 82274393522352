import { useRef,useEffect,useCallback } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { PresentationFields } from "types/game";
import { sleep,scrollTo,hasReachedToTheBottom } from "utils";
import { DEFAULT_PRESENTATION_VALUES } from "components/SharedComponents/PesentationInputs";

export const useScroll = (
  fields: PresentationFields | undefined,
  containerEl?: HTMLDivElement | null,
  tableRowEl?: HTMLTableRowElement | null,
  setPageRefreshVisibility?: (val: boolean) => void,
) => {
  const matches = useMediaQuery("(min-width:600px)");
  const loop = useRef<boolean>(true);

  const {
    scroll,
    widescreen,
    continuous = DEFAULT_PRESENTATION_VALUES.continuous,
    lastPageHoldTime = DEFAULT_PRESENTATION_VALUES.lastPageHoldTime,
    firstPageHoldTime = DEFAULT_PRESENTATION_VALUES.firstPageHoldTime,
    pagesHoldTime = DEFAULT_PRESENTATION_VALUES.pagesHoldTime,
    scrollSpeed = DEFAULT_PRESENTATION_VALUES.scrollSpeed,
    rowsPerPage = DEFAULT_PRESENTATION_VALUES.rowsPerPage,
  } = fields || DEFAULT_PRESENTATION_VALUES;


  const scrollTopWithAdv = useCallback(
    async (el: HTMLDivElement) => {
      if (widescreen && lastPageHoldTime && setPageRefreshVisibility) {
        setPageRefreshVisibility(true);
        await sleep(lastPageHoldTime);
        setPageRefreshVisibility(false);
      }
      el.scrollTop = 0;
    },
    [lastPageHoldTime,setPageRefreshVisibility,widescreen],
  );

  useEffect(() => {

    if (!widescreen) return;

    (async () => {
      // console.log("matches", matches);
      if (!matches) return;
      // console.log(scroll, containerRef, tableRowRef);

      let flag = 1;
      // console.log(tableRowEl);
      if (!scroll || !containerEl || !tableRowEl) return;

      const tableRowHeight = tableRowEl.getBoundingClientRect().height;
      // Seconds to milliseconds
      const speedInMil = scrollSpeed * 1000;
      if (!loop.current) loop.current = true;

      while (loop.current) {
        if (continuous) {
          await sleep(0.5);
          // Up ?
          if (hasReachedToTheBottom(containerEl)) {
            // console.log("Scroll Up!!");
            await scrollTopWithAdv(containerEl);
            await sleep(0.5);
          } else {
            // console.log("Scroll Down!!");
            await scrollTo(containerEl,containerEl.scrollHeight,speedInMil,true);
            // console.log("Awaked!");
          }
          continue;
        }

        // First page ?
        if (flag === 1) {
          await sleep(firstPageHoldTime);
          flag = -1;
        }
        // Other pages
        else {
          await sleep(pagesHoldTime + scrollSpeed);
        }
        // Last Page
        if (hasReachedToTheBottom(containerEl)) {
          // console.log("Scroll Up!!");
          await scrollTopWithAdv(containerEl);
          flag = 1;
        }
        // Scroll down!
        else {
          // console.log("Scroll Down!!");
          scrollTo(containerEl,rowsPerPage * tableRowHeight + containerEl.scrollTop,speedInMil,false);
        }
      }
    })();

    return () => {
      loop.current = false;
    };
  },[
    containerEl,
    tableRowEl,
    matches,
    scroll,
    continuous,
    firstPageHoldTime,
    scrollSpeed,
    scrollTopWithAdv,
    pagesHoldTime,
    rowsPerPage,
  ]);
};
