import React from "react";
import { Table, TableHead, TableRow, TableBody, Switch, TableCell, Input } from "@material-ui/core";
import { TightBodyCell, TightHeaderCell } from "../SharedComponents/CustomTableElements";
import { Eligibility } from "../../types/competition";

interface Props {
  eligibility: Eligibility[];
  className: string;
  onEligibilityChanged: (value: Eligibility[]) => void;
}

export default function CompetitionEligability(props: Props) {
  const { eligibility, className } = props;

  const onSwitchChanged = (field: string, index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const tmp = [...props.eligibility];
    tmp[index][field] = event.target.checked as boolean;
    props.onEligibilityChanged(tmp);
  };

  const onTextChanged = (
    field: string,
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const tmp = [...props.eligibility];
    tmp[index][field] = event.target.value as string;
    props.onEligibilityChanged(tmp);
  };

  return (
    <div>
      <Table size={"small"}>
        <TableHead>
          <TableRow>
            <TightHeaderCell align={"left"}></TightHeaderCell>
            <TightHeaderCell align={"left"}>Gender</TightHeaderCell>
            <TightHeaderCell align={"left"}>Min Handicap</TightHeaderCell>
            <TightHeaderCell align={"left"}>Max Handicap</TightHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TightBodyCell>
              <Switch
                size={"small"}
                checked={eligibility[0].exist}
                value={eligibility[0].exist}
                onChange={e => onSwitchChanged("exist", 0, e)}
              />
            </TightBodyCell>
            <TableCell>Men</TableCell>
            <TightBodyCell>
              <Input
                type={"number"}
                inputProps={{ min: 0, max: 54 }}
                className={className}
                value={eligibility[0].minHandicap}
                onChange={e => onTextChanged("minHandicap", 0, e)}
              />
            </TightBodyCell>
            <TightBodyCell>
              <Input
                type={"number"}
                inputProps={{ min: 0, max: 54 }}
                className={className}
                value={eligibility[0].maxHandicap}
                onChange={e => onTextChanged("maxHandicap", 0, e)}
              />
            </TightBodyCell>
          </TableRow>
          <TableRow>
            <TightBodyCell>
              <Switch
                size={"small"}
                checked={eligibility[1].exist}
                value={eligibility[1].exist}
                onChange={e => onSwitchChanged("exist", 1, e)}
              />
            </TightBodyCell>
            <TableCell>Ladies</TableCell>
            <TightBodyCell>
              <Input
                type={"number"}
                className={className}
                value={eligibility[1].minHandicap}
                inputProps={{ min: 0, max: 54 }}
                onChange={e => onTextChanged("minHandicap", 1, e)}
              />
            </TightBodyCell>
            <TightBodyCell>
              <Input
                type={"number"}
                className={className}
                value={eligibility[1].maxHandicap}
                inputProps={{ min: 0, max: 54 }}
                onChange={e => onTextChanged("maxHandicap", 1, e)}
              />
            </TightBodyCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
}
