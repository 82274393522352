import { BaseActions } from "./BaseActions";
import tournamentsApi from "../api/tournaments";
import ReduxTypes from "../consts/ReduxTypes";
import ErrorHandler from "../api/Errorhandler";
import { ErrorOnlyCallback } from "../types/actions";

export default new (class TournamentActions extends BaseActions {
  constructor() {
    super(tournamentsApi, "TOURNAMENT");
  }

  getTournamentGames = (tournamentId: string, callback?: any) => {
    return (dispatch: any) => {
      tournamentsApi
        .getTournamentGames(tournamentId)
        .then((res: any) => {
          dispatch({ type: ReduxTypes.GET_TOURNAMENT_GAMES, payload: res.data });
          if (callback) callback(null, res.data);
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
          if (callback) callback(err, null);
        });
    };
  };

  addGameToTournament = (tournamentId: string, gameId: string, callback?: ErrorOnlyCallback) => {
    return (dispatch: any) => {
      tournamentsApi
        .addGame(tournamentId, gameId)
        .then((res: any) => {
          // dispatch({ type: ReduxTypes.ADD_GAME_TO_TOURNAMENTS, payload: { tournamentId, gameId } });
          if (callback) callback(null);
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
          if (callback) callback(err);
        });
    };
  };

  removeGameFromTournament = (tournamentId: string, gameId: string, callback?: ErrorOnlyCallback) => {
    return (dispatch: any) => {
      tournamentsApi
        .removeGame(tournamentId, gameId)
        .then((res: any) => {
          // dispatch({ type: ReduxTypes.GET_TOURNAMENT_GAMES, payload: res.data });
          if (callback) callback(null);
        })
        .catch((err: any) => {
          ErrorHandler(err, dispatch);
          if (callback) callback(err);
        });
    };
  };
})();
