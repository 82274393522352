import React, { useState } from "react";
import { QRCode } from "react-qrcode-logo";
import html2canvas from "html2canvas";
import moment from "moment";
import jsPDF from "jspdf";
import Button from "@material-ui/core/Button";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

import logo from "assets/images/qr-logo.png";
import { Game } from "types/game";
import { GameDetails, getTeeDetails } from "components/Game/GameDetails";
import { useStyles } from "./styles";
import { ScoreCard } from "types/scoreCard";

interface QRCodeProps {
  url: string;
  game: Game;
}

const QRCodeComponent: React.FC<QRCodeProps> = ({ url, game }) => {
  const classes = useStyles();
  const [show, setShow] = useState(true);

  const handleClick = () => {
    html2canvas(document.querySelector("#react-qrcode-logo") as any).then(function(canvas) {
      const data = canvas.toDataURL("image/png", 1.0);
      const doc = new jsPDF("l", "mm", "a4");

      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();

      const marginX = pageWidth / 2;
      const marginY = pageHeight / 2;

      const scoreCard: ScoreCard = Object.values(game.scoreCards)[0];
      doc.text(`${game.name}, ${game.code}`, marginX, marginY, "center");
      doc.setTextColor(100, 100, 100);
      doc.text(`${scoreCard?.course.name}, ${scoreCard?.course.club?.name}`, marginX, marginY + 11, "center");
      doc.text(`${moment(game.date).format("ddd DD/MM/YY hh.mm")}`, marginX, marginY + 22, "center");

      const playingAs =
        game.competition?.playingRules && game.competition.playingRules.playingAs === "team" ? "Team" : "Individual";
      const handicapAllowance = game.competition?.fullHandicapAllowance
        ? `, PH = CH x ${game.competition.fullHandicapAllowance}%`
        : "";
      doc.text(
        `${playingAs}, ${scoreCard?.course.holesNumber} Holes${handicapAllowance}`,
        marginX,
        marginY + 33,
        "center",
      );
      doc.text(getTeeDetails(game).join(","), marginX, marginY + 44, "center");

      doc.addImage(data, "PNG", marginX - 20, marginY - 60, 40, 40);
      doc.save(`${game.name}-QR-Code.pdf`);

      setShow(false);
    });
  };

  return (
    <div className={classes.root}>
      <QRCode value={url} fgColor="#001100" logoImage={logo} logoWidth={60} logoHeight={65} quietZone={5} />

      <GameDetails game={game} />

      {show && (
        <Button onClick={handleClick} variant="contained" color="primary" startIcon={<CloudDownloadIcon />}>
          Download
        </Button>
      )}
    </div>
  );
};

export default QRCodeComponent;
