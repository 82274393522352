import * as React from "react";
import Moment from "moment";
import { Grid, Avatar, ListItemText, Typography, WithStyles, createStyles, Theme, IconButton } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { GlobalState } from "./../../types/globalState";
import { Tournament } from "./../../types/tournament";
import GameActions from "../../actions/games";
import TournamentActions from "../../actions/tournaments";
import { Assignment, AssistantPhoto, GolfCourse } from "@material-ui/icons";
import { showDialog } from "../SharedComponents/Dialog";
import TournamentLeaderboard from "../TournamentLeaderboard";
import TournamentGamesManager from "./TournamentGamesManager";
import { Rules, User } from "../../types/user";

export interface TournamentHistoryProps extends WithStyles {
  user: User;
  tournaments: Tournament[];
  getTournaments: () => void;
  getTournamentGames: (tournamentId: string, callback?: any) => void;
}

interface State {}

class TournamentHistory extends React.Component<TournamentHistoryProps> {
  componentDidMount() {
    this.props.getTournaments();
  }

  handleOnTournamentClick = (tournament: Tournament) => {
    this.props.getTournamentGames(tournament._id || "", () => {
      showDialog(<TournamentLeaderboard tournament={tournament} />, "xl", false, false, true);
    });
  };

  renderNoTournament = () => {
    const { classes } = this.props;

    return (
      <div className={classes.emptyTournament}>
        <Typography variant="body2" gutterBottom>
          You haven't joined a tournament yet.
        </Typography>
      </div>
    );
  };

  render() {
    const { classes, user } = this.props;
    return (
      <div style={{ backgroundColor: "white" }}>
        {!this.props.tournaments.length
          ? this.renderNoTournament()
          : this.props.tournaments
              .sort((a: any, b: any) => (a.createDate < b.createDate ? 1 : -1))
              .map(tournament => {
                return (
                  <React.Fragment key={tournament._id}>
                    <Grid container className={classes.rowContainer}>
                      <Grid item md={2} xs={2}>
                        <Avatar>
                          <AssistantPhoto />
                        </Avatar>
                      </Grid>
                      <Grid item md={5} xs={4}>
                        <ListItemText primary={tournament.name} secondary="" />
                      </Grid>
                      <Grid item md={3} xs={3}>
                        <ListItemText
                          primary={Moment(tournament.seasonStart).format("ll")}
                          secondary={Moment(tournament.seasonEnd).format("ll")}
                          style={{ textAlign: "center", minWidth: 80 }}
                        />
                      </Grid>
                      <Grid item md={2} xs={3} direction="row">
                        <IconButton onClick={() => this.handleOnTournamentClick(tournament)}>
                          <Assignment />
                        </IconButton>
                        <IconButton
                          onClick={() =>
                            showDialog(
                              <TournamentGamesManager
                                tournamentId={tournament._id}
                                editable={user.rule === Rules.Admin || tournament.owner === user._id}
                              />,
                              "xl",
                              true,
                              true,
                              true,
                              `${tournament.name}`,
                            )
                          }
                        >
                          <GolfCourse />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                );
              })}
      </div>
    );
  }
}
const styles = (theme: Theme) =>
  createStyles({
    rowContainer: {
      width: "100%",
      position: "relative",
      padding: "8px 24px",
      alignItems: "center",
      borderBottom: "1px solid #eeeeee",
    },
    emptyTournament: {
      textAlign: "center",
      padding: 20,
      color: "#888888",
    },
  });

const mapStateToProps = (state: GlobalState) => {
  const tournaments = state.games.tournaments;
  const { user } = state.auth;
  return {
    tournaments,
    user,
  };
};

export default connect(mapStateToProps, {
  getTournaments: GameActions.getTournaments,
  getTournamentGames: TournamentActions.getTournamentGames,
})(withStyles(styles)(TournamentHistory));
