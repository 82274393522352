import { useState, useEffect } from "react";

import playersAPI from "api/players";
import { Player } from "types/player";

export type BriefPlayer = Pick<Player, "_id" | "name" | "lastName" | "username">;

type StateType = {
  players: BriefPlayer[];
  isLoading: boolean;
};

export const useNewPlayers = (gameCode?: string) => {
  const [state, setState] = useState<StateType>({
    players: [],
    isLoading: false,
  });

  useEffect(() => {
    if (gameCode && gameCode.length !== 6) return;

    const getPlayers = async () => {
      setState(prevState => ({ ...prevState, isLoading: true }));
      const { data } = await playersAPI.getNewPlayers(gameCode);
      setState({ players: data.data.users, isLoading: false });
    };

    getPlayers();
  }, [gameCode]);

  return state;
};
