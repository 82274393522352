import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { WithStyles, IconButton } from "@material-ui/core";
import PenIcon from "@material-ui/icons/BorderColor";
import CoursesActions from "../../actions/courses";
import MyTable, { Column, TableAction, TableState } from "../SharedComponents/MyTable";
import { showDialog } from "../SharedComponents/Dialog";
import { GlobalState } from "../../types/globalState";
import ListStyles from "../../SharedStyles/ListStyle";
import { Course, IndexedCourses } from "../../types/course";
import ClubSetup from "./CourseSetup";
import LocalRules from "components/LocalRules";

interface Props extends WithStyles {
  deleteCourses(id: string): void;
  fetchCourses(page: number, limit: number, filter?: any, sort?: string, dir?: string, query?: string): void;

  courses: Course[];
  coursesObj: IndexedCourses;
  loading: boolean;
  count: number;
}

interface State {
  [inde: string]: any;
}

class CoursesListTable extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      openDialog: false,
    };
    this.props.fetchCourses(1, 50, null, "name");
  }

  prepareData = (items: Course[]) => {
    return items.map((item: Course) => ({
      ...item,
      club: item.club ? item.club.name : "Undefined",
      clubLogo: item.club?.logo || "",
    }));
  };

  _onTableChange = (actions: TableAction, state: TableState) => {
    this.props.fetchCourses(state.page + 1, state.pageSize, undefined, state.sort.field, state.sort.dir, state.query);
  };
  _onEditItem = (rowId: string) => {
    showDialog(<ClubSetup edit={this.props.coursesObj[rowId]} />, false, true);
  };

  _handleLocalRulesClick = (course?: any) => {
    if (course)
      showDialog(
        <LocalRules courseId={course._id} logo={course.clubLogo} content={course.localRules} />,
        "md",
        true,
        true,
        true,
      );
  };

  _onDeleteItems = (rowsIds: string[]) => {
    rowsIds.forEach((id: string) => {
      this.props.deleteCourses(id);
    });
  };
  _onAddItem = () => {
    showDialog(<ClubSetup />, false, true);
  };

  render() {
    const { classes, courses, loading, count } = this.props;
    const columns: Column[] = [
      {
        field: "name",
        title: "Name",
      },
      {
        field: "club",
        title: "Club",
      },
      {
        field: "holesNumber",
        title: "Number of Holes",
      },
      {
        field: "localRules",
        title: "Local Rules",
        handler: (_: any, item?: any) => (
          <IconButton style={{ color: "#2ebc2e" }} onClick={this._handleLocalRulesClick.bind(this, item)}>
            <PenIcon />
          </IconButton>
        ),
      },
    ];

    return (
      <div className={classes.paper}>
        <MyTable
          title={"Courses"}
          loading={loading}
          count={count}
          data={this.prepareData(courses)}
          defaultOrderBy={"name"}
          columns={columns}
          selectable
          searchEnabled
          onTableChange={this._onTableChange}
          onEdit={this._onEditItem}
          onDelete={this._onDeleteItems}
          onAdd={this._onAddItem}
        />
      </div>
    );
  }
}

const styles = ListStyles({});
const mapStateToProps = (state: GlobalState) => {
  const { courses, count } = state.courses;
  const { loading } = state.general;
  return {
    coursesObj: courses,
    courses: courses ? Object.values(courses) : [],
    loading,
    count,
  };
};

export default connect(mapStateToProps, {
  fetchCourses: CoursesActions.fetch,
  deleteCourses: CoursesActions.delete,
})(withStyles(styles)(CoursesListTable));
