import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Button, ButtonGroup, createStyles, Theme, WithStyles } from "@material-ui/core";

export type ButtonVariant = "text" | "outlined" | "contained";
export interface Option {
  value: any;
  label: string | number;
}
interface Props extends WithStyles {
  onChange?(value: string | number): void;

  options: Option[];
  variant?: ButtonVariant;
  value: any;
  error?: boolean;
  className?: string;
  size?: "small" | "medium" | "large";
  orientation?: "horizontal" | "vertical";
  fullWidth?: boolean;
}

interface State {
  selected?: string | number;
}

class Selector extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selected: props.value,
    };
  }

  onSelect = (opt: Option) => {
    const { onChange } = this.props;
    this.setState({ selected: opt.value });
    if (onChange) {
      onChange(opt.value);
    }
  };

  render() {
    const { classes, options, className, variant, size } = this.props;
    const { selected } = this.state;
    return (
      <div className={[className, classes.container].join(" ")} style={this.props.fullWidth ? { width: "100%" } : {}}>
        <ButtonGroup
          fullWidth={this.props.fullWidth}
          orientation={this.props.orientation || "horizontal"}
          color="primary"
          variant={variant ? variant : "outlined"}
          size={size || "large"}
        >
          {options.map((opt: Option, index: Number) => {
            const v: ButtonVariant = selected === opt.value ? "contained" : "outlined";
            return (
              <Button
                key={index.toString()}
                fullWidth={this.props.fullWidth}
                variant={v}
                onClick={() => this.onSelect(opt)}
              >
                {opt.label}
              </Button>
            );
          })}
        </ButtonGroup>
      </div>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      margin: "10px 0",
    },
    title: {
      width: "fit-content",
      padding: "0 4px",
    },
  });

export default withStyles(styles)(Selector);
