import { Action } from "../types/actions";
import ReduxTypes, { Keys } from "../consts/ReduxTypes";
import { fetchReducer } from "./BaseReducerFunctions";
import { PotState } from "../types/pot";

const INITIAL_STATE = {
  pots: {},
  count: 0,
};
const KEY: Keys = "POTS";

export default (state: PotState = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case ReduxTypes.getFetchType(KEY):
      return fetchReducer(state, action, "pots");

    case ReduxTypes.POTS_GET_TRANSACTIONS: {
      let pots = { ...state.pots };
      pots[action.id].transactions = action.payload;
      return { ...state, pots };
    }

    case ReduxTypes.PURGE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
