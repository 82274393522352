import React from "react";
import { Link, useLocation } from "react-router-dom";
import { IconButton } from "@material-ui/core";

export const BackButton = () => {
  const location = useLocation();
  if (!location.pathname.includes("leaderBoard")) return null;

  return (
    <Link to="/" style={{ textDecoration: "none", color: "white" }}>
      <IconButton color="inherit" edge="end">
        Back
      </IconButton>
    </Link>
  );
};
