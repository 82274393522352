import React, { Component } from "react";
import { connect } from "react-redux";
import {
  BottomNavigation,
  BottomNavigationAction,
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { FormatListBulleted, Home, SportsGolf } from "@material-ui/icons";
import { switchComponent } from "../../actions/componentRouter";
import { Views } from "./index";
import { GlobalState } from "../../types/globalState";
import { showDialog } from "../SharedComponents/Dialog";
import GameSetup from "../Game/GameSetup";
import { Game, GState, IndexedGames } from "../../types/game";
import { showSnackbar } from "../SharedComponents/Notifier";

interface Props extends WithStyles {
  switchComponent(view: any, props?: any): void;
  currentView: Views;
  joinedGames: IndexedGames;
}
interface State {
  selectedItem: number;
}

class Footer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      selectedItem: this.getViewIndex(),
    };
  }

  getViewIndex = () => {
    switch (this.props.currentView) {
      case Views.ClubsListView:
        return 4;
      case Views.GameManager:
        return 1;
      case Views.Profile:
        return 2;
      default:
        return 0;
    }
  };

  getNumberOfJoinedGames = () => {
    const joinedGames = Object.values(this.props.joinedGames);
    return joinedGames.reduce((total: number, game: Game) => {
      if (game.state === GState.Playing) return total + 1;
      return total;
    }, 0);
  };

  _onNavigate = (event: object, selectedItem: number) => {
    this.setState({ selectedItem });
    switch (selectedItem) {
      case 0:
        this.props.switchComponent(Views.DEFAULT);
        break;
      case 1:
        if (this.getNumberOfJoinedGames() === 0) {
          this.props.switchComponent(Views.DEFAULT);
          showSnackbar("Please join/create a game.");
        } else this.props.switchComponent(Views.GameManager);
        break;
      case 2:
        this.props.switchComponent(Views.Profile);
        break;
      case 3:
        showDialog(<GameSetup />, false, true);
        break;
      case 4:
        this.props.switchComponent(Views.ClubsListView);
        break;
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <BottomNavigation
          className={classes.bottomNavigation}
          value={this.getViewIndex()}
          onChange={this._onNavigate}
          showLabels
        >
          <BottomNavigationAction label="Clubs" style={{ marginRight: 50 }} icon={<FormatListBulleted />} value={4} />
          <BottomNavigationAction label="Play" className={classes.mainItem} icon={<SportsGolf />} value={1} />
          <BottomNavigationAction label="Home" style={{ marginLeft: 50 }} icon={<Home />} value={0} />
        </BottomNavigation>
      </>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    bottomNavigation: {
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "100%",
      zIndex: 1000,
    },
    mainItem: {
      position: "absolute",
      top: "-50%",
      width: 78,
      height: 78,
      borderRadius: 78,
      background: theme.palette.primary.main,
      color: "white !important",
    },
  });

function mapStateToProps(state: GlobalState) {
  const { currentView } = state.router;
  const { joinedGames } = state.games;
  return {
    currentView,
    joinedGames,
  };
}

export default connect(mapStateToProps, {
  switchComponent,
})(withStyles(styles)(Footer));
