import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

export interface DialogToolbarProps {
  onClick: () => void;
  title?: string;
  border?: boolean;
  secondary?: boolean;
  logo?: string;
}

const DialogToolbar: React.FC<DialogToolbarProps> = ({ onClick, title, logo, secondary, border = true }) => {
  const classes = useStyles();

  return (
    <div className={classes.root} style={!border ? { border: "none", minHeight: "auto", marginBottom: "10px" } : {}}>
      {title && (
        <Typography className={classes.title} color={secondary ? "secondary" : "inherit"} variant="h6" gutterBottom>
          {title}
        </Typography>
      )}
      {logo && <Avatar className={classes.avatar} alt="Club Logo" src={logo} />}
      <IconButton onClick={onClick} className={classes.button}>
        <CloseIcon />
      </IconButton>
    </div>
  );
};

export default DialogToolbar;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "sticky",
      top: 0,
      zIndex: 10,
      minWidth: "20rem",
      minHeight: "3rem",
      borderBottom: "1px solid #ccc",
      backgroundColor: "white",
      color: "#222",
    },
    title: {
      fontSize: "20px",
      margin: "8px 32px",
      textAlign: "center",
      textShadow: "0px 0px black",

      [theme.breakpoints.down("xs")]: {
        margin: "4px 16px",
      },
    },
    button: {
      position: "absolute",
      top: 0,
      right: 0,
    },
    avatar: {
      width: "6rem",
      height: "6rem",
      margin: "1rem auto 0",
    },
  }),
);
