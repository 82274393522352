import React, { Component } from "react";
import {
  createStyles,
  Paper,
  Theme,
  Typography,
  WithStyles,
  withStyles,
  Button,
  LinearProgress,
} from "@material-ui/core";
import { IconButton, Dialog } from "@material-ui/core";
import { connect } from "react-redux";
import { Close } from "@material-ui/icons";
import { HoleParam } from "../../types/hole";
import { Game, IndexedGames } from "../../types/game";
import { Player, HoleScoreInfo, Points } from "../../types/player";
import { GlobalState } from "../../types/globalState";
import { hideDialog } from "../SharedComponents/Dialog";
import Config from "../../config";
import GrossPickerDialog from "../Game/GrossPickerDialog";
import GameActions from "../../actions/games";
import { showAlertDialog } from "../SharedComponents/AlertDialog";
import { Mismatch } from "types/general";
import { isGameStandard, getHandicapDisplay } from "utils";
import PlayerName from "components/SharedComponents/PlayerName";
import CalcTeamWinners from "utils/CalcTeamWinners";
import { sortNumberAscending } from "utils/sortPlayerToFirst";

interface Props extends WithStyles {
  gameId?: string;
  player: Player;
  teammates?: Player[];
  games: IndexedGames;
  game?: any;
  user: any;
  quickScoring?: boolean;
  mismatches?: Mismatch[];
  useCurUserScores?: boolean;
  onBehalfPlayer?: boolean;
  hideScores?: boolean;
  onSelectHoleNumber?(holeNumber: number): void;
  onVerifyPlayer?(playerId: string): void;
  onCloseModernScoreCardDialog?(): void;
  updateScores(
    gameId: string,
    playerId: string,
    scoreId: string,
    holeNumber: number,
    gross: number,
    shots?: number,
    onBehalfPlayer?: boolean,
  ): void;
}
interface State {
  [inde: string]: any;

  cardName: string;
  holesParams: HoleParam[];
  name: string;
  club: string;
  course: string;
  openPickerDialog: boolean;
  currentHoleNumber: number;
}

class ScoreCardView extends Component<Props, State> {
  calcTeamWinners: CalcTeamWinners | null;
  divRef = React.createRef<HTMLDivElement>();

  constructor(props: Props) {
    super(props);
    this.state = {
      cardName: "",
      holesParams: [],
      name: "",
      club: "",
      course: "",
      openPickerDialog: false,
      currentHoleNumber: 0,
    };

    const game = this.getGame();
    if (this.props.teammates && game) {
      this.calcTeamWinners = new CalcTeamWinners(
        [...this.props.teammates, this.props.player],
        game.competition.scoringFormat,
        game.competition.playingRules,
      );
    } else this.calcTeamWinners = null;
  }

  componentDidUpdate = () => {
    if (this.divRef.current && this.isAllHolesScored(this.props.player)) {
      this.divRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  };

  summation(holeParams: HoleParam[], key: string): number {
    let sum = 0;
    Object.values(holeParams).forEach((param: HoleParam) => {
      if (param[key]) {
        sum += param[key] as number;
      }
    });
    return sum;
  }

  getStartingHoleIndex = (): number => {
    const { player } = this.props;
    if (player.group && player.group.startingHole) {
      return player.group.startingHole - 1;
    }
    return 0;
  };

  getGame = (): Game | null => {
    const { gameId } = this.props;
    if (gameId) {
      return this.props.games[gameId];
    } else if (this.props.game) {
      return this.props.game;
    } else return null;
  };

  isStandardGame = () => isGameStandard(this.getGame()?.competition.type);

  getScoreCard = () => {
    const { user, player } = this.props;
    const game = this.getGame();
    const startingHoleIndex = this.getStartingHoleIndex();
    if (user && game && game.scoreCards) {
      const cardId = game.players[player._id].teeColor ? game.players[player._id].teeColor.scoreCardId : null;
      let sc: any = { ...Object.values(game.scoreCards)[0] };
      if (cardId) sc = { ...game.scoreCards.find((x: any) => x._id === cardId) };
      if (sc.holesParams) {
        sc.holesParams = [...sc.holesParams.slice(startingHoleIndex), ...sc.holesParams.slice(0, startingHoleIndex)];
      }
      return sc;
    }
    return null;
  };

  getGrossStyle = (holeParam: HoleParam, player: Player) => {
    const par = holeParam.par!;
    const gross = this.getGross(holeParam, player);

    const bColors = ["#ff98ca", "#ffff01", "#fe0000", "#FFF", "#00b054", "#ffc000", "#056ec1", "#7030a0", "#000"];
    const colors = ["#FFFFFF", "#000000", "#FFFFFF", "#000", "#FFFFFF", "#000000", "#FFFFFF", "#FFFFFF", "#FFF"];
    if (gross === "" || gross === null) return {};
    else if (gross === "mismatch") return { backgroundColor: "#cc3f3c", color: "#FFF" };

    const index = gross - par + 3;
    if (colors[index]) {
      return { backgroundColor: bColors[index], color: colors[index] };
    } else {
      return { backgroundColor: "#000", color: "#FFF" };
    }
  };
  getGross(holeParam: HoleParam, player: Player) {
    const { holes, uholes } = player;
    if (!holes || !holes[holeParam.number]) return "";
    if (holes[holeParam.number].mismatch) return "mismatch";

    if (this.props.useCurUserScores) {
      return uholes?.[holeParam.number]?.grosses?.[this.props.user._id] || "";
    }

    if (this.props.onBehalfPlayer) {
      return uholes?.[holeParam.number]?.grosses?.[player._id] || "";
    }

    return holes[holeParam.number].gross;
  }

  renderTotals(holesLength: number, points: Points) {
    const { classes } = this.props;
    const scoringFormat = this.getScoringFormat();
    const isStableford = scoringFormat === Config.SCORING_FORMAT.STABLEFORD;
    const OUT = <strong>OUT: </strong>;
    const TOTAL = <strong>Total: </strong>;
    const IN = <strong>IN: </strong>;

    switch (holesLength) {
      case 6:
      case 9:
        return (
          <div className={classes.inline}>
            <div>
              <p>
                {TOTAL}
                {points.totalGross}
                <br />
                <label className={classes.points}>
                  {TOTAL}
                  {isStableford ? points.totalPoint : points.totalNett}
                </label>
              </p>
            </div>
          </div>
        );
      case 12:
        return (
          <div className={classes.inline}>
            <div>
              <p>
                {OUT}
                {points.summary[0].totalGross} <br />
                <label className={classes.points}>
                  {OUT}
                  {isStableford ? points.summary[0].totalPoint : points.summary[0].totalNett}
                </label>
              </p>
            </div>
            <div>
              <p>
                {IN}
                {points.summary[1].totalGross}
                <br />
                <label className={classes.points}>
                  {IN}
                  {isStableford ? points.summary[1].totalPoint : points.summary[1].totalNett}
                </label>
              </p>
            </div>
            <div>
              <p>
                {TOTAL}
                {points.totalGross} <br />
                <label className={classes.points}>
                  {TOTAL}
                  {isStableford ? points.totalPoint : points.totalNett}
                </label>
              </p>
            </div>
          </div>
        );
      case 18:
        return (
          <div className={classes.inline}>
            <div>
              <p>
                {OUT}
                {points.summary[1].totalGross}
                <br />
                <label className={classes.points}>
                  {OUT}
                  {isStableford ? points.summary[1].totalPoint : points.summary[1].totalNett}
                </label>
              </p>
            </div>
            <div>
              <p>
                {IN}
                {points.summary[3].totalGross}
                <br />
                <label className={classes.points}>
                  {IN}
                  {isStableford ? points.summary[3].totalPoint : points.summary[3].totalNett}
                </label>
              </p>
            </div>
            <div>
              <p>
                {TOTAL}
                {points.totalGross}
                <br />
                <label className={classes.points}>
                  {TOTAL}
                  {isStableford ? points.totalPoint : points.totalNett}
                </label>
              </p>
            </div>
          </div>
        );
    }
  }

  onHoleNumberClick = (holeNumber: number) => {
    const { quickScoring } = this.props;
    if (quickScoring) {
      this.grossPickerDialogStatusChanger(true, holeNumber);
    }

    if (!this.props.onSelectHoleNumber) return;
    this.props.onSelectHoleNumber(holeNumber);
    hideDialog();
  };

  onSubmitClick = (playerId: string) => {
    if (this.props.onVerifyPlayer)
      showAlertDialog(
        "Submit",
        "Are you sure?",
        () => this.props.onVerifyPlayer && this.props.onVerifyPlayer(playerId),
        () => {},
      );

    if (this.props.onCloseModernScoreCardDialog) this.props.onCloseModernScoreCardDialog();
    hideDialog();
  };

  getScoringFormat = () => {
    const game = this.getGame();
    return typeof game?.competition === "object" ? game.competition.scoringFormat.toString() : "undefined";
  };

  calculateHoleNumber(number: number) {
    const scoreCard = this.getScoreCard();
    if (!scoreCard || !scoreCard.name) return number;
    const name = scoreCard.name.toLowerCase();
    const { holesNumber } = scoreCard.course;
    if (name.includes("back")) {
      return number + holesNumber;
    } else if (name.includes("middle")) {
      return number + 6;
    } else return number;
  }

  renderItem(holeParam: any, player: Player, holeScores: HoleScoreInfo) {
    const { classes } = this.props;
    const gross = this.getGross(holeParam, player);
    const scoringFormat = this.getScoringFormat();
    let playerScores: number[] = [];

    if (this.calcTeamWinners && holeParam.number in player.holes) {
      playerScores = this.calcTeamWinners.calculate(holeParam.number)[this.props.player._id] || [];
    }

    return (
      <div key={holeParam.number} style={{ display: "flex", flexDirection: "column", width: "fit-content" }}>
        <div className={[classes.tile, classes.square].join(" ")}>
          <span>{this.calculateHoleNumber(holeParam.number)}</span>
          {/* Only scored holes */}
          <span>{playerScores.includes(holeParam.number) ? "✓" : ""}</span>
        </div>
        <div className={classes.tile} style={{ paddingTop: 8 }}>
          {holeParam.par}
        </div>
        <div
          className={[classes.tile, classes.circle].join(" ")}
          style={this.getGrossStyle(holeParam, player)}
          onClick={() => this.onHoleNumberClick(holeParam.number)}
        >
          {gross === "mismatch" ? <Close style={{ margin: -6 }} /> : gross}
        </div>
        <div className={[classes.tile, classes.points].join(" ")}>
          {!gross || gross === "mismatch"
            ? null
            : scoringFormat === Config.SCORING_FORMAT.STABLEFORD
            ? holeScores.point
            : holeScores.nett}
        </div>
      </div>
    );
  }

  isAllHolesScored = (player: Player) => {
    //this return false if all of player's holes gross are entered
    const holes = Object.values(player.holes);
    const { holesParams } = this.getScoreCard()!;
    if (holesParams.length !== holes.length) return false;

    if (this.props.useCurUserScores || this.props.onBehalfPlayer) {
      const pId = this.props.useCurUserScores ? this.props.user._id : player._id;
      return Object.values(player.uholes).every(
        h => h.grosses[pId] !== undefined && !player.holes[h.holeNumber]?.mismatch,
      );
    }

    return holes.every(item => item.gross !== undefined);
  };

  enterNextPlayerQuickScore = () => {
    if (this.props.quickScoring)
      showAlertDialog(
        "Quick score",
        "Do you want to enter another quick score",
        () => {},
        () => {
          hideDialog();
        },
      );
  };
  renderSubmitButton = (player: any) => {
    const { classes } = this.props;
    //second conditions checks if player is unverifiable
    return player.isUnverifiable || (this.props.quickScoring && this.isAllHolesScored(player)) ? (
      <Button
        variant="contained"
        fullWidth
        color="secondary"
        onClick={() => {
          this.onSubmitClick(player._id);
          // this.enterNextPlayerQuickScore(); // checks wheather to render the quick scoring list or not
        }}
        className={classes.okBtn}
      >
        OK TO SUBMIT
      </Button>
    ) : null;
  };

  grossPickerDialogStatusChanger = (status: boolean, holeNumber?: number) => {
    this.setState({ openPickerDialog: status, currentHoleNumber: holeNumber !== undefined ? holeNumber : 0 });
  };

  handleGrossChange = (gameId: string, player: Player, holeNumber: number, gross: any, dnfSelected: boolean) => {
    this.props.updateScores(
      gameId,
      player._id,
      player.scoreId,
      holeNumber,
      dnfSelected ? "DNF" : gross,
      gross,
      this.props.onBehalfPlayer,
    );
    this.grossPickerDialogStatusChanger(false);
  };

  renderGrossPickerDialog() {
    const { gameId } = this.props;

    const { player } = this.props;
    return (
      <Dialog
        hideBackdrop
        open={this.state.openPickerDialog}
        onBackdropClick={e => this.grossPickerDialogStatusChanger(false)}
      >
        <div>
          <IconButton style={{ float: "right" }} onClick={e => this.grossPickerDialogStatusChanger(false)}>
            <Close />
          </IconButton>
        </div>

        <GrossPickerDialog
          gameId={gameId ?? ""}
          holeNumber={this.state.currentHoleNumber}
          onSelect={(gross: any, dnfSelected: boolean, p?: Player) => {
            this.handleGrossChange(gameId ?? "", p || player, this.state.currentHoleNumber, gross, dnfSelected);
          }}
        />
      </Dialog>
    );
  }

  render() {
    let { classes, player, hideScores } = this.props;
    const game = this.getGame();
    if (!game) return null;
    const { holesParams } = this.getScoreCard()!;
    if (!holesParams) return null;
    player = game.players[player._id];
    if (!player.points) return <LinearProgress />;

    return (
      <Paper className={classes.paper}>
        {player.isUnverifiable ? (
          <div className={classes.topLabel}>
            <label>Tap score to amend</label>
          </div>
        ) : null}
        <Typography variant={"h5"} className={classes.title}>
          <PlayerName isGuest={player.guest} name={player.name} lastName={player.lastName} />

          <small>{` - PH ${getHandicapDisplay(player.hcp)} ${
            this.isStandardGame() ? `- HI ${player.hci}` : ""
          }`}</small>
        </Typography>
        {hideScores ? null : (
          <>
            <div className={classes.flex}>
              {holesParams
                .sort((a: any, b: any) => sortNumberAscending(a.number, b.number))
                .map((holeParam: HoleParam) =>
                  this.renderItem(holeParam, player, player.points.holes[holeParam.number]),
                )}
            </div>
            {this.props.mismatches
              ? this.props.mismatches.map(mismatch => {
                  return (
                    <div
                      key={mismatch.hole}
                      style={{ marginBottom: 20, display: "flex", justifyContent: "space-between" }}
                    >
                      <div>
                        <Typography>
                          <span>{mismatch.marker.name}</span>
                          <span>&nbsp;(Marker) scores&nbsp;</span>
                          <span>{mismatch.marker.score}</span>
                        </Typography>

                        <Typography>
                          <span>{mismatch.player.name}</span>
                          <span>&nbsp;(Player) scores&nbsp;</span>
                          <span>{mismatch.player.score}</span>
                        </Typography>
                      </div>

                      <Typography style={{ color: "red", marginLeft: 5 }}>Please confirm you are correct</Typography>
                    </div>
                  );
                })
              : null}
            {this.renderTotals(holesParams.length, player.points)}
            {this.renderSubmitButton(player)}
            {this.renderGrossPickerDialog()}
          </>
        )}
        <span ref={this.divRef}>&nbsp;</span>
      </Paper>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      display: "block",
      position: "relative",
      left: "50%",
      transform: "translateX(-50%)",
      width: "auto",
      backgroundColor: "rgba(255,255,552,0.85)",
      padding: theme.spacing(2),
    },
    inline: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    flex: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      flexWrap: "wrap",

      "& > div": {
        minWidth: "11%",
        marginBottom: 10,

        [theme.breakpoints.down("sm")]: {
          minWidth: "15%",
        },
      },
    },
    tile: {
      width: 42,
      height: 42,
      paddingTop: 8,
      margin: 2,
      fontSize: 16,
      textAlign: "center",
    },
    points: {
      color: "#f50057",
    },
    square: {
      border: "solid",
      borderColor: "#33a136",
      borderWidth: "2px",
      padding: 3,
      display: "flex",
      flexDirection: "column",

      "& > span": {
        lineHeight: "1",

        "&:last-child": {
          color: "#33a136",
        },
      },
    },
    circle: {
      border: "solid",
      borderColor: "#000",
      borderWidth: "2px",
      borderRadius: 32,
      cursor: "pointer",
    },
    topLabel: {
      textAlign: "center",
      padding: 5,
      backgroundColor: "#FFAF0F",
      marginBottom: 10,
      color: "#ffffff",
      borderRadius: 5,
    },
    okBtn: {
      [theme.breakpoints.down("sm")]: {
        position: "sticky",
        bottom: 20,
        left: 0,
        right: 0,
      },
    },
  });
const mapStateToProps = (state: GlobalState) => {
  const { joinedGames, organisedByMe } = state.games;
  const { user } = state.auth;
  return {
    user,
    games: { ...organisedByMe, ...joinedGames },
  };
};

export default connect(mapStateToProps, { updateScores: GameActions.updateScores })(withStyles(styles)(ScoreCardView));
