import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import CoursesActions from "../../actions/courses";
import ClubActions from "../../actions/clubs";
import { GlobalState } from "../../types/globalState";
import BorderedBox from "../SharedComponents/BorderedBox";
import { Button, createStyles, Paper, TextField, Theme, Typography, WithStyles } from "@material-ui/core";
import { showSnackbar } from "../SharedComponents/Notifier";
import LinearProgress from "@material-ui/core/LinearProgress";
import { hideDialog } from "../SharedComponents/Dialog";
import { Course, CourseInput, HoleNumbers } from "../../types/course";
import Selector, { Option } from "../SharedComponents/Selector";
import SearchInput, { SearchItem } from "../Search/SearchInput";
import { Club } from "../../types/club";
import { TextInput } from "../SharedComponents/TextInput";
import { filter } from "../../utils/profanityFilter";

interface Props extends WithStyles {
  insertCourses(data: CourseInput): void;

  updateCourses(id: string, data: CourseInput): void;

  searchClubs(
    callback: any,
    query: string,
    page: number,
    limit: number,
    filter?: any,
    sort?: string,
    dir?: string,
  ): void;

  edit?: Course;
  loading: boolean;
}
interface State {
  course: CourseInput;
  err_name?: boolean;
  err_club?: boolean;
}

class CourseSetup extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let course: CourseInput = {
      name: "",
      holesNumber: 18,
      club: {} as Club,
      golfAdvisorID: "",
      images: [],
    };
    if (props.edit) {
      const { _id, ...typeField } = props.edit;
      course = typeField;
    }
    this.state = {
      course,
    };
  }

  formValidation = () => {
    const { name, club } = this.state.course;
    const err_name = name.trim().length < 2 || filter.isProfane(name);
    const err_club = club === null;

    this.setState({
      err_name,
      err_club,
    });

    return !(err_name || err_club);
  };

  _handleTextFieldChange = (value: string, name: string) => {
    this.setState({
      course: { ...this.state.course, [name]: value },
    });
  };
  _submitForm = () => {
    const { edit } = this.props;
    const { course } = this.state;
    const newCourse: any = {
      ...course,
      name: course.name.trim(),
    };
    if (this.formValidation()) {
      if (edit) {
        this.props.updateCourses(edit._id, newCourse);
      } else {
        this.props.insertCourses(newCourse);
      }
    } else {
      showSnackbar("Form is not valid, check items");
    }
  };
  _onChangeHoleNumbers = (value: string | number) => {
    this.setState({
      course: {
        ...this.state.course,
        holesNumber: value as HoleNumbers,
      },
    });
  };
  _searchHandler = (query: string) => {
    return new Promise((resolve, reject) => {
      this.props.searchClubs(
        (err: any, result: any) => {
          if (!err) {
            resolve(
              result.data.items.map((item: Club) => ({
                value: item._id,
                title: item.name,
                subtitle: item.address,
                object: item,
              })),
            );
          } else {
            reject(err);
          }
        },
        query,
        1,
        10,
        undefined,
        "name",
        "desc",
      );
    });
  };
  _onClubSelected = (item: SearchItem) => {
    const club = item && item.object ? item.object : null;
    this.setState({ course: { ...this.state.course, club } });
    if (club) {
      this.setState({ err_club: false });
    }
  };

  renderActionButton() {
    const { classes, loading } = this.props;
    if (loading)
      return (
        <div className={classes.loadingContainer}>
          <LinearProgress color="primary" variant={"indeterminate"} />
        </div>
      );
    else
      return (
        <div className={classes.actionButtonContainer}>
          <Button
            fullWidth
            className={classes.actionButton}
            variant={"contained"}
            color={"primary"}
            onClick={this._submitForm}
          >
            Save
          </Button>
          <Button
            fullWidth
            className={classes.actionButton}
            variant={"contained"}
            color={"secondary"}
            onClick={hideDialog}
          >
            Cancel
          </Button>
        </div>
      );
  }

  renderNumberPicker() {
    const { holesNumber } = this.state.course;
    const options: Option[] = [
      {
        label: 18,
        value: 18,
      },
      {
        label: 12,
        value: 12,
      },
      {
        label: 9,
        value: 9,
      },
      {
        label: 6,
        value: 6,
      },
    ];
    return <Selector value={holesNumber} options={options} variant={"outlined"} onChange={this._onChangeHoleNumbers} />;
  }
  render() {
    const { classes } = this.props;
    const { name, club } = this.state.course;
    const { err_name, err_club } = this.state;
    const selectedItem = club ? { title: club.name, value: club._id, subtitle: club.address } : undefined;
    return (
      <Paper className={classes.paper}>
        <Typography variant={"h5"} className={classes.title}>
          Course Setup
        </Typography>
        <TextInput
          label={"Name"}
          margin={"dense"}
          variant={"outlined"}
          value={name}
          error={err_name}
          captalize
          name={"name"}
          onChange={this._handleTextFieldChange}
          fullWidth
        />
        <BorderedBox title={"Number of Holes"} variant={"h6"}>
          {this.renderNumberPicker()}
        </BorderedBox>
        <BorderedBox title={"Club"} variant={"h6"} error={err_club}>
          <SearchInput
            dialog
            minChar={3}
            label={"Input clubs name"}
            placeholder={"Click to search clubs"}
            defaultSelectedItem={selectedItem}
            searchHandler={this._searchHandler}
            onItemSelected={this._onClubSelected}
          />
        </BorderedBox>
        {this.renderActionButton()}
      </Paper>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    title: {
      fontWeight: "bold",
    },
    paper: {
      display: "block",
      position: "relative",
      left: "50%",
      transform: "translateX(-50%)",
      width: "auto",
      maxWidth: 500,
      minWith: 360,
      backgroundColor: "rgba(255,255,552,0.85)",
      padding: theme.spacing(2),
    },
    inline: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    actionButtonContainer: {
      display: "flex",
    },
    actionButton: {
      marginTop: theme.spacing(1),
    },
    loadingContainer: {
      width: "100%",
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
  });

const mapStateToProps = (state: GlobalState) => {
  const { loading } = state.general;
  return {
    loading,
  };
};

export default connect(mapStateToProps, {
  searchClubs: ClubActions.search,
  insertCourses: CoursesActions.insert,
  updateCourses: CoursesActions.update,
})(withStyles(styles)(CourseSetup));
