import React from "react";
import { Grid, Button, makeStyles, Theme } from "@material-ui/core";

export interface BestHolesProps {
  holesCount: number;
  selectedHoles: number[];
  onHoleClicked(hole: number): void;
  onSubmit(): void;
}

const BestHoles: React.SFC<BestHolesProps> = props => {
  const classes = useStyles();
  const holes = [];
  for (let i = 1; i <= props.holesCount; i++) holes.push(i);

  return (
    <div className={classes.container}>
      <Grid container>
        {holes.map((hole, index) => {
          return (
            <Grid item md={3} key={index}>
              <div
                className={classes.hole}
                style={{
                  backgroundColor: props.selectedHoles?.includes(hole) ? "#009e0f" : "#ff9900",
                }}
                onClick={() => props.onHoleClicked(hole)}
              >
                {hole}
              </div>
            </Grid>
          );
        })}
        <Grid item md={3}>
          <div
            className={classes.hole}
            style={{
              backgroundColor: "#3f51b5",
            }}
            onClick={() => props.onHoleClicked(-1)}
          >
            All
          </div>
        </Grid>
        <Grid item md={3} className={classes.buttonContainer}>
          <Button
            variant="contained"
            style={{ background: "#009e0f", color: "white", maxWidth: "100%" }}
            onClick={props.onSubmit}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: 300,
    padding: 10,
  },
  hole: {
    border: "solid",
    borderColor: "#333",
    borderWidth: "2px",
    borderRadius: 32,
    cursor: "pointer",
    width: 42,
    height: 42,
    paddingTop: 8,
    margin: "auto",
    marginTop: 2,
    marginBottom: 2,
    fontSize: 16,
    textAlign: "center",
    color: "white",
  },
  buttonContainer: {
    textAlign: "center",
    paddingTop: 5,
  },
}));

export default BestHoles;
