import { AxiosRequestConfig, AxiosError, AxiosResponse } from "axios";
import httpService from "./httpService";
import Config from "../config";

const STORAGE_NAME = Config.STORAGE.FAILED_API_CALLS;
let onChange: (offlineCalls: number) => void = (offlineCalls: number) => {};

const readFromLocalStorage = (): Array<AxiosRequestConfig> => {
  return JSON.parse(localStorage.getItem(STORAGE_NAME) || "[]") as Array<AxiosRequestConfig>;
};

const clearSavedCallsFromStorage = () => {
  localStorage.removeItem(STORAGE_NAME);
};

export const add = (error: AxiosError): void => {
  if (!error.config?.headers[Config.SAVE_IF_FAILED] || error.response?.status) return;

  const callsArray = readFromLocalStorage();
  callsArray.push(error.config);
  localStorage.setItem(STORAGE_NAME, JSON.stringify(callsArray));
  onChange(callsArray.length);
};

export const getSavedCallsCount = (): number => {
  return readFromLocalStorage().length;
};

export const resend = async () => {
  const calls = readFromLocalStorage();

  try {
    for (const call of calls) {
      if (!call.method || !call.url) continue;

      switch (call.method) {
        case "post":
        case "put":
        case "patch":
          await httpService[call.method](call.url, call.data, { headers: call.headers });
          break;
        case "delete":
          await httpService.delete(call.url, { headers: call.headers });
          break;
      }
    }
    clearSavedCallsFromStorage();
    onChange(0);
  } catch (error) {
    console.error("Error resending failed requests! ", error);
  }
};

export const onChangeListener = (callback: (offlineCalls: number) => void) => {
  onChange = callback;
};

export default {
  add: add,
  counts: getSavedCallsCount,
  resend: resend,
  onChangeListener: onChangeListener,
};
