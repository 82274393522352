import * as React from "react";
import { Handicap, IndexedHandicaps } from "../../types/handicap";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  Theme,
  IconButton,
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import moment from "moment";
import { showPickerDialog } from "../SharedComponents/PickerDialog";
import { Player } from "./../../types/player";
import HandicapActions from "../../actions/handicaps";
import { connect } from "react-redux";
import { GlobalState } from "./../../types/globalState";
import { Rules } from "../../types/user";
import { hcpConvertor } from "utils/hcpConvertor";

export interface HandicapsListProps {
  updateHandicap(id: string, competitionId: string, hcp: number, playerId: string, callback?: any): any;
  player: Player;
  id: string;
  handicaps: IndexedHandicaps;
  user: any;
}

const HandicapsList: React.SFC<HandicapsListProps> = props => {
  const classes = useStyles();

  const onEditClicked = (handicap: Handicap) => {
    showPickerDialog(
      "Handicap",
      "number",
      (hcp: string) => {
        props.updateHandicap(props.id, handicap.competitionId, parseFloat(hcpConvertor(hcp)), props.player._id);
      },
      0,
      null,
      null,
      false,
      true,
    );
  };

  const isAdmin = props.user.rule === Rules.Admin;
  const handicapsInfo = props.handicaps[props.id];
  const { user } = props;
  return (
    <div className={classes.container}>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Competition Name</TableCell>
              <TableCell>PH</TableCell>
              <TableCell>Last Update</TableCell>
              <TableCell>Edit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {handicapsInfo?.handicaps.map((handicap: Handicap, index: number) => {
              return (
                <TableRow key={index}>
                  <TableCell>{index + 1}.</TableCell>
                  <TableCell component="th" scope="row">
                    {handicap.game}
                  </TableCell>
                  <TableCell>{handicap.hcp}</TableCell>
                  <TableCell>
                    {handicap.lastUpdateDate ? moment(handicap.lastUpdateDate).format("ll HH:MM") : null}
                  </TableCell>
                  <TableCell>
                    {isAdmin || handicap.owner === user._id ? (
                      <IconButton onClick={() => onEditClicked(handicap)}>
                        <Edit />
                      </IconButton>
                    ) : null}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    minWidth: 600,
  },
}));

const mapStatetoProps = (state: GlobalState) => {
  const { user } = state.auth;
  const { handicaps } = state.handicaps;
  return {
    user,
    handicaps: handicaps,
  };
};

export default connect(mapStatetoProps, {
  updateHandicap: HandicapActions.updateHandicap,
})(HandicapsList);
