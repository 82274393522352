import {
  Button,
  Card,
  CardContent,
  CardMedia,
  createStyles,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  IconButton,
  InputAdornment,
  LinearProgress,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Theme,
  Typography,
  WithStyles,
} from "@material-ui/core";
import _ from "lodash";
import SwiperCore, { Virtual, Pagination, Thumbs, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/navigation/navigation.scss";
import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { GlobalState } from "../../types/globalState";
import ClubsActions from "../../actions/clubs";
import { Close, ExpandMore, Favorite, FavoriteBorder, Search as SearchIcon, VerifiedUser } from "@material-ui/icons";
import { showDialog } from "../SharedComponents/Dialog";
import GameSetup from "../Game/GameSetup";
import { Club, IndexedClubs } from "../../types/club";
import { Course } from "../../types/course";
import config from "../../config";

SwiperCore.use([Virtual, Pagination, Navigation]);

interface Props extends WithStyles {
  addToFav(id: string, callback?: any): void;
  removeFromFav(id: string, callback?: any): void;
  fetchClubs(page: number, limit: number, filter?: any, sort?: string, dir?: string, query?: string): void;

  loading: boolean;
  clubs: IndexedClubs;
}
interface State {
  searchText: string;
}

class ClubsListView extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      searchText: "",
    };
    this.props.fetchClubs(1, 50, undefined, "name", "asc");
  }

  _onSearchInputChanged = (event: any) => {
    const searchText = event.target.value as string;
    if (searchText.trim().length > 2) this.props.fetchClubs(1, 20, undefined, "name", "asc", searchText.trim());
    this.setState({ searchText });
  };
  _clearSearchText = () => {
    this.setState({ searchText: "" });
  };
  _createGame = (club: any, course: any) => {
    showDialog(<GameSetup course={course} club={club} />, false, true, true, true);
  };

  renderSearchInput() {
    const { classes } = this.props;
    const { searchText } = this.state;
    return (
      <TextField
        value={searchText}
        variant={"outlined"}
        placeholder={"Search clubs by name"}
        className={classes.searchInput}
        onChange={this._onSearchInputChanged}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment:
            searchText !== "" ? (
              <InputAdornment position="end">
                <IconButton onClick={this._clearSearchText}>
                  <Close />
                </IconButton>
              </InputAdornment>
            ) : null,
        }}
      />
    );
  }
  renderCourses(club: Club) {
    if (!club.courses || club.courses.length === 0) return null;

    return (
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
          <Typography> Courses </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <List className={"full-width"} dense>
            {club.courses
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((course: Course) => (
                <ListItem key={course._id}>
                  <ListItemText primary={course.name} secondary={`${course.holesNumber} Hole`} />
                  <ListItemSecondaryAction>
                    <Button color={"primary"} variant={"outlined"} onClick={() => this._createGame(club, course)}>
                      Create a game
                    </Button>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
          </List>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
  renderClubCard(club: Club) {
    const { classes } = this.props;
    let placeholder = require("../../assets/images/course.jpg");
    let images: string[] = [];
    club.courses &&
      club.courses.forEach(
        course =>
          course.images &&
          images.push(...course.images.map(img => `${config.API_URL}public/uploads/coursesImages/${img}`)),
      );

    return (
      <Card key={club._id} className={classes.card} raised>
        {images.length > 0 ? (
          <Swiper virtual spaceBetween={0} slidesPerView={1} pagination={{ clickable: true }} navigation>
            {_.uniq(images).map(image => (
              <SwiperSlide>
                <CardMedia className={classes.media} image={image} />
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <CardMedia className={classes.media} image={placeholder} />
        )}
        <CardContent style={{ position: "relative" }}>
          <div className={classes.row}>
            <Typography variant="h5" component="h2">
              {club.name}
            </Typography>
            {club.registered && <VerifiedUser style={{ color: "#0084b4" }} />}
          </div>
          <IconButton className={classes.favButton}>
            {club.favourite ? (
              <Favorite style={{ color: "#f44336" }} onClick={() => this.props.removeFromFav(club._id)} />
            ) : (
              <FavoriteBorder onClick={() => this.props.addToFav(club._id)} />
            )}
          </IconButton>
          <Typography variant="body2" color="textSecondary" component="p">
            {club.address}
          </Typography>
        </CardContent>
        {this.renderCourses(club)}
      </Card>
    );
  }

  filterClubs = (clubs: Club[]) => {
    const { searchText } = this.state;
    if (searchText.trim().length > 2)
      return clubs.filter(a => a.name.toLowerCase().includes(searchText.trim().toLowerCase()));
    else return clubs;
  };

  renderList(clubs: any) {
    const items: Club[] = Object.values(clubs);

    if (items.length === 0) return <h3 className={"text-center"}>CLUBS LOADING - PLEASE WAIT</h3>;
    const filteredClubs = this.filterClubs(items);
    if (filteredClubs.length === 0)
      return <h3 className={"text-center"}>NO CLUB FOUND SEARCHING {this.state.searchText}</h3>;

    return (
      <div>
        {filteredClubs
          .sort((a, b) => (a.favourite ? -1 : b.favourite ? 1 : a.name > b.name ? 1 : -1))
          .map(club => this.renderClubCard(club))}
      </div>
    );
  }

  render() {
    const { classes, loading, clubs } = this.props;
    return (
      <div>
        {loading && <LinearProgress style={{ height: 4 }} />}
        <header className={classes.header}>{this.renderSearchInput()}</header>
        {this.renderList(clubs)}
      </div>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    card: {
      margin: 8,
      marginBottom: 4,
      position: "relative",
    },
    favButton: {
      position: "absolute",
      top: 8,
      right: 8,
    },
    media: {
      height: 140,
    },
    header: {
      backgroundColor: "#FFF",
    },
    searchInput: {
      margin: 16,
      width: "93%",
    },
    row: {
      display: "flex",
      alignItems: "center",
      marginBottom: "0.35em",
    },
  });
const mapStateToProps = (state: GlobalState) => {
  const { loading } = state.general;
  const { clubs } = state.clubs;
  return {
    loading,
    clubs,
  };
};

export default connect(mapStateToProps, {
  fetchClubs: ClubsActions.fetch,
  addToFav: ClubsActions.addToFav,
  removeFromFav: ClubsActions.removeFromFav,
})(withStyles(styles)(ClubsListView));
