import { Action } from "../types/actions";
import ReduxTypes, { Keys } from "../consts/ReduxTypes";
import { fetchReducer, insertReducer } from "./BaseReducerFunctions";
import { HandicapState } from "../types/handicap";

const INITIAL_STATE = {
  handicaps: {},
  count: 0,
};
const KEY: Keys = "HANDICAPS";

export default (state: HandicapState = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case ReduxTypes.getInsertType(KEY):
      return insertReducer(state, action, "handicaps");

    case ReduxTypes.getFetchType(KEY):
      return fetchReducer(state, action, "handicaps");

    case ReduxTypes.HANDICAPS_SET_HANDICAPS_DETAILS: {
      let handicaps = { ...state.handicaps };
      handicaps[action.id].handicaps = action.payload;
      return { ...state, handicaps };
    }

    case ReduxTypes.HANDICAPS_UPDATE_HCP: {
      let handicaps = { ...state.handicaps };
      handicaps[action.id].handicaps.find((x: any) => x.competitionId === action.competitionId).hcp = action.payload;
      return { ...state, handicaps };
    }

    case ReduxTypes.PURGE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
