import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Dialog, IconButton, Tooltip, Grid, Typography, Divider } from "@material-ui/core";
import { CloudDownload, PictureAsPdf } from "@material-ui/icons";
import { AxiosError } from "axios";
import { playerCustomHandicap } from "../../types/handicpas";
import HandicapActions from "../../actions/handicaps";
import CSVIcon from "../../assets/images/icons/csv-icon.png";
import { share } from "../../utils/share";
import { Game } from "../../types/game";
import {
  downloadCustomHandicapsCSV,
  getCustomHandicapsCSVFile,
  downloadLeaderboardCSV,
  getLeaderboardCSVFile,
} from "utils/GameReportGenerator/csv";
import { ShareFileButton } from "../SharedComponents/ShareButton";
import {
  exportLeaderboardToImageFile,
  exportLeaderboardToPDF,
  exportResultToImageFile,
  exportResultToPDF,
} from "../../utils/GameReportGenerator/pdf";

interface Props {
  game: Game;
  gameQueryType: string;
  getHandicapsByRuleId(id: string, callback: (err: AxiosError | null, data: playerCustomHandicap[]) => void): void;
}

const ShareGameInformationDialog = (props: Props) => {
  const { game, gameQueryType } = props;
  const [handicaps, setHandicaps] = useState<playerCustomHandicap[]>();
  const [openCH, setOpenCH] = useState<boolean>(false);
  const [openResult, setOpenResult] = useState<boolean>(false);
  const [openLeaderBoard, setOpenLeaderBoard] = useState<boolean>(false);

  useEffect(() => {
    props.getHandicapsByRuleId(game.competition._id, (err, handicaps) => {
      if (!err) setHandicaps(handicaps);
    });
  }, [game]);

  return (
    <>
      {handicaps && (
        <Tooltip title="CSV Report" leaveTouchDelay={2000}>
          <IconButton onClick={() => setOpenCH(true)}>
            <img
              src={CSVIcon}
              style={{
                width: "auto",
                height: 24,
              }}
            />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title="Export Result" leaveTouchDelay={2000}>
        <IconButton onClick={() => setOpenResult(true)}>
          <CloudDownload />
        </IconButton>
      </Tooltip>
      <Tooltip title="Export LeaderBoard" leaveTouchDelay={2000}>
        <IconButton onClick={() => setOpenLeaderBoard(true)}>
          <PictureAsPdf />
        </IconButton>
      </Tooltip>
      {handicaps && (
        <Dialog maxWidth="xs" open={openCH} onBackdropClick={() => setOpenCH(false)}>
          <Grid container>
            <Grid item>
              <div className="flex-column" style={{ padding: "1rem 2rem" }}>
                <Typography>Custom Handicaps CSV</Typography>
                <Divider style={{ marginBottom: 15 }} />

                <Button
                  onClick={() =>
                    share({
                      title: `${game.name} custom handicaps CSV file by SCHALA`,
                      text: `Here is custom handicaps of players in the game ${game.name} which has been played using SCHALA`,
                      url: `https://schala.golf`,
                      files: [getCustomHandicapsCSVFile(`Handicaps.csv`, game, handicaps)],
                    })
                  }
                >
                  Share
                </Button>
                <Button onClick={() => downloadCustomHandicapsCSV(game, handicaps)}>Download</Button>
              </div>
            </Grid>

            {/*  */}

            <Grid item>
              <div className="flex-column" style={{ padding: "1rem 2rem" }}>
                <Typography>Leaderboard CSV</Typography>
                <Divider style={{ marginBottom: 15 }} />
                <Button
                  onClick={() =>
                    share({
                      title: `${game.name} Leaderboard CSV file by SCHALA`,
                      text: `Here is the leaderboard of ${game.name} game which has been played using SCHALA`,
                      url: `https://schala.golf`,
                      files: [getLeaderboardCSVFile(`leaderboard.csv`, game, gameQueryType)],
                    })
                  }
                >
                  Share
                </Button>
                <Button onClick={() => downloadLeaderboardCSV(game, gameQueryType)}>Download</Button>
              </div>
            </Grid>
          </Grid>
        </Dialog>
      )}
      <Dialog open={openResult} onBackdropClick={() => setOpenResult(false)}>
        <div className="flex-column">
          <ShareFileButton
            title={`SCHALA - ${game.name} game Result`}
            text={`Here is result of ${game.name} which has been played using SCHALA`}
            url={`https://schala.golf`}
            getFiles={async () => await exportResultToImageFile(`GameResult`, game, gameQueryType)}
          />
          <Button onClick={() => exportResultToPDF(game, gameQueryType)}>Download</Button>
        </div>
      </Dialog>

      <Dialog open={openLeaderBoard} onBackdropClick={() => setOpenLeaderBoard(false)}>
        <div className="flex-column">
          <ShareFileButton
            title={`SCHALA - ${game.name} game leaderboard`}
            text={`Here is leaderboard of ${game.name} which has been played using SCHALA`}
            url={`https://schala.golf`}
            getFiles={async () => await exportLeaderboardToImageFile(`Leaderboard`, "leaderboard", game)}
          />
          <Button onClick={() => exportLeaderboardToPDF("leaderboard", game)}>Download</Button>
        </div>
      </Dialog>
    </>
  );
};

export default connect(null, { getHandicapsByRuleId: HandicapActions.getHandicapsByRuleId })(
  ShareGameInformationDialog,
);
