import React, { useEffect } from "react";
import { connect } from "react-redux";
import Moment from "moment";
import {
  createStyles,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import { AddCircle, RemoveCircle } from "@material-ui/icons";
import { IndexedGames } from "../../types/game";
import { GlobalState } from "../../types/globalState";
import tournamentActions from "../../actions/tournaments";
import { Game } from "../../types/game";
import { ErrorOnlyCallback } from "../../types/actions";
import Loading from "../Loading";

interface Props extends WithStyles {
  addGameToTournament(tournamentId: string, gameId: string, callback?: ErrorOnlyCallback): void;
  removeGameFromTournament(tournamentId: string, gameId: string, callback?: ErrorOnlyCallback): void;
  getTournamentGames(tournamentId: string, callback?: any): void;
  tournamentId: string;
  editable?: boolean;
  games: Game[];
}

const TournamentGamesManager: React.FC<Props> = props => {
  useEffect(() => {
    props.getTournamentGames(props.tournamentId);
  }, []);

  const games = props.games.reduce(
    (acc: { included: Game[]; remaining: Game[] }, item) => {
      acc[item.tournaments.some(t => t._id === props.tournamentId) ? "included" : "remaining"].push(item);
      return acc;
    },
    { included: [], remaining: [] },
  );

  return (
    <div className={props.classes.root}>
      <Loading style={{ position: "sticky", top: 50 }} />

      <List>
        {games.included.length ? (
          <>
            <Typography style={{ borderColor: "#000" }} className={props.classes.header}>
              Current games included in this Tournament
            </Typography>
            <List className={props.classes.nested}>
              {games.included.map(game => (
                <ListItem key={game.code} className={props.classes.item}>
                  <ListItemText primary={game.name} secondary={game.code} />
                  <ListItemText secondary={`${Moment(game.date).format("ddd DD/MM/YY hh.mm")}`} />
                  {props.editable && (
                    <ListItemSecondaryAction>
                      <IconButton
                        onClick={() =>
                          props.removeGameFromTournament(
                            props.tournamentId,
                            game._id,
                            err => !err && props.getTournamentGames(props.tournamentId),
                          )
                        }
                      >
                        <RemoveCircle color={"secondary"} />
                      </IconButton>
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              ))}
            </List>
          </>
        ) : null}

        {games.remaining.length ? (
          <Typography style={{ borderColor: "orange" }} className={props.classes.header}>
            Additional games to select
          </Typography>
        ) : null}
        {games.remaining.map(game => (
          <ListItem key={game.code} className={props.classes.item}>
            <ListItemText primary={game.name} secondary={game.code} />
            <ListItemText secondary={`${Moment(game.date).format("ddd DD/MM/YY hh.mm")}`} />
            {props.editable && (
              <ListItemSecondaryAction>
                <IconButton
                  onClick={() =>
                    props.addGameToTournament(
                      props.tournamentId,
                      game._id,
                      err => !err && props.getTournamentGames(props.tournamentId),
                    )
                  }
                >
                  <AddCircle color={"primary"} />
                </IconButton>
              </ListItemSecondaryAction>
            )}
          </ListItem>
        ))}

        {props.games.length === 0 && (
          <ListItem>
            <ListItemText secondary="No Game found" />
          </ListItem>
        )}
      </List>
    </div>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      minWidth: 500,

      [theme.breakpoints.down("xs")]: {
        minWidth: "100%",
      },

      "& li:not(:last-child)": {
        borderBottom: "1px solid #999",
      },
    },
    header: {
      textAlign: "center",
      padding: "0.6rem 1rem",
      margin: "1rem",
      border: "2px solid",
    },
    nested: {
      margin: "1rem",
      border: "2px solid green",
      padding: 0,
    },
    item: {},
  });

const sortGame = (games: Game[], tournamentId: string) => {
  return games.sort((a, b) => (a.tournaments.find(t => t._id === tournamentId) ? -1 : 1));
};

const mapStateToProps = (state: GlobalState, props: any) => {
  const { tournamentGames } = state.tournaments;
  let games = tournamentGames.games;
  if (props.editable) {
    const { joinedGames, organisedByMe, history } = state.games;
    let indexedTournamentGames: IndexedGames = {};
    tournamentGames.games &&
      tournamentGames.games.forEach(g => {
        indexedTournamentGames[g._id] = g;
      });
    games = Object.values({ ...history.items, ...organisedByMe, ...joinedGames, ...indexedTournamentGames });
  }
  return {
    games: sortGame(games, props.tournamentId),
  };
};

export default connect(mapStateToProps, {
  addGameToTournament: tournamentActions.addGameToTournament,
  removeGameFromTournament: tournamentActions.removeGameFromTournament,
  getTournamentGames: tournamentActions.getTournamentGames,
})(withStyles(styles)(TournamentGamesManager));
